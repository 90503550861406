import { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField, PhoneMaskField, DateMaskField } from "@preferral/ui";
import toast from "react-hot-toast";
import { localDateToISO } from "@preferral/common";
import { analytics } from "lib/analytics";

const CREATE_PATIENT_MEMBER = gql`
  mutation CreatePatientMember($input: PatientMemberInput!) {
    createPatientMember(input: $input) {
      errors {
        key
        message
      }
      patientMember {
        id
        patient {
          id
        }
      }
    }
  }
`;

interface MutationData {
  createPatientMember: {
    errors?: InputError[];
    patientMember?: {
      id: string;
      patient: {
        id: string;
      };
    };
  };
}

interface PatientMemberInput {
  firstName: string;
  lastName: string;
  primaryPhone: string;
  dob: string;
  medicalRecordNumber: string;
  ssn: string;
}

interface MutationVariables {
  input: PatientMemberInput;
}

type FormValues = PatientMemberInput;

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    primaryPhone: Yup.string()
      .required("Required")
      .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, "Invalid phone number"),
    dob: Yup.string().required("Required"),
  })
  .required();

interface NewPatientMemberFormProps {
  onSuccess(patientMemberId: string): void;
};

export const NewPatientMemberForm: FC<NewPatientMemberFormProps> = props => {
  const { onSuccess } = props;

  const [createPatientMember] = useMutation<MutationData, MutationVariables>(CREATE_PATIENT_MEMBER);

  const onSubmit = useCallback(
    async (values, formikHelpers) => {
      const { setStatus, setSubmitting } = formikHelpers;

      setStatus({ errors: null });

      const variables = {
        input: {
          ...values,
          dob: localDateToISO(values.dob)
        }
      }

      try {
        const { data } = await createPatientMember({ variables });

        if (data?.createPatientMember.errors) {
          setStatus({ errors: data.createPatientMember.errors });
        } else if (data?.createPatientMember.patientMember) {
          // it worked...
          toast.success("Patient added");
          analytics.track("Patient Added", { source: 'Patients Page' });
          return onSuccess(data.createPatientMember.patientMember.id);
        }
        setSubmitting(false);
      } catch (e) {
        toast.error("Something went wrong.");
        console.error(e);
        setSubmitting(false);
      }
    }, []);

  return (
    <div className="_NewPatientMemberForm">
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          primaryPhone: "",
          dob: "",
          medicalRecordNumber: "",
          ssn: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <TextField
              name="medicalRecordNumber"
              label="MRN"
              indicateOptional
            />

            <div className="flex flex-wrap -mx-3 mt-3">
              <div className="w-full md:w-1/2 px-3">
                <TextField name="firstName" label="First Name" autoFocus />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <TextField name="lastName" label="Last Name" />
              </div>
            </div>

            <div className="mt-3">
              <DateMaskField
                name="dob"
                label="Date of Birth (mm/dd/yyyy)"
                placeholder="mm/dd/yyyy"
              />
            </div>

            <div className="mt-3">
              <PhoneMaskField
                name="primaryPhone"
                label="Primary Phone"
                placeholder="(___) ___-____"
              />
            </div>

            <div className="mt-3">
              <TextField name="ssn" label="SSN" indicateOptional />
            </div>

            <div className="flex items-center justify-end mt-3 py-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-blue"
              >
                Create Patient
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
