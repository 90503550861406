import { FC } from "react";
import {
  ResponsiveContainer,
  LineChart as RCLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

type LineChartProps = {
  data: { [x in string]: any }[];
  xValKey: string;
  yValKey: string;
  yValLabel: string;
};

// const data = [
//   { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
//   { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
//   { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
//   { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
//   { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
//   { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
//   { name: "Page G", uv: 3490, pv: 4300, amt: 2100 }
// ];

export const LineChart: FC<LineChartProps> = ({
  data,
  xValKey,
  yValKey,
  yValLabel,
}) => {
  return (
    <ResponsiveContainer width="100%" aspect={5.0 / 3.0}>
      <RCLineChart data={data}>
        <XAxis dataKey={xValKey} />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          name={yValLabel}
          dataKey={yValKey}
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </RCLineChart>
    </ResponsiveContainer>
  );
};
