import { FC, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { gql, useQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import {
  TextField,
  StandardOption,
  Button,
  TextMaskField,
  SelectField,
} from "@preferral/ui";
import uniqBy from "lodash/uniqBy";
import { analytics } from "lib/analytics";

const SPECIALTIES = gql`
  query NewProviderSpecialties {
    specialties(filter: { curated: true }) {
      id
      name
      taxonomyCode
    }
    me {
      id
      organization {
        id
        departments {
          id
          departmentSpecialties {
            id
            specialty {
              id
              name
            }
          }
        }
      }
    }
  }
`;

interface SpecialtiesData {
  specialties: {
    id: string;
    name: string;
    taxonomyCode: string;
  }[];
  me: {
    id: string;
    organization: {
      id: string;
      departments: {
        id: string;
        departmentSpecialties: {
          id: string;
          specialty: {
            id: string;
            name: string;
          };
        }[];
      }[];
    };
  };
}

const CREATE_PROVIDER = gql`
  mutation CreateProviderAndEmployment($input: CreateProviderInput!) {
    createProviderAndEmployment(input: $input) {
      errors {
        key
        message
      }
      provider {
        id
      }
    }
  }
`;

interface CreateProviderData {
  createProviderAndEmployment: {
    errors?: InputError[];
    provider?: {
      id: string;
    };
  };
}

interface CreateProviderInput {
  npi: string;
  prefix?: string;
  firstName: string;
  middleInitial?: string;
  lastName: string;
  suffix?: string;
  credentialText?: string;
  gender?: string;
  receiveProviderRequests?: boolean;
  receivePatientRequests?: boolean;
  specialtyIds?: string[];
}

interface CreateProviderVariables {
  input: CreateProviderInput;
}

interface FormValues {
  firstName: string;
  lastName: string;
  npi: string;
  primarySpecialtyId: string;
  specialtyIds: string[];
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    npi: Yup.string().required("Required"),
    primarySpecialtyId: Yup.string(),
    specialtyIds: Yup.array().of(Yup.string().required("Required")),
  })
  .required();

const initialValues: FormValues = {
  firstName: "",
  lastName: "",
  npi: "",
  primarySpecialtyId: "",
  specialtyIds: [],
};

const npiMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

/**
 * CreateProviderForm.
 */

interface CreateProviderFormProps {
  onSuccess(): void;
}

export const CreateProviderForm: FC<CreateProviderFormProps> = props => {
  const { onSuccess } = props;

  const { data, loading } = useQuery<SpecialtiesData>(SPECIALTIES);

  const [createProvider] = useMutation<
    CreateProviderData,
    CreateProviderVariables
  >(CREATE_PROVIDER);

  const specialtyOptions = getSpecialtyOptions(data);

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      try {
        const { data } = await createProvider({ variables: { input: values } });

        if (data?.createProviderAndEmployment.errors) {
          setStatus({ errors: data.createProviderAndEmployment.errors });
        } else if (data?.createProviderAndEmployment.provider) {
          // it worked...
          analytics.track("Create Provider", {
            provider_name: `${values.firstName} ${values.lastName}`,
            provider_npi: values.npi,
            page_url: window.location.href,
          });
          toast.success("Success!");
          onSuccess();
        }
      } catch (e) {
        console.error(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
      } finally {
        setSubmitting(false);
      }
    },
    [onSuccess, createProvider]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, status, errors }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          <div className="mt-3">
            <TextMaskField
              name="npi"
              label="NPI"
              mask={npiMask}
              placeholder="NPI"
            />
          </div>

          <div className="mt-3 grid grid-cols-2 gap-2">
            <TextField name="firstName" label="First name" />
            <TextField name="lastName" label="Last name" />
          </div>

          <div className="mt-3">
            <SelectField
              name="primarySpecialtyId"
              options={specialtyOptions}
              isLoading={loading}
              label="Primary Specialty"
            />
          </div>

          <div className="flex items-center justify-end mt-3">
            <Button
              type="submit"
              kind="primary"
              color="blue"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Create Provider
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

function getSpecialtyOptions(data?: SpecialtiesData): StandardOption[] {
  const deptSpecialties =
    data?.me.organization.departments
      .map(d => d.departmentSpecialties.map(ds => ds.specialty))
      .flat() || [];

  if (!(data?.specialties.length || deptSpecialties.length)) {
    return [];
  } else {
    const specialtyOptions =
      data?.specialties.map(s => ({ value: s.id, label: s.name })) || [];
    const deptSpecialtyOptions = deptSpecialties
      .map(
        ds =>
          ds && {
            value: ds.id,
            label: ds.name,
          }
      )
      .filter(Boolean) as StandardOption[];

    return uniqBy(specialtyOptions.concat(deptSpecialtyOptions), "value").sort(
      (o1: StandardOption, o2: StandardOption) =>
        o1.label.localeCompare(o2.label)
    );
  }
}
