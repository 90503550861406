import { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Button,
  HorizontalField,
  HorizontalPhoneMaskField,
  HorizontalTextAreaField,
  HorizontalTextField,
  SingleCheckboxInput,
} from "@preferral/ui";
import { Formik } from "formik";
import * as Yup from "yup";
import { analytics } from "lib/analytics";

const SUBMIT_INBOUND_REFERRAL_CONFIRMATION = gql`
  mutation CreateAppointmentRequestForInboundReferral(
    $input: CreateAppointmentRequestInput!
  ) {
    createAppointmentRequest(input: $input) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
      }
    }
  }
`;

interface MutationData {
  createAppointmentRequest: {
    errors?: InputError[];
    appointmentRequest?: AppointmentRequestModel;
  };
}

interface MutationVariables {
  input: CreateAppointmentRequestInput;
}

export interface AppointmentRequestModel {
  id: string;
}

interface CreateAppointmentRequestInput {
  inboundReferralId: string;
  additionalNotes?: string;
  patientMember: {
    email?: string;
    patient: {
      primaryPhone: string;
      secondaryPhone?: string;
      consentToTextMessage: boolean;
    };
  };
}

interface FormValues {
  primaryPhone: string;
  secondaryPhone?: string;
  email?: string;
  consentToTextMessage: boolean;
  additionalNotes?: string;
}

const formattedPhoneRegex = /^\(\d{3}\)\s\d{3}-\d{4}/;
const emailRegex = /^\S+@\S+\.\S+/;

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    primaryPhone: Yup.string()
      .trim()
      .matches(formattedPhoneRegex, "Invalid phone number")
      .required("Required"),
    secondaryPhone: Yup.string()
      .trim()
      .matches(formattedPhoneRegex, "Invalid phone number"),
    email: Yup.string().trim().matches(emailRegex, "Invalid email"),
    consentToTextMessage: Yup.bool(),
    additionalNotes: Yup.string().trim(),
  })
  .required();

interface ConfirmationFormProps {
  inboundReferralId: string;
  initialValues: FormValues;
  onSuccess(appointmentRequest: AppointmentRequestModel): void;
}

export const ConfirmationForm: FC<ConfirmationFormProps> = (props) => {
  const { inboundReferralId, initialValues, onSuccess } = props;

  const [confirmReferral] = useMutation<MutationData, MutationVariables>(
    SUBMIT_INBOUND_REFERRAL_CONFIRMATION
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setSubmitting, setStatus } = formikActions;
      setStatus({ errors: null });

      const input: CreateAppointmentRequestInput = {
        inboundReferralId,
        additionalNotes: values.additionalNotes,
        patientMember: {
          email: values.email,
          patient: {
            primaryPhone: values.primaryPhone,
            secondaryPhone: values.secondaryPhone,
            consentToTextMessage: values.consentToTextMessage,
          },
        },
      };

      return confirmReferral({
        variables: { input },
      }).then(
        (resp) => {
          if (resp.data?.createAppointmentRequest.errors) {
            setStatus({ errors: resp.data.createAppointmentRequest.errors });
          } else if (resp.data?.createAppointmentRequest.appointmentRequest) {
            // it worked...
            const { appointmentRequest } = resp.data.createAppointmentRequest;
            analytics.track("Referral Created", { type: 'Inbound' });
            return onSuccess(appointmentRequest);
          }
          setSubmitting(false);
        },
        (rej) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong." }],
          });
          setSubmitting(false);
        }
      );
    },
    [inboundReferralId, confirmReferral, onSuccess]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <form className="pt-5" onSubmit={handleSubmit}>
          <HorizontalPhoneMaskField
            icon="phone"
            name="primaryPhone"
            label="Patient Phone"
          />
          <div className="mt-3">
            <HorizontalPhoneMaskField
              icon="phone"
              name="secondaryPhone"
              label="Secondary Phone"
              indicateOptional
            />
          </div>

          <div className="mt-3">
            <HorizontalField label="">
              <SingleCheckboxInput
                name="consentToTextMessage"
                checkboxLabel={
                  <p>
                    Patient consents to receiving care related text messages.
                  </p>
                }
              />
            </HorizontalField>
          </div>
          <div className="mt-3">
            <HorizontalTextField
              icon="envelope"
              name="email"
              label="Patient Email"
              indicateOptional
            />
          </div>
          <div className="mt-3">
            <HorizontalTextAreaField
              name="additionalNotes"
              label="Additional Notes"
              indicateOptional
            />
          </div>
          <div className="text-center p-4">
            <Button
              type="submit"
              color="blue"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Submit Referral
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
