import { FC } from 'react';
import { FilterForm, FilterField, SelectInput, Button } from '@preferral/ui';
import { gql, useQuery } from "@apollo/client";

const LIST_SPECIALTIES = gql`
  query ListSpecialties {
    specialties(filter: { curated: true }) {
      id
      name
    }
  }
`;

interface Data {
  specialties: {
    id: string;
    name: string;
  }[];
}

export interface FilterModel {
  primarySpecialtyId?: string;
}

export const defaultFilter: FilterModel = {
  primarySpecialtyId: "ALL"
}

const allOption = {
  value: "ALL",
  label: "All"
};

interface FilterPanelProps {
  value: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading: boolean;
}

export const FilterPanel: FC<FilterPanelProps> = props => {
  const { value = defaultFilter, onChange, isLoading } = props;

  const { data, loading } = useQuery<Data>(LIST_SPECIALTIES);

  const specialtyOptions = [
    allOption,
    ...(data?.specialties.map(s => ({ value: s.id, label: s.name })) || [])
  ];

  return (
    <FilterForm<FilterModel>
      defaultValue={defaultFilter}
      value={value}
      onChange={onChange}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="primarySpecialtyId" icon="filter" label="Specialty">
          <div className="w-64">
            <SelectInput
              name="primarySpecialtyId"
              options={specialtyOptions}
              isLoading={loading}
            />
          </div>
        </FilterField>

        <FilterField>
          <Button
            type="submit"
            color="blue"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Filter
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  )
}
