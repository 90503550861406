import { FC } from "react";
import { useField, ErrorMessage } from "formik";
import { VerticalField, HorizontalField } from "@preferral/ui";
import { HealthPlanPicker, HealthPlanValue } from "components/HealthPlanPicker";
import { useCallback } from "react";

export interface HealthPlanPickerInputProps {
  id?: string;
  disabled?: boolean;
  name: string;
}

export const HealthPlanPickerInput: FC<HealthPlanPickerInputProps> = (
  props
) => {
  const { id, disabled, name } = props;
  const [, , helpers] = useField(name);
  const { setValue } = helpers;

  const handleChange = useCallback(
    (value: HealthPlanValue) => {
      setValue(value);
    },
    [setValue]
  );

  return (
    <>
      <HealthPlanPicker id={id} onChange={handleChange} disabled={disabled} />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
};

export interface HealthPlanPickerFieldProps extends HealthPlanPickerInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const HealthPlanPickerField: FC<HealthPlanPickerFieldProps> = (
  props
) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <HealthPlanPickerInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalHealthPlanPickerField: FC<HealthPlanPickerFieldProps> = (
  props
) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <HealthPlanPickerInput {...rest} />
    </HorizontalField>
  );
};
