import React, { FC, useState } from "react";
import cx from "classnames";
import { useRouteMatch } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { Spinner } from "components/Spinner";
import { FAIcon } from "components/FAIcon";

const LOGIN_ATTEMPTS_QUERY = gql`
  query GetOrganizationUser($id: UUID4!) {
    organizationUser(id: $id) {
      id
      firstName
      lastName
      confirmedAt
      role {
        id
        name
      }
      loginAttempts {
        id
        insertedAt
        successful
        requestInfo {
          remoteIp
          userAgent
          clientLocation
          latitude
          longitude
        }
      }
    }
  }
`;

type LoginAttempt = {
  id: string;
  insertedAt: string;
  successful: boolean;
  requestInfo: {
    remoteIp: string;
    userAgent: string;
    clientLocation?: string;
    latitude?: number;
    longitude?: number;
  };
};

interface Data {
  organizationUser: {
    id: string;
    firstName: string;
    lastName: string;
    confirmedAt: void | string;
    role: {
      id: string;
      name: string;
    };
    loginAttempts: LoginAttempt[];
  };
}

interface Variables {
  id: string;
}

type LoginAttemptDetailsProps = {
  loginAttempt: LoginAttempt;
};

const LoginAttemptDetails: React.FC<LoginAttemptDetailsProps> = ({
  loginAttempt
}) => (
  <div>
    <ul>
      <li>
        <strong>ID</strong>: {loginAttempt.id}
      </li>
      <li>
        <strong>Time</strong>: {loginAttempt.insertedAt}
      </li>
      <li>
        <strong>Success / Failure</strong>:{" "}
        {loginAttempt.successful ? "Success" : "Failure"}
      </li>
      <li>
        <strong>User Agent</strong>: {loginAttempt.requestInfo.userAgent}
      </li>
      <li>
        <strong>Remote IP</strong>: {loginAttempt.requestInfo.remoteIp}
      </li>
      <li>
        <strong>Location</strong>: {loginAttempt.requestInfo.clientLocation}
      </li>
    </ul>
  </div>
);

interface LoginAttemptsProps {}

export const LoginAttempts: FC<LoginAttemptsProps> = () => {
  const [selectedAttempt, selectAttempt] = useState<LoginAttempt | null>(null);
  const match = useRouteMatch<{ userId: string }>();
  const { userId } = match.params;
  const { data, loading, error } = useQuery<Data, Variables>(
    LOGIN_ATTEMPTS_QUERY,
    { variables: { id: userId } }
  );

  return error ? (
    <h1>Error</h1>
  ) : loading ? (
    <div className="p-12 text-center">
      <Spinner />
    </div>
  ) : data && data.organizationUser.loginAttempts.length === 0 ? (
    <div className="has-text-centered" style={{ padding: "1rem 0 3rem" }}>
      <p style={{ fontSize: 72, color: "#ccc", margin: "1rem 0" }}>
        ¯\_(ツ)_/¯
      </p>
      <h3 className="subtitle is-3">No Login Attempts</h3>
      <p>There are no login attempts for this user's account yet.</p>
    </div>
  ) : (
    <div className="LoginAttempts columns">
      <div className="column">
        <table className="table is-fullwidth">
          <tbody>
            {data &&
              data.organizationUser.loginAttempts.map(
                (loginAttempt: LoginAttempt) => (
                  <tr
                    className={cx({
                      success: loginAttempt.successful,
                      failure: !loginAttempt.successful,
                      "is-active":
                        selectedAttempt &&
                        selectedAttempt.id === loginAttempt.id
                    })}
                    key={loginAttempt.id}
                    onClick={() => selectAttempt(loginAttempt)}
                  >
                    <td>
                      <FAIcon
                        icon={
                          loginAttempt.successful
                            ? "check-circle"
                            : "times-circle"
                        }
                      />
                    </td>
                    <td>
                      {loginAttempt.requestInfo.clientLocation
                        ? loginAttempt.requestInfo.clientLocation
                        : "Unknown Location"}
                    </td>
                    <td>
                      {format(
                        parseISO(loginAttempt.insertedAt),
                        "MM/dd/yy - H:mm a"
                      )}
                    </td>
                    <td>{loginAttempt.requestInfo.remoteIp}</td>
                  </tr>
                )
              )}
          </tbody>
        </table>
      </div>
      <div className="column is-vcentered">
        {selectedAttempt === null ? (
          <pre>Select a login attempt to view details</pre>
        ) : (
          <LoginAttemptDetails loginAttempt={selectedAttempt} />
        )}
      </div>
    </div>
  );
};
