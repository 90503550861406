import React from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField, SelectField } from "@preferral/ui";
import toast from "react-hot-toast";
import { analytics } from "lib/analytics";

const CREATE_SPECIALTY = gql`
  mutation CreateCustomSpecialty(
    $departmentId: UUID4!
    $input: SpecialtyInput!
  ) {
    createCustomSpecialty(departmentId: $departmentId, input: $input) {
      errors {
        key
        message
      }
      specialty {
        id
        name
      }
    }
  }
`;

interface MutationData {
  createCustomSpecialty: {
    errors?: InputError[];
    specialty?: {
      id: string;
      naem: string;
    };
  };
}

interface CreateSpecialtyInput {
  name: string;
}

interface MutationVariables {
  departmentId: string;
  input: CreateSpecialtyInput;
}

type NewSpecialtyFormProps = {
  departmentId: string;
  refetchQueries: any;
  closeModal: () => void;
};

const ageOptions = [
  { value: "any", label: "Any Age" },
  { value: "pediatric", label: "Pediatric" },
  { value: "adult", label: "Adult" },
];

const NewSpecialtyForm: React.FC<NewSpecialtyFormProps> = ({
  departmentId,
  refetchQueries,
  closeModal,
}) => {
  const [createSpecialty] = useMutation<MutationData, MutationVariables>(
    CREATE_SPECIALTY
  );
  return (
    <div className="NewSpecialtyForm">
      <Formik
        initialValues={{
          name: "",
          taxonomyCode: "",
          ageClassification: "any",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .trim()
            .required("Required"),
          ageClassification: Yup.string().oneOf(["any", "pediatric", "adult"]),
        })}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setStatus({ errors: null });
          createSpecialty({
            variables: { departmentId, input: values },
            refetchQueries,
          }).then(
            (resp) => {
              if (resp && resp.data && resp.data.createCustomSpecialty.errors) {
                setStatus({ errors: resp.data.createCustomSpecialty.errors });
              } else if (
                resp &&
                resp.data &&
                resp.data.createCustomSpecialty.specialty
              ) {
                // it worked
                analytics.track("Create Specialty", {
                  name: values.name,
                });
                toast.success("Specialty Created");
                closeModal();
              }
              setSubmitting(false);
            },
            (rej) => {
              setStatus({
                errors: [{ key: "", message: "Something went wrong" }],
              });
              setSubmitting(false);
            }
          );
        }}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />
            <div className="mt-3">
              <TextField name="name" label="Name" autoFocus />
            </div>
            <div className="mt-3">
              <TextField
                name="taxonomyCode"
                label="Taxonomy Code"
                indicateOptional
              />
            </div>
            <div className="mt-3">
              <SelectField
                name="ageClassification"
                label="Age Classification"
                options={ageOptions}
              />
            </div>
            <div className="flex items-center justify-end mt-3 py-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-blue"
              >
                Create Specialty
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { NewSpecialtyForm };
