import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { NewPacketForm } from "./NewPacketForm";

interface NewPacketModalProps {
  departmentId: string;
  isOpen: boolean;
  onClose(): void;
  refetch(): void;
}

export const NewPacketModal: FC<NewPacketModalProps> = (props) => {
  const { departmentId, isOpen, onClose, refetch } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="envelope-open-text"
        title="New Patient Packet"
        onClose={onClose}
      />

      <div className="p-4">
        <NewPacketForm
          departmentId={departmentId}
          onSuccess={() => {
            refetch();
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};
