import React from "react";
import styled from "styled-components";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";

type NavTabsProps = {};

const NavTabBar = styled.div`
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06),
    inset 0 -1px 1px 0 rgba(0, 0, 0, 0.07);
`;

const NavTabs: React.FC<NavTabsProps> = ({ children }) => {
  return (
    <NavTabBar className="flex w-full bg-cool-gray-200 text-cool-gray-500">
      {children}
    </NavTabBar>
  );
};

type NavTabLinkProps = {
  to: string;
  activeClassName?: string;
} & RouteComponentProps;

const _NavTabLink: React.FC<NavTabLinkProps> = ({
  to,
  match,
  children,
  activeClassName = "bg-cool-gray-100",
}) => {
  return (
    <NavLink
      to={to}
      className="tab-link border-cool-gray-400 border-r font-semibold px-12 py-4 semibold tab-link text-lg"
      activeClassName={`tab-link--active text-blue-600 ${activeClassName}`}
    >
      {children}
    </NavLink>
  );
};

const NavTabLink = withRouter(_NavTabLink);

export { NavTabs, NavTabLink };
