import { FC, useCallback } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Button, DateMaskField, TextField } from '@preferral/ui';
import { localDateToISO, ISODateToLocal, removeVoidKeys } from "@preferral/common";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Spinner } from "components/Spinner";
import { Modal, ModalHeader } from "components/Modal";

const PATIENT_PACKET_SUBMISSION = gql`
  query GetPatientPacketSubmission($id: UUID4!) {
    patientPacketSubmission(id: $id) {
      id
      title
      patientFirstName
      patientLastName
      patientDob
      appointmentDate
    }
  }
`;

interface Data {
  patientPacketSubmission: PatientPacketSubmission;
}

interface Variables {
  id: string;
}

const UPDATE_PATIENT_PACKET_SUBMISSION = gql`
  mutation UpdatePatientPacketSubmission($id: UUID4!, $input: PatientPacketSubmissionInput!) {
    updatePatientPacketSubmission(id: $id, input: $input) {
      errors {
        key
        message
      }
      patientPacketSubmission {
        id
        patientFirstName
        patientLastName
        patientDob
        appointmentDate
      }
    }
  }
`;

interface MutationData {
  updatePatientPacketSubmission: {
    errors?: InputError[];
    patientPacketSubmission?: PatientPacketSubmission;
  };
}

interface MutationVariables {
  id: string;
  input: Partial<FormValues>;
}

interface PatientPacketSubmission {
  id: string;
  title: string | null;
  patientFirstName: string | null;
  patientLastName: string | null;
  patientDob: string | null;
  appointmentDate: string | null;
}

interface FormValues {
  patientFirstName: string;
  patientLastName: string;
  patientDob: string;
  appointmentDate: string;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    patientFirstName: Yup.string().trim(),
    patientLastName: Yup.string().trim(),
    patientDob: Yup.string().trim(),
    appointmentDate: Yup.string().trim()
  })
  .required();

interface EditPacketModalProps {
  patientPacketSubmissionId: string;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
};

export const EditPacketModal: FC<EditPacketModalProps> = props => {
  const { patientPacketSubmissionId, isOpen, onClose, onSuccess } = props;
  const { data, loading, error } = useQuery<Data, Variables>(PATIENT_PACKET_SUBMISSION, { variables: { id: patientPacketSubmissionId } });

  const [updatePacket] = useMutation<MutationData, MutationVariables>(UPDATE_PATIENT_PACKET_SUBMISSION);

  const onSubmit = useCallback(
    async (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      const input = removeVoidKeys<FormValues>({
        ...values,
        patientDob: values.patientDob ? localDateToISO(values.patientDob) : "",
        appointmentDate: values.appointmentDate ? localDateToISO(values.appointmentDate) : "",
      });

      try {
        const { data } = await updatePacket({ variables: { id: patientPacketSubmissionId, input } });

        if (data?.updatePatientPacketSubmission.errors) {
          setStatus({ errors: data.updatePatientPacketSubmission.errors });
        } else if (data?.updatePatientPacketSubmission.patientPacketSubmission) {
          // it worked
          toast.success("Packet updated");
          onSuccess();
          return onClose();
        }
      } catch (e) {
        console.log(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong" }] });
      }
      setSubmitting(false);
    },
    [updatePacket, patientPacketSubmissionId, onSuccess, onClose]
  );

  return (
    <Modal className="_EditPacketModal" isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="pencil-alt" title="Edit Packet" onClose={onClose} />
      {loading ? (
        <div className="p-12 text-center">
          <Spinner />
        </div>
      ) : (error || !data?.patientPacketSubmission) ? (
        <p>Failed to load</p>
      ) : (
        <div className="p-4">

          {data.patientPacketSubmission.title ? (
            <p className="font-semibold pb-6 text-lg text-purple-500">Packet: {data.patientPacketSubmission.title}</p>
          ) : null}

          <Formik<FormValues>
            initialValues={{
              patientFirstName: data.patientPacketSubmission.patientFirstName || "",
              patientLastName: data.patientPacketSubmission.patientLastName || "",
              patientDob: data.patientPacketSubmission.patientDob ? ISODateToLocal(data.patientPacketSubmission.patientDob) : "",
              appointmentDate: data.patientPacketSubmission.appointmentDate ? ISODateToLocal(data.patientPacketSubmission.appointmentDate) : ""
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormStatusErrors status={status} />

                <div className="grid grid-cols-2 gap-2">
                  <TextField
                    name="patientFirstName"
                    label="Patient First Name"
                  />
                  <TextField
                    name="patientLastName"
                    label="Patient Last Name"
                  />
                </div>

                <div className="mt-3">
                  <DateMaskField
                    name="patientDob"
                    label="Patient Date of Birth"
                  />
                </div>

                <div className="mt-3">
                  <DateMaskField
                    name="appointmentDate"
                    label="Appointment Date"
                  />
                </div>

                <div className="flex items-center justify-end mt-3 py-4">
                  <Button
                    type="submit"
                    kind="primary"
                    color="blue"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </Modal>
  );
};
