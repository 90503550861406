import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import uniqBy from "lodash.uniqby";
import {
  FilterForm,
  FilterField,
  Button,
  StandardOption,
  SelectInput,
} from "@preferral/ui";
import { sortBy } from "@preferral/common";

export interface FilterModel {
  inboxId?: string;
  assignedReceivingUserId?: string;
  requestedSpecialtyId?: string;
  requestedLocationId?: string;
  requestedProviderId?: string;
  isOpen?: boolean;
  createdBefore?: Date;
  createdAfter?: Date;
}

const unassignedOption: StandardOption = {
  value: "UNASSIGNED",
  label: "UNASSIGNED",
};

const INBOX_FILTER_DATA = gql`
  query InboxFilterData($inboxId: UUID4!) {
    inbox(id: $inboxId) {
      id
      department {
        id
        users {
          id
          name
        }
        locations {
          id
          name
        }
        providers {
          id
          nameWithAppellation
        }
        departmentSpecialties {
          id
          specialty {
            id
            name
          }
        }
      }
    }
  }
`;

interface Data {
  inbox: {
    id: string;
    department: {
      id: string;
      users: {
        id: string;
        name: string;
      }[];
      locations: {
        id: string;
        name: string;
      }[];
      providers: {
        id: string;
        nameWithAppellation: string;
      }[];
      departmentSpecialties: {
        id: string;
        specialty: {
          id: string;
          name: string;
        };
      }[];
    };
  };
}

interface Variables {
  inboxId: string;
}

interface FilterPanelProps {
  inboxId: string;
  value: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading?: boolean;
}

export const FilterPanel: FC<FilterPanelProps> = props => {
  const { inboxId, value, onChange, isLoading } = props;

  const { data, loading } = useQuery<Data, Variables>(INBOX_FILTER_DATA, {
    variables: { inboxId },
  });

  const defaultFilter: FilterModel = { inboxId };

  const specialtyOpts = [
    unassignedOption,
    ...specialtyOptions(data),
  ] as StandardOption[];

  const userOpts = [
    unassignedOption,
    ...assignedUserOptions(data),
  ] as StandardOption[];

  const locationOpts = [
    unassignedOption,
    ...locationOptions(data),
  ] as StandardOption[];

  const providerOpts = [
    unassignedOption,
    ...providerOptions(data),
  ] as StandardOption[];

  return (
    <FilterForm<FilterModel>
      defaultValue={defaultFilter}
      value={value}
      onChange={(values: FilterModel) =>
        onChange(removeVoidKeys(values as any))
      }
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="assignedReceivingUserId" icon="filter" label="Assigned To">
          <div className="w-48">
            <SelectInput
              name="assignedReceivingUserId"
              options={userOpts}
              isLoading={loading}
              placeholder="Any"
              isClearable
            />
          </div>
        </FilterField>

        <FilterField
          htmlFor="requestedSpecialtyId"
          icon="filter"
          label="Specialty"
        >
          <div className="w-48">
            <SelectInput
              name="requestedSpecialtyId"
              options={specialtyOpts}
              isLoading={loading}
              placeholder="Any"
              isClearable
            />
          </div>
        </FilterField>

        <FilterField
          htmlFor="requestedLocationId"
          icon="filter"
          label="Location"
        >
          <div className="w-48">
            <SelectInput
              name="requestedLocationId"
              options={locationOpts}
              isLoading={loading}
              placeholder="Any"
              isClearable
            />
          </div>
        </FilterField>

        <FilterField
          htmlFor="requestedProviderId"
          icon="filter"
          label="Provider"
        >
          <div className="w-48">
            <SelectInput
              name="requestedProviderId"
              options={providerOpts}
              isLoading={loading}
              placeholder="Any"
              isClearable
            />
          </div>
        </FilterField>

        <FilterField>
          <Button
            type="submit"
            color="blue"
            disabled={loading || isLoading}
            isLoading={isLoading}
          >
            Apply
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};

function specialtyOptions(data: Data | void): StandardOption[] {
  if (!data) {
    return [];
  } else {
    const rawOptions: StandardOption[] =
      data.inbox.department.departmentSpecialties
        .map(ds => ds.specialty)
        .flat()
        .map(s => ({
          value: s!.id,
          label: s!.name,
        })) || [];
    return sortBy<StandardOption>(
      uniqBy<StandardOption>(rawOptions, "label"),
      "label"
    );
  }
}

function assignedUserOptions(data: Data | void): StandardOption[] {
  if (!data) {
    return [];
  } else {
    const rawOptions: StandardOption[] = data.inbox.department.users.map(u => ({
      value: u.id,
      label: u.name,
    }));
    return sortBy(uniqBy(rawOptions, "value"), "label");
  }
}

function locationOptions(data: Data | void): StandardOption[] {
  if (!data) {
    return [];
  } else {
    const rawOptions: StandardOption[] = data.inbox.department.locations.map(
      l => ({
        value: l.id,
        label: l.name,
      })
    );
    return sortBy(uniqBy(rawOptions, "value"), "label");
  }
}

function providerOptions(data: Data | void): StandardOption[] {
  if (!data) {
    return [];
  } else {
    const rawOptions: StandardOption[] = data.inbox.department.providers.map(
      p => ({
        value: p.id,
        label: p.nameWithAppellation,
      })
    );
    return sortBy(uniqBy(rawOptions, "value"), "label");
  }
}

function removeVoidKeys(
  filterWithNulls: Record<keyof FilterModel, any>
): FilterModel {
  let filter = {};
  for (const key in filterWithNulls) {
    if (filterWithNulls[key] !== null && filterWithNulls[key] !== undefined) {
      filter[key] = filterWithNulls[key];
    }
  }
  return filter;
}
