import React, { FC } from "react";
import { ScreenTitle } from "context/ScreenTitle";

interface CalendarScreenProps {}

export const CalendarScreen: FC<CalendarScreenProps> = () => {
  return (
    <div className="CalendarScreen">
      <ScreenTitle title="Calendar" />
      <p>CalendarScreen</p>
    </div>
  );
};
