import { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { TextField, Button } from "@preferral/ui";
import { useAuth } from "context/AuthContext";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { AMD_LOGIN_SERVICE_ORIGIN } from "config/constants";
import { analytics } from "lib/analytics";

const amdRedirect = encodeURIComponent(
  `https://${window.location.host}/auth/amd_sso`
);
const amdLoginUrl = `${AMD_LOGIN_SERVICE_ORIGIN}/signin?redirect=${amdRedirect}`;

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      errors {
        key
        message
      }
      session {
        token
      }
    }
  }
`;

interface Data {
  login: {
    errors?: InputError[];
    session?: {
      token: string;
    };
  };
}

interface Variables {
  email: string;
  password: string;
}

interface FormValues {
  email: string;
  password: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    email: Yup.string().trim().email().required("Required"),
    password: Yup.string().required("Required"),
  })
  .required();

interface SignInFormProps {}

export const SignInForm: FC<SignInFormProps> = () => {
  const { login: signIn } = useAuth();
  const [login] = useMutation<Data, Variables>(LOGIN);

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: any) => {
      const { setStatus, setSubmitting } = formikActions;
      setStatus({ errors: null });

      const variables = values;

      try {
        const { data } = await login({ variables });
        if (data?.login.errors) {
          setStatus({ errors: data.login.errors });
        } else if (data?.login?.session) {
          // it worked
          analytics.track("Login");
          const { token } = data.login.session;
          // onSignIn(token);
          return signIn(token);
        }
      } catch (e) {
        toast.error("Something went wrong.");
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    },
    [login, signIn]
  );

  return (
    <>
      <div className="_SignInForm bg-white py-8 px-4 shadow-xl sm:rounded-2xl sm:px-10">
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ status, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <TextField
                name="email"
                type="email"
                label="Email"
                placeholder="you@organization.com"
                autoFocus
              />

              <div className="mt-3">
                <TextField
                  name="password"
                  placeholder="••••••••••"
                  type="password"
                  label="Password"
                />
              </div>

              <div className="mt-8">
                <span className="block w-full rounded-md shadow-sm">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    color="blue"
                    className="w-full justify-center"
                  >
                    Sign in
                  </Button>
                </span>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="mt-4 text-sm">
        <p className="leading-5 max-w mt-1 text-center text-gray-600 text-sm">
          Using SSO?{" "}
          <Link
            to="/auth/sso"
            className="inline-block ml-2 text-blue-500 hover:text-blue-700 font-semibold"
          >
            Sign in with SSO
          </Link>
        </p>
        <p className="leading-5 mt-1 text-center text-gray-600 text-sm">
          Using eConsults?{" "}
          <a
            href={amdLoginUrl}
            className="inline-block ml-2 text-blue-500 hover:text-blue-700 font-semibold"
          >
            Sign in with eConsult Login
          </a>
        </p>
        <p className="leading-5 max-w mt-1 text-center text-gray-600 text-sm">
          <Link
            to="/auth/forgot_password"
            className="inline-block ml-2 text-blue-500 hover:text-blue-700 font-semibold"
          >
            Forgot password?
          </Link>
        </p>
      </div>
    </>
  );
};
