import { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Formik, FieldArray } from "formik";
import toast from "react-hot-toast";
import ArrowUpIcon from '@heroicons/react/outline/ArrowUpIcon'
import ArrowDownIcon from '@heroicons/react/outline/ArrowDownIcon'
import {
  Button,
  FAIcon,
  TextField,
  VerticalField,
  SelectInput,
} from "@preferral/ui";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { PatientFormSelectOption } from "components/PatientFormOption";

const FORMS = gql`
  query GetDepartmentPatientForms($departmentId: UUID4!) {
    department(id: $departmentId) {
      id
      name
      patientForms {
        id
        title
        insertedAt
        updatedAt
      }
    }
  }
`;

interface Data {
  department: {
    id: string;
    name: string;
    patientForms: PatientFormModel[];
  };
}

interface Variables {
  departmentId: string;
}

interface PatientFormModel {
  id: string;
  title: string;
  insertedAt: string;
  updatedAt: string;
}

const CREATE_PATIENT_PACKET = gql`
  mutation CreatePatientPacket(
    $departmentId: UUID4!
    $input: PatientPacketInput!
  ) {
    createPatientPacket(departmentId: $departmentId, input: $input) {
      errors {
        key
        message
      }
      patientPacket {
        id
      }
    }
  }
`;

interface MutationData {
  createPatientPacket: {
    errors?: InputError[];
    patientPacket?: {
      id: string;
    };
  };
}

interface MutationVariables {
  departmentId: string;
  input: PatientPacketInput;
}

interface PatientPacketInput {
  title: string;
  patientFormIds: (string | void)[];
}

type FormValues = PatientPacketInput;

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    title: Yup.string().required("Required"),
    patientFormIds: Yup.array(Yup.string()).required("Required"),
  })
  .required();

interface NewPacketFormProps {
  departmentId: string;
  onSuccess(): void;
}

export const NewPacketForm: FC<NewPacketFormProps> = (props) => {
  const { departmentId, onSuccess } = props;

  const { data, loading } = useQuery<Data, Variables>(FORMS, {
    variables: { departmentId },
    fetchPolicy: "network-only",
  });

  const patientFormOptions = data?.department.patientForms || [];

  const [createPacket] = useMutation<MutationData, MutationVariables>(
    CREATE_PATIENT_PACKET
  );

  const onSubmit = useCallback(
    (values, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      const input: PatientPacketInput = {
        ...values,
        patientFormIds: values.patientFormIds.filter(Boolean),
      };

      return createPacket({ variables: { departmentId, input } }).then(
        (resp) => {
          if (resp.data?.createPatientPacket.errors) {
            setStatus({ errors: resp.data.createPatientPacket.errors });
          } else if (resp.data?.createPatientPacket.patientPacket) {
            // it worked...
            toast.success("Patient packet created!");
            return onSuccess();
          }
          setSubmitting(false);
        }
      );
    },
    [createPacket, onSuccess, departmentId]
  );

  return (
    <Formik<FormValues>
      initialValues={{
        title: "",
        patientFormIds: [""],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          <TextField name="title" label="Title" />

          <div className="mt-3">
            <VerticalField label="Patient Forms">
              <FieldArray
                name="patientFormIds"
                render={(arrayHelpers) => {
                  return (
                    <>
                      {values.patientFormIds.map((formId, idx) => (
                        <div key={formId || idx} className="flex gap-2 pb-2">
                          <span className="relative z-0 inline-flex shadow-sm rounded-md">
                            <button
                              type="button"
                              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-25 disabled:cursor-not-allowed focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                              disabled={idx === 0}
                              onClick={() => arrayHelpers.move(idx, idx - 1)}
                            >
                              <span className="sr-only">Move Up</span>
                              <ArrowUpIcon className="h-4 w-4" aria-hidden="true" />
                            </button>
                            <button
                              type="button"
                              className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-25 disabled:cursor-not-allowed focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                              disabled={idx === values.patientFormIds.length - 1}
                              onClick={() => arrayHelpers.move(idx, idx + 1)}
                            >
                              <span className="sr-only">Move Down</span>
                              <ArrowDownIcon className="h-4 w-4" aria-hidden="true" />
                            </button>
                          </span>

                          <div className="flex-grow">
                            <SelectInput
                              name={`patientFormIds.${idx}`}
                              options={patientFormOptions}
                              isLoading={loading}
                              getOptionLabel={(o: PatientFormModel) => o.title}
                              getOptionValue={(o: PatientFormModel) => o.id}
                              components={{ Option: PatientFormSelectOption }}
                            />
                          </div>
                          {idx > 0 ? (
                            <Button
                              type="button"
                              kind="tertiary"
                              size="xs"
                              className="border"
                              color="red"
                              onClick={() => arrayHelpers.remove(idx)}
                            >
                              <FAIcon icon="times" />
                            </Button>
                          ) : null}
                        </div>
                      ))}
                      <div className="text-center">
                        <Button
                          type="button"
                          color="blue"
                          kind="tertiary"
                          size="xs"
                          onClick={() => arrayHelpers.push("")}
                        >
                          <span className="mr-2">
                            <FAIcon icon="plus" />
                          </span>
                          Add Form
                        </Button>
                      </div>
                    </>
                  );
                }}
              />
            </VerticalField>
          </div>

          <div className="mt-3 text-center">
            <Button
              type="submit"
              color="blue"
              kind="primary"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Create Patient Packet
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
