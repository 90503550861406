import { FC, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { NoResults } from "components/NoResults";
import { Modal, ModalHeader } from "components/Modal";
import { NewPatientMemberForm } from "./NewPatientMemberForm";
import { ScreenTitle } from "context/ScreenTitle";
import { VerticalField, Button, FAIcon } from "@preferral/ui";
import { ZERO_WIDTH_SPACE } from "@preferral/common";
import { FilterPanel } from "./FilterPanel";

export type PhoneNumber = {
  formatted: string;
  raw: string;
};

export type Patient = {
  id: string;
  firstName: string;
  lastName: string;
  primaryPhone?: PhoneNumber;
  secondaryPhone?: PhoneNumber;
  dob: string;
};

export type InsuranceCoverage = {
  id: string;
  updatedAt: string;
  patientMemberId: string;
  membershipNumber: string;
  unlistedCarrierName?: string;
  unlistedHealthPlanName?: string;
  healthPlan?: {
    id: string;
    name: string;
    carrier: {
      id: string;
      name: string;
      eligibleApiId?: string;
      isMedicare: boolean;
      logoThumbnail?: {
        base64Src: string;
        format: "png" | "jpg";
      };
    };
  };
};

export type PatientMember = {
  id: string;
  medicalRecordNumber?: string;
  patient: Patient;
  isSelfPay: boolean;
  insuranceCoverages: InsuranceCoverage[];
  insertedAt: string;
};

interface PatientsScreenProps {}

const PatientsScreen: FC<PatientsScreenProps> = () => {
  const history = useHistory();
  const location = useLocation();
  const search = qs.parse(location.search);

  const [currentModal, setCurrentModal] = useState<string | null>(
    search.open === "new" ? "NEW_PATIENT_MEMBER" : null
  );

  // NB: This removes the ?open=new search params from the URL so that clicking the link in the "New..." modal works
  //     even from this page.
  useEffect(() => {
    if (search.open) {
      setCurrentModal("NEW_PATIENT_MEMBER");
    }
    history.replace("/o/patients");
  }, [search.open, setCurrentModal, history]);

  return (
    <>
      <ScreenTitle title="Patients" />
      <Modal
        isOpen={currentModal === "NEW_PATIENT_MEMBER"}
        onRequestClose={() => setCurrentModal(null)}
      >
        <ModalHeader
          icon="user-plus"
          title="Create Patient"
          onClose={() => setCurrentModal(null)}
        />
        <div className="px-8 pt-6 pb-8 flex flex-col">
          <NewPatientMemberForm
            onSuccess={(patientMemberId: string) => {
              setCurrentModal(null);
              history.push(`/o/patients/${patientMemberId}`);
            }}
          />
        </div>
      </Modal>
      <div className="_PatientsScreen text-left container mx-auto">
        <div className="flex justify-between items-start py-4 px-2 lg:px-4">
          <FilterPanel />

          <div spec-id="patients-actions">
            {/* <button
            type="button"
            className="ml-4 px-4 border border-blue-500 font-semibold rounded text-blue-500"
            onClick={() => toast.success(`Works ${toastId++}`)}
          >
            Import
          </button> */}
            <VerticalField label={ZERO_WIDTH_SPACE}>
              <Button
                type="button"
                color="blue"
                onClick={() => setCurrentModal("NEW_PATIENT_MEMBER")}
              >
                <span className="mr-2">
                  <FAIcon icon="plus" />
                </span>
                Add Patient
              </Button>
            </VerticalField>
          </div>
        </div>
        <NoResults icon="search" text="Search or Add a Patient" />
      </div>
    </>
  );
};

export { PatientsScreen };
