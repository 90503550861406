import { FC } from "react";
import { gql, useQuery } from '@apollo/client';
import { Button, CircleIcon, JsonDebugger, Spinner, Table, TableContainer, TD, TH } from "@preferral/ui";
import { iso8601Date } from "lib/dateFormatters";
import { TimeRange, ValueLabelData, ValueLabelDatum } from "../model";
import { BarChart } from "../charts/BarChart";
import { NoResults } from "components/NoResults";

const PATIENT_PACKET_SUBMISSIONS_BY_TAG = gql`

  query PatientPacketSubmissionsByTag($dateRange: InclusiveDateRangeInput!) {
    analyticsPatientPacketSubmissionsByTag(dateRange: $dateRange) {
      data {
        value
        label
        group
      }
    }
  }
`;

interface Data {
  analyticsPatientPacketSubmissionsByTag: ValueLabelData;
}

/**
 * PatientPacketSubmissionsByTag.
 */

interface PatientPacketSubmissionsByTagProps {
  timeRange: TimeRange;
};

export const PatientPacketSubmissionsByTag: FC<PatientPacketSubmissionsByTagProps> = props => {
  const { timeRange } = props;

  const dateRange = {
    start: iso8601Date(timeRange.start),
    finish: iso8601Date(timeRange.finish)
  };

  const { data, loading, error, refetch } = useQuery<Data>(PATIENT_PACKET_SUBMISSIONS_BY_TAG, {
    variables: { dateRange }
  });

  const stackData = data?.analyticsPatientPacketSubmissionsByTag.data
    ? transform(data?.analyticsPatientPacketSubmissionsByTag.data)
    : [];

  return (
    <div className="_PatientPacketSubmissionsByTag  p-4 bg-white rounded-lg border shadow-xl">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <CircleIcon icon="chart-bar" />
          <p className="text-xl font-semibold ml-4">
            Patient Packets by Tag
          </p>
        </div>
      </div>
      {
        loading ? (
          <div className="p-8 text-center">
            <Spinner />
          </div>
        ) : error || !data?.analyticsPatientPacketSubmissionsByTag ? (
          <div className="p-8 text-center">
            <p>Failed to load.</p>
            <Button
              type="button"
              size="sm"
              className="mt-4"
              disabled={loading}
              isLoading={loading}
              onClick={() => refetch()}
            >
              Retry
            </Button>
          </div>
        ) : data.analyticsPatientPacketSubmissionsByTag.data.length === 0 ? (
          <NoResults icon="chart-bar" text="No data for time range" />
        ) : (
          <>
            <div className="p-8 text-left">
              <p className="italic pb-12 text-gray-500">Note: In the charts below, packets are included if they were sent during the selected date range. Also note that because packets are grouped by tag, if a packet has 2 tags it will be counted twice (once for each tag).</p>
              <BarChart
                data={stackData}
                bars={[
                  { dataKey: "COMPLETED", stackId: "group", fill: "#2ca02c" },
                  { dataKey: "UNOPENED", stackId: "group", fill: "#ff7f0e" },
                  { dataKey: "PENDING", stackId: "group", fill: "#1f77b4" }
                ]}
                xValKey={"group"}
              />
            </div>
            <TableContainer>
              <Table>
                <thead>
                  <tr>
                    <TH>Tag</TH>
                    <TH># Unopened</TH>
                    <TH># Pending</TH>
                    <TH># Completed</TH>
                    <TH>TOTAL</TH>
                  </tr>
                </thead>
                <tbody>
                  {stackData.map((d: any) => (
                    <tr key={d.group}>
                      <TD>{d.group}</TD>
                      <TD>{d.UNOPENED || 0}</TD>
                      <TD>{d.PENDING || 0}</TD>
                      <TD>{d.COMPLETED || 0}</TD>
                      <TD>{(d.COMPLETED || 0) + (d.UNOPENED || 0) + (d.PENDING || 0)}</TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          </>
        )
      }
      <JsonDebugger data={data} />
    </div>
  );
};


function transform(data: ValueLabelDatum[]): any {
  const result: any[] = [];

  data.forEach(item => {
    const existingGroup = result.find(entry => entry.group === item.group);

    if (existingGroup) {
      existingGroup[item.label] = item.value;
    } else {
      result.push({
        group: item.group,
        [item.label]: item.value
      });
    }
  });

  return result;
}
