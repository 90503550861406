import React, { FC } from "react";
import { components, OptionProps } from "react-select";
import { CircleIcon } from "@preferral/ui";

export const PATIENT_PACKET_OPTION_GQL_FIELDS = `
id
title
patientFormCount
department {
  id
  name
}
`;

export interface PatientPacketModel {
  id: string;
  title: string;
  patientFormCount: number;
  department: {
    id: string;
    name: string;
  };
}

/**
 * PatientPacketOption.
 */

interface PatientPacketOptionProps {
  patientPacket: PatientPacketModel;
}

export const PatientPacketOption: FC<PatientPacketOptionProps> = (props) => {
  const { patientPacket } = props;
  return (
    <div className="_PatientPacketOption flex items-center">
      <CircleIcon icon="envelope-open-text" />
      <div className="ml-3">
        <p className="leading-tight text-cool-gray-900 font-semibold">
          {patientPacket.title}
          <span className="ml-2 text-sm truncate font-light italic text-cool-gray-500">
            ({patientPacket.department.name})
          </span>
        </p>
        <div className="mt-1 leading-none text-cool-gray-700 text-xs italic">
          <p>{patientPacket.patientFormCount} forms</p>
        </div>
      </div>
    </div>
  );
};

/**
 * PatientPacketSelectOption.
 */

type PatientPacketSelectOptionProps = OptionProps<PatientPacketModel, false>;

export const PatientPacketSelectOption: FC<PatientPacketSelectOptionProps> = (
  props
) => {
  return (
    <components.Option {...props}>
      <PatientPacketOption patientPacket={props.data} />
    </components.Option>
  );
};
