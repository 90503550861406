import React, { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { Button } from "@preferral/ui";
import toast from "react-hot-toast";

const REMOVE_PROVIDER_EMPLOYMENT = gql`
  mutation RemoveProviderEmployment($providerId: UUID4!) {
    removeProviderEmployment(providerId: $providerId) {
      errors {
        key
        message
      }
      provider {
        id
      }
    }
  }
`;

interface Data {
  removeProviderEmployment: {
    errors?: InputError[];
    provider?: {
      id: string;
    };
  };
}

interface Variables {
  providerId: string;
}

interface ProviderSettingsProps {
  providerId: string;
  onRemove(): void;
}

export const ProviderSettings: FC<ProviderSettingsProps> = (props) => {
  const { providerId, onRemove } = props;

  const [removeProvider, { loading: removeLoading }] = useMutation<
    Data,
    Variables
  >(REMOVE_PROVIDER_EMPLOYMENT);

  const handleRemove = useCallback(() => {
    return removeProvider({ variables: { providerId } }).then((resp) => {
      if (resp.data?.removeProviderEmployment.errors) {
        toast.error(resp.data.removeProviderEmployment.errors[0].message);
      } else if (resp.data?.removeProviderEmployment.provider) {
        // it worked...
        return onRemove();
      }
    });
  }, [providerId, onRemove]);

  return (
    <div className="mt-4 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="mt-6 space-y-6">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Danger Zone
          </h3>
        </div>
        <div className="border border-red-400 rounded-md">
          <dl className="divide-y divide-gray-200">
            <div className="p-4 space-y-1 sm:p-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:pt-5">
              <dt className="font-semibold leading-5 text-cool-gray-800 text-sm">
                Remove Provider from Organization
              </dt>
              <dd className="flex text-sm leading-5 text-gray-900 sm:mt-0">
                <Button
                  type="button"
                  kind="primary"
                  color="red"
                  size="sm"
                  className="sm:ml-auto"
                  isLoading={removeLoading}
                  disabled={removeLoading}
                  onClick={handleRemove}
                >
                  Remove Provider
                </Button>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};
