export const DEFAULT_AFTER_SIGN_IN_PATH = "/o/dashboard";

const productionDomains = process.env.REACT_APP_PRODUCTION_DOMAINS!.split(",");

const isProd = productionDomains
  .filter(Boolean)
  .includes(window.location.hostname);

export const ENV = isProd ? "PROD" : "DEV";

export const baseDomain = isProd
  ? window.location.hostname.split(".").slice(-2).join(".")
  : window.location.hostname.split(".").slice(-3).join(".");

export const AMD_AUTH_COOKIE_NAME = `aristamd_auth${isProd ? "" : "_dev"}`;

export const AMD_LOGIN_SERVICE_ORIGIN = `https://login.${baseDomain}`;

export const AMD_ECONSULT_REQUEST_FORM_ORIGIN = `https://request.${baseDomain}`;

export const AMD_AUTH_COOKIE_PATH = '/';

export const AMD_COOKIE_EXPIRES_IN_SECONDS = 15;

export const AMD_AUTH_LOCK_COOKIE_NAME = AMD_AUTH_COOKIE_NAME.replace('auth', 'lock');

export const MIXPANEL_TOKEN = isProd
  ? process.env.REACT_APP_MIXPANEL_PROD_TOKEN
  : process.env.REACT_APP_MIXPANEL_DEV_TOKEN;
