import { FC } from "react";
// import { Link } from "react-router-dom";
import { SignInForm } from "./SignInForm";
// import { Icon } from "@preferral/ui";
import aristamdLogoSrc from 'images/aristamd_logo.svg';
/**
 * FaxCodeCTA.
 */
// const FaxCodeCTA: FC = () => {
//   return (
//     <p className="bg-teal-100 border border-teal-300 flex items-center p-1 pr-4 rounded-full shadow-md max-w-md text-gray-600 text-sm">
//       <span className="bg-cool-gray-100 border border-teal-300 flex items-center justify-center mr-3 p-1 rounded-full shadow-inner">
//         <Icon icon="fax" color="teal" size={32} />
//       </span>
//       Have a fax with a referral code?{" "}
//       <Link
//         to="/ug/referral_code"
//         className="font-semibold hover:text-teal-700 inline-block ml-2 text-teal-600"
//       >
//         Click here
//       </Link>
//     </p>
//   );
// };

/**
 * SignInScreen.
 */

export const SignInScreen: FC = () => {
  return (
    <div className="min-h-screen bg-cool-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto w-48"
          src={aristamdLogoSrc}
          alt="AristaMD Logo"
        />
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Sign in to your account
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <SignInForm />
      </div>
      {/* <div className="mt-6 mx-auto">
        <FaxCodeCTA />
      </div> */}
    </div>
  );
};
