import { FC, useCallback } from "react";
import qs from "query-string";
import { gql, useQuery } from "@apollo/client";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import { Spinner, FadeUpIn } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { CircleIconHeader } from "components/CircleIconHeader";
import {
  ConfirmationForm,
  AppointmentRequestModel,
  ReceiverInput,
} from "./ConfirmationForm";
import { ReferralBrief } from "../QuestionsStep/ReferralBrief";
import { ReactComponent as PaperClip } from "images/paperclip.svg";
import { ReferralTo } from "./ReferralTo";

const REFERRAL_CONFIRMATION = gql`
  query FetchPlatformReferral($id: UUID4!) {
    platformReferral(id: $id) {
      id
      referringPatientMemberId
      specialty {
        name
      }
      referringLocation {
        name
      }
      referringProvider {
        nameWithAppellation
      }
      referringDepartment {
        name
      }
      referringPatientMember {
        id
        email
        patient {
          id
          firstName
          lastName
          dob
          consentToTextMessage
          primaryPhone {
            raw
            formatted
          }
          secondaryPhone {
            raw
            formatted
          }
        }
      }
    }
  }
`;

interface Data {
  platformReferral: {
    id: string;
    referringPatientMemberId: string;
    specialty: {
      name: string;
    };
    referringLocation: {
      name: string;
    };
    referringProvider: {
      nameWithAppellation: string;
    };
    referringDepartment: {
      name: string;
    };
    referringPatientMember: {
      id: string;
      email: string;
      patient: {
        id: string;
        consentToTextMessage: boolean;
        primaryPhone?: {
          raw: string;
          formatted: string;
        };
        secondaryPhone?: {
          raw: string;
          formatted: string;
        };
      };
    };
  };
}

interface Variables {
  id: string;
}

interface RouteParams {
  referralId: string;
}

interface ConfirmationStepProps {}

export const ConfirmationStep: FC<ConfirmationStepProps> = props => {
  const match = useRouteMatch<RouteParams>();
  const { referralId } = match.params;

  const { data, loading, error } = useQuery<Data, Variables>(
    REFERRAL_CONFIRMATION,
    { variables: { id: referralId } }
  );

  const history = useHistory();

  const handleSuccess = useCallback(
    (outboundRequest: AppointmentRequestModel) => {
      history.push(`/o/sent_requests/${outboundRequest.id}`);
    },
    [history]
  );

  const location = useLocation();
  const qsParams = qs.parse(location.search) as Record<string, string>;
  const receiver = getReceiver(qsParams);

  return (
    <>
      <ScreenTitle title={["New Referral", "Confirm"]} />
      <div className="_ConfirmationStep container mx-auto mt-4 mb-8 text-left">
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.platformReferral ? (
          <p>Failed to load</p>
        ) : (
          <FadeUpIn show>
            <div className="flex px-2 mt-4">
              <div className="flex-1 max-w-sm mx-2">
                <div className="rounded-2xl bg-white shadow-md p-4 sticky top-4">
                  <div className="relative">
                    <div className="absolute right-0 top-0">
                      <PaperClip className="-translate-y-6 h-12 transform translate-x-1 w-12" />
                    </div>
                  </div>
                  <ReferralTo
                    providerId={qsParams.providerId as string | undefined}
                    locationId={qsParams.locationId as string | undefined}
                    nppesProviderId={
                      qsParams.nppesProviderId as string | undefined
                    }
                    nppesLocationId={
                      qsParams.nppesLocationId as string | undefined
                    }
                  />
                  <ReferralBrief referralId={referralId} />
                </div>
              </div>

              <div className="flex-1 mx-2 pb-8">
                <div className="bg-white px-2 pt-4 rounded-xl shadow-xl">
                  <CircleIconHeader icon="list-alt">
                    Last Step: Confirm
                  </CircleIconHeader>
                  <div className="max-w-2xl p-2 mx-auto">
                    <h3 className="border-b border-cool-gray-300 border-double font-medium pb-2 pt-1 text-cool-gray-700 text-xl">
                      Patient Contact Info
                    </h3>
                    <ConfirmationForm
                      referralId={referralId}
                      receiver={receiver}
                      onSuccess={handleSuccess}
                      initialValues={{
                        consentToTextMessage:
                          data.platformReferral.referringPatientMember.patient
                            .consentToTextMessage,
                        primaryPhone:
                          data.platformReferral.referringPatientMember.patient
                            .primaryPhone?.formatted || "",
                        secondaryPhone:
                          data.platformReferral.referringPatientMember.patient
                            .secondaryPhone?.formatted || "",
                        email:
                          data.platformReferral.referringPatientMember.email ||
                          "",
                        additionalNotes: "",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </FadeUpIn>
        )}
      </div>
    </>
  );
};

function getReceiver(queryStringParams: Record<string, string>): ReceiverInput {
  return queryStringParams as ReceiverInput;
}
