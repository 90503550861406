import { FC, useState, useCallback } from "react";
import { InsuranceCoverage as InsuranceCoverageModel } from "screens/PatientsScreen";
import { EligibilityLookups } from "screens/EligibilityLookups";
import { Drawer, DrawerHeader } from "components/Drawer";
import { DetailList, DetailItem } from "@preferral/ui";
import { format } from "lib/dateFormatters";

/**
 * EligibilityLookupsDrawer.
 */
interface EligibilityLookupsDrawerProps {
  isOpen: boolean;
  onClose(): void;
  insuranceCoverageId: string;
}

const EligibilityLookupsDrawer: FC<EligibilityLookupsDrawerProps> = (props) => {
  const { isOpen, onClose, insuranceCoverageId } = props;

  return (
    <Drawer
      isOpen={isOpen}
      width="2xl"
      onRequestClose={onClose}
      className="bg-cool-gray-100"
    >
      <DrawerHeader
        icon={["far", "address-card"]}
        title="Coverage"
        onClose={onClose}
      />
      <EligibilityLookups insuranceCoverageId={insuranceCoverageId} />
    </Drawer>
  );
};

interface InsuranceCoverageCardProps {
  insuranceCoverage: InsuranceCoverageModel;
}

export const InsuranceCoverageCard: FC<InsuranceCoverageCardProps> = (
  props
) => {
  const { insuranceCoverage } = props;
  const [isOpen, setIsOpen] = useState(false);

  const closeDrawer = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <EligibilityLookupsDrawer
        isOpen={isOpen}
        onClose={closeDrawer}
        insuranceCoverageId={insuranceCoverage.id}
      />
      <div className="_InsuranceCoverageCard border border-gray-300 p-4 rounded-lg shadow-md text-left">
        <div className="flex items-center justify-between">
          <div className="pr-2 flex-grow">
            {insuranceCoverage.healthPlan &&
              insuranceCoverage.healthPlan.carrier.logoThumbnail ? (
              <img
                src={`data:image/${insuranceCoverage.healthPlan.carrier.logoThumbnail.format};base64, ${insuranceCoverage.healthPlan.carrier.logoThumbnail.base64Src}`}
                alt="insurance carrier logo"
                className="h-16 w-full object-contain"
              />
            ) : null}
          </div>
          <div className="flex">
            {insuranceCoverage.healthPlan &&
              (insuranceCoverage.healthPlan.carrier.eligibleApiId ||
                insuranceCoverage.healthPlan.carrier.isMedicare) ? (
              <button
                type="button"
                className="h-8 border border-teal-300 font-semibold hover:bg-teal-100 leading-none px-2 py-2 rounded text-sm text-teal-600 focus:outline-none focus:shadow-outline"
                onClick={() => setIsOpen(true)}
              >
                View Eligibility
              </button>
            ) : (
              <p className="text-gray-500 text-sm leading-loose">
                Eligibility Unavailable
              </p>
            )}
          </div>
        </div>

        <div className="mt-5">
          <DetailList>
            <DetailItem label="Carrier">
              {insuranceCoverage.healthPlan
                ? insuranceCoverage.healthPlan.carrier.name
                : insuranceCoverage.unlistedCarrierName}
            </DetailItem>
            <DetailItem label="Health Plan">
              {insuranceCoverage.healthPlan
                ? insuranceCoverage.healthPlan.name
                : insuranceCoverage.unlistedHealthPlanName}
            </DetailItem>
            <DetailItem label="Membership #">
              {insuranceCoverage.membershipNumber}
            </DetailItem>
          </DetailList>
        </div>

        <div className="mt-4 flex items-center justify-around">
          <p className="text-xs text-gray-600">
            Updated at: {format(insuranceCoverage.updatedAt, "M/d/yy")}
          </p>
        </div>
      </div>
    </>
  );
};
