import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { AddFilesForm } from "./AddFilesForm";

interface AddFilesModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const AddFilesModal: FC<AddFilesModalProps> = (props) => {
  const { isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="file-alt" title="Add Files" onClose={onClose} />
      <div className="p-4">
        <AddFilesForm onSuccess={onClose} />
      </div>
    </Modal>
  );
};
