import { FC } from "react";
import { Redirect, Switch, Route, useRouteMatch } from "react-router-dom";
import { NavTabs, NavTabLink } from "layouts/PortalLayout/NavTabs";
import { ScreenTitle } from "context/ScreenTitle";
import { OpenEConsultsScreen } from "./OpenEConsultsScreen";
import { AllEConsultsScreen } from "./AllEConsultsScreen";

interface EConsultScreenProps { }

export const EConsultsScreen: FC<EConsultScreenProps> = () => {
  const match = useRouteMatch();

  return (
    <div className="_EConsultScreen">
      <ScreenTitle title="eConsults" />

      <NavTabs>
        <NavTabLink to={`${match.url}/open`}>
          <span>Open eConsults</span>
        </NavTabLink>
        <NavTabLink to={`${match.url}/all`}>
          <span>All eConsults</span>
        </NavTabLink>
      </NavTabs>

      <Switch>
        <Route exact path={match.path}>
          <Redirect to={`${match.url}/open`} />
        </Route>
        <Route path={`${match.path}/open`}>
          <OpenEConsultsScreen />
        </Route>
        <Route path={`${match.path}/all`}>
          <AllEConsultsScreen />
        </Route>
      </Switch>
    </div>
  );
};
