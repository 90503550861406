import { FC, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { Spinner, TableContainer, Table, TH, Button } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { loader } from "graphql.macro";
import { QueryData } from "../model";
import { EConsultRow } from "../EConsultRow";
import { analytics } from "lib/analytics";
import {
  defaultFilter,
  FilterModel,
  FilterPanel,
} from "../OpenEConsultsScreen/FilterPanel";
import {
  removeUnusedFilterKeys,
  removeVoidKeys,
} from "../AllEConsultsScreen/FilterPanel";
import { useCurrentProvider } from "hooks/useCurrentProvider";

const LIST_ECONSULTS_QUERY = loader("../ListEconsultsQuery.graphql");

const PAGE_SIZE = 25;

interface OpenEConsultsScreenProps {}

export const OpenEConsultsScreen: FC<OpenEConsultsScreenProps> = () => {
  const [rawFilter, setFilter] = useState<FilterModel>();
  const filter = useMemo(
    () =>
      rawFilter ? removeUnusedFilterKeys(removeVoidKeys(rawFilter)) : undefined,
    [rawFilter]
  );
  const { aristamdProviderId, loading: loadingProvider } = useCurrentProvider();

  useEffect(() => {
    if (!loadingProvider) {
      if (aristamdProviderId) {
        setFilter({
          ...defaultFilter,
          requestingPhysicianId: aristamdProviderId,
        });
      } else {
        setFilter(defaultFilter);
      }
    }
  }, [aristamdProviderId, loadingProvider]);

  const { data, loading, error, fetchMore } = useQuery<QueryData>(
    LIST_ECONSULTS_QUERY,
    {
      variables: { first: PAGE_SIZE, filter },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      skip: filter === undefined,
    }
  );

  useEffect(() => {
    analytics.track("Viewed eConsults", {
      tab: "Open eConsults",
      page_url: window.location.href,
    });
  }, []);

  return (
    <>
      <ScreenTitle title={["eConsults", "Open eConsults"]} />
      <div className="_OpenEConsultsScreen text-left container mx-auto">
        <div className="py-4 lg:px-4 max-w-5xl">
          {rawFilter ? (
            <FilterPanel
              value={rawFilter}
              onChange={setFilter}
              isLoading={loading}
            />
          ) : null}
        </div>
        {loading || loadingProvider ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.econsults ? (
          <p>Failed to load.</p>
        ) : (
          <div className="py-3 lg:px-4">
            <TableContainer>
              <Table>
                <thead>
                  <tr>
                    <TH>Info</TH>
                    <TH>Status</TH>
                    <TH>Disposition</TH>
                  </tr>
                </thead>
                <tbody>
                  {data.econsults.items.map(e => (
                    <EConsultRow key={e.id} eConsult={e} />
                  ))}
                </tbody>
              </Table>
              {data.econsults.items.length > 0 ? (
                <div className="bg-white border-gray-300 border-t p-2 text-center text-gray-500">
                  {data.econsults.endOfList ? (
                    <p>End of List</p>
                  ) : (
                    <Button
                      type="button"
                      size="sm"
                      kind="secondary"
                      color="blue"
                      className="w-full"
                      onClick={() => {
                        fetchMore({
                          variables: {
                            after: data.econsults.cursor,
                          },
                        });
                      }}
                    >
                      Load More
                    </Button>
                  )}
                </div>
              ) : null}
            </TableContainer>
          </div>
        )}
      </div>
    </>
  );
};
