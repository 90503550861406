import React, { FC } from "react";
import { DetailList, DetailItem, Spinner } from "@preferral/ui";
import { gql, useQuery } from "@apollo/client";
import parseISO from "date-fns/parseISO";
import differenceInYears from "date-fns/differenceInYears";
import { ZERO_WIDTH_SPACE } from "@preferral/common";
import { SelectedProvider } from "components/ProviderSearchSelect";
import { SelectedLocation } from "components/ProviderLocationSelect";

const INBOUND_REFERRAL = gql`
  query FetchInboundReferral($id: UUID4!) {
    inboundReferral(id: $id) {
      id
      department {
        id
        name
      }
      specialty {
        id
        name
      }
      patientMember {
        id
        patient {
          id
          name
          dob
        }
        isSelfPay
        insuranceCoverages {
          id
          unlistedCarrierName
          unlistedHealthPlanName
          healthPlan {
            id
            name
            carrier {
              id
              name
            }
          }
        }
      }
      referringProviderReference {
        id
        kind
      }
      referringLocationReference {
        id
        kind
      }
    }
  }
`;

const Spacer: FC = () => {
  return <div className="m-2 lg:m-4 border-b border-cool-gray-200" />;
};

interface ReferralBriefProps {
  inboundReferralId: string;
}

export const ReferralBrief: FC<ReferralBriefProps> = (props) => {
  const { inboundReferralId } = props;

  const { data, loading, error } = useQuery(INBOUND_REFERRAL, {
    variables: { id: inboundReferralId },
  });

  return (
    <div className="_ReferralBrief">
      <div className="px-2 py-3">
        <h4 className="font-semibold text-cool-gray-700 text-lg">
          Inbound Referral Info{" "}
          <span className="text-cool-gray-400">(Draft)</span>
        </h4>
        <p className="font-medium text-cool-gray-600 text-xs">
          {data?.inboundReferral
            ? `${data.inboundReferral.department.name} - ${data.inboundReferral.specialty.name}`
            : ZERO_WIDTH_SPACE}
        </p>
      </div>
      <Spacer />
      {loading ? (
        <div className="p-4 lg:p-6 text-center">
          <Spinner />
        </div>
      ) : error || !data?.inboundReferral ? (
        <p>Failed to load</p>
      ) : (
        <>
          <h5 className="font-black text-base text-cool-gray-600">Patient</h5>
          <div className="mt-3">
            <DetailList>
              <DetailItem label="Name">
                {data.inboundReferral.patientMember.patient.name}
              </DetailItem>
              <DetailItem label="DOB">
                {formatDate(data.inboundReferral.patientMember.patient.dob)}{" "}
                <span className="text-cool-gray-400">
                  ({age(data.inboundReferral.patientMember.patient.dob)} y/o)
                </span>
              </DetailItem>
              {data.inboundReferral.patientMember.isSelfPay ? (
                <DetailItem label="Insurance">Self-Pay</DetailItem>
              ) : hasInsurance(data) ? (
                <>
                  <DetailItem label="Carrier">
                    {carrierName(data.inboundReferral.patientMember)}
                  </DetailItem>
                  <DetailItem label="Health Plan">
                    {healthPlanName(data.inboundReferral.patientMember)}
                  </DetailItem>
                </>
              ) : null}
            </DetailList>
          </div>

          <Spacer />
          <h5 className="font-black text-base text-cool-gray-600">
            Referring Provider
          </h5>
          <div className="mt-3">
            <SelectedProvider
              provider={data.inboundReferral.referringProviderReference}
            />
          </div>

          <Spacer />
          <h5 className="font-black text-base text-cool-gray-600">
            Referring Location
          </h5>
          <div className="mt-3">
            <SelectedLocation
              location={data.inboundReferral.referringLocationReference}
            />
          </div>
        </>
      )}
    </div>
  );
};

function formatDate(isoDate: string): string {
  const [yyyy, mm, dd] = isoDate.split("-");
  return `${mm}/${dd}/${yyyy}`;
}

function age(isoDate: string): number {
  const date = parseISO(isoDate);
  return differenceInYears(new Date(), date);
}

function hasInsurance(data: any): boolean {
  return !!(data?.inboundReferral.patientMember.insuranceCoverages?.length > 0);
}

function carrierName(patientMember: any): string {
  const coverage = patientMember.insuranceCoverages[0];
  return coverage.unlistedCarrierName || coverage.healthPlan.carrier.name;
}

function healthPlanName(patientMember: any): string {
  const coverage = patientMember.insuranceCoverages[0];
  return coverage.unlistedHealthPlanName || coverage.healthPlan.name;
}
