import React, { FC } from "react";
import { Drawer, DrawerHeader } from "components/Drawer";
import { LocationReferenceModel } from "./model";
import { UnlistedLocationForm } from "./UnlistedLocationForm";
import { ProviderReferenceModel } from "components/ProviderSearchSelect";

interface CreateUnlistedLocationDrawerProps {
  providers?: ProviderReferenceModel[];
  isOpen: boolean;
  onClose(): void;
  onSuccess(locationReference: LocationReferenceModel): void;
}

export const CreateUnlistedLocationDrawer: FC<CreateUnlistedLocationDrawerProps> = (
  props
) => {
  const { isOpen, onClose, onSuccess, providers } = props;

  return (
    <Drawer isOpen={isOpen} onRequestClose={onClose} width="lg">
      <DrawerHeader
        icon="map-marker-alt"
        title="Enter Location Info"
        onClose={onClose}
      />
      <UnlistedLocationForm
        providers={providers}
        onSuccess={(locationRef) => {
          onSuccess(locationRef);
          onClose();
        }}
      />
    </Drawer>
  );
};
