import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { RejectRequestForm } from "./RejectRequestForm";

interface RejectRequestModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const RejectRequestModal: FC<RejectRequestModalProps> = (props) => {
  const { isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon={["far", "thumbs-down"]}
        title="Reject Request"
        onClose={onClose}
      />

      <div className="p-4">
        <RejectRequestForm onCancel={onClose} onSuccess={onClose} />
      </div>
    </Modal>
  );
};
