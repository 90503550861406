import React, { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { ImportProvidersForm } from "./ImportProvidersForm";

interface ImportProvidersModalProps {
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

export const ImportProvidersModal: FC<ImportProvidersModalProps> = (props) => {
  const { isOpen, onClose, onSuccess } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="list-alt" title="Import Providers" onClose={onClose} />

      <div className="p-4">
        <ImportProvidersForm
          onSuccess={() => {
            onSuccess();
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};
