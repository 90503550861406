import React, { FC } from "react";
import { ScreenTitle } from "context/ScreenTitle";

interface DirectMessagesScreenProps {}

export const DirectMessagesScreen: FC<DirectMessagesScreenProps> = () => {
  return (
    <div className="DirectMessagesScreen">
      <ScreenTitle title="Direct Messages" />
      <p>DirectMessagesScreen</p>
    </div>
  );
};
