import React, { FC, useState, useCallback } from 'react'
import cx from 'classnames'
import { gql, useQuery, useMutation } from '@apollo/client'
import { FormikProvider, useFormik } from 'formik'
import { FormStatusErrors } from 'components/formik/FormStatusErrors'
import { Spinner, Icon, CopyText, HorizontalRadioGroupField } from '@preferral/ui'
import {
  QUESTIONNAIRE_FRAGMENT,
  questionnaireForServer,
  QuestionnaireModel,
  removeAnswers,
  Form as PreviewForm,
} from 'components/Questionnaire'
import { defaultReferralForm } from 'components/QuestionnaireTemplateEditor'
import { QuestionnaireTemplateEditor } from 'components/QuestionnaireTemplateEditor'
import { ScreenTitle } from 'context/ScreenTitle'
import { useRouteMatch } from 'react-router-dom'
import { UploadPatientFormLogoModal } from './UploadPatientFormLogoModal'
import { TextField } from '@preferral/ui'
import toast from 'react-hot-toast'

const PATIENT_FORM = gql`
  query FetchPatientForm($departmentId: UUID4!, $patientFormId: UUID4!) {
    department(id: $departmentId) {
      id
      name
      organization {
        id
        name
      }
    }
    patientForm(id: $patientFormId) {
      id
      title
      submissionTitleTemplate
      kind
      logoUrl
      form {
        ...QuestionnaireFields
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`

interface Data {
  department: {
    id: string
    name: string
    organization: {
      id: string
      name: string
    }
  }
  patientForm: PatientFormModel
}

interface Variables {
  departmentId: string
  patientFormId: string
}

const UPDATE_PATIENT_FORM = gql`
  mutation UpdatePatientForm($id: UUID4!, $input: PatientFormInput) {
    updatePatientForm(id: $id, input: $input) {
      errors {
        key
        message
      }
      patientForm {
        id
        title
        submissionTitleTemplate
        form {
          ...QuestionnaireFields
        }
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`

interface MutationData {
  updatePatientForm: {
    errors?: InputError[]
    patientForm?: PatientFormModel
  }
}

interface MutationVariables {
  id: string
  input: any
}

interface PatientFormModel {
  id: string
  title: string
  submissionTitleTemplate?: string
  kind: string;
  logo?: Blob
  logoUrl?: string
  form: QuestionnaireModel
}

interface RouteParams {
  departmentId: string
  patientFormId: string
}

const Tabs: React.FC = ({ children }) => {
  return (
    <div className="p-4 flex items-center justify-center -mx-1">{children}</div>
  )
}

const Tab: React.FC<{ active: boolean; onClick: () => void }> = ({
  active,
  onClick,
  children,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cx(
        'mx-1 inline-block border rounded py-1 px-3 font-semibold focus:outline-none focus:shadow-outline',
        {
          'bg-teal-500 text-white border-teal-500 shadow-inner': active,
          'border-white hover:border-gray-200 text-teal-500 hover:bg-gray-200': !active,
        },
      )}
    >
      {children}
    </button>
  )
}

interface FormValues {
  title: string
  submissionTitleTemplate?: string
  kind: string;
  form: QuestionnaireModel
}

/**
 * EditPatientFormForm.
 */

interface SavePatientFormResult {
  errors?: InputError[]
  patientForm?: PatientFormModel
}

interface EditPatientFormFormProps {
  initialValues: FormValues
  save(values: FormValues): Promise<SavePatientFormResult>
  onSuccess?(): void
}

const EditPatientFormForm: FC<EditPatientFormFormProps> = (props) => {
  const { initialValues, save, onSuccess } = props

  const [activeTab, setActiveTab] = useState<'EDITOR' | 'LIVE_PREVIEW'>(
    'EDITOR',
  )

  const viewEditor = useCallback(() => {
    setActiveTab('EDITOR')
  }, [setActiveTab])

  const viewPreview = useCallback(() => {
    setActiveTab('LIVE_PREVIEW')
  }, [setActiveTab])

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions

      setStatus({ errors: null })

      return save(values).then(
        (resp) => {
          if (resp.errors) {
            setStatus({ errors: resp.errors })
          } else if (resp.patientForm) {
            // it worked...
            toast.success('Patient form template updated')
            if (onSuccess) {
              onSuccess()
            }
          }
          setSubmitting(false)
        },
        (rej) => {
          setStatus({
            errors: [{ key: '', message: 'Something went wrong' }],
          })
          setSubmitting(false);
        },
      )
    },
    [save, onSuccess],
  )

  const formikBag = useFormik<FormValues>({
    initialValues,
    onSubmit,
  })

  const { values, status, handleSubmit } = formikBag

  return (
    <>
      <Tabs>
        <Tab active={activeTab === 'EDITOR'} onClick={viewEditor}>
          Editor
        </Tab>
        <Tab active={activeTab === 'LIVE_PREVIEW'} onClick={viewPreview}>
          Live Preview
        </Tab>
      </Tabs>

      {activeTab === 'LIVE_PREVIEW' ? (
        <PreviewForm template={values.form} />
      ) : (
        <FormikProvider value={formikBag}>
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <TextField name="title" label="Form Title" />

            <div className="mt-3">
              <TextField
                name="submissionTitleTemplate"
                label="Submission Title Template"
              />
            </div>

            <div className="mt-3">
              <HorizontalRadioGroupField
                name="kind"
                label="Form Kind"
                options={[
                  { value: "form", label: "Form" },
                  { value: "notice", label: "Notice" }
                ]}
              />
            </div>

            <QuestionnaireTemplateEditor name="form" />

            <div className="flex justify-around p-4">
              <button type="submit" className="btn btn-blue">
                Save
              </button>
            </div>
          </form>
        </FormikProvider>
      )}
    </>
  )
}

/**
 * EditPatientFormScreen.
 */

interface EditPatientFormScreenProps { }

export const EditPatientFormScreen: FC<EditPatientFormScreenProps> = () => {
  const match = useRouteMatch<RouteParams>()
  const { departmentId, patientFormId } = match.params

  const { data, loading, error, refetch } = useQuery<Data, Variables>(PATIENT_FORM, {
    variables: { departmentId, patientFormId },
  })

  const [logoModalOpen, setLogoModalOpen] = useState(false)
  const openLogoModal = () => setLogoModalOpen(true)
  const closeLogoModal = () => setLogoModalOpen(false)

  const [updatePatientForm] = useMutation<MutationData, MutationVariables>(
    UPDATE_PATIENT_FORM,
  )

  const savePatientForm = useCallback<
    (values: FormValues) => Promise<SavePatientFormResult>
  >(
    (values: FormValues) => {
      const questionnaire = questionnaireForServer(removeAnswers(values.form))

      const input = {
        ...values,
        form: questionnaire,
      }

      return updatePatientForm({
        variables: {
          id: patientFormId,
          input,
        },
      }).then((resp) => ({
        errors: resp.data?.updatePatientForm?.errors,
        patientForm: resp.data?.updatePatientForm.patientForm,
      }))
    },
    [patientFormId, updatePatientForm],
  )

  return (
    <div className="_EditPatientFormScreen">
      <ScreenTitle title={['Settings', 'Patient Forms', 'Edit Form']} />

      <div className="mt-4 bg-white border border-gray-300 p-4 rounded-lg shadow-md">
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.patientForm ? (
          <p>Failed to load</p>
        ) : (
          <>
            <UploadPatientFormLogoModal
              patientFormId={patientFormId}
              isOpen={logoModalOpen}
              onClose={closeLogoModal}
              onSuccess={closeLogoModal}
            />
            <div className="_logo-upload text-center">
              {data.patientForm.logoUrl ? (
                <div className="relative inline-block mx-auto p-2 rounded-lg overflow-hidden">
                  <img
                    alt="logo"
                    className="h-16"
                    src={data.patientForm.logoUrl}
                  />
                  <div
                    className="absolute top-0 right-0 bottom-0 left-0 bg-gray-900 opacity-0 hover:opacity-75 cursor-pointer transition-opacity ease-in-out duration-150"
                    onClick={openLogoModal}
                  >
                    <p className="font-bold text-white">Click to change logo</p>
                  </div>
                </div>
              ) : (
                <div
                  className="bg-gray-900 opacity-25 hover:opacity-75 cursor-pointer transition-opacity ease-in-out"
                  onClick={openLogoModal}
                >
                  <p className="font-bold text-white">Click to change logo</p>
                </div>
              )}
            </div>

            <div className="p-5 flex items-center justify-between">
              {/* <div>
                <img src="org-logo-TODO" />
              </div> */}
              <div>
                <p className="font-semibold text-3xl text-gray-900">
                  {data.patientForm.title}{' '}
                  <span className="font-light text-2xl text-gray-600">
                    Patient Form
                  </span>
                </p>
                <p className="font-semibold pt-3 text-gray-700">
                  {data.department.organization.name}
                </p>
                {data.department.name !== data.department.organization.name ? (
                  <p className="font-semibold text-gray-700">
                    <span className="font-normal text-gray-600 text-xs">
                      Department:
                    </span>{' '}
                    {data.department.name}
                  </p>
                ) : null}
              </div>
              <div>
                <div className="flex items-center">
                  <Icon icon="widget-add" />
                  <p className="font-semibold ml-2 text-teal-600 text-xl uppercase">
                    Form Builder
                  </p>
                </div>
              </div>
            </div>

            <EditPatientFormForm
              initialValues={{
                title: data.patientForm.title,
                submissionTitleTemplate:
                  data.patientForm.submissionTitleTemplate,
                kind: data.patientForm.kind,
                form: data.patientForm.form || defaultReferralForm,
              }}
              save={savePatientForm}
              onSuccess={refetch}
            />
          </>
        )}
      </div>
      <p className="text-xs">
        <CopyText
          text={`${window.location.origin}/pg/forms/${patientFormId}`}
          label={'Copy form link'}
        />
      </p>
    </div>
  )
}
