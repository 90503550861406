import { gql, useQuery } from "@apollo/client";

const CURRENT_USER_PROVIDER_ID = gql`
  query CurrentUserProviderId {
    me {
      aristamdProviderId
    }
  }
`;

interface Data {
  me: {
    aristamdProviderId: number | null;
  };
}

export function useCurrentProvider() {
  const { data, loading } = useQuery<Data>(CURRENT_USER_PROVIDER_ID);
  return { aristamdProviderId: data?.me.aristamdProviderId, loading };
}
