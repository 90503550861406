import { createContext, FC, useContext, useMemo, useReducer } from "react";
import { Carrier, CarrierGroup, HealthPlan, RibbonHealthPlan, HealthPlanValue } from "./model";

interface State {
  carrierGroup: CarrierGroup | null;
  carrier: Carrier | null;
  healthPlan: HealthPlan | RibbonHealthPlan | null;
  carrierType: "medical" | "dental" | "vision";
  searchTerm: string;
  isOpen: boolean;
  currentTab: "choose_carrier" | "specify_carrier" | "choose_health_plan";
};

type Action =
  | { type: "CARRIER_GROUP_CLICKED"; carrierGroup: CarrierGroup }
  | { type: "CARRIER_CLICKED"; carrier: Carrier }
  | { type: "HEALTH_PLAN_CLICKED"; healthPlan: HealthPlan }
  | { type: "RIBBON_HEALTH_PLAN_CLICKED"; ribbonHealthPlan: RibbonHealthPlan }
  | { type: "FOCUSED" }
  | { type: "CLICKED_OUTSIDE" }
  | { type: "SEARCH_TERM_UPDATED"; searchTerm: string }
  | { type: "STEP_1_TAB_CLICKED" }
  | { type: "FORGET_CARRIER" };

function healthPlanPickerReducer(prevState: State, action: Action): State {
  switch (action.type) {
    case "FOCUSED": {
      return {
        ...prevState,
        isOpen: true,
      };
    }

    case "CLICKED_OUTSIDE": {
      return {
        ...prevState,
        isOpen: false,
      };
    }

    case "SEARCH_TERM_UPDATED": {
      return {
        ...prevState,
        searchTerm: action.searchTerm,
      };
    }

    case "CARRIER_GROUP_CLICKED": {
      return {
        ...prevState,
        searchTerm: "",
        currentTab: "specify_carrier",
        carrierGroup: action.carrierGroup,
      };
    }

    case "CARRIER_CLICKED": {
      return {
        ...prevState,
        searchTerm: "",
        currentTab: "choose_health_plan",
        carrier: action.carrier,
      };
    }

    case "HEALTH_PLAN_CLICKED": {
      return {
        ...prevState,
        searchTerm: "",
        healthPlan: action.healthPlan,
        isOpen: false,
      };
    }

    case "RIBBON_HEALTH_PLAN_CLICKED": {
      return {
        ...prevState,
        searchTerm: "",
        healthPlan: action.ribbonHealthPlan,
        isOpen: false
      }
    }

    case "STEP_1_TAB_CLICKED": {
      return {
        ...prevState,
        currentTab: "choose_carrier",
      };
    }

    case "FORGET_CARRIER": {
      return {
        ...prevState,
        currentTab: "choose_carrier",
        carrier: null,
        carrierGroup: null,
        healthPlan: null,
      };
    }

    default:
      return prevState;
  }
}

const initialState: State = {
  carrierGroup: null,
  carrier: null,
  healthPlan: null,
  carrierType: "medical",
  searchTerm: "",
  isOpen: false,
  currentTab: "choose_carrier",
};

interface OnHealthPlanSelect {
  (value: HealthPlanValue): void
}

export const ctx = createContext({
  state: initialState,
  dispatch: (_action: Action) => { },
  onHealthPlanSelect: ((_item: HealthPlan | RibbonHealthPlan | void) => { })
});

export function useHealthPlanPicker() {
  const c = useContext(ctx);
  if (c === undefined) {
    throw new Error(
      `useHealthPlanPicker must be used within a HealthPlanPickerContext`
    )
  }
  return c;
}

export const HealthPlanPickerContext: FC<{ onChange: OnHealthPlanSelect }> = props => {
  const { children, onChange } = props;

  const [state, dispatch] = useReducer(healthPlanPickerReducer, initialState);

  const contextValue = useMemo(() => {
    function onHealthPlanSelect(healthPlan: HealthPlan | RibbonHealthPlan | void): void {
      let value: HealthPlanValue;
      switch (healthPlan?.__typename) {
        case "HealthPlan":
          value = {
            carrierId: state.carrier!.id,
            healthPlanId: healthPlan.id,
            ribbonHealthPlanUuid: null
          };
          break;
        case "RibbonHealthPlan":
          value = {
            carrierId: state.carrier!.id,
            healthPlanId: null,
            ribbonHealthPlanUuid: healthPlan.uuid
          };
          break;
        default:
          value = {
            carrierId: state.carrier?.id || null,
            healthPlanId: null,
            ribbonHealthPlanUuid: null
          }
      }
      return onChange(value);
    }

    return { state, dispatch, onHealthPlanSelect };
  }, [state, dispatch, onChange]);

  return (
    <ctx.Provider value={contextValue}>
      {children}
    </ctx.Provider>
  );
}
