import { FC } from "react";
import { useField, ErrorMessage } from "formik";
import { PatientSearchInput } from "components/PatientSearchInput";
import { PatientSearchResult, PatientSearchFilter } from "components/PatientSearchInput";
import { VerticalField, HorizontalField } from "@preferral/ui";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { HTMLAttributes } from "react";

/**
 * PatientSearchTextInput.
 */

interface PatientSearchTextInputProps {
  id?: string;
  name: string;
  filter?: PatientSearchFilter;
  placeholder?: string;
  autoFocus?: boolean;
  icon?: IconProp;
  className?: string;
  searchEnabled?: boolean;
  inputProps?: HTMLAttributes<HTMLInputElement>;
  onPatientClick(patient: PatientSearchResult): void;
  externalLoading?: boolean;
}

export const PatientSearchTextInput: FC<PatientSearchTextInputProps> = (
  props
) => {
  const {
    id,
    name,
    placeholder,
    filter,
    icon,
    autoFocus = false,
    className,
    inputProps = {},
    onPatientClick,
  } = props;

  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;

  const cn = className ?? "";

  return (
    <>
      <PatientSearchInput
        inputProps={{
          id: id || name,
          placeholder,
          name,
          autoFocus,
          autoComplete: "new",
          ...inputProps,
        }}
        icon={icon}
        className={
          meta && meta.touched && meta.error
            ? `border border-red-500 ${cn}`
            : cn
        }
        value={value}
        onChange={setValue}
        onBlur={onBlur}
        onPatientClick={onPatientClick}
        filter={filter}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
};

/**
 * PatientSearchTextField.
 */

interface PatientSearchTextFieldProps extends PatientSearchTextInputProps {
  label: string;
  indicateOptional?: boolean;
}

export const PatientSearchTextField: FC<PatientSearchTextFieldProps> = (
  props
) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      label={label}
      indicateOptional={indicateOptional}
    >
      <PatientSearchTextInput
        {...rest}
        placeholder={rest.placeholder || label}
      />
    </VerticalField>
  );
};

export const HorizontalPatientSearchTextField: FC<PatientSearchTextFieldProps> = (
  props
) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      label={label}
      indicateOptional={indicateOptional}
    >
      <PatientSearchTextInput
        {...rest}
        placeholder={rest.placeholder || label}
      />
    </HorizontalField>
  );
};
