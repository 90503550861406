import { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { analytics } from "lib/analytics";

const CURRENT_USER = gql`
  query CurrentUser {
    me {
      id
      email
      name
      role {
        name
      }
      organization {
        id
        name
      }
    }
  }
`;

interface Data {
  me: {
    id: string;
    email: string;
    name: string;
    role: {
      name: string;
    }
    organization: {
      id: string;
      name: string;
    };
  };
}

export function useTrackUser() {
  const { data } = useQuery<Data>(CURRENT_USER);
  useEffect(() => {
    if (data?.me) {
      const isInternal = data.me.email.trim().toLowerCase().includes('@aristamd.com');
      analytics.identify(data.me.id, {
        $name: data.me.name,
        $email: data.me.email,
        kind: "User",
        is_internal: isInternal,
        user_type: data.me.role.name,
      });
      analytics.setGroup("organization_id", data.me.organization.id);
      analytics.updateGroup("organization_id", data.me.organization.id, {
        $name: data.me.organization.name,
      });
    }
  }, [data]);
}
