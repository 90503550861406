import React, { FC } from "react";
import { DetailRow, DetailTable } from "components/DetailTable";
import { CheckCircleIcon } from "components/TwoToneIcons/CheckCircleIcon";
import { ExclamationCircleIcon } from "components/TwoToneIcons/ExclamationCircleIcon";
import { XCircleIcon } from "components/TwoToneIcons/XCircleIcon";
import { hMmA, mmDdYyyy } from "lib/dateFormatters";
import { Coverage } from "./coverage";
import { formatGender } from "./helpers";
import { PlanContactDisplay } from "./PlanContactDisplay";

interface MedicareCoverageProps {
  coverage: Coverage;
}

export const MedicareCoverage: FC<MedicareCoverageProps> = (props) => {
  const { coverage } = props;
  return (
    <div className="_MedicareCoverage">
      <div className="flex justify-between">
        <div>
          <div className="flex items-center">
            <div>
              <CheckCircleIcon className="h-6 w-6" color="blue" />
            </div>
            <div className="ml-2 flex items-baseline">
              <h4 className="font-semibold text-blue-700">Valid Medicare</h4>
              <p className="ml-4 text-xs text-gray-600">
                Updated: {mmDdYyyy(coverage.json.created_at)} at{" "}
                {hMmA(coverage.json.created_at)}
              </p>
            </div>
          </div>
        </div>
        <div>
          {false && (
            <button
              type="button"
              className="h-8 border border-teal-300 font-semibold hover:bg-teal-100 leading-none px-2 py-2 rounded text-sm text-teal-600 focus:outline-none focus:shadow-outline"
            >
              View Financials
            </button>
          )}
        </div>
      </div>
      <div className="mt-4 -mx-4 flex">
        <div className="w-1/2 px-4">
          <h5 className="text-sm font-semibold text-gray-900">Member</h5>
          <DetailTable className="mt-4">
            <DetailRow label="Name">
              {coverage.json.first_name} {coverage.json.last_name}
            </DetailRow>
            <DetailRow label="DOB">
              {coverage.json.dob && mmDdYyyy(coverage.json.dob)}
            </DetailRow>
            {coverage.json.date_of_death && (
              <DetailRow label="Death">
                {mmDdYyyy(coverage.json.date_of_death)}
              </DetailRow>
            )}
            <DetailRow label="Member ID" noWrapLabel>
              <p className="font-mono">{coverage.json.member_id}</p>
            </DetailRow>
            <DetailRow label="Gender">
              {formatGender(coverage.json.gender)}
            </DetailRow>
            <DetailRow label="Address">
              {coverage.json.address && coverage.json.address.street_line_1 && (
                <div className="leading-tight">
                  <p>{coverage.json.address.street_line_1}</p>
                  {coverage.json.address.street_line_2 && (
                    <p>{coverage.json.address.street_line_2}</p>
                  )}
                  <p>
                    {[
                      coverage.json.address.city,
                      coverage.json.address.state,
                      coverage.json.address.zip,
                    ]
                      .filter((e) => !!e)
                      .join(", ")}
                  </p>
                </div>
              )}
            </DetailRow>
          </DetailTable>
        </div>
        <div className="w-1/2 px-4">
          <h5 className="text-sm font-semibold text-gray-900">Coverage</h5>
          {coverage.hasMedicarePrimaryPayer() && (
            <div className="mt-3 flex items-center">
              <div>
                <ExclamationCircleIcon className="h-6 w-6" color="orange" />
              </div>
              <div className="ml-2">
                <p className="text-sm font-semibold leading-none text-orange-700">
                  Medicare is Supplemental
                </p>
                <p className="mt-1 text-xs leading-none text-orange-500">
                  See below for Primary details
                </p>
              </div>
            </div>
          )}
          <div>
            {["MA", "MB", "MC", "MD"].map((abbrev) => {
              const active =
                coverage.json.plan_details[abbrev] &&
                coverage.json.plan_details[abbrev].active;
              return (
                <div key={abbrev} className="mt-3 flex items-center">
                  <div>
                    {active ? (
                      <CheckCircleIcon className="h-6 w-6" color="green" />
                    ) : (
                      <XCircleIcon className="h-6 w-6" color="gray" />
                    )}
                  </div>
                  <div className="ml-2">
                    <p
                      className={`text-sm font-semibold leading-none ${active ? "text-green-700" : "text-gray-700"
                        }`}
                    >
                      {coverage.json.plan_types[abbrev]}
                    </p>
                    <p
                      className={`mt-1 text-xs leading-none ${active ? "text-green-500" : "text-gray-500"
                        }`}
                    >
                      {active ? "Active" : "Inactive"}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div>
        {coverage.hasMedicarePrimaryPayer() && (
          <div className="mt-6">
            <h5 className="text-sm font-semibold text-gray-900">
              {coverage.json.plan_types["PR"]}
            </h5>
            <DetailTable className="mt-4">
              <DetailRow label="Payer Name" noWrapLabel>
                {coverage.getMedicarePrimaryPayer().payer_name}
              </DetailRow>
              {coverage.getMedicarePrimaryPayer().insurance_type_label && (
                <DetailRow label="Insurance Type" noWrapLabel>
                  coverage.getMedicarePrimaryPayer().insurance_type_label
                </DetailRow>
              )}
              <DetailRow label="Policy Number" noWrapLabel>
                {coverage.getMedicarePrimaryPayer().policy_number}
              </DetailRow>
              <DetailRow label="Effective Date" noWrapLabel>
                {coverage.getMedicarePrimaryPayer().effective_date &&
                  mmDdYyyy(coverage.getMedicarePrimaryPayer().effective_date)}
              </DetailRow>
              <DetailRow label="Termination Date" noWrapLabel>
                {coverage.getMedicarePrimaryPayer().termination_date &&
                  mmDdYyyy(coverage.getMedicarePrimaryPayer().termination_date)}
              </DetailRow>
              <DetailRow label="Contacts">
                {coverage.getMedicarePrimaryPayer().contacts &&
                  coverage.getMedicarePrimaryPayer().contacts.length > 0 &&
                  coverage
                    .getMedicarePrimaryPayer()
                    .contacts.map((contact: JSONObject, idx: number) => (
                      <PlanContactDisplay
                        key={`contact-${idx}`}
                        contact={contact}
                      />
                    ))}
              </DetailRow>
              {coverage.getMedicarePrimaryPayer().comments &&
                coverage.getMedicarePrimaryPayer().comments.length > 0 && (
                  <DetailRow label="Comments">
                    <p className="leading-tight">
                      {coverage.getMedicarePrimaryPayer().comments.join(". ")}
                    </p>
                  </DetailRow>
                )}
            </DetailTable>
          </div>
        )}
        {["MA", "MB"].map((abbrev) => {
          const details = coverage.json.plan_details[abbrev];
          const active = details && details.active;
          if (!active) {
            return null;
          }

          return (
            <div key={abbrev} className="mt-6">
              <h5 className="text-sm font-semibold text-gray-900">
                {coverage.json.plan_types[abbrev]}
              </h5>
              <DetailTable className="mt-4">
                <DetailRow label="Start Date" noWrapLabel>
                  {details.start_date && mmDdYyyy(details.start_date)}
                </DetailRow>
                <DetailRow label="End Date" noWrapLabel>
                  {details.end_date && mmDdYyyy(details.end_date)}
                </DetailRow>
                {details.info_valid_until && (
                  <DetailRow label="Info Valid Until" noWrapLabel>
                    {mmDdYyyy(details.info_valid_until)}
                  </DetailRow>
                )}
              </DetailTable>
            </div>
          );
        })}
        {["MC", "MD"].map((abbrev) => {
          const details = coverage.json.plan_details[abbrev];
          const active = details && details.active;
          if (!active) {
            return null;
          }
          return (
            <div key={abbrev} className="mt-6">
              <h5 className="text-sm font-semibold text-gray-900">
                {coverage.json.plan_types[abbrev]}
              </h5>
              <DetailTable className="mt-4">
                <DetailRow label="Payer Name" noWrapLabel>
                  {details.payer_name}
                </DetailRow>
                {details.insurance_type_label && (
                  <DetailRow label="Insurance Type" noWrapLabel>
                    {details.insurance_type_label}
                  </DetailRow>
                )}
                <DetailRow label="Policy Number" noWrapLabel>
                  {details.policy_number}
                </DetailRow>

                <DetailRow label="Effective Date" noWrapLabel>
                  {details.effective_date && mmDdYyyy(details.effective_date)}
                </DetailRow>
                <DetailRow label="Termination Date" noWrapLabel>
                  {details.termination_date &&
                    mmDdYyyy(details.termination_date)}
                </DetailRow>
                <DetailRow label="Contacts">
                  {details.contacts &&
                    details.contacts.length > 0 &&
                    details.contacts.map((contact: JSONObject, idx: number) => (
                      <PlanContactDisplay
                        key={`contact-${idx}`}
                        contact={contact}
                      />
                    ))}
                </DetailRow>
                {details.comments && details.comments.length > 0 && (
                  <DetailRow label="Comments">
                    <p className="leading-tight">
                      {details.comments.join(". ")}
                    </p>
                  </DetailRow>
                )}
              </DetailTable>
            </div>
          );
        })}
      </div>
    </div>
  );
};
