import React, { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { EligibilityLookups } from "screens/EligibilityLookups";
import { Spinner } from "components/Spinner";
import { Drawer, DrawerHeader } from "components/Drawer";
import { InsuranceCoverage } from "screens/PatientsScreen/PatientsScreen";
import { Modal, ModalHeader } from "components/Modal";
import { DetailList, DetailItem } from "components/DetailList";
import { AddInsuranceCoverageForm } from "./AddInsuranceCoverageForm";
// import {
//   Dropdown,
//   DropdownTrigger,
//   DropdownContent
// } from "components/Dropdown";
import { useDropdown } from "hooks/oldUseDropdown";
import { FAIcon } from "components/FAIcon";
import toast from "react-hot-toast";

export const PATIENT_MEMBER_INSURANCE_COVERAGES = gql`
  query GetPatientMemberInsuranceCoverages($id: UUID4!) {
    patientMember(id: $id) {
      id
      isSelfPay
      insuranceCoverages {
        id
        patientMemberId
        updatedAt
        membershipNumber
        unlistedCarrierName
        unlistedHealthPlanName
        healthPlan {
          id
          name
          carrier {
            id
            name
            eligibleApiId
            isMedicare
            logoThumbnail {
              base64Src
              format
            }
          }
        }
        eligibilityLookups {
          id
        }
      }
    }
  }
`;

interface Data {
  patientMember: {
    id: string;
    isSelfPay: boolean;
    insuranceCoverages: InsuranceCoverage[];
  };
}

interface Variables {
  id: string;
}

const DELETE_INSURANCE_COVERAGE = gql`
  mutation DeleteInsuranceCoverage($id: UUID4!) {
    deleteInsuranceCoverage(id: $id) {
      errors {
        key
        message
      }
      successful
    }
  }
`;

interface MutationData {
  deleteInsuranceCoverage: {
    errors?: InputError[];
    successful?: string;
  };
}

interface PatientInsuranceCoveragesProps {
  patientMemberId: string;
}

const SelfPayIndicator: React.FC = () => {
  return <p>Self Pay</p>;
};

const UnknownInsuranceIndicator: React.FC = () => {
  return (
    <div className="bg-gray-200 inline-block mx-auto px-8 py-6 rounded-lg shadow-inner">
      <p className="text-gray-700">
        <span className="mr-2 text-gray-600">
          <FAIcon icon={["far", "address-card"]} />
        </span>
        No insurance added yet.
      </p>
    </div>
  );
};

const MoreActions: React.FC = ({ children }) => {
  const triggerEl = React.useRef(null);
  const contentEl = React.useRef(null);
  const [dropdownOpen, toggleDropdown] = useDropdown(triggerEl, contentEl);
  const onClick = () => toggleDropdown();

  return (
    <div className="relative">
      <button
        ref={triggerEl}
        onClick={onClick}
        type="button"
        className="h-8 border border-gray-300 hover:bg-gray-100 ml-2 px-1 rounded text-gray-800 focus:outline-none focus:shadow-outline"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 14C4.46957 14 3.96086 13.7893 3.58579 13.4142C3.21071 13.0391 3 12.5304 3 12C3 11.4696 3.21071 10.9609 3.58579 10.5858C3.96086 10.2107 4.46957 10 5 10C5.53043 10 6.03914 10.2107 6.41421 10.5858C6.78929 10.9609 7 11.4696 7 12C7 12.5304 6.78929 13.0391 6.41421 13.4142C6.03914 13.7893 5.53043 14 5 14ZM12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14ZM19 14C18.4696 14 17.9609 13.7893 17.5858 13.4142C17.2107 13.0391 17 12.5304 17 12C17 11.4696 17.2107 10.9609 17.5858 10.5858C17.9609 10.2107 18.4696 10 19 10C19.5304 10 20.0391 10.2107 20.4142 10.5858C20.7893 10.9609 21 11.4696 21 12C21 12.5304 20.7893 13.0391 20.4142 13.4142C20.0391 13.7893 19.5304 14 19 14Z"
            fill="currentColor"
          />
        </svg>
      </button>
      <div
        className="absolute right-0 origin-top-right"
        ref={contentEl}
        hidden={!dropdownOpen}
      >
        <div className="w-48 text-left bg-white border border-gray-300 py-1 rounded-lg shadow-lg">
          {children}
        </div>
      </div>
    </div>
  );
};

const InsuranceCoverageCard: React.FC<{
  insuranceCoverage: InsuranceCoverage;
}> = ({ insuranceCoverage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [deleteCoverage, { loading }] = useMutation<MutationData, Variables>(
    DELETE_INSURANCE_COVERAGE,
    {
      refetchQueries: [
        {
          query: PATIENT_MEMBER_INSURANCE_COVERAGES,
          variables: { id: insuranceCoverage.patientMemberId },
        },
      ],
    }
  );
  return (
    <div className="border border-gray-300 mt-4 p-4 rounded-lg shadow-md text-left">
      <Drawer
        isOpen={isOpen}
        width="2xl"
        onRequestClose={() => setIsOpen(false)}
        className="bg-cool-gray-100"
      >
        <DrawerHeader
          icon={["far", "address-card"]}
          title="Coverage"
          onClose={() => setIsOpen(false)}
        />
        <EligibilityLookups insuranceCoverageId={insuranceCoverage.id} />
      </Drawer>

      <Modal
        isOpen={deleteIsOpen}
        onRequestClose={() => setDeleteIsOpen(false)}
      >
        <ModalHeader
          color="red"
          icon={["far", "address-card"]}
          title="Delete Coverage?"
          onClose={() => setDeleteIsOpen(false)}
        />

        <div className="p-6 text-center">
          <p>Are you sure you want to delete this insurance coverage?</p>
        </div>

        <div className="flex items-center justify-end p-6">
          <button
            type="button"
            className="btn"
            onClick={() => setDeleteIsOpen(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="ml-3 btn btn-red"
            onClick={() =>
              deleteCoverage({ variables: { id: insuranceCoverage.id } }).then(
                (res: any) => {
                  if (res.data && res.data.deleteInsuranceCoverage.successful) {
                    toast.success("Insurance coverage deleted.");
                  }
                }
              )
            }
            disabled={loading}
          >
            Delete
          </button>
        </div>
      </Modal>

      <div className="flex items-center justify-around">
        <div className="flex-grow pr-2">
          {insuranceCoverage.healthPlan &&
            insuranceCoverage.healthPlan.carrier.logoThumbnail ? (
            <img
              src={`data:image/${insuranceCoverage.healthPlan.carrier.logoThumbnail.format};base64, ${insuranceCoverage.healthPlan.carrier.logoThumbnail.base64Src}`}
              alt="insurance carrier logo"
              className="h-16 w-full object-contain"
            />
          ) : null}
        </div>
        <div className="flex">
          {insuranceCoverage.healthPlan &&
            (insuranceCoverage.healthPlan.carrier.eligibleApiId ||
              insuranceCoverage.healthPlan.carrier.isMedicare) ? (
            <button
              type="button"
              className="h-8 border border-teal-300 font-semibold hover:bg-teal-100 leading-none px-2 py-2 rounded text-sm text-teal-600 focus:outline-none focus:shadow-outline"
              onClick={() => setIsOpen(true)}
            >
              View Eligibility
            </button>
          ) : (
            <p className="text-gray-500 text-sm leading-loose">
              Eligibility Unavailable
            </p>
          )}
          <MoreActions>
            <>
              <button
                type="button"
                onClick={() => setDeleteIsOpen(true)}
                className="block w-full text-sm font-semibold text-left cursor-pointer hover:bg-red-200 hover:text-red-800 px-2 py-1 text-red-800"
              >
                Delete Coverage
              </button>
            </>
          </MoreActions>
        </div>
      </div>

      <div className="mt-6">
        <DetailList>
          <DetailItem label="Carrier">
            {insuranceCoverage.healthPlan
              ? insuranceCoverage.healthPlan.carrier.name
              : insuranceCoverage.unlistedCarrierName}
          </DetailItem>
          <DetailItem label="Health Plan">
            {insuranceCoverage.healthPlan
              ? insuranceCoverage.healthPlan.name
              : insuranceCoverage.unlistedHealthPlanName}
          </DetailItem>
          <DetailItem label="Membership #">
            {insuranceCoverage.membershipNumber}
          </DetailItem>
        </DetailList>
      </div>

      <div className="mt-4 flex items-center justify-around">
        <p className="text-xs text-gray-600">
          Updated at: {format(parseISO(insuranceCoverage.updatedAt), "M/d/yy")}
        </p>
      </div>
    </div>
  );
};

const PatientInsuranceCoverages: React.FC<PatientInsuranceCoveragesProps> = ({
  patientMemberId,
}) => {
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const { loading, error, data } = useQuery<Data, Variables>(
    PATIENT_MEMBER_INSURANCE_COVERAGES,
    { variables: { id: patientMemberId } }
  );
  return (
    <div className="PatientInsuranceCoverages">
      <Modal
        isOpen={activeModal === "ADD_INSURANCE"}
        onRequestClose={() => setActiveModal(null)}
      >
        <ModalHeader
          icon={["far", "address-card"]}
          title="Add Insurance"
          onClose={() => setActiveModal(null)}
        />
        <div className="px-8 pt-6 pb-8 flex flex-col">
          <AddInsuranceCoverageForm
            patientMemberId={patientMemberId}
            refetchQueries={[
              {
                query: PATIENT_MEMBER_INSURANCE_COVERAGES,
                variables: { id: patientMemberId },
              },
            ]}
            closeModal={() => setActiveModal(null)}
          />
        </div>
      </Modal>
      <div className="flex justify-between">
        <p className="font-semibold text-indigo-700">Patient's insurance:</p>
      </div>
      <div className="mt-6 text-center">
        {loading ? (
          <Spinner />
        ) : error || !data ? (
          <p>Failed to load</p>
        ) : (
          <>
            {data.patientMember.isSelfPay && <SelfPayIndicator />}
            {!data.patientMember.isSelfPay &&
              data.patientMember.insuranceCoverages.length === 0 && (
                <UnknownInsuranceIndicator />
              )}
            {data.patientMember.insuranceCoverages.map((ic: any) => (
              <InsuranceCoverageCard key={ic.id} insuranceCoverage={ic} />
            ))}
            <div className="text-center">
              <button
                className="bg-white border border-gray-500 font-semibold hover:bg-blue-100 hover:border-gray-600 hover:text-gray-700 mt-4 px-3 py-1 rounded text-gray-600 text-sm focus:outline-none focus:shadow-outline"
                onClick={() => setActiveModal("ADD_INSURANCE")}
              >
                <span className="mr-2">
                  <FAIcon icon="plus" />
                </span>
                Add Insurance
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { PatientInsuranceCoverages };
