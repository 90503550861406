import React from "react";
import styled from "styled-components";

const Trigger = styled.span`
  display: inline-block;
  position: relative;

  &:hover > .Tooltip__tip-container {
    transform: translate(-50%, -135%);
    opacity: 1;
    visibility: visible;
  }
`;

const TipContainer = styled.span`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -85%);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  display: table;
  font-size: 0.875rem;
  text-align: center;
  border-radius: 0.375rem;
  padding: 0.25rem 0.75rem;
  transition: transform 150ms, opacity 150ms, visibility 150ms;
`;

const TipInner = styled.span`
  position: relative;
  display: block;
`;

const TriangleBack = styled.span`
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid currentColor;
`;

const TriangleFront = styled.span`
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid currentColor;
`;

type TooltipProps = {
  tip: React.ReactElement | null;
  color?: AppColor;
  style?: React.CSSProperties;
  onMouseLeave?: () => void;
};

const Tooltip: React.FC<TooltipProps> = ({
  tip,
  children,
  color = "blue",
  style,
  onMouseLeave
}) => {
  return (
    <Trigger className="Tooltip" onMouseLeave={onMouseLeave}>
      {children}
      {tip && (
        <TipContainer
          className={`Tooltip__tip-container bg-${color}-200 block border border-${color}-400 shadow-md text-${color}-800`}
          style={style}
        >
          <TipInner>
            <span>{tip}</span>
            <TriangleBack className={`text-${color}-400`} />
            <TriangleFront className={`text-${color}-200`} />
          </TipInner>
        </TipContainer>
      )}
    </Trigger>
  );
};

export { Tooltip };
