import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { NewAppointmentForm } from "./NewAppointmentForm";

interface NewAppointmentModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const NewAppointmentModal: FC<NewAppointmentModalProps> = (props) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="max-w-3xl">
      <ModalHeader
        icon="calendar-alt"
        onClose={onClose}
        title="Schedule Appointment"
      />
      <div className="_NewAppointmentModal p-4">
        <NewAppointmentForm onCancel={onClose} onSuccess={onClose} />
      </div>
    </Modal>
  );
};
