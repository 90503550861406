import { FC, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spinner, DetailList, DetailItem, FAIcon, Button } from "@preferral/ui";
import { PatientMemberModel, InsuranceCoverageModel } from "../types";
import { mmDdYy } from "lib/dateFormatters";

const PATIENT_MEMBER = gql`
  query GetPatientMember($id: UUID4!) {
    patientMember(id: $id) {
      id
      medicalRecordNumber
      patient {
        id
        firstName
        preferredName
        lastName
        dob
        primaryPhone {
          formatted
          raw
        }
        zip
        fipsCode
      }
      isSelfPay
      insuranceCoverages {
        id
        membershipNumber
        updatedAt
        unlistedCarrierName
        unlistedHealthPlanName
        healthPlan {
          id
          ribbonUuid
          name
          carrier {
            id
            name
            logoThumbnail {
              base64Src
              format
            }
          }
        }
      }
    }
  }
`;

interface Data {
  patientMember: PatientMemberModel;
}

interface Variables {
  id: string;
}

/**
 * InsuranceCoverage.
 */

interface InsuranceCoverageProps {
  insuranceCoverage: InsuranceCoverageModel;
}

const InsuranceCoverage: FC<InsuranceCoverageProps> = (props) => {
  const { insuranceCoverage } = props;

  return (
    <div className="_InsuranceCoverage border border-gray-300 mt-4 p-4 rounded-lg shadow-md text-left">
      <div className="flex items-center justify-around">
        <div className="flex-grow pr-2">
          {insuranceCoverage.healthPlan &&
            insuranceCoverage.healthPlan.carrier.logoThumbnail ? (
            <img
              src={`data:image/${insuranceCoverage.healthPlan.carrier.logoThumbnail.format};base64, ${insuranceCoverage.healthPlan.carrier.logoThumbnail.base64Src}`}
              alt="insurance carrier logo"
              className="h-16 w-full object-contain"
            />
          ) : null}
        </div>
      </div>

      <div className="mt-6">
        <DetailList>
          <DetailItem label="Carrier">
            {insuranceCoverage.healthPlan
              ? insuranceCoverage.healthPlan.carrier.name
              : insuranceCoverage.unlistedCarrierName}
          </DetailItem>
          <DetailItem label="Health Plan">
            {insuranceCoverage.healthPlan
              ? insuranceCoverage.healthPlan.name
              : insuranceCoverage.unlistedHealthPlanName}
          </DetailItem>
          <DetailItem label="Membership #">
            {insuranceCoverage.membershipNumber}
          </DetailItem>
        </DetailList>
      </div>

      <div className="mt-4 flex items-center justify-around">
        <p className="text-xs text-gray-600">
          Updated at: {mmDdYy(insuranceCoverage.updatedAt)}
        </p>
      </div>
    </div>
  );
};

/**
 * PatientMemberInfo.
 */

interface PatientMemberInfoProps {
  patientMember: PatientMemberModel;
}

const PatientMemberInfo: FC<PatientMemberInfoProps> = (props) => {
  const { patientMember } = props;
  return (
    <div className="PatientMemberInfo px-4 text-left">
      <div className="_patient-info flex">
        <div className="px-2 py-4">
          <div className="_initials-avatar flex-0 text-center bg-indigo-100 border border-2 border-indigo-400 flex flex-col align-middle h-16 w-16 justify-center rounded-full shadow-inner text-xl sm:text-2xl text-indigo-800">
            <span className="tracking-wider">
              {patientMember.patient.firstName.slice(0, 1)}
              {patientMember.patient.lastName.slice(0, 1)}
            </span>
          </div>
        </div>
        <div className="_patient-demographic ml-4 py-4">
          <p className="mt-1 text-2xl leading-tight text-gray-900">
            {patientMember.patient.firstName} {patientMember.patient.lastName}
          </p>
          <DetailList className="mt-2">
            <DetailItem label="DOB" labelWidth={44}>
              <p>{mmDdYy(patientMember.patient.dob)}</p>
            </DetailItem>
            <DetailItem label="MRN" labelWidth={44}>
              {patientMember.medicalRecordNumber ? (
                <p>{patientMember.medicalRecordNumber}</p>
              ) : (
                <p className="text-cool-gray-500">-</p>
              )}
            </DetailItem>
          </DetailList>
        </div>
      </div>

      <div className="pt-2">
        <div className="_insurance-info">
          {patientMember.insuranceCoverages.map((ic) => (
            <InsuranceCoverage key={ic.id} insuranceCoverage={ic} />
          ))}
        </div>
      </div>
    </div>
  );
};

/**
 * EditingPatientMemberInfo.
 */

interface EditingPatientMemberInfoProps {
  patientMember: PatientMemberModel;
  onCancelEditClick?(): void;
}

const EditingPatientMemberInfo: FC<EditingPatientMemberInfoProps> = (props) => {
  const { patientMember, onCancelEditClick } = props;
  return (
    <div className="_EditingPatientMemberInfo -mx-3 bg-gray-50 border px-3 py-2 rounded shadow-md">
      <p className="font-semibold text-gray-600 text-xs">Editing Patient:</p>
      <div className="flex items-center">
        <div className="_patient-info flex-grow gap-2">
          <p className="mt-1 text-base leading-tight text-gray-900">
            {patientMember.patient.firstName} {patientMember.patient.lastName}
          </p>
          <div className="flex gap-2 text-xs text-gray-500">
            {
              patientMember.medicalRecordNumber ? (
                <p>MRN: {patientMember.medicalRecordNumber}</p>
              ) : null
            }
            <p>DOB: {mmDdYy(patientMember.patient.dob)}</p>
          </div>
        </div>

        {onCancelEditClick ? (
          <div>
            <Button type="button" size="xs" kind="tertiary" color="purple" className="border" onClick={onCancelEditClick}>
              <FAIcon icon="rotate-left" className="mr-2" />
              Cancel Edits
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

/**
 * SelectedPatientMember.
 */

interface SelectedPatientMemberProps {
  patientMemberId: string;
  onClearSelection(): void;
  onEditClick?(patientMember: PatientMemberModel): void;
  isEditing?: boolean;
  onCancelEditClick?(): void;
  offerAddInsurance?: boolean;
}

export const SelectedPatientMember: FC<SelectedPatientMemberProps> = (
  props
) => {
  const { patientMemberId, onClearSelection, onEditClick, isEditing, onCancelEditClick, offerAddInsurance: offerAddInsuranceProp = false } = props;
  const { data, loading, error } = useQuery<Data, Variables>(PATIENT_MEMBER, {
    variables: {
      id: patientMemberId,
    },
  });

  useEffect(() => {
    const canEdit = !!onEditClick && !isEditing;
    const isMissingData = data && (!data.patientMember.patient.zip || !data?.patientMember.patient.fipsCode || (!data.patientMember.isSelfPay && data.patientMember.insuranceCoverages.length === 0));
    if (canEdit && isMissingData) {
      onEditClick!(data!.patientMember);
    }
  }, [patientMemberId, data, isEditing, onEditClick]);

  const offerAddInsurance = !!onEditClick && offerAddInsuranceProp && data?.patientMember && data.patientMember.insuranceCoverages.length === 0;

  return (
    <div className="_SelectedPatientMember p-4">
      {loading ? (
        <div className="text-center p-6">
          <Spinner />
        </div>
      ) : error || !(data && data.patientMember) ? (
        <p>Failed to load.</p>
      ) : (
        <div>
          <div className="_actions pb-3 flex items-center justify-center gap-2">
            {!isEditing && onEditClick ? (
              <Button
                kind="tertiary"
                color="blue"
                size="xs"
                onClick={() => onEditClick(data.patientMember)}
              >
                <FAIcon icon="user-edit" className="mr-2" />
                Edit Patient Info
              </Button>
            ) : null}
            <Button
              kind="tertiary"
              color="red"
              size="xs"
              onClick={onClearSelection}
            >
              <FAIcon icon="times" className="mr-2" />
              Clear Selected Patient
            </Button>
          </div>

          {isEditing ? (
            <EditingPatientMemberInfo
              patientMember={data.patientMember}
              onCancelEditClick={onCancelEditClick}
            />
          ) : (
            <PatientMemberInfo patientMember={data.patientMember} />
          )}

          {offerAddInsurance ? (
            <div className="text-center">
              <Button
                kind="tertiary"
                color="blue"
                size="xs"
                onClick={onClearSelection}
              >
                <FAIcon icon="plus" className="mr-2" />
                Add Insurance
              </Button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
