import {
  FC,
  createContext,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { differenceInSeconds, addSeconds } from 'date-fns'
import { gql, useMutation, useQuery } from "@apollo/client";
import Cookies from 'js-cookie';
import {
  AMD_AUTH_COOKIE_NAME,
  AMD_LOGIN_SERVICE_ORIGIN,
  AMD_AUTH_LOCK_COOKIE_NAME,
  AMD_COOKIE_EXPIRES_IN_SECONDS,
  AMD_AUTH_COOKIE_PATH,
  baseDomain
} from "config/constants";
import { tokenStore, useAuth } from "./AuthContext";
import { useTimeout } from "@preferral/ui";

let currentAristaMDUserId: number;
let sessionStorageLocked = false;

const RENEW_AMD_TOKEN_MUTATION = gql`
  mutation RefreshAmdAccessToken($input: AmdRefreshTokenInput!) {
    refreshAmdToken(input: $input) {
      errors {
        key
        message
      }
      cookie {
        accessToken
        refreshToken
        expiresIn
        userId
      }
    }
  }
`;

interface RefreshAmdTokenData {
  refreshAmdToken: {
    errors?: InputError[];
    cookie?: {
      accessToken: string;
      refreshToken: string;
      expiresIn: number;
      userId: number;
    }
  }
}

interface RefreshAmdTokenInput {
  input: {
    refreshToken: string;
  }
}

const CURRENT_USER = gql`
  query CurrentUser {
    me {
      id
      aristamdUserId
    }
  }
`;

interface CurrentUserData {
  me: {
    id: string;
    aristamdUserId: number;
  };
}

export interface AmdSessionContext {
  logout(): void;
  redirectAmdLogin(): void;
  getCookie(): AMDCookieData | null;
  isActive(): boolean;
}

interface AMDCookieData {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  received_at: string;
  user_id: number;
}

const amdSessionContext = createContext<AmdSessionContext>(null!)

export const AmdSessionProvider: FC = props => {
  const setTimeout = useTimeout();

  const [refreshAmdToken] = useMutation<RefreshAmdTokenData, RefreshAmdTokenInput>(RENEW_AMD_TOKEN_MUTATION);
  const { token } = useAuth()

  const { data: currentUserData, error: currentUserError, client } = useQuery<CurrentUserData>(CURRENT_USER, { skip: !token });

  /**
   * Redirect to AristaMD login page on auth service
   */
  const redirectAmdLogin = useCallback(() => {
    // Preferral logout - Just clear token from localStorage, avoiding redirect to preferral login
    tokenStore.forgetToken();
    clearSession(true);
    redirectToAMDLoginService('/signin', `${window.location.origin}/auth/amd_sso?redirect=${window.location.pathname}`);
  }, []);

  /**
   * Redirect to logout page on auth service which handles logging out of all services
   */
  const logout = useCallback(() => {
    setRefreshLock()

    tokenStore.forgetToken();
    clearSession(true);
    // Clear Apollo cache data.
    client.clearStore();
    redirectToAMDLoginService('/logout')
  }, [client]);

  /**
   * Refresh the AMD access token
   */
  const refreshToken = useCallback(() => {
    setRefreshLock();
    let cookie = getCookie();
    if (cookie) {
      refreshAmdToken({
        variables: {
          input: {
            refreshToken: cookie?.refresh_token
          }
        }
      }).then(({ data }) => {
        removeRefreshLock();
        if (data?.refreshAmdToken?.errors) {
          logout();
        } else if (data?.refreshAmdToken?.cookie) {
          const newCookie: AMDCookieData = {
            access_token: data.refreshAmdToken.cookie.accessToken,
            refresh_token: data.refreshAmdToken.cookie.refreshToken,
            expires_in: data.refreshAmdToken.cookie.expiresIn,
            received_at: (new Date()).toISOString(),
            user_id: data.refreshAmdToken.cookie.userId
          }
          updateCookie(newCookie);
          updateSessionStorageFromCookie(newCookie);
        }
      }).catch(() => {
        removeRefreshLock();
        logout();
      })
    }
  }, [logout, refreshAmdToken])

  /**
   * Do some checks to validate the session, call itself again in 2 seconds
   * @returns {boolean}
   */
  const validate = useCallback(() => {
    let cookie = getCookie();

    // If the cookie has been deleted, redirect to the login page
    if (!cookie) {
      redirectAmdLogin();
      return;
    }

    // If cookie user is different from the session user, reload the page
    if (cookie.user_id !== currentAristaMDUserId) {
      window.location.reload();
      return false;
    }

    // Get the time left in the secs since the access token was emitted.
    const elapsedTime = differenceInSeconds(new Date(), new Date(cookie.received_at));

    // if for some reason the token already expired, don't try to refresh it and logout
    if (cookie.expires_in - elapsedTime <= 0 && !isRefreshLocked()) {
      logout();
      return false;
    }

    // If cookie expires in 2 mins or less, refresh the token
    if (cookie.expires_in - elapsedTime <= 120 && !isRefreshLocked()) {
      refreshToken();
      return false;
    }

    updateSessionStorageFromCookie(cookie);
    keepAlive();

    setTimeout(validate.bind(this), 2000);
    return true;
  }, [logout, refreshToken, redirectAmdLogin, setTimeout])

  /**
   * Initialize session
   * @returns void
   */
  const init = useCallback(() => {
    clearStaleSession();
    let cookie = getCookie();
    if (!cookie) {
      redirectAmdLogin();
      return;
    }
    currentAristaMDUserId = cookie.user_id;
    validate();
  }, [validate, redirectAmdLogin])

  // This will be executed only if current user is an AristaMD User
  useEffect(() => {
    const alreadyInitialized = !!currentAristaMDUserId;
    if (!alreadyInitialized && !currentUserError && currentUserData?.me.aristamdUserId) init();
  }, [currentUserData, currentUserError, token, init])

  const value = useMemo(() => ({ logout, getCookie, redirectAmdLogin, isActive }), [logout, redirectAmdLogin]);
  return (<amdSessionContext.Provider value={value} {...props} />)
}

export function useAmdSessionContext() {
  const context = useContext(amdSessionContext);
  if (context === undefined) {
    throw new Error(`useAmdAuth must be used within an AmdAuthProvider`);
  }
  return context;
}

/**
 * Update auth cookie expiration date to keep it alive, since cookie is destroyed automatically after expiration.
 * When an AristaMD app is opened in a new tab, it will look for auth cookie. If the cookie doesn't exist, the
 * user will be required to log in again. Otherwise the session is kept alive and we can initialize a new
 * session from the auth cookie.
 */
function keepAlive() {
  if (!isRefreshLocked()) updateCookie();
}

/**
 * Store auth cookie.
 */
function setCookie(cookie: JSONObject): JSONObject {
  Cookies.set(
    AMD_AUTH_COOKIE_NAME,
    JSON.stringify(cookie),
    {
      expires: addSeconds(new Date(), AMD_COOKIE_EXPIRES_IN_SECONDS),
      path: AMD_AUTH_COOKIE_PATH,
      domain: baseDomain,
      secure: true,
      sameSite: 'None',
    }
  );
  return cookie;
}

/**
 * Get the auth cookie
 */
function getCookie(): AMDCookieData | null {
  function rebuildCookieFromSessionStorage(): AMDCookieData | null {
    const oauth = JSON.parse(sessionStorage.getItem('oauth') || 'null');

    if (oauth) {
      return {
        user_id: oauth.user_id,
        access_token: oauth.access_token,
        refresh_token: oauth.refresh_token,
        expires_in: oauth.expires_in,
        received_at: oauth.received_at
      }
    }
    return null;
  }

  let cookie = null;
  const cookieAsString = Cookies.get(AMD_AUTH_COOKIE_NAME);

  if (cookieAsString === '0') {
    // A value of '0' indicates the user logged out
    return cookie;
  } else if (cookieAsString === undefined) {
    // Cookie may have expired if validate timer didn't run or was delayed.
    // This can happen in IE while browsing for a file and in Safari for minimized or inactive tabs.
    // Attempt to rebuild the cookie from sessionStorage.
    try {
      cookie = rebuildCookieFromSessionStorage();
    } catch (e) {
      console.error(e);
    }
  } else if (!!cookieAsString) {
    try {
      cookie = JSON.parse(cookieAsString)
    } catch (e) {
      console.error(e);
    }
  }

  return cookie;
}

/**
 * Like setCookie but only adds data.
 */
function updateCookie(data?: AMDCookieData | null) {
  return setCookie({ ...getCookie(), ...data });
}


/**
 * Redirect to auth service specific url with a set of provided parameters
 */
function redirectToAMDLoginService(pathname: string, returnPath?: string) {
  const params = returnPath ? `?redirect=${encodeURIComponent(returnPath)}` : "";
  const href = AMD_LOGIN_SERVICE_ORIGIN + pathname + params;
  window.location.href = href;
}


/**
 * Set a temporary lock cookie to prevent multiple app instances (separate tabs) from trying
 * to refresh token at the same time.
 */
function setRefreshLock() {
  Cookies.set(
    AMD_AUTH_LOCK_COOKIE_NAME,
    'true',
    {
      expires: addSeconds(new Date(), 15),
      path: AMD_AUTH_COOKIE_PATH,
      domain: baseDomain
    }
  );
}

/**
 * Remove the token refresh lock cookie.
 */
function removeRefreshLock() {
  return Cookies.remove(AMD_AUTH_LOCK_COOKIE_NAME, { path: AMD_AUTH_COOKIE_PATH, domain: baseDomain });
}

/**
 * Returns true if token refresh lock cookie has been set.
 */
function isRefreshLocked(): boolean {
  return !!Cookies.get(AMD_AUTH_LOCK_COOKIE_NAME);
}

/**
 * Retrieves the cookie values from sessionStorage.
 */
function getOauthDataFromSession(): AMDCookieData {
  return JSON.parse(sessionStorage.getItem('oauth') || 'null') || {};
}

function updateSessionStorageFromCookie(cookie: AMDCookieData) {
  if (sessionStorageLocked) return;

  const oauth: AMDCookieData = {
    ...getOauthDataFromSession(),
    ...cookie,
    received_at: cookie.received_at || (new Date()).toISOString()
  };
  sessionStorage.setItem('oauth', JSON.stringify(oauth));
}

function isSessionStale(): boolean {
  const cookieToken = getCookie()?.access_token;
  const sessionToken = getOauthDataFromSession().access_token
  return sessionToken !== cookieToken;
}

/**
   * Clear stale session from previous log in
   * This can happen when a user logs out while using multiple tabs
   */
function clearStaleSession() {
  if (isSessionStale()) sessionStorage.clear();
}

/**
 * Clear session data
 * @param lock Lock session storage to prevent changes
 */
function clearSession(lock: boolean) {
  if (lock) sessionStorageLocked = true;
  sessionStorage.clear();
}

/**
 * Validates if the AMD session context is active (checks if validation timeout is set)
 */
function isActive(): boolean { return !!currentAristaMDUserId }
