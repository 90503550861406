import React, { FC, useState, useCallback } from "react";
import { gql, useQuery } from "@apollo/client";
import { GradientHeader } from "../../../GradientHeader";
// import { AddDepartmentLocationModal } from "./AddDepartmentLocationModal";
import { NoResults } from "components/NoResults";
import {
  Button,
  TableContainer,
  Table,
  TH,
  TD,
  DropdownButton,
  DropdownItemButton,
  Spinner,
  FAIcon,
  DropdownItemLink,
} from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { useRouteMatch } from "react-router-dom";
import { AddLocationModal } from "./AddLocationModal";
import { mmDdYyyy } from "lib/dateFormatters";

const DEPARTMENT_LOCATIONS = gql`
  query DepartmentLocations($departmentId: UUID4!) {
    department(id: $departmentId) {
      id
      name
    }
    departmentLocations(departmentId: $departmentId) {
      cursor
      endOfList
      items {
        id
        name
        streetAddress
        streetAddressLine2
        city
        stateAbbreviation
        zip
      }
    }
  }
`;

interface Data {
  department: {
    id: string;
    name: string;
  };
  departmentLocations: Paginated<LocationModel>;
}

interface Variables {
  departmentId: string;
}

interface LocationModel {
  id: string;
  name: string;
  streetAddress: string;
  streetAddressLine2?: string;
  city: string;
  stateAbbreviation: string;
  zip: string;
}

type ActiveModal =
  | "ADD_LOCATION"
  | { type: "REMOVE_LOCATION"; locationId: string };

interface DepartmentLocationsScreenProps {}

export const DepartmentLocationsScreen: FC<DepartmentLocationsScreenProps> = (
  props
) => {
  const match = useRouteMatch<{ departmentId: string }>();
  const { departmentId } = match.params;

  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    DEPARTMENT_LOCATIONS,
    { variables: { departmentId } }
  );

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const openAddLocationModal = useCallback(() => {
    setActiveModal("ADD_LOCATION");
  }, [setActiveModal]);

  const openRemoveLocationModal = useCallback(
    (locationId: string) => {
      setActiveModal({ type: "REMOVE_LOCATION", locationId });
    },
    [setActiveModal]
  );

  const closeModal = useCallback(() => {
    setActiveModal(null);
  }, [setActiveModal]);

  return (
    <>
      <AddLocationModal
        isOpen={activeModal === "ADD_LOCATION"}
        onClose={closeModal}
        departmentId={departmentId}
        refetch={refetch}
      />
      <ScreenTitle title={["Settings", "Department", "Locations"]} />
      <div className="_DepartmentLocationsScreen bg-white box rounded-lg shadow-lg">
        <GradientHeader
          icon="map-marker-alt"
          title="Department Locations"
          subtitle={`Manage ${
            data?.department.name || "this department's"
          } locations`}
        />
        <div className="p-4">
          {loading ? (
            <div className="p-12 text-center">
              <Spinner />
            </div>
          ) : error || !data?.departmentLocations ? (
            <p>Failed to load.</p>
          ) : (
            <div>
              <div className="flex justify-end px-3 pb-4">
                <Button
                  type="button"
                  kind="primary"
                  color="blue"
                  onClick={openAddLocationModal}
                >
                  <span className="mr-2">
                    <FAIcon icon="plus" />
                  </span>
                  Add Location
                </Button>
              </div>
              {data.departmentLocations.items.length === 0 ? (
                <NoResults
                  icon="map-marker-alt"
                  text="No locations added yet"
                />
              ) : (
                <TableContainer>
                  <Table>
                    <thead>
                      <tr>
                        <TH>Name</TH>
                        <TH>Address</TH>
                        <TH />
                      </tr>
                    </thead>

                    <tbody>
                      {data.departmentLocations.items.map((location) => (
                        <tr key={location.id}>
                          <TD>{location.name}</TD>
                          <TD>{location.streetAddress}</TD>
                          <TD>
                            <DropdownButton label="Actions">
                              <DropdownItemButton
                                onClick={() =>
                                  openRemoveLocationModal(location.id)
                                }
                              ></DropdownItemButton>
                            </DropdownButton>
                          </TD>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
