import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FAIcon } from "components/FAIcon";

type NoResultsProps = {
  icon: IconProp;
  text: string;
};

const NoResults: React.FC<NoResultsProps> = ({ icon, text }) => {
  return (
    <div className="px-4 py-8 text-center">
      <div className="bg-gray-200 inline-block leading-none mx-auto p-12 p-5 rounded rounded-full text-6xl text-center text-gray-600 text-xl">
        <FAIcon icon={icon} />
      </div>
      <p className="mb-12 mt-8 text-gray-800 text-xl">{text}</p>
    </div>
  );
};

export { NoResults };
