import { useState, useEffect, useCallback, MutableRefObject } from "react";

export default function useDropdown(
  triggerRef: MutableRefObject<null | HTMLElement>,
  contentRef: MutableRefObject<null | HTMLElement>
): [boolean, (toggleState?: boolean) => void] {
  const triggerEl = triggerRef.current;
  const contentEl = contentRef.current;

  const [drop, setDrop] = useState(false);

  const toggleDrop = useCallback(
    toggleState => {
      setDrop(toggleState !== undefined ? Boolean(toggleState) : !drop);
    },
    [drop]
  );

  const onWindowClick = useCallback(
    ev => {
      const clickOnAction =
        triggerEl && (ev.target === triggerEl || triggerEl.contains(ev.target));
      const clickOnDrop =
        contentEl && (ev.target === contentEl || contentEl.contains(ev.target));

      if (!clickOnAction && !clickOnDrop && drop === true) {
        toggleDrop(false);
      }
    },
    [drop, contentEl, triggerEl, toggleDrop]
  );

  const onEsc = useCallback(
    ev => {
      if (ev.keyCode === 27 && drop === true) {
        toggleDrop(false);
      }
    },
    [drop, toggleDrop]
  );

  useEffect(() => {
    window.addEventListener("click", onWindowClick);
    return () => window.removeEventListener("click", onWindowClick);
  });

  useEffect(() => {
    window.addEventListener("keyup", onEsc);
    return () => window.removeEventListener("keyup", onEsc);
  });

  return [drop, toggleDrop];
}

export { useDropdown };
