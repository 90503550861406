import { useCallback, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";

const USER_AMD_SETTINGS = gql`
  query UserAmdSettings {
    me {
      id
      organization {
        id
        aristamdOrganization {
          id
          configuration
        }
      }
    }
  }
`;

interface Data {
  me: {
    id: string;
    organization: {
      id: string;
      aristamdOrganization: {
        id: number;
        configuration: JSONObject;
      };
    };
  };
}

export function useAmdSettings() {
  const { data } = useQuery<Data>(USER_AMD_SETTINGS);

  const { settings } =
    data?.me.organization.aristamdOrganization.configuration || {};

  const hasFeature = useCallback(
    featureName => settings && settings[featureName],
    [settings]
  );

  return useMemo(() => ({ hasFeature }), [hasFeature]);
}
