import { FC, useState } from "react";
import { Table, TH, TD, PillTabs, PillTab } from "@preferral/ui";
import { Coverage } from "./coverage";

const levels = [
  "individual",
  "family",
  "children only",
  "dependents only",
  "employee only",
  "spouse only",
  "spouse and children",
  "employee and spouse",
  "employee and children"
];

/**
 * Comments.
 */

const Comments: FC<any> = ({ individual, family }) => {
  if ((individual && individual.length > 0) || (family && family.length > 0)) {
    return (
      <tr>
        <td>Comments: </td>
        <td>
          {individual && (
            <ul>
              {individual.map((c: any, idx: number) => (
                <li key={idx}>{c}</li>
              ))}
            </ul>
          )}
        </td>
        <td>
          {family && (
            <ul>
              {family.map((c: any, idx: number) => (
                <li key={idx}>{c}</li>
              ))}
            </ul>
          )}
        </td>
      </tr>
    );
  } else {
    return null;
  }
};

/**
 * Subsection.
 */

const Subsection: FC<any> = ({ name, data, dataset }) => {
  return (
    <>
      <h6 className="mt-8 mb-4 text-xs uppercase italic underline text-gray-500 pl-10 pr-4 font-semibold">{name}</h6>
      <Table className="w-full">
        <thead>
          <tr>
            <TH />
            <TH>Individual</TH>
            <TH>Family</TH>
          </tr>
        </thead>
        <tbody className="text-xs">
          {
            data.totals && (
              <>
                <tr>
                  <TD>Base Period: </TD>
                  <TD>{numberToCurrency(fetchLevel(data.totals[dataset], 'INDIVIDUAL').amount)}</TD>
                  <TD>{numberToCurrency(fetchLevel(data.totals[dataset], 'FAMILY').amount)}</TD>
                </tr>
                {/* <Comments
                individual={fetchLevel(data.totals[dataset], 'INDIVIDUAL').comments}
                family={fetchLevel(data.totals[dataset], 'FAMILY').comments}
              /> */}
              </>
            )
          }
          {data.remainings && (
            <>
              <tr>
                <TD>Remaining: </TD>
                <TD>{numberToCurrency(fetchLevel(data.remainings[dataset], 'INDIVIDUAL').amount)}</TD>
                <TD>{numberToCurrency(fetchLevel(data.remainings[dataset], 'FAMILY').amount)}</TD>
              </tr>
              {/* <Comments
              individual={fetchLevel(data.remainings[dataset], 'INDIVIDUAL').comments}
              family={fetchLevel(data.remainings[dataset], 'FAMILY').comments}
            /> */}
            </>
          )}
        </tbody>
      </Table>
    </>
  )
}

/**
 * NoData.
 */

const NoData: FC = () => {
  return (
    <div className="p-4">
      <div className="bg-gray-50 mx-4 px-4 py-6 rounded-lg rouneded shadow-inner text-center text-gray-400">
        No Data.
      </div>
    </div>
  )
}

/**
 * Deductibles.
 */

interface DeductiblesProps {
  coverage: Coverage;
}

const Deductibles: FC<DeductiblesProps> = props => {
  const { coverage } = props;

  const deductibles = coverage.getPlanDeductibles();

  return (
    <div className="_Deductibles">
      <h5 className="px-4 text-sm font-semibold text-gray-900">
        Deductibles
      </h5>
      {
        !deductibles ? (
          <NoData />
        ) : (
          <>
            <Subsection key="in" name="In Network" dataset="in_network" data={deductibles} />
            <Subsection key="out" name="Out of Network" dataset="out_network" data={deductibles} />
          </>
        )
      }
    </div>
  )
}

/**
 * Maximums.
 */

interface MaximumsProps {
  coverage: Coverage;
}

const Maximums: FC<MaximumsProps> = props => {
  const { coverage } = props;

  const maximums = coverage.getPlanMaximumMinimum();

  return (
    <div className="_Maximums">
      <h5 className="px-4 text-sm font-semibold text-gray-900">
        Maximums
      </h5>
      {
        !maximums ? (
          <NoData />
        ) : (
          <>
            <Subsection key="in" name="In Network" dataset="in_network" data={maximums} />
            <Subsection key="out" name="Out of Network" dataset="out_network" data={maximums} />
          </>
        )
      }
    </div>
  )
}

/**
 * Coinsurance.
 */

interface CoinsuranceProps {
  coverage: Coverage;
}

const Coinsurance: FC<CoinsuranceProps> = props => {
  const { coverage } = props;

  const coinsurance = coverage.getPlanCoinsurance();

  return (
    <div className="_Coinsurance">
      <h5 className="px-4 text-sm font-semibold text-gray-900">
        Coinsurance
      </h5>
      {
        !coinsurance ? (
          <NoData />
        ) : (
          <>
            <Subsection key="in" name="In Network" dataset="in_network" data={coinsurance} />
            <Subsection key="out" name="Out of Network" dataset="out_network" data={coinsurance} />
          </>
        )
      }
    </div>
  )
}

/**
 * Copayment.
 */

interface CopaymentProps {
  coverage: Coverage;
}

const Copayment: FC<CopaymentProps> = props => {
  const { coverage } = props;

  const copayment = coverage.getPlanCopayment();

  return (
    <div className="_Copayment">
      <h5 className="px-4 text-sm font-semibold text-gray-900">
        Copayment
      </h5>
      {
        !copayment ? (
          <NoData />
        ) : (
          <>
            <Subsection key="in" name="In Network" dataset="in_network" data={copayment} />
            <Subsection key="out" name="Out of Network" dataset="out_network" data={copayment} />
          </>
        )
      }
    </div>
  )
}

/**
 * SpendDown.
 */

interface SpendDownProps {
  coverage: Coverage;
}

const SpendDown: FC<SpendDownProps> = props => {
  const { coverage } = props;

  const spendDown = coverage.getPlanSpendDown();

  return (
    <div className="_SpendDown">
      <h5 className="px-4 text-sm font-semibold text-gray-900">
        SpendDown
      </h5>
      {
        !spendDown ? (
          <NoData />
        ) : (
          JSON.stringify(spendDown, null, 2)
        )
      }
    </div>
  )
}

/**
 * AdditionalPolicies.
 */

interface AdditionalPoliciesProps {
  coverage: Coverage;
}

const AdditionalPolicies: FC<AdditionalPoliciesProps> = props => {
  const { coverage } = props;

  const additionalPolicies = coverage.getAdditionalInsurancePolicies();

  return (
    <div className="_AdditionalPolicies">
      <h5 className="px-4 text-sm font-semibold text-gray-900">
        AdditionalPolicies
      </h5>
      {
        !additionalPolicies ? (
          <NoData />
        ) : (
          JSON.stringify(additionalPolicies, null, 2)
        )
      }
    </div>
  )
}

/**
 * Financials.
 */

interface FinancialsProps {
  coverage: Coverage;
};

type ActiveTab = "DEDUCTIBLES" | "MAXIMUMS" | "COINSURANCE" | "COPAYMENT" | "SPEND_DOWN" | "ADDITIONAL_POLICIES";

export const Financials: FC<FinancialsProps> = props => {
  const { coverage } = props;

  const [activeTab, setActiveTab] = useState<ActiveTab>("DEDUCTIBLES");
  const disclaimer = coverage.getPlanDisclaimer();

  return (
    <div className="mt-4 -mx-4">

      <div>
        <h5 className="px-4 font-semibold text-gray-900">
          Financials
        </h5>
      </div>

      {
        coverage.hasPlanFinancials() ? (
          <>
            <div className="mt-4 px-4 flex items-center justify-center">
              <PillTabs
                activeTab={activeTab}
                onChange={setActiveTab as (tab: string) => void}
              >
                <PillTab tab="DEDUCTIBLES">Deductibles</PillTab>
                <PillTab tab="MAXIMUMS">Maximums</PillTab>
                <PillTab tab="COINSURANCE">Coinsurance</PillTab>
                <PillTab tab="COPAYMENT">Copayment</PillTab>
                {/* <PillTab tab="SPEND_DOWN">Spend-Down</PillTab> */}
                {/* <PillTab tab="ADDITIONAL_POLICIES">Additional Policies</PillTab> */}
              </PillTabs>
            </div>

            {/* <FilterPanel /> */}

            <div className="mt-4">
              {
                activeTab === "DEDUCTIBLES" ? (
                  <Deductibles coverage={coverage} />
                ) : activeTab === "COINSURANCE" ? (
                  <Coinsurance coverage={coverage} />
                ) : activeTab === "COPAYMENT" ? (
                  <Copayment coverage={coverage} />
                ) : activeTab === "SPEND_DOWN" ? (
                  <SpendDown coverage={coverage} />
                ) : activeTab === "MAXIMUMS" ? (
                  <Maximums coverage={coverage} />
                ) : activeTab === "ADDITIONAL_POLICIES" ? (
                  <AdditionalPolicies coverage={coverage} />
                ) : null
              }
            </div>
          </>
        ) : (
          <div className="mt-4 px-4 flex items-center justify-center">
            <NoData />
          </div>
        )

      }
    </div>
  );
};

// function toTitleCase(str: string): string {
//   return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
// }

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

function numberToCurrency(amount: number | void): string | null {
  if (!amount) return null;
  return formatter.format(amount);
};

function fetchLevel(data: any, category: any) {
  return (data && data.find((d: any) => d.level === category)) || {};
}
