import React, { FC, useState, useCallback } from "react";
import { gql, useQuery } from "@apollo/client";
import { useLocation, useRouteMatch } from "react-router-dom";
import { Modal, ModalHeader } from "components/Modal";
import { GradientHeader } from "screens/SettingsScreen/GradientHeader";
import { Spinner } from "components/Spinner";
import { NoResults } from "components/NoResults";
import { TableContainer, Table, TH, TD } from "@preferral/ui";
import {
  DropdownButton,
  DropdownItemLink,
  DropdownItemButton,
} from "components/DropdownButton";
import { NewSpecialtyForm } from "./NewSpecialtyForm";
import { AddSpecialtyForm } from "./AddSpecialtyForm";
import { FAIcon } from "components/FAIcon";
import { ScreenTitle } from "context/ScreenTitle";

const DEPARTMENT_SPECIALTIES = gql`
  query GetDepartmentSpecialties($id: UUID4!) {
    department(id: $id) {
      id
      name
      departmentSpecialties {
        id
        specialty {
          id
          name
          isCustom
        }
      }
    }
  }
`;

interface Data {
  department: {
    id: string;
    name: string;
    departmentSpecialties: {
      id: string;
      specialty: {
        id: string;
        name: string;
        isCustom: boolean;
      };
    }[];
  };
}

interface SpecialtiesProps {}

export const Specialties: FC<SpecialtiesProps> = () => {
  const location = useLocation();
  const match = useRouteMatch<{ departmentId: string }>();

  const [currentModal, setCurrentModal] = useState<
    "CREATE_SPECIALTY" | "ADD_SPECIALTY" | null
  >(null);

  const { departmentId } = match.params;
  const { data, loading, error } = useQuery<Data>(DEPARTMENT_SPECIALTIES, {
    variables: { id: departmentId },
  });

  const openCreateModal = useCallback(() => {
    setCurrentModal("CREATE_SPECIALTY");
  }, [setCurrentModal]);

  const openAddModal = useCallback(() => {
    setCurrentModal("ADD_SPECIALTY");
  }, [setCurrentModal]);

  const dismissModal = useCallback(() => {
    setCurrentModal(null);
  }, [setCurrentModal]);

  return (
    <>
      <ScreenTitle title="Settings » Department » Specialties" />
      <Modal
        isOpen={currentModal === "ADD_SPECIALTY"}
        onRequestClose={dismissModal}
      >
        <ModalHeader
          icon="stethoscope"
          title="Add Specialty"
          onClose={dismissModal}
        />
        <div className="px-8 pt-6 pb-8 flex flex-col">
          <div className="py-4">
            Can't find the specialty you're looking for?{" "}
            <button
              type="button"
              onClick={openCreateModal}
              className="font-semibold text-blue-600"
            >
              Create a custom specialty
            </button>
          </div>
          <AddSpecialtyForm
            departmentId={departmentId}
            refetchQueries={[
              {
                query: DEPARTMENT_SPECIALTIES,
                variables: { id: departmentId },
              },
            ]}
            closeModal={dismissModal}
          />
        </div>
      </Modal>
      <Modal
        isOpen={currentModal === "CREATE_SPECIALTY"}
        onRequestClose={dismissModal}
      >
        <ModalHeader
          icon="stethoscope"
          title="Create Specialty"
          onClose={dismissModal}
        />
        <div className="px-8 pt-6 pb-8 flex flex-col">
          <NewSpecialtyForm
            departmentId={departmentId}
            refetchQueries={[
              {
                query: DEPARTMENT_SPECIALTIES,
                variables: { id: departmentId },
              },
            ]}
            closeModal={dismissModal}
          />
        </div>
      </Modal>
      <div className="bg-white box rounded-lg shadow-lg">
        <GradientHeader
          icon="stethoscope"
          title="Specialties"
          subtitle={
            !(data && data.department)
              ? ""
              : `Manage specialties for the ${data.department.name} department.`
          }
        />
        <div className="p-4">
          {loading ? (
            <div className="p-12">
              <Spinner />
            </div>
          ) : error || !(data && data.department) ? (
            <p>Failed to load</p>
          ) : (
            <div>
              <div className="flex justify-end px-3 pb-4">
                <button onClick={openAddModal} className="btn btn-blue">
                  <FAIcon icon="plus" />
                  <span className="ml-2">Add Specialty</span>
                </button>
              </div>
              {data.department.departmentSpecialties.length === 0 ? (
                <NoResults icon="stethoscope" text="No specialties added yet" />
              ) : (
                <TableContainer>
                  <Table>
                    <thead>
                      <tr>
                        <TH>Specialty</TH>
                        <TH />
                      </tr>
                    </thead>
                    <tbody>
                      {data.department.departmentSpecialties.map((ds) => (
                        <tr key={ds.id}>
                          <TD>
                            {ds.specialty.name}{" "}
                            {ds.specialty.isCustom && (
                              <span className="ml-2">(Custom)</span>
                            )}
                          </TD>
                          <TD className="w-40">
                            <DropdownButton label="Actions">
                              <DropdownItemLink
                                to={`${location.pathname}/${ds.id}/referral_form`}
                              >
                                Edit Referral Form
                              </DropdownItemLink>
                            </DropdownButton>
                          </TD>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
