import { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Formik } from "formik";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { VerticalField } from "@preferral/ui";
import { useEffect } from "react";
import { useState } from "react";
import { Modal, ModalHeader } from "components/Modal";
import toast from "react-hot-toast";

// const FETCH_FORM_LOGO = gql`
//   query FetchPatientFormLogoUrl($formId: UUID4!) {
//     patientForm(id: $formId) {
//       id
//       logoUrl
//     }
//   }
// `;

const UPDATE_FORM_LOGO = gql`
  mutation UpdatePatientFormLogo($formId: UUID4!, $logo: Upload!) {
    updatePatientFormLogo(id: $formId, logo: $logo) {
      errors {
        key
        message
      }
      patientForm {
        id
        logoUrl
      }
    }
  }
`;

interface MutationData {
  updatePatientFormLogo: {
    errors?: InputError[];
    patientForm?: {
      id: string;
      logoUrl?: string;
    }
  }
}

/**
 * Preview.
 */

interface PreviewProps {
  file: any;
}

const Preview: FC<PreviewProps> = (props) => {
  const { file } = props;
  const [loading, setLoading] = useState(true);
  const [preview, setPreview] = useState<any>();

  useEffect(() => {
    if (file) {
      let reader = new FileReader();
      reader.onload = () => {
        setLoading(false);
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
      return () => reader.abort();
    }
    return () => { };
  }, [file, setLoading, setPreview]);

  if (!file) {
    return null;
  }

  if (loading) {
    return <p>loading...</p>;
  }

  return (
    <img
      src={preview}
      alt={file.name}
      className="img-thumbnail mt-2"
      height={200}
      width={200}
    />
  );
};

/**
 * UploadPatientFormLogoModal.
 */

interface FormValues {
  file: any;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    file: Yup.mixed().required("Required"),
  })
  .required();

interface UploadPatientFormLogoModalProps {
  patientFormId: string;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

export const UploadPatientFormLogoModal: FC<UploadPatientFormLogoModalProps> = (
  props
) => {
  const { patientFormId, isOpen, onClose, onSuccess } = props;
  const [updateLogo] = useMutation<MutationData>(UPDATE_FORM_LOGO);

  const onSubmit = useCallback((values, formikHelpers) => {
    const { setStatus, setSubmitting } = formikHelpers;
    return updateLogo({
      variables: {
        formId: patientFormId,
        logo: values.file,
      },
    }).then((resp) => {
      if (resp.data?.updatePatientFormLogo.errors) {
        setStatus({ errors: resp.data.updatePatientFormLogo.errors });
      } else if (resp.data?.updatePatientFormLogo.patientForm) {
        // it worked...
        toast.success("Logo updated");
        onSuccess();
      }
      setSubmitting(false);
    });
  }, [updateLogo, patientFormId, onSuccess])

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="signature" title="Upload Logo" onClose={onClose} />
      <div className="UploadPatientFormLogoModal p-4">
        <Formik<FormValues>
          initialValues={{ file: null }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ status, values, handleSubmit, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <VerticalField label="Select File">
                <input
                  id="file"
                  name="file"
                  type="file"
                  onChange={(event: any) => {
                    setFieldValue("file", event.currentTarget.files[0]);
                  }}
                  className="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                />
              </VerticalField>
              {values.file && (
                <VerticalField label="Preview" className="mt-3">
                  <Preview file={values.file} />
                </VerticalField>
              )}

              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  >
                    Update Logo
                  </button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
