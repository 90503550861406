import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { SelectField } from "@preferral/ui";
import toast from "react-hot-toast";
import { analytics } from "lib/analytics";

const SPECIALTIES = gql`
  query GetOrganizationSpecialties {
    specialties(filter: { curated: true }) {
      id
      name
    }
    me {
      id
      organization {
        id
        customSpecialties {
          id
          name
        }
      }
    }
  }
`;

type Specialty = {
  id: string;
  name: string;
};

interface Data {
  specialties: Specialty[];
  me: {
    id: string;
    organization: {
      id: string;
      customSpecialties: Specialty[];
    };
  };
}

function concat(set1: Specialty[], set2: Specialty[]) {
  return set1.concat(set2).sort((a, b) => (a.name <= b.name ? -1 : 1));
}

const ADD_SPECIALTY = gql`
  mutation CreateCustomSpecialty($departmentId: UUID4!, $specialtyId: UUID4!) {
    assignSpecialtyToDepartment(
      departmentId: $departmentId
      specialtyId: $specialtyId
    ) {
      departmentSpecialty {
        id
        specialty {
          id
          name
        }
      }
    }
  }
`;

interface MutationData {
  assignSpecialtyToDepartment: {
    departmentSpecialty: {
      id: string;
      specialty: {
        id: string;
        naem: string;
      };
    };
  };
}

interface MutationVariables {
  departmentId: string;
  specialtyId: string;
}

type AddSpecialtyFormProps = {
  departmentId: string;
  refetchQueries: any;
  closeModal: () => void;
};

const AddSpecialtyForm: React.FC<AddSpecialtyFormProps> = ({
  departmentId,
  refetchQueries,
  closeModal,
}) => {
  const { data, loading } = useQuery<Data>(SPECIALTIES);
  const [assignSpecialty] = useMutation<MutationData, MutationVariables>(
    ADD_SPECIALTY
  );

  return (
    <div className="AddSpecialtyForm">
      <Formik
        initialValues={{ specialtyId: "" }}
        validationSchema={Yup.object().shape({
          specialtyId: Yup.string().required("Required"),
        })}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setStatus({ errors: null });
          assignSpecialty({
            variables: {
              departmentId,
              specialtyId: values.specialtyId,
            },
            refetchQueries,
          }).then((res) => {
            if (res && res.data && res.data.assignSpecialtyToDepartment) {
              // it worked...
              analytics.track("Add Specialty", {
                id: values.specialtyId,
              });
              toast.success("Added Specialty");
              closeModal();
            }
            setSubmitting(false);
          });
        }}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <div className="mt-3">
              <SelectField
                name="specialtyId"
                label="Specialty"
                options={concat(
                  data?.specialties || [],
                  data?.me.organization.customSpecialties || []
                ).map((s) => ({ value: s.id, label: s.name }))}
                isLoading={loading}
              />
            </div>
            <div className="flex items-center justify-end mt-3 py-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-blue"
              >
                Add Specialty
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { AddSpecialtyForm };
