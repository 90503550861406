import React, { FC, useEffect } from "react";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { ConfirmationSuccess } from "./ConfirmationSuccess";
import { ConfirmationFailure } from "./ConfirmationFailure";

const CONFIRM_ACCOUNT = gql`
  mutation ConfirmAccount($id: UUID4!, $token: String!) {
    confirmAccount(id: $id, token: $token) {
      success
      failureReason
    }
  }
`;

type FAILURE_REASON = "ALREADY_CONFIRMED" | "INVALID_TOKEN" | "TOKEN_EXPIRED";

interface Data {
  confirmAccount: {
    success: boolean;
    failureReason: FAILURE_REASON;
  };
}

interface Variables {
  id: string;
  token: string;
}

const DisplayFailure: FC<{ reason: FAILURE_REASON }> = ({ reason }) => {
  switch (reason) {
    case "ALREADY_CONFIRMED":
      return <ConfirmationSuccess />;
    case "TOKEN_EXPIRED":
      return <ConfirmationFailure message="This link has expired" />;
    case "INVALID_TOKEN":
      return <ConfirmationFailure message="Invalid verification URL" />;
    default:
      return <ConfirmationFailure message="Something went wrong" />;
  }
};

export const ConfirmAccountScreen: FC = () => {
  const location = useLocation();
  const { id, token } = qs.parse(location.search);

  const [confirmAccount, { data, loading, error }] = useMutation<
    Data,
    Variables
  >(CONFIRM_ACCOUNT);

  useEffect(() => {
    confirmAccount({
      variables: {
        id: id as string,
        token: token as string
      }
    });
  }, []);

  return (
    <section className="ConfirmAccountScreen hero is-fullheight is-medium is-bold">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            {loading ? (
              <h1>Loading</h1>
            ) : error ? (
              <div>{error.message}</div>
            ) : data?.confirmAccount ? (
              data.confirmAccount.success ? (
                <ConfirmationSuccess />
              ) : (
                <DisplayFailure reason={data.confirmAccount.failureReason} />
              )
            ) : (
              <h1>
                {/* This should never happen. "Error Code 64" is arbitrary, but grep-able */}
                Something Went Wrong. Error Code 64.
              </h1>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
