import React, { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spinner } from "@preferral/ui";

const PROVIDER_PROFILE = gql`
  query ProviderProfile($id: UUID4!) {
    provider(id: $id) {
      id
      prefix
      firstName
      middleInitial
      lastName
      credentialText
      gender
      primarySpecialty {
        id
        name
      }
      specialties {
        id
        name
      }
    }
  }
`;

interface Data {
  provider: ProviderModel;
}

interface ProviderModel {
  id: string;
  prefix: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  credentialText: string;
  gender: string;
  primarySpecialty?: SpecialtyModel;
  specialties: SpecialtyModel[];
}

interface SpecialtyModel {
  id: string;
  name: string;
}

interface Variables {
  id: string;
}

interface ProviderProfileProps {
  providerId: string;
}

export const ProviderProfile: FC<ProviderProfileProps> = (props) => {
  const { providerId } = props;

  const { data, loading, error } = useQuery<Data, Variables>(PROVIDER_PROFILE, {
    variables: { id: providerId },
  });

  return (
    <>
      {loading ? (
        <div className="p-8 text-center">
          <Spinner />
        </div>
      ) : error || !data?.provider ? (
        <p>Failed to load</p>
      ) : (
        <>
          <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  First Name
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {data.provider.firstName}
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Last Name</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {data.provider.lastName}
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Primary Specialty
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {data.provider.primarySpecialty?.name || "-"}
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Additional Specialties
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <ul>
                    {data.provider.specialties
                      .filter(
                        (s) => s.id !== data.provider.primarySpecialty?.id
                      )
                      .map((s) => (
                        <li key={s.id}>{s.name}</li>
                      ))}
                  </ul>
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Gender</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {data.provider.gender || "-"}
                </dd>
              </div>
            </dl>
          </div>
        </>
      )}
    </>
  );
};
