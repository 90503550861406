interface TwoToneColor {
  light: string;
  dark: string;
}

export const colorClassNames: Record<AppColor, TwoToneColor> = {
  blue: {
    light: "text-blue-400",
    dark: "text-blue-800",
  },
  gray: {
    light: "text-gray-400",
    dark: "text-gray-800",
  },
  orange: {
    light: "text-orange-400",
    dark: "text-orange-800",
  },
  green: {
    light: "text-green-400",
    dark: "text-green-800",
  },
  red: {
    light: "text-red-400",
    dark: "text-red-800",
  },
  yellow: {
    light: "text-yellow-400",
    dark: "text-yellow-800",
  },
  teal: {
    light: "text-teal-400",
    dark: "text-teal-800",
  },
  purple: {
    light: "text-purple-400",
    dark: "text-purple-800",
  },
  indigo: {
    light: "text-indigo-400",
    dark: "text-indigo-800",
  },
  pink: {
    light: "text-pink-400",
    dark: "text-pink-800",
  },
};
