import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { AddTaskForm } from "./AddTaskForm";

interface NewTaskModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const NewTaskModal: FC<NewTaskModalProps> = (props) => {
  const { isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="check-double" title="Add Task" onClose={onClose} />
      <div className="p-4">
        <AddTaskForm onCreate={onClose} />
      </div>
    </Modal>
  );
};
