import { FC, useState, useCallback } from "react";
import { gql, useMutation, useQuery } from '@apollo/client';
import toast from "react-hot-toast";
import {
  Spinner,
  FAIcon,
  TableContainer,
  Table,
  TH,
  TD,
  Button,
  VerticalField,
  makeAppendItems,
  Badge,
  DropdownButton,
  DropdownItemButton,
  usePersistedState,
  StandardOption,
  InputPlainSelect
} from "@preferral/ui";
import { ZERO_WIDTH_SPACE } from '@preferral/common';
import { ScreenTitle } from 'context/ScreenTitle';
import { NoResults } from "components/NoResults";
import { FilterPanel, defaultFilter, FilterModel, PacketSubmissionStatus, statusLabels } from './FilterPanel';
import { SendPacketModal } from './SendPacketModal';
import { EditPacketModal } from './EditPacketModal';
import { Link } from "react-router-dom";
import { hMmA, mmDdYy, mmDdYyyy } from "lib/dateFormatters";
import { formatPhoneNumber } from "@preferral/common";
import { AddTagDropdown } from "./AddTagDropdown";
import { Tag } from "components/Tag";

const ADD_TAG = gql`
  mutation AddPatientPacketSubmissionTag(
    $patientPacketSubmissionId: UUID4!
    $patientPacketSubmissionTagId: UUID4!
  ) {
    addPatientPacketSubmissionTag(
      patientPacketSubmissionId: $patientPacketSubmissionId
      patientPacketSubmissionTagId: $patientPacketSubmissionTagId
    ) {
      errors {
        key
        message
      }
      patientPacketSubmission {
        id
        patientPacketSubmissionTags {
          id
          label
          color
        }
      }
    }
  }
`;

interface AddMutData {
  addPatientPacketSubmissionTag: {
    errors: void | InputError[];
    patientPacketSubmission: void | { id: string; patientPacketSubmissionTags: TagModel[] };
  };
}

const REMOVE_TAG = gql`
  mutation RemovePatientPacketSubmissionTag(
    $patientPacketSubmissionId: UUID4!
    $patientPacketSubmissionTagId: UUID4!
  ) {
    removePatientPacketSubmissionTag(
      patientPacketSubmissionId: $patientPacketSubmissionId
      patientPacketSubmissionTagId: $patientPacketSubmissionTagId
    ) {
      errors {
        key
        message
      }
      patientPacketSubmission {
        id
        patientPacketSubmissionTags {
          id
          label
          color
        }
      }
    }
  }
`;

interface RemoveMutData {
  removePatientPacketSubmissionTag: {
    errors: void | InputError[];
    patientPacketSubmission: void | { id: string; patientPacketSubmissionTags: TagModel[] };
  };
}

const PAGE_SIZE = 10;

const LIST_PATIENT_PACKET_SUBMISSIONS = gql`
  query ListPatientPacketSubmissions(
    $first: Int
    $after: UUID4
    $filter: PatientPacketSubmissionsFilter
    $sortBy: String
  ) {
    patientPacketSubmissionTags(first: 100) {
      items {
        id
        label
        color
      }
    }
    patientPacketSubmissions(first: $first, after: $after, filter: $filter, sortBy: $sortBy) {
      cursor
      endOfList
      items {
        id
        title
        patientFirstName
        patientLastName
        patientDob
        appointmentDate
        insertedAt
        resentAt
        openedAt
        completedAt
        archivedAt
        status
        patientFormSubmissionsCount
        completedPatientFormsCount
        patientAccount {
          id
          email
          phoneNumber
        }
        patientPacketSubmissionTags {
          id
          label
          color
        }
      }
    }
  }
`;

interface Data {
  patientPacketSubmissionTags: Paginated<TagModel>;
  patientPacketSubmissions: Paginated<PatientPacketSubmissionModel>;
}

const RESEND_PACKET = gql`
  mutation ResendPatientPacket($id: UUID4!) {
    resendPatientPacket(id: $id) {
      errors {
        key
        message
      }
      patientPacketSubmission {
        id
        resentAt
      }
    }
  }
`;

interface ResendMutationData {
  resendPatientPacket: {
    errors?: InputError[];
    patientPacketSubmission?: {
      id: string;
    }
  }
}

interface ResendMutationVariables {
  id: string;
}

const ARCHIVE_PACKET = gql`
  mutation ArchivePatientPacketSubmission($id: UUID4!) {
    archivePatientPacketSubmission(id: $id) {
      errors {
        key
        message
      }
      patientPacketSubmission {
        id
        archivedAt
      }
    }
  }
`;

interface ArchiveMutationData {
  archivePatientPacketSubmission: {
    errors?: InputError[];
    patientPacketSubmission?: {
      id: string;
      archivedAt: string | void;
    }
  }
}

interface ArchiveMutationVariables {
  id: string;
}

const UNARCHIVE_PACKET = gql`
  mutation UnarchivePatientPacketSubmission($id: UUID4!) {
    unarchivePatientPacketSubmission(id: $id) {
      errors {
        key
        message
      }
      patientPacketSubmission {
        id
        archivedAt
      }
    }
  }
`;

interface UnarchiveMutationData {
  unarchivePatientPacketSubmission: {
    errors?: InputError[];
    patientPacketSubmission?: {
      id: string;
      archivedAt: string | void;
    }
  }
}

interface UnarchiveMutationVariables {
  id: string;
}

interface PatientPacketSubmissionModel {
  id: string;
  title: string;
  patientFirstName: string | null;
  patientLastName: string | null;
  patientDob: string | null;
  appointmentDate: string | null;
  insertedAt: string;
  status: PacketSubmissionStatus;
  resentAt: string | void;
  openedAt?: string;
  completedAt?: string;
  archivedAt: string | void;
  patientFormSubmissionsCount: number;
  completedPatientFormsCount: number;
  patientAccount: {
    id: string;
    email?: string;
    phoneNumber?: string;
  };
  patientPacketSubmissionTags: TagModel[];
}

export interface TagModel {
  id: string;
  label: string;
  color: string;
}

type SortByModel = "SENT_AT_DESC" | "SENT_AT_ASC" | "APPT_DATE_DESC" | "APPT_DATE_ASC";

const sortByOptions: StandardOption<SortByModel>[] = [
  { label: "Sent At (Newest First)", value: "SENT_AT_DESC" },
  { label: "Sent At (Oldest First)", value: "SENT_AT_ASC" },
  { label: "Appointment Date (Farthest First)", value: "APPT_DATE_DESC" },
  { label: "Appointment Date (Earliest First)", value: "APPT_DATE_ASC" }
]

type ActiveModal = { type: "SEND_PACKET" } | { type: "EDIT_PACKET", patientPacketSubmissionId: string };

const statusColors: Record<PacketSubmissionStatus, AppColor> = {
  UNOPENED: "orange",
  PENDING: "purple",
  COMPLETED: "green"
};

interface PatientPacketsScreenProps { };

export const PatientPacketsScreen: FC<PatientPacketsScreenProps> = props => {
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const closeModal = useCallback(() => setActiveModal(null), []);

  const [sortBy, setSortBy] = usePersistedState<SortByModel>("patientPacketsSortBy", "SENT_AT_DESC");
  const [filter, setFilter] = usePersistedState<FilterModel>("patientPacketsFilter", defaultFilter);

  const { data, loading, error, fetchMore, refetch } = useQuery<Data>(
    LIST_PATIENT_PACKET_SUBMISSIONS,
    { variables: { first: PAGE_SIZE, filter, sortBy } }
  );

  const [resendPacketMut, resendPacketMutMeta] = useMutation<ResendMutationData, ResendMutationVariables>(RESEND_PACKET);

  const resendPacket = useCallback((id: string) => {
    return resendPacketMut({ variables: { id } }).then(resp => {
      if (resp.data?.resendPatientPacket.errors) {
        toast.error(resp.data.resendPatientPacket.errors[0].message);
      } else if (resp.data?.resendPatientPacket.patientPacketSubmission) {
        // it worked...
        toast.success("Packet resent to patient.")
      }
    });
  }, [resendPacketMut]);

  const [archivePacketMut, archivePacketMutMeta] = useMutation<ArchiveMutationData, ArchiveMutationVariables>(ARCHIVE_PACKET);

  const archivePacket = useCallback((id: string) => {
    return archivePacketMut({ variables: { id } }).then(resp => {
      if (resp.data?.archivePatientPacketSubmission.errors) {
        toast.error(resp.data.archivePatientPacketSubmission.errors[0].message)
      } else if (resp.data?.archivePatientPacketSubmission.patientPacketSubmission) {
        // it worked...
        toast.success("Packet submission archived.");
      }
    });
  }, [archivePacketMut])

  const [unarchivePacketMut, unarchivePacketMutMeta] = useMutation<UnarchiveMutationData, UnarchiveMutationVariables>(UNARCHIVE_PACKET);

  const unarchivePacket = useCallback((id: string) => {
    return unarchivePacketMut({ variables: { id } }).then(resp => {
      if (resp.data?.unarchivePatientPacketSubmission.errors) {
        toast.error(resp.data.unarchivePatientPacketSubmission.errors[0].message)
      } else if (resp.data?.unarchivePatientPacketSubmission.patientPacketSubmission) {
        // it worked...
        toast.success("Packet submission archived.");
      }
    });
  }, [unarchivePacketMut])

  const [addTagMut] = useMutation<AddMutData>(ADD_TAG);
  const [removeTagMut] = useMutation<RemoveMutData>(REMOVE_TAG);

  const onAddTag = useCallback(
    async (patientPacketSubmissionId: string, tagId: string) => {
      const variables = {
        patientPacketSubmissionId,
        patientPacketSubmissionTagId: tagId,
      };

      try {
        const { data } = await addTagMut({
          variables, refetchQueries: [
            {
              query: LIST_PATIENT_PACKET_SUBMISSIONS,
              variables: { first: PAGE_SIZE, filter }
            }
          ]
        });

        for (let error of data?.addPatientPacketSubmissionTag.errors || []) {
          toast.error(error.message);
        }
        // NB: No op on success
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
      }
    },
    [addTagMut, filter]
  );

  const onRemoveTag = useCallback(
    async (patientPacketSubmissionId: string, tagId: string) => {
      const variables = {
        patientPacketSubmissionId,
        patientPacketSubmissionTagId: tagId,
      };

      try {
        const { data } = await removeTagMut({ variables });

        for (let error of data?.removePatientPacketSubmissionTag.errors || []) {
          toast.error(error.message);
        }
        // NB: No op on success
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
      }
    },
    [removeTagMut]
  );

  return (
    <>
      <ScreenTitle title="Patient Packets" />
      <SendPacketModal
        isOpen={activeModal?.type === "SEND_PACKET"}
        onClose={closeModal}
        onSuccess={refetch}
      />

      {activeModal?.type === "EDIT_PACKET" ? (
        <EditPacketModal
          isOpen
          patientPacketSubmissionId={activeModal.patientPacketSubmissionId}
          onClose={closeModal}
          onSuccess={refetch}
        />
      ) : null}

      <div className="_PatientPacketsScreen text-left container mx-auto">
        <div className="flex justify-between items-start py-4 px-2 lg:px-4">
          <FilterPanel value={filter} onChange={setFilter} isLoading={loading} dataLoading={loading} filterData={{
            tags: data?.patientPacketSubmissionTags.items
          }} />
          <div spec-id="patient-forms-action">
            <VerticalField label={ZERO_WIDTH_SPACE}>
              <Button color="blue" onClick={() => setActiveModal({ type: "SEND_PACKET" })}>
                <span className="mr-2">
                  <FAIcon icon="paper-plane" />
                </span>
                Send Form
              </Button>
            </VerticalField>
          </div>
        </div>
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.patientPacketSubmissions ? (
          <p>Failed to load</p>
        ) : (
          <div className="pb-8 lg:px-4 pt-2">
            {/* <pre className="text-xs">
              {JSON.stringify(data, null, 2)}
            </pre> */}
            <div className="flex items-center pb-2">
              <label className="font-semibold text-sm">
                <span className="text-gray-500 mr-2">
                  <FAIcon icon="arrow-down-wide-short" />
                </span>
                Sort By:
              </label>
              <InputPlainSelect
                value={sortBy}
                onChange={setSortBy}
                className="ml-2 py-1"
                options={sortByOptions}
              />
            </div>

            {data.patientPacketSubmissions.items.length === 0 ? (
              <NoResults
                icon="file-alt"
                text="No Patient Packet Submissions"
              />
            ) : (
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <TH>Packet</TH>
                      <TH>Recipient</TH>
                      <TH>Tags</TH>
                      <TH>Sent At</TH>
                      <TH />
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {data.patientPacketSubmissions.items.map((packet) => (
                      <tr key={packet.id}>
                        <TD>
                          <Link to={`/o/patient_packets/${packet.id}`} className="text-lg text-blue-500 underline cursor-pointer">
                            {packet.title}
                          </Link>
                          <Badge color={statusColors[packet.status]} className="ml-2">
                            {statusLabels[packet.status]}
                          </Badge>
                          {
                            packet.appointmentDate ? (
                              <p className="text-xs text-gray-700 font-semibold"><span className="text-gray-500 italic font-normal"><FAIcon icon="calendar-alt" className="mr-1" />Appt Date: </span>{mmDdYyyy(packet.appointmentDate)}</p>
                            ) : null
                          }
                          {
                            packet.archivedAt ? (
                              <Badge color="gray" className="ml-2">
                                Archived
                              </Badge>
                            ) : null
                          }
                        </TD>
                        <TD>
                          <div className="-mt-1">
                            <div className="flex items-center mt-1">
                              <span className="mr-2 w-4 text-center text-gray-400">
                                <FAIcon icon="id-card" />
                              </span>
                              <p className="font-semibold text-gray-700">{fullName(packet.patientFirstName, packet.patientLastName) || "-"} {packet.patientDob ? <span className="ml-2 text-gray-500 text-xs">(DOB: <span className="font-semibold">{mmDdYyyy(packet.patientDob)})</span></span> : null}</p>
                            </div>
                            <div className="flex items-center mt-1">
                              <span className="mr-2 w-4 text-center text-gray-400">
                                <FAIcon icon="mobile-alt" />
                              </span>
                              {
                                packet.patientAccount.phoneNumber ? (
                                  <p className="font-semibold text-gray-700">{formatPhoneNumber(packet.patientAccount.phoneNumber)}</p>
                                ) : (
                                  "-"
                                )
                              }
                            </div>
                            <div className="flex items-center mt-1">
                              <span className="mr-2 w-4 text-center text-gray-400">
                                <FAIcon icon="at" />
                              </span>
                              {
                                packet.patientAccount.email ? (
                                  <p className="font-semibold text-gray-700">{packet.patientAccount.email}</p>
                                ) : "-"
                              }
                            </div>
                          </div>
                        </TD>
                        <TD>
                          <div className="flex">
                            <div className="flex flex-wrap flex-grow">
                              {packet.patientPacketSubmissionTags.map((tag) => (
                                <div key={tag.id} className="inline-block">
                                  <Tag
                                    label={tag.label}
                                    color={tag.color}
                                    onDelete={() => onRemoveTag(packet.id, tag.id)}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="flex-shrink-0">
                              <AddTagDropdown
                                items={
                                  unusedTags(
                                    packet.patientPacketSubmissionTags,
                                    data.patientPacketSubmissionTags.items
                                  )}
                                onSelect={(tag) => onAddTag(packet.id, tag.id)}
                              />
                            </div>
                          </div>
                        </TD>
                        <TD>
                          <p><span className="font-semibold text-gray-400 text-xs">Sent:</span> {mmDdYy(packet.insertedAt)} at {hMmA(packet.insertedAt)}</p>
                          {packet.resentAt ? (
                            <p className="italic mt-1 text-gray-500"><span className="font-semibold text-gray-400 text-xs">Resent:</span> {mmDdYy(packet.resentAt)} at {hMmA(packet.resentAt)}</p>) : null}
                        </TD>
                        <TD>
                          <DropdownButton label="Actions">
                            <DropdownItemButton
                              onClick={() => setActiveModal({ type: "EDIT_PACKET", patientPacketSubmissionId: packet.id })}
                            >
                              <span className="mr-2">
                                <FAIcon icon="pencil-alt" />
                              </span>
                              Edit Packet
                            </DropdownItemButton>
                            <DropdownItemButton
                              disabled={resendPacketMutMeta.loading}
                              isLoading={resendPacketMutMeta.loading}
                              onClick={() => resendPacket(packet.id)}
                            >
                              <span className="mr-2">
                                <FAIcon icon="share" />
                              </span>
                              Resend Packet
                            </DropdownItemButton>
                            {
                              packet.archivedAt ? (
                                <DropdownItemButton
                                  disabled={unarchivePacketMutMeta.loading}
                                  isLoading={unarchivePacketMutMeta.loading}
                                  onClick={() => unarchivePacket(packet.id)}
                                >
                                  <span className="mr-2">
                                    <FAIcon icon="inbox" />
                                  </span>
                                  Unarchive
                                </DropdownItemButton>
                              ) : (
                                <DropdownItemButton
                                  disabled={archivePacketMutMeta.loading}
                                  isLoading={archivePacketMutMeta.loading}
                                  color="red"
                                  onClick={() => archivePacket(packet.id)}
                                >
                                  <span className="mr-2">
                                    <FAIcon icon="archive" />
                                  </span>
                                  Archive
                                </DropdownItemButton>
                              )
                            }
                          </DropdownButton>
                        </TD>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {data.patientPacketSubmissions.items.length > 0 ? (
                  <div className="bg-white border-gray-300 border-t p-2 text-center text-gray-500">
                    {data.patientPacketSubmissions.endOfList ? (
                      <p>End of List</p>
                    ) : (
                      <div className="max-w-sm mx-auto">
                        <Button
                          type="button"
                          size="sm"
                          kind="secondary"
                          color="blue"
                          className="w-full"
                          onClick={() =>
                            fetchMore({
                              query: LIST_PATIENT_PACKET_SUBMISSIONS,
                              variables: {
                                first: PAGE_SIZE,
                                after: data.patientPacketSubmissions.cursor,
                                filter,
                              },
                              updateQuery,
                            })
                          }
                        >
                          Load More
                        </Button>
                      </div>
                    )}
                  </div>
                ) : null}
              </TableContainer>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const updateQuery = makeAppendItems<Data>("patientPacketSubmissions");

function unusedTags(currentTags: TagModel[], allTags: TagModel[]) {
  const currentIds = currentTags.map((t) => t.id);
  return allTags.filter((t) => !currentIds.includes(t.id));
}

function fullName(firstName: string | null, lastName: string | null) {
  return [firstName, lastName].filter((n) => n).join(" ");
}
