import React, { FC, useState, useCallback } from "react";
import { gql, useQuery } from "@apollo/client";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { GradientHeader } from "../../GradientHeader";
import { NoResults } from "components/NoResults";
import { Modal, ModalHeader } from "components/Modal";
import { TableContainer, Table, TH, TD } from "@preferral/ui";
import { DropdownButton, DropdownItemButton } from "components/DropdownButton";
import { NewLocationForm } from "./NewLocationForm";
import { Spinner } from "components/Spinner";
import { FAIcon } from "components/FAIcon";
import { ScreenTitle } from "context/ScreenTitle";

export const ORGANIZATION_LOCATIONS = gql`
  query OrganizationLocations(
    $first: Int
    $after: UUID4
    $filter: LocationsFilter
  ) {
    organizationLocations(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        name
        insertedAt
      }
    }
  }
`;

interface LocationsFilter {}

interface Data {
  organizationLocations: {
    cursor?: string;
    endOfList: boolean;
    items: {
      id: string;
      name: string;
      insertedAt: string;
    }[];
  };
}

interface Variables {
  first?: number;
  after?: string;
  filter?: LocationsFilter;
}

export type Location = {
  id: string;
  name: string;
  insertedAt: string;
};

type LocationsState = {
  currentModal: null | "CREATE_LOCATION" | "EDIT_LOCATION";
};

interface LocationsProps {}

export const Locations: FC<LocationsProps> = () => {
  const [newModalOpen, setNewModalOpen] = useState(false);
  const closeModal = useCallback(() => setNewModalOpen(false), [
    setNewModalOpen,
  ]);

  const { data, loading, error } = useQuery<Data, Variables>(
    ORGANIZATION_LOCATIONS
  );
  return (
    <>
      <ScreenTitle title="Settings » Locations" />
      <Modal isOpen={newModalOpen} onRequestClose={closeModal}>
        <ModalHeader
          icon="map-marker-alt"
          title="Add Location"
          onClose={closeModal}
        />
        <div className="px-8 pt-6 pb-8 flex flex-col">
          <NewLocationForm
            closeModal={closeModal}
            refetchQueries={[{ query: ORGANIZATION_LOCATIONS }]}
          />
        </div>
      </Modal>
      <div className="bg-white box rounded-lg shadow-lg">
        <GradientHeader
          icon="map-marker-alt"
          title="Locations"
          subtitle="Manage your organization's locations"
        />
        <div className="p-4">
          {loading ? (
            <div className="p-12 text-center">
              <Spinner />
            </div>
          ) : error || !(data && data.organizationLocations) ? (
            <p>Failed to load</p>
          ) : (
            <div>
              <div className="flex justify-end px-3 pb-4">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={() => setNewModalOpen(true)}
                >
                  <span className="mr-2">
                    <FAIcon icon="plus" />
                  </span>
                  Add Location
                </button>
              </div>
              {data.organizationLocations.items.length === 0 ? (
                <NoResults
                  icon="map-marker-alt"
                  text="No locations added yet"
                />
              ) : (
                <TableContainer>
                  <Table>
                    <thead>
                      <tr>
                        <TH>Name</TH>
                        <TH>Address</TH>
                        <TH />
                      </tr>
                    </thead>
                    <tbody>
                      {data.organizationLocations.items.map((location) => (
                        <tr key={location.id}>
                          <TD>{location.name}</TD>
                          <TD>
                            {format(parseISO(location.insertedAt), "M/d/yy")}
                          </TD>
                          <TD className="w-40">
                            <DropdownButton label="Actions">
                              <DropdownItemButton
                                onClick={() => console.log("TODO :)")}
                              >
                                Remove Location
                              </DropdownItemButton>
                            </DropdownButton>
                          </TD>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
