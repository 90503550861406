import { FC, useCallback } from "react";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { gql, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Button, TextField } from "@preferral/ui";
import { useAppointmentRequest } from "../../AppointmentRequestContext";

const WITHDRAW_REQUEST = gql`
  mutation WithdrawRequest($appointmentRequestId: UUID4!, $reason: String!) {
    withdrawAppointmentRequest(
      appointmentRequestId: $appointmentRequestId
      reason: $reason
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
      }
    }
  }
`;

interface MutationData {
  withdrawAppointmentRequest: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appointmentRequestId: string;
  reason: string;
}

interface FormValues {
  reason: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    reason: Yup.string().required("Required"),
  })
  .required();

interface WithdrawRequestFormProps {
  onCancel(): void;
  onSuccess(): void;
}

export const WithdrawRequestForm: FC<WithdrawRequestFormProps> = (props) => {
  const { onCancel, onSuccess } = props;

  const {
    appointmentRequest: { id: appointmentRequestId, withdrawalReason },
    refetch,
  } = useAppointmentRequest();

  const [withdrawRequest] = useMutation<MutationData, MutationVariables>(
    WITHDRAW_REQUEST
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      return withdrawRequest({
        variables: { appointmentRequestId, reason: values.reason },
      }).then((resp) => {
        if (resp.data?.withdrawAppointmentRequest.errors) {
          setStatus({ errors: resp.data.withdrawAppointmentRequest.errors });
        } else if (resp.data?.withdrawAppointmentRequest.appointmentRequest) {
          // it worked...
          toast.success("Request withdrawn");
          refetch();
          return onSuccess();
        }
        setSubmitting(false);
      });
    },
    [appointmentRequestId, withdrawRequest, refetch, onSuccess]
  );
  return (
    <Formik<FormValues>
      initialValues={{
        reason: withdrawalReason || "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, status }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <div className="mt-3">
            <TextField name="reason" label="Withdrawal Reason" />
          </div>
          <div className="flex items-center justify-end mt-3 gap-3">
            <Button
              type="button"
              kind="primary"
              color="gray"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              kind="primary"
              color="blue"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
