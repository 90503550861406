import { FC, useState } from "react";
import { FilterField, Spinner } from "@preferral/ui";
import { PatientSearchInput } from "components/PatientSearchInput";
import { useHistory } from "react-router-dom";
import { useSyncPatientMember } from "../EConsultShowScreen/useSyncPatientMember";
import { PatientSearchResult } from "components/PatientSearchInput";
import { toast } from "react-hot-toast";

interface FilterPanelProps { };

export const FilterPanel: FC<FilterPanelProps> = props => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");

  const { syncPatientMember, loading } = useSyncPatientMember();

  const goToDetails = async (patient: PatientSearchResult) => {
    let patientMemberId = patient.patient?.patientMembers[0].id
    if (!patientMemberId && patient.amdPatient) {
      const { patientMember, errors } = await syncPatientMember(patient.amdPatient.id);
      if (patientMember) {
        patientMemberId = patientMember.id
      } else if (errors) {
        toast.error(errors[0].message);
      } else {
        toast.error("Error loading patient details");
      }
    }
    if (patientMemberId) {
      history.push(`/o/patients/${patientMemberId}`)
    }
  }

  return (
    <FilterField icon="search" label="Search" className="w-64">
      <PatientSearchInput
        value={searchTerm}
        onChange={setSearchTerm}
        onPatientClick={goToDetails}
        inputProps={{ placeholder: "Search..." }}
        externalLoading={loading}
      />
    </FilterField>
  );
};
