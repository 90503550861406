import { useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import omitBy from "lodash.omitby";
import {
  LocationModel,
  LocationOptionGqlFields,
} from "components/LocationOption";

const LOCATIONS = gql`
  query Locations($departmentId: UUID4!, $locationsFilter: LocationsFilter) {
    departmentLocations(departmentId: $departmentId, filter: $locationsFilter, first: 100) {
      cursor
      endOfList
      items {
        ${LocationOptionGqlFields}
      }
    }
  }
`;

interface LocationsData {
  departmentLocations: Paginated<LocationModel>;
}

interface LocationsVariables {
  departmentId: string;
  locationsFilter?: { providerId?: string };
}

export function useReferringLocations(filter: {
  referringDepartmentId: string | void;
  referringProviderId: string | void;
}) {
  const { referringDepartmentId, referringProviderId } = filter;
  const [fetchLocations, locationsData] = useLazyQuery<
    LocationsData,
    LocationsVariables
  >(LOCATIONS);

  // When referringDepartmentId or referringProviderId changes, (re)fetch locations.
  useEffect(() => {
    if (referringDepartmentId) {
      const locationsFilter = omitBy(
        { providerId: referringProviderId },
        val => !val
      );
      fetchLocations({
        variables: {
          departmentId: referringDepartmentId,
          locationsFilter,
        },
      });
    }
  }, [fetchLocations, referringDepartmentId, referringProviderId]);

  return locationsData;
}
