import { useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  ProviderModel,
  ProviderOptionGqlFields,
} from "components/ProviderOption";

const PROVIDERS = gql`
  query Providers($departmentId: UUID4!) {
    departmentProviders(departmentId: $departmentId, first: 100) {
      cursor
      endOfList
      items {
        ${ProviderOptionGqlFields}
      }
    }
  }
`;

interface ProvidersData {
  departmentProviders: Paginated<ProviderModel>;
}

interface ProvidersVariables {
  departmentId: string;
}

export function useReferringProviders(filter: {
  referringDepartmentId: string | void;
}) {
  const { referringDepartmentId } = filter;
  const [fetchProviders, providersData] = useLazyQuery<
    ProvidersData,
    ProvidersVariables
  >(PROVIDERS);

  // When departmentId changes, (re)fetch providers
  useEffect(() => {
    if (referringDepartmentId) {
      fetchProviders({
        variables: { departmentId: referringDepartmentId },
      });
    }
  }, [fetchProviders, referringDepartmentId]); //

  return providersData;
}
