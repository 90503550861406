import React, { useRef, useMemo, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import useOnClickOutside from "use-onclickoutside";
import {
  Carrier,
  CarrierGroup,
  HealthPlan,
  CarriersFilter,
  HealthPlansFilter
} from "./model";
import { useHealthPlanPicker } from './context';
import { HealthPlanFinderNav } from "./HealthPlanFinderNav";
import { useDebounce } from "use-debounce";
import { Spinner } from "components/Spinner";
import { FAIcon } from "@preferral/ui";

type HealthPlanFinderProps = {};

const LIST_CARRIERS = gql`
  query ListCarriersAndHealthPlans(
    $carriersFilter: CarriersFilter
    $carrierGroupsFilter: CarrierGroupsFilter
  ) {
    carrierGroups(filter: $carrierGroupsFilter) {
      id
      name
      isPopular
    }
    carriers(filter: $carriersFilter) {
      id
      name
      ribbonCarrierName
      isPopular
    }
  }
`;

interface Data {
  carrierGroups: CarrierGroup[];
  carriers: Carrier[];
}

interface Variables {
  carriersFilter: CarriersFilter;
  carrierGroupsFilter: {
    searchTerm: string;
  };
}

const CarrierResults: React.FC<{
  searchTerm: string;
  type: string;
  carrierGroup?: CarrierGroup | null;
}> = ({ searchTerm, type, carrierGroup }) => {
  const { dispatch } = useHealthPlanPicker();
  const [debouncedSearchTerm] = useDebounce(searchTerm, 400);
  const carriersFilter: CarriersFilter = {
    searchTerm: debouncedSearchTerm,
    type
  };
  if (carrierGroup) {
    carriersFilter.carrierGroupId = carrierGroup.id;
  }
  const carrierGroupsFilter = { searchTerm: debouncedSearchTerm };
  const { data, loading, error } = useQuery<Data, Variables>(LIST_CARRIERS, {
    variables: { carriersFilter, carrierGroupsFilter }
  });

  return (
    <div>
      {loading ? (
        <div className="p-12 text-center">
          <div className="mx-auto">
            <Spinner />
          </div>
        </div>
      ) : error || !(data && data.carriers && data.carrierGroups) ? (
        {}
      ) : (
        <>
          <FancyList<Carrier | CarrierGroup>
            label="carriers"
            items={[...data.carriers, ...data.carrierGroups]}
            onClickItem={item => {
              if (item.__typename === "Carrier") {
                dispatch({ type: "CARRIER_CLICKED", carrier: item });
              } else if (item.__typename === "CarrierGroup") {
                dispatch({
                  type: "CARRIER_GROUP_CLICKED",
                  carrierGroup: item
                });
              }
            }}
          />
        </>
      )}
    </div>
  );
};

const LIST_HEALTH_PLANS = gql`
  query ListHealthPlans($filter: HealthPlansFilter) {
    healthPlans(filter: $filter) {
      id
      name
      isPopular
    }
  }
`;

interface HealthPlansData {
  healthPlans: HealthPlan[];
}

interface HealthPlansVariables {
  filter: HealthPlansFilter;
}

type FancyListProps<T = HealthPlan | Carrier | CarrierGroup> = {
  items: T[];
  label: string;
  onClickItem: (item: T) => void;
};

type FancyList<T = HealthPlan | Carrier | CarrierGroup> = React.FC<
  FancyListProps<T>
>;

function groupedByLetter<T extends HealthPlan | Carrier | CarrierGroup>(
  items: T[]
): { [x in string]: T[] } {
  const grouped: { [x in string]: T[] } = {};
  items.forEach(item => {
    const first = item.name.slice(0, 1).toUpperCase();
    const numRegex = new RegExp("[0-9]");
    if (numRegex.test(first)) {
      if (grouped["#"] === undefined) {
        grouped["#"] = [];
      }
      grouped["#"].push(item);
    } else {
      if (grouped[first] === undefined) {
        grouped[first] = [];
      }
      grouped[first].push(item);
    }
  });
  return grouped;
}

function FancyList<T extends HealthPlan | Carrier | CarrierGroup>(
  props: FancyListProps<T>
) {
  const { items, label, onClickItem } = props;
  const groupedItems = useMemo(() => {
    return {
      popular: items.filter(item => item.isPopular),
      byLetter: groupedByLetter(items)
    };
  }, [items]);

  return (
    <>
      {groupedItems.popular && groupedItems.popular.length > 0 ? (
        <>
          <h6 className="font-semibold px-4 py-3 text-gray-500 text-sm">
            popular {label}
          </h6>
          <ul>
            {groupedItems.popular.map(item => (
              <li
                key={`popular-${item.id}`}
                className="flex items-center justify-between px-4 py-1 cursor-pointer hover:bg-blue-200"
                onClick={() => onClickItem(item)}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </>
      ) : null}
      <h6 className="font-semibold mt-3 px-4 py-3 text-gray-500 text-sm">
        all {label}
      </h6>
      <ul>
        {Object.keys(groupedItems.byLetter).map(letter => (
          <div key={letter}>
            <h6 className="text-xl mt-4 px-4">{letter}</h6>
            <ul>
              {groupedItems.byLetter[letter].map(item => (
                <li
                  key={item.id}
                  className="flex items-center justify-between px-4 py-1 cursor-pointer hover:bg-blue-200"
                  onClick={() => onClickItem(item)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </ul>
    </>
  );
}
// const FancyList: FancyList = ({ items, onClickItem }) => {

// }

const HealthPlanResults: React.FC<{
  searchTerm: string;
  carrier: Carrier | null;
}> = ({ searchTerm, carrier }) => {
  const { dispatch, onHealthPlanSelect } = useHealthPlanPicker();
  const [debouncedSearchTerm] = useDebounce(searchTerm, 400);
  const healthPlansFilter: HealthPlansFilter = {
    searchTerm: debouncedSearchTerm
  };
  if (carrier) {
    healthPlansFilter.carrierId = carrier.id;
  }
  const { data, loading, error } = useQuery<
    HealthPlansData,
    HealthPlansVariables
  >(LIST_HEALTH_PLANS, {
    variables: { filter: healthPlansFilter }
  });

  if (!carrier) {
    return null;
  }
  return (
    <div>
      {loading ? (
        <div className="p-12 text-center">
          <div className="mx-auto">
            <Spinner />
          </div>
        </div>
      ) : error || !(data && data.healthPlans) ? (
        <p>Failed to load.</p>
      ) : (
        <>
          <FancyList<HealthPlan>
            label="plans"
            items={data.healthPlans}
            onClickItem={item => {
              onHealthPlanSelect(item);
              dispatch({ type: "HEALTH_PLAN_CLICKED", healthPlan: item });
            }}
          />
        </>
      )}
    </div>
  );
};

const SelectedCarrier: React.FC = () => {
  const { state, dispatch, onHealthPlanSelect } = useHealthPlanPicker();
  if (!state.carrier) return null;
  return (
    <div className="flex items-end">
      <p className="font-semibold text-blue-900 text-xl">
        {state.carrier.name}
      </p>
      <button
        type="button"
        className="ml-2 px-1 py-0.5 text-gray-400"
        onClick={() => {
          onHealthPlanSelect(); // void arg
          dispatch({ type: "FORGET_CARRIER" });
        }}
      >
        <FAIcon icon="times" />
      </button>
    </div>
  );
};

const HealthPlanFinder: React.FC<HealthPlanFinderProps> = () => {
  const { state, dispatch } = useHealthPlanPicker();
  const ref = useRef(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  useOnClickOutside(ref, () => dispatch({ type: "CLICKED_OUTSIDE" }));

  // The following useEffect serves to keep the input box focused
  // as the entire control is navigated around.
  const { carrier, carrierGroup, healthPlan, currentTab, isOpen } = state;
  useEffect(() => {
    if (inputRef && inputRef.current && isOpen) {
      inputRef.current.focus();
    }
  }, [inputRef, carrier, carrierGroup, healthPlan, currentTab, isOpen]);

  const placeholder =
    state.currentTab === "choose_health_plan"
      ? "insurance plan"
      : "insurance carrier or plan";

  return (
    <div ref={ref} className="relative" id="health-plan-finder">
      <div>
        <input
          ref={inputRef}
          type="text"
          value={state.searchTerm}
          onChange={e =>
            dispatch({
              type: "SEARCH_TERM_UPDATED",
              searchTerm: e.target.value
            })
          }
          placeholder={placeholder}
          className="form-input inset-0 w-full"
          autoFocus
        />
      </div>
      <div>
        <div className="absolute bg-white border border-gray-300 left-0 max-w-lg rounded shadow-md w-screen">
          <div className="p-3">
            <HealthPlanFinderNav />
          </div>
          {state.carrier ? (
            <div className="p-3">
              <SelectedCarrier />
            </div>
          ) : null}
          <div
            className="overflow-y-scroll h-screen"
            style={{ maxHeight: 456 }}
          >
            {state.currentTab === "choose_carrier" ? (
              <CarrierResults
                searchTerm={state.searchTerm}
                type={state.carrierType}
              />
            ) : state.currentTab === "specify_carrier" ? (
              <CarrierResults
                searchTerm={state.searchTerm}
                type={state.carrierType}
                carrierGroup={state.carrierGroup}
              />
            ) : state.currentTab === "choose_health_plan" ? (
              <>
                {
                  <HealthPlanResults
                    searchTerm={state.searchTerm}
                    carrier={state.carrier}
                  />
                }
              </>
            ) : (
              <p>Error: Invalid tab</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { HealthPlanFinder };
