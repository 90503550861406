import { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Modal, ModalHeader } from "components/Modal";
import { TextField } from "@preferral/ui";
import {
  QuestionnaireModel,
  questionnaireForServer
} from "components/Questionnaire";
import { defaultReferralForm } from 'components/QuestionnaireTemplateEditor';
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

const CREATE_PATIENT_FORM = gql`
  mutation CreatePatientForm($departmentId: UUID4!, $input: PatientFormInput!) {
    createPatientForm(departmentId: $departmentId, input: $input) {
      errors {
        key
        message
      }
      patientForm {
        id
      }
    }
  }
`;

interface MutationData {
  createPatientForm: {
    errors?: InputError[];
    patientForm?: {
      id: string;
    };
  };
}

interface MutationVariables {
  departmentId: string;
  input: {
    title: string;
    form: QuestionnaireModel;
  };
}

interface FormValues {
  title: string;
}

interface NewPatientFormModalProps {
  departmentId: string;
  isOpen: boolean;
  onClose(): void;
  refetchQueries: any;
}

export const NewPatientFormModal: FC<NewPatientFormModalProps> = (props) => {
  const { departmentId, isOpen, onClose, refetchQueries } = props;
  const history = useHistory();

  const [createPatientForm] = useMutation<MutationData, MutationVariables>(
    CREATE_PATIENT_FORM
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setSubmitting, setStatus } = formikActions;
      setStatus({ errors: null });

      const input = {
        title: values.title,
        form: questionnaireForServer(defaultReferralForm),
      };

      return createPatientForm({
        variables: { departmentId, input },
        refetchQueries,
      }).then(
        (resp) => {
          if (resp.data?.createPatientForm.errors) {
            setStatus({ errors: resp.data.createPatientForm.errors });
          } else if (resp.data?.createPatientForm.patientForm) {
            // it worked
            toast.success("Patient Form Created!");
            const { id } = resp.data.createPatientForm.patientForm;
            return history.push(
              `/o/settings/departments/${departmentId}/patient_forms/${id}/edit`
            );
          }
          setSubmitting(false);
        },
        (rej) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong" }],
          });
          setSubmitting(false);
        }
      );
    },
    [departmentId, createPatientForm, history, refetchQueries]
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="file-alt"
        title="Create Patient Form"
        onClose={onClose}
      />
      <div className="p-4">
        <Formik<FormValues>
          initialValues={{
            title: "",
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required("Required"),
          })}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting, status }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />
              <div className="mt-3">
                <TextField name="title" label="Form Title" />
              </div>
              <div className="flex items-center justify-end mt-3 py-4">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-blue"
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
