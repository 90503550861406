import { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useRouteMatch, useHistory } from "react-router-dom";
import {
  QuestionnaireModel,
  QUESTIONNAIRE_FRAGMENT,
  questionnaireForServer,
  mergeAnswers,
  QuestionnaireFields,
  QuestionnaireFieldset,
  templateToFields
} from "components/Questionnaire";
import { defaultReferralForm } from 'components/QuestionnaireTemplateEditor'
import { Spinner, Button, FAIcon, FadeUpIn } from "@preferral/ui";
import { Formik } from "formik";
import { ScreenTitle } from "context/ScreenTitle";
import { ReferralBrief } from "./ReferralBrief";
import { CircleIconHeader } from "components/CircleIconHeader";
import { ProviderReferenceModel } from "components/ProviderSearchSelect";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { FakeEligibilityNotification } from "components/FakeEligibilityNotification";

const INBOUND_REFERRAL_FORM = gql`
  query FetchInboundReferralForm($id: UUID4!) {
    inboundReferral(id: $id) {
      id
      patientMemberId
      specialty {
        id
        name
      }
      referringProviderReference {
        id
        kind
      }
      form {
        ...QuestionnaireFields
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

interface Data {
  inboundReferral: {
    id: string;
    form?: QuestionnaireModel;
    patientMemberId: string;
    referringProviderReference: ProviderReferenceModel;
    specialty: {
      id: string;
      name: string;
    };
  };
}

interface Variables {
  id: string;
}

const SUBMIT_QUESTIONNAIRE = gql`
  mutation UpdateInboundReferralForm($id: UUID4!, $input: ReferralFormInput!) {
    updateInboundReferralForm(id: $id, input: $input) {
      errors {
        key
        message
      }
      inboundReferral {
        id
        form {
          ...QuestionnaireFields
        }
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

interface MutationData {
  updateInboundReferralForm: {
    errors?: InputError[];
    inboundReferral?: {
      id: string;
      form: QuestionnaireModel;
    };
  };
}

interface MutationVariables {
  id: string;
  input: {
    form: QuestionnaireModel;
  };
}

interface RouteParams {
  inboundReferralId: string;
}

interface FormValues {
  form: QuestionnaireFieldset;
}

interface QuestionsStepProps { }

export const QuestionsStep: FC<QuestionsStepProps> = () => {
  const match = useRouteMatch<RouteParams>();
  const { inboundReferralId } = match.params;

  const history = useHistory();

  const { data, loading, error } = useQuery<Data, Variables>(
    INBOUND_REFERRAL_FORM,
    { variables: { id: inboundReferralId } }
  );

  const questionnaireTemplate = data?.inboundReferral.form || defaultReferralForm;

  const [submitQuestionnaire] = useMutation<MutationData, MutationVariables>(
    SUBMIT_QUESTIONNAIRE
  );

  const onSuccess = useCallback(
    (inboundReferralId: string) => {
      return history.push(`/o/inbound_referral/${inboundReferralId}/confirm`);
    },
    [history]
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;
      setStatus({ errors: null });

      const questionnaire = questionnaireForServer(
        mergeAnswers(questionnaireTemplate, values.form),
      );

      const input = {
        form: questionnaire,
      };

      return submitQuestionnaire({
        variables: { id: inboundReferralId, input },
      }).then(
        (resp) => {
          if (resp.data?.updateInboundReferralForm.errors) {
            setStatus({ errors: resp.data.updateInboundReferralForm.errors });
          } else if (resp.data?.updateInboundReferralForm.inboundReferral) {
            // it worked...
            const { id } = resp.data.updateInboundReferralForm.inboundReferral;
            return onSuccess(id);
          }
        },
        (rej) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong." }],
          });
          setSubmitting(false);
        }
      );
    },
    [questionnaireTemplate, onSuccess, inboundReferralId, submitQuestionnaire]
  );

  const { hasFeature } = useFeatureFlags();

  return (
    <>
      <ScreenTitle title={["Inbound Request", "Questions"]} />
      <div className="_QuestionsStep container mx-auto mt-4 mb-8 text-left">
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.inboundReferral ? (
          <p>Failed to load</p>
        ) : (
          <FadeUpIn show>
            <div className="flex -mx-2 mt-4">
              <div className="flex-1 max-w-sm mx-2">
                <div className="rounded-2xl bg-white shadow-md p-4 sticky top-4">
                  <ReferralBrief inboundReferralId={inboundReferralId} />
                </div>
              </div>

              <div className="flex-1 mx-2 pb-8">
                <div className="bg-white px-2 pt-6 rounded-xl shadow-xl">
                  <div className="max-w-2xl px-2 pb-8 mx-auto">
                    <CircleIconHeader icon="clipboard-list">
                      {data.inboundReferral.specialty.name} Questions
                    </CircleIconHeader>

                    <Formik
                      initialValues={{
                        form: templateToFields(questionnaireTemplate),
                      }}
                      onSubmit={onSubmit}
                    >
                      {({ handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                          <QuestionnaireFields name="form" template={questionnaireTemplate} />
                          <div className="max-w-3xl mx-auto mt-8 border-t border-gray-200 pt-5">
                            <div className="flex justify-end">
                              <span className="ml-3 inline-flex rounded-md shadow-sm">
                                <Button
                                  type="submit"
                                  color="blue"
                                  disabled={isSubmitting}
                                >
                                  Next
                                  <span className="ml-2">
                                    <FAIcon icon="arrow-right" />
                                  </span>
                                </Button>
                              </span>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </FadeUpIn>
        )}
      </div>
      {hasFeature("fake_eligibility_notification") ? (
        <FakeEligibilityNotification />
      ) : null}
    </>
  );
};
