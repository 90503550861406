import React from "react";
import "./BlockSpinner.css";

type BlockSpinnerProps = {};

const BlockSpinner: React.FC<BlockSpinnerProps> = () => {
  return (
    <div
      className="BlockSpinner mx-auto"
      role="progressbar"
      aria-busy={true}
      aria-label="Loading"
    />
  );
};

export { BlockSpinner };
