import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Icon, IconName } from "@preferral/ui";

interface IconNavLinkProps {
  to: string;
  label: string;
  icon: IconName;
  notificationCount?: number;
  className?: string;
}

export const IconNavLink: FC<IconNavLinkProps> = (props) => {
  const { to, label, notificationCount = 0, icon, className = "" } = props;
  return (
    <NavLink
      to={to}
      className={`IconNavLink mx-auto mt-3 flex items-center justify-around relative rounded-full border-2 border-transparent hover:bg-blue-100 hover:border-solid hover:border-blue-400 ${className}`}
      activeClassName="navlink--active bg-blue-100 border-solid border-blue-600"
      style={{ width: 48, height: 48, transition: "all 200ms" }}
    >
      {notificationCount && notificationCount > 0 ? (
        <div
          className="absolute p-1 bg-red-600 border border-solid border-red-900 rounded-full align-middle text-white font-semibold leading-none text-center"
          style={{ top: -3, right: -3, minWidth: 26 }}
        >
          {notificationCount}
        </div>
      ) : null}
      <Icon icon={icon} size={32} />
      <div className="IconNavLink__tooltip px-4 py-2 bg-gray-800 text-blue-100 rounded-full">
        {label}
      </div>
    </NavLink>
  );
};
