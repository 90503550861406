import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spinner, Button } from "@preferral/ui";
import XIcon from '@heroicons/react/outline/XIcon';
import {
  LocationModel,
  LocationReferenceModel,
  LocationKindModel,
} from "./model";
import { LocationOption } from "./LocationOption";
import { toClientModel } from "./ProviderLocationSelectInput";

const fields = `
id
name
streetAddress
streetAddressLine2
city
stateAbbreviation
zip
`;

const LOCATION_OF_KIND = gql`
  query GetLocation($kind: String!, $id: UUID4!) {
    locationOfKind(kind: $kind, id: $id) {
      __typename
      ... on Location {
        ${fields}
      }
      ... on NppesLocation {
        ${fields}
      }
      ... on UnlistedLocation {
        ${fields}
      }
    }
  }
`;

interface Data {
  locationOfKind: LocationAPIModel;
}

interface Variables {
  kind: LocationKindModel;
  id: string;
}

// NB: These are the GraphQL model types.
type LocationUnionTypes = "Location" | "NppesLocation" | "UnlistedLocation";

interface LocationAPIModel extends Omit<LocationModel, "kind"> {
  __typename: LocationUnionTypes;
}

interface SelectedLocationProps {
  location: LocationReferenceModel;
  onClear?(): void;
  isClearable?: boolean;
}

export const SelectedLocation: FC<SelectedLocationProps> = (props) => {
  const { location, onClear, isClearable = false } = props;
  const { id, kind } = location;
  const { data, loading, error } = useQuery<Data, Variables>(LOCATION_OF_KIND, {
    variables: {
      id,
      kind,
    },
  });

  return (
    <div className="_SelectedLocation">
      {loading ? (
        <div className="px-3 py-4 flex items-center justify-center h-16 border border-cool-gray-200 rounded-lg">
          <Spinner />
        </div>
      ) : error || !data?.locationOfKind ? (
        <p>Failed to load</p>
      ) : (
        <div className="_SelectedLocationCard h-16 border border-cool-gray-200 rounded-lg flex items-center px-3 py-1 shadow text-left">
          <div className="flex-grow relative">
            <LocationOption location={toClientModel(data.locationOfKind)} />
          </div>
          {isClearable && onClear ? (
            <Button
              type="button"
              kind="tertiary"
              size="sm"
              color="red"
              onClick={onClear}
            >
              <XIcon className="h-6 w-6" />
            </Button>
          ) : null}
        </div>
      )}
    </div>
  );
};
