import React, { FC, ReactNode } from "react";

interface DetailTableProps {
  className?: string;
}

export const DetailTable: FC<DetailTableProps> = (props) => {
  const { className, children } = props;
  const cn = ["_DetailTable", className].filter(Boolean).join(" ");
  return (
    <table style={{ borderSpacing: 0 }} className={cn}>
      <tbody>{children}</tbody>
    </table>
  );
};

/**
 * DetailRow.
 */

interface DetailRowProps {
  label: ReactNode;
  className?: string;
  noWrapLabel?: boolean;
}

export const DetailRow: FC<DetailRowProps> = (props) => {
  const { label, children, noWrapLabel, className = "" } = props;

  const labelCn = [
    "_DetailRowLabel",
    "leading-normal pl-2 py-0 text-gray-700 text-right text-xs",
    noWrapLabel ? "whitespace-no-wrap" : null,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <tr className={`_DetailRow align-baseline ${className}`}>
      <td className={labelCn}>{label}</td>
      <td className="leading-none pt-2 px-4 pb-0 text-gray-900">
        {!children ? <p className="text-gray-500">-</p> : children}
      </td>
    </tr>
  );
};
