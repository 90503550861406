import { Button, CircleIcon, InputSelect, InputText } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import React, { FC, useCallback } from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { InboundFaxShowScreen } from "./InboundFaxShowScreen";
import { maybePluralize } from "lib/maybePluralize";
import { hMmA, mmDdYyyy } from "lib/dateFormatters";

const FAX_NUMBERS = gql`
  query FaxNumber {
    me {
      id
      departments {
        id
        faxNumbers {
          id
          name
          number {
            raw
            formatted
          }
        }
      }
    }
  }
`;

interface Data {
  me: {
    id: string;
    departments: {
      id: string;
      faxNumbers: {
        id: string;
        name: string;
        number: {
          raw: string;
          formatted: string;
        };
      }[];
    }[];
  };
}

interface FaxesInboxScreenProps {}

export const FaxesInboxScreen: FC<FaxesInboxScreenProps> = (props) => {
  const match = useRouteMatch<{ faxNumberId?: string }>(
    "/o/faxes/inbox/:faxNumberId"
  );
  const history = useHistory();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");

  const { faxNumberId } = match?.params || {};

  const { data, loading } = useQuery<Data>(FAX_NUMBERS);

  const faxNumberOptions =
    data?.me.departments
      .flatMap((d) => d.faxNumbers)
      .map((fn) => ({ value: fn.id, label: fn.name || fn.number })) || [];

  const onNumberChange = useCallback(
    (faxNumberId: string | null) => {
      if (faxNumberId) {
        history.push(`/o/faxes/inbox/${faxNumberId}`);
      }
    },
    [history]
  );

  return (
    <>
      <ScreenTitle title={["Faxes", "Inbox"]} />
      <div className="flex-1 flex bg-white min-h-full max-h-full overflow-y-auto">
        <Switch>
          {/* <Route path={`${match.path}/:providerId`}>
            <ProviderShowScreen refetchProviders={refetch} />
          </Route> */}
          <Route path={`/o/faxes/inbox/:faxNumberId/fax/:inboundFaxId`}>
            <InboundFaxShowScreen />
          </Route>
          <Route path="*">
            <div className="bg-cool-gray-50 flex flex-col items-center justify-center min-h-full w-full">
              <CircleIcon icon="fax" color="indigo" />
              <h4 className="font-bold pb-1 pt-3 text-cool-gray-700 text-lg">
                No Fax Selected
              </h4>
              {/* <p className="text-cool-gray-500 text-sm">
                Select a fax from the list
              </p> */}
              {/* <div className="flex gap-3 py-5">
                <Button
                  type="button"
                  kind="primary"
                  size="xs"
                  onClick={() => setActiveModal("ADD_PROVIDER")}
                >
                  <span className="mr-2">
                    <FAIcon icon="plus" />
                  </span>
                  Add Provider
                </Button>
                <Button
                  type="button"
                  kind="primary"
                  size="xs"
                  onClick={() => setActiveModal("IMPORT_PROVIDERS")}
                >
                  <span className="mr-2">
                    <FAIcon icon="file-upload" />
                  </span>
                  Import List
                </Button>
              </div> */}
            </div>
          </Route>
        </Switch>
        <aside className="hidden text-left xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
          {/* <div className="px-6 py-3 flex items-center justify-end gap-2">
            <Button
              type="button"
              kind="primary"
              size="xs"
              onClick={() => setActiveModal("ADD_PROVIDER")}
            >
              <span className="mr-2">
                <FAIcon icon="plus" />
              </span>
              Add Provider
            </Button>
            <Button
              type="button"
              kind="primary"
              size="xs"
              onClick={() => setActiveModal("IMPORT_PROVIDERS")}
            >
              <span className="mr-2">
                <FAIcon icon="file-upload" />
              </span>
              Import List
            </Button>
          </div> */}
          <div>
            {/* <p className="mt-1 text-sm text-gray-600">
              Search directory of{" "}
              {[data?.me.organization.providerCount, "providers"]
                .filter(Boolean)
                .join(" ")}
            </p> */}
            <form className="px-6 pt-5 pb-4 flex space-x-4" action="#">
              <div className="flex-1 min-w-0 text-left">
                <label
                  htmlFor="faxNumberSelect"
                  className="block text-sm py-1 font-semibold text-gray-600"
                >
                  Fax Number:
                </label>
                <InputSelect
                  id="faxNumberSelect"
                  value={faxNumberId || null}
                  onChange={onNumberChange}
                  options={faxNumberOptions}
                  inputProps={{ isLoading: loading }}
                  autoSelect
                />
                {/* <InputText
                  value={searchTerm}
                  onChange={setSearchTerm}
                  icon="search"
                  inputProps={{ placeholder: "Search" }}
                /> */}
                {/* <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    name="search"
                    id="search"
                    className="focus:ring-pink-500 focus:border-pink-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Search"
                  />
                </div> */}
              </div>
              {/* <button
                type="submit"
                className="inline-flex justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <Filter_sm className="h-5 w-5 text-cool-gray-500" />
                <span className="sr-only">Search</span>
              </button> */}
            </form>
            <div className="border-t border-b border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500">
              Sorted by date
            </div>
          </div>

          <nav
            className="flex-1 min-h-0 overflow-y-auto"
            aria-label="Directory"
          >
            <ul className="relative z-0 divide-y divide-gray-200">
              {faxes(faxNumberId || "1").map((fax) => (
                <li
                  key={fax.id}
                  className={pathSegments.includes(fax.id) ? "bg-blue-50" : ""}
                >
                  <div className="flex focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 hover:bg-gray-50 items-center px-3 py-5 relative space-x-3">
                    <div className="rounded-full h-3 w-3 bg-blue-600"></div>
                    <div className="flex-1 min-w-0">
                      <Link
                        to={`/o/faxes/inbox/${fax.faxNumberId}/fax/${fax.id}`}
                        className="focus:outline-none"
                      >
                        {/* <!-- Extend touch target to entire panel --> */}
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                        <p className="text-sm font-medium text-gray-900">
                          {fax.to}
                        </p>
                        <p className="text-xs text-gray-500 truncate">
                          {`${fax.pages} ${maybePluralize("page", fax.pages)}`}
                        </p>
                      </Link>
                    </div>
                    <div className="flex-shrink-0">
                      <p className="text-sm text-gray-800">
                        {mmDdYyyy(fax.insertedAt)}
                      </p>
                      <p className="text-xs text-gray-500 truncate">
                        {hMmA(fax.insertedAt)}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {/* {Object.keys(providersByLetter).map((letter) => (
              <div key={letter} className="relative">
                <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                  <h3>{letter}</h3>
                </div>
                <ul className="relative z-0 divide-y divide-gray-200">
                  {providersByLetter[letter].map((provider) => (
                    <li
                      key={provider.id}
                      className={
                        pathSegments.includes(provider.id) ? "bg-blue-50" : ""
                      }
                    >
                      <div className="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500">
                        <div className="flex-shrink-0">
                          <Avatar size="thumb" image={provider.avatar} />

                        </div>
                        <div className="flex-1 min-w-0">
                          <Link
                            to={`${match.url}/${provider.id}`}
                            className="focus:outline-none"
                          >
                            <!-- Extend touch target to entire panel -->
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            ></span>
                            <p className="text-sm font-medium text-gray-900">
                              {provider.nameWithAppellation}
                            </p>
                            <p className="text-xs text-gray-500 truncate">
                              {provider.primarySpecialty?.name}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))} */}
            <div className="border-t border-cool-gray-200 text-center p-3 text-xs italic text-cool-gray-400">
              End of List
            </div>
          </nav>
        </aside>
      </div>
    </>
  );
};

function faxes(faxNumberId: string) {
  return [
    {
      id: "1",
      to: "(555) 555-0001",
      status: "unread",
      faxNumberId,
      insertedAt: "2021-04-15T07:41:43.118487Z",
      pages: 4,
    },
  ];
}
