import { FC, useCallback, useState } from "react";
import { gql } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/client";
import { Link, useRouteMatch } from "react-router-dom";
import {
  Spinner,
  FAIcon,
  DropdownButton,
  DropdownItemLink,
  DropdownItemButton,
  JsonDebugger,
} from "@preferral/ui";
import { ddd } from "@preferral/common";
import { mmDdYyyy, hMmA, distanceInWords } from "lib/dateFormatters";
import { ScreenTitle } from "context/ScreenTitle";
import { QUESTIONNAIRE_FRAGMENT } from "components/Questionnaire";
import { DetailItem, DetailList } from "components/DetailList";
import { SentAppointmentRequestModel } from "../model";
import { TaskPanel } from "../components/TaskPanel";
import { AppointmentsPanel } from "../components/AppointmentsPanel";
import { ActivityPanel } from "../components/ActivityPanel";
import { PatientPanel } from "../components/PatientPanel";
import { InsurancePanel } from "../components/InsurancePanel";
import { CircleIconHeader } from "components/CircleIconHeader";
import { Files } from "../components/Files";
import { RequestedProviderPanel } from "../components/RequestedProviderPanel";
import { RequestedLocationPanel } from "../components/RequestedLocationPanel";
import { StatusBadge } from "../components/StatusBadge";
// import { ChangeSpecialtyModal } from "../components/ChangeSpecialtyModal";
import { SendFaxModal } from "../components/SendFaxModal";
import { AppointmentRequestProvider } from "../AppointmentRequestContext";
import {
  APPOINTMENT_REQUEST_EVENT_FRAGMENT,
  APPOINTMENT_REQUEST_FRAGMENT,
  LOCATION_FRAGMENT,
  NPPES_LOCATION_FRAGMENT,
  NPPES_PROVIDER_FRAGMENT,
  PATIENT_MEMBER_FRAGMENT,
  PROVIDER_FRAGMENT,
} from "../fragments";
import { WithdrawRequestModal } from "../components/WithdrawRequestModal";
import { CloseRequestModal } from "../components/CloseRequestModal";
import { ReferredBy } from "../components/ReferredBy";
import { QuestionnairePanel } from "../components/QuestionnairePanel";
import toast from "react-hot-toast";
import { analytics } from "lib/analytics";
import { AssignReferringTeammateModal } from "../components/AssignReferringTeammateModal";
import { AppointmentRequestEventNotificationAlert } from "../components/AppointmentRequestEventNotificationAlert";

const APPOINTMENT_REQUEST = gql`
  query GetSentAppointmentRequest($id: UUID4!) {
    appointmentRequest(id: $id) {
      ...AppointmentRequestFields
      assignedReferringUser {
        id
        name
      }
      platformReferral {
        id
        form {
          ...QuestionnaireFields
        }
        referringPatientMember {
          ...PatientMemberFields
        }
        referringDepartment {
          id
          name
        }
        referringProvider {
          __typename
          ...ProviderFields
        }
        referringLocation {
          __typename
          ...LocationFields
        }
        createdByUser {
          id
          name
        }
      }
      requestedNppesProvider {
        ...NppesProviderFields
      }
      requestedNppesLocation {
        ...NppesLocationFields
      }
      appointmentRequestEvents {
        ...AppointmentRequestEventFields
      }
    }
  }
  ${APPOINTMENT_REQUEST_FRAGMENT}
  ${APPOINTMENT_REQUEST_EVENT_FRAGMENT}
  ${LOCATION_FRAGMENT}
  ${NPPES_LOCATION_FRAGMENT}
  ${PATIENT_MEMBER_FRAGMENT}
  ${PROVIDER_FRAGMENT}
  ${NPPES_PROVIDER_FRAGMENT}
  ${QUESTIONNAIRE_FRAGMENT}
`;

const DOWNLOAD_PDF = gql`
  mutation AppointmentRequestDownloadPdf($id: UUID4!) {
    appointmentRequestDownloadPdf(id: $id) {
      url
    }
  }
`;

interface MutationData {
  appointmentRequestDownloadPdf: {
    url: string;
  };
}

interface MutationVariables {
  id: string | undefined;
}

interface Data {
  appointmentRequest: SentAppointmentRequestModel;
}

interface Variables {
  id: string;
}

type ActiveModal =
  | "ASSIGN_REFERRING_TEAMMATE"
  | "CHANGE_SPECIALTY"
  | "WITHDRAW_REQUEST"
  | "CLOSE_REQUEST"
  | "SEND_FAX";

interface SentRequestShowScreenProps {}

export const SentRequestShowScreen: FC<SentRequestShowScreenProps> = props => {
  const {
    params: { appointmentRequestId },
  } = useRouteMatch<{ appointmentRequestId: string }>();

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => setActiveModal(null), []);

  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    APPOINTMENT_REQUEST,
    { variables: { id: appointmentRequestId } }
  );

  const [downloadPdfMut, { loading: downloadingPDF }] = useMutation<
    MutationData,
    MutationVariables
  >(DOWNLOAD_PDF);

  const downloadPdf = useCallback(async () => {
    const variables = { id: data?.appointmentRequest.id };

    analytics.track("Download PDF", {
      direction: "sent",
      status: data?.appointmentRequest.status,
    });

    try {
      const { data: downloadData } = await downloadPdfMut({ variables });

      if (downloadData?.appointmentRequestDownloadPdf.url) {
        // it worked...
        window.open(downloadData.appointmentRequestDownloadPdf.url);
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
  }, [data, downloadPdfMut]);

  const appointmentRequest = data?.appointmentRequest;

  return (
    <>
      <ScreenTitle title={["Referrals", "View Sent Referral"]} />
      <div className="_SentRequestShowScreen lg:container mx-auto text-left">
        {loading ? (
          <div className="p-6 text-center">
            <Spinner />
          </div>
        ) : error || !appointmentRequest ? (
          <p>Failed to load</p>
        ) : (
          <AppointmentRequestProvider
            refetch={refetch}
            appointmentRequest={appointmentRequest}
            patientMember={
              appointmentRequest.platformReferral.referringPatientMember
            }
          >
            <AssignReferringTeammateModal
              isOpen={activeModal === "ASSIGN_REFERRING_TEAMMATE"}
              onClose={closeModal}
            />
            {/* <ChangeSpecialtyModal
              isOpen={activeModal === "CHANGE_SPECIALTY"}
              onClose={closeModal}
            /> */}
            <WithdrawRequestModal
              isOpen={activeModal === "WITHDRAW_REQUEST"}
              onClose={() => {
                analytics.track("Withdraw Referral", {
                  referral_id: appointmentRequestId,
                  referral_requested_date: appointmentRequest.insertedAt,
                });
                closeModal();
              }}
            />

            <CloseRequestModal
              isOpen={activeModal === "CLOSE_REQUEST"}
              onClose={closeModal}
            />

            <SendFaxModal
              isOpen={activeModal === "SEND_FAX"}
              onClose={closeModal}
            />
            <div className="pt-4 px-8">
              <Link
                to={`/o/referrals/sent`}
                className="border-b border-blue-500 border-dashed font-medium text-blue-700"
              >
                <span className="mr-2 text-sm">
                  <FAIcon icon="chevron-left" />
                </span>
                Back to Sent Referrals
              </Link>
            </div>
            <div className="pt-4 px-4 lg:grid lg:grid-cols-5 lg:gap-4">
              <div className="col-span-5">
                {(appointmentRequest.appointmentRequestEvents || []).map(
                  event => (
                    <AppointmentRequestEventNotificationAlert
                      appointmentRequestEvent={event}
                    />
                  )
                )}
              </div>
              <div className="col-span-3">
                <div className="bg-white rounded-xl shadow-xl border p-4">
                  <div className="flex items-center">
                    <div className="flex-grow">
                      <CircleIconHeader icon={["far", "address-card"]}>
                        Referral
                      </CircleIconHeader>
                    </div>
                    <div className="mr-2">
                      <StatusBadge />
                    </div>
                    <div>
                      <DropdownButton label="Actions">
                        <DropdownItemLink
                          to={`/o/referral/new?patientId=${appointmentRequest.platformReferral.referringPatientMember.id}`}
                        >
                          Start New Referral
                        </DropdownItemLink>
                        <DropdownItemButton
                          onClick={() => setActiveModal("SEND_FAX")}
                        >
                          Send Fax
                        </DropdownItemButton>
                        <DropdownItemButton
                          onClick={() =>
                            setActiveModal("ASSIGN_REFERRING_TEAMMATE")
                          }
                        >
                          Assign Teammate
                        </DropdownItemButton>
                        {/* <DropdownItemButton
                          onClick={() => setActiveModal("CHANGE_SPECIALTY")}
                        >
                          Change Specialty
                        </DropdownItemButton> */}
                        <DropdownItemButton
                          onClick={() => downloadPdf()}
                          disabled={downloadingPDF}
                        >
                          {downloadingPDF ? (
                            <>
                              <div className="mr-1.5 h-5 w-5 inline">
                                <Spinner />
                              </div>
                              <span>Downloading PDF...</span>
                            </>
                          ) : (
                            <span>Download PDF</span>
                          )}
                        </DropdownItemButton>
                        {appointmentRequest.status !== "CLOSED" ? (
                          <>
                            <DropdownItemButton
                              color="red"
                              onClick={() => setActiveModal("WITHDRAW_REQUEST")}
                            >
                              Withdraw Request
                            </DropdownItemButton>
                            <DropdownItemButton
                              color="red"
                              onClick={() => setActiveModal("CLOSE_REQUEST")}
                            >
                              Close Request
                            </DropdownItemButton>
                          </>
                        ) : null}
                      </DropdownButton>
                    </div>
                  </div>
                  <div id="_RequestInfo" className="mt-6 px-6 py-3">
                    <DetailList>
                      <DetailItem label="Assigned To">
                        {appointmentRequest!.assignedReferringUser ? (
                          appointmentRequest?.assignedReferringUser.name
                        ) : (
                          <span className="text-cool-gray-500 italic">
                            Unassigned
                          </span>
                        )}
                      </DetailItem>
                      <DetailItem label="Received">
                        {ddd(appointmentRequest!.insertedAt)}{" "}
                        {mmDdYyyy(appointmentRequest!.insertedAt)} at{" "}
                        {hMmA(appointmentRequest!.insertedAt)}{" "}
                        <span className="text-cool-gray-400">
                          ({distanceInWords(appointmentRequest!.insertedAt)}{" "}
                          ago)
                        </span>
                      </DetailItem>
                      <DetailItem label="Specialty">
                        {appointmentRequest!.requestedSpecialty?.name || "-"}
                      </DetailItem>
                      {appointmentRequest?.reasonForDelay ? (
                        <DetailItem label="Reason for Delay">
                          {appointmentRequest.reasonForDelay}
                        </DetailItem>
                      ) : null}
                      {appointmentRequest?.rejectedAt ? (
                        <DetailItem label="Rejected At">
                          {mmDdYyyy(appointmentRequest.rejectedAt)}
                          {" at "}
                          {hMmA(appointmentRequest.rejectedAt)}
                        </DetailItem>
                      ) : null}
                      {appointmentRequest?.rejectionReason ? (
                        <DetailItem label="Rejection Reason">
                          {appointmentRequest.rejectionReason}
                        </DetailItem>
                      ) : null}
                    </DetailList>
                  </div>
                  <div className="pt-4 grid grid-cols-2 gap-2 divide-x">
                    <div className="px-4 border-r">
                      <div id="_PatientInfo">
                        <PatientPanel />
                      </div>

                      <div id="_InsuranceInfo">
                        <InsurancePanel />
                      </div>
                    </div>

                    <div className="px-4 border-l">
                      <div id="_RequestedProvider">
                        <RequestedProviderPanel />
                      </div>
                      <div id="_RequestedLocation" className="pt-4">
                        <RequestedLocationPanel />
                      </div>
                    </div>
                  </div>

                  <div className="_FilesSection pt-4">
                    <Files />
                  </div>

                  <div id="pt-4 _RequestObjectInfo">
                    <ReferredBy
                      referringProvider={
                        appointmentRequest.platformReferral.referringProvider
                      }
                      referringLocation={
                        appointmentRequest.platformReferral.referringLocation
                      }
                    />

                    <QuestionnairePanel
                      questionnaire={appointmentRequest.platformReferral.form}
                    />
                  </div>

                  <div className="pt-8">
                    <JsonDebugger data={data} />
                  </div>
                </div>
              </div>

              <div className="col-span-2">
                <div className="bg-white rounded-xl shadow-xl border p-4">
                  <CircleIconHeader icon="calendar-alt">
                    Scheduling
                  </CircleIconHeader>
                  <div id="_AppointmentsPanelContainer">
                    <AppointmentsPanel />
                  </div>
                  <div id="_TaskPanelContainer px-4">
                    <TaskPanel />
                  </div>
                </div>

                <div className="mt-4 pt-4 bg-white rounded-xl shadow-xl border">
                  <CircleIconHeader icon="clipboard-list">
                    Activity
                  </CircleIconHeader>

                  <div id="_ActivityPanelContainer" className="pt-8">
                    <ActivityPanel />
                  </div>
                </div>
              </div>
            </div>
          </AppointmentRequestProvider>
        )}
      </div>
    </>
  );
};
