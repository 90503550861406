import { FC } from "react";
import {
  BarChart as RCBarChart,
  Bar as RCBar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Label
} from "recharts";

type Bar = {
  dataKey: string;
  fill: string;
  stackId?: string;
};

type BarChartProps = {
  data: { [x in string]: any }[];
  bars: Bar[];
  useAVGLine?: Boolean;
  /**
   * e.g "providerName"
   */
  xValKey: string;
};

export const BarChart: FC<BarChartProps> = ({ data, bars, xValKey, useAVGLine }) => {
  const dataAverage = useAVGLine && data.length !== 0 ? (data.map(d => d.value).reduce((acc, val) => acc + val, 0) / data.length).toFixed(2) : 0
  return (
    <div className="charts">
      <RCBarChart
        width={600}
        height={300}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xValKey} />
        <YAxis />
        <Tooltip />
        <Legend />
        {bars.map((bar) => (
          <RCBar
            key={bar.dataKey}
            dataKey={bar.dataKey}
            stackId={bar.stackId}
            fill={bar.fill}
          />
        ))}
        {useAVGLine && <ReferenceLine y={dataAverage} stroke="#b91c1c" strokeDasharray="3 3" label={<Label value={dataAverage} position="top" fill="#b91c1c" />} />}
      </RCBarChart>
    </div>
  );
};
