import React, { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Modal, ModalHeader } from "components/Modal";
import { SelectField } from "@preferral/ui";
import toast from "react-hot-toast";
import { analytics } from "lib/analytics";

const ORGANIZATION_USERS = gql`
  query OrganizationUsers {
    organizationUsers {
      cursor
      endOfList
      items {
        id
        name
        email
      }
    }
  }
`;

interface Data {
  organizationUsers: {
    cursor: string;
    endOfList: boolean;
    items: {
      id: string;
      name: string;
      email: string;
    }[];
  };
}

const ASSIGN_USER_TO_DEPARTMENT = gql`
  mutation AssignUserToDepartment($departmentId: UUID4!, $userId: UUID4!) {
    assignUserToDepartment(departmentId: $departmentId, userId: $userId) {
      errors {
        key
        message
      }
      department {
        id
        users {
          id
        }
      }
    }
  }
`;

interface MutationData {
  assignUserToDepartment: {
    errors?: InputError[];
    department?: {
      id: string;
      users: {
        id: string;
      }[];
    };
  };
}

interface MutationVariables {
  userId: string;
  departmentId: string;
}

interface FormValues {
  userId: string;
}

interface AddUserModalProps {
  departmentId: string;
  isOpen: boolean;
  onClose(): void;
  refetchQueries: any;
}

export const AddUserModal: FC<AddUserModalProps> = props => {
  const { departmentId, refetchQueries, isOpen, onClose } = props;
  const { data, loading, error } = useQuery<Data>(ORGANIZATION_USERS);
  const userOptions =
    data?.organizationUsers.items.map(u => ({
      value: u.id,
      label: u.name,
    })) || [];

  const [assignUser] = useMutation<MutationData, MutationVariables>(
    ASSIGN_USER_TO_DEPARTMENT
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      assignUser({
        variables: {
          userId: values.userId,
          departmentId,
        },
        refetchQueries,
      }).then(
        (resp: any) => {
          if (resp.data?.assignUserToDepartment.errors) {
            setStatus({ errors: resp.data.assignUserToDepartment.errors });
          } else if (resp.data?.assignUserToDepartment.department) {
            // it worked
            toast.success("Success");
            analytics.track("Add User to Department", {
              department_id: departmentId,
              user_id: values.userId,
            });
            return onClose();
          }
          setSubmitting(false);
        },
        (rej: any) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong" }],
          });
          setSubmitting(false);
        }
      );
    },
    [departmentId, assignUser, toast, refetchQueries]
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="user-plus"
        title="Add User to Department"
        onClose={onClose}
      />
      <div className="p-4">
        <Formik<FormValues>
          initialValues={{
            userId: "",
          }}
          validationSchema={Yup.object().shape({
            userId: Yup.string().trim().required("Required"),
          })}
          onSubmit={onSubmit}
        >
          {({ status, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />
              <div className="mt-3">
                <SelectField
                  name="userId"
                  label="User"
                  options={userOptions}
                  isLoading={loading}
                />
              </div>
              <div className="flex items-center justify-end mt-3 py-4">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-blue"
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
