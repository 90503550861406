import React, { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { CreateProviderForm } from "./CreateProviderForm";

interface CreateProviderModalProps {
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

export const CreateProviderModal: FC<CreateProviderModalProps> = (props) => {
  const { isOpen, onClose, onSuccess } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="user-doctor" title="Create Provider" onClose={onClose} />

      <div className="p-4">
        <CreateProviderForm
          onSuccess={() => {
            onSuccess();
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};

// import React from "react";
// import { gql, useQuery, useMutation } from "@apollo/client";
// import toast from "react-hot-toast";
// import { Formik, FieldArray } from "formik";
// import * as Yup from "yup";
// import { FormStatusErrors } from "components/formik/FormStatusErrors";
// import { TextField, SelectInput, StandardOption } from "@preferral/ui";
// import { ModalHeader } from "components/Modal";

// import { FAIcon } from "components/FAIcon";
// import uniqBy from "lodash/uniqBy";

// const CREATE_PROVIDER = gql`
//   mutation CreateProviderAndEmployment($input: CreateProviderInput!) {
//     createProviderAndEmployment(input: $input) {
//       errors {
//         key
//         message
//       }
//     }
//   }
// `;

// interface CreateProviderData {
//   createProviderAndEmployment: {
//     errors?: InputError[];
//   };
// }

// interface CreateProviderInput {
//   npi: string;
//   prefix?: string;
//   firstName: string;
//   middleInitial?: string;
//   lastName: string;
//   suffix?: string;
//   credentialText?: string;
//   gender?: string;
//   receiveProviderRequests?: boolean;
//   receivePatientRequests?: boolean;
//   specialtyIds?: string[];
// }

// interface CreateProviderVariables {
//   input: CreateProviderInput;
// }

// const SPECIALTIES = gql`
//   {
//     specialties(filter: { curated: true }) {
//       id
//       name
//       taxonomyCode
//     }
//     me {
//       id
//       organization {
//         id
//         departments {
//           id
//           departmentSpecialties {
//             id
//             specialty {
//               id
//               name
//             }
//           }
//         }
//       }
//     }
//   }
// `;

// interface SpecialtiesData {
//   specialties: {
//     id: string;
//     name: string;
//     taxonomyCode: string;
//   }[];
//   me: {
//     id: string;
//     organization: {
//       id: string;
//       departments: {
//         id: string;
//         departmentSpecialties: {
//           id: string;
//           specialty: {
//             id: string;
//             name: string;
//           };
//         }[];
//       }[];
//     };
//   };
// }

// type CreateProviderModalProps = {
//   onCloseModal: () => void;
// };

// export const CreateProviderModal: React.FC<CreateProviderModalProps> = (
//   props
// ) => {
//   const { onCloseModal } = props;
//   const { data, loading, error } = useQuery<SpecialtiesData>(SPECIALTIES);
//   const [createProvider] = useMutation<
//     CreateProviderData,
//     CreateProviderVariables
//   >(CREATE_PROVIDER);

//   const specialtyOptions = getSpecialtyOptions(data);
//   return (
//     <div className="CreateProviderModal box" style={{ padding: 0 }}>
//       <ModalHeader
//         icon="user-plus"
//         title="Add a Provider"
//         onClose={onCloseModal}
//       />
//       <div style={{ padding: "1rem" }}>
//         <Formik
//           initialValues={{
//             firstName: "",
//             lastName: "",
//             npi: "",
//             specialtyIds: [""],
//           }}
//           validationSchema={Yup.object().shape({
//             firstName: Yup.string().required("Required"),
//             lastName: Yup.string().required("Required"),
//             npi: Yup.string().required("Required"),
//           })}
//           onSubmit={(values, { setStatus, setSubmitting }) => {
//             setStatus({ errors: null });
//             createProvider({
//               variables: { input: values },
//               refetchQueries: [{ query: ORGANIZATION_PROVIDERS }],
//             }).then((res) => {
//               if (
//                 res &&
//                 res.data &&
//                 res.data.createProviderAndEmployment.errors
//               ) {
//                 setStatus({
//                   errors: res.data.createProviderAndEmployment.errors,
//                 });
//               } else {
//                 toast.success(`Added Provider`);
//                 onCloseModal();
//               }
//               setSubmitting(false);
//             });
//           }}
//         >
//           {({ values, status, isSubmitting, handleSubmit }) => (
//             <form onSubmit={handleSubmit}>
//               <FormStatusErrors status={status} />
//               <div className="field field-body">
//                 <TextField name="firstName" label="First Name" />

//                 <TextField name="lastName" label="Last Name" />
//               </div>

//               <TextField name="npi" label="NPI" />

//               <div className="field">
//                 <label className="label">Specialties</label>
//                 <div className="control">
//                   <FieldArray
//                     name="specialtyIds"
//                     render={(arrayHelpers) => (
//                       <div>
//                         {values.specialtyIds &&
//                         values.specialtyIds.length > 0 ? (
//                           <div
//                             style={{
//                               marginBottom: "0.5rem",
//                             }}
//                           >
//                             {values.specialtyIds.map((_specialtyId, index) => (
//                               <div
//                                 key={index}
//                                 style={{
//                                   marginBottom: "0.5rem",
//                                 }}
//                               >
//                                 <div className="level">
//                                   <div className="level-item">
//                                     <div
//                                       style={{
//                                         width: "100%",
//                                       }}
//                                     >
//                                       <SelectInput
//                                         name={`specialtyIds.${index}`}
//                                         options={specialtyOptions}
//                                         isLoading={loading}
//                                       />
//                                     </div>
//                                   </div>
//                                   <div className="level-item is-narrow">
//                                     <div
//                                       style={{
//                                         margin: "0 0 0 0.75rem",
//                                       }}
//                                     >
//                                       <button
//                                         type="button"
//                                         className="button is-danger is-outlined"
//                                         onClick={() =>
//                                           arrayHelpers.remove(index)
//                                         }
//                                       >
//                                         <FAIcon icon="times" />
//                                       </button>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                             ))}
//                             <div className="has-text-right">
//                               <button
//                                 type="button"
//                                 onClick={() => arrayHelpers.push("")}
//                               >
//                                 Add another specialty
//                               </button>
//                             </div>
//                           </div>
//                         ) : (
//                           <div className="has-text-centered">
//                             <button
//                               type="button"
//                               onClick={() => arrayHelpers.push("")}
//                             >
//                               Add a specialty
//                             </button>
//                           </div>
//                         )}
//                       </div>
//                     )}
//                   />
//                 </div>
//               </div>

//               <div
//                 style={{
//                   margin: "1.5rem auto 1rem",
//                   textAlign: "center",
//                 }}
//               >
//                 <button
//                   type="submit"
//                   className="button is-primary is-medium"
//                   disabled={isSubmitting}
//                 >
//                   Create Provider
//                 </button>
//               </div>
//             </form>
//           )}
//         </Formik>
//       </div>
//     </div>
//   );
// };

// function getSpecialtyOptions(data?: SpecialtiesData): StandardOption[] {
//   const deptSpecialties =
//     data?.me.organization.departments
//       .map((d) => d.departmentSpecialties.map((ds) => ds.specialty))
//       .flat() || [];
//   if (!(data?.specialties.length || deptSpecialties.length)) {
//     return [];
//   } else {
//     const specialtyOptions =
//       data?.specialties.map((s) => ({ value: s.id, label: s.name })) || [];
//     const deptSpecialtyOptions = deptSpecialties
//       .map(
//         (ds) =>
//           ds && {
//             value: ds.id,
//             label: ds.name,
//           }
//       )
//       .filter(Boolean) as StandardOption[];
//     return uniqBy(
//       specialtyOptions.concat(deptSpecialtyOptions),
//       "value"
//     ).sort((o1: StandardOption, o2: StandardOption) =>
//       o1.label.localeCompare(o2.label)
//     );
//   }
// }
