import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { WithdrawRequestForm } from './WithdrawRequestForm';

interface WithdrawRequestModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const WithdrawRequestModal: FC<WithdrawRequestModalProps> = (props) => {
  const { isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="archive"
        title="Withdraw Request"
        onClose={onClose}
      />

      <div className="p-4">
        <WithdrawRequestForm onCancel={onClose} onSuccess={onClose} />
      </div>
    </Modal>
  );
};
