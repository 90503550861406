import { FC, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  FilterField,
  FilterForm,
  SelectInput,
  TextInput,
  ToggleSwitchInput,
  Tooltip,
  SliderField,
  TextMaskInput,
} from "@preferral/ui";
import { SearchResultsMeta } from "./model";
import { gql, useQuery } from "@apollo/client";
import { useFormikContext } from "formik";
import * as Yup from "yup";

const LIST_SPECIALTIES = gql`
  query Specialties {
    specialties(filter: { curated: true }) {
      id
      name
      taxonomyCode
      defaultSearchMode
    }
  }
`;

interface FilterData {
  specialties: {
    id: string;
    name: string;
    taxonomyCode: string;
    defaultSearchMode: string;
  }[];
}

export interface FilterModel {
  specialtyId: string;
  onlyInNetwork: boolean;
  searchByLocation: boolean;
  searchTerm: string;
  distance: number;
  zip: string;
}

export const defaultFilter: FilterModel = {
  specialtyId: "REFERRAL_SPECIALTY",
  onlyInNetwork: false,
  searchByLocation: false,
  searchTerm: "",
  distance: 10,
  zip: "",
};

const referralSpecialtyOption = {
  value: "REFERRAL_SPECIALTY",
  label: "Referral Specialty",
};

interface FilterPanelProps {
  value: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading: boolean;
  searchResultsMeta: SearchResultsMeta | null;
}

interface FormObserverProps {
  onValuesChange(values: any, previousValues: any): void;
  shouldResetFormValues: Boolean;
  setShouldResetFilters(value: SetStateAction<boolean>): void;
}

// Observes the changes inside a Formik form and executes a callback in case there is a value change
const FormObserver: FC<FormObserverProps> = props => {
  const { values, initialValues, resetForm, submitForm } = useFormikContext();
  const { onValuesChange, shouldResetFormValues, setShouldResetFilters } =
    props;
  const [previousValues, setPreviousValues] = useState(initialValues);

  useEffect(() => {
    if (shouldResetFormValues) {
      resetForm();
      submitForm();
    }
    return () => {
      setShouldResetFilters(false);
    };
  }, [shouldResetFormValues]);

  useEffect(() => {
    onValuesChange(values, previousValues);
    setPreviousValues(values);
  }, [values, onValuesChange]);

  return null;
};

const filterValidationSchema: Yup.SchemaOf<FilterModel> = Yup.object()
  .shape({
    zip: Yup.string()
      .matches(/^[0-9]+$/, "Please enter a valid zip code")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
  })
  .required();

export const FilterPanel: FC<FilterPanelProps> = props => {
  const {
    value = defaultFilter,
    onChange,
    isLoading,
    searchResultsMeta,
  } = props;
  const [shouldResetFilters, setShouldResetFilters] = useState(false);
  const [specialtySearchDefaultBy, setSpecialtySearchDefaultBy] = useState<
    string | null
  >(null);

  const { data, loading } = useQuery<FilterData>(LIST_SPECIALTIES);

  const specialtyOptions = [
    referralSpecialtyOption,
    ...(data?.specialties.map(s => ({
      value: s.id,
      label: s.name,
    })) || []),
  ];

  const resetFilter = () => {
    setShouldResetFilters(true);
  };

  const isReferralSpecialtyDefaultToLocation = (
    filter: FilterModel,
    defaultSearchMode = specialtySearchDefaultBy
  ) =>
    filter.specialtyId === "REFERRAL_SPECIALTY" &&
    defaultSearchMode === "location";

  const onFormValuesChange = (
    values: FilterModel,
    previousValues: FilterModel
  ) => {
    if (values.specialtyId !== previousValues.specialtyId) {
      // If the selected specialty is referral specialty and default search mode is by location
      if (isReferralSpecialtyDefaultToLocation(values)) {
        values.searchByLocation = true;
        // It's another specialty but its default search mode is by location
      } else if (
        data?.specialties.find(s => values.specialtyId === s.id)
          ?.defaultSearchMode === "location"
      ) {
        values.searchByLocation = true;
        // Otherwise let's find by provider
      } else {
        values.searchByLocation = false;
      }
    }
  };

  // Since we don't have information about the referral specialty we must wait
  // until first search is done and extract the info based on the metadata
  if (specialtySearchDefaultBy === null && searchResultsMeta) {
    setSpecialtySearchDefaultBy(searchResultsMeta.specialty.defaultSearchMode);
    value.searchByLocation = isReferralSpecialtyDefaultToLocation(
      value,
      searchResultsMeta.specialty.defaultSearchMode
    );
    onChange(value);
  }

  return (
    <>
      <FilterForm<FilterModel>
        defaultValue={defaultFilter}
        value={value}
        onChange={onChange}
        showClearFilter={false}
        validationSchema={filterValidationSchema}
      >
        <FormObserver
          onValuesChange={onFormValuesChange}
          shouldResetFormValues={shouldResetFilters}
          setShouldResetFilters={setShouldResetFilters}
        />
        <div className="mt-5">
          <FilterField htmlFor="searchTerm" icon="search" label="Edit Search">
            <TextInput
              name="searchTerm"
              placeholder="Search for providers or clinics"
              className="mt-2"
            />
          </FilterField>
        </div>
        <div className="mt-8">
          <ToggleSwitchInput
            name="searchByLocation"
            toggleSwitchProps={{
              size: 26,
              fontSize: 14,
              showLabel: true,
              checkedLabel: "Search by Clinic",
              uncheckedLabel: "Search by Clinic",
            }}
          />
        </div>
        <div className="mt-5">
          <FilterField htmlFor="zip" icon="filter" label="Zip Code">
            <TextMaskInput
              name="zip"
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
              placeholder="Zip Code"
              className="mt-2"
            />
          </FilterField>
        </div>
        <div className="mt-5">
          <FilterField htmlFor="distance" icon="filter" label="Radius (miles)">
            <div className="w-56 p-3 mt-2">
              <SliderField
                name="distance"
                value={value.distance}
                max={50}
                min={1}
                step={1}
              />
            </div>
          </FilterField>
        </div>
        <div className="mt-8">
          <FilterField htmlFor="specialtyId" icon="filter" label="Specialty">
            <div className="w-56 mt-2">
              <SelectInput
                name="specialtyId"
                options={specialtyOptions}
                isLoading={loading}
              />
            </div>
          </FilterField>
        </div>
        <div className="flex mt-8 gap-2">
          <FilterField>
            <Button
              className="self-end text-blue-600 pl-0"
              style={{ border: "none" }}
              type="button"
              onClick={resetFilter}
              color=""
              disabled={isLoading}
            >
              Reset Filters
            </Button>
          </FilterField>
          <FilterField>
            <Button
              className="self-end"
              type="submit"
              color="blue"
              disabled={isLoading}
            >
              Apply Filters
            </Button>
          </FilterField>
        </div>
      </FilterForm>
    </>
  );
};
