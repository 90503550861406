export function determineChannel(vars: { phoneNumber?: string, email?: string }): "sms" | "email" | "email+sms" {
  const phoneNumber = (vars.phoneNumber || "").trim();
  const email = (vars.email || "").trim();

  if (phoneNumber === "") {
    return "email";
  } else if (email === "") {
    return "sms";
  } else {
    return "email+sms";
  }
}
