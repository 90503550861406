import { FC, useCallback } from "react";
import ReactDOM from 'react-dom';
import { Manager, Reference, Popper } from 'react-popper';
import { FAIcon } from 'components/FAIcon';
import { useDropdown } from 'hooks/useDropdown';
import { TagCombobox, TagModel } from 'components/TagCombobox';

interface AddTagDropdownProps {
  items: TagModel[];
  onSelect(tag: TagModel): void;
};

export const AddTagDropdown: FC<AddTagDropdownProps> = props => {
  const { items, onSelect } = props;
  const { isOpen, toggle, triggerRef, contentRef, close } = useDropdown();

  const handleSelect = useCallback((tag) => {
    onSelect(tag);
    if (isOpen) close();
  }, [isOpen, onSelect, close]);

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div ref={ref} className="inline-block">
            <button
              ref={triggerRef}
              onClick={toggle}
              type="button"
              className="hover:bg-gray-100 focus:outline-none focus:shadow-outline px-1 ml-2 font-medium text-gray-800 border border-gray-300 rounded"
            >
              <p className="flex items-center px-1 text-xs">
                <span className="mr-1 text-xs text-gray-700">
                  <FAIcon icon="plus" />
                </span>
                Add Tag
              </p>
            </button>
          </div>
        )}
      </Reference>
      {isOpen &&
        ReactDOM.createPortal(
          <Popper placement="bottom-end">
            {({ placement, ref, style }) => (
              <div
                ref={ref}
                style={style}
                data-placement={placement}
                className="z-40"
              >
                <div
                  className="absolute right-0 z-10 pb-12 mt-1 origin-top-right"
                  ref={contentRef}
                  hidden={!isOpen}
                >
                  <div className="w-48 px-1 pb-1 text-left bg-white border border-gray-300 rounded-lg shadow-lg">
                    <TagCombobox items={items} onSelect={handleSelect} />
                  </div>
                </div>
              </div>
            )}
          </Popper>,
          document.querySelector('#dropdown-button-action-portal')!
        )}
    </Manager>
  );
};
