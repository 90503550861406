import React from "react";
import { Link } from "react-router-dom";
import { FAIcon } from "components/FAIcon";
// import "./ConfirmationFailure.scss";

type ConfirmationFailureProps = {
  message: string;
};

const ConfirmationFailure: React.FC<ConfirmationFailureProps> = ({
  message
}) => {
  return (
    <div className="ConfirmationFailure box" style={{ padding: "2.5rem" }}>
      <div className="ConfirmationFailure__check-container">
        <FAIcon icon="exclamation" />
      </div>
      <h1 className="title is-4">{message}</h1>
      <h2 className="subtitle is-5" style={{ marginTop: "1rem" }}>
        <Link to="/auth/sign_in" style={{ textDecoration: "underline" }}>
          Click here
        </Link>{" "}
        to sign in and request a new confirmation email.
      </h2>
    </div>
  );
};

export { ConfirmationFailure };
