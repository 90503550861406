import React from "react";
import cx from "classnames";
import { useHealthPlanPicker } from "./context";

type InputBoxProps = {
  disabled?: boolean;
};

const InputBox: React.FC<InputBoxProps> = ({ disabled = false }) => {
  const { state, dispatch } = useHealthPlanPicker();

  const placeholder =
    state.currentTab === "choose_health_plan"
      ? "insurance plan"
      : "insurance carrier or plan";

  const onClick = () => (disabled ? null : dispatch({ type: "FOCUSED" }));
  return (
    <div className="InputBox">
      {state.healthPlan && state.carrier ? (
        <div
          className={cx(
            "border border-gray-400 rounded px-5 py-2 cursor-pointer font-semibold",
            {
              "cursor-pointer": !disabled,
              "bg-gray-200": disabled
            }
          )}
          onClick={onClick}
        >
          {
            state.healthPlan.__typename === "RibbonHealthPlan" ? (
              state.healthPlan.displayName
            ) : (
              `${state.carrier.name} - ${state.healthPlan.name}`
            )
          }
        </div>
      ) : state.carrier || state.carrierGroup ? (
        <div
          onClick={onClick}
          className="border border-gray-400 cursor-pointer font-semibold pl-10 px-5 py-2 relative rounded text-purple-600"
        >
          <svg
            className="absolute inset-x-0 ml-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22C9.34784 22 6.8043 20.9464 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22Z"
              fill="currentColor"
            />
            <path
              d="M12 19.5C11.6022 19.5 11.2206 19.342 10.9393 19.0607C10.658 18.7794 10.5 18.3978 10.5 18C10.5 17.6022 10.658 17.2206 10.9393 16.9393C11.2206 16.658 11.6022 16.5 12 16.5C12.3978 16.5 12.7793 16.658 13.0606 16.9393C13.342 17.2206 13.5 17.6022 13.5 18C13.5 18.3978 13.342 18.7794 13.0606 19.0607C12.7793 19.342 12.3978 19.5 12 19.5ZM13 14C13 14.2652 12.8946 14.5196 12.7071 14.7071C12.5196 14.8946 12.2652 15 12 15C11.7348 15 11.4804 14.8946 11.2929 14.7071C11.1053 14.5196 11 14.2652 11 14V12.59C10.9987 12.4036 11.0495 12.2206 11.1467 12.0616C11.2439 11.9026 11.3836 11.7739 11.55 11.69L14 10.5C14.64 10.08 15 9.53 15 9C15 7.97 13.7 7 12 7C10.65 7 9.50999 7.62 9.12999 8.43C9.00497 8.64978 8.80133 8.81405 8.56006 8.88972C8.3188 8.9654 8.05782 8.94687 7.82967 8.83787C7.60152 8.72886 7.42313 8.53747 7.33042 8.30223C7.23771 8.06699 7.23755 7.80535 7.32999 7.57C8.04999 6.01 9.91999 5 12 5C14.7 5 17 6.72 17 9C17 10.3 16.24 11.46 14.95 12.24L13 13.2V14Z"
              fill="#FFFFFF"
            />
          </svg>
          choose plan
        </div>
      ) : (
        <input
          type="text"
          disabled={disabled}
          className={cx("form-input w-full", {
            "bg-gray-200": disabled
          })}
          placeholder={placeholder}
          autoCapitalize={"off"}
          autoComplete={"off"}
          autoCorrect={"off"}
          onFocus={onClick}
        />
      )}
    </div>
  );
};

export { InputBox };
