import { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Button, TextField, SelectField, PhoneMaskField } from "@preferral/ui";
import {
  PatientFormSelectOption,
  PATIENT_FORM_OPTION_GQL_FIELDS,
  PatientFormModel,
} from "components/PatientFormOption";
import toast from "react-hot-toast";
import { analytics } from "lib/analytics";
import { determineChannel } from "./helpers";

const LIST_PATIENT_FORMS = gql`
  query ListPatientForms {
    patientForms {
      endOfList
      cursor
      items {
        ${PATIENT_FORM_OPTION_GQL_FIELDS}
      }
    }
  }
`;

interface Data {
  patientForms: Paginated<PatientFormModel>;
}

const SEND_PATIENT_FORM = gql`
  mutation SendPatientForm($id: UUID4!, $email: String, $phoneNumber: String) {
    sendPatientForm(id: $id, email: $email, phoneNumber: $phoneNumber) {
      errors {
        key
        message
      }
      sent
    }
  }
`;

interface MutationData {
  sendPatientForm: {
    errors?: InputError[];
    sent?: boolean;
  };
}

interface MutationVariables {
  id: string;
  phoneNumber?: string;
  email?: string;
}

interface FormValues {
  patientFormId: string;
  email: string;
  phoneNumber: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    patientFormId: Yup.string().required("Required"),
    email: Yup.string(),
    phoneNumber: Yup.string(),
  })
  .required();

interface SendSingleFormFormProps {
  onSuccess(): void;
}

export const SendSingleFormForm: FC<SendSingleFormFormProps> = props => {
  const { onSuccess } = props;
  const { data, loading } = useQuery<Data>(LIST_PATIENT_FORMS);

  const patientFormOptions = data?.patientForms.items || [];

  const [sendPatientForm] = useMutation<MutationData, MutationVariables>(
    SEND_PATIENT_FORM
  );
  const onSubmit = useCallback(
    async (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      const variables = {
        id: values.patientFormId,
        phoneNumber: values.phoneNumber,
        email: values.email,
      };

      try {
        const { data } = await sendPatientForm({ variables });

        if (data?.sendPatientForm.errors) {
          setStatus({ errors: data.sendPatientForm.errors });
        } else if (data?.sendPatientForm.sent) {
          // it worked
          toast.success("Patient form sent!");
          const channel = determineChannel(variables);
          analytics.track("Patient Form Sent", { channel });
          onSuccess();
        }
        setSubmitting(false);
      } catch (e) {
        toast.error("Something went wrong.");
        setSubmitting(false);
      }
    },
    [sendPatientForm, onSuccess]
  );

  return (
    <Formik<FormValues>
      initialValues={{
        patientFormId: "",
        email: "",
        phoneNumber: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <SelectField
            name="patientFormId"
            label="Form"
            options={patientFormOptions}
            isLoading={loading}
            getOptionLabel={(o: PatientFormModel) => o.title}
            getOptionValue={(o: PatientFormModel) => o.id}
            components={{ Option: PatientFormSelectOption }}
          />

          <div className="mt-3">
            <TextField indicateOptional name="email" label="Email Address" />
          </div>

          <div className="mt-3">
            <PhoneMaskField
              indicateOptional
              name="phoneNumber"
              label="Phone Number"
            />
          </div>
          <div className="flex items-center justify-end mt-3 py-4">
            <Button
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              color="blue"
            >
              Send Form
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
