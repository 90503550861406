import { FC } from "react";
import { DetailList, DetailItem } from "@preferral/ui";
import { mmDdYyyy } from "lib/dateFormatters";
import { useAppointmentRequest } from "../../AppointmentRequestContext";

interface PatientPanelProps { }

export const PatientPanel: FC<PatientPanelProps> = (props) => {
  const { patientMember } = useAppointmentRequest();

  const patientName = patientMember.patient.preferredName
    ? `${patientMember.patient.firstName} (${patientMember.patient.preferredName}) ${patientMember.patient.lastName}`
    : `${patientMember.patient.firstName} ${patientMember.patient.lastName}`;

  return (
    <div className="PatientPanel">
      <h4 className="text-xl py-4 font-semibold">Patient</h4>
      <div className="px-4">
        <DetailList>
          <DetailItem label="Name">{patientName}</DetailItem>
          <DetailItem label="DOB">
            {mmDdYyyy(patientMember.patient.dob)}{" "}
            <span className="text-cool-gray-400">
              ({patientMember.patient.ageString})
            </span>
          </DetailItem>
          <DetailItem label="Phone">
            {patientMember.patient.primaryPhone?.formatted || "-"}
          </DetailItem>
          {patientMember.medicalRecordNumber ? (
            <DetailItem label="MRN">
              {patientMember.medicalRecordNumber}
            </DetailItem>
          ) : null}
          {patientMember.formattedSsn ? (
            <DetailItem label="SSN">{patientMember.formattedSsn}</DetailItem>
          ) : null}
          {patientMember.ssnLast4 ? (
            <DetailItem label="SSN (last 4)">
              {patientMember.ssnLast4}
            </DetailItem>
          ) : null}
        </DetailList>
      </div>
    </div>
  );
};
