import React, { FC } from "react";

interface ReferralCodeScreenProps {}

export const ReferralCodeScreen: FC<ReferralCodeScreenProps> = () => {
  return (
    <div className="ReferralCodeScreen">
      <p>ReferralCodeScreen</p>
    </div>
  );
};
