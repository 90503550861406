import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSyncPatientMember } from "./useSyncPatientMember";
import toast from "react-hot-toast";

export function useStartReferral() {
  const history = useHistory();

  const { syncPatientMember, loading } = useSyncPatientMember();

  const startReferral = useCallback(
    async (amdPatientId: number) => {
      try {
        const { patientMember, errors } = await syncPatientMember(amdPatientId);

        if (patientMember) {
          history.push(`/o/referral/new?patientId=${patientMember.id}`);
        } else if (errors) {
          toast.error(errors[0].message);
        } else {
          toast.error("Error starting referral");
        }
      } catch (e) {
        console.error(e);
        toast.error("Error starting referral");
      }
    },
    [history, syncPatientMember]
  );

  return {
    startReferral,
    loading,
  };
}
