import React, { forwardRef, HTMLAttributes } from "react";
import qs from "query-string";

interface GoogleMapsStaticImageProps extends HTMLAttributes<HTMLImageElement> {
  point: Coordinate;
  width?: number;
  height?: number;
}

export interface Coordinate {
  lat: number;
  lng: number;
}

export type MapMarker = { type: ""; point: Coordinate };

const markerIconUrl = "http://i.imgur.com/2wctcxy.png";

export const GoogleMapsStaticImage = forwardRef<
  HTMLImageElement,
  GoogleMapsStaticImageProps
>((props, ref) => {
  const { point, width = 800, height = 400, ...rest } = props;

  const params = {
    markers: [`icon:${markerIconUrl}`, `${point.lat},${point.lng}`].join("|"),
    size: `${width}x${height}`,
    sensor: false,
    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  };

  return (
    <img
      {...rest}
      ref={ref}
      src={`//maps.googleapis.com/maps/api/staticmap?${qs.stringify(params)}`}
      alt="map"
    />
  );
});
