import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const SYNC_PATIENT_MEMBER = gql`
  mutation SyncPatientMember($amdPatientId: Int!) {
    syncPatientMember(amdPatientId: $amdPatientId) {
      patientMember {
        id
      }
    }
  }
`;

interface Data {
  syncPatientMember: {
    errors?: InputError[];
    patientMember?: {
      id: string;
    };
  };
}

interface Variables {
  amdPatientId: number;
}

export function useSyncPatientMember() {
  const [syncPatientMemberMut, { loading }] = useMutation<Data, Variables>(
    SYNC_PATIENT_MEMBER
  );

  const syncPatientMember = useCallback(
    (amdPatientId: number) => {
      return syncPatientMemberMut({
        variables: {
          amdPatientId,
        },
      }).then(res => res.data?.syncPatientMember || {});
    },
    [syncPatientMemberMut]
  );

  return {
    syncPatientMember,
    loading,
  };
}
