import { FC, useCallback } from "react";
import { useMutation, gql } from '@apollo/client';
import { Modal } from 'components/Modal';
import { Button } from '@preferral/ui';
import toast from 'react-hot-toast';
import UserAddIcon from '@heroicons/react/outline/UserAddIcon';

const REACTIVATE_USER = gql`
  mutation ReactivateUser($userId: UUID4!) {
    reactivateUser(id: $userId) {
      errors {
        key
        message
      }
      user {
        id
        deactivatedAt
      }
    }
  }
`;

interface MutationData {
  reactivateUser: {
    errors?: InputError[];
    user?: {
      id: string;
      deactivatedAt: string;
    }
  }
}

interface MutationVariables {
  userId: string;
}

interface ReactivateUserModalProps {
  isOpen: boolean;
  onClose(): void;
  userId: string;
  onSuccess(): void;
};

export const ReactivateUserModal: FC<ReactivateUserModalProps> = props => {
  const { isOpen, onClose, userId, onSuccess } = props;

  const [reactivateUser, { loading: submitting }] = useMutation<MutationData, MutationVariables>(REACTIVATE_USER);

  const onSubmit = useCallback(() => {
    return reactivateUser({ variables: { userId } }).then(resp => {
      if (resp.data?.reactivateUser.errors) {
        toast.error(resp.data.reactivateUser.errors[0].message);
      } else if (resp.data?.reactivateUser.user) {
        // it worked...
        toast.success("User reactivated");
        onSuccess();
        onClose();
      }
    })
  }, [userId, reactivateUser, onSuccess, onClose])

  return (
    <Modal className="_ReactivateUserModal max-w-lg" isOpen={isOpen} onRequestClose={onClose}>
      <div className="p-4">
        <div className="sm:flex sm:items-start">
          <div className="sm:mx-0 sm:h-10 sm:w-10 flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-blue-100 rounded-full">
            <UserAddIcon
              className="w-6 h-6 text-blue-600"
              aria-hidden="true"
            />
          </div>
          <div className="sm:mt-0 sm:ml-4 sm:text-left mt-3 text-center">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Reactivate User Account
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Are you sure you want to reactivate this user account? Doing so will enable them to access your organization on Preferral.
              </p>
            </div>
          </div>
        </div>
        <div className="sm:mt-4 sm:flex sm:flex-row-reverse mt-5">
          <Button
            type="button"
            kind="primary"
            color="blue"
            isLoading={submitting}
            disabled={submitting}
            className="sm:ml-3"
            onClick={onSubmit}
          >
            Reactivate User
          </Button>
          <Button
            type="button"
            className="hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
