import React from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "@preferral/ui";
import toast from "react-hot-toast";

const CREATE_DEPARTMENT = gql`
  mutation CreateDepartment($input: DepartmentInput!) {
    createDepartment(input: $input) {
      errors {
        key
        message
      }
      department {
        id
        name
      }
    }
  }
`;

interface MutationData {
  createDepartment: {
    errors?: InputError[];
    department?: {
      id: string;
      name: string;
    };
  };
}

interface CreateDepartmentInput {
  name: string;
}

interface MutationVariables {
  input: CreateDepartmentInput;
}

type NewDepartmentFormProps = {
  refetchQueries: any;
  closeModal: () => void;
  onSuccess: (departmentId: string) => void;
};

const NewDepartmentForm: React.FC<NewDepartmentFormProps> = ({
  refetchQueries,
  onSuccess,
  closeModal,
}) => {
  const [createDepartment] = useMutation<MutationData, MutationVariables>(
    CREATE_DEPARTMENT
  );

  return (
    <div className="NewDepartmentForm">
      <Formik
        initialValues={{
          name: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .trim()
            .required("Required"),
        })}
        onSubmit={(formValues, { setStatus, setSubmitting }) => {
          setStatus({ errors: null });
          createDepartment({
            variables: { input: formValues },
            refetchQueries,
          }).then(
            (resp) => {
              if (resp && resp.data && resp.data.createDepartment.errors) {
                setStatus({ errors: resp.data.createDepartment.errors });
              } else if (
                resp &&
                resp.data &&
                resp.data.createDepartment.department
              ) {
                // it worked
                toast.success("Department created");
                closeModal();
                onSuccess(resp.data.createDepartment.department.id);
              }
              setSubmitting(false);
            },
            (rej) => {
              setStatus({
                errors: [{ key: "", message: "Something went wrong" }],
              });
              setSubmitting(false);
            }
          );
        }}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <div className="mt-3">
              <TextField name="name" label="Name" />
            </div>
            <div className="flex items-center justify-end mt-3 py-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-blue"
              >
                Create Department
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { NewDepartmentForm };
