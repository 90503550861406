import React from "react";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { Coverage } from "./coverage";
import { BlockSpinner } from "components/BlockSpinner";
import { CoverageLookupError } from "./CoverageLookupError";
import { ExclamationCircleIcon } from "components/TwoToneIcons/ExclamationCircleIcon";
import { CheckCircleIcon } from "components/TwoToneIcons/CheckCircleIcon";
import { DetailTable, DetailRow } from "components/DetailTable";
import { formatGender } from "./helpers";
import { MedicareCoverage } from "./MedicareCoverage";
import { useToggle } from "@preferral/ui";
import { Financials } from "./Financials";
import { ServicesCovered } from "./ServicesCovered";

export type EligibilityLookup = {
  id: string;
  loading: boolean;
  error?: string;
  data?: string;
};

type CoverageDetailsProps = {
  eligibilityLookup: EligibilityLookup;
};

const BriefCoverageDetails: React.FC<CoverageDetailsProps> = ({
  eligibilityLookup,
}) => {
  if (
    eligibilityLookup.loading ||
    (eligibilityLookup.data === "{}" && !eligibilityLookup.error)
  ) {
    return (
      <div
        className="mt-4 pt-4 px-4 pb-8 relative rounded-lg shadow border border-gray-300 bg-white"
        data-spec-id={`EligibilityLookup:${eligibilityLookup.id}`}
      >
        <p className="pb-8 pt-4 px-4 text-center text-gray-700 text-lg">
          Retrieving coverage details...
        </p>
        <div className="mx-auto">
          <BlockSpinner />
        </div>
      </div>
    );
  } else if (!eligibilityLookup.data) {
    return <div>Not Loaded.</div>;
  }

  const coverage = new Coverage(JSON.parse(eligibilityLookup.data));

  return (
    <div
      className="mt-4 pt-4 px-4 pb-8 relative rounded-lg shadow border border-gray-300 bg-white"
      data-spec-id={`EligibilityLookup:${eligibilityLookup.id}`}
    >
      {coverage.hasError() ? (
        <CoverageLookupError coverage={coverage} />
      ) : coverage.isMedicare() ? (
        <MedicareCoverage coverage={coverage} />
      ) : (
        <>

          <div className="flex items-center">
            <div>
              {coverage.hasPlan() &&
                coverage.json.plan.coverage_status === "1" ? (
                <CheckCircleIcon className="h-6 w-6" color="green" />
              ) : (
                <ExclamationCircleIcon color="orange" />
              )}
            </div>
            <div className="ml-2 flex items-baseline">
              {coverage.hasPlan() ? (
                <h4
                  className={`font-semibold ${coverage.json.plan.coverage_status === "1"
                    ? "text-green-700"
                    : "text-orange-700"
                    }`}
                >
                  {coverage.json.plan.coverage_status_label}
                </h4>
              ) : (
                <h4 className="font-semibold text-red-700">Missing Plan</h4>
              )}

              <p className="ml-4 text-xs text-gray-600">
                Updated:{" "}
                {format(
                  parseISO(coverage.json.created_at),
                  "M/d/yy 'at' h:mm a"
                )}
              </p>
            </div>

          </div>
          <div className="mt-4 -mx-4 flex">
            {coverage.hasSubscriber() && (
              <div className="w-1/2 px-4">
                <h5 className="font-semibold text-gray-900">
                  Subscriber
                </h5>
                <DetailTable className="mt-4">
                  <DetailRow label="Name">
                    {coverage.getSubscriber().first_name}{" "}
                    {coverage.getSubscriber().last_name}
                  </DetailRow>
                  <DetailRow label="DOB">
                    {coverage.getSubscriber().dob &&
                      format(parseISO(coverage.getSubscriber().dob), "M/d/yy")}
                  </DetailRow>
                  <DetailRow label="Member ID" noWrapLabel>
                    {coverage.getSubscriber().member_id && (
                      <p className="font-mono">
                        {coverage.getSubscriber().member_id}
                      </p>
                    )}
                  </DetailRow>
                  <DetailRow label="Gender">
                    {formatGender(coverage.getSubscriber().gender)}
                  </DetailRow>
                  <DetailRow label="Address">
                    {coverage.hasSubscriberAddress() && (
                      <div className="leading-tight">
                        <p>{coverage.getSubscriberAddress().street_line_1}</p>
                        {coverage.getSubscriberAddress().street_line_2 && (
                          <p>{coverage.getSubscriberAddress().street_line_2}</p>
                        )}
                        <p>
                          {[
                            coverage.getSubscriberAddress().city,
                            coverage.getSubscriberAddress().state,
                            coverage.getSubscriberAddress().zip,
                          ]
                            .filter((e) => !!e)
                            .join(", ")}
                        </p>
                      </div>
                    )}
                  </DetailRow>
                </DetailTable>
              </div>
            )}
            <div className="w-1/2 px-4">
              <h5 className="font-semibold text-gray-900">Insurance</h5>
              <DetailTable className="mt-4">
                <DetailRow label="Payer" noWrapLabel>
                  {coverage.getInsurance() && coverage.getInsurance()!.name}
                </DetailRow>
                <DetailRow label="Payer Type" noWrapLabel>
                  {coverage.getInsurance() &&
                    coverage.getInsurance()!.payer_type_label}
                </DetailRow>
                <DetailRow label="Plan" noWrapLabel>
                  {coverage.getPlan() && coverage.getPlan()!.plan_name}
                </DetailRow>
                <DetailRow label="Plan Type" noWrapLabel>
                  {coverage.getPlan() && coverage.getPlan()!.plan_type_label}
                </DetailRow>
                <DetailRow label="Group Name" noWrapLabel>
                  {coverage.getPlan() && coverage.getPlan()!.group_name}
                </DetailRow>
                <DetailRow label="Plan Begins" noWrapLabel>
                  {!!coverage.getPlanBegins() &&
                    format(parseISO(coverage.getPlanBegins()!), "M/d/yy")}
                </DetailRow>
                {!!coverage.getPlanEligibilityBegins() && (
                  <DetailRow label="Eligibility Begins" noWrapLabel>
                    {format(
                      parseISO(coverage.getPlanEligibilityBegins()!),
                      "M/d/yy"
                    )}
                  </DetailRow>
                )}
                <DetailRow label="Plan Ends" noWrapLabel>
                  {!!coverage.getPlanEnds() &&
                    format(parseISO(coverage.getPlanEnds()!), "M/d/yy")}
                </DetailRow>
              </DetailTable>
            </div>
          </div>
          <div className="mt-4 -mx-4 flex">
            {coverage.hasDependent() && (
              <div className="w-1/2 px-4">
                <h5 className="text-sm font-semibold text-gray-900">
                  Dependent
                </h5>
                <DetailTable className="mt-4">
                  <DetailRow label="Name">
                    {coverage.getDependent().first_name}{" "}
                    {coverage.getDependent().last_name}
                  </DetailRow>
                  <DetailRow label="DOB">
                    {coverage.getDependent().dob &&
                      format(parseISO(coverage.getDependent().dob), "M/d/yy")}
                  </DetailRow>
                  <DetailRow label="Relationship">
                    {coverage.getDependent().relationship}
                  </DetailRow>
                  <DetailRow label="Member ID" noWrapLabel>
                    {coverage.getDependent().member_id && (
                      <p className="font-mono">
                        {coverage.getDependent().member_id}
                      </p>
                    )}
                  </DetailRow>
                  <DetailRow label="Gender">
                    {formatGender(coverage.getDependent().gender)}
                  </DetailRow>
                  <DetailRow label="Address">
                    {coverage.hasDependentAddress() && (
                      <div className="leading-tight">
                        <p>{coverage.getDependentAddress().street_line_1}</p>
                        {coverage.getDependentAddress().street_line_2 && (
                          <p>{coverage.getDependentAddress().street_line_2}</p>
                        )}
                        <p>
                          {[
                            coverage.getDependentAddress().city,
                            coverage.getDependentAddress().state,
                            coverage.getDependentAddress().zip,
                          ]
                            .filter((e) => !!e)
                            .join(", ")}
                        </p>
                      </div>
                    )}
                  </DetailRow>
                </DetailTable>
              </div>
            )}
          </div>
          <div className="mt-4">
            <ServicesCovered coverage={coverage} />
          </div>
          <Financials coverage={coverage} />
        </>
      )}
      <p className="absolute bottom-0 right-0 italic pb-2 px-4 text-gray-500 text-xs">
        ID: {coverage.json.eligible_id}
      </p>
    </div>
  );
};

const CoverageDetails: React.FC<CoverageDetailsProps> = ({
  eligibilityLookup,
}) => {
  const [showRawData, toggleShowRawData] = useToggle(false);

  return (
    <div className="_CoverageDetails">
      <BriefCoverageDetails eligibilityLookup={eligibilityLookup} />
      <div>
        {eligibilityLookup.data && (
          <>
            <button
              type="button"
              className="text-xs text-teal-500"
              onClick={toggleShowRawData}
            >
              Show raw data
            </button>
            {showRawData && (
              <pre className="mt-4 text-xs">
                {JSON.stringify(JSON.parse(eligibilityLookup.data), null, 2)}
              </pre>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { CoverageDetails };
