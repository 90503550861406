import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { useAuth } from "context/AuthContext";
import { FAIcon, ZoomIn, useDropdown } from "@preferral/ui";
import { Link } from "react-router-dom";
import { useAmdSessionContext } from "context/AmdSessionContext";

const CURRENT_USER = gql`
  query CurrentUser {
    me {
      id
      email
      firstName
      lastName
      timeZoneName
    }
  }
`;

interface Data {
  me: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    timeZoneName: string;
  };
}

interface CurrentUserMenuProps {}

export const CurrentUserMenu: FC<CurrentUserMenuProps> = props => {
  const { isOpen, toggle, triggerRef, contentRef } = useDropdown();

  const { data, error } = useQuery<Data>(CURRENT_USER);
  const initial = data?.me?.firstName ? data.me.firstName.slice(0, 1) : "-";
  const name = data?.me?.firstName || "Loading...";
  const email = data?.me?.email || "Loading...";

  const { logout: amdLogout, isActive: isAmdContextActive } =
    useAmdSessionContext();
  const { logout } = useAuth();

  return (
    <div className="_CurrentUserMenu mx-auto inline-block relative text-left">
      <div>
        <span className="rounded-lg shadow-sm">
          <button
            type="button"
            ref={triggerRef}
            onClick={toggle}
            className="inline-flex items-center justify-center w-full rounded-lg border border-gray-300 p-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
          >
            {error ? (
              <p>Failed to load</p>
            ) : (
              <>
                <p className="bg-indigo-200 flex h-8 items-center justify-center leading-none rounded-lg shadow-inner text-indigo-700 text-lg w-8">
                  {initial}
                </p>
                <p className="hidden sm:block flex-1 ml-3 mr-4 text-gray-800 text-left truncate">
                  {name}
                </p>
                <div className="ml-3 mr-2 text-gray-600">
                  <FAIcon icon="chevron-down" />
                </div>
              </>
            )}
          </button>
        </span>
      </div>

      <ZoomIn
        ref={contentRef}
        show={isOpen}
        className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg"
      >
        <div className="rounded-md bg-white shadow-xs">
          <div className="px-4 py-3">
            <p className="text-sm leading-5">Signed in as</p>
            <p className="text-sm leading-5 font-medium text-gray-900 truncate">
              {email}
            </p>
          </div>
          <div className="border-t border-gray-100"></div>
          <div className="py-1">
            <Link
              to="/o/settings/profile"
              className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            >
              Account settings
            </Link>
          </div>
          <div className="border-t border-gray-100"></div>
          <div className="py-1">
            <button
              type="button"
              onClick={() => (isAmdContextActive() ? amdLogout() : logout())}
              className="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
            >
              Sign out
            </button>
          </div>
        </div>
      </ZoomIn>
    </div>
  );
};
