import React, { FC } from "react";
import { components, OptionProps } from "react-select";
import {
  FilterForm,
  FilterField,
  SelectInput,
  Button,
  StandardOption,
  TextInput,
} from "@preferral/ui";
import { gql, useQuery } from "@apollo/client";
import { TagModel } from "components/TagCombobox";

const LIST_PATIENT_PACKETS = gql`
  query ListPatientPackets {
    patientPackets {
      endOfList
      cursor
      items {
        id
        title
      }
    }
  }
`;

interface Data {
  patientPackets: Paginated<PatientPacketModel>;
}

interface PatientPacketModel {
  id: string;
  title: string;
}

/**
 * TagOption.
 */

const TagOption: React.FC<OptionProps<TagModel, true>> = React.memo(props => {
  return (
    <components.Option {...props}>
      <div className="flex items-center font-semibold">
        <div
          className="w-5 h-5 mr-2 rounded-full shadow-inner"
          style={{ backgroundColor: props.data.color }}
        />
        <p className="flex-grow">{props.data.label}</p>
      </div>
    </components.Option>
  );
});

export type PacketSubmissionStatus = "UNOPENED" | "PENDING" | "COMPLETED";

export const statusLabels: Record<PacketSubmissionStatus, string> = {
  UNOPENED: "Unopened",
  PENDING: "In Progress",
  COMPLETED: "Complete",
};

const statusOptions: StandardOption[] = [
  {
    value: "ALL",
    label: "All",
  },
  {
    value: "ALL_OPEN",
    label: "All Open",
  },
  ...Object.keys(statusLabels).map(key => ({
    value: key,
    label: statusLabels[key],
  })),
  {
    value: "ARCHIVED",
    label: "Archived"
  }
];

export interface FilterModel {
  searchTerm?: string;
  patientPacketId?: string;
  status?: "ALL" | "ALL_OPEN" | PacketSubmissionStatus;
  tagIds: string[];
}

export const defaultFilter: FilterModel = {
  searchTerm: "",
  patientPacketId: "ALL",
  status: "ALL_OPEN",
  tagIds: []
};

const allOption = {
  value: "ALL",
  label: "All",
};

interface FilterPanelProps {
  value: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading: boolean;
  dataLoading?: boolean;
  filterData: {
    tags: void | TagModel[];
  };
}

export const FilterPanel: FC<FilterPanelProps> = props => {
  const {
    value,
    onChange,
    isLoading = false,
    filterData,
    dataLoading = false,
  } = props;

  const { data, loading } = useQuery<Data>(LIST_PATIENT_PACKETS, {
    variables: { filter: value },
  });

  const patientFormOptions = [
    allOption,
    ...(data?.patientPackets.items.map(pf => ({
      value: pf.id,
      label: pf.title,
    })) || []),
  ];

  return (
    <FilterForm<FilterModel>
      defaultValue={defaultFilter}
      value={value}
      onChange={onChange}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="searchTerm" icon="search" label="Search">
          <TextInput name="searchTerm" placeholder="Patient Name Search" />
        </FilterField>

        <FilterField htmlFor="patientPacketId" icon="filter" label="Packet">
          <div className="w-64">
            <SelectInput
              name="patientPacketId"
              options={patientFormOptions}
              isLoading={loading}
            />
          </div>
        </FilterField>

        <FilterField htmlFor="status" icon="filter" label="Status">
          <div className="w-48">
            <SelectInput name="status" options={statusOptions} />
          </div>
        </FilterField>

        <FilterField htmlFor="tagIds" icon="tags" label="Tags">
          <div className="w-56">
            <SelectInput<TagModel>
              name="tagIds"
              options={filterData.tags || []}
              getOptionLabel={(o: TagModel) => o.label}
              getOptionValue={(o: TagModel) => o.id}
              placeholder="Any"
              isLoading={dataLoading}
              components={{ Option: TagOption }}
              isClearable
              isMulti
            />
          </div>
        </FilterField>

        <input type="submit" style={{ display: "none" }} />
        <FilterField>
          <Button
            type="submit"
            color="blue"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Apply Filter
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};
