import { FC } from "react";
import qs from "query-string";
import { Link, useLocation } from "react-router-dom";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { coalesceString } from "lib/coalesce";
import { useAuth } from "context/AuthContext";
import preferralLogoSrc from "images/aristamd_logo.svg";

export const ResetPasswordScreen: FC = () => {
  const location = useLocation();
  const { id, token } = qs.parse(location.search);
  const { login } = useAuth();

  return (
    <div className="min-h-screen bg-cool-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto w-48"
          src={preferralLogoSrc}
          alt="Preferral Logo"
        />
      </div>
      {id && token ? (
        <>
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Reset Your Password
          </h2>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <ResetPasswordForm
              id={coalesceString(id)}
              token={coalesceString(token)}
              onSignIn={login}
            />
          </div>
        </>
      ) : (
        <div>
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Invalid Password Reset URL
          </h2>
          <p className="mt-4">
            <Link
              to="/auth/forgot_password"
              className="text-blue-500 hover:text-blue-600 font-semibold underline"
            >
              Click Here
            </Link>{" "}
            to request a new password reset link
          </p>
        </div>
      )}
    </div>
  );
};
