import React, { useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "@preferral/ui";
import { Spinner } from "components/Spinner";
import toast from "react-hot-toast";

const INBOX = gql`
  query GetInbox($id: UUID4!) {
    inbox(id: $id) {
      id
      name
    }
  }
`;

interface Data {
  inbox: {
    id: string;
    name: string;
  };
}

const UPDATE_INBOX = gql`
  mutation UpdateInbox($id: UUID4!, $input: InboxInput!) {
    updateInbox(id: $id, input: $input) {
      errors {
        key
        message
      }
      inbox {
        id
        name
        active
      }
    }
  }
`;

interface MutationData {
  updateInbox: {
    errors?: InputError[];
    inbox?: {
      id: string;
      name: string;
      active: boolean;
    };
  };
}

interface MutationVariables {
  id: string;
  input: FormValues;
}

type FormValues = {
  name: string;
};

type EditInboxFormProps = {
  inboxId: string;
  refetchQueries: any;
  closeModal: () => void;
};

const EditInboxForm: React.FC<EditInboxFormProps> = ({
  inboxId,
  refetchQueries,
  closeModal,
}) => {
  const { data, loading, error } = useQuery<Data>(INBOX, {
    variables: { id: inboxId },
  });
  const [updateInbox] = useMutation<MutationData, MutationVariables>(
    UPDATE_INBOX
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      updateInbox({
        variables: { id: inboxId, input: values },
        refetchQueries,
      }).then(
        (resp: any) => {
          if (resp && resp.data && resp.data.updateInbox.errors) {
            setStatus({ errors: resp.data.updateInbox.errors });
          } else if (resp && resp.data && resp.data.updateInbox.inbox) {
            // it worked
            toast.success("Inbox updated");
            closeModal();
          }
          setSubmitting(false);
        },
        (rej: any) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong" }],
          });
          setSubmitting(false);
        }
      );
    },
    [updateInbox, toast, refetchQueries]
  );

  if (loading) {
    return (
      <div className="p-6 text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="EditInboxForm">
      {loading ? (
        <p>Loading...</p>
      ) : error || !(data && data.inbox) ? (
        <div>
          <p>Failed to load</p>
        </div>
      ) : (
        <Formik<FormValues>
          initialValues={{ name: data.inbox && data.inbox.name }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .trim()
              .required("Required"),
          })}
          onSubmit={onSubmit}
        >
          {({ status, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />
              <div className="mt-3">
                <TextField name="name" label="Inbox Name" autoFocus />
              </div>
              <div className="flex items-center justify-end mt-3 py-4">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-blue"
                >
                  Update
                </button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export { EditInboxForm };
