import { FC } from "react";
import { ErrorMessage, useField } from "formik";
import { DateRangeDropdown, TimeRange } from "components/DateRangeDropdown";

interface DateRangeDropdownInputProps {
  id?: string;
  name: string;
};

export const DateRangeDropdownInput: FC<DateRangeDropdownInputProps> = props => {
  const { id, name } = props;
  const [field, , helpers] = useField<TimeRange>(name);
  const { value } = field;
  const { setValue } = helpers;

  return (
    <>
      <DateRangeDropdown id={id} value={value} onChange={setValue} />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
};
