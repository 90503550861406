import React, { FC, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useLocation, useRouteMatch } from "react-router-dom";
import { GradientHeader } from "screens/SettingsScreen/GradientHeader";
import { Spinner } from "components/Spinner";
import { NoResults } from "components/NoResults";
import { TableContainer, Table, TH, TD, FAIcon } from "@preferral/ui";
import { DropdownButton, DropdownItemLink } from "components/DropdownButton";
import { ScreenTitle } from "context/ScreenTitle";
import { NewPatientFormModal } from "./NewPatientFormModal";

const DEPARTMENT_PATIENT_FORMS = gql`
  query GetDepartmentPatientForms($id: UUID4!) {
    department(id: $id) {
      id
      name
      patientForms {
        id
        title
        insertedAt
        updatedAt
      }
    }
  }
`;

interface Data {
  department: {
    id: string;
    name: string;
    patientForms: {
      id: string;
      title: string;
      insertedAt: string;
      updatedAt: string;
    }[];
  };
}

interface Variables {
  id: string;
}

interface PatientFormsScreenProps {}

export const PatientFormsScreen: FC<PatientFormsScreenProps> = (props) => {
  const location = useLocation();
  const match = useRouteMatch<{ departmentId: string }>();
  const { departmentId } = match.params;

  const [activeModal, setActiveModal] = useState<"NEW_FORM" | null>(null);
  const closeModal = () => setActiveModal(null);
  const openNewFormModal = () => setActiveModal("NEW_FORM");

  const { data, loading, error } = useQuery<Data, Variables>(
    DEPARTMENT_PATIENT_FORMS,
    { variables: { id: departmentId } }
  );

  return (
    <>
      <ScreenTitle title="Settings » Department » Patient Forms" />
      <NewPatientFormModal
        departmentId={departmentId}
        isOpen={activeModal === "NEW_FORM"}
        onClose={closeModal}
        refetchQueries={[
          {
            query: DEPARTMENT_PATIENT_FORMS,
            variables: { id: departmentId },
          },
        ]}
      />
      <div className="bg-white box rounded-lg shadow-lg">
        <GradientHeader
          icon="file-alt"
          title="Patient Forms"
          subtitle={
            data?.department
              ? `Manage patient forms for the ${data.department.name} department.`
              : ""
          }
        />
        <div className="p-4">
          {loading ? (
            <div className="p-12">
              <Spinner />
            </div>
          ) : error || !(data && data.department) ? (
            <p>Failed to load</p>
          ) : (
            <div>
              <div className="flex justify-end px-3 pb-4">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={openNewFormModal}
                >
                  <FAIcon icon="plus" />
                  <span className="ml-2">Add Patient Form</span>
                </button>
              </div>
              {data.department.patientForms.length === 0 ? (
                <NoResults icon="file-alt" text="No patient forms added yet" />
              ) : (
                <TableContainer>
                  <Table>
                    <thead>
                      <tr>
                        <TH>Form Name</TH>
                        <TH />
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {data.department.patientForms.map((pf) => (
                        <tr key={pf.id}>
                          <TD>
                            {pf.title}
                            <a
                              href={`${window.location.origin}/pg/forms/${pf.id}`}
                              target="_blank"
                              rel="noref nofollow"
                              className="ml-2 text-sm text-blue-500 underline"
                            >
                              <FAIcon icon="external-link-alt" />
                            </a>
                          </TD>
                          <TD className="w-40">
                            <DropdownButton label="Actions">
                              <DropdownItemLink
                                to={`${location.pathname}/${pf.id}/edit`}
                              >
                                Edit Form
                              </DropdownItemLink>
                            </DropdownButton>
                          </TD>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
