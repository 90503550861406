import React, { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField, TextMaskField, Button } from "@preferral/ui";
import { LocationReferenceModel } from "./model";
import { StateSelectField } from "components/formik/StateSelectField";
import { CountySelectField } from "components/formik/CountySelectField";
import { ProviderReferenceModel } from "components/ProviderSearchSelect";

const zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

const CREATE_UNLISTED_LOCATION = gql`
  mutation CreateUnlistedLocation($input: UnlistedLocationInput!) {
    createUnlistedLocation(input: $input) {
      errors {
        key
        message
      }
      unlistedLocation {
        id
      }
    }
  }
`;

interface MutationData {
  createUnlistedLocation: {
    errors?: InputError[];
    unlistedLocation?: UnlistedLocationModel;
  };
}

interface MutationVariables {
  input: UnlistedLocationInput;
}

interface UnlistedLocationInput extends FormValues {
  providers?: ProviderReferenceModel[];
}

interface UnlistedLocationModel {
  id: string;
}

interface FormValues {
  name: string;
  streetAddress: string;
  streetAddressLine2?: string;
  city: string;
  stateAbbreviation: string;
  zip: string;
  fipsCode: string;
}

const initialValues: FormValues = {
  name: "",
  streetAddress: "",
  streetAddressLine2: "",
  city: "",
  stateAbbreviation: "",
  zip: "",
  fipsCode: "",
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    name: Yup.string().trim().required("Required"),
    streetAddress: Yup.string().trim().required("Required"),
    city: Yup.string().trim().required("Required"),
    stateAbbreviation: Yup.string().required("Required"),
    zip: Yup.string()
      .trim()
      .matches(/^\d{5}$/, "Invalid zip code")
      .required("Required"),
    fipsCode: Yup.string().required("Required"),
  })
  .required();

interface UnlistedLocationFormProps {
  providers?: ProviderReferenceModel[];
  onSuccess(location: LocationReferenceModel): void;
}

export const UnlistedLocationForm: FC<UnlistedLocationFormProps> = (props) => {
  const { providers, onSuccess } = props;

  const [createUnlistedLocation] = useMutation<MutationData, MutationVariables>(
    CREATE_UNLISTED_LOCATION
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      createUnlistedLocation({
        variables: { input: { ...values, providers } },
      }).then(
        (resp) => {
          if (resp.data?.createUnlistedLocation.errors) {
            setStatus({ errors: resp.data.createUnlistedLocation.errors });
          } else if (resp.data?.createUnlistedLocation.unlistedLocation) {
            // it worked
            const { unlistedLocation } = resp.data.createUnlistedLocation;
            const locationReference: LocationReferenceModel = {
              kind: "unlisted",
              id: unlistedLocation.id,
            };
            return onSuccess(locationReference);
          }
          setSubmitting(false);
        },
        (_rej) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong" }],
          });
          setSubmitting(false);
        }
      );
    },
    [createUnlistedLocation, onSuccess, providers]
  );

  return (
    <div className="_UnlistedLocationForm p-4">
      <p className="bg-cool-gray-100 p-2 rounded-lg text-sm">
        If you couldn't find the referring practice location in the dropdown,
        you can enter it here. After entering, the practice location will be
        available in the dropdown for future referrals.
      </p>
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, handleSubmit, values, errors }) => (
          <form onSubmit={handleSubmit} className="mt-5">
            <FormStatusErrors status={status} />

            <div className="mt-3">
              <TextField name="name" label="Practice Location Name" autoFocus />
            </div>

            <div className="mt-3 sm:grid sm:grid-cols-2 sm:gap-3">
              <TextField name="streetAddress" label="Address" />
              <div className="mt-3 sm:mt-0">
                <TextField name="streetAddressLine2" label="Address Line 2" />
              </div>
            </div>

            <div className="mt-3 sm:grid sm:grid-cols-2 sm:gap-3">
              <TextField name="city" label="City" />
              <div className="mt-3 sm:mt-0">
                <StateSelectField name="stateAbbreviation" label="State" />
              </div>
            </div>

            <div className="mt-3 sm:grid sm:grid-cols-3 sm:gap-3">
              <TextMaskField
                mask={zipMask}
                name="zip"
                label="Zip"
                placeholder="Zip"
              />
              <div className="mt-3 sm:mt-0 sm:col-span-2">
                <CountySelectField
                  label="County"
                  name="fipsCode"
                  zip={values.zip}
                  placeholder={
                    values.zip.length === 5 ? "Select..." : "Enter Zip first..."
                  }
                  disabled={values.zip.length !== 5}
                />
              </div>
            </div>

            <div className="flex items-center justify-end mt-3 py-4">
              <Button
                type="submit"
                kind="primary"
                color="blue"
                disabled={isSubmitting}
              >
                Add Location
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
