// const levels = ["individual", "family", "children only", "dependents only", "employee only", "spouse only", "spouse and children", "employee and spouse", "employee and children"];

// type Demographic

type CoverageErrorJSON = {
  error?: any;
};

type Address = {
  street_line_1: string;
  street_line_2: string | null;
  state: string;
  zip: string;
  city: string;
};

type Person = {
  gender?: "M" | "F";
  member_id: string;
  first_name: string;
  middle_name?: string | null;
  last_name: string;
  dob: string;
  group_id: string | null;
  group_name: string | null;
  relationship?: string;
  address: Address;
};

/**
 * This section returns demographic information according to the payer's system.
 *
 * Typically, any claims submitted to the payer will require information to match this data; otherwise, there is a high likelihood a
 * claim will be denied. If the information here does not match the information provided by a patient, it is recommended to inform
 * the patient and ask them to resolve any discrepencies with their carier before attempting any further transactions on their
 * behalf.
 *
 * If the patient is a dependent of the plan's subscriber, the patient information will be in the "dependent" section, along with an
 * additional field, "relationship", which will return a code defining the patient's relationship to that subscriber. Otherwise, it is
 * assumed the subscriber is also the patient.
 */
type Demographics = {
  subscriber?: Person;
  dependent?: {} | Person;
};

/**
 * ### Plan Details
 * - [Coverage Status Codes](https://account.eligible.com/docs/appendix#codes-coverage-status-codes) provides a list of possible **`coverage_status`** values and their descriptions.
 * - [Plan Type Codes](https://account.eligible.com/docs/appendix#plan-type-codes) provides a list of possible **`plan_type`** values and their descriptions.
 * - [Date Types](https://account.eligible.com/docs/appendix#coverage-response-date-types) provides a list of possible **`date_type`** values in the **`dates`** array.
 *
 * ### Additional Insurance Policies
 *
 * If there are any additional policies that are found for the patient, they will be listed in this array. This could include Primary,
 * Secondary, Vision, Medicare Advantage, etc. This information may be useful for retrieving additional coverage details if your
 * patient is covered by multiple policies.
 */
type Plan = {
  type: string;
  plan_type: PlanTypeCode | null;
  plan_type_label: string | null;
  plan_number: string;
  plan_name: string;
  coverage_status: string;
  coverage_status_label: string;
  group_name: string | null;
  financials?: {} | Financials;
  exclusions?: {
    noncovered: any[];
    pre_existing_condition: {
      waiting_period: [];
    };
  };
  reference?: [];
  benefit_details: {
    benefit_description: {
      amounts: any[];
    };
    managed_care: {
      amounts: any[];
    };
    unlimited: {
      amounts: any[];
    };
  };
  additional_insurance_policies: AdditionalInsurancePolicy[];
  dates: DateDescriptor[];
  comments: string[];
};

type DateDescriptor = {
  date_type: string;
  date_value: string;
  date_source?: string;
};

type AdditionalInsurancePolicy = {
  coverage_description: null | string;
  insurance_type: null | string;
  comments: string[];
  reference: string[];
  contact_details: {
    entity_code: string;
    entity_code_label: string;
    first_name: string | null;
    last_name: string;
    identification_code: string;
    identification_type: string;
    contacts: any[];
    address: Address;
  }[];
  payer_type: string;
  payer_type_label: string;
  insurance_type_label: string | null;
  service_type_codes: any[];
  dates: DateDescriptor[];
};

type Financials = {
  deductible: {
    totals: NetworkCoverage<FinancialAmountData>;
    remainings: NetworkCoverage<FinancialAmountData>;
  };
  stop_loss: {
    totals: NetworkCoverage<FinancialAmountData>;
    remainings: NetworkCoverage<FinancialAmountData>;
  };
  spend_down: {
    totals: NetworkCoverage<FinancialAmountData>;
    remainings: NetworkCoverage<FinancialAmountData>;
  };
  cost_containment: {
    totals: NetworkCoverage<FinancialAmountData>;
    remainings: NetworkCoverage<FinancialAmountData>;
  };
  copayment: {
    amounts: NetworkCoverage<FinancialAmountData>;
  };
  coinsurance: {
    percents: NetworkCoverage<FinancialPercentData>;
  };
  reserve: {
    remainings: NetworkCoverage<FinancialAmountData>;
  };
  limitations: {
    amounts: FinancialAmountData[];
  };
  other_sources: {
    amounts: FinancialAmountData[];
  };
  disclaimer: string[];
};

type NetworkCoverage<T> = {
  in_network: T[];
  out_network: T[];
};

interface FinancialBaseData {}

interface FinancialAmountData extends FinancialBaseData {}

interface FinancialPercentData extends FinancialBaseData {}

type PlanTypeCode =
  | "D"
  | "E"
  | "U"
  | "12"
  | "13"
  | "14"
  | "15"
  | "16"
  | "41"
  | "42"
  | "43"
  | "47"
  | "AP"
  | "C1"
  | "CO"
  | "CP"
  | "DB"
  | "EP"
  | "FF"
  | "GP"
  | "HM"
  | "HN"
  | "HS"
  | "IN"
  | "IP"
  | "LC"
  | "LD"
  | "LI"
  | "LT"
  | "MA"
  | "MB"
  | "MC"
  | "MH"
  | "MI"
  | "MP"
  | "OT"
  | "PE"
  | "PL"
  | "PP"
  | "PR"
  | "PS"
  | "QM"
  | "RP"
  | "SP"
  | "TF"
  | "WC"
  | "WU";

export const plan_types: { [x in PlanTypeCode]: string } = {
  D:
    "Disability - Provides periodic payments to replace income when an insured person is unable to work as a result of illness, injury or disease",
  E: "Medicare - Point of Service (POS)",
  U: "Multiple Options Health Plan",
  "12":
    "Medicare Secondary Working Aged Beneficiary or Spouse with Employer Group Health Plan",
  "13":
    "Medicare Secondary End-Stage Renal Disease Beneficiary in the Mandated Coordination Period with an Employer's Group Health Plan",
  "14": "Medicare Secondary, No-fault Insurance including Auto is Primary",
  "15": "Medicare Secondary Worker's Compensation",
  "16":
    "Medicare Secondary Public Health Service (PHS) or Other Federal Agency",
  "41": "Medicare Secondary Black Lung",
  "42": "Medicare Secondary Veteran's Administration",
  "43":
    "Medicare Secondary Disabled Beneficiary Under Age 65 with Large Group Health Plan (LGHP)",
  "47": "Medicare Secondary, Other Liability Insurance is Primary",
  AP: "Auto Insurance Policy",
  C1: "Commercial",
  CO: "Consolidated Omnibus Budget Reconciliation Act (COBRA)",
  CP: "Medicare Conditionally Primary",
  DB: "Disability Benefits",
  EP:
    "Exclusive Provider Organization - Gives subscriber a choice of providers from an approved/contracted payer list; there are fixed dollar co-payments for most covered services in return for using plan providers",
  FF: "Family or Friends",
  GP:
    "Group Policy - Two or more people who are part of complete unit who enter into an insurance contract with an insurance company",
  HM: "Health Maintenance Organization (HMO)",
  HN: "Health Maintenance Organization (HMO) - Medicare Risk",
  HS:
    "Special Low Income Medicare Beneficiary - An individual eligible for Medicare for whom Medicaid pays only Medicare premiums",
  IN:
    "Indemnity - Gives a subscriber the choice to select any provider. Payment is fixed percentage of the cost for covered care after satisfying an annual deductible",
  IP: "Individual Policy",
  LC:
    "Long Term Care - Coverage designed to help pay for some or all long term care costs, reducing the risk that a policy-holder would need to deplete all of his or her assets to pay for long term care",
  LD: "Long Term Policy",
  LI: "Life Insurance",
  LT: "Litigation",
  MA: "Medicare Part A",
  MB: "Medicare Part B",
  MC:
    "Medicaid - Program of health care services made available to medically indigent and other needy persons, regardless of age, under terms of a 1965 amendment to the U.S. Social Security Act",
  MH:
    "Medigap Part A - Health insurance policy intended to cover the non-covered portion of expenses eligible for Medicare Part A reimbursement which must be paid by a Medicare beneficiary for health care services and/or supplies received",
  MI:
    "Medigap Part B - Health insurance policy intended to cover the non-covered portion of expenses eligible for Medicare Part B reimbursement which must be paid by a Medicare beneficiary for health care services and/or supplies received",
  MP:
    "Medicare Primary - Medicare has the primary responsibility to pay for health care services and/or supplies received by a covered beneficiary (a person entitled to Medicare benefits)",
  OT: "Other",
  PE: "Property Insurance - Personal",
  PL: "Personal",
  PP: "Personal Payment (Cash - No Insurance)",
  PR: "Preferred Provider Organization (PPO)",
  PS: "Point of Service (POS)",
  QM:
    "Qualified Medicare Beneficiary - Coverage for a Medicare eligible individual for whom Medicaid pays only for Medicare premiums, co-insurance, and deductibles",
  RP: "Property Insurance - Real",
  SP:
    "Supplemental Policy - An insurance policy intended to cover non-covered charges of another insurance policy",
  TF: "Tax Equity Fiscal Responsibility Act (TEFRA)",
  WC:
    "Workers Compensation - Coverage provides medical treatment, rehabilitation, lost wages and related expenses arising from a job related injury or disease",
  WU:
    "Wrap Up Policy - A Workers Compensation Policy written for a specific job site, which will include or cover more than one insured",
};

type Service = {
  type: string;
  type_label: string;
  coverage_basis: any[];
  coverage_status: string;
  coverage_status_label: string;
  noncovered: string[];
  financials: Financials;
  visits: {
    totals: {
      in_network: [];
      out_network: [];
    };
    spent: {
      in_network: [];
      out_network: [];
    };
    remainings: {
      in_network: [];
      out_network: [];
    };
  };
  additional_insurance_policies: AdditionalInsurancePolicy[];
  facility: {
    amounts: any[];
  };
  benefit_details: {
    managed_care: {
      amounts: any[];
    };
    benefit_description: {
      amounts: any[];
    };
    unlimited: {
      amounts: any[];
    };
  };
};

/**
 * The insurance section of the response provides you with details of the payer as well as details of the patient's known
 * physicians.
 *
 * For a full list of possible fields, please visit Eligible's [API Reference docs](https://account.eligible.com/reference#coverage).
 */
type Insurance = {
  id: string;
  name: string;
  payer_type: string;
  payer_type_label: string;
  contacts: Contact[];
  service_providers: ServiceProviders;
};

type Contact = {
  contact_type: string;
  contact_value: string;
  contact_name: string;
};

type ServiceProviders = {
  physicians: {
    insurance_type: string;
    primary_care: boolean;
    comments: string[];
    contact_details: any[];
    eligibility_code: string;
    insurance_type_label: string;
    eligibility_code_label: string;
    restricted: boolean;
    dates: any[];
  }[];
};

type CoverageSuccessJSON = {
  error: undefined;
  eligible_id: string;
  created_at: string;
  demographics: Demographics;
  plan: Plan;
  services: Service[];
  insurance: Insurance;
};

type CoverageJSON = CoverageSuccessJSON; // | CoverageErrorJSON

class Coverage {
  json: JSONObject; // CoverageJSON;

  constructor(json: JSONObject) {
    this.json = json;
  }

  hasError() {
    return this.json.error !== undefined;
  }

  parseError() {
    if (this.hasError()) {
      return this.json.error;
    } else {
      return null;
    }
  }

  hasDemographics(): boolean {
    return !!(
      this.json.demographics?.dependent || this.json.demographics?.subscriber
    );
  }

  getDemographics(): Demographics | null {
    if (!this.hasDemographics()) {
      return null;
    }
    return this.json.demographics;
  }

  hasSubscriber(): boolean {
    return !!this.json.demographics?.subscriber;
  }

  getSubscriber() {
    if (!this.hasSubscriber()) {
      return null;
    }
    return this.json.demographics.subscriber;
  }

  hasDependent(): boolean {
    return !!this.json.demographics?.dependent?.first_name;
  }

  getDependent() {
    if (!this.hasDependent()) {
      return null;
    }
    return this.json.demographics.dependent;
  }

  getPatient() {
    if (!this.hasDemographics()) {
      return null;
    }
    if (this.json.demographics.dependent?.first_name) {
      return this.json.demographics.dependent;
    } else {
      if (
        this.json.demographics.subscriber?.first_name ||
        this.json.demographics.subscriber?.member_id
      ) {
        return this.json.demographics.subscriber;
      } else {
        return null;
      }
    }
  }

  hasServiceProviders(): boolean {
    if (!this.hasInsurance()) {
      return false;
    }
    return !!(
      this.json.insurance.service_providers?.physicians &&
      this.json.insurance.service_providers?.physicians.length > 0
    );
  }

  getServiceProviders(): null | ServiceProviders {
    if (!this.hasServiceProviders()) {
      return null;
    }
    return this.json.insurance.service_providers;
  }

  hasInsurance(): boolean {
    if (this.json.insurance === undefined) {
      return false;
    }
    return !!this.json.insurance?.name;
  }

  getInsurance(): Insurance | null {
    if (!this.hasInsurance()) {
      return null;
    }
    return this.json.insurance;
  }

  hasPlan(): boolean {
    if (this.json.plan === undefined) {
      return false;
    }
    return !!this.json.plan?.coverage_status_label;
  }

  getPlan(): Plan | null {
    if (!this.hasPlan()) {
      return null;
    }
    return this.json.plan;
  }

  getPlanBeginsDate(dates: DateDescriptor[]) {
    const date = dates.find((d) => d.date_type === "plan_begin");
    if (!date) {
      return null;
    }
    return date.date_value;
  }

  getPlanEligibilityBeginsDate(dates: DateDescriptor[]) {
    const date = dates.find((d) => d.date_type === "eligibility_begin");
    if (!date) {
      return null;
    }
    return date.date_value;
  }

  getPlanEndsDate(dates: DateDescriptor[]) {
    const date = dates.find((d) => d.date_type === "plan_end");
    if (!date) {
      return null;
    }
    return date.date_value;
  }

  getPlanEligibilityEndsDate(dates: DateDescriptor[]) {
    const date = dates.find((d) => d.date_type === "eligibility_end");
    if (!date) {
      return null;
    }
    return date.date_value;
  }

  hasPlanDates(): boolean {
    if (!this.hasPlan()) {
      return false;
    }
    return !!this.json.plan.dates;
  }

  getPlanBegins(): string | null {
    if (!this.hasPlanDates()) {
      return null;
    }
    return this.getPlanBeginsDate(this.json.plan.dates);
  }

  getPlanEligibilityBegins(): string | null {
    if (!this.hasPlanDates()) {
      return null;
    }
    return this.getPlanEligibilityBeginsDate(this.json.plan.dates);
  }

  getPlanEnds(): string | null {
    if (!this.hasPlanDates()) {
      return null;
    }
    return this.getPlanEndsDate(this.json.plan.dates);
  }

  getPlanEligibilityEnds(): string | null {
    if (!this.hasPlanDates()) {
      return null;
    }
    return this.getPlanEligibilityEndsDate(this.json.plan.dates);
  }

  hasFinancials(plan: JSONObject) {
    if (plan.financials === undefined) {
      return false;
    }
    return !!(plan?.financials && typeof plan.financials === "object");
  }

  hasPlanFinancials() {
    if (!this.hasPlan()) {
      return false;
    }
    return this.hasFinancials(this.json.plan);
  }

  getPlanFinancials() {
    if (!this.hasPlanFinancials()) {
      return null;
    }
    return this.json.plan.financials;
  }

  hasMaximumMinimum(stopLoss?: JSONObject): boolean {
    if (!stopLoss) {
      return false;
    }
    return (
      stopLoss.remainings.in_network.length > 0 ||
      stopLoss.remainings.out_network.length > 0 ||
      stopLoss.totals.in_network.length > 0 ||
      stopLoss.totals.out_network.length > 0
    );
  }

  hasPlanMaximumMinimum() {
    if (!this.hasPlanFinancials()) {
      return false;
    }
    const stopLoss = this.json.plan.financials.stop_loss;
    return this.hasMaximumMinimum(stopLoss);
  }

  getPlanMaximumMinimum() {
    if (!this.hasPlanMaximumMinimum()) {
      return null;
    }
    return this.json.plan.financials.stop_loss;
  }

  hasServicesMaximumMinimum(): boolean {
    if (this.getServices() === null) {
      return false;
    }
    return this.getServices().some((value: JSONObject) => {
      return (
        this.hasFinancials(value) &&
        this.hasMaximumMinimum(value.financials.stop_loss)
      );
    });
  }

  hasDeductibles(financialItem?: JSONObject) {
    return (
      financialItem &&
      (financialItem.remainings.in_network.length > 0 ||
        financialItem.remainings.out_network.length > 0 ||
        financialItem.totals.in_network.length > 0 ||
        financialItem.totals.out_network.length > 0)
    );
  }

  hasPlanDeductibles() {
    if (!this.hasPlanFinancials()) {
      return false;
    }
    const deductible = this.json.plan.financials.deductible;
    return this.hasDeductibles(deductible);
  }

  hasServicesDeductibles(): boolean {
    if (!this.hasServices()) {
      return false;
    }
    return this.getServices().some((service: JSONObject) => {
      return (
        this.hasFinancials(service) &&
        this.hasDeductibles(service.financials.deductible)
      );
    });
  }

  getPlanDeductibles() {
    if (!this.hasPlanDeductibles()) {
      return null;
    }
    return this.json.plan.financials.deductible;
  }

  hasCoinsurance(financialItem?: JSONObject) {
    return (
      financialItem &&
      (financialItem.percents.in_network.length > 0 ||
        financialItem.percents.out_network.length > 0)
    );
  }

  hasPlanCoinsurance() {
    if (!this.hasPlanFinancials()) {
      return false;
    }
    const coinsurance = this.json.plan.financials.coinsurance;
    return this.hasCoinsurance(coinsurance);
  }

  hasServicesCoinsurance(): boolean {
    if (!this.getServices()) {
      return false;
    }
    return this.getServices().some((service: JSONObject) => {
      return (
        this.hasFinancials(service) &&
        this.hasCoinsurance(service.financials.coinsurance)
      );
    });
  }

  getPlanCoinsurance() {
    if (!this.hasPlanCoinsurance()) {
      return null;
    }
    return this.json.plan.financials.coinsurance;
  }

  hasCopayment(financialItem?: JSONObject) {
    return (
      financialItem &&
      (financialItem.in_network.length > 0 ||
        financialItem.out_network.length > 0)
    );
  }

  hasPlanCopayment() {
    if (!this.hasPlanFinancials()) {
      return false;
    }
    const amounts = this.json.plan.financials.copayment.amounts;
    return this.hasCopayment(amounts);
  }

  hasServicesCopayment(): boolean {
    if (!this.getServices()) {
      return false;
    }
    return this.getServices().some((service: JSONObject) => {
      return (
        this.hasFinancials(service) &&
        this.hasCopayment(service.financials.copayment.amounts)
      );
    });
  }

  getPlanCopayment() {
    if (!this.hasPlanCopayment()) {
      return null;
    }
    return this.json.plan.financials.copayment;
  }

  hasSpendDown(financialItem?: JSONObject) {
    return (
      financialItem &&
      (financialItem.remainings.in_network.length > 0 ||
        financialItem.remainings.out_network.length > 0 ||
        financialItem.totals.in_network.length > 0 ||
        financialItem.totals.out_network.length > 0)
    );
  }

  hasPlanSpendDown() {
    if (!this.hasPlanFinancials()) {
      return false;
    }
    const spendDown = this.json.plan.financials.spend_down;
    return this.hasSpendDown(spendDown);
  }

  hasServicesSpendDown(): boolean {
    if (!this.getServices()) {
      return false;
    }
    return this.getServices().some((service: JSONObject) => {
      return (
        this.hasFinancials(service) &&
        this.hasSpendDown(service.financials.spend_down)
      );
    });
  }

  getPlanSpendDown() {
    if (!this.hasPlanSpendDown()) {
      return null;
    }
    return this.json.plan.financials.spend_down;
  }

  hasDisclaimer(disclaimers?: string[]): boolean {
    return !!(disclaimers && disclaimers.length > 0);
  }

  hasPlanDisclaimer() {
    if (!this.hasPlanFinancials()) {
      return false;
    }
    const disclaimers = this.json.plan.financials.disclaimer;
    return this.hasDisclaimer(disclaimers);
  }

  getPlanDisclaimer(): null | string[] {
    if (!this.hasPlanDisclaimer()) {
      return null;
    }
    return this.json.plan.financials.disclaimer;
  }

  hasAdditionalInsurancePolicies(): boolean {
    if (!this.hasPlan()) {
      return false;
    }
    return !!(
      this.json.plan.additional_insurance_policies &&
      this.json.plan.additional_insurance_policies.length > 0
    );
  }

  hasNonCovered(items: JSONObject): boolean {
    return !!(items && items.length > 0);
  }

  hasPlanNonCovered() {
    if (this.json.plan.exclusions == null) {
      return false;
    }
    const noncovered = this.json.plan.exclusions.noncovered;
    return this.hasNonCovered(noncovered);
  }

  hasServicesNonCovered(): boolean {
    if (!this.hasServices()) {
      return false;
    }
    return this.json.services.some((service: JSONObject) => {
      return this.hasNonCovered(service.noncovered);
    });
  }

  hasLimitations(items: JSONObject) {
    return items && items.amounts && items.amounts.length > 0;
  }

  hasPlanLimitations() {
    if (!this.hasPlanFinancials()) {
      return false;
    }
    return this.hasLimitations(this.json.plan.financials.limitations);
  }

  hasServicesLimitations(): boolean {
    if (!this.hasServices()) {
      return false;
    }
    return this.json.services.some((service: JSONObject) => {
      return !!(
        service.financials &&
        this.hasLimitations(service.financials.limitations)
      );
    });
  }

  getAdditionalInsurancePolicies() {
    if (!this.hasAdditionalInsurancePolicies()) {
      return null;
    }
    return this.json.plan.additional_insurance_policies;
  }

  hasServices() {
    if (this.json.services === undefined) {
      return false;
    }
    return this.json.services && this.json.services.length > 0;
  }

  getServices() {
    if (!this.hasServices()) {
      return null;
    }
    return this.json.services;
  }

  hasMedicaidManagedCare(): boolean {
    return !!(
      this.json["00030"] &&
      this.json["00030"].managed_care &&
      this.json["00030"].managed_care.length > 0
    );
  }

  hasMedicaidNursingHome(): boolean {
    return !!(
      this.json["00030"] &&
      this.json["00030"].nursing_home &&
      this.json["00030"].nursing_home.length > 0
    );
  }

  getMedicaidData() {
    return this.json["00030"];
  }

  hasAddress(): boolean {
    return !!(this.json.address && this.json.address["street_line_1"]);
  }

  getAddress() {
    if (!this.hasAddress()) {
      return null;
    }
    return this.json.address;
  }

  hasSubscriberAddress(): boolean {
    if (!this.hasSubscriber()) {
      return false;
    }
    return !!(
      this.json.demographics.subscriber.address &&
      this.json.demographics.subscriber.address.street_line_1
    );
  }

  getSubscriberAddress() {
    if (!this.hasSubscriberAddress()) {
      return null;
    }
    return this.json.demographics.subscriber.address;
  }

  hasDependentAddress(): boolean {
    if (!this.hasDependent()) {
      return false;
    }
    return !!(
      this.json.demographics.dependent.address &&
      this.json.demographics.dependent.address.street_line_1
    );
  }

  getDependentAddress() {
    if (!this.hasDependentAddress()) {
      return null;
    }
    return this.json.demographics.dependent.address;
  }

  hasEligibilityDates(): boolean {
    return !!this.json.eligibility_dates?.start;
  }

  getEligibilityDates() {
    if (!this.hasEligibilityDates()) {
      return null;
    }
    return this.json.eligibility_dates;
  }

  hasInactivityDates(): boolean {
    return !!this.json.inactivity_dates?.start;
  }

  getInactivityDates() {
    if (!this.hasInactivityDates()) {
      return null;
    }
    return this.json.inactivity_dates;
  }

  hasPlanTypes(): boolean {
    return !!(this.json.plan_types && Object.keys(this.json.plan_types));
  }

  getPlanTypes() {
    if (!this.hasPlanTypes()) {
      return null;
    }
    return this.json.plan_types;
  }

  hasPlanDetails(): boolean {
    return !!(this.json.plan_details && Object.keys(this.json.plan_details));
  }

  isMedicare(): boolean {
    return this.hasPlanDetails();
  }

  getPlanDetails() {
    if (!this.hasPlanDetails()) {
      return null;
    }
    return this.json.plan_details;
  }

  hasMedicarePrimaryPayer(): boolean {
    return !!(
      this.json.plan_details &&
      this.json.plan_details["PR"] &&
      this.json.plan_details["PR"][0] &&
      this.json.plan_details["PR"][0]["active"] === true
    );
  }

  getMedicarePrimaryPayer() {
    if (!this.hasMedicarePrimaryPayer()) {
      return null;
    }
    return this.json.plan_details["PR"][0];
  }

  hasMedicarePartA(): boolean {
    return !!(this.json.plan_details && this.json.plan_details["MA"]);
  }

  getMedicarePartA() {
    if (!this.hasMedicarePartA()) {
      return null;
    }
    return this.json.plan_details["MA"];
  }

  hasMedicarePartB(): boolean {
    return !!(this.json.plan_details && this.json.plan_details["MB"]);
  }

  getMedicarePartB() {
    if (!this.hasMedicarePartB()) {
      return null;
    }
    return this.json.plan_details["MB"];
  }

  hasMedicarePartC(): boolean {
    return !!(this.json.plan_details && this.json.plan_details["MC"]);
  }

  getMedicarePartC() {
    if (!this.hasMedicarePartC()) {
      return null;
    }
    return this.json.plan_details["MC"];
  }

  hasMedicarePartD(): boolean {
    return !!(this.json.plan_details && this.json.plan_details["MD"]);
  }

  getMedicarePartD() {
    if (!this.hasMedicarePartD()) {
      return null;
    }
    return this.json.plan_details["MD"];
  }

  hasMedicareRequestedServiceTypes(): boolean {
    return !!(
      this.json.requested_service_types &&
      this.json.requested_service_types.length > 0
    );
  }

  getMedicareRequestedServiceTypes() {
    return this.json.requested_service_types;
  }

  hasMedicareRequestedProcedureCodes(): boolean {
    return !!(
      this.json.requested_procedure_codes &&
      this.json.requested_procedure_codes.length > 0
    );
  }

  getMedicareRequestedProcedureCodes() {
    return this.json.requested_procedure_codes;
  }
}

export { Coverage };
