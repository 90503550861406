import React, { FC, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  QUESTIONNAIRE_FRAGMENT,
  QuestionnaireModel,
  Response,
} from "components/Questionnaire";
import { Button, FilterField, Spinner } from "@preferral/ui";
import { distanceInWords, hMmA, mmDdYyyy } from "lib/dateFormatters";
import "./PatientFormSubmissionView.css"
import PrinterIcon from "@heroicons/react/solid/PrinterIcon";
import toast from "react-hot-toast";

const PATIENT_FORM_SUBMISSION = gql`
query GetPatientFormSubmission($id: UUID4!) {
  patientFormSubmission(id: $id) {
    id
    title
    status
    submittedAt
    patientForm {
      id
      title
      logoUrl
    }
    form {
      ...QuestionnaireFields
    }
  }
}
${QUESTIONNAIRE_FRAGMENT}
`;

interface Data {
  patientFormSubmission: {
    id: string;
    title: string;
    status: string;
    submittedAt?: string;
    patientForm: {
      id: string;
      title: string;
      logoUrl?: string;
    };
    form: QuestionnaireModel;
  };
}

interface Variables {
  id: string;
}

const GENERATE_PDF = gql`
  mutation GeneratePatientFormSubmissionPdf($id: UUID4!) {
    generatePatientFormSubmissionPdf(id: $id) {
      errors {
        key
        message
      }
      patientFormSubmission {
        id
        pdfUrl
      }
    }
  }
`;

interface MutationData {
  generatePatientFormSubmissionPdf: {
    errors?: InputError[];
    patientFormSubmission?: {
      id: string;
      pdfUrl: string;
    }
  }
}

interface MutationVariables {
  id: string;
}

interface PatientFormSubmissionViewProps { };

interface RouteParams {
  patientFormSubmissionId: string;
}

export const PatientFormSubmissionView: FC<PatientFormSubmissionViewProps> = props => {
  const match = useRouteMatch<RouteParams>();
  const { patientFormSubmissionId } = match.params;
  const { data, loading, error } = useQuery<Data, Variables>(PATIENT_FORM_SUBMISSION, {
    variables: { id: patientFormSubmissionId },
  });

  const [generatePdfMut, { loading: mutLoading }] = useMutation<MutationData, MutationVariables>(GENERATE_PDF);

  const generatePdf = useCallback(
    async () => {
      const variables = { id: patientFormSubmissionId };

      try {
        const { data } = await generatePdfMut({ variables });

        if (data?.generatePatientFormSubmissionPdf.errors) {
          toast.error(data.generatePatientFormSubmissionPdf.errors[0].message);
        } else if (data?.generatePatientFormSubmissionPdf.patientFormSubmission) {
          // it worked...
          window.open(data.generatePatientFormSubmissionPdf.patientFormSubmission.pdfUrl);
        }
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
      }
    }, [patientFormSubmissionId, generatePdfMut]);

  return (
    <div className="_PatientFormSubmissionView">
      {
        data?.patientFormSubmission.status === "UNOPENED" ? (
          <div className="mb-4 p-3 rounded text-yellow-700 bg-yellow-100 border-yellow-300 border font-semibold">
            This form has not yet been started by the patient.
          </div>
        ) : data?.patientFormSubmission.status === "IN_PROGRESS" ? (
          <div className="mb-4 p-3 rounded text-purple-700 bg-purple-100 border-purple-300 border font-semibold">
            This form has not yet been submitted by the patient.
          </div>
        ) : data?.patientFormSubmission.status === "COMPLETED" ? (
          <div className="mb-4 p-3 rounded text-green-700 bg-green-100 border-green-300 border font-semibold">
            This form was submitted on {mmDdYyyy(data.patientFormSubmission.submittedAt!)} at {hMmA(data.patientFormSubmission.submittedAt!)} ({distanceInWords(data.patientFormSubmission.submittedAt!)} ago)
          </div>
        ) : null
      }

      {/* Toolbar */}
      <div className="bg-gray-50 border mb-4 rounded-lg shadow-inner">
        <div className="flex flex-col h-16 justify-center">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="py-3 flex justify-between">
              {/* Left buttons */}
              <div>
                <span className="relative z-0 inline-flex shadow-sm rounded-md sm:shadow-none sm:space-x-3">
                  <span className="inline-flex sm:shadow-sm">
                    <Button
                      type="button"
                      onClick={generatePdf}
                      disabled={mutLoading}
                    >
                      {
                        mutLoading ? (
                          <>
                            <div className="mr-2.5 h-5 w-5">
                              <Spinner />
                            </div>
                            <span>Generating PDF...</span>
                          </>
                        ) : (
                          <>
                            <PrinterIcon
                              className="mr-2.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span>Print/Download</span>
                          </>
                        )
                      }

                    </Button>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Form Submission Meta */}
        <div className="flex items-start lg:px-8 p-4 sm:px-6 space-x-8">
          <FilterField icon="file-alt" label="Form Title">
            <p className="text-lg">{data?.patientFormSubmission.title}</p>
          </FilterField>

          {
            data?.patientFormSubmission.submittedAt ? (
              <FilterField icon="calendar-alt" label="Submitted At">
                <p className="text-lg">{mmDdYyyy(data.patientFormSubmission.submittedAt)}</p>
              </FilterField>
            ) : null
          }
        </div>
      </div>

      <div id="print-target" className="bg-white px-4 pt-6 pb-4 border rounded-lg shadow-lg">
        {data?.patientFormSubmission.patientForm.logoUrl ? (
          <div className="pt-4 pb-8">
            <img
              alt="logo"
              className="h-16 mx-auto"
              src={data.patientFormSubmission.patientForm.logoUrl}
            />
          </div>
        ) : null}

        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.patientFormSubmission ? (
          <p>Failed to load</p>
        ) : (
          <Response questionnaire={data.patientFormSubmission.form} />
        )}
      </div>
    </div>
  );
};
