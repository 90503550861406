import React, { FC, useCallback, useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { Button } from "@preferral/ui";
import {
  ProviderSearchSelect,
  ProviderReferenceModel,
} from "components/ProviderSearchSelect";
import { analytics } from "lib/analytics";

const ADD_PROVIDER_EMPLOYMENT = gql`
  mutation AddProviderEmployment($provider: ProviderReferenceInput!) {
    addProviderEmployment(provider: $provider) {
      errors {
        key
        message
      }
      provider {
        id
      }
    }
  }
`;

interface MutationData {
  addProviderEmployment: {
    errors?: InputError[];
    provider?: {
      id: string;
    };
  };
}

interface MutationVariables {
  provider: ProviderReferenceModel;
}

interface AddProviderFormProps {
  onSuccess(): void;
}

export const AddProviderForm: FC<AddProviderFormProps> = (props) => {
  const { onSuccess } = props;

  const [provider, setProvider] = useState<ProviderReferenceModel | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [addProvider, { loading }] = useMutation<
    MutationData,
    MutationVariables
  >(ADD_PROVIDER_EMPLOYMENT);
  // Clear error message if provider is cleared
  useEffect(() => {
    if (!provider) {
      setError(null);
    }
  }, [provider, setError]);

  const onSubmit = useCallback(() => {
    if (!provider) {
      return setError("You must select a provider");
    } else {
      setError(null);
      return addProvider({
        variables: { provider },
      }).then((resp) => {
        if (resp.data?.addProviderEmployment.errors) {
          const { errors } = resp.data.addProviderEmployment;
          if (errors[0].key === "provider_organization_uniqueness") {
            setError("Provider already added to organization");
          } else {
            setError(errors[0].message);
          }
        } else if (resp?.data?.addProviderEmployment?.provider) {
          // it worked
          analytics.track("Add Provider");
          toast.success("Success");
          return onSuccess();
        }
      });
    }
  }, [provider, addProvider, onSuccess]);

  return (
    <div className="AddProviderForm">
      <div className="mt-3">
        <ProviderSearchSelect
          value={provider}
          onChange={setProvider}
          filter={{
            kinds: ["nppes", "platform"],
          }}
          icon="search"
          allowCreation={false}
          inputProps={{
            placeholder: "Search for provider...",
          }}
        />
      </div>
      {error ? (
        <div className="mt-3 p-2 flex justify-center">
          <p className="rounded-md text-red-800 bg-red-100 px-2 py-1 text-sm text-center inline-block">
            {error}
          </p>
        </div>
      ) : null}
      <div className="flex items-center justify-end mt-3 py-4">
        <Button
          type="button"
          color="blue"
          disabled={loading || !provider}
          onClick={onSubmit}
        >
          Add Provider
        </Button>
      </div>
    </div>
  );
};
