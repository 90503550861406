import { library } from "@fortawesome/fontawesome-svg-core";
// import { faComments } from '@fortawesome/free-solid-svg-icons/faComments'
import { faAddressCard } from "@fortawesome/free-regular-svg-icons/faAddressCard";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faArchive } from "@fortawesome/free-solid-svg-icons/faArchive";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faArrowDownWideShort } from "@fortawesome/free-solid-svg-icons/faArrowDownWideShort";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faArrowsSplitUpAndLeft } from "@fortawesome/free-solid-svg-icons/faArrowsSplitUpAndLeft";
import { faAt } from "@fortawesome/free-solid-svg-icons/faAt";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faBolt } from "@fortawesome/free-solid-svg-icons/faBolt";
import { faBracketsCurly } from "@fortawesome/pro-solid-svg-icons/faBracketsCurly";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faCalendarAlt as farCalendarAlt } from "@fortawesome/free-regular-svg-icons/faCalendarAlt";
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons/faCalendarCheck";
import { faCalendarTimes } from "@fortawesome/free-regular-svg-icons/faCalendarTimes";
import { faChartBar } from "@fortawesome/free-solid-svg-icons/faChartBar";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import { faChartNetwork } from "@fortawesome/pro-solid-svg-icons/faChartNetwork";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons/faCheckDouble";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faCircleNodes } from "@fortawesome/free-solid-svg-icons/faCircleNodes";
import { faClipboard } from "@fortawesome/free-solid-svg-icons/faClipboard";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons/faClipboardCheck";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { faClock } from "@fortawesome/free-regular-svg-icons/faClock";
import { faCode } from "@fortawesome/free-solid-svg-icons/faCode";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons/faCommentDots";
import { faComments } from "@fortawesome/free-solid-svg-icons/faComments";
import { faCopy } from "@fortawesome/free-solid-svg-icons/faCopy";
import { faCreditCard as farCreditCard } from "@fortawesome/free-regular-svg-icons/faCreditCard";
import { faCrop } from "@fortawesome/free-solid-svg-icons/faCrop";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons/faEnvelopeOpenText";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { faFax } from "@fortawesome/free-solid-svg-icons/faFax";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons/faFileAlt";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons/faFileDownload";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons/faFileSignature";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons/faFileUpload";
import { faFilter } from "@fortawesome/free-solid-svg-icons/faFilter";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faHashtag } from "@fortawesome/free-solid-svg-icons/faHashtag";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faHospitalAlt } from "@fortawesome/free-solid-svg-icons/faHospitalAlt";
import { faIdCard } from "@fortawesome/free-solid-svg-icons/faIdCard";
import { faInbox } from "@fortawesome/free-solid-svg-icons/faInbox";
import { faInboxOut } from "@fortawesome/pro-solid-svg-icons/faInboxOut";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faLevelUpAlt } from "@fortawesome/free-solid-svg-icons/faLevelUpAlt";
import { faLifeRing } from "@fortawesome/free-solid-svg-icons/faLifeRing";
import { faListAlt } from "@fortawesome/free-solid-svg-icons/faListAlt";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faMask } from "@fortawesome/free-solid-svg-icons/faMask";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons/faMobileAlt";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faReply } from "@fortawesome/free-solid-svg-icons/faReply";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons/faRotateLeft";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faShare } from "@fortawesome/free-solid-svg-icons/faShare";
import { faSignature } from "@fortawesome/free-solid-svg-icons/faSignature";
import { faSitemap } from "@fortawesome/free-solid-svg-icons/faSitemap";
import { faStethoscope } from "@fortawesome/free-solid-svg-icons/faStethoscope";
import { faTags } from "@fortawesome/free-solid-svg-icons/faTags";
import { faThumbsDown } from "@fortawesome/free-regular-svg-icons/faThumbsDown";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons/faThumbsUp";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faTrademark } from "@fortawesome/free-solid-svg-icons/faTrademark";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faUserDoctor } from "@fortawesome/free-solid-svg-icons/faUserDoctor";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons/faUserEdit";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons/faUserGroup";
import { faUserTag } from "@fortawesome/free-solid-svg-icons/faUserTag";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";

/**
 * Warning: side-effects!
 * See: https://github.com/FortAwesome/react-fontawesome/tree/9ee4f58cfc07296f250b870249a66ed493f8589f#build-a-library-to-reference-icons-throughout-your-app-more-conveniently
 * Building this "library" allows us to only import the SVG icons we care to use,
 * imported individually above, and then use them by string reference in components.
 * This function needs to be run in an initializing module of the app/site.
 */
export default function createIconLibrary() {
  library.add(
    faAddressCard,
    faAngleDown,
    faArchive,
    faArrowLeft,
    faArrowRight,
    faArrowDownWideShort,
    faArrowsSplitUpAndLeft,
    faAt,
    faBars,
    faBell,
    faBolt,
    faBracketsCurly,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarTimes,
    faChartBar,
    faChartLine,
    faChartNetwork,
    faCheck,
    faCheckCircle,
    faCheckDouble,
    faChevronDown,
    faChevronLeft,
    faCircleNodes,
    faClipboard,
    faClipboardCheck,
    faClipboardList,
    faClock,
    faCode,
    faCog,
    faCommentDots,
    faComments,
    faCopy,
    faCrop,
    faEnvelope,
    faEnvelopeOpenText,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLinkAlt,
    faFax,
    faFileAlt,
    faFileDownload,
    faFileSignature,
    faFileUpload,
    faFilter,
    faGlobe,
    faHashtag,
    faHome,
    faHospitalAlt,
    faIdCard,
    faInbox,
    faInboxOut,
    faInfoCircle,
    faLevelUpAlt,
    faLifeRing,
    faListAlt,
    faLock,
    faMapMarkerAlt,
    faMask,
    faMobileAlt,
    faPaperPlane,
    faPencilAlt,
    faPhone,
    faPlus,
    faPlusCircle,
    faReply,
    faRotateLeft,
    farCalendarAlt,
    farCreditCard,
    faSearch,
    faShare,
    faSignature,
    faSitemap,
    faStethoscope,
    faTags,
    faThumbsDown,
    faThumbsUp,
    faTimes,
    faTimesCircle,
    faTrademark,
    faTrash,
    faUserDoctor,
    faUserEdit,
    faUserGroup,
    faUserTag,
    faUserPlus,
    faUsers
  );
}
