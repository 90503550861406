import React, { FC, useState, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Button,
  FAIcon,
  HorizontalField,
  SelectField,
  InputSingleCheckbox,
} from "@preferral/ui";
import toast from "react-hot-toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import {
  ProviderSearchSelect,
  ProviderReferenceModel,
} from "components/ProviderSearchSelect";

// const ORGANIZATION_PROVIDERS = gql`
//   query OrganizationProviders {
//     organizationProviders {

//     }
//   }
// `;

const ADD_PROVIDER_TO_DEPARTMENT = gql`
  mutation AssignDepartmentProvider(
    $departmentId: UUID4!
    $provider: ProviderReferenceInput!
    $input: DepartmentProviderInput
  ) {
    assignProviderToDepartment(
      departmentId: $departmentId
      provider: $provider
      input: $input
    ) {
      errors {
        key
        message
      }
      departmentProvider {
        id
        acceptingNewPatients
        provider {
          id
        }
        department {
          id
        }
      }
    }
  }
`;

interface MutationData {
  assignProviderToDepartment: {
    errors?: InputError[];
    departmentProvider?: {
      id: string;
      acceptingNewPatients: boolean;
      provider: {
        id: string;
      };
      department: {
        id: string;
      };
    };
  };
}

interface MutationVariables {
  departmentId: string;
  provider: ProviderReferenceModel;
  input: {
    acceptingNewPatients: boolean;
  };
}

interface AddProviderToDepartmentFormProps {
  departmentId: string;
  onSuccess?(): void;
}

export const AddProviderToDepartmentForm: FC<AddProviderToDepartmentFormProps> = (
  props
) => {
  const { departmentId, onSuccess } = props;
  const [provider, setProvider] = useState<ProviderReferenceModel | null>(null);
  const [acceptPatients, setAcceptPatients] = useState(true);
  const [statusErrors, setStatusErrors] = useState<InputError[] | null>(null);

  const [addProvider, { loading: isSubmitting }] = useMutation<
    MutationData,
    MutationVariables
  >(ADD_PROVIDER_TO_DEPARTMENT);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      setStatusErrors(null);
      const input = {
        acceptingNewPatients: acceptPatients,
      };
      if (!provider) {
        return setStatusErrors([
          { key: "", message: "You must select a provider." },
        ]);
      } else {
        return addProvider({
          variables: { departmentId, provider, input },
        }).then((resp) => {
          if (resp.data?.assignProviderToDepartment.errors) {
            setStatusErrors(resp.data.assignProviderToDepartment.errors);
          } else if (resp.data?.assignProviderToDepartment.departmentProvider) {
            // it worked.
            toast.success("Provider added");
            if (onSuccess) {
              return onSuccess();
            }
            return;
          }
        });
      }
    },
    [onSuccess, addProvider, provider, acceptPatients, departmentId]
  );

  return (
    <form className="AddProviderToDepartmentForm" onSubmit={onSubmit}>
      <FormStatusErrors status={{ errors: statusErrors || undefined }} />

      <HorizontalField label="Provider">
        <ProviderSearchSelect
          value={provider}
          onChange={setProvider}
          icon="search"
          inputProps={{
            placeholder: "Search for Provider...",
          }}
          allowCreation
        />
      </HorizontalField>

      <HorizontalField label="">
        <InputSingleCheckbox
          value={acceptPatients}
          onChange={setAcceptPatients}
          checkboxLabel="Accepting new patients"
        />
      </HorizontalField>

      <div className="flex items-center justify-center mt-3 py-4">
        <Button
          type="submit"
          color="blue"
          kind="primary"
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          Add Provider
        </Button>
      </div>
    </form>
  );
};
