import { FC, useCallback } from "react";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { gql, useQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Button, SelectField, Spinner } from "@preferral/ui";
import { useAppointmentRequest } from "../../AppointmentRequestContext";
import { analytics } from "../../../../lib/analytics";

const DEPARTMENT_USERS = gql`
  query DepartmentUsers($departmentId: UUID4!) {
    department(id: $departmentId) {
      id
      users {
        id
        name
        email
      }
    }
  }
`;

interface Data {
  department: {
    id: string;
    users: UserModel[];
  };
}

interface Variables {
  departmentId: string;
}

interface UserModel {
  id: string;
  name: string;
  email: string;
}

const ASSIGN_TEAMMATE = gql`
  mutation AssignTeammate($appointmentRequestId: UUID4!, $userId: UUID4!) {
    assignAppointmentRequestReceivingUser(
      appointmentRequestId: $appointmentRequestId
      userId: $userId
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
      }
    }
  }
`;

interface MutationData {
  assignAppointmentRequestReceivingUser: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appointmentRequestId: string;
  userId: string;
}

interface FormValues {
  userId: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    userId: Yup.string().required("Required"),
  })
  .required();

interface AssignTeammateFormProps {
  onCancel(): void;
  onSuccess(): void;
}

export const AssignTeammateForm: FC<AssignTeammateFormProps> = props => {
  const { onCancel, onSuccess } = props;

  const {
    appointmentRequest: {
      id: appointmentRequestId,
      departmentId,
      insertedAt,
      assignedReceivingUser,
    },
    refetch,
  } = useAppointmentRequest();

  const { data, loading } = useQuery<Data, Variables>(DEPARTMENT_USERS, {
    variables: { departmentId },
  });

  const userOptions =
    data?.department.users.map(u => ({ value: u.id, label: u.name })) || [];

  const [assignTeammate] = useMutation<MutationData, MutationVariables>(
    ASSIGN_TEAMMATE
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      return assignTeammate({
        variables: { appointmentRequestId, userId: values.userId },
      }).then(resp => {
        if (resp.data?.assignAppointmentRequestReceivingUser.errors) {
          setStatus({
            errors: resp.data.assignAppointmentRequestReceivingUser.errors,
          });
        } else if (
          resp.data?.assignAppointmentRequestReceivingUser.appointmentRequest
        ) {
          // it worked...
          analytics.track("Assigned to Teammate", {
            referral_id: appointmentRequestId,
            referral_requested_date: insertedAt,
          });
          toast.success("Teammate assigned!");
          refetch();
          return onSuccess();
        }
        setSubmitting(false);
      });
    },
    [assignTeammate, appointmentRequestId, onSuccess, refetch]
  );

  return loading ? (
    <div className="p-12 text-center">
      <Spinner />
    </div>
  ) : (
    <Formik<FormValues>
      initialValues={{
        userId: assignedReceivingUser?.id || "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, status }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <div className="mt-3">
            <SelectField
              name="userId"
              label="Teammate"
              options={userOptions}
              isLoading={loading}
            />
          </div>
          <div className="flex items-center justify-end mt-3 gap-3">
            <Button
              type="button"
              kind="primary"
              color="gray"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              kind="primary"
              color="blue"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
