import React, { FC } from "react";
import { CircleIcon } from "@preferral/ui";
import { AppointmentRequestEventModel } from "../../../AppointmentRequestShowScreen/model";
import "./EventIndicatorIcon.css";

const indicatorEvent = ["fax_failed", "missing_fax_number"];

const colorIndicatorEvent = {
  fax_failed: "red",
  missing_fax_number: "yellow",
};

interface EventIndicatorProps {
  appointmentRequestEvents: AppointmentRequestEventModel[];
}

export const EventIndicator: FC<EventIndicatorProps> = props => {
  const { appointmentRequestEvents } = props;
  const event = appointmentRequestEvents.find(a =>
    indicatorEvent.includes(a.type)
  );

  return event && event.appointmentRequestEventNotification && !event.appointmentRequestEventNotification.isAcknowledged ? (
    <div className="absolute">
      <CircleIcon
        className="relative event-indicator h-8 shadow"
        icon="exclamation-triangle"
        color={colorIndicatorEvent[event.type]}
      />
    </div>
  ) : null;
};
