import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import uniqBy from "lodash.uniqby";
import { sortBy } from "@preferral/common";
import { Button, Spinner } from "@preferral/ui";
import { Modal, ModalHeader } from "components/Modal";
import { FormValues, RerouteEConsultForm, SpecialtyOption } from "./RerouteEConsultForm";
import ArrowDownIcon from "@heroicons/react/outline/ArrowDownIcon"

const AVAILABLE_SPECIALTIES = gql`
  query AvailablePanelSpecialties($code: String!) {
    recommendedSpecialty: amdSpecialty(code: $code) {
      id
      code
      displayName
    }
    me {
      id
      organization {
        id
        aristamdOrganizationId
        aristamdOrganization {
          id
          primaryPanel {
            id
            displayName
            availableSpecialties {
              id
              displayName
              code
            }
          }
          backupPanel {
            id
            displayName
            availableSpecialties {
              id
              displayName
              code
            }
          }
        }
      }
    }
  }
`;

interface Data {
  recommendedSpecialty: SpecialtyModel;
  me: {
    id: string;
    organization: {
      id: string;
      aristamdOrganization: {
        id: number;
        primaryPanel: PanelModel;
        backupPanel?: null | PanelModel;
      };
    };
  };
}

interface PanelModel {
  id: number;
  displayName: string;
  availableSpecialties: SpecialtyModel[];
}

interface SpecialtyModel {
  id: number;
  displayName: string;
  code: string;
}

interface NoSpecialistAvailableProps {
  currentSpecialtyName: string;
  onClose(): void;
}

const NoSpecialistAvailable: FC<NoSpecialistAvailableProps> = (props) => {
  const { onClose } = props;

  return (
    <div className="p-4 pt-2">
      <p>
        There are currently no other specialists available to accept this request.
      </p>
      <div className="py-4 text-center">
        <Button type="button" onClick={onClose}>
          Dismiss
        </Button>
      </div>
    </div>
  );
};

interface RerouteEConsultModalProps {
  isOpen: boolean;
  onClose(): void;
  eConsultId: number;
  recommendedSpecialtyCode: string;
  currentSpecialtyName: string;
  onSuccess(): void;
}

export const RerouteEConsultModal: FC<RerouteEConsultModalProps> = props => {
  const {
    isOpen,
    onClose,
    eConsultId,
    recommendedSpecialtyCode,
    currentSpecialtyName,
    onSuccess
  } = props;

  const { loading, data, error } = useQuery<Data>(AVAILABLE_SPECIALTIES, {
    variables: { code: recommendedSpecialtyCode },
  });

  const specialtyOpts = specialtyOptions(data);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="max-w-2xl">
      <ModalHeader
        icon="arrows-split-up-and-left"
        title="Reroute to Another Specialty"
        onClose={onClose}
      />
      {
        loading ? (
          <div className="p-6 text-center">
            <Spinner />
          </div>
        ) : error || !data?.me ? (
          <p>Failed to load.</p>
        ) : (
          <div className="gap-1 grid lg:grid-cols-5">
            <div className="_left p-4 col-span-2 flex items-center justify-center">
              <div>
                <div className="bg-gray-50 border flex flex-col items-start p-2 rounded-lg">
                  <div className="p-2">
                    <p className="font-semibold text-purple-800 text-xs">Current Specialty</p>
                    <p className="text-lg">{currentSpecialtyName}</p>
                  </div>
                  <div className="w-full flex justify-center py-2">
                    <ArrowDownIcon className="h-6 w-6 text-gray-500" />
                  </div>
                  <div className="p-2">
                    <p className="font-semibold text-purple-800 text-xs">Recommended Specialty</p>
                    <p className="text-lg">{data.recommendedSpecialty.displayName}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="_right col-span-3 p-4">
              {specialtyOpts.length === 0 ? (
                <NoSpecialistAvailable
                  currentSpecialtyName={currentSpecialtyName}
                  onClose={onClose}
                />
              ) : (
                <RerouteEConsultForm
                  eConsultId={eConsultId}
                  initialValues={initialValues(data)}
                  specialtyOptions={specialtyOptions(data)}
                  onClose={onClose}
                  onSuccess={onSuccess}
                />
              )}
            </div>
          </div>
        )
      }
    </Modal>
  );
};

function specialtyOptions(data: Data | void): SpecialtyOption[] {
  if (!data) return [];

  const amdOrg = data.me.organization.aristamdOrganization;
  const primarySpecialties = amdOrg.primaryPanel.availableSpecialties;
  const backupSpecialties = amdOrg.backupPanel?.availableSpecialties || [];

  const allSpecialties = sortBy(
    uniqBy(primarySpecialties.concat(backupSpecialties), "id"),
    "displayName"
  );

  return allSpecialties.map(s => ({
    value: s.id,
    label: s.displayName,
    code: s.code
  }));
}

function initialValues(data: Data): FormValues {
  return {
    specialtyId: data.recommendedSpecialty.id
  };
}
