import React, { FC } from "react";
import { Avatar } from "@preferral/ui";
import { ProviderModel } from "./model";
import { components, OptionProps } from "react-select";

export const ProviderOptionGqlFields = `
id
npi
nameWithAppellation
primarySpecialty {
  id
  name
}
avatar(size: "thumbnail") {
  base64
  src
  srcSet
}
`;

/**
 * ProviderOption.
 */

export interface ProviderOptionProps {
  provider: ProviderModel;
}

export const ProviderOption: FC<ProviderOptionProps> = (props) => {
  const { provider } = props;

  return (
    <div className="_ProviderOption flex items-center">
      <Avatar image={provider.avatar} size="thumb" />
      <div className="ml-3">
        <p className="leading-tight text-cool-gray-900 font-semibold">
          {provider.nameWithAppellation}
        </p>
        <p className="mt-1 leading-none text-cool-gray-700 text-xs">
          {provider.primarySpecialty?.name}
        </p>
      </div>
    </div>
  );
};

/**
 * ProviderSelectOption.
 */
type ProviderSelectOptionProps = OptionProps<ProviderModel, false>;

export const ProviderSelectOption: FC<ProviderSelectOptionProps> = (props) => {
  return (
    <components.Option {...props}>
      <ProviderOption provider={props.data} />
    </components.Option>
  );
};
