import { FC } from "react";
import { Avatar } from "@preferral/ui";
import { GoogleMapsStaticImage } from "components/GoogleMapsStaticImage";
import { ReferringProviderModel, ReferringLocationModel } from "../../model";
import { FAIcon, CircleIcon } from "@preferral/ui";

/**
 * ProviderCard.
 */

interface ProviderCardProps {
  provider: ReferringProviderModel;
}

const ProviderCard: FC<ProviderCardProps> = (props) => {
  const { provider } = props;

  return (
    <div className="bg-white flex items-center p-3 rounded-xl">
      <Avatar
        size="sm"
        alt={provider.nameWithAppellation}
        image={provider.avatar}
      />
      <div className="ml-5">
        <p className="font-black text-cool-gray-800 text-lg">
          {provider.nameWithAppellation}
        </p>
        <p className="leading-none text-cool-gray-600 text-xs italic">
          {provider.primarySpecialty?.name}
        </p>
      </div>
    </div>
  );
};

/**
 * LocationCard.
 */

interface LocationCardProps {
  location: ReferringLocationModel;
}

const LocationCard: FC<LocationCardProps> = (props) => {
  const { location } = props;

  return (
    <div className="_LocationCard flex gap-4 p-3">
      <CircleIcon icon="map-marker-alt" />
      <div>
        <p className="text-cool-gray-700 text-lg">{location.name}</p>
        <div className="italic mt-1 text-cool-gray-500 text-xs">
          <p>{location.streetAddress}</p>
          {location.streetAddressLine2 && (
            <p>{location.streetAddressLine2}</p>
          )}
          <p>
            {location.city}, {location.stateAbbreviation},{" "}
            {location.zip}
          </p>
        </div>
        <div className="-mx-3 flex flex-wrap items-center justify-around mt-3">
          <div className="flex-shrink-0 px-3 py-1 font-bold text-cool-gray-600 text-xs">
            <span className="mr-2 text-cool-gray-400">
              <FAIcon icon="phone" />
            </span>
            {location.phone?.formatted || "-"}
          </div>
          <div className="flex-shrink-0 font-bold px-3 py-1 text-cool-gray-600 text-xs">
            <span className="mr-2 text-cool-gray-400">
              <FAIcon icon="fax" />
            </span>
            {location.fax?.formatted || "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

interface ReferredByProps {
  referringProvider: ReferringProviderModel;
  referringLocation: ReferringLocationModel;
}

export const ReferredBy: FC<ReferredByProps> = (props) => {
  const { referringProvider, referringLocation } = props;

  return (
    <div id="_ReferredByInfo">
      <h4 className="text-xl py-4 font-semibold">Referred By</h4>
      <div className="border border-cool-gray-100 grid grid-cols-2 overflow-hidden rounded-xl shadow-xl">
        <div>
          <ProviderCard provider={referringProvider} />
          <p className="text-lg text-light text-cool-gray-500 italic text-center">
            At
          </p>
          <LocationCard location={referringLocation} />
        </div>
        {
          referringLocation.geom ? (
            <GoogleMapsStaticImage
              className="object-cover h-full w-full"
              point={referringLocation.geom}
            />
          ) : null
        }
      </div>
    </div>
  );
};
