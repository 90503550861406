import { FC, useCallback, useState } from "react";
import { Button, FAIcon } from "@preferral/ui";
import { useAppointmentRequest } from "../../AppointmentRequestContext";
import { TaskModel } from "../../model";
import ClipboardCheckIcon from '@heroicons/react/outline/ClipboardCheckIcon';
import { NewTaskModal } from "./NewTaskModal";
import { Task } from "./Task";

/**
 * Tab.
 */

interface TabProps {
  active: boolean;
  onClick(): void;
}

const Tab: FC<TabProps> = (props) => {
  const { active, onClick, children } = props;

  const activeClassName = active
    ? "bg-indigo-100 text-indigo-700"
    : "text-gray-500 hover:text-gray-700";

  return (
    <button
      type="button"
      role="tab"
      aria-selected={active ? "true" : "false"}
      className={`${activeClassName} mx-1 px-2 py-1 font-medium text-xs rounded-md`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const NoTasks: FC = () => {
  return (
    <div className="flex items-center justify-center p-4">
      <ClipboardCheckIcon className="inline-block h-5 w-5 text-cool-gray-400" />
      <p className="ml-2 text-cool-gray-500">No Tasks</p>
    </div>
  );
};

type ActiveTab = "ALL" | "DUE" | "ACTIVE" | "COMPLETE";
type ActiveModal = "NEW_TASK";

interface TaskPanelProps { }

export const TaskPanel: FC<TaskPanelProps> = (props) => {
  const {
    appointmentRequest: { tasks: allTasks },
    refetch,
  } = useAppointmentRequest();

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const openNewTaskModal = useCallback(() => setActiveModal("NEW_TASK"), []);
  const closeModal = useCallback(() => setActiveModal(null), []);

  const [activeTab, setActiveTab] = useState<ActiveTab>("ALL");

  const tasks = filterTasks(allTasks, activeTab);

  const dueCount = countDue(tasks);

  return (
    <>
      <NewTaskModal isOpen={activeModal === "NEW_TASK"} onClose={closeModal} />
      <div className="TaskPanel">
        <h4 className="text-xl py-4 font-semibold">Tasks</h4>

        <div className="_TaskListContainer px-4">
          <div className="_TaskList border rounded-md">
            <div className="_TaskListHeader px-2 flex items-center justify-between">
              <p
                className={`font-semibold px-2 ${dueCount > 0 ? "text-yellow-500" : "text-cool-gray-600"
                  } text-sm`}
              >
                {dueCount} due
              </p>

              <div className="py-1">
                {[
                  ["All", "ALL"],
                  ["Due", "DUE"],
                  ["Active", "ACTIVE"],
                  ["Complete", "COMPLETE"],
                ].map(([label, tab]) => (
                  <Tab
                    key={tab}
                    onClick={() => setActiveTab(tab as ActiveTab)}
                    active={tab === activeTab}
                  >
                    {label}
                  </Tab>
                ))}
              </div>

              {/* Just for flex spacing */}
              <div />
            </div>
            <div className="_TaskListItems py-1">
              {tasks.length === 0 ? (
                <NoTasks />
              ) : (
                tasks.map((t) => (
                  <Task
                    key={t.id}
                    task={t}
                    onToggle={refetch}
                    onDelete={refetch}
                  />
                ))
              )}
            </div>
            <div className="_TaskListFooter p-2 border-t rounded-b text-center bg-cool-gray-50">
              <Button
                type="button"
                color="blue"
                size="sm"
                kind="secondary"
                onClick={openNewTaskModal}
              >
                <span className="mr-2">
                  <FAIcon icon="plus" />
                </span>
                Add Task
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function countDue(tasks: TaskModel[]): number {
  return tasks.reduce(
    (acc, elem) => (elem.isDue && !elem.done ? acc + 1 : acc),
    0
  );
}

function filterTasks(tasks: TaskModel[], activeTab: ActiveTab): TaskModel[] {
  switch (activeTab) {
    case "ALL":
      return tasks;
    case "ACTIVE":
      return tasks.filter((t) => !t.done);
    case "COMPLETE":
      return tasks.filter((t) => t.done);
    case "DUE":
      return tasks.filter((t) => t.isDue);
  }
}
