import { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useAppointmentRequest } from "../../../AppointmentRequestContext";
import { TimeMaskInput } from "components/formik/TimeMaskField";
import { Formik, FormikHelpers, Field } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { DatePickerInput } from "components/formik/DatePickerField";
import {
  HorizontalSelectField,
  HorizontalField,
  Button,
  Spinner,
} from "@preferral/ui";
import { localDateRegex, localDateToISO } from "@preferral/common";
import { analytics } from "../../../../../lib/analytics";

const CURRENT_USER_AND_TIMEZONES = gql`
  query CurrentUser {
    timeZoneNames
    me {
      id
      timeZoneName
    }
  }
`;

interface Data {
  timeZoneNames: string[]
  me: {
    id: string;
    timeZoneName: string;
  };
}

const SET_APPOINTMENT = gql`
  mutation BookAppointment(
    $appointmentRequestId: UUID4!
    $input: AppointmentInput!
  ) {
    bookAppointment(
      appointmentRequestId: $appointmentRequestId
      input: $input
    ) {
      errors {
        key
        message
      }
      appointment {
        id
      }
    }
  }
`;

interface MutationData {
  bookAppointment: {
    errors?: InputError[];
    appointment?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appointmentRequestId: string;
  input: AppointmentInput;
}

interface AppointmentInput {
  date: string;
  startTime: string;
  nppesProviderId: string | null;
  nppesLocationId: string;
  timeZoneName: string;
  duration: number;
}

interface FormValues {
  date: string;
  startTime: string;
  providerId: string;
  locationId: string;
  timeZoneName: string;
  duration: number;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    date: Yup.string()
      .matches(localDateRegex, "Invalid date")
      .required("Required"),
    startTime: Yup.string().required("Required"),
    timeZoneName: Yup.string().required("Required")
  })
  .required();

interface NewAppointmentFormProps {
  onCancel(): void;
  onSuccess(): void;
}

export const NewAppointmentForm: FC<NewAppointmentFormProps> = props => {
  const { onCancel, onSuccess } = props;

  const {
    appointmentRequest: {
      id: appointmentRequestId,
      requestedLocation,
      requestedNppesLocation,
      requestedNppesProvider,
      requestedProvider,
      insertedAt,
    },
    refetch,
  } = useAppointmentRequest();

  const location = requestedLocation || requestedNppesLocation;
  const provider = requestedProvider || requestedNppesProvider;

  const { data } = useQuery<Data>(CURRENT_USER_AND_TIMEZONES);

  const [setAppointment] = useMutation<MutationData, MutationVariables>(
    SET_APPOINTMENT
  );

  const onSubmit = useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikHelpers;

      console.log(values);

      setStatus({ errors: null });

      const input: AppointmentInput = {
        nppesProviderId: values.providerId || null,
        nppesLocationId: values.locationId,
        date: localDateToISO(values.date),
        startTime: values.startTime,
        timeZoneName: values.timeZoneName,
        duration: values.duration,
      };

      return setAppointment({
        variables: { appointmentRequestId, input },
      }).then(resp => {
        if (resp.data?.bookAppointment.errors) {
          setStatus({ errors: resp.data.bookAppointment.errors });
        } else if (resp.data?.bookAppointment.appointment) {
          // it worked...
          toast.success("Appointment created!");
          analytics.track("Appointment Added", {
            referral_id: appointmentRequestId,
            referral_requested_date: insertedAt,
          });
          refetch();
          return onSuccess();
        }
        setSubmitting(false);
      });
    },
    [setAppointment, appointmentRequestId, refetch, onSuccess]
  );

  const initialValues = {
    date: "",
    startTime: "",
    providerId: provider?.id || "",
    locationId: location?.id || "",
    timeZoneName:
      location?.timeZoneName || data?.me.timeZoneName || "US/Central",
    duration: 30,
  };

  return !location && !provider ? (
    <div className="p-12 text-center">
      <Spinner />
    </div>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="mt-3">
            {provider && (
              <HorizontalSelectField
                label="Provider"
                name="providerId"
                options={[provider] || []}
                getOptionLabel={p => p.nameWithAppellation}
                getOptionValue={p => p.id}
                autoSelect
              />
            )}
          </div>
          <div className="mt-3">
            {location && (
              <HorizontalSelectField
                label="Location"
                name="locationId"
                options={[location] || []}
                getOptionLabel={l => l.name}
                getOptionValue={l => l.id}
                autoSelect
              />
            )}
          </div>
          <div className="mt-3">
            <HorizontalField label="Date/Time">
              <div className="flex items-start">
                <div className="flex-grow mr-1">
                  <DatePickerInput name="date" icon="calendar-alt" />
                  <div className="text-right">
                    <Field as="select" className="border-b border-dashed border-indigo-300 font-semibold p-1 text-indigo-800 text-xs" name="timeZoneName">
                      {(data?.timeZoneNames || []).map((tz: string) => <option key={tz} value={tz}>{tz}</option>)}
                    </Field>
                  </div >
                </div>
                <div className="ml-1 flex-grow">
                  <TimeMaskInput
                    name="startTime"
                    placeholder="hh:mm pm"
                    icon={["far", "clock"]}
                  />
                  {/*<div>
                    <select>
                      <option>30 min</option>
                      <option>35 min</option>
                    </select>
                  </div>*/}
                </div>
              </div>
            </HorizontalField>
          </div>

          <div className="mt-3 p-3 flex items-center justify-center gap-3">
            <Button type="button" onClick={onCancel} disabled={isSubmitting}>
              Cancel
            </Button>

            <Button
              type="submit"
              kind="primary"
              color="blue"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Save Appointment
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
