import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import uniqBy from "lodash.uniqby";
import {
  FilterForm,
  FilterField,
  Button,
  StandardOption,
  SelectInput,
} from "@preferral/ui";
import { sortBy } from "@preferral/common";

type StatusSet = "ALL_OPEN" | "PENDING" | "SCHEDULED" | "REJECTED" | "EXHAUSTED" | "CLOSED";

export interface FilterModel {
  statusSet?: StatusSet;
  specialtyId?: string;
}

const statusSetOptions: StandardOption<StatusSet>[] = [
  { value: "ALL_OPEN", label: "All Open" },
  { value: "PENDING", label: "Unscheduled" },
  { value: "SCHEDULED", label: "Scheduled" },
  { value: "REJECTED", label: "Rejected" },
  { value: "EXHAUSTED", label: "Exhausted" },
  { value: "CLOSED", label: "Closed" }
];

export const defaultFilter: FilterModel = {
  statusSet: "ALL_OPEN",
  specialtyId: "ALL",
};

const allOption = {
  value: "ALL",
  label: "All",
};

const SENT_REFERRALS_FILTER_DATA = gql`
  query SentReferralsFilterData {
    me {
      id
      departments {
        id
        providers {
          id
          nameWithAppellation
        }
        locations {
          id
          name
        }
      }
    }
    specialties(filter: { curated: true }) {
      id
      name
    }
  }
`;

interface Data {
  me: {
    id: string;
    departments: {
      id: string;
      providers: {
        id: string;
        nameWithAppellation: string;
      }[];
      locations: {
        id: string;
        name: string;
      }[];
    }[];
  };
  specialties: {
    id: string;
    name: string;
  }[];
}

interface FilterPanelProps {
  value: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading?: boolean;
}

export const FilterPanel: FC<FilterPanelProps> = props => {
  const { value, onChange, isLoading } = props;

  const { data, loading } = useQuery<Data>(SENT_REFERRALS_FILTER_DATA);

  const statusSetOpts = [allOption, ...statusSetOptions];

  const specialtyOpts = [
    allOption,
    ...specialtyOptions(data),
  ] as StandardOption[];

  return (
    <FilterForm<FilterModel>
      defaultValue={defaultFilter}
      value={value}
      onChange={(values: FilterModel) => onChange(values)}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="statusSet" icon="filter" label="Status">
          <div className="w-48">
            <SelectInput
              name="statusSet"
              options={statusSetOpts}
              isLoading={loading}
              placeholder="Any"
              isClearable={false}
            />
          </div>
        </FilterField>

        <FilterField
          htmlFor="referringProviderId"
          icon="filter"
          label="Referring Provider"
        >
          <div className="w-56">
            <SelectInput
              name="referringProviderId"
              options={referringProviderOptions(data)}
              isLoading={loading}
              placeholder="Any"
              isClearable={false}
            />
          </div>
        </FilterField>

        <FilterField
          htmlFor="referringLocationId"
          icon="filter"
          label="Referring Location"
        >
          <div className="w-56">
            <SelectInput
              name="referringLocationId"
              options={referringLocationOptions(data)}
              isLoading={loading}
              placeholder="Any"
              isClearable={false}
            />
          </div>
        </FilterField>

        <FilterField htmlFor="specialtyId" icon="filter" label="Specialty">
          <div className="w-56">
            <SelectInput
              name="specialtyId"
              options={specialtyOpts}
              isLoading={loading}
              placeholder="Any"
              isClearable={false}
            />
          </div>
        </FilterField>

        <FilterField>
          <Button
            type="submit"
            color="blue"
            disabled={loading || isLoading}
            isLoading={isLoading}
          >
            Apply
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};

function referringProviderOptions(data: Data | void): StandardOption[] {
  if (!data) {
    return [];
  } else {
    const rawOptions: StandardOption[] = data.me.departments.flatMap(d =>
      d.providers.map(p => ({
        value: p.id,
        label: p.nameWithAppellation,
      }))
    );
    return sortBy(uniqBy(rawOptions, "value"), "label");
  }
}

function referringLocationOptions(data: Data | void): StandardOption[] {
  if (!data) {
    return [];
  } else {
    const rawOptions: StandardOption[] = data.me.departments.flatMap(d =>
      d.locations.map(l => ({
        value: l.id,
        label: l.name,
      }))
    );
    return sortBy(uniqBy(rawOptions, "value"), "label");
  }
}

function specialtyOptions(data: Data | void): StandardOption[] {
  if (!data) return [];

  const rawOptions = data.specialties.map(s => ({
    value: s.id,
    label: s.name,
  }));
  return sortBy(rawOptions, "label");
}

export function removeVoidKeys(
  filterWithNulls: Partial<Record<keyof FilterModel, any>>
): FilterModel {
  let filter = {};
  for (const key in filterWithNulls) {
    if (filterWithNulls[key] !== null && filterWithNulls[key] !== undefined) {
      filter[key] = filterWithNulls[key];
    }
  }
  return filter;
}

/**
 * Returns filter without keys that have value "ANY" or "ALL".
 * This is useful because the GraphQL API for AristaMD ID fields (e.g. specialtyId)
 * are integers, but we use "ANY" and "ALL" as the "unselected" value.
 */
export function removeUnusedFilterKeys(
  filter: Partial<Record<keyof FilterModel, any>>
): FilterModel {
  let newFilter = {};
  for (const key in filter) {
    if (filter[key] !== "ANY" && filter[key] !== "ALL") {
      newFilter[key] = filter[key];
    }
  }
  return newFilter;
}
