import { useCallback } from "react";
import { useComponentId, useFileUploader, useWaitFor } from "@preferral/ui";

export function useWaitForUploads() {
  const instanceId = useComponentId();
  const {
    state: { isUploading },
  } = useFileUploader({ instanceId });

  const checkUploadsComplete = useCallback(() => !isUploading, [isUploading]);
  const [waitForUploads] = useWaitFor(checkUploadsComplete);

  return [waitForUploads, instanceId] as const;
}
