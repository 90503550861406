import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { AssignProviderForm } from "./AssignProviderForm";

interface AssignProviderModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const AssignProviderModal: FC<AssignProviderModalProps> = (props) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="user-doctor" onClose={onClose} title="Assign Provider" />
      <div className="p-4">
        <AssignProviderForm onSuccess={onClose} />
      </div>
    </Modal>
  );
};
