import { FC } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  QUESTIONNAIRE_FRAGMENT,
  QuestionnaireModel,
  Response,
} from "components/Questionnaire";
import { Button, Spinner } from "@preferral/ui";
import PrinterIcon from '@heroicons/react/solid/PrinterIcon'
import { FilterField } from "@preferral/ui";
import { mmDdYyyy } from "lib/dateFormatters";
import { useCallback } from "react";
import toast from "react-hot-toast";

const PATIENT_FORM_SUBMISSION = gql`
  query GetPatientFormSubmission($id: UUID4!) {
    patientFormSubmission(id: $id) {
      id
      title
      submittedAt
      patientForm {
        id
        title
        logoUrl
      }
      form {
        ...QuestionnaireFields
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

interface Data {
  patientFormSubmission: {
    id: string;
    title: string;
    submittedAt: string;
    patientForm: {
      id: string;
      title: string;
      logoUrl?: string;
    };
    form: QuestionnaireModel;
  };
}

interface Variables {
  id: string;
}

const GENERATE_PDF = gql`
  mutation GeneratePatientFormSubmissionPdf($id: UUID4!) {
    generatePatientFormSubmissionPdf(id: $id) {
      errors {
        key
        message
      }
      patientFormSubmission {
        id
        pdfUrl
      }
    }
  }
`;

interface MutationData {
  generatePatientFormSubmissionPdf: {
    errors?: InputError[];
    patientFormSubmission?: {
      id: string;
      pdfUrl: string;
    }
  }
}

interface MutationVariables {
  id: string;
}

interface ViewPatientFormSubmissionProps {
  patientFormSubmissionId: string;
}

export const ViewPatientFormSubmission: FC<ViewPatientFormSubmissionProps> = (
  props
) => {
  const { patientFormSubmissionId } = props;
  const { data, loading, error } = useQuery<Data, Variables>(PATIENT_FORM_SUBMISSION, {
    variables: { id: patientFormSubmissionId },
  });

  const [generatePdfMut, { loading: mutLoading }] = useMutation<MutationData, MutationVariables>(GENERATE_PDF);

  const generatePdf = useCallback(() => {
    return generatePdfMut({ variables: { id: patientFormSubmissionId } }).then(resp => {
      if (resp.data?.generatePatientFormSubmissionPdf.errors) {
        toast.error(resp.data.generatePatientFormSubmissionPdf.errors[0].message);
      } else if (resp.data?.generatePatientFormSubmissionPdf.patientFormSubmission) {
        // it worked...
        window.open(resp.data.generatePatientFormSubmissionPdf.patientFormSubmission.pdfUrl);
      }
    });
  }, [patientFormSubmissionId, generatePdfMut]);

  return (
    <div className="_ViewPatientFormSubmission">
      {/* Toolbar */}
      <div className="bg-gray-50 border-b border-t shadow-inner">
        <div className="flex flex-col h-16 justify-center">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="py-3 flex justify-between">
              {/* Left buttons */}
              <div>
                <span className="relative z-0 inline-flex shadow-sm rounded-md sm:shadow-none sm:space-x-3">
                  <span className="inline-flex sm:shadow-sm">
                    <Button
                      type="button"
                      onClick={generatePdf}
                      disabled={mutLoading}
                    >
                      {
                        mutLoading ? (
                          <>
                            <div className="mr-2.5 h-5 w-5">
                              <Spinner />
                            </div>
                            <span>Generating PDF...</span>
                          </>
                        ) : (
                          <>
                            <PrinterIcon
                              className="mr-2.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span>Print/Download</span>
                          </>
                        )
                      }

                    </Button>
                    {/* <button
                      type="button"
                      className="hidden sm:inline-flex -ml-px relative items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600"
                    >
                      <TagIcon
                        className="mr-2.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Tag</span>
                    </button>
                    <button
                      type="button"
                      className="hidden sm:inline-flex -ml-px relative items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600"
                    >
                      <UserAddIcon
                        className="mr-2.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Assign</span>
                    </button> */}
                  </span>


                  {/* <Menu
                    as="span"
                    className="-ml-px relative block sm:shadow-sm lg:hidden"
                  >
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:rounded-md sm:px-3">
                            <span className="sr-only sm:hidden">More</span>
                            <span className="hidden sm:inline">More</span>
                            <ChevronDownIcon
                              className="h-5 w-5 text-gray-400 sm:ml-2 sm:-mr-1"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block sm:hidden px-4 py-2 text-sm"
                                    )}
                                  >
                                    Note
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block sm:hidden px-4 py-2 text-sm"
                                    )}
                                  >
                                    Assign
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}
                                  >
                                    Archive
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}
                                  >
                                    Move
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu> */}
                </span>
              </div>

              {/* Right buttons */}
              {/* <nav aria-label="Pagination">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <a
                    href="#"
                    className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  </a>
                  <a
                    href="#"
                    className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  </a>
                </span>
              </nav> */}
            </div>
          </div>
        </div>

        {/* Form Submission Meta */}
        <div className="flex items-start lg:px-8 p-4 sm:px-6 space-x-8">
          <FilterField icon="file-alt" label="Form Title">
            <p className="text-lg">{data?.patientFormSubmission.title}</p>
          </FilterField>

          {
            data?.patientFormSubmission.submittedAt ? (
              <FilterField icon="calendar-alt" label="Submitted At">
                <p className="text-lg">{mmDdYyyy(data.patientFormSubmission.submittedAt)}</p>
              </FilterField>
            ) : null
          }
        </div>
      </div>



      {/* Form Submission Content */}
      <div className="p-8">
        {data?.patientFormSubmission.patientForm.logoUrl ? (
          <div className="pt-4 pb-8">
            <img
              alt="logo"
              className="h-16 mx-auto"
              src={data.patientFormSubmission.patientForm.logoUrl}
            />
          </div>
        ) : null}

        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.patientFormSubmission ? (
          <p>Failed to load</p>
        ) : (
          <Response questionnaire={data.patientFormSubmission.form} />
        )}
      </div>
    </div>
  );
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
