import { FC, useCallback } from "react";
import ClockIcon from "@heroicons/react/outline/ClockIcon";
import {
  FAIcon,
  DropdownButton,
  DropdownItemButton,
  Icon,
} from "@preferral/ui";
import LocationMarkerIcon from "@heroicons/react/outline/LocationMarkerIcon";
import XIcon from "@heroicons/react/outline/XIcon";
import { AppointmentModel, AppointmentStatus } from "../../model";
import { gql, useMutation } from "@apollo/client";
import { analytics } from "../../../../lib/analytics";
import { useAppointmentRequest } from "../../AppointmentRequestContext";

const COMPLETE_APPOINTMENT = gql`
  mutation CompleteAppointment($appointmentId: UUID4!) {
    completeAppointment(appointmentId: $appointmentId) {
      errors {
        key
        message
      }
      appointment {
        id
      }
    }
  }
`;

interface CompleteMutationData {
  completeAppointment: {
    errors?: InputError[];
    appointment?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appointmentId: string;
}

const statusClassNames: Record<AppointmentStatus, string> = {
  COMPLETED: "bg-green-50 border-green-500 text-green-800",
  SCHEDULED: "bg-blue-50 border-blue-500 text-blue-800",
  CONFIRMED: "bg-green-50 border-green-500 text-green-800",
  CANCELLED: "bg-red-50 border-red-500 text-red-800",
  MISSED: "bg-red-50 border-red-500 text-red-800",
};

interface AppointmentCardProps {
  appointment: AppointmentModel;
  onChanged?(): void;
  onCancelClick(): void;
}

export const AppointmentCard: FC<AppointmentCardProps> = props => {
  const { appointment, onChanged, onCancelClick } = props;

  const {
    appointmentRequest: { id: appointmentRequestId, insertedAt },
  } = useAppointmentRequest();

  const [completeAppointment, { loading: isCompleting }] = useMutation<
    CompleteMutationData,
    MutationVariables
  >(COMPLETE_APPOINTMENT);

  const doCompleteAppointment = useCallback(() => {
    return completeAppointment({
      variables: { appointmentId: appointment.id },
    }).then(resp => {
      if (resp.data?.completeAppointment.appointment) {
        // it worked x
        analytics.track("Appointment Completed", {
          referral_id: appointmentRequestId,
          referral_requested_date: insertedAt,
        });
        if (onChanged) {
          return onChanged();
        }
      }
    });
  }, [appointment.id, completeAppointment, onChanged]);

  return (
    <div className="relative w-full border rounded-lg shadow py-2">
      <div
        className={`${statusClassNames[appointment.status]
          } absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 py-1 px-2 font-bold rounded-full shadow uppercase text-xs border-2`}
      >
        {appointment.status}
      </div>
      <div className="_AppointmentCard w-full p-2 flex items-center">
        <div className="flex-grow-0 px-3">
          {appointment.status === "COMPLETED" ? (
            <FAIcon
              icon={["far", "calendar-check"]}
              className="h-9 w-9 p-1 text-green-500"
            />
          ) : appointment.status === "CANCELLED" ||
            appointment.status === "MISSED" ? (
            <FAIcon
              icon={["far", "calendar-times"]}
              className="h-9 w-9 p-1 text-red-500"
            />
          ) : (
            <Icon icon="calendar-date" size={36} color="blue" />
          )}
        </div>
        <div className="flex-grow">
          <div className="flex items-center">
            <div className="flex-grow-0 h-5 mr-2 text-center text-cool-gray-500 w-5">
              <ClockIcon className="h-5 w-5" />
            </div>
            <p className="text-cool-gray-800 font-semibold">
              {appointment.startDateString} - {appointment.startTimeString}
            </p>
          </div>

          {appointment.provider || appointment.nppesProvider ? <div className="flex items-center">
            <p className="text-cool-gray-700 text-sm font-semibold">
              <span className="inline-block text-center mr-2 text-cool-gray-500 w-5">
                <FAIcon icon="user-doctor" />
              </span>
              {appointment.provider?.nameWithAppellation ||
                appointment.nppesProvider?.nameWithAppellation}
            </p>
          </div> : null}

          <div className="flex items-center">
            <div className="flex-grow-0 h-5 mr-2 text-center text-cool-gray-500 w-5">
              <LocationMarkerIcon className="h-5 w-5" />
            </div>
            <p className="text-cool-gray-700 text-sm font-semibold">
              {appointment.location?.name || appointment.nppesLocation?.name}
            </p>
          </div>

          {appointment.status === "CANCELLED" &&
            appointment.cancellationReason ? (
            <div className="flex items-center">
              <div className="flex-grow-0 h-5 mr-2 text-center text-cool-gray-500 w-5">
                <XIcon className="h-5 w-5 text-red-400" />
              </div>
              <p className="text-cool-gray-700 text-sm font-semibold">
                Cancellation Reason: {appointment.cancellationReason}
              </p>
            </div>
          ) : null}
        </div>
        <div className="mr-4">
          <DropdownButton>
            {/*<DropdownItemButton>Reschedule</DropdownItemButton>*/}
            {/*<DropdownItemButton>Mark as Confirmed</DropdownItemButton>*/}
            <DropdownItemButton
              onClick={doCompleteAppointment}
              isLoading={isCompleting}
            >
              Mark as Completed
            </DropdownItemButton>
            {/*<DropdownItemButton color="red">Mark as Missed</DropdownItemButton>*/}
            <DropdownItemButton color="red" onClick={onCancelClick}>
              Cancel Appointment
            </DropdownItemButton>
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};
