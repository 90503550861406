import { FC } from "react";
import CheckCircleIcon from "@heroicons/react/solid/CheckCircleIcon";
import { Badge } from "@preferral/ui";
import { EconsultModel } from "./model";

const badges: Record<Exclude<EconsultModel["statusSet"], "finalized">, [AppColor, string]> = {
  draft: ["blue", "Draft"],
  pending_specialist_review: ["indigo", "Pending Specialist Review"],
  ready_to_review: ["green", "Ready to Review"]
}

interface StatusBadgeProps {
  eConsult: Pick<EconsultModel, "statusSet">
};

export const StatusBadge: FC<StatusBadgeProps> = props => {
  const { eConsult } = props;

  if (eConsult.statusSet === "finalized") {
    return (
      <span className="flex text-sm font-semibold items-center py-1 text-green-800">
        <CheckCircleIcon className="w-4 h-4 mr-2 text-green-500" />
        Finalized
      </span>
    );
  }

  const [color, label] = badges[eConsult.statusSet];

  return (
    <Badge color={color}>
      {label}
    </Badge>
  );
};
