import React, { useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Spinner } from "components/Spinner";
import { SelectField } from "@preferral/ui";
import toast from "react-hot-toast";

const DEPARTMENT_INBOXES = gql`
  query DepartmentActiveInboxes($id: UUID4!) {
    department(id: $id) {
      id
      inboxes {
        id
        name
        active
      }
    }
  }
`;

interface Data {
  department: {
    id: string;
    inboxes: {
      id: string;
      name: string;
      active: boolean;
    }[];
  };
}

interface Variables {
  id: string;
}

const MUTATION = gql`
  mutation RetireInbox($id: UUID4!, $replacementId: UUID4!) {
    retireInbox(id: $id, replacementId: $replacementId) {
      errors {
        key
        message
      }
      inbox {
        id
      }
    }
  }
`;

interface MutationData {
  retireInbox: {
    errors?: InputError[];
    inbox?: {
      id: string;
    };
  };
}

interface MutationVariables {
  id: string;
  replacementId: string;
}

type FormValues = {
  replacementId: string;
};

type RetireInboxFormProps = {
  departmentId: string;
  inboxId: string;
  refetchQueries: any;
  closeModal: () => void;
};

const RetireInboxForm: React.FC<RetireInboxFormProps> = ({
  departmentId,
  inboxId,
  refetchQueries,
  closeModal,
}) => {
  const { data, loading, error } = useQuery<Data, Variables>(
    DEPARTMENT_INBOXES,
    { variables: { id: departmentId } }
  );

  const [retireInbox] = useMutation<MutationData, MutationVariables>(MUTATION);

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      retireInbox({
        variables: { id: inboxId, replacementId: values.replacementId },
        refetchQueries,
      }).then(
        (resp) => {
          if (resp && resp.data && resp.data.retireInbox.errors) {
            setStatus({ errors: resp.data.retireInbox.errors });
          } else if (resp && resp.data && resp.data.retireInbox.inbox) {
            // it worked
            toast.success("Success");
            closeModal();
          }
          setSubmitting(false);
        },
        (rej) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong" }],
          });
          setSubmitting(false);
        }
      );
    },
    [retireInbox, toast, refetchQueries]
  );

  const inboxOptions =
    data && data.department
      ? data.department.inboxes
          .filter((i) => i.active && i.id !== inboxId)
          .map((i) => ({
            value: i.id,
            label: i.name,
          }))
      : [];

  return (
    <div className="RetireInboxForm">
      <p>RetireInboxForm</p>
      {loading ? (
        <div className="p-12 text-center">
          <Spinner />
        </div>
      ) : error || !(data && data.department) ? (
        <>
          <p>Failed to load</p>
          {/* <pre>{JSON.stringify(error, null, 2)</pre> */}
        </>
      ) : (
        <div>
          <pre>{JSON.stringify(data, null, 2)}</pre>
          <Formik<FormValues>
            initialValues={{
              replacementId: "",
            }}
            validationSchema={Yup.object().shape({
              replacementId: Yup.string()
                .trim()
                .required("Required"),
            })}
            onSubmit={onSubmit}
          >
            {({ status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormStatusErrors status={status} />
                <div className="mt-3">
                  <SelectField
                    name="replacementId"
                    options={inboxOptions}
                    label="Replacement Inbox"
                  />
                </div>
                <div className="flex items-center justify-end mt-3 py-4">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-blue"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export { RetireInboxForm };
