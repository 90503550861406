import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { AssignFaxForm } from "./AssignFaxForm";

interface AssignFaxModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const AssignFaxModal: FC<AssignFaxModalProps> = (props) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="fax"
        title="Edit Fax"
        onClose={onClose}
      />
      <div className="p-4">
        <AssignFaxForm onSuccess={onClose} />
      </div>
    </Modal>
  );
};
