import React, { FC } from "react";
import { Drawer, DrawerHeader } from "components/Drawer";
import { UnlistedProviderForm } from "./UnlistedProviderForm";
import { ProviderReferenceModel } from "./types";
import { analytics } from "lib/analytics";

interface CreateUnlistedProviderDrawerProps {
  isOpen: boolean;
  onClose(): void;
  onSuccess(providerReference: ProviderReferenceModel): void;
}

export const CreateUnlistedProviderDrawer: FC<CreateUnlistedProviderDrawerProps> = (
  props
) => {
  const { isOpen, onClose, onSuccess } = props;

  return (
    <Drawer isOpen={isOpen} onRequestClose={onClose} width="lg">
      <DrawerHeader
        icon="user-doctor"
        title="Enter Provider Info"
        onClose={onClose}
      />
      <UnlistedProviderForm
        onSuccess={(providerRef) => {
          analytics.track("Add Provider to Department", { name: providerRef })
          onSuccess(providerRef);
          onClose();
        }}
      />
    </Drawer>
  );
};
