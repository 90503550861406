import React, { FC, useCallback } from "react";
import * as Yup from "yup";
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { Button, PhoneMaskField } from "@preferral/ui";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { analytics } from "lib/analytics";

import { useAppointmentRequest } from "../../../AppointmentRequestContext";

const ASSIGN_FAX = gql`
  mutation updateNppesLocation($nppesLocationUuid: UUID4!, $input: UpdateLocationInput!){
  updateNppesLocation(id: $nppesLocationUuid, input: $input) {
    nppesLocation {
      id
    }
    errors {
      key
      message
    }
  }
}
`;

interface MutationData {
  updateNppesLocation: {
    errors?: InputError[];
    nppesLocation?: {
      id: string;
    };
  };
}

interface MutationVariables {
  nppesLocationUuid: string;
  input: {
    fax: string;
  }
}

interface FormValues {
  fax: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    fax: Yup.string().matches(
      /^\(\d{3}\)\s\d{3}-\d{4}$/,
      "Invalid fax number"
    )
  })
  .required();

interface AssignFaxFormProps {
  onSuccess(): void;
}

export const AssignFaxForm: FC<AssignFaxFormProps> = (props) => {
  const { onSuccess } = props;
  const {
    appointmentRequest: { requestedNppesLocation, id: appointmentRequestId },
    refetch,
  } = useAppointmentRequest();

  const [assignFax] = useMutation<MutationData, MutationVariables>(
    ASSIGN_FAX
  );

  const onSubmit = useCallback(
    (values, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;
      setStatus({ errors: null });
      return assignFax({
        variables: { nppesLocationUuid: requestedNppesLocation?.id || '', input: { fax: values.fax } },
      }).then((resp) => {
        if (resp.data?.updateNppesLocation.errors) {
          setStatus({
            errors: resp.data.updateNppesLocation.errors,
          });
        } else if (
          resp.data?.updateNppesLocation.nppesLocation
        ) {
          // it worked....
          analytics.track("Fax Number Added Manually", { referralId: appointmentRequestId })
          toast.success("Fax assigned!");
          refetch();
          onSuccess();
        }
        setSubmitting(false);
      });
    },
    [requestedNppesLocation, assignFax, onSuccess, refetch]
  );

  return (
    <Formik
      initialValues={{ fax: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <PhoneMaskField
            name="fax"
            label="Fax Number"
            placeholder="(___) ___-____"
            icon="fax"
          />
          <div className="mt-3 text-center">
            <Button
              type="submit"
              kind="primary"
              color="blue"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Save Fax
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
