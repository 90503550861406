import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  FilterForm,
  FilterField,
  Button,
  StandardOption,
  SelectInput,
} from "@preferral/ui";
import { sortBy } from "@preferral/common";
import uniqBy from "lodash.uniqby";
import addDays from "date-fns/addDays";
import { TimeRange } from "components/DateRangeDropdown";
import { DateRangeDropdownInput } from "components/formik/DateRangeDropdownField";

const past = addDays(new Date(), -365);
const now = new Date();

export const defaultFilter: FilterModel = {
  specialtyId: undefined,
  requestingPhysicianId: undefined,
  siteId: undefined,
  statusSet: undefined,
  timeRange: { start: past, finish: now },
};

export interface FilterModel {
  specialtyId?: "ANY" | number;
  requestingPhysicianId?: "ANY" | number;
  siteId?: "ANY" | number;
  statusSet?: string;
  timeRange?: TimeRange;
}

const statusSetOptions: StandardOption[] = [
  { value: "draft", label: "Draft" },
  { value: "pending_specialist_review", label: "Pending Specialist Review" },
  { value: "ready_to_review", label: "Ready to Review" },
  { value: "finalized", label: "Finalized" },
];

const ECONSULT_FILTER_DATA = gql`
  query EconsultFilterData {
    me {
      id
      departments {
        id
        users {
          id
          name
          lastName
          aristamdUserId
          aristamdProviderId
        }
        locations {
          id
          name
          aristamdOrganizationId
        }
      }
    }
    amdSpecialties {
      id
      displayName
    }
  }
`;

interface Data {
  amdSpecialties: {
    id: number;
    displayName: string;
  }[];
  me: {
    id: string;
    departments: {
      id: string;
      users: {
        id: string;
        name: string;
        lastName: string;
        aristamdUserId: number | null;
        aristamdProviderId: number | null;
      }[];
      locations: {
        id: string;
        name: string;
        aristamdOrganizationId: number | null;
      }[];
    }[];
  };
}

/**
 * FilterPanel.
 */

interface FilterPanelProps {
  value: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading?: boolean;
}

export const FilterPanel: FC<FilterPanelProps> = props => {
  const { value, onChange, isLoading } = props;
  const { data, loading } = useQuery(ECONSULT_FILTER_DATA);

  const specialtyOpts = specialtyOptions(data);
  const requestingPhysicianOpts = requestingPhysicianOptions(data);
  const siteOpts = siteOptions(data);

  return (
    <FilterForm<FilterModel>
      defaultValue={defaultFilter}
      value={value}
      onChange={(values: FilterModel) => onChange(values)}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="afterDate" icon="filter" label="Date">
          <div style={{ width: "20rem" }}>
            <DateRangeDropdownInput name="timeRange" />
          </div>
        </FilterField>

        <FilterField htmlFor="specialtyId" icon="filter" label="Specialty">
          <div className="w-56">
            <SelectInput
              name="specialtyId"
              options={specialtyOpts}
              isLoading={loading}
              placeholder="Any"
              isClearable
            />
          </div>
        </FilterField>

        <FilterField
          htmlFor="requestingPhysicianId"
          icon="filter"
          label="Provider"
        >
          <div className="w-48">
            <SelectInput
              name="requestingPhysicianId"
              options={requestingPhysicianOpts}
              isLoading={loading}
              placeholder="Any"
              isClearable
            />
          </div>
        </FilterField>

        <FilterField htmlFor="siteId" icon="filter" label="Site">
          <div className="w-48">
            <SelectInput
              name="siteId"
              options={siteOpts}
              isLoading={loading}
              placeholder="Any"
              isClearable
            />
          </div>
        </FilterField>

        <FilterField htmlFor="statusSet" icon="filter" label="Status">
          <div className="w-48">
            <SelectInput
              name="statusSet"
              options={statusSetOptions}
              isLoading={loading}
              placeholder="Any"
              isClearable
            />
          </div>
        </FilterField>

        <FilterField>
          <Button
            type="submit"
            color="blue"
            disabled={loading || isLoading}
            isLoading={isLoading}
          >
            Apply
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};

function specialtyOptions(data: Data | void): StandardOption<number>[] {
  if (!data) {
    return [];
  } else {
    return data.amdSpecialties.map(s => ({
      value: s.id,
      label: s.displayName,
    }));
  }
}

function requestingPhysicianOptions(
  data: Data | void
): StandardOption<number>[] {
  if (!data) {
    return [];
  } else {
    const rawOptions = data.me.departments.flatMap(dept =>
      dept.users.filter(user => user.aristamdProviderId)
    );
    return sortBy(uniqBy(rawOptions, "aristamdUserId"), "lastName").map(u => ({
      value: u.aristamdUserId!,
      label: u.name,
    }));
  }
}

function siteOptions(data: Data | void): StandardOption<number>[] {
  function isAMD(location: any) {
    return !!location.aristamdOrganizationId;
  }

  if (!data) {
    return [];
  } else {
    const rawOptions = data.me.departments.flatMap(dept =>
      dept.locations.filter(isAMD)
    );
    return sortBy(uniqBy(rawOptions, "aristamdOrganizationId"), "name").map(
      l => ({
        value: l.aristamdOrganizationId!,
        label: l.name,
      })
    );
  }
}

export function removeVoidKeys(
  filterWithNulls: Partial<Record<keyof FilterModel, any>>
): FilterModel {
  let filter = {};
  for (const key in filterWithNulls) {
    if (filterWithNulls[key] !== null && filterWithNulls[key] !== undefined) {
      filter[key] = filterWithNulls[key];
    }
  }
  return filter;
}

/**
 * Returns filter without keys that have value "ANY" or "ALL".
 * This is useful because the GraphQL API for AristaMD ID fields (e.g. specialtyId)
 * are integers, but we use "ANY" and "ALL" as the "unselected" value.
 */
export function removeUnusedFilterKeys(
  filter: Partial<Record<keyof FilterModel, any>>
): FilterModel {
  let newFilter = {};
  for (const key in filter) {
    if (filter[key] !== "ANY" && filter[key] !== "ALL") {
      newFilter[key] = filter[key];
    }
  }
  return newFilter;
}
