import { FC, useState, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import PaperClipIcon from '@heroicons/react/outline/PaperClipIcon';
import {
  Button,
  DropdownButton,
  DropdownItemButton,
  FAIcon,
} from "@preferral/ui";
import { AddFilesModal } from "./AddFilesModal";
import { useAppointmentRequest } from "../../AppointmentRequestContext";
// import { FileUploadModel } from "../../model";
import { useSet } from "hooks/useSet";

const REMOVE_FILE = gql`
  mutation RemoveFileUpload(
    $appointmentRequestId: UUID4!
    $fileUploadId: UUID4!
  ) {
    removeAppointmentRequestFile(
      appointmentRequestId: $appointmentRequestId
      fileUploadId: $fileUploadId
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
      }
    }
  }
`;

interface RemoveMutationData {
  removeAppointmentRequestFile: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
    };
  };
}

interface RemoveMutationVariables {
  appointmentRequestId: string;
  fileUploadId: string;
}

/**
 * NoFiles.
 */

const NoFiles: FC = () => {
  return (
    <div className="_NoFiles p-3 rounded bg-gray-50 border border-dotted text-center">
      <div className="flex items-center justify-center p-4">
        <PaperClipIcon className="inline-block h-5 w-5 text-cool-gray-400" />
        <p className="ml-2 text-cool-gray-500">No File Attachments</p>
      </div>
    </div>
  );
};

/**
 * Files.
 */

type ActiveModal = "ADD_FILES";

interface FilesProps { }

export const Files: FC<FilesProps> = (props) => {
  const {
    appointmentRequest: { id: appointmentRequestId, fileUploads },
    refetch,
  } = useAppointmentRequest();

  // Used for loading indicators.
  const deletingFiles = useSet<string>();
  // Used for optimistic UI updates.
  const deletedFiles = useSet<string>();

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const openAddFilesModal = useCallback(() => setActiveModal("ADD_FILES"), []);
  const closeModal = useCallback(() => setActiveModal(null), []);

  const [removeFile, { loading: isRemoving }] = useMutation<
    RemoveMutationData,
    RemoveMutationVariables
  >(REMOVE_FILE);

  const doRemoveFile = useCallback(
    (fileUploadId: string) => {
      deletingFiles.add(fileUploadId);
      return removeFile({
        variables: { appointmentRequestId, fileUploadId },
      }).then((resp) => {
        deletingFiles.remove(fileUploadId);
        if (resp.data?.removeAppointmentRequestFile.appointmentRequest) {
          // it worked
          deletedFiles.add(fileUploadId);
          return refetch();
        }
      });
    },
    [
      deletedFiles,
      appointmentRequestId,
      deletingFiles,
      removeFile,
      refetch,
    ]
  );

  return (
    <>
      <AddFilesModal
        isOpen={activeModal === "ADD_FILES"}
        onClose={closeModal}
      />
      <div className="Files">
        <h4 className="text-xl py-4 font-semibold">Files</h4>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Attachments</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {fileUploads.length === 0 ? (
              <NoFiles />
            ) : (
              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                {fileUploads
                  .filter((fu) => !deletedFiles.has(fu.id))
                  .map((fu) => (
                    <li
                      key={fu.id}
                      className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                    >
                      <div className="w-0 flex-1 flex items-center">
                        {/* <!-- Heroicon name: paper-clip --> */}
                        <PaperClipIcon className="inline-block flex-shrink-0 h-5 w-5 text-gray-400" />
                        <span className="ml-2 flex-1 w-0 truncate">
                          {fu.name}
                        </span>
                      </div>
                      <div className="ml-2 flex-shrink-0">
                        <a
                          href={fu.url}
                          target="_blank"
                          rel="noref noreferrer nofollow"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Download
                        </a>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <DropdownButton>
                          <DropdownItemButton
                            color="red"
                            onClick={() => doRemoveFile(fu.id)}
                            isLoading={isRemoving}
                          >
                            Remove File
                          </DropdownItemButton>
                        </DropdownButton>
                      </div>
                    </li>
                  ))}
              </ul>
            )}
            <div className="_FilesListFooter p-2 text-center">
              <Button
                type="button"
                color="blue"
                size="xs"
                kind="secondary"
                onClick={openAddFilesModal}
              >
                <span className="mr-2">
                  <FAIcon icon="plus" />
                </span>
                Add Files
              </Button>
            </div>
          </dd>
        </div>
      </div>
    </>
  );
};
