import React, { FC } from "react";
import { useAuth } from "context/AuthContext";
import { Route, RouteProps, Redirect } from "react-router-dom";

type PrivateRouteProps = {
  signInPath?: string;
} & RouteProps;

export const PrivateRoute: FC<PrivateRouteProps> = props => {
  const { children, signInPath = "/auth/sign_in", ...rest } = props;
  const { token } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: signInPath,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};
