import { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import { ApolloProvider } from "./ApolloContext";
import { AmdSessionProvider } from "./AmdSessionContext"
import { ScreenTitleProvider } from "./ScreenTitle";
import { FileUploaderProvider } from "@preferral/ui";

export const AppProviders: FC = (props) => {
  const { children } = props;

  return (
    <AuthProvider>
      <ApolloProvider>
        <ScreenTitleProvider>
          <FileUploaderProvider>
            <AmdSessionProvider>
              <Router>{children}</Router>
            </AmdSessionProvider>
          </FileUploaderProvider>
        </ScreenTitleProvider>
      </ApolloProvider>
    </AuthProvider>
  );
};
