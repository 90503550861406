import { FC, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import {
  QuestionnaireModel,
  QUESTIONNAIRE_FRAGMENT,
  Response,
  questionnaireForServer,
  mergeAnswers,
  QuestionnaireFields,
  QuestionnaireFieldset,
  templateToFields,
} from "components/Questionnaire";
import { Spinner, Button } from "@preferral/ui";
import { Formik } from "formik";
import CheckCircleIcon from "@heroicons/react/outline/CheckCircleIcon";
import toast from "react-hot-toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";

const PATIENT_FORM = gql`
  query FetchPatientForm($id: UUID4!) {
    patientForm(id: $id) {
      id
      title
      kind
      logoUrl
      form {
        ...QuestionnaireFields
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

interface Data {
  patientForm: {
    id: string;
    title: string;
    kind: string;
    logoUrl?: string;
    form: QuestionnaireModel;
  };
}

const SUBMIT_PATIENT_FORM = gql`
  mutation SubmitPatientForm(
    $patientFormId: UUID4!
    $input: PatientFormSubmissionInput!
  ) {
    submitPatientForm(patientFormId: $patientFormId, input: $input) {
      errors {
        key
        message
      }
      patientFormSubmission {
        id
        form {
          ...QuestionnaireFields
        }
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

interface MutationData {
  submitPatientForm: {
    errors?: InputError[];
    patientFormSubmission?: {
      id: string;
      form: QuestionnaireModel;
    };
  };
}

interface MutationVariables {
  patientFormId: string;
  input: {
    form: QuestionnaireModel;
  };
}

interface FormValues {
  // form: QuestionnaireModel;
  form: QuestionnaireFieldset;
}

interface RouteParams {
  formId: string;
}

/**
 * FillOutPatientForm.
 */

interface FillOutPatientFormProps {
  patientFormId: string;
  onSuccess?(form: QuestionnaireModel): void;
}

const FillOutPatientForm: FC<FillOutPatientFormProps> = props => {
  const { patientFormId, onSuccess } = props;

  const { loading, data, error } = useQuery<Data>(PATIENT_FORM, {
    variables: { id: patientFormId },
  });

  const [submitPatientForm] = useMutation<MutationData, MutationVariables>(
    SUBMIT_PATIENT_FORM
  );

  const onSubmit = (values: FormValues, formikActions: any) => {
    const { setStatus, setSubmitting } = formikActions;

    const questionnaire = mergeAnswers(data!.patientForm.form, values.form);
    const input = {
      ...values,
      form: questionnaireForServer(questionnaire),
    };

    setStatus({ errors: null });
    return submitPatientForm({
      variables: { patientFormId, input },
    }).then(resp => {
      if (resp?.data?.submitPatientForm.errors) {
        setStatus({ errors: resp.data.submitPatientForm.errors });
      } else if (resp.data?.submitPatientForm.patientFormSubmission) {
        // it worked...
        toast.success("Form submitted!");

        if (onSuccess) {
          onSuccess(questionnaire);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <div className="FillOutPatientFormScreen text-left">
      <div className={`container mx-auto px-3 py-8 ${data?.patientForm.kind === "notice" ? "pb-16" : ""}`}>
        <div className="rounded-xl border shadow-xl p-4 lg:p-8 bg-white">
          {data?.patientForm.logoUrl ? (
            <div className="pt-4 pb-8">
              <img
                alt="logo"
                className="mx-auto"
                src={data.patientForm.logoUrl}
                style={{
                  maxWidth: 240,
                  maxHeight: 90,
                  width: "auto",
                  height: "auto",
                }}
              />
            </div>
          ) : null}

          {loading ? (
            <div className="p-12 text-center">
              <Spinner />
            </div>
          ) : error || !data?.patientForm ? (
            <p>Failed to load</p>
          ) : (
            <>
              <h2 className="mt-4 mb-8 text-2xl lg:text-3xl font-bold text-gray-700">
                {data.patientForm.title}
              </h2>

              <Formik
                initialValues={{
                  form: templateToFields(data.patientForm.form),
                }}
                onSubmit={onSubmit}
              >
                {({ status, handleSubmit, isSubmitting }) => (
                  <>
                    <form onSubmit={handleSubmit}>
                      <FormStatusErrors status={status} />
                      <QuestionnaireFields
                        name="form"
                        template={data.patientForm.form}
                      />
                      <div className={data.patientForm.kind === "notice" ? "bg-white border-gray-200 border-t bottom-0 fixed left-0 mx-auto p-6 right-0 shadow-2xl w-full" : "max-w-3xl mx-auto mt-8 border-t border-gray-200 pt-5"}>
                        <div className="container mx-auto">
                          <div className="flex justify-end">
                            <span className="ml-3 inline-flex rounded-md shadow-sm">
                              <Button
                                type="submit"
                                color="blue"
                                isLoading={isSubmitting}
                                disabled={isSubmitting}
                              >
                                {data.patientForm.kind === "notice"
                                  ? "Acknowledge Notice"
                                  : "Submit Form"}
                              </Button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </Formik>
            </>
          )}
        </div>
      </div>
    </div >
  );
};

/**
 * ViewPatientFormSubmission.
 */

interface ViewPatientFormSubmissionProps {
  submission: QuestionnaireModel;
}

const ViewPatientFormSubmission: FC<ViewPatientFormSubmissionProps> = props => {
  const { submission } = props;

  return (
    <div className="_ViewPatientFormSubmission bg-cool-gray-200">
      <div className="text-center">
        <div className="pb-4 pt-8 px-8">
          <CheckCircleIcon className="text-green-500 mx-auto w-16 h-16" />
        </div>
        <div className="px-8 pt-4 pb-8">
          <h1 className="text-xl text-cool-gray-600 font-bold">
            Thank you for submitting the form!
          </h1>
          <p className="text-cool-gray-800 font-semibold mt-3">
            View your form submission below:
          </p>
        </div>
      </div>
      <div className="container mx-auto p-4 text-left">
        <div className="border overflow-hidden rounded-lg shadow-xl">
          <Response questionnaire={submission} />
        </div>
      </div>
    </div>
  );
};

/**
 * FillOutPatientFormScreen.
 */

interface FillOutPatientFormScreenProps { }

export const FillOutPatientFormScreen: FC<FillOutPatientFormScreenProps> = props => {
  const match = useRouteMatch<RouteParams>();
  const { formId } = match.params;

  const [
    savedSubmission,
    setSavedSubmission,
  ] = useState<QuestionnaireModel | null>(null);

  return !savedSubmission ? (
    <FillOutPatientForm patientFormId={formId} onSuccess={setSavedSubmission} />
  ) : (
    <ViewPatientFormSubmission submission={savedSubmission} />
  );
};
