import { FC } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Spinner } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { VerticalNav, VerticalNavLink } from "components/VerticalNav";
import { AppointmentRequestInboxScreen } from "screens/AppointmentRequestInboxScreen";

const INBOXES = gql`
  query UserInboxes {
    me {
      id
      departments {
        id
        name
        inboxes {
          id
          name
          openAppointmentRequestCount
        }
      }
    }
  }
`;

interface Data {
  me: {
    id: string;
    departments: DepartmentModel[];
  };
}

interface DepartmentModel {
  id: string;
  name: string;
  inboxes: InboxModel[];
}

interface InboxModel {
  id: string;
  name: string;
  openAppointmentRequestCount: number;
}

interface ReceivedAppointmentRequestsScreenProps { }

export const ReceivedAppointmentRequestsScreen: FC<ReceivedAppointmentRequestsScreenProps> = () => {
  const { data, loading, error } = useQuery<Data>(INBOXES);
  const firstInbox = data?.me.departments[0]?.inboxes[0];
  const match = useRouteMatch();

  const inboxCount = countInboxes(data);

  return (
    <>
      <ScreenTitle title={["Referrals", "Received"]} />
      <div className="_ReceivedAppointmentRequestsScreen text-left">
        <div className="flex">
          {inboxCount && inboxCount > 1 ? (
            <div className="_InboxNav px-6 py-4 flex-grow-0 flex-shrink-0">
              <div className="w-60">
                {loading ? (
                  <div className="p-4 text-center">
                    <Spinner />
                  </div>
                ) : error || !data?.me ? (
                  <p>Failed to load</p>
                ) : (
                  <>
                    {data.me.departments.map((dept) => (
                      <div key={dept.id} className="pb-6">
                        <h3 className="mb-4 text-cool-gray-500 uppercase tracking-wide font-bold text-sm lg:text-xs">
                          {dept.name}
                        </h3>
                        <VerticalNav>
                          {dept.inboxes.map((inbox) => (
                            <VerticalNavLink
                              key={inbox.id}
                              icon="inbox"
                              to={`${match.url}/${inbox.id}/requests`}
                              badgeText={
                                inbox.openAppointmentRequestCount || undefined
                              }
                            >
                              {inbox.name}
                            </VerticalNavLink>
                          ))}
                        </VerticalNav>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          ) : null}

          <div className="_SelectedInbox flex-grow sm:px-6 lg:px-8">
            <Switch>
              {firstInbox ? (
                <Route exact path={match.path}>
                  <Redirect to={`${match.url}/${firstInbox.id}/requests`} />
                </Route>
              ) : null}
              <Route exact path={`${match.path}/:inboxId/requests`}>
                <AppointmentRequestInboxScreen />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

function countInboxes(data: Data | void): number | void {
  if (data) {
    const inboxCount = data.me.departments.reduce(
      (acc, elem) => acc + elem.inboxes.length,
      0
    );
    return inboxCount;
  }
}
