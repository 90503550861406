import { FC, useCallback } from "react";
import Select, { createFilter } from "react-select";
import { gql, useQuery } from "@apollo/client";
import { HorizontalField, StandardOption, VerticalField } from "@preferral/ui";
import { ErrorMessage, useField } from "formik";

const LIST_STATES = gql`
  query ListStates {
    states {
      id
      name
      abbreviation
    }
  }
`;

interface Data {
  states: StateModel[];
}

interface StateModel {
  id: string;
  name: string;
  abbreviation: StateAbbreviation;
}

type StateAbbreviation = string;

const filterFromStart = createFilter({ matchFrom: "start" });

export interface InputStateSelectProps {
  id?: string;
  className?: string;
  value?: StateAbbreviation | null | undefined;
  onBlur?(e: any): void;
  onChange(stateAbbreviation: StateAbbreviation | null): void;
  placeholder?: string;
}

export const InputStateSelect: FC<InputStateSelectProps> = (props) => {
  const { id, className = "", value, onBlur, onChange, placeholder } = props;

  const { data, loading } = useQuery<Data>(LIST_STATES);
  const options = data?.states.map(optionForState) || [];

  const handleChange = useCallback(
    (option: any) => {
      if (option) {
        onChange(option.value);
      } else {
        onChange(null);
      }
    },
    [onChange]
  );

  return (
    <Select<StandardOption>
      id={id}
      className={className}
      value={options.find((o) => o.value === value)}
      options={options}
      onChange={handleChange}
      onBlur={onBlur}
      isLoading={loading}
      placeholder={placeholder}
      filterOption={filterFromStart}
    />
  );
};

export interface StateSelectInputProps {
  name: string;
  id?: string;
  placeholder?: string;
  className?: string;
}

export const StateSelectInput: FC<StateSelectInputProps> = (props) => {
  const { id, name, placeholder, className } = props;

  const [field, meta, helpers] = useField(name);
  const { value = "", onBlur } = field;
  const { setValue } = helpers;

  return (
    <>
      <InputStateSelect
        id={id}
        value={value}
        onChange={setValue}
        onBlur={onBlur}
        className={
          meta && meta.touched && meta.error
            ? `${className} border border-red-500`
            : className
        }
        placeholder={placeholder}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />{" "}
    </>
  );
};

/**
 * StateSelectField.
 */

export interface StateSelectFieldProps extends StateSelectInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const StateSelectField: FC<StateSelectFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <StateSelectInput {...rest} placeholder={props.placeholder || label} />
    </VerticalField>
  );
};

export const HorizontalStateSelectField: FC<StateSelectFieldProps> = (
  props
) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <StateSelectInput {...rest} placeholder={props.placeholder || label} />
    </HorizontalField>
  );
};

function optionForState(state: StateModel): StandardOption {
  return {
    value: state.abbreviation,
    label: state.name,
  };
}
