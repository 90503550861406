import { FC } from "react";
import { gql, useQuery } from '@apollo/client';
import { Button, CircleIcon, JsonDebugger, Spinner } from "@preferral/ui";
import { iso8601Date } from "lib/dateFormatters";
import { TimeRange, TimeSeriesData } from "../model";
import { LineChart } from "../charts/LineChart";
import { transformTimeSeries } from "../helpers";

const SENT_REFERRALS_VOLUME = gql`
  query SentReferralsVolume($dateRange: InclusiveDateRangeInput!) {
    analyticsSentReferralsVolume(dateRange: $dateRange) {
      timeRange {
        start
        finish
      }
      data {
        timespan
        metrics {
          metric
          value
        }
      }
    }
  }
`;

interface Data {
  analyticsSentReferralsVolume: TimeSeriesData;
}

interface SentReferralsVolumeProps {
  timeRange: TimeRange;
}

export const SentReferralsVolume: FC<SentReferralsVolumeProps> = props => {
  const { timeRange } = props;

  const dateRange = {
    start: iso8601Date(timeRange.start),
    finish: iso8601Date(timeRange.finish)
  };

  const { data, loading, error, refetch } = useQuery<Data>(SENT_REFERRALS_VOLUME, {
    variables: { dateRange }
  });

  return (
    <div className="_SentReferralsVolume p-4 bg-white rounded-lg border shadow-xl">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <CircleIcon icon="chart-line" />
          <p className="text-xl font-semibold ml-4">
            Sent Referrals
          </p>
        </div>
      </div>
      {
        loading ? (
          <div className="p-8 text-center">
            <Spinner />
          </div>
        ) : error || !data?.analyticsSentReferralsVolume ? (
          <div className="p-8 text-center">
            <p>Failed to load.</p>
            <Button type="button" size="sm" className="mt-4" disabled={loading} isLoading={loading} onClick={() => refetch()}>
              Retry
            </Button>
          </div>
        ) : (
          <div className="p-8 text-left">
            <LineChart
              data={transformTimeSeries(data.analyticsSentReferralsVolume.data)}
              xValKey="datetime"
              yValKey="referrals"
              yValLabel="Referrals"
            />
          </div>
        )
      }
      <JsonDebugger data={data} />
    </div>
  );
};
