import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spinner, Button, Avatar, ImageObject } from "@preferral/ui";
import XIcon from '@heroicons/react/outline/XIcon'
import { defaultAvatar } from "./ProviderSearchResult";
import { ProviderReferenceModel } from "./types";

const PROVIDER_OF_KIND = gql`
  query GetProvider($kind: String!, $id: UUID4!) {
    providerOfKind(kind: $kind, id: $id) {
      __typename
      ... on Provider {
        id
        nameWithAppellation
        primarySpecialty {
          id
          name
        }
        cityStateNames
        avatar(size: "thumbnail") {
          base64
          src
          srcSet
        }
        organizations {
          id
          name
        }
      }
      ... on NppesProvider {
        id
        nameWithAppellation
        primarySpecialty {
          id
          name
        }
        cityStateNames
        avatar(size: "thumbnail") {
          base64
          src
          srcSet
        }
        organizations {
          id
          name
        }
      }
      ... on UnlistedProvider {
        id
        nameWithAppellation
        primarySpecialty {
          id
          name
        }
        cityStateNames
        avatar(size: "thumbnail") {
          base64
          src
          srcSet
        }
      }
    }
  }
`;

interface Data {
  providerOfKind: Provider | NPPESProvider | UnlistedProvider;
}

interface Variables {
  kind: string;
  id: string;
}

interface Provider {
  __typename: "Provider";
  id: string;
  nameWithAppellation: string;
  primarySpecialty?: {
    id: string;
    name: string;
  };
  cityStateNames: string[];
  avatar?: ImageObject;
  organizations: {
    id: string;
    name: string;
  }[];
}

interface NPPESProvider {
  __typename: "NppesProvider";
  id: string;
  nameWithAppellation: string;
  primarySpecialty?: {
    id: string;
    name: string;
  };
  cityStateNames: string[];
  avatar?: ImageObject;
  organizations: {
    id: string;
    name: string;
  }[];
}

interface UnlistedProvider {
  __typename: "UnlistedProvider";
  id: string;
  nameWithAppellation: string;
  primarySpecialty?: {
    id: string;
    name: string;
  };
  cityStateNames: string[];
  avatar?: ImageObject;
}

/**
 * ProviderInfoCard.
 */

interface ProviderInfoCardProps {
  nameWithAppellation: string;
  specialtyName?: string;
  cityStateName?: string;
  avatarImage?: ImageObject;
  platformVerified?: boolean;
  organizationName?: string;
  onClearSelection?(): void;
  isClearable?: boolean;
}

const ProviderInfoCard: FC<ProviderInfoCardProps> = (props) => {
  const {
    nameWithAppellation,
    specialtyName,
    cityStateName,
    avatarImage = defaultAvatar,
    organizationName,
    onClearSelection,
    platformVerified = false,
    isClearable = false,
  } = props;

  return (
    <div className="_PlatformProviderCard h-16 border border-cool-gray-200 rounded-lg flex items-center px-3 py-1 shadow text-left">
      <div className="flex-shrink-0">
        <Avatar
          size="thumb"
          image={avatarImage}
          className="border border-cool-gray-100"
          verifiedCheck={platformVerified}
        />
      </div>
      <div className="flex-grow flex-shrink min-w-0 overflow-hidden ml-3 font-semibold text-gray-800">
        <p className="whitespace-no-wrap truncate">
          {nameWithAppellation}
          {specialtyName ? (
            <span className="ml-2 font-light text-xs italic text-cool-gray-600 truncate">
              {specialtyName}
            </span>
          ) : null}
        </p>
        {cityStateName || organizationName ? (
          <p className="font-light text-cool-gray-600 italic text-xs">
            {organizationName || null}
            {cityStateName && organizationName && (
              <span className="mx-2">{"·"}</span>
            )}
            {cityStateName || null}
          </p>
        ) : null}
      </div>
      {isClearable && onClearSelection ? (
        <Button
          type="button"
          kind="tertiary"
          size="sm"
          color="red"
          onClick={onClearSelection}
        >
          <XIcon className="h-6 w-6" />
        </Button>
      ) : null}
    </div>
  );
};

/**
 * SelectedProviderCard.
 */

interface SelectedProviderCardProps {
  provider: Provider | NPPESProvider | UnlistedProvider;
  onClearSelection?(): void;
  isClearable?: boolean;
}

const SelectedProviderCard: FC<SelectedProviderCardProps> = (props) => {
  const { provider, onClearSelection, isClearable } = props;

  switch (provider.__typename) {
    case "Provider": {
      const organizationName = provider.organizations.map((o) => o.name)[0];
      return (
        <ProviderInfoCard
          nameWithAppellation={provider.nameWithAppellation}
          avatarImage={provider.avatar}
          specialtyName={provider.primarySpecialty?.name}
          organizationName={organizationName}
          onClearSelection={onClearSelection}
          isClearable={isClearable}
          cityStateName={provider.cityStateNames[0]}
          platformVerified
        />
      );
    }
    case "NppesProvider": {
      const organizationName = provider.organizations.map((o) => o.name)[0];
      return (
        <ProviderInfoCard
          nameWithAppellation={provider.nameWithAppellation}
          avatarImage={provider.avatar}
          specialtyName={provider.primarySpecialty?.name}
          organizationName={organizationName}
          cityStateName={provider.cityStateNames[0]}
          onClearSelection={onClearSelection}
          isClearable={isClearable}
        />
      );
    }
    case "UnlistedProvider": {
      return (
        <ProviderInfoCard
          nameWithAppellation={provider.nameWithAppellation}
          avatarImage={provider.avatar}
          specialtyName={provider.primarySpecialty?.name}
          cityStateName={provider.cityStateNames[0]}
          onClearSelection={onClearSelection}
          isClearable={isClearable}
        />
      );
    }
  }
};

/**
 * SelectedProvider.
 */

interface SelectedProviderProps {
  provider: ProviderReferenceModel;
  onClear?(): void;
  isClearable?: boolean;
}

export const SelectedProvider: FC<SelectedProviderProps> = (props) => {
  const { provider, onClear, isClearable } = props;
  const { id, kind } = provider;
  const { data, loading, error } = useQuery<Data, Variables>(PROVIDER_OF_KIND, {
    variables: {
      id,
      kind,
    },
  });

  return (
    <div>
      {loading ? (
        <div className="px-3 py-4 flex items-center justify-center h-16 border border-cool-gray-200 rounded-lg">
          <Spinner />
        </div>
      ) : error || !data?.providerOfKind ? (
        <p>Failed to load.</p>
      ) : (
        <SelectedProviderCard
          provider={data.providerOfKind}
          onClearSelection={onClear}
          isClearable={isClearable}
        />
      )}
    </div>
  );
};
