import { FC, useCallback } from "react";
import {
  DropdownButton,
  DropdownItemButton,
  FAIcon,
  Spinner,
} from "@preferral/ui";
import { gql, FetchResult, useMutation } from "@apollo/client";
import { TaskModel } from "../../model";
import CheckIcon from '@heroicons/react/outline/CheckIcon';
import { ddd } from "@preferral/common";
import { mmDd } from "lib/dateFormatters";
import { useAppointmentRequest } from "../../AppointmentRequestContext";
import { analytics } from "lib/analytics";

const COMPLETE_TASK = gql`
  mutation CompleteTask($taskId: UUID4!) {
    completeTask(taskId: $taskId) {
      errors {
        key
        message
      }
      task {
        id
        done
        completedAt
      }
    }
  }
`;

interface CompleteMutationData {
  completeTask: {
    errors?: InputError[];
    task?: {
      id: string;
      done: boolean;
      completedAt?: string;
    };
  };
}

const UNCOMPLETE_TASK = gql`
  mutation UncompleteTask($taskId: UUID4!) {
    uncompleteTask(taskId: $taskId) {
      errors {
        key
        message
      }
      task {
        id
        done
        completedAt
      }
    }
  }
`;

interface UncompleteMutationData {
  uncompleteTask: {
    errors?: InputError[];
    task?: {
      id: string;
      done: boolean;
      completedAt?: string;
    };
  };
}

const DELETE_TASK = gql`
  mutation DeleteTask($taskId: UUID4!) {
    deleteTask(taskId: $taskId) {
      errors {
        key
        message
      }
      task {
        id
      }
    }
  }
`;

interface DeleteMutationData {
  deleteTask: {
    errors?: InputError[];
    task?: {
      id: string;
    };
  };
}

interface MutationVariables {
  taskId: string;
}

/**
 * CheckButton.
 */

interface CheckButtonProps {
  isChecked: boolean;
  onClick(): void;
  isLoading?: boolean;
}

const CheckButton: FC<CheckButtonProps> = (props) => {
  const { isChecked, onClick, isLoading = false } = props;

  const classNames = isChecked
    ? "border-green-300 bg-green-100 text-green-600 hover:bg-cool-gray-100"
    : "bg-white hover:bg-green-100";

  return (
    <div
      role="button"
      className={`ml-6 h-10 w-10 flex-shrink-0 flex items-center justify-center border rounded-full transition-colors ease-in-out duration-100 ${classNames}`}
      onClick={onClick}
    >
      {isLoading ? (
        <Spinner />
      ) : isChecked ? (
        <CheckIcon className="inline-block h-6 w-6" />
      ) : null}
    </div>
  );
};

export interface TaskProps {
  task: TaskModel;
  onToggle?(): void;
  onDelete?(): void;
}

export const Task: FC<TaskProps> = (props) => {
  const { task, onToggle, onDelete } = props;

  const [completeTask, { loading: isCompleting }] = useMutation<
    CompleteMutationData,
    MutationVariables
  >(COMPLETE_TASK);

  const [uncompleteTask, { loading: isUncompleting }] = useMutation<
    UncompleteMutationData,
    MutationVariables
  >(UNCOMPLETE_TASK);

  const [deleteTask, { loading: isDeleting }] = useMutation<
    DeleteMutationData,
    MutationVariables
  >(DELETE_TASK);

  const {
    appointmentRequest: { id: appointmentRequestId, insertedAt },
    refetch,
  } = useAppointmentRequest();

  const doDeleteTask = useCallback(() => {
    return deleteTask({ variables: { taskId: task.id } }).then((resp) => {
      if (resp.data?.deleteTask.task) {
        // it worked
        analytics.track("Task Deleted", {
          referral_id: appointmentRequestId,
          referral_requested_date: insertedAt,
        });
        if (onDelete) {
          return onDelete();
        }
      }
    });
  }, [task.id, deleteTask, onDelete]);

  const isSubmitting = isCompleting || isUncompleting;

  const toggleCompletion = useCallback(() => {
    const successHandler = (resp: FetchResult) => {
      if (resp.data?.completeTask?.task || resp.data?.uncompleteTask?.task) {
        // it worked
        if (resp.data?.completeTask?.task.done) {
          analytics.track("Task Completed",
            {
              'referral_id': appointmentRequestId,
              'referral_requested_date': insertedAt,
            });
        }
        if (onToggle) {
          return onToggle();
        }
      }
    };

    if (task.done) {
      return uncompleteTask({ variables: { taskId: task.id } }).then(
        successHandler
      );
    } else {
      return completeTask({ variables: { taskId: task.id } }).then(
        successHandler
      );
    }
  }, [task.done, task.id, completeTask, uncompleteTask, onToggle]);

  const labelClassName = task.done
    ? "italic text-cool-gray-700 line-through"
    : task.isDue
      ? "text-yellow-600"
      : "text-cool-gray-800";

  const dueDateString = task.dueAt
    ? `${ddd(task.dueAt)} ${mmDd(task.dueAt)}`
    : null;

  return (
    <div className="_Task border-t border-cool-gray-200 relative py-1 w-full">
      {task.isDue && !task.done ? (
        <div className="-translate-x-1/2 -translate-y-1/2 absolute bg-yellow-100 border border-yellow-300 flex w-6 h-6 items-center justify-center left-0 rounded-full text-sm text-yellow-800 top-1/2 transform">
          <FAIcon icon="bell" />
        </div>
      ) : null}
      <div className="flex items-center">
        <CheckButton
          isChecked={task.done}
          onClick={toggleCompletion}
          isLoading={isSubmitting}
        />

        <div className="ml-3 flex-grow">
          <p className={`font-bold ${labelClassName}`}>{task.label}</p>
          <div className="flex items-end">
            {dueDateString ? (
              <p
                className={`mr-3 text-xs font-semibold ${task.isDue && !task.done
                  ? "text-yellow-500"
                  : "text-cool-gray-600"
                  }`}
              >
                {dueDateString}
              </p>
            ) : null}

            {task.createdByUser ? (
              <p className="text-xs text-cool-gray-500 font-light">
                Added by: {task.createdByUser.firstName}{" "}
                {task.createdByUser.lastName} (
                {task.createdByUser.organization.name})
              </p>
            ) : null}
          </div>
        </div>
        <div className="mr-4">
          <DropdownButton>
            <DropdownItemButton color="red" onClick={doDeleteTask} isLoading={isDeleting}>
              <span className="mr-2">
                <FAIcon icon="trash" />
              </span>
              Delete Task
            </DropdownItemButton>
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};
