import React, { HTMLAttributes, forwardRef } from "react";
import { colorClassNames } from "./colorClassNames";

interface ExclamationCircleIconProps extends HTMLAttributes<SVGSVGElement> {
  color: AppColor;
}

export const ExclamationCircleIcon = forwardRef<
  SVGSVGElement,
  ExclamationCircleIconProps
>((props, ref) => {
  const { color, ...rest } = props;
  const lightClassName = colorClassNames[color].light;
  const darkClassName = colorClassNames[color].dark;
  return (
    <svg
      ref={ref}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        className={lightClassName}
        d="M12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C9.34784 22 6.8043 20.9464 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2V2Z"
        fill="currentColor"
      />
      <path
        className={darkClassName}
        d="M12 18C11.6022 18 11.2207 17.842 10.9393 17.5607C10.658 17.2794 10.5 16.8978 10.5 16.5C10.5 16.1022 10.658 15.7207 10.9393 15.4394C11.2207 15.158 11.6022 15 12 15C12.3978 15 12.7794 15.158 13.0607 15.4394C13.342 15.7207 13.5 16.1022 13.5 16.5C13.5 16.8978 13.342 17.2794 13.0607 17.5607C12.7794 17.842 12.3978 18 12 18ZM13 12.1C12.87 13.3 11.12 13.3 11 12.1L10.5 7.10001C10.486 6.96054 10.5015 6.81967 10.5454 6.68656C10.5893 6.55344 10.6608 6.43105 10.7551 6.32733C10.8493 6.2236 10.9644 6.14086 11.0927 6.08447C11.2211 6.02809 11.3598 5.99931 11.5 6.00001H12.5C12.6402 5.99931 12.7789 6.02809 12.9073 6.08447C13.0356 6.14086 13.1507 6.2236 13.245 6.32733C13.3393 6.43105 13.4107 6.55344 13.4546 6.68656C13.4986 6.81967 13.514 6.96054 13.5 7.10001L13 12.1Z"
        fill="currentColor"
      />
    </svg>
  );
});
