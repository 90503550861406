import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField, SelectField } from "@preferral/ui";

// type StateOption = {
//   id: string;
//   name: string;
//   abbreviation: string;
// };

const TIME_ZONES = gql`
  {
    roles {
      id
      name
    }
    timeZoneNames
    me {
      id
      timeZoneName
    }
  }
`;

interface TimeZonesData {
  roles: {
    id: string;
    name: string;
  }[];
  timeZoneNames: string[];
  me: {
    id: string;
    timeZoneName: string;
  };
}

const INVITE_NEW_USER = gql`
  mutation CreateUserInvitation($input: UserInvitationInput!) {
    inviteUser(input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

interface InviteNewUserData {
  inviteUser: {
    errors?: InputError[];
  };
}

interface UserInvitationInput {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  timeZoneName: string;
}

interface InviteNewUserVariables {
  input: UserInvitationInput;
}

const roleOptions = [
  { label: "Staff", value: "staff" },
  { label: "Org Admin", value: "org_admin" },
];

type NewUserFormProps = {
  refetchQueries: any;
  closeModal: () => void;
};

const NewUserForm: React.FC<NewUserFormProps> = ({
  refetchQueries,
  closeModal,
}) => {
  const { data, loading } = useQuery<TimeZonesData>(TIME_ZONES);
  const [inviteNewUser] = useMutation<
    InviteNewUserData,
    InviteNewUserVariables
  >(INVITE_NEW_USER);
  return (
    <div className="NewUserForm">
      <Formik
        initialValues={{
          email: "",
          firstName: "",
          lastName: "",
          roleId: "",
          timeZoneName: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Invalid email")
            .required("Required"),
          firstName: Yup.string()
            .trim()
            .required("Required"),
          lastName: Yup.string()
            .trim()
            .required("Required"),
          roleId: Yup.string().required("Required"),
          timeZoneName: Yup.string().required("Required"),
        })}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setStatus({ errors: null });
          inviteNewUser({
            variables: { input: values },
            refetchQueries,
          }).then((res) => {
            if (res && res.data && res.data.inviteUser.errors) {
              setStatus({
                errors: res.data.inviteUser.errors,
              });
            } else {
              // it worked...
              toast.success(`Added ${values.email}`);
              closeModal();
            }
            setSubmitting(false);
          });
        }}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <div className="flex flex-wrap -mx-3">
              <div className="w-full md:w-1/2 px-3">
                <TextField name="firstName" label="First Name" autoFocus />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <TextField name="lastName" label="Last Name" />
              </div>
            </div>

            <div className="mt-3">
              <TextField name="email" label="Email" icon="envelope" />
            </div>

            <div className="mt-3">
              <SelectField
                name="roleId"
                label="Role"
                options={
                  data?.roles.map((r) => ({ value: r.id, label: r.name })) || []
                }
              />
            </div>

            <div className="mt-3">
              <SelectField
                name="timeZoneName"
                label="Time Zone"
                options={
                  data?.timeZoneNames.map((tz: string) => ({
                    value: tz,
                    label: tz,
                  })) || []
                }
                isLoading={loading}
              />
            </div>

            <div className="flex items-center justify-end mt-3 py-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-blue"
              >
                Invite User
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { NewUserForm };
