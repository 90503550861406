import React, { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { AddLocationForm } from "./AddLocationForm";

interface AddLocationModalProps {
  departmentId: string;
  refetch(): void;
  isOpen: boolean;
  onClose(): void;
}

export const AddLocationModal: FC<AddLocationModalProps> = (props) => {
  const { departmentId, refetch, isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="map-marker-alt"
        title="Add Department Location"
        onClose={onClose}
      />
      <div className="p-4">
        <AddLocationForm
          departmentId={departmentId}
          refetch={refetch}
          onSuccess={onClose}
        />
      </div>
    </Modal>
  );
};
