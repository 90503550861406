import isNil from "lodash.isnil";

/**
 *
 * @param obj Return a copy of the object, filtered to only have values for the whitelisted keys (or array of valid keys). Alternatively accepts a predicate indicating which keys to pick.
 */
export function pick<T extends Record<string, U>, U extends any>(
  obj: T,
  predicate: (value: U, key?: string, object?: T) => boolean
): Partial<T> {
  const newObj: Record<string, U> = {};
  Object.keys(obj).forEach((key) => {
    if (predicate(obj[key], key, obj)) {
      newObj[key] = obj[key];
    }
  });
  return newObj as Partial<T>;
}

export function pickPresent<T extends Record<string, U>, U extends any>(
  obj: T
): Partial<T> {
  return pick<T, U>(obj, (val) => !isNil(val));
}
