import { FC } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router";
import { NavTabs, NavTabLink } from "layouts/PortalLayout/NavTabs";
import { ScreenTitle } from "context/ScreenTitle";
import { PatientFormSubmissionsScreen } from "./PatientFormSubmissionsScreen";
import { PatientPacketsScreen } from "screens/PatientPacketsScreen";

interface PatientFormsScreenProps { }

export const PatientFormsScreen: FC<PatientFormsScreenProps> = (props) => {
  const match = useRouteMatch();

  return (
    <div className="_PatientFormsScreen min-h-full flex flex-col">
      <ScreenTitle title="Patient Forms" />

      <NavTabs>
        <NavTabLink to={`${match.url}/packets`}>
          <span>Packets</span>
        </NavTabLink>
        <NavTabLink to={`${match.url}/free_forms`}>
          <span>Free Forms</span>
        </NavTabLink>
      </NavTabs>

      {/* <NavTabs>
        <NavTabLink to={`${match.url}/submissions`}>
          <span>Submissions</span>
        </NavTabLink>
        <NavTabLink to={`${match.url}/send`}>
          <span>Send Form</span>
        </NavTabLink>
      </NavTabs> */}

      <Switch>
        <Route exact path={match.path}>
          <Redirect to={`${match.url}/packets`} />
        </Route>
        <Route path={`${match.url}/packets`}>
          <PatientPacketsScreen />
        </Route>
        <Route path={`${match.url}/free_forms`}>
          <PatientFormSubmissionsScreen />
        </Route>
      </Switch>
    </div>
  );
};
