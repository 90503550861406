import { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { mmDdYyyy } from "lib/dateFormatters";
import {
  FAIcon,
  HorizontalField,
  IconContainer,
  VerticalField,
} from "@preferral/ui";
import { ErrorMessage, useField } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerField.css";

export interface InputDatePickerProps {
  value: string;
  icon?: IconProp;
  inputProps?: Partial<ReactDatePickerProps>;
  className?: string;
  placeholder?: string;
  onChange(newValue: string): void;
  onBlur?(e: any): void;
  disabled?: boolean;
}

export const InputDatePicker: FC<InputDatePickerProps> = (props) => {
  const {
    value,
    onChange,
    onBlur,
    icon,
    inputProps = {},
    className = "",
    placeholder = "mm/dd/yyyy",
    disabled = false,
  } = props;

  const handleChange = (selectedDate: Date | null) => {
    const dateString = !!selectedDate ? mmDdYyyy(selectedDate) : "";
    onChange(dateString);
  };

  /**
   * NB: `className` can be overridden by `inputProps`.
   */

  return (
    <div
      className={`InputDatePicker rounded-md shadow-sm relative ${!!icon ? "has-icons-left" : ""
        }`}
    >
      <DatePicker
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${className}`}
        placeholderText={placeholder}
        disabled={disabled}
        {...inputProps}
      />
      {icon && (
        <IconContainer className="text-cool-gray-500">
          <FAIcon icon={icon} />
        </IconContainer>
      )}
    </div>
  );
};

export interface DatePickerInputProps {
  name: string;
  id?: string;
  placeholder?: string;
  autoFocus?: boolean;
  icon?: IconProp;
  className?: string;
  inputProps?: ReactDatePickerProps;
  disabled?: boolean;
}

export const DatePickerInput: FC<DatePickerInputProps> = (props) => {
  const {
    id,
    name,
    icon,
    autoFocus = false,
    className = "",
    inputProps = {},
    disabled,
  } = props;

  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;

  return (
    <>
      <InputDatePicker
        inputProps={{
          id: id || name,
          name,
          autoFocus,
          ...inputProps,
        }}
        icon={icon}
        className={
          meta && meta.touched && meta.error
            ? `border border-red-500 ${className}`
            : className
        }
        value={value}
        onChange={setValue}
        onBlur={onBlur}
        disabled={disabled}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
};

export interface DatePickerFieldProps extends DatePickerInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const DatePickerField: FC<DatePickerFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <DatePickerInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalDatePickerField: FC<DatePickerFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <DatePickerInput {...rest} />
    </HorizontalField>
  );
};
