import { FC, useState, HTMLAttributes } from "react"
import { Icd10CodeSearchInput } from "components/Icd10CodeSearchInput"
import { ErrorMessage, useField } from "formik";
import { VerticalField, HorizontalField } from "@preferral/ui";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Icd10CodeDiagnosisTextFieldProps {
  id?: string;
  name: string;
  placeholder?: string;
  autoFocus?: boolean;
  icon?: IconProp;
  inputProps?: HTMLAttributes<HTMLInputElement>;
}

export const Icd10CodeDiagnosisTextField: FC<Icd10CodeDiagnosisTextFieldProps> = (props) => {
  const {
    id,
    name,
    placeholder,
    autoFocus,
    inputProps,
    icon
  } = props

  const [field, meta, helpers] = useField(name);
  const { value, onBlur } = field;
  const { setValue } = helpers;


  return (<>
    <Icd10CodeSearchInput
      inputProps={{
        id: id || name,
        placeholder,
        name,
        autoFocus,
        autoComplete: "new",
        ...inputProps,
      }}
      icon={icon}
      onItemSelect={setValue}
    />
    <ErrorMessage
      component="p"
      name={name}
      className="mt-2 text-red-500 text-xs italic"
    />
  </>)
}

interface Icd10CodeDiagnosisFieldProps extends Icd10CodeDiagnosisTextFieldProps {
  label: string;
  indicateOptional?: boolean;
}

export const Icd10CodeDiagnosisField: FC<Icd10CodeDiagnosisFieldProps> = (
  props
) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      label={label}
      indicateOptional={indicateOptional}
    >
      <Icd10CodeDiagnosisTextField
        {...rest}
        placeholder={rest.placeholder || label}
      />
    </VerticalField>
  );
};

export const HorizontalIcd10CodeDiagnosisField: FC<Icd10CodeDiagnosisFieldProps> = (
  props
) => {
  const { label, indicateOptional, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      label={label}
      indicateOptional={indicateOptional}
    >
      <Icd10CodeDiagnosisTextField
        {...rest}
        placeholder={rest.placeholder || label}
      />
    </HorizontalField>
  );
};