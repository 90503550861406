import { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Button, SelectField, StandardOption } from "@preferral/ui";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import toast from "react-hot-toast";

const REROUTE_ECONSULT = gql`
  mutation RerouteEConsult($id: Int!, $specialtyId: Int!) {
    rerouteEconsult(id: $id, specialtyId: $specialtyId) {
      errors {
        key
        message
      }
      econsult {
        id
        status
        specialty {
          id
          displayName
        }
      }
    }
  }
`;

interface MutationData {
  rerouteEconsult: {
    errors?: InputError[];
    econsult?: {
      id: string;
      status: string;
    };
  };
}

interface MutationVariables {
  id: number;
  specialtyId: number;
}

export interface SpecialtyOption extends StandardOption<number> {
  code: string;
}

export interface FormValues {
  specialtyId: number;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    specialtyId: Yup.number()
      .moreThan(0, "A specialty must be selected")
      .required(),
  })
  .required();

interface RerouteEConsultFormProps {
  eConsultId: number;
  initialValues: FormValues;
  specialtyOptions: SpecialtyOption[];
  onClose(): void;
  onSuccess(): void;
};

export const RerouteEConsultForm: FC<RerouteEConsultFormProps> = props => {
  const { eConsultId, initialValues, specialtyOptions, onClose, onSuccess } = props;

  const [rerouteEConsult] = useMutation<MutationData, MutationVariables>(
    REROUTE_ECONSULT
  );

  const onSubmit = useCallback(async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
    const { setStatus, setSubmitting } = formikActions

    const variables = {
      id: eConsultId,
      specialtyId: values.specialtyId
    };

    try {
      const { data } = await rerouteEConsult({ variables });

      if (data?.rerouteEconsult.errors) {
        setStatus({ errors: data.rerouteEconsult.errors });
      } else if (data?.rerouteEconsult.econsult) {
        // it worked...
        toast.success("eConsult Rerouted");
        return onSuccess();
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
    setSubmitting(false);
  }, [eConsultId, rerouteEConsult, onSuccess])

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="border p-4 rounded-lg shadow-xl text-sm">
          <h4 className="text-lg pb-4">Request New Specialty</h4>
          <p className="text-gray-700">
            Select a new specialty to reroute the eConsult. This action
            cannot be undone.
          </p>
          <FormStatusErrors status={status} />
          <div className="pt-4">
            <SelectField
              name="specialtyId"
              label="New Specialty"
              options={specialtyOptions}
              placeholder="Select Specialty"
            />
          </div>
          <div className="flex items-center justify-end pt-4">
            <Button color="gray" size="lg" onClick={onClose} className="mr-2">
              Cancel
            </Button>
            <Button
              type="submit"
              kind="primary"
              color="blue"
              size="lg"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Reroute eConsult
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
