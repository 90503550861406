import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { ReasonForDelayForm } from "./ReasonForDelayForm";

interface ReasonForDelayModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const ReasonForDelayModal: FC<ReasonForDelayModalProps> = (props) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="exclamation"
        title="Reason for Delay"
        onClose={onClose}
      />
      <div className="p-4">
        <ReasonForDelayForm onSuccess={onClose} />
      </div>
    </Modal>
  );
};
