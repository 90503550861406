import { FC, useCallback } from "react";
import * as Yup from "yup";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import { Button, SelectField } from "@preferral/ui";
import { useAppointmentRequest } from "screens/AppointmentRequestShowScreen/AppointmentRequestContext";
import toast from "react-hot-toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import {
  ProviderSelectOption,
  ProviderOptionGqlFields,
  ProviderModel,
} from "components/ProviderOption";

const DEPARTMENT_PROVIDERS = gql`
  query GetDepartmentProviders($departmentId: UUID4!) {
    departmentProviders(departmentId: $departmentId, first: 100) {
      cursor
      endOfList
      items {
        ${ProviderOptionGqlFields}
      }
    }
  }
`;

interface Data {
  departmentProviders: Paginated<ProviderModel>;
}

interface Variables {
  departmentId: string;
}

const ASSIGN_PROVIDER = gql`
  mutation AssignProvider($appointmentRequestId: UUID4!, $providerId: UUID4!) {
    assignAppointmentRequestProvider(
      appointmentRequestId: $appointmentRequestId
      providerId: $providerId
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
      }
    }
  }
`;

interface MutationData {
  assignAppointmentRequestProvider: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appointmentRequestId: string;
  providerId: string;
}

interface FormValues {
  providerId: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    providerId: Yup.string().required("Required"),
  })
  .required();

interface AssignProviderFormProps {
  onSuccess(): void;
}

export const AssignProviderForm: FC<AssignProviderFormProps> = (props) => {
  const { onSuccess } = props;
  const {
    appointmentRequest: { id, departmentId, requestedProvider },
    refetch,
  } = useAppointmentRequest();

  const { data, loading } = useQuery<Data, Variables>(DEPARTMENT_PROVIDERS, {
    variables: { departmentId },
  });

  const [assignProvider] = useMutation<MutationData, MutationVariables>(
    ASSIGN_PROVIDER
  );

  const providerOptions = data?.departmentProviders.items || [];

  const onSubmit = useCallback(
    (values, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;
      setStatus({ errors: null });
      return assignProvider({
        variables: { appointmentRequestId: id, providerId: values.providerId },
      }).then((resp) => {
        if (resp.data?.assignAppointmentRequestProvider.errors) {
          setStatus({
            errors: resp.data.assignAppointmentRequestProvider.errors,
          });
        } else if (
          resp.data?.assignAppointmentRequestProvider.appointmentRequest
        ) {
          // it worked...
          toast.success("Provider assigned!");
          refetch();
          onSuccess();
        }
        setSubmitting(false);
      });
    },
    [id, assignProvider, onSuccess, refetch]
  );

  return (
    <Formik<FormValues>
      initialValues={{ providerId: requestedProvider?.id || "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <SelectField
            name="providerId"
            label="Provider"
            options={providerOptions}
            isLoading={loading}
            getOptionLabel={(p: ProviderModel) => p.nameWithAppellation}
            getOptionValue={(p: ProviderModel) => p.id}
            components={{ Option: ProviderSelectOption }}
          />
          <div className="mt-3 text-center">
            <Button
              type="submit"
              kind="primary"
              color="blue"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Assign Provider
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
