import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  FilterForm,
  FilterField,
  Button,
  StandardOption,
  SelectInput,
} from "@preferral/ui";
import { sortBy } from "@preferral/common";
import uniqBy from "lodash.uniqby";

export const defaultFilter: FilterModel = {
  requestingPhysicianId: undefined,
  statusSet: "OPEN",
};

export interface FilterModel {
  requestingPhysicianId?: "ANY" | number;
  statusSet?: string;
}

const ECONSULT_FILTER_DATA = gql`
  query EconsultFilterData {
    me {
      id
      departments {
        id
        users {
          id
          name
          lastName
          aristamdUserId
          aristamdProviderId
        }
        locations {
          id
          name
          aristamdOrganizationId
        }
      }
    }
    amdSpecialties {
      id
      displayName
    }
  }
`;

interface Data {
  amdSpecialties: {
    id: number;
    displayName: string;
  }[];
  me: {
    id: string;
    departments: {
      id: string;
      users: {
        id: string;
        name: string;
        lastName: string;
        aristamdUserId: number | null;
        aristamdProviderId: number | null;
      }[];
      locations: {
        id: string;
        name: string;
        aristamdOrganizationId: number | null;
      }[];
    }[];
  };
}

/**
 * FilterPanel.
 */

interface FilterPanelProps {
  value: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading?: boolean;
}

export const FilterPanel: FC<FilterPanelProps> = props => {
  const { value, onChange, isLoading } = props;
  const { data, loading } = useQuery(ECONSULT_FILTER_DATA);

  const requestingPhysicianOpts = requestingPhysicianOptions(data);

  return (
    <FilterForm<FilterModel>
      defaultValue={defaultFilter}
      value={value}
      onChange={(values: FilterModel) => onChange(values)}
    >
      <div className="flex items-end gap-2">
        <FilterField
          htmlFor="requestingPhysicianId"
          icon="filter"
          label="Provider"
        >
          <div className="w-48">
            <SelectInput
              name="requestingPhysicianId"
              options={requestingPhysicianOpts}
              isLoading={loading}
              placeholder="Any"
              isClearable
            />
          </div>
        </FilterField>
        <FilterField>
          <Button
            type="submit"
            color="blue"
            disabled={loading || isLoading}
            isLoading={isLoading}
          >
            Apply
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};

function requestingPhysicianOptions(
  data: Data | void
): StandardOption<number>[] {
  if (!data) {
    return [];
  } else {
    const rawOptions = data.me.departments.flatMap(dept =>
      dept.users.filter(user => user.aristamdProviderId)
    );
    return sortBy(uniqBy(rawOptions, "aristamdUserId"), "lastName").map(u => ({
      value: u.aristamdUserId!,
      label: u.name,
    }));
  }
}
