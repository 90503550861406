import React, { useState, useCallback } from "react";
import { FAIcon } from "components/FAIcon";
import { IconName } from "@fortawesome/fontawesome-svg-core";

type AlertProps = {
  message?: string;
  icon?: IconName;
  color?: AppColor;
  onDismiss?: Function;
};

export const Alert: React.FC<AlertProps> = ({
  message = "There was an error. Please try again.",
  icon = "triangle-exclamation",
  onDismiss,
  color = "red",
}) => {

  const [dismissed, setDismissed] = useState(false);

  const dismiss = useCallback(() => {
    setDismissed(true);
    if (onDismiss) {
      onDismiss();
    }
  }, [setDismissed, onDismiss]);

  return dismissed ? null : (
    <div className="_EnvironmentNotice">
      <div className={`p-3 text-${color}-700 bg-${color}-200 rounded-lg shadow-sm`}>
        <div>
          <button className={`float-right hover:bg-${color}-50 pl-2 pr-2`} onClick={dismiss}>
            <FAIcon icon="xmark"></FAIcon>
          </button>
          <div className="ml-3 text-center">
            <p className={`text-${color}-700`}>
              <FAIcon icon={icon} className="mr-2"></FAIcon>
              {message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
