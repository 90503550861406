import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FAIcon } from "@preferral/ui";
import React, { FC } from "react";
import { NavLink } from "react-router-dom";

interface VerticalNavProps {}

export const VerticalNav: FC<VerticalNavProps> = (props) => {
  const { children } = props;

  return (
    <nav className="_VerticalNav" aria-label="Sidebar">
      {children}
    </nav>
  );
};

interface VerticalNavLinkProps {
  to: string;
  exact?: boolean;
  icon?: IconProp;
  className?: string;
  activeClassName?: string;
  badgeText?: string | number;
}

export const VerticalNavLink: FC<VerticalNavLinkProps> = (props) => {
  const {
    to,
    exact = false,
    icon,
    className = "",
    activeClassName = "",
    children,
    badgeText,
  } = props;

  return (
    <NavLink
      to={to}
      exact={exact}
      className={`px-3 py-2 mb-1 transition-fast relative block hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 text-sm text-gray-600 font-medium rounded-lg ${className}`}
      activeClassName={`text-blue-700 bg-blue-200 ${activeClassName}`}
    >
      <span className="rounded-lg absolute inset-0 bg-teal-200 opacity-0" />
      <span className="truncate relative flex items-center">
        {icon ? (
          <span className="text-sm w-6 mr-1 text-center inline-block">
            <FAIcon icon={icon} />
          </span>
        ) : null}
        {children}
        {!!badgeText ? (
          <span className="border border-teal-100 bg-teal-500 text-shadow font-bold inline-block ml-auto px-3 py-0.5 rounded-full shadow-inner text-white text-xs">
            {badgeText}
          </span>
        ) : null}
      </span>
    </NavLink>
  );
};
