import React, { FC } from "react";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import preferralLogoSrc from "images/aristamd_logo.svg";

export const ForgotPasswordScreen: FC = () => {
  return (
    <div className="min-h-screen bg-cool-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto w-48"
          src={preferralLogoSrc}
          alt="Preferral Logo"
        />
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Reset your password
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <ForgotPasswordForm />
      </div>
    </div>
  );
};
