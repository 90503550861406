import React, { FC } from "react";
import { Avatar, Button, FAIcon, useToggle } from "@preferral/ui";
import {
  LocationResultModel,
  NPPESLocationResultModel,
  NPPESProviderLocationResultModel,
  ProviderLocationResultModel,
  SearchResultModel,
  NewAppointmentRequestParams,
  SpecialtyModel,
  RibbonLocation,
} from "./model";
import { addressString } from "./helpers";
import { defaultAvatar } from "components/ProviderSearchSelect";
import { ReactComponent as DistanceIcon } from "images/distance.svg";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";

/**
 * SearchResultCard.
 */

interface SearchResultCardProps {
  specialty: SpecialtyModel;
  searchResult: SearchResultModel;
  onSelect(params: NewAppointmentRequestParams): void;
}

export const SearchResultCard: FC<SearchResultCardProps> = props => {
  const { specialty, searchResult, onSelect } = props;
  const [showMoreLocations, toggleShowMoreLocations] = useToggle(false);

  if (searchResult.provider && searchResult.location) {
    return (
      <ProviderLocationResultCard
        searchResult={searchResult as ProviderLocationResultModel}
        onSelect={onSelect}
      />
    );
  } else if (searchResult.location) {
    return (
      <LocationResultCard
        searchResult={searchResult as LocationResultModel}
        onSelect={onSelect}
      />
    );
  } else if (searchResult.nppesProvider && searchResult.nppesLocations) {
    return (
      <NPPESProviderLocationResultCard
        searchResult={searchResult as NPPESProviderLocationResultModel}
        onSelect={onSelect}
      />
    );
  } else if (searchResult.nppesLocation) {
    return (
      <NPPESLocationResultCard
        searchResult={searchResult as NPPESLocationResultModel}
        onSelect={onSelect}
      />
    );
  } else if (searchResult.ribbonLocation) {
    return (
      <RibbonLocationResultCard
        searchResult={searchResult.ribbonLocation}
        specialty={specialty}
        onSelect={onSelect}
      />
    );
  } else if (searchResult.ribbonProvider) {
    return (
      <div
        className="_RibbonProviderCard relative overflow-hidden bg-gray-50 border rounded-xl shadow-lg text-left"
        style={{ backgroundColor: "#F5F7FA" }}
      >
        <div className="p-4 flex items-center gap-x-4">
          <div className="flex-shrink-0">
            <div className="overflow-visible" style={{ height: 64 }}>
              <Avatar
                size={{ px: 100, className: "" }}
                image={searchResult.ribbonProvider.avatar}
                className="border border-blue-500"
              />
            </div>
          </div>
          <div>
            <p className="_ProviderName font-bold text-gray-800 text-xl">
              {searchResult.ribbonProvider.firstName}{" "}
              {searchResult.ribbonProvider.lastName}
            </p>
            <p>{specialty.name}</p>
          </div>
        </div>
        {/* <p>searchResult.ribbonProvider.</p> */}
        <ul
          style={{ backgroundColor: "#eff1f3" }}
          className="border-b border-t p-3 shadow-inner"
        >
          {searchResult.ribbonProvider.locations.map((location, idx) => {
            const primaryPhone = (location.phoneNumbers || []).find(
              p => p.details === "primary"
            )?.phone.formatted;
            const primaryFax = (location.faxes || []).find(
              p => p.details === "primary"
            )?.fax.formatted;

            const address = [
              location.addressDetails.addressLine1,
              location.addressDetails.addressLine2,
            ]
              .filter(Boolean)
              .join(", ");

            const locality = [
              location.addressDetails.city,
              location.addressDetails.state,
              location.addressDetails.zip,
            ]
              .filter(Boolean)
              .join(", ");

            return (
              <li
                key={location.uuid}
                className={`py-5 ${idx > 0 ? "border-t" : ""} 
                ${
                  idx > 0 ? (showMoreLocations ? "block" : "hidden") : "block"
                }`}
              >
                <div className="flex items-center justify-between">
                  {location.distance ? (
                    <div className="_Distance mr-2 pl-2 pr-4">
                      <DistanceIcon className="h-4" />
                      <p>{location.distance.toFixed(1)} mi</p>
                    </div>
                  ) : null}
                  <div className="flex-grow text-xs flex flex-col space-y-2">
                    <p className="text-sm font-semibold leading-tight">
                      {location.name}
                    </p>
                    <div className="leading-tight py-1">
                      {address ? <p>{address}</p> : null}
                      {locality ? <p>{locality}</p> : null}
                    </div>
                    <div className="-mx-2 flex flex-wrap items-center">
                      {primaryPhone ? (
                        <p className="px-2 flex-shrink-0">
                          <FAIcon icon="phone" className="text-gray-400 mr-1" />
                          {primaryPhone}
                        </p>
                      ) : null}
                      {primaryFax ? (
                        <p className="px-2 flex-shrink-0">
                          <FAIcon icon="fax" className="text-gray-400 mr-1" />
                          {primaryFax}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex-shrink-0 pl-5">
                    <Button
                      type="button"
                      color="blue"
                      kind="secondary"
                      className="border border-blue-500"
                      onClick={() =>
                        onSelect({
                          ribbonProviderNpi: searchResult.ribbonProvider!.npi,
                          ribbonLocationUuid: location.uuid,
                        })
                      }
                    >
                      Send Referral
                    </Button>
                  </div>
                </div>
              </li>
            );
          })}

          {searchResult.ribbonProvider.locations.length > 1 ? (
            <div
              className="text-center text-blue-600"
              onClick={toggleShowMoreLocations}
            >
              {showMoreLocations ? (
                <p className="inline-flex items-center cursor-pointer">
                  View less locations{" "}
                  <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                </p>
              ) : (
                <p className="inline-flex items-center cursor-pointer">
                  View more locations{" "}
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </p>
              )}
            </div>
          ) : null}
        </ul>
        <p className="absolute bottom-0 right-4 text-xs text-gray-300 italic">
          Kind: ribbon
        </p>
      </div>
    );
  }

  return null;
};

/**
 * ProviderLocationResultCard.
 */

interface ProviderLocationResultCardProps {
  searchResult: ProviderLocationResultModel;
  onSelect(params: NewAppointmentRequestParams): void;
}

const ProviderLocationResultCard: FC<ProviderLocationResultCardProps> =
  props => {
    const { searchResult, onSelect } = props;
    const { provider, location } = searchResult;

    return (
      <div className="_ProviderLocationResultCard relative bg-white rounded-lg shadow">
        <div className="flex">
          <div className="flex-shrink-0 p-4">
            <Avatar size="md" image={provider.avatar} />
          </div>
          <div className="flex-1 min-w-0 p-4 text-left">
            <p className="font-extrabold text-gray-800 text-xl">
              {provider.nameWithAppellation}
            </p>
            {provider.primarySpecialty ? (
              <p>{provider.primarySpecialty.name}</p>
            ) : null}
            <p className="font-semibold text-gray-700 text-sm">
              {location.name}
            </p>
            <p className="text-gray-600 text-xs">{addressString(location)}</p>
            <Button
              className="mt-4"
              type="button"
              color="blue"
              onClick={() =>
                onSelect({
                  providerId: provider.id,
                  locationId: location.id,
                })
              }
            >
              Send Referral
            </Button>
          </div>
        </div>
        <p className="absolute bottom-0 right-4 text-xs text-gray-300 italic">
          Kind: platform
        </p>
      </div>
    );
  };

/**
 * LocationResultCard.
 */

interface LocationResultCardProps {
  searchResult: LocationResultModel;
  onSelect(params: NewAppointmentRequestParams): void;
}

const LocationResultCard: FC<LocationResultCardProps> = props => {
  const { searchResult, onSelect } = props;

  const primaryPhone = searchResult.location.phone?.formatted;
  const primaryFax = searchResult.location.fax?.formatted;

  const address = [
    searchResult.location.streetAddress,
    searchResult.location.streetAddressLine2,
  ]
    .filter(Boolean)
    .join(", ");

  const locality = [
    searchResult.location.city,
    searchResult.location.stateAbbreviation,
    searchResult.location.zip,
  ]
    .filter(Boolean)
    .join(", ");

  return (
    <div
      className="_LocationResultCard relative overflow-hidden bg-gray-50 border rounded-xl shadow-lg text-left w-96"
      style={{ backgroundColor: "#F5F7FA" }}
    >
      <div className="p-4 flex items-center gap-x-4">
        <div>
          <p className="_LocationName font-bold text-gray-800 text-xl">
            {searchResult.location.name}
          </p>
        </div>
      </div>
      {/* <p>searchResult.ribbonProvider.</p> */}
      <ul
        style={{ backgroundColor: "#eff1f3" }}
        className="border-b border-t p-3 shadow-inner"
      >
        <li key={searchResult.location.id} className="py-5">
          <div className="flex items-center justify-between">
            {searchResult.location.distance ? (
              <div className="_Distance mr-2 pl-2 pr-4">
                <DistanceIcon className="h-4" />
                <p>{searchResult.location.distance.toFixed(1)} mi</p>
              </div>
            ) : null}
            <div className="flex-grow text-xs flex flex-col space-y-2">
              <div className="leading-tight py-1">
                {address ? <p>{address}</p> : null}
                {locality ? <p>{locality}</p> : null}
              </div>
              <div className="-mx-2 flex flex-wrap items-center">
                {primaryPhone ? (
                  <p className="px-2 flex-shrink-0">
                    <FAIcon icon="phone" className="text-gray-400 mr-1" />
                    {primaryPhone}
                  </p>
                ) : null}
                {primaryFax ? (
                  <p className="px-2 flex-shrink-0">
                    <FAIcon icon="fax" className="text-gray-400 mr-1" />
                    {primaryFax}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex-shrink-0 pl-5">
              <Button
                type="button"
                color="blue"
                kind="secondary"
                className="border border-blue-500"
                onClick={() =>
                  onSelect({ locationId: searchResult.location.id })
                }
              >
                Send Referral
              </Button>
            </div>
          </div>
        </li>
      </ul>
      <p className="absolute bottom-0 right-4 text-xs text-gray-300 italic">
        Kind: platform
      </p>
    </div>
  );
};

interface RibbonLocationResultCardProps {
  searchResult: RibbonLocation;
  specialty: SpecialtyModel;
  onSelect(params: NewAppointmentRequestParams): void;
}

const RibbonLocationResultCard: FC<RibbonLocationResultCardProps> = props => {
  const { searchResult, onSelect } = props;

  const primaryPhone = (searchResult.phoneNumbers || []).find(
    p => p.details === "primary"
  )?.phone.formatted;
  const primaryFax = (searchResult.faxes || []).find(
    p => p.details === "primary"
  )?.fax.formatted;

  const address = [
    searchResult.addressDetails.addressLine1,
    searchResult.addressDetails.addressLine2,
  ]
    .filter(Boolean)
    .join(", ");

  const locality = [
    searchResult.addressDetails.city,
    searchResult.addressDetails.state,
    searchResult.addressDetails.zip,
  ]
    .filter(Boolean)
    .join(", ");

  return (
    <div
      className="_RibbonLocationCard relative overflow-hidden bg-gray-50 border rounded-xl shadow-lg text-left"
      style={{ backgroundColor: "#F5F7FA" }}
    >
      <div className="p-4 flex items-center gap-x-4">
        <div>
          <p className="_LocationName font-bold text-gray-800 text-xl">
            {searchResult.name}
          </p>
        </div>
      </div>
      {/* <p>searchResult.ribbonProvider.</p> */}
      <ul
        style={{ backgroundColor: "#eff1f3" }}
        className="border-b border-t p-3 shadow-inner"
      >
        <li key={searchResult.uuid} className="py-5">
          <div className="flex items-center justify-between">
            {searchResult.distance ? (
              <div className="_Distance mr-2 pl-2 pr-4">
                <DistanceIcon className="h-4" />
                <p>{searchResult.distance.toFixed(1)} mi</p>
              </div>
            ) : null}
            <div className="flex-grow text-xs flex flex-col space-y-2">
              <div className="leading-tight py-1">
                {address ? <p>{address}</p> : null}
                {locality ? <p>{locality}</p> : null}
              </div>
              <div className="-mx-2 flex flex-wrap items-center">
                {primaryPhone ? (
                  <p className="px-2 flex-shrink-0">
                    <FAIcon icon="phone" className="text-gray-400 mr-1" />
                    {primaryPhone}
                  </p>
                ) : null}
                {primaryFax ? (
                  <p className="px-2 flex-shrink-0">
                    <FAIcon icon="fax" className="text-gray-400 mr-1" />
                    {primaryFax}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex-shrink-0 pl-5">
              <Button
                type="button"
                color="blue"
                kind="secondary"
                className="border border-blue-500"
                onClick={() =>
                  onSelect({ ribbonLocationUuid: searchResult.uuid })
                }
              >
                Send Referral
              </Button>
            </div>
          </div>
        </li>
      </ul>
      <p className="absolute bottom-0 right-4 text-xs text-gray-300 italic">
        Kind: ribbon
      </p>
    </div>
  );
};

/**
 * NPPESProviderLocationResultCard.
 */

interface NPPESProviderLocationResultCardProps {
  searchResult: NPPESProviderLocationResultModel;
  onSelect(params: NewAppointmentRequestParams): void;
}

const NPPESProviderLocationResultCard: FC<NPPESProviderLocationResultCardProps> =
  props => {
    const { searchResult, onSelect } = props;
    const { nppesProvider, nppesLocations } = searchResult;

    return (
      <div
        className="_NPPESProviderLocationResultCard relative overflow-hidden bg-gray-50 border rounded-xl shadow-lg text-left"
        style={{ backgroundColor: "#F5F7FA" }}
      >
        <div className="p-4 flex items-center gap-x-4">
          <div className="flex-shrink-0">
            <div className="overflow-visible" style={{ height: 64 }}>
              <Avatar
                size={{ px: 100, className: "" }}
                image={nppesProvider.avatar}
                className="border border-blue-500"
              />
            </div>
          </div>
          <div>
            <p className="_ProviderName font-bold text-gray-800 text-xl">
              {nppesProvider.nameWithAppellation}
            </p>
            <p>{nppesProvider.primarySpecialty?.name}</p>
          </div>
        </div>
        {/* <p>searchResult.ribbonProvider.</p> */}
        <ul
          style={{ backgroundColor: "#eff1f3" }}
          className="border-b border-t p-3 shadow-inner"
        >
          {nppesLocations.map((nppesLocation, idx) => {
            const streetAddress = [
              nppesLocation.streetAddress,
              nppesLocation.streetAddressLine2,
            ]
              .filter(Boolean)
              .join(", ");
            const cityStateZip = [
              nppesLocation.city,
              nppesLocation.stateAbbreviation,
              nppesLocation.zip,
            ]
              .filter(Boolean)
              .join(", ");
            return (
              <li
                key={nppesLocation.id}
                className={`py-5 ${idx > 0 ? "border-t" : ""}`}
              >
                <div className="flex items-center justify-between">
                  {nppesLocation.distance ? (
                    <div className="_Distance mr-2 pl-2 pr-4">
                      <DistanceIcon className="h-4" />
                      <p>{nppesLocation.distance.toFixed(1)} mi</p>
                    </div>
                  ) : null}
                  <div className="flex-grow text-xs flex flex-col space-y-2">
                    <p className="text-sm font-semibold leading-tight">
                      {nppesLocation.name}
                    </p>
                    <div className="leading-tight py-1">
                      {streetAddress ? <p>{streetAddress}</p> : null}
                      {cityStateZip ? <p>{cityStateZip}</p> : null}
                    </div>
                    <div className="-mx-2 flex flex-wrap items-center">
                      {nppesLocation.phone ? (
                        <p className="px-2 flex-shrink-0">
                          <FAIcon icon="phone" className="text-gray-400 mr-1" />
                          {nppesLocation.phone.formatted}
                        </p>
                      ) : null}
                      {nppesLocation.fax ? (
                        <p className="px-2 flex-shrink-0">
                          <FAIcon icon="fax" className="text-gray-400 mr-1" />
                          {nppesLocation.fax.formatted}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex-shrink-0 pl-5">
                    <Button
                      type="button"
                      color="blue"
                      kind="secondary"
                      className="border border-blue-500"
                      onClick={() =>
                        onSelect({
                          nppesProviderId: nppesProvider.id,
                          nppesLocationId: nppesLocation.id,
                        })
                      }
                    >
                      Send Referral
                    </Button>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <p className="absolute bottom-0 right-4 text-xs text-gray-300 italic">
          Kind: NPPES
        </p>
      </div>
    );
  };

/**
 * NPPESLocationResultCard.
 */

interface NPPESLocationResultCardProps {
  searchResult: NPPESLocationResultModel;
  onSelect(params: NewAppointmentRequestParams): void;
}

const NPPESLocationResultCard: FC<NPPESLocationResultCardProps> = props => {
  const { searchResult, onSelect } = props;

  const primaryPhone = searchResult.nppesLocation.phone?.formatted;
  const primaryFax = searchResult.nppesLocation.fax?.formatted;

  const address = [
    searchResult.nppesLocation.streetAddress,
    searchResult.nppesLocation.streetAddressLine2,
  ]
    .filter(Boolean)
    .join(", ");

  const locality = [
    searchResult.nppesLocation.city,
    searchResult.nppesLocation.stateAbbreviation,
    searchResult.nppesLocation.zip,
  ]
    .filter(Boolean)
    .join(", ");

  return (
    <div
      className="_NPPESLocationResultCard relative overflow-hidden bg-gray-50 border rounded-xl shadow-lg text-left w-96"
      style={{ backgroundColor: "#F5F7FA" }}
    >
      <div className="p-4 flex items-center gap-x-4">
        <div>
          <p className="_LocationName font-bold text-gray-800 text-xl">
            {searchResult.nppesLocation.name}
          </p>
        </div>
      </div>
      {/* <p>searchResult.ribbonProvider.</p> */}
      <ul
        style={{ backgroundColor: "#eff1f3" }}
        className="border-b border-t p-3 shadow-inner"
      >
        <li key={searchResult.nppesLocation.id} className="py-5">
          <div className="flex items-center justify-between">
            {searchResult.nppesLocation.distance ? (
              <div className="_Distance mr-2 pl-2 pr-4">
                <DistanceIcon className="h-4" />
                <p>{searchResult.nppesLocation.distance.toFixed(1)} mi</p>
              </div>
            ) : null}
            <div className="flex-grow text-xs flex flex-col space-y-2">
              <div className="leading-tight py-1">
                {address ? <p>{address}</p> : null}
                {locality ? <p>{locality}</p> : null}
              </div>
              <div className="-mx-2 flex flex-wrap items-center">
                {primaryPhone ? (
                  <p className="px-2 flex-shrink-0">
                    <FAIcon icon="phone" className="text-gray-400 mr-1" />
                    {primaryPhone}
                  </p>
                ) : null}
                {primaryFax ? (
                  <p className="px-2 flex-shrink-0">
                    <FAIcon icon="fax" className="text-gray-400 mr-1" />
                    {primaryFax}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex-shrink-0 pl-5">
              <Button
                type="button"
                color="blue"
                kind="secondary"
                className="border border-blue-500"
                onClick={() =>
                  onSelect({ nppesLocationId: searchResult.nppesLocation.id })
                }
              >
                Send Referral
              </Button>
            </div>
          </div>
        </li>
      </ul>
      <p className="absolute bottom-0 right-4 text-xs text-gray-300 italic">
        Kind: NPPES
      </p>
    </div>
  );
};
