import React from "react";
import { Link } from "react-router-dom";
import { FAIcon } from "components/FAIcon";
// import "./ConfirmationSuccess.scss";

type ConfirmationSuccessProps = {};

const ConfirmationSuccess: React.FC<ConfirmationSuccessProps> = () => {
  return (
    <div className="ConfirmationSuccess box" style={{ padding: "2.5rem" }}>
      <div className="ConfirmationSuccess__check-container">
        <FAIcon icon="check" />
      </div>
      <h1 className="title is-4">Account Email Verified!</h1>
      <h2 className="subtitle is-5" style={{ marginTop: "1rem" }}>
        <Link to="/auth/sign_in" style={{ textDecoration: "underline" }}>
          Click here
        </Link>{" "}
        to sign in to your account.
      </h2>
    </div>
  );
};

export { ConfirmationSuccess };
