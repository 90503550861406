import { FC } from "react";
import DOMPurify from "dompurify";

interface HtmlSanitizerProps {
  html: string;
  options?: DOMPurify.Config;
}
export const HtmlSanitizer: FC<HtmlSanitizerProps> = ({ html, options }) => {
  return <div className="html-sanitizer-text" dangerouslySetInnerHTML={sanitize(html, options)} />;
};

function sanitize(htmlContent: string, options?: DOMPurify.Config) {
  return { __html: DOMPurify.sanitize(htmlContent, { ...options }) as string };
}
