import { CircleIcon } from "@preferral/ui";
import React, { FC } from "react";
import { LocationModel } from "./model";

export interface LocationOptionProps {
  location: LocationModel;
}

export const LocationOption: FC<LocationOptionProps> = (props) => {
  const { location } = props;
  return (
    <div className="_LocationOption flex items-center">
      <CircleIcon icon="map-marker-alt" />
      <div className="ml-3">
        <p className="leading-tight text-cool-gray-900 font-semibold">
          {location.name}
        </p>
        <div className="mt-1 leading-none text-cool-gray-700 text-xs">
          <p>
            {location.streetAddress}
            {location.streetAddressLine2 && `, ${location.streetAddressLine2}`}
          </p>
          <p>
            {location.city}, {location.stateAbbreviation}, {location.zip}
          </p>
        </div>
      </div>
    </div>
  );
};
