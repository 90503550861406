import { FC, useCallback } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { ToggleSwitch, Spinner } from "@preferral/ui";
import toast from "react-hot-toast";
import { ScreenTitle } from "context/ScreenTitle";
import { GradientHeader } from "../../GradientHeader";
import { CommunicationPreference, CommunicationType, MergedPreference } from "./model";

import {
  groupByMedium,
  mediumLabel,
  mergeDefaultsAndPreferences,
  preferenceKey,
} from "./helpers";
import { useSet } from "hooks/useSet";
import { useFeatureFlags } from "hooks/useFeatureFlags";

const CURRENT_USER_NOTIFICATION_PREFERENCES = gql`
  query CurrentUserNotificationPreferences {
    communicationTypes(ownerType: "user") {
      medium
      notification
      label
      description
      defaultValue
    }
    me {
      id
      communicationPreferences {
        medium
        notification
        value
      }
    }
  }
`;

interface Data {
  communicationTypes: CommunicationType[];
  me: {
    id: string;
    communicationPreferences: CommunicationPreference[];
  };
}

const UPDATE_NOTIFICATION_PREFERENCE = gql`
  mutation UpdateUserNotificationPreference(
    $medium: String!
    $notification: String!
    $value: Boolean!
  ) {
    updateCurrentUserCommunicationPreference(
      medium: $medium
      notification: $notification
      value: $value
    )
  }
`;

interface MutationData {
  updateCurrentUserCommunicationPreference: string;
}

interface MutationVariables {
  medium: string;
  notification: string;
  value: boolean;
}

function hideUnavailableFeatures(preferences: MergedPreference[], hasFeature: (feature: string) => boolean): MergedPreference[] {
  const featureNotifications: Record<string, string[]> = {
    patient_forms: ["patient_packet_submission_received", "free_patient_form_submission_received"],
  }

  /**
   * NB: Looks like:
   * {
   *    "patient_packet_submission_received": "patient_forms",
   *    "free_patient_form_submission_received": "patient_forms",
   *    "notification_type3": "feature_flag",
   *    ...
   * }
   */

  const notificationToFeature = Object.keys(featureNotifications).reduce((acc, featureName) => {
    return featureNotifications[featureName].reduce((acc, notifType) => ({...acc, [notifType]: featureName}), acc)
  }, {});

  return preferences.filter(p => {
    const featureName = notificationToFeature[p.notification];
    return !featureName || hasFeature(featureName);
  });
}

/**
 * NotificationPreferences.
 */
interface NotificationPreferencesProps { }

export const NotificationPreferences: FC<NotificationPreferencesProps> = () => {
  const { hasFeature } = useFeatureFlags();
  const { data, loading, error } = useQuery<Data>(
    CURRENT_USER_NOTIFICATION_PREFERENCES
  );

  const preferences =
    data &&
    groupByMedium(
      hideUnavailableFeatures(
        mergeDefaultsAndPreferences(
          data.communicationTypes,
          data.me.communicationPreferences
        ),
        hasFeature
      )
    );

  const {
    add: addSaving,
    remove: removeSaving,
    has: isSaving,
  } = useSet<string>();

  const [updateNotificationPreference] = useMutation<
    MutationData,
    MutationVariables
  >(UPDATE_NOTIFICATION_PREFERENCE);

  const updatePreference = useCallback(
    async (newCommPref: CommunicationPreference) => {
      const key = preferenceKey(newCommPref);

      addSaving(key);

      try {
        await updateNotificationPreference({
          variables: newCommPref,
          refetchQueries: [{ query: CURRENT_USER_NOTIFICATION_PREFERENCES }],
        });
        toast.success("Updated communication preference.");
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
      }
      removeSaving(key);
    },
    [addSaving, removeSaving, updateNotificationPreference]
  );

  return (
    <>
      <ScreenTitle title="Settings » Notifications" />
      <div className="bg-white box rounded-lg shadow-lg">
        <GradientHeader
          icon="bell"
          title="Notification Preferences"
          subtitle="Configure which notifications you'd like to receive."
        />
        <div className="p-8">
          {loading ? (
            <div className="p-12 text-center">
              <Spinner />
            </div>
          ) : error || !preferences ? (
            <p>Failed to load</p>
          ) : (
            <>
              {preferences.map(medium => (
                <div key={medium.medium} className="mb-8">
                  <h3 className="capitalize text-gray-700 font-semibold max-w-3xl mb-4 mx-auto text-2xl w-full">
                    {mediumLabel(medium.medium)}
                  </h3>
                  {medium.items.map(item => (
                    <form
                      key={`${item.medium}-${item.notification}`}
                      className="border-b max-w-3xl mb-2 mx-auto pb-2 w-full"
                    >
                      <div className="-mx-6 flex items-center p-4">
                        <div className="flex-1 px-6">
                          <label className="font-semibold text-gray-900">
                            {item.label}
                          </label>
                          <p className="mt-3 text-gray-700 text-sm">
                            {item.description}
                          </p>
                        </div>
                        <div className="px-6 w-40">
                          <ToggleSwitch
                            id={`_PreferenceToggle-${preferenceKey(item)}`}
                            checked={item.value}
                            onChange={(enabled: boolean) =>
                              updatePreference({ ...item, value: enabled })
                            }
                            loading={isSaving(preferenceKey(item))}
                            showLabel
                          />
                        </div>
                      </div>
                    </form>
                  ))}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};
