import React, { FC, useState } from "react";
import { ProviderReferenceModel } from "components/ProviderSearchSelect";
import { Props as SelectProps, components, OptionProps } from "react-select";
import { LocationModel, LocationReferenceModel } from "./model";
import { LocationOption } from "./LocationOption";
import { Button } from "@preferral/ui";
import { CreateUnlistedLocationDrawer } from "./CreateUnlistedLocationDrawer";
import { ProviderLocationSelectInput } from "./ProviderLocationSelectInput";
import { SelectedLocation } from "./SelectedLocation";

/**
 * LocationSelectOption.
 */
type LocationSelectOption = OptionProps<LocationModel, false>;

const LocationSelectOption: FC<LocationSelectOption> = (props) => {
  return (
    <components.Option {...props}>
      <LocationOption location={props.data} />
    </components.Option>
  );
};

/**
 * ProviderLocationSelect.
 */

type ActiveModal = "CREATE_UNLISTED_LOCATION";

export interface ProviderLocationSelectProps
  extends Pick<SelectProps<LocationModel>, "id" | "onBlur" | "placeholder"> {
  provider: ProviderReferenceModel | null;
  value?: LocationReferenceModel | null;
  onChange(selectedValue: LocationReferenceModel | null): void;
  allowCreation?: boolean;
  inputProps?: SelectProps<LocationModel>;
  disabled?: boolean;
}

export const ProviderLocationSelect: FC<ProviderLocationSelectProps> = (
  props
) => {
  const {
    onChange,
    allowCreation = false,
    provider,
    value = null,
    onBlur,
    inputProps = {},
    disabled,
  } = props;

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const providers: ProviderReferenceModel[] = [provider].filter(
    Boolean
  ) as ProviderReferenceModel[];

  return (
    <div className="ProviderLocationSelect">
      {allowCreation ? (
        <CreateUnlistedLocationDrawer
          providers={providers}
          isOpen={activeModal === "CREATE_UNLISTED_LOCATION"}
          onClose={() => setActiveModal(null)}
          onSuccess={onChange}
        />
      ) : null}
      {!!value ? (
        <div className="py-2">
          <SelectedLocation
            location={value}
            onClear={() => onChange(null)}
            isClearable
          />
        </div>
      ) : (
        <>
          <ProviderLocationSelectInput
            provider={provider}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            inputProps={inputProps}
          />
          {allowCreation ? (
            <div className="flex items-center p-1">
              <p className="mr-2 text-cool-gray-700 text-xs">
                Couldn't find the location?
              </p>
              <Button
                type="button"
                kind="tertiary"
                size="xs"
                color="blue"
                onClick={() =>
                  !disabled && setActiveModal("CREATE_UNLISTED_LOCATION")
                }
                disabled={disabled}
              >
                Enter a Location
              </Button>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
