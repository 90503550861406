import { FC } from "react";
import { Link } from "react-router-dom";
import ExclamationCircleIcon from "@heroicons/react/solid/ExclamationCircleIcon";
import { FAIcon, TD } from "@preferral/ui";
import { AppropriatenessModel, EconsultModel } from "./model";
import { StatusBadge } from "./StatusBadge";
import { distanceInWords } from "lib/dateFormatters";
import { useAmdSettings } from "hooks/useAmdSettings";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const dispositions: Record<AppropriatenessModel, [IconProp, string]> = {
  face_to_face: ["share", "Refer for In-Person"],
  ok_to_treat: [["far", "thumbs-up"], "Treatment Plan"],
  pending_requester_feedback: ["comments", "Follow Up Required"],
  wrong_specialty: ["exclamation-circle", "Wrong Specialty"],
};

/**
 * Disposition.
 */

interface DispositionProps {
  appropriateness: AppropriatenessModel;
}

const Disposition: FC<DispositionProps> = props => {
  const { appropriateness } = props;

  const [icon, label] = dispositions[appropriateness];

  return (
    <p className="text-indigo-800 font-semibold">
      <FAIcon icon={icon} className="text-indigo-500 mr-2" />
      {label}
    </p>
  );
};

/**
 * EConsultRow.
 */

interface EConsultRowProps {
  eConsult: EconsultModel;
}

export const EConsultRow: FC<EConsultRowProps> = props => {
  const { eConsult } = props;
  // Gets fhir id from eConsult external identifiers
  const fhirId = eConsult.aristamdFhirId?.identifier;

  const { hasFeature } = useAmdSettings();

  const link =
    eConsult.statusSet === "draft" && fhirId
      ? `/o/econsults/${fhirId}/edit`
      : `/o/econsults/${eConsult.id}`;

  return (
    <tr
      key={eConsult.id}
      className={`_EConsultRow ${
        eConsult.statusSet === "draft" ? "bg-blue-50" : "bg-white"
      }`}
    >
      <TD>
        <div className="flex items-center">
          <Link to={link} className="font-bold text-blue-700">
            {eConsult.patient.name}
          </Link>
        </div>
        <p className="text-gray-700 text-xs font-semibold">
          {eConsult.specialty?.displayName || "No Specialty"} •{" "}
          {eConsult.panelist ? (
            eConsult.panelist.user.name
          ) : (
            <span className="italic text-gray-500 font-normal">Unassigned</span>
          )}
        </p>
        <p className="text-gray-500 text-xs">
          Requested by {eConsult.requestingPhysician.name}
        </p>
        <p className="text-gray-500 text-xs italic">
          Updated {distanceInWords(eConsult.updatedAt)} ago
        </p>
      </TD>
      <TD>
        <StatusBadge eConsult={eConsult} />

        {eConsult.additionalDetails?.uploaded_to_ehr === false &&
        hasFeature("manual_ehr_notification_tracking") ? (
          <span className="flex text-sm font-semibold items-center py-1 text-yellow-400">
            <ExclamationCircleIcon className="w-4 h-4 mr-2 text-yellow-400" />
            Not uploaded to EHR
          </span>
        ) : null}
      </TD>
      <TD>
        {eConsult.answer?.appropriateness ? (
          <Disposition appropriateness={eConsult.answer.appropriateness} />
        ) : null}
      </TD>
    </tr>
  );
};
