import React, { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField, TextMaskField, SelectField, Button } from "@preferral/ui";
import { ProviderReferenceModel } from "./types";
import { analytics } from "../../lib/analytics";

const npiMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

const LIST_SPECIALTIES = gql`
  query ListSpecialties {
    specialties(filter: { curated: true }) {
      id
      name
      taxonomyCode
    }
  }
`;

interface Data {
  specialties: {
    id: string;
    name: string;
    taxonomyCode: string;
  }[];
}

const MUTATION = gql`
  mutation CreateUnlistedProvider($input: UnlistedProviderInput!) {
    createUnlistedProvider(input: $input) {
      errors {
        key
        message
      }
      unlistedProvider {
        id
      }
    }
  }
`;

interface MutationData {
  createUnlistedProvider: {
    errors?: InputError[];
    unlistedProvider?: UnlistedProviderModel;
  };
}

interface MutationVariables {
  input: FormValues;
}

interface UnlistedProviderModel {
  id: string;
}

interface FormValues {
  npi: string;
  firstName: string;
  lastName: string;
}

interface UnlistedProviderFormProps {
  onSuccess(provider: ProviderReferenceModel): void;
}

export const UnlistedProviderForm: FC<UnlistedProviderFormProps> = props => {
  const { onSuccess } = props;
  const { data, loading } = useQuery<Data>(LIST_SPECIALTIES);
  const specialtyOptions =
    data?.specialties.map(s => ({
      value: s.id,
      label: s.name,
    })) || [];

  const [createUnlistedProvider] = useMutation<MutationData, MutationVariables>(
    MUTATION
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      createUnlistedProvider({
        variables: { input: values },
      }).then(
        resp => {
          if (resp?.data?.createUnlistedProvider.errors) {
            setStatus({ errors: resp.data.createUnlistedProvider.errors });
          } else if (resp?.data?.createUnlistedProvider.unlistedProvider) {
            // it worked
            // toast.success("Success");
            // closeModal();
            analytics.track("Create Provider", {
              provider_name: `${values.firstName} ${values.lastName}`,
              provider_npi: values.npi,
              page_url: window.location.href,
            });
            const { unlistedProvider } = resp.data.createUnlistedProvider;
            const providerReference: ProviderReferenceModel = {
              kind: "unlisted",
              id: unlistedProvider.id,
            };
            return onSuccess(providerReference);
          }
          setSubmitting(false);
        },
        _rej => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong" }],
          });
          setSubmitting(false);
        }
      );
    },
    [createUnlistedProvider, onSuccess]
  );

  return (
    <div className="_UnlistedProviderForm p-4">
      <p className="bg-cool-gray-100 p-2 rounded-lg text-sm">
        If you couldn't find the referring provider in the search dropdown, you
        can enter him/her here. After entering, the provider will be available
        in the search dropdown for future referrals
      </p>
      <Formik<FormValues>
        initialValues={{
          npi: "",
          firstName: "",
          lastName: "",
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().trim().required("Required"),
          lastName: Yup.string().trim().required("Required"),
          npi: Yup.string()
            .trim()
            .matches(/^\d{10}$/, "NPI should be a 10-digit number")
            .required(),
          primarySpecialtyId: Yup.string().required("Required"),
        })}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="mt-5">
            <FormStatusErrors status={status} />
            <div className="mt-3 sm:grid sm:grid-cols-2 sm:gap-3">
              <TextField
                name="firstName"
                label="Provider First Name"
                autoFocus
              />
              <div className="mt-3 sm:mt-0">
                <TextField name="lastName" label="Provider Last Name" />
              </div>
            </div>

            <div className="mt-3">
              <TextMaskField
                name="npi"
                label="NPI"
                mask={npiMask}
                placeholder="NPI"
              />
            </div>

            <div className="mt-3">
              <SelectField
                name="primarySpecialtyId"
                options={specialtyOptions}
                isLoading={loading}
                label="Specialty"
              />
            </div>
            <div className="flex items-center justify-end mt-3 py-4">
              <Button
                type="submit"
                kind="primary"
                color="blue"
                disabled={isSubmitting}
              >
                Add Provider
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
