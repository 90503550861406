import { FC, useCallback } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import * as Yup from "yup";
import { Formik, FieldArray } from "formik";
import toast from "react-hot-toast";
import ArrowUpIcon from '@heroicons/react/outline/ArrowUpIcon'
import ArrowDownIcon from '@heroicons/react/outline/ArrowDownIcon'
import {
  Button,
  FAIcon,
  TextField,
  TextAreaField,
  VerticalField,
  SelectInput,
  Spinner,
} from "@preferral/ui";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { PatientFormSelectOption } from "components/PatientFormOption";

const EDIT_PATIENT_PACKET = gql`
  query EditPatientPacket($patientPacketId: UUID4!, $departmentId: UUID4!) {
    patientPacket(id: $patientPacketId) {
      id
      title
      noticeText
      formOrder
      patientForms {
        id
        title
        insertedAt
        updatedAt
      }
    }
    department(id: $departmentId) {
      id
      name
      patientForms {
        id
        title
        insertedAt
        updatedAt
      }
    }
  }
`;

interface Data {
  patientPacket: PatientPacketModel;
  department: DepartmentModel;
}

interface Variables {
  patientPacketId: string;
  departmentId: string;
}

interface PatientPacketModel {
  id: string;
  title: string;
  noticeText: null | string;
  patientForms: PatientFormModel[];
}

interface DepartmentModel {
  id: string;
  name: string;
  patientForms: PatientFormModel[];
}

interface PatientFormModel {
  id: string;
  title: string;
  insertedAt: string;
  updatedAt: string;
}


const UPDATE_PATIENT_PACKET = gql`
  mutation UpdatePatientPacket($id: UUID4!, $input: PatientPacketInput!) {
    updatePatientPacket(id: $id, input: $input) {
      errors {
        key
        message
      }
      patientPacket {
        id
      }
    }
  }
`;

interface MutationData {
  updatePatientPacket: {
    errors?: InputError[]
    patientPacket?: {
      id: string;
    };
  };
}

interface MutationVariables {
  id: string;
  input: PatientPacketInput;
}

interface PatientPacketInput {
  title: string;
  noticeText: string;
  patientFormIds: (string | void)[];
}

type FormValues = PatientPacketInput;

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    title: Yup.string().required("Required"),
    noticeText: Yup.string(),
    patientFormIds: Yup.array(Yup.string()).required("Required"),
  })
  .required();

interface EditPacketFormProps {
  patientPacketId: string;
  departmentId: string;
  onSuccess(): void;
};

export const EditPacketForm: FC<EditPacketFormProps> = props => {
  const { patientPacketId, departmentId, onSuccess } = props;

  const { data, loading, error } = useQuery<Data, Variables>(EDIT_PATIENT_PACKET, { variables: { patientPacketId, departmentId }, fetchPolicy: "network-only" });

  const patientFormOptions = data?.department.patientForms || [];

  const [updatePacket] = useMutation<MutationData, MutationVariables>(UPDATE_PATIENT_PACKET);

  const onSubmit = useCallback((values, formikActions) => {
    const { setStatus, setSubmitting } = formikActions;

    const input: PatientPacketInput = {
      ...values,
      patientFormIds: values.patientFormIds.filter(Boolean)
    };

    return updatePacket({ variables: { id: patientPacketId, input } }).then(
      (resp) => {
        if (resp.data?.updatePatientPacket.errors) {
          setStatus({ errors: resp.data.updatePatientPacket.errors });
        } else if (resp.data?.updatePatientPacket.patientPacket) {
          // it worked...
          toast.success("Patient packet updated!");
          return onSuccess();
        }
        setSubmitting(false);
      }
    )
  }, [updatePacket, onSuccess, patientPacketId, departmentId]);

  return (
    <div className="_EditPacketForm">
      {
        loading ? (
          <div className="text-center p-8">
            <Spinner />
          </div>
        ) : error || !data?.patientPacket ? (
          <p>Failed to load</p>
        ) : (
          <Formik<FormValues>
            initialValues={{
              title: data.patientPacket.title,
              noticeText: data.patientPacket.noticeText || "",
              patientFormIds: data.patientPacket.patientForms.map(pf => pf.id)
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormStatusErrors status={status} />

                <TextField name="title" label="Title" />

                <div className="mt-3">
                  <TextAreaField name="noticeText" label="Pop-Up Notice Text" indicateOptional />
                </div>

                <div className="mt-3">
                  <VerticalField label="Patient Forms">
                    <FieldArray
                      name="patientFormIds"
                      render={(arrayHelpers) => {
                        return (
                          <>
                            {values.patientFormIds.map((formId, idx) => (
                              <div key={formId || idx} className="flex gap-2 pb-2">
                                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                  <button
                                    type="button"
                                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-25 disabled:cursor-not-allowed focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                    disabled={idx === 0}
                                    onClick={() => arrayHelpers.move(idx, idx - 1)}
                                  >
                                    <span className="sr-only">Move Up</span>
                                    <ArrowUpIcon className="h-4 w-4" aria-hidden="true" />
                                  </button>
                                  <button
                                    type="button"
                                    className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-25 disabled:cursor-not-allowed focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                    disabled={idx === values.patientFormIds.length - 1}
                                    onClick={() => arrayHelpers.move(idx, idx + 1)}
                                  >
                                    <span className="sr-only">Move Down</span>
                                    <ArrowDownIcon className="h-4 w-4" aria-hidden="true" />
                                  </button>
                                </span>

                                <div className="flex-grow">
                                  <SelectInput
                                    name={`patientFormIds.${idx}`}
                                    options={patientFormOptions}
                                    isLoading={loading}
                                    getOptionLabel={(o: PatientFormModel) => o.title}
                                    getOptionValue={(o: PatientFormModel) => o.id}
                                    components={{ Option: PatientFormSelectOption }}
                                  />
                                </div>
                                {idx > 0 ? (
                                  <Button
                                    type="button"
                                    kind="tertiary"
                                    size="xs"
                                    className="border"
                                    color="red"
                                    onClick={() => arrayHelpers.remove(idx)}
                                  >
                                    <FAIcon icon="times" />
                                  </Button>
                                ) : null}
                              </div>
                            ))}
                            <div className="text-center">
                              <Button
                                type="button"
                                color="blue"
                                kind="tertiary"
                                size="xs"
                                onClick={() => arrayHelpers.push("")}
                              >
                                <span className="mr-2">
                                  <FAIcon icon="plus" />
                                </span>
                                Add Form
                              </Button>
                            </div>
                          </>
                        )
                      }}
                    />
                  </VerticalField>
                </div>

                <div className="mt-3 text-center">
                  <Button
                    type="submit"
                    color="blue"
                    kind="primary"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Update Patient Packet
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )
      }
    </div>
  );
};
