import React, { FC, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Button,
  SelectField,
  SingleCheckboxInput,
  StandardOption,
} from "@preferral/ui";
import toast from "react-hot-toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { analytics } from "lib/analytics";

const ORGANIZATION_DEPARTMENTS = gql`
  query OrganizationDepartments {
    me {
      id
      organization {
        id
        departments {
          value: id
          label: name
        }
      }
    }
  }
`;

interface Data {
  me: {
    id: string;
    organization: {
      id: string;
      departments: StandardOption[];
    };
  };
}

const CREATE_DEPARTMENT_PROVIDER = gql`
  mutation CreateDepartmentProvider(
    $provider: ProviderReferenceInput!
    $departmentId: UUID4!
    $input: DepartmentProviderInput!
  ) {
    assignProviderToDepartment(
      provider: $provider
      departmentId: $departmentId
      input: $input
    ) {
      errors {
        key
        message
      }
      departmentProvider {
        id
      }
    }
  }
`;

interface MutationData {
  assignProviderToDepartment: {
    errors?: InputError[];
    departmentProvider?: {
      id: string;
    };
  };
}

interface MutationVariables {
  provider: { kind: "platform"; id: string };
  departmentId: string;
  input: DepartmentProviderInput;
}

interface DepartmentProviderInput {
  acceptingNewPatients: boolean;
}

interface FormValues {
  departmentId: string;
  acceptingNewPatients: boolean;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    departmentId: Yup.string().required("Required"),
    acceptingNewPatients: Yup.boolean().required(),
  })
  .required();

const initialValues: FormValues = {
  departmentId: "",
  acceptingNewPatients: true,
};

interface AddDepartmentFormProps {
  providerId: string;
  excludeDepartmentIds?: string[];
  onSuccess(): void;
}

export const AddDepartmentForm: FC<AddDepartmentFormProps> = (props) => {
  const { providerId, excludeDepartmentIds = [], onSuccess } = props;

  const { data, loading } = useQuery<Data>(ORGANIZATION_DEPARTMENTS);

  const departmentOptions =
    data?.me.organization.departments.filter(
      (d) => !excludeDepartmentIds.includes(d.value)
    ) || [];

  const [addDepartment] = useMutation<MutationData, MutationVariables>(
    CREATE_DEPARTMENT_PROVIDER
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting, resetForm } = formikActions;

      setStatus({ errors: null });

      const input: DepartmentProviderInput = {
        acceptingNewPatients: values.acceptingNewPatients,
      };

      try {
        const { data } = await addDepartment({
          variables: {
            provider: { kind: "platform", id: providerId },
            departmentId: values.departmentId,
            input,
          },
        });

        if (data?.assignProviderToDepartment.errors) {
          setStatus({
            errors: data.assignProviderToDepartment.errors,
          });
        } else if (data?.assignProviderToDepartment.departmentProvider) {
          // it worked.
          analytics.track("Add Provider to Department", {
            id: providerId,
            departmentId: values.departmentId,
            accepting_new_patient: values.acceptingNewPatients,
          });
          toast.success("Success!");
          resetForm();
          onSuccess();
        }
      } catch (e) {
        console.error(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
      } finally {
        setSubmitting(false);
      }
    },
    [addDepartment, providerId, onSuccess]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, status }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <div className="mt-3">
            <SelectField
              name="departmentId"
              label="Department"
              isLoading={loading}
              options={departmentOptions}
            />
          </div>
          <div className="mt-3">
            <SingleCheckboxInput
              name="acceptingNewPatients"
              checkboxLabel="Accepting new patients"
            />
          </div>
          <div className="flex items-center justify-end mt-3">
            <Button
              type="submit"
              kind="primary"
              color="blue"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Add Department
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
