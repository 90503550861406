import { FC, useCallback } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from "@apollo/client";
import { HorizontalRadioGroupField, HorizontalTextField, HorizontalStarRatingField, HorizontalTextAreaField, Button } from '@preferral/ui'
import { Modal, ModalHeader } from "components/Modal";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import toast from "react-hot-toast";
import { HorizontalIcd10CodeDiagnosisField } from "components/formik/Icd10CodeDiagnosisField";
import { analytics } from "lib/analytics";

const FINALIZE_ECONSULT = gql`
  mutation FinalizeEConsult($id: Int!, $input: FinalizeEconsultInput!) {
    finalizeEconsult(id:$id, input: $input) {
      errors{
        key
        message
      }
      econsult {
        id
        status
        statusSet
        comments {
          id
          comment
          type
          createdAt
          user {
            id
            name
            roles {
              id
              displayName
            }
          }
        }
      }
    }
  }
`;

interface MutationData {
  finalizeEconsult: {
    errors?: InputError[];
    econsult?: {
      id: string;
      status: string;
    };
  };
}

interface MutationVariables {
  id: number,
  input: FinalizeEConsultInput;
}

interface FormValues {
  newDiagnosis: string;
  econsultInfluence: string;
  econsultInside: string;
  requesterFeedback: string;
  econsultRating: number;
  requesterStarRating: string
  answerDetails: {
    text?: string | null
    ICD10_code?: string | null
  }
}

interface FinalizeEConsultInput {
  newDiagnosis: string,
  econsultInfluence: string,
  econsultInside: string,
  requesterFeedback?: string,
  econsultRating?: string,
  requesterStarRating?: string,
  answerDetails?: string
}

const initialValues: FormValues = {
  newDiagnosis: "",
  econsultInfluence: "",
  econsultInside: "",
  requesterFeedback: "",
  econsultRating: 0,
  requesterStarRating: "",
  answerDetails: {
    text: null,
    ICD10_code: null
  }
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    newDiagnosis: Yup.string().required("Required"),
    econsultInfluence: Yup.string().required("Required"),
    econsultInside: Yup.string().required("Required"),
  })
  .required();

/**
 * FinalizeEConsultModal.
 */

interface FinalizeEConsultModalProps {
  isOpen: boolean;
  onClose(): void;
  eConsultId: number;
  onSuccess(): void;
}

export const FinalizeEConsultModal: FC<FinalizeEConsultModalProps> = (props) => {
  const { isOpen, onClose, eConsultId, onSuccess } = props;
  const [finalizeEConsult] = useMutation<MutationData, MutationVariables>(FINALIZE_ECONSULT);

  const onSubmit = useCallback(async (values: FormValues, formikActions) => {
    const { setStatus, setSubmitting } = formikActions
    setSubmitting(true);
    setStatus({ errors: null })

    let input: FinalizeEConsultInput = {
      newDiagnosis: values.newDiagnosis,
      econsultInfluence: values.econsultInfluence,
      econsultInside: values.econsultInfluence,
    };
    if (!!values.requesterFeedback.trim()) {
      input.requesterFeedback = values.requesterFeedback
    }

    if (values.newDiagnosis == 'Yes') {
      input.answerDetails = JSON.stringify(values.answerDetails)
    }
    if (values.econsultRating > 0) {
      // NB: The star rating component spits out 0-100, we want 0-5.
      input.econsultRating = (values.econsultRating / 20).toString();
      input.requesterStarRating = values.requesterStarRating;
    }

    try {
      const { data } = await finalizeEConsult({ variables: { id: eConsultId, input } });
      if (data?.finalizeEconsult.errors) {
        setStatus({ errors: data.finalizeEconsult.errors });
      } else if (data?.finalizeEconsult.econsult?.status) {
        // it worked...
        toast.success("eConsult Finalized.");
        analytics.track("Finalized eConsult", {
          influenced_care_plan: values.econsultInfluence,
          new_diagnosis: values.newDiagnosis,
          imo_data: values.answerDetails.text,
          star_rating: values.econsultRating,
          rating_details: values.requesterStarRating,
        });
        onSuccess();
      } else {
        throw new Error("Should never happen");
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
    setSubmitting(false);
  }, [eConsultId, finalizeEConsult, onSuccess]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="max-w-2xl">
      <ModalHeader
        icon="circle-check"
        title={`Finalize eConsult ID#: C${eConsultId}`}
        onClose={onClose}
      />
      <div className="p-4 pt-2">
        <Formik<FormValues>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ status, isSubmitting, handleSubmit, values }) => (
            <form onSubmit={handleSubmit} className="text-sm">
              <FormStatusErrors status={status} />
              <h1 className="font-semibold text-indigo-700 text-base">Please tell us about the impact of this eConsult</h1>
              <div className="mt-3 border-t border-gray-100 pt-3">
                <HorizontalRadioGroupField
                  name="newDiagnosis"
                  label="This eConsult resulted in a new diagnosis"
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" }
                  ]}
                />
                {values.newDiagnosis == "Yes" ? <>
                  <HorizontalIcd10CodeDiagnosisField name="answerDetails" label="What is the new Diagnosis? (optional)" icon="search" />
                </> : null}
              </div>
              <div className="mt-3 border-t border-gray-100 pt-3">
                <HorizontalRadioGroupField
                  name="econsultInfluence"
                  label="This eConsult strongly influenced my care plan"
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" }
                  ]}
                />
              </div>
              <div className="mt-3 border-t border-gray-100 pt-3">
                <HorizontalRadioGroupField
                  name="econsultInside"
                  label="This eConsult eliminated need for an on-site visit to a specialist"
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" }
                  ]}
                />
              </div>
              <div className="mt-3 border-t border-gray-100 pt-3">
                <HorizontalStarRatingField
                  name="econsultRating"
                  label="Please rate the quality of the specialist's response"
                  showTooltip={true}
                  tooltipArray={['Poor', 'Not Good', 'OK', 'Good', 'Excellent']}
                />
                {(values.econsultRating && values.econsultRating < 100) ?
                  <div className="mt-3">
                    <HorizontalTextField
                      className="mb-12"
                      label="Please provide rating details"
                      name="requesterStarRating"
                    />
                  </div> : null
                }
              </div>
              {
                (values.econsultRating === 100) ? (
                  <div className="mt-3">
                    <HorizontalTextAreaField
                      name="requesterFeedback"
                      indicateOptional
                      label={`"Thank you" Note`}
                      placeholder="Enter a personal thank you note (optional)"
                    />
                  </div>
                ) : null
              }
              <p className="text-xs py-1 text-gray-600 italic">
                Your feedback will help to improve the quality of the eConsult service. Feedback is kept anonymous
                and may be shared with the specialist
              </p>
              <div className="mt-5 border rounded bg-gray-50 p-2">
                <h1 className="text-gray-700 text-sm font-semibold">Disclaimer</h1>
                <p className="text-xs">By Finalizing this eConsult you are confirming that you have reviewed the eConsult response by sent the specialist
                  and acknowledge all resulting patient treatment are your own decision
                </p>
              </div>
              <div className="flex items-center justify-end mt-3 py-4">
                <Button
                  type="submit"
                  kind="primary"
                  color="blue"
                  size="lg"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Finalize eConsult
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}
