import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { AssignLocationForm } from "./AssignLocationForm";

interface AssignLocationModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const AssignLocationModal: FC<AssignLocationModalProps> = (props) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="map-marker-alt"
        title="Assign Location"
        onClose={onClose}
      />
      <div className="p-4">
        <AssignLocationForm onSuccess={onClose} />
      </div>
    </Modal>
  );
};
