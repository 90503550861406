import { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import * as Yup from "yup";
import {
  Button,
  SelectInput,
  StandardOption,
  TextAreaInput,
} from "@preferral/ui";
import { Formik } from "formik";
import ChatAltIcon from '@heroicons/react/outline/ChatAltIcon';
import { useAppointmentRequest } from "../../AppointmentRequestContext";
import toast from "react-hot-toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { UserAvatar } from "components/UserAvatar";
import { analytics } from "lib/analytics";

const ME = gql`
  query Me {
    me {
      id
      firstName
      lastName
    }
  }
`;

interface Data {
  me: {
    id: string;
    firstName: string;
    lastName: string;
  }
}

const ADD_COMMENT = gql`
  mutation AddComment(
    $appointmentRequestId: UUID4!
    $input: AppointmentRequestCommentInput!
  ) {
    addAppointmentRequestComment(
      appointmentRequestId: $appointmentRequestId
      input: $input
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
      }
    }
  }
`;

interface MutationData {
  addAppointmentRequestComment: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appointmentRequestId: string;
  input: CommentInput;
}

interface CommentInput {
  comment: string;
  visibility: string;
}

type FormValues = CommentInput;

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    comment: Yup.string().trim().required("Required"),
    visibility: Yup.string().trim().required("Required"),
  })
  .required();

const visibilityOptions: StandardOption[] = [
  {
    value: "everyone",
    label: "Visible to All",
  },
  {
    value: "internal_only",
    label: "Internal Only",
  },
];

interface ActivityFormProps { }

export const ActivityForm: FC<ActivityFormProps> = (props) => {
  const { data } = useQuery<Data>(ME);

  const [addComment] = useMutation<MutationData, MutationVariables>(
    ADD_COMMENT
  );

  const {
    appointmentRequest: { id: appointmentRequestId, insertedAt },
    refetch,
  } = useAppointmentRequest();

  const onSubmit = useCallback(
    (values, { setSubmitting, setStatus, resetForm }) => {
      setStatus({ errors: null });
      return addComment({
        variables: { appointmentRequestId, input: values },
      }).then((resp) => {
        if (resp.data?.addAppointmentRequestComment.errors) {
          setStatus({ errors: resp.data.addAppointmentRequestComment.errors });
        } else if (resp.data?.addAppointmentRequestComment.appointmentRequest) {
          // it worked
          analytics.track("Comment Left on Activity Log", {
              referral_id: appointmentRequestId,
              referral_requested_date: insertedAt,
            });
          toast.success("Comment added");
          resetForm();
          return refetch();
        }
        setSubmitting(false);
      });
    },
    [addComment, appointmentRequestId, refetch]
  );

  return (
    <div className="_ActivityForm max-w-xl mx-auto">
      <div className="flex space-x-3">
        <div className="flex-shrink-0">
          <div className="relative">
            {
              !data?.me ? (
                <div className="h-10 w-10" />
              ) : (
                <UserAvatar
                  firstName={data.me.firstName}
                  lastName={data.me.lastName}
                />
              )
            }

            <span
              className="absolute bg-white rounded"
              style={{
                bottom: -2,
                right: -4,
                padding: 1,
              }}
            >
              <ChatAltIcon className="h-5 w-5 text-cool-gray-500" />
            </span>
          </div>
        </div>
        <div className="min-w-0 flex-1">
          <Formik<FormValues>
            initialValues={{
              comment: "",
              visibility: "everyone",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormStatusErrors status={status} />
                <div>
                  <label htmlFor="comment" className="sr-only">
                    Comment
                  </label>
                  {/* <textarea
                    id="comment"
                    name="comment"
                    rows={3}
                    className="p-3 shadow-sm block w-full focus:ring-gray-900 focus:border-gray-900 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Leave a comment"
                  ></textarea> */}
                  <TextAreaInput name="comment" placeholder="Leave a comment" />
                </div>
                <div className="mt-6 flex items-center justify-end space-x-4">
                  <p>Visibility</p>
                  <div className="w-56">
                    <SelectInput
                      name="visibility"
                      options={visibilityOptions}
                    />
                  </div>
                  <Button
                    type="submit"
                    color="blue"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Comment
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
