import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField, PhoneMaskField, DateMaskField } from "@preferral/ui";
import toast from "react-hot-toast";

const PATIENT_MEMBER = gql`
  query GetPatientMember($id: UUID4!) {
    patientMember(id: $id) {
      id
      medicalRecordNumber
      ssn
      patient {
        id
        firstName
        lastName
        dob
        primaryPhone {
          formatted
        }
      }
    }
  }
`;

const UPDATE_PATIENT_MEMBER = gql`
  mutation UpdatePatientMember($id: UUID4!, $input: PatientMemberInput!) {
    updatePatientMember(id: $id, input: $input) {
      errors {
        key
        message
      }
      patientMember {
        id
        medicalRecordNumber
        ssn
        patient {
          id
          firstName
          lastName
          dob
          primaryPhone {
            formatted
          }
        }
      }
    }
  }
`;

type Patient = {
  id: string;
  firstName: string;
  lastName: string;
  dob: string;
  primaryPhone?: {
    formatted: string;
  };
};

type PatientMember = {
  id: string;
  medicalRecordNumber?: string;
  ssn?: string;
  patient: Patient;
};

interface Data {
  patientMember: PatientMember;
}

interface MutationData {
  updatePatientMember: {
    errors?: InputError[];
    patientMember?: PatientMember;
  };
}

interface PatientMemberInput {
  firstName: string;
  lastName: string;
  dob: string;
  primaryPhone?: string;
  medicalRecordNumber?: string;
  ssn?: string;
}

interface Variables {
  id: string;
  input: PatientMemberInput;
}

type FormProps = {
  patientMember: PatientMember;
  onSuccess: () => void;
};

const Form: React.FC<FormProps> = ({ patientMember, onSuccess }) => {
  const [updatePatientMember] = useMutation<MutationData, Variables>(
    UPDATE_PATIENT_MEMBER
  );
  return (
    <Formik
      initialValues={{
        firstName: patientMember.patient.firstName,
        lastName: patientMember.patient.lastName,
        primaryPhone: patientMember.patient.primaryPhone?.formatted || "",
        dob: format(parseISO(patientMember.patient.dob), "MM/dd/yyyy"),
        medicalRecordNumber: patientMember.medicalRecordNumber,
        ssn: patientMember.ssn,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        primaryPhone: Yup.string()
          .required("Required")
          .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, "Invalid phone number"),
        dob: Yup.string().required("Required"),
      })}
      onSubmit={(formValues, { setStatus, setSubmitting }) => {
        setStatus({ errors: null });
        const input = {
          ...formValues,
          dob: format(
            parse(formValues.dob, "MM/dd/yyyy", new Date()),
            "yyyy-MM-dd"
          ),
        };
        updatePatientMember({
          variables: { id: patientMember.id, input },
        }).then(
          (resp: any) => {
            if (resp && resp.data && resp.data.updatePatientMember.errors) {
              setStatus({ errors: resp.data.updatePatientMember.errors });
            } else if (
              resp &&
              resp.data &&
              resp.data.updatePatientMember.patientMember
            ) {
              // it worked
              toast.success("Patient updated");
              onSuccess();
            }
            setSubmitting(false);
          },
          (rej: any) => {
            setStatus({
              errors: [{ key: "", message: "Something went wrong" }],
            });
            setSubmitting(false);
          }
        );
      }}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3">
              <TextField name="firstName" label="First Name" autoFocus />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <TextField name="lastName" label="Last Name" />
            </div>
          </div>

          <div className="mt-3">
            <DateMaskField
              name="dob"
              label="Date of Birth (mm/dd/yyyy)"
              placeholder="mm/dd/yyyy"
            />
          </div>

          <div className="mt-3">
            <PhoneMaskField
              name="primaryPhone"
              label="Primary Phone"
              placeholder="(___) ___-____"
            />
          </div>

          <div className="mt-3">
            <TextField
              name="medicalRecordNumber"
              label="Patient MRN"
              inputProps={{ disabled: !!patientMember.medicalRecordNumber }}
            />
          </div>

          <div className="mt-3">
            <TextField name="ssn" label="SSN" indicateOptional />
          </div>

          <div className="flex items-center justify-end mt-3 py-4">
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-blue"
            >
              Update Patient
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

type EditPatientMemberFormProps = {
  patientMemberId: string;
  onSuccess: () => void;
};

const EditPatientMemberForm: React.FC<EditPatientMemberFormProps> = ({
  patientMemberId,
  onSuccess,
}) => {
  const { data, loading, error } = useQuery<Data>(PATIENT_MEMBER, {
    variables: { id: patientMemberId },
  });
  return (
    <div className="EditPatientMemberForm">
      {loading ? (
        <p>Loading</p>
      ) : error || !(data && data.patientMember) ? (
        <p>Failed to load</p>
      ) : (
        <Form patientMember={data.patientMember} onSuccess={onSuccess} />
      )}
    </div>
  );
};

export { EditPatientMemberForm };
