import { LocationModel, NPPESLocationModel } from "./model";

export function addressString(
  location: LocationModel | NPPESLocationModel
): string {
  return [
    location.streetAddress,
    location.streetAddressLine2,
    location.city,
    location.zip,
    location.stateAbbreviation,
  ]
    .filter(Boolean)
    .join(", ");
}
