import React, { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";
import Select, {
  components,
  OptionProps,
  ValueType,
  Props as SelectProps,
} from "react-select";
import { ProviderReferenceModel } from "components/ProviderSearchSelect";
import {
  LocationModel,
  LocationReferenceModel,
  LocationKindModel,
} from "./model";
import { LocationOption } from "./LocationOption";

const Option: FC<OptionProps<LocationModel, false>> = (props) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <LocationOption location={data as LocationModel} />
    </components.Option>
  );
};

const fields = `
id
name
streetAddress
streetAddressLine2
city
stateAbbreviation
zip
`;

const LIST_PROVIDER_LOCATIONS = gql`
  query GetProviderLocations($provider: ProviderReferenceInput) {
    providerLocations(provider: $provider) {
      __typename
      ... on Location {
        ${fields}
      }
      ... on NppesLocation {
        ${fields}
      }
      ... on UnlistedLocation {
        ${fields}
      }
    }
  }
`;

interface Data {
  providerLocations: LocationAPIModel[];
}

// NB: These are the GraphQL model types.
type LocationUnionTypes = "Location" | "NppesLocation" | "UnlistedLocation";

interface LocationAPIModel extends Omit<LocationModel, "kind"> {
  __typename: LocationUnionTypes;
}

interface Variables {
  provider?: ProviderReferenceModel | null;
}

interface ProviderLocationSelectInputProps {
  provider: ProviderReferenceModel | null;
  value: LocationReferenceModel | null;
  onChange(locationReference: LocationReferenceModel | null): void;
  onBlur?(e: any): void;
  placeholder?: string;
  className?: string;
  id?: string;
  disabled?: boolean;
  inputProps?: SelectProps<LocationModel>;
}

export const ProviderLocationSelectInput: FC<ProviderLocationSelectInputProps> = (
  props
) => {
  const {
    id,
    className = "",
    value,
    onBlur,
    onChange,
    placeholder,
    provider,
    disabled,
    inputProps = {},
  } = props;

  const [debouncedProvider] = useDebounce(provider, 400, {
    equalityFn: isSameProvider,
  });
  const { data, loading } = useQuery<Data, Variables>(LIST_PROVIDER_LOCATIONS, {
    variables: { provider: debouncedProvider },
  });

  const options = data?.providerLocations.map(toClientModel) || [];
  const selected = options.find(
    (l) => l.kind === value?.kind && l.id === value.id
  );

  function handleChange(option: ValueType<LocationModel, false>): void {
    if (!option) {
      return onChange(null);
    } else {
      const locationRef: LocationReferenceModel = {
        id: (option as LocationModel).id,
        kind: (option as LocationModel).kind,
      };
      return onChange(locationRef);
    }
  }

  return (
    <Select<LocationModel>
      id={id}
      className={className}
      placeholder={placeholder}
      isLoading={loading}
      disabled={disabled}
      isDisabled={disabled}
      value={selected}
      options={options}
      onChange={handleChange}
      onBlur={onBlur}
      components={{ Option }}
      getOptionLabel={getOptionLabel}
      {...inputProps}
    />
  );
};

function isSameProvider(
  left: ProviderReferenceModel | null,
  right: ProviderReferenceModel | null
): boolean {
  return left?.id === right?.id && left?.kind === right?.kind;
}

const locationTypeKindMap: Record<LocationUnionTypes, LocationKindModel> = {
  Location: "platform",
  NppesLocation: "nppes",
  UnlistedLocation: "unlisted",
};

export function toClientModel(location: LocationAPIModel): LocationModel {
  const { __typename, ...rest } = location;

  const kind = locationTypeKindMap[__typename];

  return {
    ...rest,
    kind,
  };
}

function getOptionLabel(option: LocationModel): string {
  return option.name;
}

// function getOptionValue(option: LocationModel): LocationReferenceModel {
//   return {
//     id: option.id,
//     kind: option.kind,
//   };
// }
