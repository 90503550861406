import { useCallback, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";

const USER_FEATURE_FLAGS = gql`
  query UserFeatureFlags {
    me {
      id
      organization {
        id
        featureFlags {
          feature
          enabled
        }
      }
    }
  }
`;

interface Data {
  me: {
    id: string;
    organization: {
      id: string;
      featureFlags: {
        feature: string;
        enabled: boolean;
      }[];
    };
  };
}

export function useFeatureFlags() {
  const { data } = useQuery<Data>(USER_FEATURE_FLAGS);

  const hasFeature = useCallback(
    featureName => {
      const enabledFeatures =
        data?.me.organization.featureFlags
          .filter(f => f.enabled)
          .map(f => f.feature) || [];

      return enabledFeatures.includes(featureName);
    },
    [data]
  );

  return useMemo(() => ({ hasFeature }), [hasFeature]);
}
