import { FC, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { gql, useMutation, useQuery } from '@apollo/client';
import { Formik } from "formik";
import toast from 'react-hot-toast';
import { VerticalField, TextAreaInput, Spinner, Button } from '@preferral/ui';
import { ScreenTitle } from 'context/ScreenTitle';
import { GradientHeader } from '../../GradientHeader';
import { FormStatusErrors } from "components/formik/FormStatusErrors";

const DEPARTMENT_SMS_TEMPLATES = gql`
  query DepartmentSMSTemplates($departmentId: UUID4!) {
    department(id: $departmentId) {
      id
      appointmentRequestedAssignedSmsTemplate
      appointmentRequestedUnassignedSmsTemplate
      appointmentScheduledSmsTemplate
      appointmentRescheduledSmsTemplate
      appointmentCancelledSmsTemplate
      appointmentReminderSmsTemplate
    }
  }
`;

interface Data {
  department: DepartmentModel
}

interface Variables {
  departmentId: string;
}

interface DepartmentModel {
  id: string;
  appointmentRequestedAssignedSmsTemplate: string | void;
  appointmentRequestedUnassignedSmsTemplate: string | void;
  appointmentScheduledSmsTemplate: string | void;
  appointmentRescheduledSmsTemplate: string | void;
  appointmentCancelledSmsTemplate: string | void;
  appointmentReminderSmsTemplate: string | void;
}

const UPDATE_SMS_TEMPLATES = gql`
  mutation UpdateSMSTemplates($departmentId: UUID4!, $input: DepartmentInput!) {
    updateDepartment(id: $departmentId, input: $input) {
      errors {
        key
        message
      }
      department {
        id
        appointmentRequestedAssignedSmsTemplate
        appointmentRequestedUnassignedSmsTemplate
        appointmentScheduledSmsTemplate
        appointmentRescheduledSmsTemplate
        appointmentCancelledSmsTemplate
        appointmentReminderSmsTemplate
      }
    }
  }
`

interface MutationData {
  updateDepartment: {
    errors?: InputError[];
    department?: DepartmentModel;
  }
}

interface MutationVariables {
  departmentId: string;
  input: DepartmentInput
}

type DepartmentInput = Omit<DepartmentModel, "id">;

const unassignedAppointmentRequestVariables = [
  "receiving_organization_name",
  "specialty"
]

const appointmentRequestVariables = [
  "receiving_organization_name",
  "receiving_location_name",
  "receiving_location_short_name",
  "receiving_provider_name",
  "referring_provider_name",
  "specialty",
  "receiving_location_phone_number",
  "receiving_location_full_address"
];

const appointmentVariables = appointmentRequestVariables.concat([
  "provider_name",
  "appointment_time",
  "appointment_date",
  "location_name",
  "location_phone"
]);

const templates = {
  appointmentRequestedAssignedSmsTemplate: {
    label: "\"Pre-Call\" Text Message (for Assigned Referrals)",
    description: "Text message sent to patients when they've been referred to a specific provider or location within your department.",
    validVariables: appointmentRequestVariables
  },
  appointmentRequestedUnassignedSmsTemplate: {
    label: "\"Pre-Call\" Text Message (for Unassigned Referrals)",
    description: "Text message sent to patients when they've been referred to your department without specifying a requested provider/location.",
    validVariables: unassignedAppointmentRequestVariables
  },
  appointmentScheduledSmsTemplate: {
    label: "Appointment Scheduled Text Message",
    description: "Text message sent to patients when an appointment is scheduled.",
    validVariables: appointmentVariables
  },
  appointmentRescheduledSmsTemplate: {
    label: "Appointment Rescheduled Text Message",
    description: "Text message sent to patients when an appointment is rescheduled.",
    validVariables: appointmentVariables
  },
  appointmentCancelledSmsTemplate: {
    label: "Appointment Cancelled Text Message",
    description: "Text message sent to patients when an appointment is cancelled.",
    validVariables: appointmentVariables
  },
  appointmentReminderSmsTemplate: {
    label: "Appointment Reminder Text Message",
    description: "Text message sent to patients the day before their scheduled appointment.",
    validVariables: appointmentVariables
  }
}

interface SMSTemplatesScreenProps { };

export const SMSTemplatesScreen: FC<SMSTemplatesScreenProps> = props => {
  const match = useRouteMatch<{ departmentId: string }>();
  const { departmentId } = match.params;

  const { data, loading, error } = useQuery<Data, Variables>(DEPARTMENT_SMS_TEMPLATES, { variables: { departmentId } });

  const [saveTemplates] = useMutation<MutationData, MutationVariables>(UPDATE_SMS_TEMPLATES);

  const onSubmit = useCallback(async (values, formikActions) => {
    const { setStatus, setSubmitting } = formikActions;

    try {
      const { data } = await saveTemplates({ variables: { departmentId, input: values } });

      if (data?.updateDepartment.errors) {
        setStatus({ errors: data.updateDepartment.errors });
      } else {
        // it worked...
        toast.success("SMS Templates Updated.")
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.")
    }
    setSubmitting(false);
  }, [departmentId, saveTemplates]);

  return (
    <>
      <ScreenTitle title={["Settings", "Department", "SMS Templates"]} />
      <div className="_SMSTemplatesScreen bg-white box rounded-lg shadow-lg">
        <GradientHeader
          icon="brackets-curly"
          title="SMS Templates"
          subtitle="Customize your department's patient SMS notifications"
        />
        {
          loading ? (
            <div className="p-12 text-center">
              <Spinner />
            </div>
          ) : error || !data?.department ? (
            <p>Failed to load.</p>
          ) : (
            <Formik
              initialValues={Object.keys(templates).reduce((acc, elem) => {
                acc[elem] = (data.department[elem] || "")
                return acc;
              }, {})}
              onSubmit={onSubmit}
            >
              {({ status, isSubmitting, handleSubmit }) => (
                <form className="p-6" onSubmit={handleSubmit}>
                  <FormStatusErrors status={status} />

                  {
                    Object.keys(templates).map((templateName, idx) => (
                      <div key={templateName} className={`pt-3 ${idx > 0 ? "border-t mt-6" : ""}`}>
                        <VerticalField label={templates[templateName].label}>
                          <div className="mt-3 flex gap-x-4">
                            <div className="w-1/2 flex-shrink-0">
                              <p className="text-sm mb-2 font-semibold mt-2 text-gray-700">Template:</p>
                              <TextAreaInput name={templateName} />
                            </div>

                            <div className="text-gray-500 text-sm">
                              <p className="font-semibold mt-2 text-gray-700">Description:</p>
                              <p className="mt-2">{templates[templateName].description}</p>
                              <p className="font-semibold mt-2 text-gray-700">Valid Variables:</p>
                              <pre className="bg-gray-100 mt-2 p-2 rounded-lg text-xs">
                                {templates[templateName].validVariables.map((v: string) => `{{${v}}}`).join("\n")}
                              </pre>
                            </div>
                          </div>

                        </VerticalField>
                      </div>
                    ))
                  }

                  <div className="mt-3 text-center">
                    <Button
                      type="submit"
                      color="blue"
                      kind="primary"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Save Templates
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          )
        }
      </div>
    </>
  );
};
