/**
 * Deterministically turns a given string into a color in hsl format.
 *
 * @param str Seed string
 * @param s Saturation
 * @param l Lightness
 * @returns An hsl() color string.
 */
export function stringToHslColor(str: string, s: number, l: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
}
