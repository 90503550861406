import { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Formik } from "formik";
import { SingleCheckboxInput, TextField, Button } from "@preferral/ui";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { analytics } from "../../../../../lib/analytics";
import { useAppointmentRequest } from "../../../AppointmentRequestContext";

const CANCEL_APPOINTMENT = gql`
  mutation CancelAppointment(
    $appointmentId: UUID4!
    $input: CancelAppointmentInput!
  ) {
    cancelAppointment(appointmentId: $appointmentId, input: $input) {
      errors {
        key
        message
      }
      appointment {
        id
      }
    }
  }
`;

interface MutationData {
  cancelAppointment: {
    errors?: InputError[];
    appointment?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appointmentId: string;
  input: FormValues;
}

interface FormValues {
  cancellationReason: string;
  requiresReschedule: boolean;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    cancellationReason: Yup.string().required("Required"),
  })
  .required();

const initialValues: FormValues = {
  cancellationReason: "",
  requiresReschedule: false,
};

interface CancelAppointmentFormProps {
  appointmentId: string;
  onSuccess(): void;
}

export const CancelAppointmentForm: FC<CancelAppointmentFormProps> = props => {
  const { appointmentId, onSuccess } = props;

  const [cancelAppointment] = useMutation<MutationData, MutationVariables>(
    CANCEL_APPOINTMENT
  );
  const {
    appointmentRequest: { id: appointmentRequestId, insertedAt },
  } = useAppointmentRequest();

  const onSubmit = useCallback(
    async (values, formikHelpers) => {
      const { setSubmitting, setStatus } = formikHelpers;

      try {
        const { data } = await cancelAppointment({
          variables: {
            appointmentId: appointmentId,
            input: values,
          },
        });

        if (data?.cancelAppointment.errors) {
          setStatus(data.cancelAppointment.errors);
        } else if (data?.cancelAppointment.appointment) {
          // it worked...
          toast.success("Appointment cancelled");
          analytics.track("Appointment Canceled", {
            referral_id: appointmentRequestId,
            referral_requested_date: insertedAt,
          });
          return onSuccess();
        }
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
      } finally {
        setSubmitting(false);
      }
    },
    [appointmentId, cancelAppointment, onSuccess]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          <div className="mt-3">
            <TextField
              name="cancellationReason"
              label="Cancellation Reason"
              indicateOptional
            />
          </div>

          <div className="mt-3">
            <SingleCheckboxInput
              name="requiresReschedule"
              checkboxLabel="Requires reschedule"
            />
          </div>
          <div className="mt-3 text-center">
            <Button
              type="submit"
              color="red"
              kind="primary"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
