import { FC } from "react";
import { Mutation, Query } from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { GradientHeader } from "../../GradientHeader";
import toast from "react-hot-toast";
import { validPassword } from "lib/validPassword";
import { HorizontalField, HorizontalTextField } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";

const CURRENT_USER_ACCOUNT_DATA_QUERY = gql`
  {
    me {
      id
      email
      confirmedAt
    }
  }
`;

interface MeData {
  me: {
    id: string;
    email: string;
    confirmedAt: void | string;
  };
}

const UPDATE_CURRENT_USER_EMAIL = gql`
  mutation UpdateCurrentUserEmail($input: UpdateEmailInput!) {
    updateCurrentUserEmail(input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

interface UpdateEmailData {
  updateCurrentUserEmail: {
    errors?: InputError[];
  };
}

interface UpdateEmailInput {
  email: string;
  password: string;
}

interface UpdateEmailVariables {
  input: UpdateEmailInput;
}

const UPDATE_CURRENT_USER_PASSWORD = gql`
  mutation UpdateCurrentUserPassword($input: UpdatePasswordInput!) {
    updateCurrentUserPassword(input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

interface UpdatePasswordData {
  updateCurrentUserPassword: {
    errors?: InputError[];
  };
}

interface UpdatePasswordInput {
  password: string;
  passwordConfirmation: string;
  currentPassword: string;
}

interface UpdatePasswordVariables {
  input: UpdatePasswordInput;
}

interface AccountFormProps { }

export const AccountForm: FC<AccountFormProps> = () => {
  return (
    <div className="bg-white box rounded-lg shadow-lg">
      <ScreenTitle title="Settings » Account" />
      <GradientHeader
        icon="lock"
        title="Account Information"
        subtitle="Update your account information."
      />
      <div className="AccountForm">
        <Query<MeData> query={CURRENT_USER_ACCOUNT_DATA_QUERY}>
          {({ loading, data, error }) =>
            loading ? (
              <h1>Loading...</h1>
            ) : error ? (
              <div>
                <h1>Error</h1>
                <pre>{JSON.stringify(error)}</pre>
              </div>
            ) : (
              <div>
                <Mutation<UpdateEmailData, UpdateEmailVariables>
                  mutation={UPDATE_CURRENT_USER_EMAIL}
                >
                  {(updateEmail) => (
                    <Formik
                      initialValues={{
                        email: (data && data.me.email) || "",
                        password: "",
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string()
                          .email("Invalid email")
                          .required("Required"),
                        password: Yup.string().required(
                          "Your password is required to update your email."
                        ),
                      })}
                      onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus({ errors: null });
                        updateEmail({
                          variables: { input: values },
                          refetchQueries: [
                            { query: CURRENT_USER_ACCOUNT_DATA_QUERY },
                          ],
                        }).then(
                          (res) => {
                            if (
                              res &&
                              res.data &&
                              res.data.updateCurrentUserEmail.errors
                            ) {
                              setStatus({
                                errors: res.data.updateCurrentUserEmail.errors,
                              });
                            } else {
                              // Success
                              toast.success("Email updated!");
                            }
                            setSubmitting(false);
                          },
                          (rej) => setSubmitting(false)
                        );
                      }}
                    >
                      {({ status, isSubmitting, handleSubmit }) => (
                        <div className="p-6">
                          <h3 className="text-xl font-semibold">
                            Update Email
                          </h3>
                          <form onSubmit={handleSubmit}>
                            <FormStatusErrors status={status} />

                            <div className="mt-3">
                              <HorizontalTextField
                                id="updateEmail-email"
                                name="email"
                                label="Email"
                                type="email"
                              />
                            </div>

                            <div className="mt-3">
                              <HorizontalTextField
                                id="updateEmail-password"
                                name="password"
                                label="Password"
                                type="password"
                              />
                            </div>

                            <div className="mt-3">
                              <HorizontalField label="">
                                <button
                                  type="submit"
                                  className="btn btn-blue"
                                  disabled={isSubmitting}
                                >
                                  Update Email
                                </button>
                              </HorizontalField>
                            </div>
                          </form>
                        </div>
                      )}
                    </Formik>
                  )}
                </Mutation>
                <Mutation<UpdatePasswordData, UpdatePasswordVariables>
                  mutation={UPDATE_CURRENT_USER_PASSWORD}
                >
                  {(updatePassword) => (
                    <Formik
                      initialValues={{
                        password: "",
                        passwordConfirmation: "",
                        currentPassword: "",
                      }}
                      validationSchema={Yup.object().shape({
                        password: validPassword,
                        passwordConfirmation: validPassword,
                        currentPassword: Yup.string().required(
                          "Your current password is required to update your password."
                        ),
                      })}
                      onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus({ errors: null });
                        if (values.password !== values.passwordConfirmation) {
                          setStatus({
                            errors: [
                              {
                                message:
                                  "New Password and New Password Confirmation must match",
                              },
                            ],
                          });
                          setSubmitting(false);
                        } else {
                          updatePassword({
                            variables: { input: values },
                          }).then(
                            (res) => {
                              if (
                                res &&
                                res.data &&
                                res.data.updateCurrentUserPassword.errors
                              ) {
                                setStatus({
                                  errors:
                                    res.data.updateCurrentUserPassword.errors,
                                });
                              } else {
                                // Success
                                toast.success("Password updated!");
                              }
                              // TODO:
                              setSubmitting(false);
                            },
                            (rej) => setSubmitting(false)
                          );
                        }
                      }}
                    >
                      {({ status, isSubmitting, handleSubmit }) => (
                        <div className="p-6">
                          <h3 className="text-xl font-semibold">
                            Update Password
                          </h3>
                          <form onSubmit={handleSubmit}>
                            <FormStatusErrors status={status} />

                            <div className="mt-3">
                              <HorizontalTextField
                                id="updatePassword-password"
                                name="password"
                                label="New Password"
                                type="password"
                              />
                            </div>

                            <div className="mt-3">
                              <HorizontalTextField
                                id="updatePassword-passwordConfirmation"
                                name="passwordConfirmation"
                                label="Confirm New Password"
                                type="password"
                              />
                            </div>

                            <div className="mt-3">
                              <HorizontalTextField
                                id="updatePassword-currentPassword"
                                name="currentPassword"
                                label="Current Password"
                                type="password"
                              />
                            </div>

                            <HorizontalField label="">
                              <button
                                type="submit"
                                className="btn btn-blue"
                                disabled={isSubmitting}
                              >
                                Update Password
                              </button>
                            </HorizontalField>
                          </form>
                        </div>
                      )}
                    </Formik>
                  )}
                </Mutation>
              </div>
            )
          }
        </Query>
      </div>
    </div>
  );
};
