import { FC, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import { GradientHeader } from "screens/SettingsScreen/GradientHeader";
import { NoResults } from "components/NoResults";
import {
  Spinner,
  Button,
  TableContainer,
  Table,
  TH,
  TD,
  FAIcon,
  DropdownItemButton,
} from "@preferral/ui";
import { DropdownButton } from "components/DropdownButton";
import { ScreenTitle } from "context/ScreenTitle";
import { NewPacketModal } from "./NewPacketModal";
import { EditPacketModal } from "./EditPacketModal";

const DEPARTMENT_PATIENT_PACKETS = gql`
  query GetDepartmentPatientPackets($id: UUID4!) {
    department(id: $id) {
      id
      name
      patientPackets {
        id
        title
        insertedAt
        updatedAt
        patientForms {
          id
          title
        }
        patientRequestPacketUrl
      }
    }
  }
`;

interface Data {
  department: {
    id: string;
    name: string;
    patientPackets: PatientPacketModel[];
  };
}

interface Variables {
  id: string;
}

interface PatientPacketModel {
  id: string;
  title: string;
  insertedAt: string;
  updatedAt: string;
  patientForms: PatientFormModel[];
  patientRequestPacketUrl: string;
}

interface PatientFormModel {
  id: string;
  title: string;
}

interface PatientPacketsScreenProps { }

type ActiveModal =
  | { type: "NEW_PACKET" }
  | { type: "EDIT_PACKET"; patientPacketId: string };

export const PatientPacketsScreen: FC<PatientPacketsScreenProps> = props => {
  const match = useRouteMatch<{ departmentId: string }>();
  const { departmentId } = match.params;

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const closeModal = () => setActiveModal(null);
  const openNewPacketModal = () => setActiveModal({ type: "NEW_PACKET" });
  const openEditPacketModal = (patientPacketId: string) =>
    setActiveModal({ type: "EDIT_PACKET", patientPacketId });

  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    DEPARTMENT_PATIENT_PACKETS,
    { variables: { id: departmentId } }
  );

  return (
    <>
      <ScreenTitle title={["Settings", "Department", "Patient Packets"]} />
      <NewPacketModal
        departmentId={departmentId}
        isOpen={activeModal?.type === "NEW_PACKET"}
        onClose={closeModal}
        refetch={refetch}
      />
      {activeModal?.type === "EDIT_PACKET" ? (
        <EditPacketModal
          patientPacketId={activeModal.patientPacketId}
          departmentId={departmentId}
          isOpen={activeModal.type === "EDIT_PACKET"}
          onClose={closeModal}
          refetch={refetch}
        />
      ) : null}
      <div className="_PatientPacketsScreen bg-white box rounded-lg shadow-lg">
        <GradientHeader
          icon="envelope-open-text"
          title="Patient Packets"
          subtitle={
            data?.department
              ? `Manage patient packets for the ${data.department.name} department.`
              : ""
          }
        />
        <div className="p-4">
          {loading ? (
            <div className="p-12">
              <Spinner />
            </div>
          ) : error || !data?.department ? (
            <p>Failed to load</p>
          ) : (
            <>
              <div className="flex justify-end px-3 pb-4">
                <Button
                  type="button"
                  color="blue"
                  kind="primary"
                  onClick={openNewPacketModal}
                >
                  <FAIcon icon="plus" />
                  <span className="ml-2">Add Patient Packet</span>
                </Button>
              </div>
              {data.department.patientPackets.length === 0 ? (
                <NoResults
                  icon="envelope-open-text"
                  text="No patient packets added yet"
                />
              ) : (
                <TableContainer>
                  <Table>
                    <thead>
                      <tr>
                        <TH>Packet Name</TH>
                        <TH>Forms</TH>
                        <TH />
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {data.department.patientPackets.map(pp => (
                        <tr key={pp.id}>
                          <TD>
                            <p className="font-semibold text-cool-gray-800">
                              {pp.title}
                              <a
                                href={pp.patientRequestPacketUrl}
                                target="_blank"
                                rel="noref noreferrer nofollow"
                                className="ml-2 text-sm text-blue-500 underline"
                              >
                                <FAIcon icon="share" />
                              </a>
                            </p>
                          </TD>
                          <TD>
                            <ul>
                              {pp.patientForms.map(pf => (
                                <li key={pf.id}>{pf.title}</li>
                              ))}
                            </ul>
                          </TD>
                          <TD className="w-40">
                            <DropdownButton label="Actions">
                              <DropdownItemButton
                                onClick={() => openEditPacketModal(pp.id)}
                              >
                                Edit Packet
                              </DropdownItemButton>
                            </DropdownButton>
                          </TD>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
