import { FC } from "react";
import { useAppointmentRequest } from "../../AppointmentRequestContext";
import { AppointmentRequestEvent } from "./AppointmentRequestEvent";

interface ActivityLogProps { }

export const ActivityLog: FC<ActivityLogProps> = () => {
  const {
    appointmentRequest: { appointmentRequestEvents },
  } = useAppointmentRequest();
  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {appointmentRequestEvents.map((apptReqEvt, idx) => (
          <AppointmentRequestEvent
            key={apptReqEvt.id}
            showLine={idx < appointmentRequestEvents.length - 1}
            appointmentRequestEvent={apptReqEvt}
          />
        ))}
      </ul>
    </div>
  );
};
