import { FC, CSSProperties } from "react";
import { stringToHslColor } from "lib/colorUtils";

const DEFAULT_SIZE = 40;

interface UserAvatarProps {
  src?: string;
  firstName: string;
  lastName: string;
  size?: number;
  style?: CSSProperties;
  className?: string;
}

export const UserAvatar: FC<UserAvatarProps> = props => {
  const { src, size = DEFAULT_SIZE, style, firstName, lastName, className } = props;

  const cn = [
    "_UserAvatar rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white",
    className,
  ]
    .filter(Boolean)
    .join(" ");

  return !!src ? (
    <div
      className={cn}
      style={{
        width: size,
        height: size,
        backgroundImage: `url(${src})`,
        ...style,
      }}
    />
  ) : (
    <div
      className={cn}
      style={{
        width: size,
        height: size,
        backgroundColor: stringToHslColor(`${firstName} ${lastName}`, 30, 80),
        color: "#fff",
        ...style,
      }}
    >
      <span
        className="_UserAvatar__letters cursor-default"
        style={{ fontSize: Math.floor(size / 2.5) }}
      >
        {firstName.slice(0, 1)}
        {lastName.slice(0, 1)}
      </span>
    </div>
  );
};
