import { FC, useCallback } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { GradientHeader } from "../../GradientHeader";
import toast from "react-hot-toast";
import { HorizontalField, HorizontalTextField, Spinner, Button } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";

const CURRENT_ORGANIZATION_PROFILE_DATA_QUERY = gql`
  {
    me {
      id
      organization {
        id
        name
      }
    }
  }
`;

interface CurrentOrgData {
  me: {
    id: string;
    organization: {
      id: string;
      name: string;
    };
  };
}

const UPDATE_ORGANIZATION_PROFILE_MUTATION = gql`
  mutation UpdateOrganizationProfile($input: OrganizationProfileInput!) {
    updateOrganizationProfile(input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

interface UpdateOrgData {
  updateOrganizationProfile: {
    errors?: InputError[];
  };
}

interface OrganizationProfileInput {
  name: string;
}

interface UpdateOrgVariables {
  input: OrganizationProfileInput;
}

interface FormValues {
  name: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    name: Yup.string().required("Required"),
  })
  .required();

/**
 * ProfileForm.
 */

interface ProfileFormProps { }

export const ProfileForm: FC<ProfileFormProps> = () => {

  const { data, loading, error } = useQuery<CurrentOrgData>(CURRENT_ORGANIZATION_PROFILE_DATA_QUERY);

  const [updateOrganization] = useMutation<UpdateOrgData, UpdateOrgVariables>(UPDATE_ORGANIZATION_PROFILE_MUTATION);

  const onSubmit = useCallback((values, formikHelpers) => {
    const { setStatus, setSubmitting } = formikHelpers;

    setStatus({ errors: null });
    return updateOrganization({
      variables: { input: values },
      refetchQueries: [
        { query: CURRENT_ORGANIZATION_PROFILE_DATA_QUERY },
      ],
    }).then(
      (res) => {
        if (res.data?.updateOrganizationProfile.errors) {
          setStatus({
            errors: res.data.updateOrganizationProfile.errors,
          });
        } else {
          toast.success("Updated organization profile!");
        }
        setSubmitting(false);
      },
      () => setSubmitting(false)
    );
  }, [updateOrganization])

  return (
    <div className="bg-white box rounded-lg shadow-lg">
      <ScreenTitle title="Settings » Organization Profile" />
      <GradientHeader
        icon="hospital-alt"
        title="Organization Information"
        subtitle="Update your organization's profile information."
      />
      <div className="ProfileForm">
        {
          loading ? (
            <div className="p-12 text-center">
              <Spinner />
            </div>
          ) : error || !data?.me ? (
            <p>Failed to load.</p>
          ) : (
            <Formik
              initialValues={{
                name: data.me.organization.name,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ status, isSubmitting, handleSubmit }) => (
                <form className="p-6" onSubmit={handleSubmit}>
                  <FormStatusErrors status={status} />

                  <div style={{ marginBottom: "1.75rem" }}>
                    <HorizontalTextField name="name" label="Name" />
                  </div>
                  <HorizontalField label="">
                    <Button
                      type="submit"
                      kind="primary"
                      color="blue"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Save
                    </Button>
                  </HorizontalField>
                </form>
              )}
            </Formik>
          )
        }
      </div>
    </div>
  );
};
