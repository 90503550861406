import { FC } from "react";
import { SSOForm } from "./SSOForm";
import logoSrc from "images/aristamd_logo.svg";

export const SSOScreen: FC = () => {
  return (
    <div className="min-h-screen bg-cool-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto w-48"
          src={logoSrc}
          alt="Preferral Logo"
        />
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <SSOForm />
      </div>
    </div>
  );
};
