import React, { FC } from "react";
import { CircleIcon } from "@preferral/ui";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface CircleIconHeaderProps {
  icon: IconProp;
  color?: AppColor;
}

export const CircleIconHeader: FC<CircleIconHeaderProps> = (props) => {
  const { icon, color = "blue", children } = props;
  return (
    <div className="_CircleIconHeader md:px-4 flex items-center">
      <CircleIcon icon={icon} color={color} />
      <h2 className="ml-3 font-black text-2xl sm:text-3xl text-gray-800">
        {children}
      </h2>
    </div>
  );
};
