import React, { FC, useState, useCallback } from 'react'
import cx from 'classnames'
import { gql, useQuery, useMutation } from '@apollo/client'
import { useFormik, FormikProvider } from 'formik'
import { FormStatusErrors } from 'components/formik/FormStatusErrors'
import { Spinner } from 'components/Spinner'
import { Link, useRouteMatch } from 'react-router-dom'
import { Icon } from '@preferral/ui'
import { FAIcon } from 'components/FAIcon'
import {
  QUESTIONNAIRE_FRAGMENT,
  QuestionnaireModel,
  questionnaireForServer
} from 'components/Questionnaire'
import {
  QuestionnaireTemplateEditor,
  defaultReferralForm
} from 'components/QuestionnaireTemplateEditor';
import toast from 'react-hot-toast'
import { ScreenTitle } from 'context/ScreenTitle'
import { PreviewForm } from './PreviewForm';

const DEPARTMENT_SPECIALTY = gql`
  query GetDepartmentSpecialty($id: UUID4!) {
    departmentSpecialty(id: $id) {
      id
      specialty {
        id
        name
        isCustom
      }
      department {
        id
        name
        organization {
          id
          name
        }
      }
      referralForm {
        ...QuestionnaireFields
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`

interface Data {
  departmentSpecialty: {
    id: string
    specialty: {
      id: string
      name: string
      isCustom: boolean
    }
    department: {
      id: string
      name: string
      organization: {
        id: string
        name: string
      }
    }
    referralForm: QuestionnaireModel
  }
}

interface Variables {
  id: string
}

const UPDATE_REFERRAL_FORM_TEMPLATE = gql`
  mutation UpdateReferralFormTemplate(
    $id: UUID4!
    $referralForm: QuestionnaireInput!
  ) {
    updateReferralFormTemplate(id: $id, referralForm: $referralForm) {
      errors {
        key
        message
      }
      departmentSpecialty {
        id
        specialty {
          id
          name
        }
        department {
          id
          name
        }
        referralForm {
          ...QuestionnaireFields
        }
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`

interface MutationData {
  updateReferralFormTemplate: {
    errors?: InputError[]
    departmentSpecialty?: {
      id: string
      referralForm: QuestionnaireModel
    }
  }
}

type MutationVars = {
  id: string
  referralForm: QuestionnaireModel
}

interface FormValues {
  referralForm: QuestionnaireModel
}

const BackToLink: React.FC<{ to: string; label: string }> = ({ to, label }) => {
  return (
    <Link
      to={to}
      className="text-blue-600 border-b border-blue-600 border-dashed"
    >
      <span className="mr-2">
        <FAIcon icon="chevron-left" />
      </span>{' '}
      {label}
    </Link>
  )
}

const Tabs: React.FC = ({ children }) => {
  return (
    <div className="p-4 flex items-center justify-center -mx-1">{children}</div>
  )
}

const Tab: React.FC<{ active: boolean; onClick: () => void }> = ({
  active,
  onClick,
  children,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cx(
        'mx-1 inline-block border rounded py-1 px-3 font-semibold focus:outline-none focus:shadow-outline',
        {
          'bg-teal-500 text-white border-teal-500 shadow-inner': active,
          'border-white hover:border-gray-200 text-teal-500 hover:bg-gray-200': !active,
        },
      )}
    >
      {children}
    </button>
  )
}

/**
 * EditReferralFormForm.
 */

interface SaveQuestionnaireTemplateResult {
  errors?: InputError[]
  questionnaireTemplate?: QuestionnaireModel
}

interface EditReferralFormFormProps {
  questionnaireTemplate: QuestionnaireModel
  save(template: QuestionnaireModel): Promise<SaveQuestionnaireTemplateResult>
  onSuccess?(): void
}

const EditReferralFormForm: FC<EditReferralFormFormProps> = (props) => {
  const { questionnaireTemplate, save, onSuccess } = props

  const [activeTab, setActiveTab] = useState<'EDITOR' | 'LIVE_PREVIEW'>(
    'EDITOR',
  )

  const viewEditor = useCallback(() => {
    setActiveTab('EDITOR')
  }, [setActiveTab])

  const viewPreview = useCallback(() => {
    setActiveTab('LIVE_PREVIEW')
  },
    [setActiveTab],
  )

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions

      setStatus({ errors: null })

      return save(values.referralForm).then(
        (resp) => {
          if (resp.errors) {
            setStatus({ errors: resp.errors })
          } else if (resp.questionnaireTemplate) {
            // it worked...
            toast.success('Referral form updated')
            if (onSuccess) {
              onSuccess()
            }
          }
          setSubmitting(false)
        },
        (rej) => {
          setStatus({
            errors: [{ key: '', message: 'Something went wrong' }],
          })
        },
      )
    },
    [save, onSuccess],
  )

  const formikBag = useFormik<FormValues>({
    initialValues: {
      referralForm: questionnaireTemplate,
    },
    onSubmit,
  })

  const { values, status, handleSubmit } = formikBag

  return (
    <>
      <Tabs>
        <Tab active={activeTab === 'EDITOR'} onClick={viewEditor}>
          Editor
        </Tab>
        <Tab
          active={activeTab === 'LIVE_PREVIEW'}
          onClick={viewPreview}
        >
          Live Preview
        </Tab>
      </Tabs>

      {activeTab === 'LIVE_PREVIEW' ? (
        <PreviewForm template={values.referralForm} />
      ) : (
        <FormikProvider value={formikBag}>
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <QuestionnaireTemplateEditor name="referralForm" />

            <div className="flex justify-around p-4">
              <button type="submit" className="btn btn-blue">
                Save
              </button>
            </div>
          </form>
        </FormikProvider >
      )}
    </>
  )
}

/**
 * EditReferralForm.
 */

interface EditReferralFormProps { }
interface RouteParams {
  departmentId: string
  departmentSpecialtyId: string
}

export const EditReferralForm: FC<EditReferralFormProps> = (props) => {
  const match = useRouteMatch<RouteParams>()
  const { departmentId, departmentSpecialtyId } = match.params

  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    DEPARTMENT_SPECIALTY,
    { variables: { id: departmentSpecialtyId } },
  )

  const [updateReferralFormTemplate] = useMutation<MutationData, MutationVars>(
    UPDATE_REFERRAL_FORM_TEMPLATE,
  )

  const saveTemplate = useCallback<
    (template: QuestionnaireModel) => Promise<SaveQuestionnaireTemplateResult>
  >(
    (questionnaireTemplate: QuestionnaireModel) => {
      return updateReferralFormTemplate({
        variables: {
          id: departmentSpecialtyId,
          referralForm: questionnaireForServer(questionnaireTemplate),
        },
      }).then((resp) => ({
        errors: resp.data?.updateReferralFormTemplate?.errors,
        questionnaireTemplate:
          resp.data?.updateReferralFormTemplate.departmentSpecialty
            ?.referralForm,
      }))
    },
    [departmentSpecialtyId, updateReferralFormTemplate],
  )

  return (
    <>
      <ScreenTitle title="Settings » Department » Specialty » Edit Referral Form" />
      <div className="EditReferralForm">
        <div>
          <BackToLink
            to={`/o/settings/departments/${departmentId}/specialties`}
            label="Back to Specialties"
          />
        </div>
        <div className="mt-4 bg-white border border-gray-300 p-4 rounded-lg shadow-md">
          {loading ? (
            <div className="p-12">
              <Spinner />
            </div>
          ) : error || !(data && data.departmentSpecialty) ? (
            <h1>Failed to load</h1>
          ) : (
            <>
              <div className="p-5 flex items-center justify-between">
                {/* <div>
                <img src="org-logo-TODO" />
              </div> */}
                <div>
                  <p className="font-semibold text-3xl text-gray-900">
                    {data.departmentSpecialty.specialty.name}{' '}
                    <span className="font-light text-2xl text-gray-600">
                      Referral Form
                    </span>
                  </p>
                  <p className="font-semibold pt-3 text-gray-700">
                    {data.departmentSpecialty.department.organization.name}
                  </p>
                  {data.departmentSpecialty.department.name !==
                    data.departmentSpecialty.department.organization.name ? (
                    <p className="font-semibold text-gray-700">
                      <span className="font-normal text-gray-600 text-xs">
                        Department:
                      </span>{' '}
                      {data.departmentSpecialty.department.name}
                    </p>
                  ) : null}
                </div>
                <div>
                  <div className="flex items-center">
                    <Icon icon="widget-add" />
                    <p className="font-semibold ml-2 text-teal-600 text-xl uppercase">
                      Form Builder
                    </p>
                  </div>
                </div>
              </div>

              <EditReferralFormForm
                questionnaireTemplate={
                  data.departmentSpecialty.referralForm || defaultReferralForm
                }
                save={saveTemplate}
                onSuccess={refetch}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}
