import React, { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "@preferral/ui";
import toast from "react-hot-toast";
import { analytics } from "lib/analytics";

const MUTATION = gql`
  mutation CreateInbox($departmentId: UUID4!, $input: InboxInput!) {
    createInbox(departmentId: $departmentId, input: $input) {
      errors {
        key
        message
      }
      inbox {
        id
      }
    }
  }
`;

interface MutationData {
  createInbox: {
    errors?: InputError[];
    inbox?: {
      id: string;
    };
  };
}

interface MutationVariables {
  departmentId: string;
  input: FormValues;
}

type FormValues = {
  name: string;
};

type NewInboxFormProps = {
  departmentId: string;
  refetchQueries: any;
  closeModal: () => void;
  // onSuccess: (inboxId: string) => void;
};

const NewInboxForm: React.FC<NewInboxFormProps> = ({
  departmentId,
  refetchQueries,
  closeModal,
}) => {
  const [createInbox] = useMutation<MutationData, MutationVariables>(MUTATION);

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      createInbox({
        variables: { departmentId, input: values },
        refetchQueries,
      }).then(
        (resp) => {
          if (resp && resp.data && resp.data.createInbox.errors) {
            setStatus({ errors: resp.data.createInbox.errors });
          } else if (resp && resp.data && resp.data.createInbox.inbox) {
            // it worked
            analytics.track("Add Inbox", {
              name: values.name,
            });
            toast.success("Inbox created");
            closeModal();
          }
          setSubmitting(false);
        },
        (rej) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong" }],
          });
          setSubmitting(false);
        }
      );
    },
    [createInbox, toast, refetchQueries]
  );

  return (
    <div className="NewInboxForm">
      <Formik<FormValues>
        initialValues={{
          name: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .trim()
            .required("Required"),
        })}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />
            <div className="mt-3">
              <TextField name="name" label="Inbox Name" autoFocus />
            </div>
            <div className="flex items-center justify-end mt-3 py-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-blue"
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { NewInboxForm };
