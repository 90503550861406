import React, { useState, useCallback, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { NewEligibilityLookupForm } from "./NewEligibilityLookupForm";
import { CoverageDetails, EligibilityLookup } from "./CoverageDetails";
import { Spinner } from "components/Spinner";
import { FAIcon } from "components/FAIcon";

const INSURANCE_COVERAGE = gql`
  query InsuranceCoverageAndLookups($id: UUID4!) {
    insuranceCoverage(id: $id) {
      id
      healthPlan {
        id
        name
        carrier {
          id
          name
        }
      }
      eligibilityLookups {
        id
        loading
        error
        data
      }
    }
  }
`;

interface Data {
  insuranceCoverage: {
    id: string;
    healthPlan?: {
      id: string;
      name: string;
      carrier: {
        id: string;
        name: string;
      };
    };
    eligibilityLookups: EligibilityLookup[];
  };
}

interface Variables {
  id: string;
}

type EligibilityLookupsProps = {
  insuranceCoverageId: string;
  defaultProviderId?: string;
};

const EligibilityLookups: React.FC<EligibilityLookupsProps> = ({
  insuranceCoverageId,
  defaultProviderId,
}) => {
  const { data, loading, error, startPolling, stopPolling } = useQuery<
    Data,
    Variables
  >(INSURANCE_COVERAGE, {
    variables: { id: insuranceCoverageId },
  });

  // If the most recently created EligibilityLookup is loading, poll for it.
  useEffect(() => {
    if (
      data &&
      data.insuranceCoverage &&
      data.insuranceCoverage.eligibilityLookups &&
      data.insuranceCoverage.eligibilityLookups[0] &&
      data.insuranceCoverage.eligibilityLookups[0].loading
    ) {
      startPolling(500);
    }
    return () => stopPolling();
  }, [data, startPolling, stopPolling]);

  const [formIsOpen, setFormIsOpen] = useState(false);

  const toggleFormOpen = useCallback(() => {
    setFormIsOpen(!formIsOpen);
  }, [formIsOpen]);

  const [viewAllLookups, setViewAllLookups] = useState(false);

  const showOlderLookups = useCallback(() => {
    setViewAllLookups(true);
  }, []);

  return (
    <div className="EligibilityLookups px-6 py-4">
      <button
        className="bg-white border border-gray-500 font-semibold hover:bg-blue-100 hover:border-gray-600 hover:text-gray-700 px-3 py-1 rounded text-gray-600 text-sm focus:outline-none focus:shadow-outline"
        onClick={toggleFormOpen}
      >
        <span className="mr-2">
          <FAIcon icon="plus" />
        </span>
        New Coverage Lookup
      </button>
      {formIsOpen ? (
        <div className="mt-4">
          <NewEligibilityLookupForm
            insuranceCoverageId={insuranceCoverageId}
            onCancel={toggleFormOpen}
            onSuccess={() => setFormIsOpen(false)}
            refetchQueries={[
              {
                query: INSURANCE_COVERAGE,
                variables: { id: insuranceCoverageId },
              },
            ]}
          />
        </div>
      ) : null}

      {loading ? (
        <div className="p-8 text-center">
          <Spinner />
        </div>
      ) : error || !(data && data.insuranceCoverage) ? (
        <p>Failed to Load</p>
      ) : data.insuranceCoverage.eligibilityLookups.length === 0 ? (
        formIsOpen ? null : (
          <div className="mt-16 text-center">
            <div>
              <svg
                className="mx-auto"
                width="128"
                height="127"
                viewBox="0 0 128 127"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="10.6667"
                  y="21.3333"
                  width="106.667"
                  height="85.3333"
                  rx="6"
                  fill="white"
                />
                <path
                  d="M21.3334 21.8333H106.667C109.363 21.8333 111.949 22.9045 113.856 24.8111C115.762 26.7177 116.833 29.3036 116.833 32V96C116.833 98.6964 115.762 101.282 113.856 103.189C111.949 105.096 109.363 106.167 106.667 106.167H21.3334C18.637 106.167 16.0511 105.096 14.1444 103.189C12.2378 101.282 11.1667 98.6964 11.1667 96V32C11.1667 26.4095 15.7428 21.8333 21.3334 21.8333ZM90.6667 36.8333C89.1196 36.8333 87.6359 37.4479 86.5419 38.5419C85.4479 39.6358 84.8334 41.1196 84.8334 42.6667C84.8334 44.2138 85.4479 45.6975 86.5419 46.7914C87.6359 47.8854 89.1196 48.5 90.6667 48.5H101.333C102.88 48.5 104.364 47.8854 105.458 46.7914C106.552 45.6975 107.167 44.2138 107.167 42.6667C107.167 41.1196 106.552 39.6358 105.458 38.5419C104.364 37.4479 102.88 36.8333 101.333 36.8333H90.6667ZM80 58.1667C78.4529 58.1667 76.9692 58.7812 75.8752 59.8752C74.7813 60.9692 74.1667 62.4529 74.1667 64C74.1667 65.5471 74.7813 67.0308 75.8752 68.1248C76.9692 69.2187 78.4529 69.8333 80 69.8333H101.333C102.88 69.8333 104.364 69.2187 105.458 68.1248C106.552 67.0308 107.167 65.5471 107.167 64C107.167 62.4529 106.552 60.9692 105.458 59.8752C104.364 58.7812 102.88 58.1667 101.333 58.1667H80ZM85.3334 79.5C83.7863 79.5 82.3025 80.1146 81.2086 81.2085C80.1146 82.3025 79.5 83.7862 79.5 85.3333C79.5 86.8804 80.1146 88.3642 81.2086 89.4581C82.3025 90.5521 83.7863 91.1666 85.3334 91.1666H101.333C102.88 91.1666 104.364 90.5521 105.458 89.4581C106.552 88.3642 107.167 86.8804 107.167 85.3333C107.167 83.7862 106.552 82.3025 105.458 81.2085C104.364 80.1146 102.88 79.5 101.333 79.5H85.3334Z"
                  fill="#E2E8F0"
                  stroke="#718096"
                />
                <path
                  d="M42.6666 64C38.4232 64 34.3535 62.3143 31.3529 59.3137C28.3524 56.3131 26.6666 52.2435 26.6666 48C26.6666 43.7565 28.3524 39.6869 31.3529 36.6863C34.3535 33.6857 38.4232 32 42.6666 32C46.9101 32 50.9798 33.6857 53.9804 36.6863C56.9809 39.6869 58.6666 43.7565 58.6666 48C58.6666 52.2435 56.9809 56.3131 53.9804 59.3137C50.9798 62.3143 46.9101 64 42.6666 64ZM32 74.6667H53.3333C56.1623 74.6667 58.8754 75.7905 60.8758 77.7909C62.8762 79.7913 64 82.5044 64 85.3333V90.6667C64 92.0812 63.4381 93.4377 62.4379 94.4379C61.4377 95.4381 60.0811 96 58.6666 96H26.6666C25.2522 96 23.8956 95.4381 22.8954 94.4379C21.8952 93.4377 21.3333 92.0812 21.3333 90.6667V85.3333C21.3333 79.4667 26.1333 74.6667 32 74.6667Z"
                  fill="#2D3748"
                />
              </svg>
            </div>
            <div className="text-lg text-gray-700 leading-relaxed">
              <p>No coverage lookups have been performed.</p>
              <p>Click "New Coverage Lookup" to lookup coverage details.</p>
            </div>
          </div>
        )
      ) : (
        data.insuranceCoverage.eligibilityLookups.map((el, idx) =>
          idx === 0 || viewAllLookups ? (
            <CoverageDetails key={el.id} eligibilityLookup={el} />
          ) : idx === 1 ? (
            <div key={el.id} className="p-4 text-center">
              <button
                className="text-blue-500 text-sm"
                onClick={showOlderLookups}
              >
                Show older lookups
              </button>
            </div>
          ) : null
        )
      )}
    </div>
  );
};

export { EligibilityLookups };
