import { loader } from "graphql.macro";

export const APPOINTMENT_REQUEST_FRAGMENT = loader(
  "./AppointmentRequestFields.graphql"
);
export const APPOINTMENT_REQUEST_EVENT_FRAGMENT = loader(
  "./AppointmentRequestEventFields.graphql"
);

export const LOCATION_FRAGMENT = loader("./LocationFields.graphql");
export const NPPES_LOCATION_FRAGMENT = loader("./NppesLocationFields.graphql");
export const UNLISTED_LOCATION_FRAGMENT = loader(
  "./UnlistedLocationFields.graphql"
);

export const PATIENT_MEMBER_FRAGMENT = loader("./PatientMemberFields.graphql");
export const PROVIDER_FRAGMENT = loader("./ProviderFields.graphql");
export const NPPES_PROVIDER_FRAGMENT = loader("./NppesProviderFields.graphql");
export const UNLISTED_PROVIDER_FRAGMENT = loader(
  "./UnlistedProviderFields.graphql"
);
