import { FC } from "react";
import { gql, useQuery } from '@apollo/client';
import { Button, CircleIcon, JsonDebugger, Spinner, Table, TableContainer, TD, TH } from "@preferral/ui";
import { iso8601Date } from "lib/dateFormatters";
import { TimeRange, ValueLabelData } from "../model";
import { BarChart } from "../charts/BarChart";
import { NoResults } from "components/NoResults";

const SENT_REFERRALS_BY_REFERRING_LOCATION = gql`
  query SentReferralsByReferringLocation($dateRange: InclusiveDateRangeInput!) {
    analyticsSentReferralsByReferringLocation(dateRange: $dateRange) {
      data {
        value
        label
      }
    }
  }
`;

interface Data {
  analyticsSentReferralsByReferringLocation: ValueLabelData;
}

/**
 * SentReferralsByReferringLocation.
 */

interface SentReferralsByReferringLocationProps {
  timeRange: TimeRange;
}

export const SentReferralsByReferringLocation: FC<SentReferralsByReferringLocationProps> = props => {
  const { timeRange } = props;

  const dateRange = {
    start: iso8601Date(timeRange.start),
    finish: iso8601Date(timeRange.finish)
  };

  const { data, loading, error, refetch } = useQuery<Data>(SENT_REFERRALS_BY_REFERRING_LOCATION, {
    variables: { dateRange }
  });

  return (
    <div className="_EConsultVolume p-4 bg-white rounded-lg border shadow-xl">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <CircleIcon icon="chart-bar" />
          <p className="text-xl font-semibold ml-4">
            Sent Referrals by Referring Location
          </p>
        </div>
      </div>
      {
        loading ? (
          <div className="p-8 text-center">
            <Spinner />
          </div>
        ) : error || !data?.analyticsSentReferralsByReferringLocation ? (
          <div className="p-8 text-center">
            <p>Failed to load.</p>
            <Button
              type="button"
              size="sm"
              className="mt-4"
              disabled={loading}
              isLoading={loading}
              onClick={() => refetch()}
            >
              Retry
            </Button>
          </div>
        ) : data.analyticsSentReferralsByReferringLocation.data.length === 0 ? (
          <NoResults icon="chart-bar" text="No data for time range" />
        ) : (
          <>
            <div className="p-8 text-left">
              <BarChart
                data={data?.analyticsSentReferralsByReferringLocation.data}
                bars={[{ dataKey: "value", fill: "#8884d8" }]}
                xValKey={"label"}
              />
            </div>
            <TableContainer>
              <Table>
                <thead>
                  <tr>
                    <TH>Location</TH>
                    <TH># Sent Referrals</TH>
                  </tr>
                </thead>
                <tbody>
                  {data?.analyticsSentReferralsByReferringLocation.data.map((ds) => (
                    <tr key={ds.label}>
                      <TD>{ds.label}</TD>
                      <TD>{ds.value}</TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          </>
        )
      }
      <JsonDebugger data={data} />
    </div>
  );
};
