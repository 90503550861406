import { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button } from "@preferral/ui";
import { FileUploadField } from "components/formik/FileUploadField";
import toast from "react-hot-toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { useAppointmentRequest } from "../../AppointmentRequestContext";

const ADD_FILES = gql`
  mutation AddFileUploads(
    $appointmentRequestId: UUID4!
    $fileUploadIds: [UUID4!]!
  ) {
    addAppointmentRequestFiles(
      appointmentRequestId: $appointmentRequestId
      fileUploadIds: $fileUploadIds
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
      }
    }
  }
`;

interface MutationData {
  addAppointmentRequestFiles: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appointmentRequestId: string;
  fileUploadIds: string[];
}

/**
 * AddFilesForm.
 */

interface FormValues {
  fileUploadIds: string[];
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    fileUploadIds: Yup.array(Yup.string().required()).required(),
  })
  .required();

const initialValues: FormValues = {
  fileUploadIds: [],
};

interface AddFilesFormProps {
  onSuccess(): void;
}

export const AddFilesForm: FC<AddFilesFormProps> = (props) => {
  const { onSuccess } = props;

  const [attachFiles] = useMutation<MutationData, MutationVariables>(ADD_FILES);

  const {
    appointmentRequest: { id: appointmentRequestId },
    refetch,
  } = useAppointmentRequest();

  const onSubmit = useCallback(
    (values, { setStatus, setSubmitting, resetForm }) => {
      setStatus({ errors: null });
      return attachFiles({
        variables: {
          appointmentRequestId,
          fileUploadIds: values.fileUploadIds,
        },
      }).then((resp) => {
        if (resp.data?.addAppointmentRequestFiles.errors) {
          setStatus({ errors: resp.data.addAppointmentRequestFiles.errors });
        } else if (resp.data?.addAppointmentRequestFiles.appointmentRequest) {
          // it worked
          toast.success("Files added");
          resetForm();
          refetch();
          if (onSuccess) {
            return onSuccess();
          }
        }
        setSubmitting(false);
      });
    },
    [refetch, appointmentRequestId, attachFiles, onSuccess]
  );
  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          <FileUploadField name="fileUploadIds" label="Upload Files" />

          <div className="flex items-center justify-end mt-3 py-4">
            <Button
              type="submit"
              color="blue"
              kind="primary"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Add Files
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
