import React, { FC, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { gql, useMutation } from "@apollo/client";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { FileUploadField } from "components/formik/FileUploadField";
import { Button } from "@preferral/ui";
import { analytics } from "lib/analytics";

const IMPORT_PROVIDERS = gql`
  mutation ImportProviderList($fileUploadId: UUID4!) {
    importProviderList(fileUploadId: $fileUploadId) {
      errors {
        key
        message
      }
      success
    }
  }
`;

interface MutationData {
  importProviderList: {
    errors?: InputError[];
    success?: boolean;
  };
}

interface MutationVariables {
  fileUploadId: string;
}

interface FormValues {
  fileUploadId: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    fileUploadId: Yup.array()
      .of(Yup.string().required("Required"))
      .required("Required")
      .min(1)
      .max(1),
  })
  .required();

const initialValues: FormValues = {
  fileUploadId: "",
};

interface ImportProvidersFormProps {
  onSuccess(): void;
}

export const ImportProvidersForm: FC<ImportProvidersFormProps> = (props) => {
  const { onSuccess } = props;

  const [importProviders] = useMutation<MutationData, MutationVariables>(
    IMPORT_PROVIDERS
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      try {
        const { data } = await importProviders({
          variables: { fileUploadId: values.fileUploadId[0] },
        });

        if (data?.importProviderList.errors) {
          setStatus({ errors: data.importProviderList.errors });
        } else if (data?.importProviderList.success) {
          // it worked...
          analytics.track("Import Provider");
          toast.success("Success!");
          onSuccess();
        }
      } catch (e) {
        console.error(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
      } finally {
        setSubmitting(false);
      }
    },
    [onSuccess]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, status }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <div className="mt-3">
            <FileUploadField
              name="fileUploadId"
              label="Provider List File"
              acceptFileTypes={["text/csv"]}
            />
          </div>
          <div className="flex items-center justify-end mt-3">
            <Button
              type="submit"
              kind="primary"
              color="blue"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Import List
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
