import { FC, InputHTMLAttributes } from "react";
import { InputText, Spinner, FAIcon, Popper } from "@preferral/ui";
import { gql, useQuery } from "@apollo/client";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useCombobox } from "downshift";
import { useDebounce } from "use-debounce/lib";

const PATIENT_SEARCH = gql`
  query SearchPatients($filter: SearchPatientsFilter) {
    searchPatients(filter: $filter) {
      items {
        patient {
          id
          firstName
          lastName
          dob
          primaryPhone {
            formatted
          }
          patientMembers {
            id
            medicalRecordNumber
          }
        }
        amdPatient {
          id
          firstName
          lastName
          dateOfBirth
          referenceId
        }
      }
    }
  }
`;

interface Data {
  searchPatients: {
    items: PatientSearchResult[];
  };
}

interface Variables {
  filter?: PatientSearchFilter;
}

export interface PatientSearchFilter {
  searchTerm?: string;
}

export interface PatientSearchResult {
  patient?: {
    id: string;
    firstName: string;
    lastName: string;
    dob: string;
    primaryPhone?: {
      formatted: string;
    };
    patientMembers: PatientMember[];
  },
  amdPatient?: {
    id: number;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    referenceId: string;
  }
}

export interface PatientMember {
  id: string;
  medicalRecordNumber?: string;
}

/**
 * PatientSearchInput.
 */
interface PatientSearchInputProps {
  value: string;
  icon?: IconProp;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  className?: string;
  onChange(newValue: string): void;
  onBlur?(e: any): void;
  onFocus?(e: any): void;
  onPatientClick(patient: PatientSearchResult): void;
  filter?: PatientSearchFilter;
  externalLoading?: boolean;
}

export const PatientSearchInput: FC<PatientSearchInputProps> = (props) => {
  const {
    value,
    className,
    onChange,
    filter = {},
    inputProps = {},
    icon,
    onPatientClick,
    externalLoading,
  } = props;

  const [debouncedValue] = useDebounce(value, 400);
  const { data, loading } = useQuery<Data, Variables>(PATIENT_SEARCH, {
    variables: {
      filter: {
        ...filter,
        searchTerm: debouncedValue,
      },
    },
  });

  const {
    isOpen,
    getInputProps,
    getComboboxProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox<PatientSearchResult>({
    initialInputValue: value || "",
    items: data?.searchPatients.items || [],
    onInputValueChange({ inputValue }) {
      onChange(inputValue || "");
    },
    onSelectedItemChange({ selectedItem }) {
      if (selectedItem) {
        onPatientClick(selectedItem);
      }
    },
    itemToString(item) {
      const patient = item.patient || item.amdPatient
      return `${patient?.lastName}, ${patient?.firstName}`;
    },
  });

  return (
    <div className="relative">
      <Popper
        placement={{
          horizontal: "left",
          vertical: "bottom",
        }}
        widthFull
        content={
          isOpen && (
            <div className="relative">
              <div
                className="bg-white border mt-1 py-2 rounded shadow-xl w-full"
                {...getMenuProps()}
              >
                <p className="font-semibold leading-tight select-none text-center text-gray-600 text-xs">
                  Type to search
                </p>
                {data?.searchPatients ? (
                  data.searchPatients.items.length === 0 ? (
                    <p className="font-light py-6 text-center text-gray-600 text-lg">
                      <span className="mr-2 text-base text-gray-500">
                        <FAIcon icon="search" />
                      </span>
                      No Results
                    </p>
                  ) : (
                    data.searchPatients.items.map((item, index) => {
                      return item.patient ? (
                        <div
                          key={item.patient.id}
                          className={`PatientSearchResult cursor-pointer hover:bg-blue-200 px-3 py-1 transition-colors transition-faster ${index === highlightedIndex ? "bg-blue-200" : ""
                            }`}
                          {...getItemProps({
                            item,
                            index,
                          })}
                        >
                          <p className="font-semibold text-gray-800">
                            {item.patient.lastName}, {item.patient.firstName}
                          </p>
                          <div className="flex gap-3">
                            {
                              item.patient.patientMembers.find(pm => pm.medicalRecordNumber) ? (
                                <p className="text-gray-700 text-xs">
                                  <span className="font-light text-gray-600 italic mr-1">
                                    MRN:
                                  </span>
                                  {item.patient.patientMembers.find(pm => pm.medicalRecordNumber)?.medicalRecordNumber}
                                </p>
                              ) : null
                            }
                            <p className="text-gray-700 text-xs">
                              <span className="font-light text-gray-600 italic mr-1">
                                DOB:
                              </span>
                              {item.patient.dob}
                            </p>
                          </div>
                          {process.env.NODE_ENV !== "production" ? (
                            <p className="text-gray-700 text-xs italic" >Source: Preferral</p>
                          ) : null}
                        </div>
                      ) : (
                        <div
                          key={item.amdPatient?.id}
                          className={`PatientSearchResult cursor-pointer hover:bg-blue-200 px-3 py-1 transition-colors transition-faster ${index === highlightedIndex ? "bg-blue-200" : ""
                            }`}
                          {...getItemProps({
                            item,
                            index,
                          })}
                        >
                          <p className="font-semibold text-gray-800">
                            {item.amdPatient?.lastName}, {item.amdPatient?.firstName}
                          </p>
                          <div className="flex gap-3">
                            <p className="text-gray-700 text-xs">
                              <span className="font-light text-gray-600 italic mr-1">
                                MRN:
                              </span>
                              {item.amdPatient?.referenceId}
                            </p>
                            <p className="text-gray-700 text-xs">
                              <span className="font-light text-gray-600 italic mr-1">
                                DOB:
                              </span>
                              {item.amdPatient?.dateOfBirth}
                            </p>
                          </div>
                          {process.env.NODE_ENV !== "production" ? (
                            <p className="text-gray-700 text-xs italic" >Source: Arista MD</p>
                          ) : null}
                        </div>
                      )
                    })
                  )
                ) : loading || externalLoading ? (
                  <div className="text-center p-4">
                    <Spinner />
                  </div>
                ) : (
                  <p>Failed to load.</p>
                )}
              </div>
            </div>
          )
        }
      >
        <div className="_PatientSearchInput" {...getComboboxProps()}>
          <InputText
            isLoading={loading}
            className={className}
            value={value}
            onChange={onChange}
            icon={icon}
            inputProps={{
              ...inputProps,
              ...getInputProps(),
              autoComplete: "new",
            }}
          />
        </div>
      </Popper>
    </div>
  );

  // return (
  //   <div className="relative">
  //     <div className="PatientSearchInput" {...getComboboxProps()}>
  //       <InputText
  //         isLoading={loading}
  //         value={value}
  //         onChange={onChange}
  //         icon={icon}
  //         inputProps={{
  //           ...inputProps,
  //           ...getInputProps(),
  //           autoComplete: "disabled"
  //         }}
  //       />
  //     </div>
  //     {isOpen && (
  //       <div className="relative">
  //         <div
  //           className="bg-white border max-w-md mt-1 py-2 rounded shadow-xl w-full absolute"
  //           {...getMenuProps()}
  //         >
  //           <p className="font-semibold leading-tight select-none text-center text-gray-600 text-xs">
  //             Type to search
  //           </p>
  //           {data?.searchPatients ? (
  //             data.searchPatients.items.length === 0 ? (
  //               <p className="font-light py-6 text-center text-gray-600 text-lg">
  //                 <span className="mr-2 text-base text-gray-500">
  //                   <FAIcon icon="search" />
  //                 </span>
  //                 No Results
  //               </p>
  //             ) : (
  //               data.searchPatients.items.map((item, index) => (
  //                 <div
  //                   key={item.id}
  //                   className={`PatientSearchResult cursor-pointer hover:bg-blue-200 px-3 py-1 transition-colors transition-faster ${
  //                     index === highlightedIndex && "bg-blue-200"
  //                   }`}
  //                   {...getItemProps({
  //                     item,
  //                     index
  //                   })}
  //                 >
  //                   <p className="font-semibold text-gray-800">
  //                     {item.lastName}, {item.firstName}
  //                   </p>
  //                   <p className="text-gray-700 text-xs">
  //                     <span className="font-light text-gray-600 italic mr-1">
  //                       DOB:
  //                     </span>
  //                     {item.dob}
  //                   </p>
  //                 </div>
  //               ))
  //             )
  //           ) : loading ? (
  //             <div className="text-center p-4">
  //               <Spinner />
  //             </div>
  //           ) : (
  //             <p>Failed to load.</p>
  //           )}
  //         </div>
  //       </div>
  //     )}
  //   </div>
  // );
};
