import { FC, useMemo, useState, useCallback } from "react";
import { getMonth, lightFormat } from "date-fns";
import { DateRangePicker, Range } from "react-date-range";
import { TimeRange } from "./model";
import { FAIcon, Button } from "@preferral/ui";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./DateRangeDropdown.css";

interface DateRangeDropdownProps {
  id?: string;
  value: TimeRange;
  onChange(timeRange: TimeRange): void;
}

export const DateRangeDropdown: FC<DateRangeDropdownProps> = (props) => {
  const { id, value, onChange } = props;
  const { start, finish } = value;

  const propRange = useMemo(() => ({
    startDate: start as any,
    endDate: finish as any,
  }), [start, finish]) as Range;

  const [isOpen, setIsOpen] = useState(false);
  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const [selectedRange, setSelectedRange] = useState<Range>(propRange);

  const handleApply = useCallback(() => {
    onChange({
      start: (selectedRange.startDate! as any) as Date,
      finish: (selectedRange.endDate! as any) as Date,
    });
    close();
  }, [selectedRange, onChange, close]);

  const handleCancel = useCallback(() => {
    setSelectedRange(propRange);
    close();
  }, [propRange, close]);

  return (
    <div id={id} className="DateRangeDropdown">
      <div className="DateRangeDropdown__container">
        <div
          className="DateRangeDropdown__trigger"
          onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
        >
          <div className="DateRangeDropdown__trigger__date">
            {formatDate(start)}
          </div>
          <div className="DateRangeDropdown__trigger__date-divider">
            <FAIcon icon="arrow-right" />
          </div>
          <div className="DateRangeDropdown__trigger__date">
            {formatDate(finish)}
          </div>
          <div className="DateRangeDropdown__trigger__chevron">
            <FAIcon icon="chevron-down" />
          </div>
        </div>
        <div
          className={`DateRangeDropdown__content ${isOpen
            ? "DateRangeDropdown__content--open"
            : "DateRangeDropdown__content--closed"
            }`}
        >
          <div className="DateRangeDropdown__content__body">
            <DateRangePicker
              ranges={[{ ...selectedRange, key: "selection" }]}
              onChange={(e: any) => setSelectedRange(e.selection)}
              twoStepChange={false}
            />
          </div>
          <div className="DateRangeDropdown__content__footer">
            <Button
              type="button"
              kind="tertiary"
              color="gray"
              className="mr-2"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button type="button" color="blue" onClick={handleApply}>
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

function formatDate(date: Date): string {
  return `${monthStr(date)} ${lightFormat(date, "d, yyyy")}`;
}

const monthStrings = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

function monthStr(date: Date): string {
  return monthStrings[getMonth(date)];
}
