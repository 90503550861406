import { FC } from "react";
import {
  InputTextMask,
  InputTextMaskProps,
  TextMaskInputProps,
  TextMaskInput,
  TextMaskFieldProps,
  TextMaskField,
  HorizontalTextMaskField,
} from "@preferral/ui";

const mask = [/\d/, /\d/, ":", /[0-5]/, /\d/, " ", /(a|p)/, "m"];

/**
 * InputTimeMask.
 */

export type InputTimeMaskProps = Omit<InputTextMaskProps, "mask" | "pipe">;

export const InputTimeMask: FC<InputTimeMaskProps> = (props) => {
  const { placeholder = "hh:mm am" } = props;
  return (
    <InputTextMask
      mask={mask}
      pipe={pipe}
      placeholder={placeholder}
      {...props}
    />
  );
};

/**
 * TimeMaskInput.
 */

export type TimeMaskInputProps = Omit<TextMaskInputProps, "mask" | "pipe">;

export const TimeMaskInput: FC<TimeMaskInputProps> = (props) => {
  const { placeholder = "hh:mm am" } = props;
  return (
    <TextMaskInput
      mask={mask}
      pipe={pipe}
      placeholder={placeholder}
      {...props}
    />
  );
};

/**
 * TimeMaskField.
 */

export type TimeMaskFieldProps = Omit<TextMaskFieldProps, "mask" | "pipe">;

export const TimeMaskField: FC<TimeMaskFieldProps> = (props) => {
  const { placeholder = "hh:mm am" } = props;
  return (
    <TextMaskField
      mask={mask}
      pipe={pipe}
      placeholder={placeholder}
      {...props}
    />
  );
};

export const HorizontalTimeMaskField: FC<TimeMaskFieldProps> = (props) => {
  const { placeholder = "hh:mm am" } = props;
  return (
    <HorizontalTextMaskField
      mask={mask}
      pipe={pipe}
      placeholder={placeholder}
      {...props}
    />
  );
};

/**
 * A Pipe that takes the user input, and if the input is an tens-hour > 1,
 * pipe in a leading "0".
 */
function pipe(
  conformedValue: string
): false | string | { value: string; indexesOfPipedChars: number[] } {
  const indexesOfPipedChars = [];
  const conformedValueArr = conformedValue.split("");

  // Check first digit
  if (parseInt(conformedValue[0], 10) > 1) {
    conformedValueArr[1] = conformedValueArr[0];
    conformedValueArr[0] = "0";
    indexesOfPipedChars.push(0);
  }

  return { value: conformedValueArr.join(""), indexesOfPipedChars };
}
