import React, { FC, useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ScreenTitle } from "context/ScreenTitle";
import { Formik, FieldArray, ErrorMessage } from "formik";
import {
  ProviderSearchSelect,
  ProviderReferenceModel,
} from "components/ProviderSearchSelect";
import { PatientSearchTextField } from "components/formik/PatientSearchTextField";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import {
  TextField,
  DateMaskField,
  SelectField,
  HorizontalTextField,
  RadioGroupInput,
  StandardOption,
  VerticalField,
  Button,
  FAIcon,
  useWaitFor,
  useComponentId,
  useFileUploader,
  FadeUpIn,
} from "@preferral/ui";
import { SelectedPatientMember } from "./SelectedPatientMember";
import { CircleIconHeader } from "components/CircleIconHeader";
// import {
//   HealthPlanSelectField,
//   HorizontalHealthPlanSelectField,
// } from "./HealthPlanSelectField";
import { HorizontalHealthPlanPickerField } from "components/formik/HealthPlanPickerField";
import { FileUploadInput } from "components/formik/FileUploadField";
import { gql, useQuery, useMutation } from "@apollo/client";
import uniqBy from "lodash.uniqby";
import Select from "react-select";
import {
  ProviderLocationSelect,
  LocationReferenceModel,
} from "components/ProviderLocationSelect";
import { localDateToISO } from "@preferral/common";
import { HealthPlanValue } from "components/HealthPlanPicker";
import { PatientSearchResult } from "components/PatientSearchInput";
import { useSyncPatientMember } from "screens/EConsultShowScreen/useSyncPatientMember";
import { toast } from "react-hot-toast";

const SPECIALTY_OPTIONS = gql`
  query GetSpecialties {
    me {
      id
      departments {
        id
        name
        departmentSpecialties {
          id
          specialty {
            id
            name
          }
        }
      }
    }
  }
`;

interface Data {
  me: {
    id: string;
    departments: {
      id: string;
      name: string;
      departmentSpecialties: {
        id: string;
        specialty: {
          id: string;
          name: string;
        };
      }[];
    }[];
  };
}

const CREATE_INBOUND_REFERRAL = gql`
  mutation CreateInboundReferral($input: InboundReferralInput!) {
    createInboundReferral(input: $input) {
      errors {
        key
        message
      }
      inboundReferral {
        id
      }
    }
  }
`;

interface MutationData {
  createInboundReferral: {
    errors?: InputError[];
    inboundReferral?: {
      id: string;
    };
  };
}

interface MutationVariables {
  input: InboundReferralInput;
}

interface InboundReferralInput {
  departmentId: string;
  specialtyId: string;
  fileUploadIds: string[];
  patientMemberId?: string;
  patientMember?: {
    firstName: string;
    lastName: string;
    dob: string;
    isSelfPay: boolean;
    insuranceCoverages: InsuranceCoverageFormModel[];
    medicalRecordNumber?: string;
    ssn?: string;
    primaryPhone?: string;
  };
  referringProvider: ProviderReferenceModel;
  referringLocation: LocationReferenceModel;
}

type InsuranceType = "SELF_PAY" | "INSURANCE";

const InsuranceTypes: Record<string, InsuranceType> = {
  SELF_PAY: "SELF_PAY",
  INSURANCE: "INSURANCE",
};

const InsuranceTypeOptions = [
  {
    label: "Patient has insurance",
    value: InsuranceTypes.INSURANCE,
  },
  {
    label: "Patient plans to self-pay",
    value: InsuranceTypes.SELF_PAY,
  },
];

interface FormValues {
  referringProvider: ProviderReferenceModel | null;
  referringLocation: LocationReferenceModel | null;
  patientMember: {
    firstName: string;
    lastName: string;
    dob: string;
    insuranceType: InsuranceType;
    insuranceCoverages: InsuranceCoverageFormModel[];
  };
  specialtyId: string;
  fileUploadIds: string[];
}

interface InsuranceCoverageFormModel {
  healthPlan?: HealthPlanValue;
  unlistedCarrierName: string;
  unlistedHealthPlanName: string;
  membershipNumber: string;
}

function validateForm(values: any) {
  let errors: Record<string, string> = {};
  // console.log("VALUES", values);
  if (!values.referringProvider) {
    errors.referringProvider = "Referring Provider Required";
  }
  if (!values.referringLocation) {
    errors.referringLocation = "Referring Location Required";
  }
  return errors;
}

/**
 * PatientStep.
 */

interface PatientStepProps { }

export const PatientStep: FC<PatientStepProps> = () => {
  const [selectedPatientMemberId, setSelectedPatientMemberId] = useState<
    string | null
  >(null);

  const [
    referringProvider,
    setReferringProvider,
  ] = useState<ProviderReferenceModel | null>(null);

  const [
    referringLocation,
    setReferringLocation,
  ] = useState<LocationReferenceModel | null>(null);

  /**
   * Grab a promise we can wait on while file uploads
   * are finishing.
   */
  const instanceId = useComponentId();
  const {
    state: { isUploading },
  } = useFileUploader({
    instanceId,
  });

  const checkUploadsComplete = useCallback(() => !isUploading, [isUploading]);
  const [waitForUploads] = useWaitFor(checkUploadsComplete);

  // When provider is cleared, clear location
  useEffect(() => {
    if (!referringProvider) {
      setReferringLocation(null);
    }
  }, [referringProvider]);

  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");

  const { data, loading } = useQuery<Data>(SPECIALTY_OPTIONS);

  const [createInboundReferral] = useMutation<MutationData, MutationVariables>(
    CREATE_INBOUND_REFERRAL
  );

  const history = useHistory();

  const { syncPatientMember, loading: syncPatientLoading } = useSyncPatientMember();

  const specialtyOptions = getSpecialtyOptions(data, selectedDepartmentId);
  const departmentOptions = getDepartmentOptions(data);
  const departmentSelectVisible = !(data && departmentOptions.length === 1);

  const onPatientSearchSelection = useCallback(async (patient: PatientSearchResult) => {
    if (patient.patient) {
      setSelectedPatientMemberId(
        patient.patient.patientMembers[0].id
      )
    } else if (patient.amdPatient) {
      const { patientMember, errors } = await syncPatientMember(patient.amdPatient?.id)
      if (patientMember) {
        setSelectedPatientMemberId(
          patientMember.id
        )
      } else if (errors) {
        toast.error(errors[0].message);
      } else {
        toast.error("Error selecting patient");
      }
    }
  }, [syncPatientMember])

  // Autoselect department
  useEffect(() => {
    if (!selectedDepartmentId && departmentOptions.length === 1) {
      setSelectedDepartmentId(departmentOptions[0].value);
    }
  }, [selectedDepartmentId, setSelectedDepartmentId, departmentOptions]);

  const additionalValues = {
    referringProvider,
    referringLocation,
  };

  return (
    <div className="PatientStep px-4">
      <ScreenTitle title={["Inbound Request", "Patient Info"]} />

      <FadeUpIn show>
        <div className="bg-white max-w-5xl mx-auto mb-8 p-4 rounded-xl shadow-lg text-left w-full">
          <CircleIconHeader icon="user-doctor">
            New Inbound Referral
          </CircleIconHeader>

          <section className="mt-8">
            <Formik<FormValues>
              initialValues={{
                referringProvider: null,
                referringLocation: null,
                patientMember: {
                  firstName: "",
                  lastName: "",
                  dob: "",
                  insuranceType: InsuranceTypes.INSURANCE,
                  insuranceCoverages: [
                    {
                      healthPlan: {
                        carrierId: null,
                        healthPlanId: null,
                        ribbonHealthPlanUuid: null
                      },
                      unlistedCarrierName: "",
                      unlistedHealthPlanName: "",
                      membershipNumber: "",
                    },
                  ],
                },
                specialtyId: "",
                fileUploadIds: [],
              }}
              validate={(values) =>
                validateForm({ ...values, ...additionalValues })
              }
              onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus({ errors: null });

                let dob = values.patientMember.dob
                  ? localDateToISO(values.patientMember.dob)
                  : "";

                const isSelfPay =
                  values.patientMember.insuranceType === "SELF_PAY";

                const insuranceCoverages = (isSelfPay
                  ? []
                  : values.patientMember.insuranceCoverages).map(ic => {
                    const insuranceInput = {
                      ...ic,
                      ...(ic.healthPlan || {})
                    };
                    delete insuranceInput.healthPlan;
                    return insuranceInput
                  });

                let input: InboundReferralInput = {
                  referringProvider: referringProvider!,
                  referringLocation: referringLocation!,
                  fileUploadIds: values.fileUploadIds,
                  specialtyId: values.specialtyId,
                  departmentId: selectedDepartmentId,
                  patientMemberId: selectedPatientMemberId || undefined,
                  patientMember: selectedPatientMemberId
                    ? undefined
                    : {
                      firstName: values.patientMember.firstName,
                      lastName: values.patientMember.lastName,
                      isSelfPay,
                      insuranceCoverages,
                      dob,
                    },
                };

                return waitForUploads().then(
                  () => {
                    return createInboundReferral({ variables: { input } }).then(
                      (resp) => {
                        if (resp.data?.createInboundReferral.errors) {
                          setStatus({
                            errors: resp.data.createInboundReferral.errors,
                          });
                        } else if (
                          resp.data?.createInboundReferral.inboundReferral
                        ) {
                          // it worked
                          const {
                            id,
                          } = resp.data.createInboundReferral.inboundReferral;
                          return history.push(
                            `/o/inbound_referral/${id}/questions`
                          );
                        }
                        setSubmitting(false);
                      }
                    );
                  },
                  () => setSubmitting(false)
                );
                // setSubmitting(false);
              }}
            >
              {({ handleSubmit, values, isSubmitting, status }) => (
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <FormStatusErrors status={status} />
                  <div className="sm:grid sm:grid-cols-2 sm:gap-6 px-3">
                    <div className="_patient-info">
                      {selectedPatientMemberId ? (
                        <SelectedPatientMember
                          patientMemberId={selectedPatientMemberId}
                          onClearSelection={() =>
                            setSelectedPatientMemberId(null)
                          }
                        />
                      ) : (
                        <>
                          <div className="mt-3">
                            <PatientSearchTextField
                              name="patientMember.lastName"
                              label="Patient Last Name"
                              icon="search"
                              autoFocus
                              onPatientClick={onPatientSearchSelection}
                              externalLoading={syncPatientLoading}
                            />
                          </div>
                          <div className="mt-3 flex -mx-2">
                            <div className="flex-1 px-2">
                              <TextField
                                name="patientMember.firstName"
                                label="Patient First Name"
                              />
                            </div>
                            <div className="flex-1 px-2">
                              <DateMaskField
                                name="patientMember.dob"
                                label="Patient DOB"
                              />
                            </div>
                          </div>
                          <div className="mt-6">
                            <RadioGroupInput
                              name="patientMember.insuranceType"
                              options={InsuranceTypeOptions}
                              className="font-medium justify-center sc-fzXfMz text-sm"
                              inline
                            />
                          </div>
                          {values.patientMember.insuranceType ===
                            InsuranceTypes.INSURANCE ? (
                            <>
                              <FieldArray name="patientMember.insuranceCoverages">
                                {({ insert, remove }) => (
                                  <>
                                    {values.patientMember.insuranceCoverages.map(
                                      (ic, index) => (
                                        <div
                                          key={index}
                                          className="bg-white border m-3 p-4 rounded-lg rounded-xl shadow-lg"
                                        >
                                          <div className="flex items-center justify-between">
                                            <h6 className="text-xs font-semibold text-gray-700">
                                              {index === 0
                                                ? "Primary"
                                                : "Supplemental"}{" "}
                                              Insurance
                                            </h6>
                                            {index > 0 ? (
                                              <button
                                                type="button"
                                                className="btn btn-red-alt btn-sm"
                                                onClick={() => remove(index)}
                                              >
                                                Remove
                                              </button>
                                            ) : (
                                              <div />
                                            )}
                                          </div>
                                          <div className="mt-3">
                                            {/* <HorizontalHealthPlanSelectField
                                            name={`patientMember.insuranceCoverages.${index}.healthPlanId`}
                                            label="Health Plan"
                                          /> */}
                                            <HorizontalHealthPlanPickerField
                                              name={`patientMember.insuranceCoverages.${index}.healthPlan`}
                                              label="Health Plan"
                                            />
                                          </div>
                                          <div className="mt-3">
                                            <HorizontalTextField
                                              name={`patientMember.insuranceCoverages.${index}.membershipNumber`}
                                              label="Member ID"
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                    <div className="text-center">
                                      <button
                                        type="button"
                                        className="bg-white border btn btn-sm hover:bg-blue-100 transition-colors transition-medium"
                                        onClick={() =>
                                          insert(
                                            values.patientMember
                                              .insuranceCoverages.length,
                                            {
                                              healthPlanId: "",
                                              unlistedCarrierName: "",
                                              unlistedHealthPlanName: "",
                                              membershipNumber: "",
                                            }
                                          )
                                        }
                                      >
                                        <span className="mr-2">
                                          <FAIcon icon="plus" />
                                        </span>
                                        Add Insurance
                                      </button>
                                    </div>
                                  </>
                                )}
                              </FieldArray>
                            </>
                          ) : null}
                        </>
                      )}
                    </div>

                    <div className="_referral-info mt-3 sm:mt-0 pt-3 sm:pt-0 border-t sm:border-none border-cool-gray-100">
                      <div className="mt-3">
                        <VerticalField label="Referring Provider">
                          <ProviderSearchSelect
                            value={referringProvider}
                            onChange={setReferringProvider}
                            icon="search"
                            inputProps={{
                              placeholder: "Referring Provider",
                            }}
                            allowCreation
                          />
                          <ErrorMessage
                            component="p"
                            name="referringProvider"
                            className="mt-2 text-red-500 text-xs italic"
                          />
                        </VerticalField>
                      </div>
                      <div className="mt-3">
                        <VerticalField label="Referring Location">
                          <ProviderLocationSelect
                            provider={referringProvider}
                            value={referringLocation}
                            onChange={setReferringLocation}
                            inputProps={{
                              placeholder: !referringProvider
                                ? "Select provider first..."
                                : "Select...",
                            }}
                            disabled={!referringProvider}
                            allowCreation
                          />
                          <ErrorMessage
                            component="p"
                            name="referringLocation"
                            className="mt-2 text-red-500 text-xs italic"
                          />
                        </VerticalField>
                      </div>
                      {departmentSelectVisible && (
                        <div className="mt-3">
                          <VerticalField
                            label="For Department"
                            htmlFor={`field--departmentId`}
                          >
                            <Select
                              id="departmentId"
                              isLoading={loading}
                              options={departmentOptions}
                              onChange={(o: any) =>
                                setSelectedDepartmentId(o.value)
                              }
                              value={departmentOptions.find(
                                (o) => o.value === selectedDepartmentId
                              )}
                            />
                          </VerticalField>
                        </div>
                      )}
                      <div className="mt-3">
                        <SelectField
                          name="specialtyId"
                          label="Specialty"
                          isLoading={loading}
                          options={specialtyOptions}
                          placeholder={
                            !!selectedDepartmentId
                              ? "Select..."
                              : "Select a Department First..."
                          }
                          autoSelect
                        />
                      </div>
                      <div className="mt-8">
                        <FileUploadInput
                          name="fileUploadIds"
                          instanceId={instanceId}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-6 p-4">
                    <Button
                      type="submit"
                      kind="primary"
                      color="blue"
                      size="lg"
                      disabled={isSubmitting}
                    >
                      Next
                      <span className="ml-2">
                        <FAIcon icon="arrow-right" />
                      </span>
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </section>
        </div>
      </FadeUpIn>
    </div>
  );
};

function getSpecialtyOptions(
  data?: Data,
  departmentId?: string
): StandardOption[] {
  if (!data || !departmentId) {
    return [];
  } else {
    return uniqBy(
      data.me.departments
        .find((d) => d.id === departmentId)
        ?.departmentSpecialties.map((ds) => ({
          label: ds.specialty.name,
          value: ds.specialty.id,
        })) || [],
      "id"
    ).sort((o1: StandardOption, o2: StandardOption) =>
      o1.label.localeCompare(o2.label)
    );
  }
}

function getDepartmentOptions(data?: Data): StandardOption[] {
  return (
    data?.me.departments.map((d) => ({
      value: d.id,
      label: d.name,
    })) || []
  );
}
