import { FC, useState, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { GradientHeader } from "../../GradientHeader";
import { ModalHeader, Modal } from "components/Modal";
import {
  DropdownButton,
  DropdownItemLink,
  DropdownItemButton,
} from "components/DropdownButton";
import { NewUserForm } from "./NewUserForm";
import { FAIcon, Spinner, TableContainer, Table, TH, TD, Badge, Button } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { DeactivateUserModal } from "./DeactivateUserModal";
import toast from "react-hot-toast";
import { mmDdYyyy } from "lib/dateFormatters";
import { ReactivateUserModal } from "./ReactivateUserModal";
import { UnlockUserModal } from "./UnlockUserModal";


const ORGANIZATION_USERS = gql`
  query ListOrganizationUsers(
    $first: Int
    $after: UUID4
    $filter: ListOrganizationUsersFilter
  ) {
    me {
      id
      organization {
        id
        aristamdOrganizationId
      }
    }
    organizationUsers(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        firstName
        lastName
        email
        confirmedAt
        deactivatedAt
        insertedAt
        lockedFromFailedLoginAttempts
        role {
          id
          name
        }
      }
    }
  }
`;

interface OrganizationUsersData {
  me: {
    id: string;
    organization: {
      id: string;
      aristamdOrganizationId: number | null;
    }
  }
  organizationUsers: Paginated<UserModel>;
}

interface UserModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: RoleModel;
  insertedAt: string;
  confirmedAt: string | null;
  deactivatedAt: string | null;
  lockedFromFailedLoginAttempts: boolean;
}

interface RoleModel {
  id: string;
  name: string;
}

interface ListOrganizationUsersFilter {
  roleId?: string;
}

interface OrganizationUsersVariables {
  first?: number;
  after?: string;
  filter?: ListOrganizationUsersFilter;
}

const RESEND_INVITE = gql`
  mutation ResendInvite($id: UUID4!) {
    resendInvite(id: $id) {
      errors {
        key
        message
      }
    }
  }
`;

interface ResendInviteData {
  resendInvite: {
    errors?: InputError[];
  };
}

interface ResendInviteVariables {
  id: string;
}

/**
 * UserStatuses.
 */

interface UserStatusesProps {
  user: UserModel;
}

const UserStatuses: FC<UserStatusesProps> = props => {
  const { user } = props;

  return (
    <div className="_UserStatuses flex items-center gap-1">
      {
        user.deactivatedAt ? (
          <Badge color="gray">Deactivated</Badge>
        ) : !user.confirmedAt ? (
          <Badge color="purple">Pending</Badge>
        ) : (
          <Badge color="green">Active</Badge>
        )
      }
      {
        user.lockedFromFailedLoginAttempts ? (
          <Badge color="yellow">Locked</Badge>
        ) : null
      }
    </div>
  )
}

/**
 * ActionsDropdown.
 */

interface ActionsDropdownProps {
  user: UserModel;
  onDeactivateUser(userId: string): void;
  onReactivateUser(userId: string): void;
  onUnlockUser(userId: string): void;
  onResendInvite(userId: string): void;
}

const ActionsDropdown: FC<ActionsDropdownProps> = (props) => {
  const { user, onDeactivateUser, onReactivateUser, onUnlockUser, onResendInvite } = props;

  return (
    <DropdownButton label="Actions">
      <DropdownItemLink to={`/o/settings/users/${user.id}/login_attempts`}>
        View Login Attempts
      </DropdownItemLink>
      {!user.confirmedAt ? (
        <DropdownItemButton onClick={() => onResendInvite(user.id)}>
          Re-send Invite
        </DropdownItemButton>
      ) : null}
      {user.deactivatedAt ? (
        <DropdownItemButton onClick={() => onReactivateUser(user.id)}>
          Reactivate User
        </DropdownItemButton>
      ) : (
        <DropdownItemButton color="red" onClick={() => onDeactivateUser(user.id)}>
          Deactivate User
        </DropdownItemButton>
      )}
      {
        user.lockedFromFailedLoginAttempts ? (
          <DropdownItemButton onClick={() => onUnlockUser(user.id)}>
            Unlock User
          </DropdownItemButton>
        ) : null
      }
    </DropdownButton>
  );
};

interface OrganizationUsersProps { }

type ActiveModal =
  | { type: "INVITE_USER" }
  | { type: "DEACTIVATE_USER", userId: string }
  | { type: "REACTIVATE_USER", userId: string }
  | { type: "UNLOCK_USER", userId: string };

export const OrganizationUsers: FC<OrganizationUsersProps> = (props) => {
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const openDeactivateUser = useCallback((userId: string) => setActiveModal({ type: "DEACTIVATE_USER", userId }), [setActiveModal]);
  const openReactivateUser = useCallback((userId: string) => setActiveModal({ type: "REACTIVATE_USER", userId }), [setActiveModal]);
  const openUnlockUser = useCallback((userId: string) => setActiveModal({ type: "UNLOCK_USER", userId }), [setActiveModal]);

  const closeModal = useCallback(() => setActiveModal(null), [setActiveModal]);

  const { data, loading, error, refetch } = useQuery<
    OrganizationUsersData,
    OrganizationUsersVariables
  >(ORGANIZATION_USERS);

  const isAmdOrg = !!(data?.me.organization.aristamdOrganizationId);

  const [resendInviteMut] = useMutation<ResendInviteData, ResendInviteVariables>(
    RESEND_INVITE
  );

  const resendInvite = useCallback((userId: string) => {
    resendInviteMut({ variables: { id: userId } }).then((res) => {
      if (res && res.data && res.data.resendInvite.errors) {
        res.data.resendInvite.errors.forEach(
          (error: { key: string; message: string }) => {
            toast.error(error.message);
          }
        );
      } else {
        toast.success("Re-sent invitation!");
      }
    });
  }, [resendInviteMut]);

  return (
    <>
      <Modal isOpen={activeModal?.type === "INVITE_USER"} onRequestClose={closeModal}>
        <ModalHeader
          icon="user-plus"
          title="Invite User"
          onClose={closeModal}
        />
        <div className="px-8 pt-6 pb-8 flex flex-col">
          <NewUserForm
            closeModal={closeModal}
            refetchQueries={[{ query: ORGANIZATION_USERS }]}
          />
        </div>
      </Modal>

      {
        activeModal?.type === "DEACTIVATE_USER" && !!activeModal.userId ? (
          <DeactivateUserModal
            isOpen={activeModal.type === "DEACTIVATE_USER"}
            onClose={closeModal}
            userId={activeModal.userId}
            onSuccess={refetch}
          />
        ) : null
      }

      {
        activeModal?.type === "REACTIVATE_USER" && !!activeModal.userId ? (
          <ReactivateUserModal
            isOpen={activeModal.type === "REACTIVATE_USER"}
            onClose={closeModal}
            userId={activeModal.userId}
            onSuccess={refetch}
          />
        ) : null
      }

      {
        activeModal?.type === "UNLOCK_USER" && !!activeModal.userId ? (
          <UnlockUserModal
            isOpen={activeModal.type === "UNLOCK_USER"}
            onClose={closeModal}
            userId={activeModal.userId}
            onSuccess={refetch}
          />
        ) : null
      }

      <div className="bg-white box rounded-lg shadow-lg">
        <ScreenTitle title="Settings » Users" />
        <GradientHeader
          icon="users"
          title="Users"
          subtitle="Manage your organization's user accounts."
        />
        <div className="p-4">
          {loading ? (
            <div className="p-12 text-center">
              <Spinner />
            </div>
          ) : error || !(data && data.organizationUsers) ? (
            <p>Failed to load</p>
          ) : (
            <div>
              <div className="flex justify-end px-3 pb-4">
                {
                  isAmdOrg ? (
                    <a
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150"
                      href="mailto:support@aristamd.com"
                      target="_blank"
                      rel="noref noreferrer nofollow"
                    >
                      Contact Support to Add User
                    </a>
                  ) : (
                    <Button
                      type="button"
                      kind="primary"
                      color="blue"
                      onClick={() => setActiveModal({ type: "INVITE_USER" })}
                    >
                      <FAIcon icon="plus" className="mr-2" />
                      Add User
                    </Button>
                  )
                }
              </div>
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <TH>Name</TH>
                      <TH />
                      <TH>Added At</TH>
                      <TH />
                    </tr>
                  </thead>
                  <tbody>
                    {data.organizationUsers.items.map((user) => (
                      <tr key={user.id}>
                        <TD>
                          <p className="text-lg font-semibold text-gray-800">
                            {user.firstName} {user.lastName}
                          </p>
                          <p className="leading-tight mt-1 text-gray-500 text-xs">Email: <span className="text-gray-700 font-semibold">{user.email}</span></p>
                          <p className="leading-tight text-gray-500 text-xs">Role: <span className="text-gray-700 font-semibold">{user.role.name}</span></p>
                        </TD>
                        <TD>
                          <UserStatuses user={user} />
                        </TD>
                        <TD>
                          {mmDdYyyy(user.insertedAt)}
                        </TD>
                        <TD className="w-40">
                          <ActionsDropdown
                            user={user}
                            onDeactivateUser={openDeactivateUser}
                            onReactivateUser={openReactivateUser}
                            onUnlockUser={openUnlockUser}
                            onResendInvite={resendInvite}
                          />
                        </TD>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
