import React, { HTMLAttributes, forwardRef } from "react";
import { colorClassNames } from "./colorClassNames";

interface CheckCircleIconProps extends HTMLAttributes<SVGSVGElement> {
  color: AppColor;
}

export const CheckCircleIcon = forwardRef<SVGSVGElement, CheckCircleIconProps>(
  (props, ref) => {
    const { color, ...rest } = props;
    const lightClassName = colorClassNames[color].light;
    const darkClassName = colorClassNames[color].dark;
    return (
      <svg
        ref={ref}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          className={lightClassName}
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          fill="currentColor"
        />
        <path
          className={darkClassName}
          d="M9.99999 14.5898L16.3 8.28978C16.4924 8.12722 16.7392 8.04328 16.9908 8.05475C17.2425 8.06622 17.4805 8.17224 17.6574 8.35164C17.8343 8.53103 17.9369 8.77057 17.9448 9.02237C17.9527 9.27416 17.8653 9.51966 17.7 9.70978L10.7 16.7098C10.5131 16.893 10.2617 16.9956 9.99999 16.9956C9.73824 16.9956 9.48692 16.893 9.29999 16.7098L6.29999 13.7098C6.13471 13.5197 6.04728 13.2742 6.05518 13.0224C6.06308 12.7706 6.16572 12.531 6.34258 12.3516C6.51945 12.1722 6.75751 12.0662 7.00917 12.0547C7.26083 12.0433 7.50754 12.1272 7.69999 12.2898L9.99999 14.5898Z"
          fill="currentColor"
        />
      </svg>
    );
  }
);
