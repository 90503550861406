import React, { FC, useCallback } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { AddProviderToDepartmentForm } from "./AddProviderToDepartmentForm";

interface AddDepartmentProviderModalProps {
  departmentId: string;
  isOpen: boolean;
  onClose(): void;
  onSuccess?(): void;
}

export const AddDepartmentProviderModal: FC<AddDepartmentProviderModalProps> = (
  props
) => {
  const { departmentId, isOpen, onClose, onSuccess } = props;

  const handleSuccess = useCallback(() => {
    if (onSuccess) {
      onSuccess();
    }
    onClose();
  }, [onSuccess, onClose]);

  return (
    <Modal className="max-w-2xl" isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="user-doctor"
        title="Add Provider to Department"
        onClose={onClose}
      />
      <div className="p-4">
        <AddProviderToDepartmentForm
          departmentId={departmentId}
          onSuccess={handleSuccess}
        />
      </div>
    </Modal>
  );
};
