import { FC } from "react";
import ArchiveIcon from '@heroicons/react/solid/ArchiveIcon';
import BackspaceIcon from '@heroicons/react/solid/BackspaceIcon';
import CalendarIcon from '@heroicons/react/solid/CalendarIcon';
import ChatAltIcon from '@heroicons/react/solid/ChatAltIcon';
import DocumentAddIcon from '@heroicons/react/solid/DocumentAddIcon';
import DocumentRemoveIcon from '@heroicons/react/solid/DocumentRemoveIcon';
import HandIcon from '@heroicons/react/solid/HandIcon';
import OfficeBuildingIcon from '@heroicons/react/solid/OfficeBuildingIcon';
import TagIcon from '@heroicons/react/solid/TagIcon';
import ThumbDownIcon from '@heroicons/react/solid/ThumbDownIcon';
import UserAddIcon from '@heroicons/react/solid/UserAddIcon';
import UserCircleIcon from '@heroicons/react/solid/UserCircleIcon';

import XIcon from '@heroicons/react/solid/XIcon';
import ExclamationIcon from '@heroicons/react/solid/ExclamationIcon';
import BadgeCheckIcon from '@heroicons/react/solid/BadgeCheckIcon';
import {
  AppointmentRequestEventModel,
  AppointmentRequestEventName,
} from "../../model";
import { distanceInWords } from "lib/dateFormatters";
import { maybePluralize } from "lib/maybePluralize";
import { UserAvatar } from "components/UserAvatar";
import { FAIcon } from "components/FAIcon";

const components: Record<AppointmentRequestEventName, FC<{ event: any }>> = {
  appointment_cancelled: AppointmentCancelledEvent,
  appointment_completed: AppointmentCompletedEvent,
  appointment_missed: AppointmentMissedEvent,
  appointment_office_confirmed: AppointmentOfficeConfirmedEvent,
  appointment_patient_confirmed: AppointmentPatientConfirmedEvent,
  appointment_rescheduled: AppointmentRescheduledEvent,
  appointment_scheduled: AppointmentScheduledEvent,
  comment_added: CommentAddedEvent,
  fax_failed: FaxFailedEvent,
  fax_queued: FaxQueuedEvent,
  fax_sent: FaxSentEvent,
  file_removed: FileRemovedEvent,
  files_added: FilesAddedEvent,
  location_assigned: LocationAssignedEvent,
  location_unassigned: LocationUnassignedEvent,
  missing_fax_number: MissingFaxNumberEvent,
  provider_assigned: ProviderAssignedEvent,
  provider_unassigned: ProviderUnassignedEvent,
  reason_for_delay_updated: ReasonForDelayUpdatedEvent,
  request_closed: RequestClosedEvent,
  request_exhausted: RequestExhaustedEvent,
  request_rejected: RequestRejectedEvent,
  request_withdrawn: RequestWithdrawnEvent,
  tag_added: TagAddedEvent,
  tag_removed: TagRemovedEvent,
  task_touched: TaskTouchedEvent,
  user_assigned: UserAssignedEvent,
  user_unassigned: UserUnassignedEvent,
};

interface AppointmentRequestEventProps {
  showLine?: boolean;
  appointmentRequestEvent: AppointmentRequestEventModel;
}

export const AppointmentRequestEvent: FC<AppointmentRequestEventProps> = (
  props
) => {
  const { showLine = false, appointmentRequestEvent } = props;
  const Component = components[appointmentRequestEvent.type];
  return (
    <li>
      <div className="relative pb-8">
        {showLine ? (
          <span
            className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          ></span>
        ) : null}
        <Component event={appointmentRequestEvent} />
      </div>
    </li>
  );
};

// type EventProps<T extends AppointmentRequestEventName = never> =

function AppointmentCancelledEvent(props: {
  event: AppointmentRequestEventModel<"appointment_cancelled">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;

  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <FAIcon icon={["far", "calendar-times"]} className="h-5 w-5 text-red-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            cancelled an appointment{" "}
            {event.data.providerName ? `with ${event.data.providerName}` : ""}{" "}
            {event.data.locationName ? ` at ${event.data.locationName}` : ""}
            {event.data.cancellationReason ? `. Reason: ${event.data.cancellationReason}` : ""}
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function AppointmentCompletedEvent(props: {
  event: AppointmentRequestEventModel<"appointment_completed">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;

  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <FAIcon icon={["far", "calendar-check"]} className="h-5 w-5 text-green-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            marked an appointment{" "}
            {event.data.providerName ? `with ${event.data.providerName}` : ""}{" "}
            {event.data.locationName ? ` at ${event.data.locationName}` : ""}
            as completed.
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function AppointmentMissedEvent(props: {
  event: AppointmentRequestEventModel<"appointment_missed">;
}) {
  const { event } = props;
  return (
    <li>
      AppointmentMissedEvent: <pre>{JSON.stringify(event, null, 2)}</pre>
    </li>
  );
}

function AppointmentOfficeConfirmedEvent(props: {
  event: AppointmentRequestEventModel<"appointment_office_confirmed">;
}) {
  const { event } = props;
  return (
    <li>
      AppointmentOfficeConfirmedEvent:{" "}
      <pre>{JSON.stringify(event, null, 2)}</pre>
    </li>
  );
}

function AppointmentPatientConfirmedEvent(props: {
  event: AppointmentRequestEventModel<"appointment_patient_confirmed">;
}) {
  const { event } = props;
  return (
    <li>
      AppointmentPatientConfirmedEvent:{" "}
      <pre>{JSON.stringify(event, null, 2)}</pre>
    </li>
  );
}

function AppointmentRescheduledEvent(props: {
  event: AppointmentRequestEventModel<"appointment_rescheduled">;
}) {
  const { event } = props;
  return (
    <li>
      AppointmentRescheduledEvent: <pre>{JSON.stringify(event, null, 2)}</pre>
    </li>
  );
}

function AppointmentScheduledEvent(props: {
  event: AppointmentRequestEventModel<"appointment_scheduled">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;

  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <CalendarIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            set an appointment{" "}
            {event.data.providerName ? `with ${event.data.providerName}` : ""}{" "}
            for {event.data.startTime}
            {event.data.locationName ? ` at ${event.data.locationName}` : ""}
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function CommentAddedEvent(props: {
  event: AppointmentRequestEventModel<"comment_added">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;

  return (
    <div className="relative flex items-start space-x-3">
      <div className="relative">
        <UserAvatar
          size={40}
          firstName={firstName!}
          lastName={lastName!}
        />

        <span
          className="absolute bg-white rounded"
          style={{
            bottom: -2,
            right: -4,
            padding: 1,
          }}
        >
          {/* <!-- Heroicon name: chat-alt --> */}
          <ChatAltIcon className="h-5 w-5 text-cool-gray-400" />
        </span>
      </div>
      <div className="_CommentBubble border flex-1 min-w-0 p-3 rounded-xl shadow-md">
        <div>
          <div className="text-sm leading-tight">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>
          </div>
          <p className="mt-0.5 text-xs leading-tight text-cool-gray-500">
            Commented {distanceInWords(event.insertedAt)} ago
          </p>
        </div>
        <div className="mt-2 text-sm text-cool-gray-700">
          <p className="whitespace-pre-wrap">{event.data.comment}</p>
        </div>
      </div>
    </div>
  );
}

function FaxFailedEvent(props: {
  event: AppointmentRequestEventModel<"fax_failed">;
}) {
  const { event } = props;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-red-200 rounded-full flex items-center justify-center">
            <ExclamationIcon className="h-5 w-5 text-red-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            Fax for referral could not be sent to: {event.data.to}. Please contact support@aristamd.com for more information.
          </span>
        </div>
      </div>
    </div>
  );
}

function FaxQueuedEvent(props: {
  event: AppointmentRequestEventModel<"fax_queued">;
}) {
  const { event } = props;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <OfficeBuildingIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            Fax for referral queued to be sent to: {event.data.to}.
          </span>
        </div>
      </div>
    </div>
  );
}

function FaxSentEvent(props: {
  event: AppointmentRequestEventModel<"fax_sent">;
}) {
  const { event } = props;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-green-200 rounded-full flex items-center justify-center">
            <BadgeCheckIcon className="h-5 w-5 text-green-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            Fax for referral was successfully sent to: {event.data.to}.
          </span>
        </div>
      </div>
    </div>
  );
}

function FileRemovedEvent(props: {
  event: AppointmentRequestEventModel<"file_removed">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.user?.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <DocumentRemoveIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            removed a file: {event.data.fileName}
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function FilesAddedEvent(props: {
  event: AppointmentRequestEventModel<"files_added">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;
  const numFiles = event.data.files.length;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <DocumentAddIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            added {numFiles} {maybePluralize("file", numFiles)}
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
        <ul className="leading-tight list-disc pl-6 pr-2 py-1 text-cool-gray-500 text-xs">
          {event.data.files.map((f) => (
            <li key={f.id}>{f.name}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function LocationAssignedEvent(props: {
  event: AppointmentRequestEventModel<"location_assigned">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <OfficeBuildingIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            assigned location: {event.data.locationName}
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function LocationUnassignedEvent(props: {
  event: AppointmentRequestEventModel<"location_unassigned">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <OfficeBuildingIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            unassigned location: {event.data.locationName}
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function MissingFaxNumberEvent(props: {
  event: AppointmentRequestEventModel<"missing_fax_number">;
}) {
  const { event } = props;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <OfficeBuildingIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            Fax for referral not sent, please add a fax number.
          </span>
        </div>
      </div>
    </div>
  );
}

function ProviderAssignedEvent(props: {
  event: AppointmentRequestEventModel<"provider_assigned">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <UserAddIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            assigned provider: {event.data.providerName}
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function ProviderUnassignedEvent(props: {
  event: AppointmentRequestEventModel<"provider_unassigned">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <UserAddIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            unassigned provider: {event.data.providerName}
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function ReasonForDelayUpdatedEvent(props: {
  event: AppointmentRequestEventModel<"reason_for_delay_updated">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <HandIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            updated reason for delay: {event.data.reason}
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function RequestExhaustedEvent(props: {
  event: AppointmentRequestEventModel<"request_exhausted">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <ArchiveIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            marked this request as exhausted.
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function RequestClosedEvent(props: {
  event: AppointmentRequestEventModel<"request_closed">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <XIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            marked this request as closed.
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function RequestRejectedEvent(props: {
  event: AppointmentRequestEventModel<"request_rejected">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;

  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <ThumbDownIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            rejected this request: {event.data.reason}.
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function RequestWithdrawnEvent(props: {
  event: AppointmentRequestEventModel<"request_withdrawn">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;

  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full flex items-center justify-center">
            <BackspaceIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            withdrew this request. Reason: {event.data.reason}.
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function TagAddedEvent(props: {
  event: AppointmentRequestEventModel<"tag_added">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;
  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-cool-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
            <TagIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-sm leading-8 text-cool-gray-500">
          <span className="mr-0.5">
            <span className="font-medium text-cool-gray-900">
              {firstName} {lastName}
            </span>{" "}
            added tag{" "}
          </span>
          <span className="mr-0.5">
            <span className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
              <span className="absolute flex-shrink-0 flex items-center justify-center">
                <span
                  className="h-1.5 w-1.5 rounded-full"
                  aria-hidden="true"
                  style={{ backgroundColor: event.data.tagColor }}
                ></span>
              </span>
              <span className="ml-3.5 font-medium text-cool-gray-900 leading-snug">
                {event.data.tagLabel}
              </span>
            </span>
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function TagRemovedEvent(props: {
  event: AppointmentRequestEventModel<"tag_removed">;
}) {
  const { event } = props;
  return (
    <li>
      TagRemovedEvent: <pre>{JSON.stringify(event, null, 2)}</pre>
    </li>
  );
}

function TaskTouchedEvent(props: {
  event: AppointmentRequestEventModel<"task_touched">;
}) {
  const { event } = props;
  return (
    <li>
      TaskTouchedEvent: <pre>{JSON.stringify(event, null, 2)}</pre>
    </li>
  );
}

function UserAssignedEvent(props: {
  event: AppointmentRequestEventModel<"user_assigned">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;

  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
            {/* <!-- Heroicon name: user-circle --> */}
            <UserCircleIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-1.5">
        <div className="text-sm text-cool-gray-500">
          <span className="font-medium text-cool-gray-900">
            {firstName} {lastName}
          </span>{" "}
          assigned{" "}
          <span className="font-medium text-cool-gray-900">
            {event.data.userFirstName} {event.data.userLastName}
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}

function UserUnassignedEvent(props: {
  event: AppointmentRequestEventModel<"user_unassigned">;
}) {
  const { event } = props;
  const firstName = event.user?.firstName || event.admin?.firstName;
  const lastName = event.user?.lastName || event.admin?.lastName;

  return (
    <div className="relative flex items-start space-x-3">
      <div>
        <div className="relative px-1">
          <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
            {/* <!-- Heroicon name: user-circle --> */}
            <UserCircleIcon className="h-5 w-5 text-cool-gray-500" />
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-1.5">
        <div className="text-sm text-cool-gray-500">
          <span className="font-medium text-cool-gray-900">
            {firstName} {lastName}
          </span>{" "}
          unassigned{" "}
          <span className="font-medium text-cool-gray-900">
            {event.data.userFirstName} {event.data.userLastName}
          </span>
          <span className="ml-2 whitespace-nowrap text-xs">
            {distanceInWords(event.insertedAt)} ago
          </span>
        </div>
      </div>
    </div>
  );
}
