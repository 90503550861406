import { FC, useCallback, useEffect, useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import PaperClipIcon from "@heroicons/react/solid/PaperClipIcon";
import { Button, TextAreaInput, useOnEscape } from "@preferral/ui";
import { UserAvatar } from "components/UserAvatar";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import toast from "react-hot-toast";
import { FileUploadField } from "../../../components/formik/FileUploadField";
import { useToggle } from "hooks/useToggle";
import { Tooltip } from "components/Tooltip";
import { FAIcon } from "components/FAIcon";

const SEND_ECONSULT_FOLLOW_UP = gql`
  mutation SendEConsultFollowUp($id: Int!, $input: EconsultFollowUpInput!) {
    sendEconsultFollowUp(id: $id, input: $input) {
      errors {
        key
        message
      }
      econsult {
        id
        status
      }
    }
  }
`;

interface MutationData {
  sendEconsultFollowUp: {
    errors?: InputError[];
    econsult?: {
      id: number;
      status: string;
    };
  };
}

interface MutationVariables {
  id: number;
  input: EconsultFollowUpInput;
}

type EconsultFollowUpInput = FormValues;

interface FormValues {
  comment: string;
  assetIds: string[];
}

const initialValues: FormValues = {
  comment: "",
  assetIds: [],
};

interface FollowUpCommentBoxProps {
  currentUser: { firstName: string; lastName: string };
  eConsultId: number;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

const amdAcceptFileTypes = [
  "application/rtf",
  "application/msword",
  "application/pdf",
  "text/*",
  "image/*",
  "video/*",
];

export const FollowUpCommentBox: FC<FollowUpCommentBoxProps> = props => {
  const { currentUser, isOpen, onClose, onSuccess, eConsultId } = props;

  const [sendEConsultFollowUp] = useMutation<MutationData, MutationVariables>(
    SEND_ECONSULT_FOLLOW_UP
  );

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [attachmentsVisible, toggleAttachmentsVisible] = useToggle(false);

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [isOpen]);

  useOnEscape(isOpen, onClose);

  const onSubmit = useCallback(
    async (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;
      setStatus({ errors: null });

      const variables = {
        id: eConsultId,
        input: values,
      };

      try {
        const { data } = await sendEConsultFollowUp({ variables });

        if (data?.sendEconsultFollowUp.errors) {
          setStatus({ errors: data.sendEconsultFollowUp.econsult });
        } else if (data?.sendEconsultFollowUp.econsult) {
          // it worked...
          toast.success("Follow up sent.");
          onSuccess();
        }
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
      }
      setSubmitting(false);
    },
    [eConsultId, onSuccess, sendEConsultFollowUp]
  );

  const cn = [
    "_FollowUpCommentBox absolute z-10 inset-x-0 transform transition duration-150",
    "px-3 flex items-start space-x-4 max-w-2xl mx-auto",
    isOpen
      ? "scale-100 opacity-1 pointer-events-auto"
      : "scale-75 opacity-0 pointer-events-none",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={cn} style={{ bottom: "1rem" }}>
      <UserAvatar
        className="flex-shrink-0"
        firstName={currentUser.firstName}
        lastName={currentUser.lastName}
      />

      <div className="min-w-0 flex-1 shadow-2xl rounded-lg bg-white overflow-hidden border-2 border-transparent focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500">
        <Formik<FormValues> initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, isSubmitting, handleSubmit, status }) => (
            <form className="relative" onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <div className="overflow-hidden rounded-lg shadow-sm">
                <label htmlFor="comment" className="sr-only">
                  Add your comment
                </label>
                <TextAreaInput
                  ref={inputRef}
                  name="comment"
                  inputProps={{
                    className:
                      "block w-full resize-none border-0 p-3 focus:ring-0 outline-none sm:text-sm",
                  }}
                  placeholder="Add your comment..."
                  minRows={3}
                  maxRows={15}
                />
                {attachmentsVisible ? (
                  <div className="mx-4 mt-2 mb-2 relative">
                    <button
                      className="mt-1 mr-2 absolute top-0 right-0"
                      type="button"
                      onClick={toggleAttachmentsVisible}
                    >
                      <FAIcon className="cursor-pointer" icon="xmark"></FAIcon>
                    </button>
                    <FileUploadField
                      name="assetIds"
                      label=""
                      source="amd"
                      showIcon={false}
                      prompt="Drag & drop files, or click to browse"
                      acceptFileTypes={amdAcceptFileTypes}
                    />
                  </div>
                ) : null}
                {/* Spacer element to match the height of the toolbar */}
                <div className="py-2" aria-hidden="true">
                  {/* Matches height of button in toolbar (1px border + 36px content height) */}
                  <div
                    className="py-px"
                    style={{ paddingTop: 1, paddingBottom: 1 }}
                  >
                    <div className="h-9" />
                  </div>
                </div>
              </div>

              <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                <div className="flex items-center space-x-5">
                  <div className="flex items-center">
                    <Tooltip tip={<p>Attach Files</p>}>
                      {!attachmentsVisible ? (
                        <button
                          type="button"
                          className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                          onClick={toggleAttachmentsVisible}
                        >
                          <PaperClipIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Attach a file</span>
                        </button>
                      ) : null}
                    </Tooltip>
                  </div>
                </div>
                <div className="flex-shrink-0 flex items-center">
                  <Button
                    type="button"
                    kind="secondary"
                    onClick={onClose}
                    className="mr-2"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    kind="primary"
                    color="blue"
                    isLoading={isSubmitting}
                    disabled={isSubmitting || !values.comment}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
