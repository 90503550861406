import { FC } from "react";
import { Coverage } from "./coverage";
import { TableContainer, Table, TH, TD } from "@preferral/ui";


interface ServicesCoveredProps {
  coverage: Coverage
};

export const ServicesCovered: FC<ServicesCoveredProps> = props => {
  const { coverage } = props;
  const services = coverage.getServices();

  return (
    <div className="_ServicesCovered">
      <h5 className="mt-8 mb-4 text-sm font-semibold text-gray-900">
        Service Coverage
      </h5>

      <TableContainer>
        <Table className="w-full">
          <thead>
            <tr>
              <TH>Service Type</TH>
              <TH>Coverage Status</TH>
            </tr>
          </thead>
          <tbody className="text-xs">
            {
              services.map((service: any) => (
                <tr key={service.type_label}>
                  <TD style={{ padding: "0.25rem 0.75rem" }}><span className="text-xs">{service.type_label}</span></TD>
                  <TD wrap style={{ padding: "0.25rem 0.75rem" }}>
                    <span className={`text-xs text-wrap ${coverageStatusClassname(service.coverage_status)}`}>{service.coverage_status_label}
                    </span>
                  </TD>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

/**
 * coverageStatusLabels:
 *   1: "Active Coverage",
 *   2: "Active - Full Risk Capitation",
 *   3: "Active - Services Capitated",
 *   4: "Active - Services Capitated to Primary Care Physician",
 *   5: "Active - Pending Investigation",
 *   6: "Inactive",
 *   7: "Inactive - Pending Eligibility Update",
 *   8: "Inactive - Pending Investigation",
 *   9: "Not-Covered",
 *   10: "Cannot process",
 *   11: "Generic inquiry provided no information for this service type. Retry by including service_type= for an explicit inquiry for this service type.",
 *   12: "Not deemed a medical necessity",
 *   13: "Second surgical opinion required.",
 *   14: "Card reported stolen.",
 *   15: "Contact following entity for coverage info: "
 */

const coverageStatusClassNames = {
  "1": "text-green-600",
  "2": "text-green-600",
  "3": "text-green-600",
  "4": "text-green-600",
  "5": "text-teal-600",
  "6": "text-orange-600",
  "7": "text-orange-600",
  "8": "text-orange-600",
  "9": "text-red-600",
  "10": "text-red-600",
  "11": "text-gray-700",
  "12": "text-red-600",
  "13": "text-orange-600",
  "14": "text-orange-600",
  "15": "text-orange-600"
}
function coverageStatusClassname(coverageStatusCode: string): string {
  return coverageStatusClassNames[coverageStatusCode] || "text-gray-800";
}

