import { FC } from "react";
import { Modal, ModalHeader } from 'components/Modal';
import { SendPacketForm } from "./SendPacketForm";

interface SendPacketModalProps {
  isOpen: boolean;
  onClose(): void;
  onSuccess?(): void;
};

export const SendPacketModal: FC<SendPacketModalProps> = props => {
  const { isOpen, onClose, onSuccess } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="paper-plane"
        title="Send patient packet"
        onClose={onClose}
      />
      <div className="p-4">
        <SendPacketForm onSuccess={() => {
          if (onSuccess) {
            onSuccess();
          }
          onClose()
        }} />
      </div>
    </Modal>
  );
};
