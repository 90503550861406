import React from "react";
import cx from "classnames";
import { useHealthPlanPicker } from "./context";

const Tab: React.FC<{
  onClick?: () => void;
  active?: boolean;
  label: string;
}> = ({ onClick, active = false, label }) => {
  return (
    <div
      onClick={onClick}
      className={cx("py-1 px-3 text-sm flex-1 cursor-pointer", {
        "text-blue-800 bg-white": !active,
        "text-white bg-blue-800": active
      })}
    >
      {label}
    </div>
  );
};

type HealthPlanFinderNavProps = {};

const HealthPlanFinderNav: React.FC<HealthPlanFinderNavProps> = () => {
  const { state, dispatch } = useHealthPlanPicker();
  return (
    <div className="flex border border-blue-800 rounded">
      <Tab
        onClick={() => dispatch({ type: "STEP_1_TAB_CLICKED" })}
        active={state.currentTab === "choose_carrier"}
        label="1. Choose Carrier"
      />
      {state.carrierGroup ? (
        <>
          <Tab
            active={state.currentTab === "specify_carrier"}
            label="2. Choose BCBS"
          />
          <Tab
            active={state.currentTab === "choose_health_plan"}
            label="3. Choose Plan"
          />
        </>
      ) : (
        <Tab
          active={state.currentTab === "choose_health_plan"}
          label="2. Choose Plan"
        />
      )}
    </div>
  );
};

export { HealthPlanFinderNav };
