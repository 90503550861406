import { FC } from "react";
import { useQuery } from "@apollo/client";
// import { EligibilityLookups } from "screens/EligibilityLookups";
import { Spinner, FAIcon } from "@preferral/ui";
import { InsuranceCoverage as InsuranceCoverageModel } from "screens/PatientsScreen";
import { PATIENT_MEMBER_INSURANCE_COVERAGES } from "screens/PatientShowScreen/PatientInsuranceCoverages";
import { InsuranceCoverageCard } from "./InsuranceCoverageCard";
import CashIcon from '@heroicons/react/outline/CashIcon';

/**
 * SelfPayCard.
 */
const SelfPayCard: FC = () => {
  return (
    <div className="p-4 flex items-center justify-center bg-cool-gray-50 rounded border border-dotted">
      <CashIcon className="inline-block w-5 h-5 text-cool-gray-400" />
      <p className="ml-2 text-cool-gray-500">Self Pay</p>
    </div>
  );
};

/**
 * UnknownInsuranceCard.
 */
const UnknownInsuranceCard: FC = () => {
  return (
    <div className="bg-gray-200 inline-block mx-auto px-8 py-6 rounded-lg shadow-inner">
      <p className="text-gray-700">
        <span className="mr-2 text-gray-600">
          <FAIcon icon={["far", "address-card"]} />
        </span>
        No insurance added yet.
      </p>
    </div>
  );
};

interface Data {
  patientMember: {
    id: string;
    isSelfPay: boolean;
    insuranceCoverages: InsuranceCoverageModel[];
  };
}

interface Variables {
  id: string;
}

type ActiveModal = "ADD_INSURANCE";

interface PatientInsuranceCoveragesProps {
  patientMemberId: string;
}

export const PatientInsuranceCoverages: FC<PatientInsuranceCoveragesProps> = (
  props
) => {
  const { patientMemberId } = props;
  // const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const { loading, error, data } = useQuery<Data, Variables>(
    PATIENT_MEMBER_INSURANCE_COVERAGES,
    { variables: { id: patientMemberId } }
  );

  // const addInsuranceElement = (
  //   <div className="text-center pt-3">
  //     <Button
  //       color="blue"
  //       kind="secondary"
  //       size="xs"
  //       onClick={() => setActiveModal("ADD_INSURANCE")}
  //     >
  //       <span className="mr-2">
  //         <FAIcon icon="plus" />
  //       </span>
  //       Add Insurance
  //     </Button>
  //   </div>
  // );

  return (
    <div className="_PatientInsuranceCoverages">
      {loading ? (
        <div className="p-4 text-center">
          <Spinner />
        </div>
      ) : error || !data?.patientMember ? (
        <p>Failed to load</p>
      ) : (
        <>
          {data.patientMember.isSelfPay ? (
            <SelfPayCard />
          ) : data.patientMember.insuranceCoverages.length === 0 ? (
            <UnknownInsuranceCard />
          ) : (
            data.patientMember.insuranceCoverages.map((ic) => (
              <InsuranceCoverageCard key={ic.id} insuranceCoverage={ic} />
            ))
          )}
          {/* {addInsuranceElement} */}
        </>
      )}
    </div>
  );
};
