import React, { FC, useCallback, useState } from "react";
import { Button } from "@preferral/ui";
import { Modal } from "components/Modal";

type IconComponent =
  | React.ComponentClass<React.SVGProps<SVGSVGElement>>
  | React.FC<React.SVGProps<SVGSVGElement>>
  | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose(): void;
  icon: IconComponent;
  title: React.ReactNode;
  prompt: React.ReactNode;
  color?: "red" | "blue";
  onSubmit(): Promise<unknown>;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {
  const {
    isOpen,
    onClose,
    icon: Icon,
    title,
    prompt,
    color = "blue",
    onSubmit: doSubmit,
    children,
  } = props;

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    return doSubmit().then(() => {
      setSubmitting(false);
      onClose();
    });
  }, [setSubmitting, doSubmit, onClose]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="sm:max-w-lg">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-t-lg">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            {/* <!-- Heroicon name: outline/exclamation --> */}
            <Icon className="h-6 w-6 text-red-600" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              className="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              {title}
            </h3>
            <div className="mt-2">{children}</div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b-lg">
        <Button
          type="button"
          kind="primary"
          color={color}
          isLoading={submitting}
          onClick={onSubmit}
        >
          {prompt}
        </Button>
        <Button
          className="mr-2"
          type="button"
          kind="primary"
          color="gray"
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
