import React, { useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import cx from "classnames";
import useDropdown from "hooks/oldUseDropdown";
import { Link } from "react-router-dom";
import { Manager, Reference, Popper } from "react-popper";
import { FAIcon } from "components/FAIcon";
import { Icon } from "@preferral/ui";

type DropdownButtonProps = {
  // label: React.ReactElement;
  label?: string;
};

const DropdownButton: React.FC<DropdownButtonProps> = ({ label, children }) => {
  const triggerEl = useRef(null);
  const contentEl = useRef(null);
  const [open, toggleOpen] = useDropdown(triggerEl, contentEl);
  const onClick = useCallback(() => toggleOpen(), [toggleOpen]);

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div ref={ref} className="inline-block">
            <button
              ref={triggerEl}
              onClick={onClick}
              type="button"
              className="h-8 border border-gray-300 hover:bg-gray-100 ml-2 px-1 rounded text-gray-800 focus:outline-none focus:shadow-outline"
            >
              {!label ? (
                <Icon icon="dots-horizontal" />
              ) : (
                <p className="flex items-center px-1 text-sm">
                  {label}
                  <span className="ml-2 mt-1 text-xs text-gray-700">
                    <FAIcon icon="chevron-down" />
                  </span>
                </p>
              )}
            </button>
          </div>
        )}
      </Reference>
      {open &&
        ReactDOM.createPortal(
          <Popper placement="bottom-end">
            {({ placement, ref, style }) => (
              <div ref={ref} style={style} data-placement={placement}>
                <div
                  className="absolute mt-1 z-10 right-0 origin-top-right"
                  ref={contentEl}
                  hidden={!open}
                >
                  <div className="w-48 text-left bg-white border border-gray-300 rounded-lg px-1 pb-1 rounded-lg shadow-lg">
                    {children}
                  </div>
                </div>
              </div>
            )}
          </Popper>,
          document.querySelector("#dropdown-button-action-portal")!
        )}
    </Manager>
  );
};

type DropdownItemButtonProps = {
  onClick: () => void;
  className?: string;
  color?: "blue" | "red";
};

const DropdownItemButton: React.FC<DropdownItemButtonProps> = ({
  className,
  color = "blue",
  onClick,
  children,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cx(
        className,
        "block w-full bg-white text-sm font-semibold text-left cursor-pointer rounded mt-1 px-2 py-1",
        {
          "text-red-800 hover:bg-red-200 hover:text-red-600": color === "red",
          "text-gray-800 hover:bg-blue-200 hover:text-blue-800":
            color === "blue",
        }
      )}
    >
      {children}
    </button>
  );
};

type DropdownItemLinkProps = {
  to: string;
  className?: string;
  color?: "blue" | "red";
};

const DropdownItemLink: React.FC<DropdownItemLinkProps> = ({
  className,
  to,
  color = "blue",
  children,
}) => {
  return (
    <Link
      to={to}
      className={cx(
        className,
        "block w-full bg-white text-sm font-semibold text-left cursor-pointer rounded mt-1 px-2 py-1",
        {
          "text-red-800 hover:bg-red-200 hover:text-red-600": color === "red",
          "text-gray-800 hover:bg-blue-200 hover:text-blue-800":
            color === "blue",
        }
      )}
    >
      {children}
    </Link>
  );
};

export { DropdownButton, DropdownItemButton, DropdownItemLink };
