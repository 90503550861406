import React from "react";
import { gql, useQuery, useMutation } from "@apollo/client";

import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { CountySelectField } from "components/formik/CountySelectField";
import { TextField, PhoneMaskField, SelectField } from "@preferral/ui";
import toast from "react-hot-toast";
import { analytics } from "lib/analytics";

const CREATE_LOCATION = gql`
  mutation CreateLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      errors {
        key
        message
      }
      location {
        id
        name
      }
    }
  }
`;

interface MutationData {
  createLocation: {
    errors?: InputError[];
    location?: {
      id: string;
      name: string;
    };
  };
}

interface CreateLocationInput {
  name: string;
  phone: string;
  fax?: string;
  streetAddress: string;
  streetAddressLine2?: string;
  city: string;
  stateAbbreviation: string;
  zip: string;
  fipsCode: string;
  timeZoneName: string;
}

interface MutationVariables {
  input: CreateLocationInput;
}

const STATES_AND_TIME_ZONES = gql`
  query StatesAndTimeZones {
    timeZoneNames
    me {
      id
      timeZoneName
    }
    states {
      id
      name
      abbreviation
    }
  }
`;

interface StatesAndTimeZonesData {
  timeZoneNames: string[];
  me: {
    id: string;
    timeZoneName: string;
  };
  states: {
    id: string;
    name: string;
    abbreviation: string;
  }[];
}

type NewLocationFormProps = {
  refetchQueries: any;
  closeModal: () => void;
};

const NewLocationForm: React.FC<NewLocationFormProps> = ({
  refetchQueries,
  closeModal,
}) => {
  const { data, loading } = useQuery<StatesAndTimeZonesData>(
    STATES_AND_TIME_ZONES
  );
  const [createLocation] = useMutation<MutationData, MutationVariables>(
    CREATE_LOCATION
  );
  return (
    <div className="NewLocationForm">
      <Formik
        initialValues={{
          name: "",
          phone: "",
          fax: "",
          streetAddress: "",
          streetAddressLine2: "",
          city: "",
          stateAbbreviation: "",
          zip: "",
          fipsCode: "",
          timeZoneName: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
          phone: Yup.string()
            .required("Required")
            .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, "Invalid phone number"),
          fax: Yup.string().matches(
            /^\(\d{3}\)\s\d{3}-\d{4}$/,
            "Invalid fax number"
          ),
          streetAddress: Yup.string().required("Required"),
          city: Yup.string().required("Required"),
          stateAbbreviation: Yup.string().required("Required"),
          zip: Yup.string().required("Required"),
          fipsCode: Yup.string().required("Required"),
          timeZoneName: Yup.string().required("Required"),
        })}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setStatus({ errors: null });
          createLocation({
            variables: { input: values },
            refetchQueries,
          }).then(res => {
            if (res && res.data && res.data.createLocation.errors) {
              setStatus({
                errors: res.data.createLocation.errors,
              });
            } else {
              analytics.track("Create Location", {
                name: values.name,
              });
              toast.success(`Created Location`);
              closeModal();
            }
            setSubmitting(false);
          });
        }}
      >
        {({ values, status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <div className="mt-3">
              <TextField name="name" label="Name" autoFocus />
            </div>
            <div className="mt-3 flex flex-wrap -mx-3">
              <div className="w-full md:w-2/3 px-3">
                <PhoneMaskField
                  name="phone"
                  label="Phone"
                  placeholder="(___) ___-____"
                  icon="phone"
                />
              </div>
              <div className="w-full md:w-1/3 px-3">
                <TextField name="phoneExtension" label="Phone Ext." />
              </div>
            </div>
            <div className="mt-3 flex flex-wrap -mx-3">
              <div className="w-full md:w-2/3 px-3">
                <PhoneMaskField
                  name="fax"
                  label="Fax"
                  placeholder="(___) ___-____"
                  icon="fax"
                />
              </div>
              <div className="w-full md:w-1/3 px-3">
                <TextField name="faxExtension" label="Fax Ext." />
              </div>
            </div>
            <div className="mt-3">
              <TextField name="streetAddress" label="Address" />
            </div>
            <div className="mt-3">
              <TextField name="streetAddressLine2" label="Address Line 2" />
            </div>
            <div className="mt-3">
              <TextField name="city" label="City" />
            </div>
            <div className="mt-3">
              <SelectField
                name="stateAbbreviation"
                label="State"
                options={
                  data?.states.map(s => ({
                    value: s.abbreviation,
                    label: s.name,
                  })) || []
                }
                isLoading={loading}
              />
            </div>
            <div className="mt-3">
              <TextField name="zip" label="Zip Code" />
            </div>
            <div className="mt-3">
              <CountySelectField
                name="fipsCode"
                label="County"
                zip={values.zip}
              />
            </div>
            <div className="mt-3">
              <SelectField
                name="timeZoneName"
                label="Time Zone"
                isLoading={loading}
                options={
                  data?.timeZoneNames.map((tz: string) => ({
                    value: tz,
                    label: tz,
                  })) || []
                }
              />
            </div>
            <div className="flex items-center justify-end mt-3 py-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-blue"
              >
                Create Location
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { NewLocationForm };
