import React, { useState } from "react";
import {
  Link,
  RouteComponentProps,
  Route,
  Redirect,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Modal, ModalHeader } from "components/Modal";
import { Spinner } from "components/Spinner";
import { PatientMember } from "screens/PatientsScreen/PatientsScreen";
import { NavTabs, NavTabLink } from "layouts/PortalLayout/NavTabs";
import styled from "styled-components";
import { PatientInsuranceCoverages } from "./PatientInsuranceCoverages";
import { EditPatientMemberForm } from "./EditPatientMemberForm";
import { FAIcon } from "components/FAIcon";
import { ScreenTitle } from "context/ScreenTitle";
import { useFeatureFlags } from "hooks/useFeatureFlags";

const PatientShowRoot: React.FC<RouteComponentProps> = ({ match }) => (
  <Redirect to={`${match.url}/activity`} />
);

const QuickAction = styled.div`
  flex-basis: 0;
`;

export const PATIENT_MEMBER = gql`
  query GetPatientMember($id: UUID4!) {
    patientMember(id: $id) {
      id
      medicalRecordNumber
      isSelfPay
      patient {
        id
        firstName
        lastName
        dob
        primaryPhone {
          formatted
          raw
        }
      }
      insuranceCoverages {
        id
        membershipNumber
        updatedAt
        unlistedCarrierName
        unlistedHealthPlanName
        healthPlan {
          id
          name
          carrier {
            id
            name
          }
        }
      }
    }
  }
`;

interface Data {
  patientMember: PatientMember;
}

interface Variables {
  id: string;
}

interface PatientShowScreenProps { }

export const PatientShowScreen: React.FC<PatientShowScreenProps> = () => {
  const match = useRouteMatch<{ id: string }>();

  const { id } = match.params;
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const { data, loading, error } = useQuery<Data, Variables>(PATIENT_MEMBER, { variables: { id } });

  const { hasFeature } = useFeatureFlags();

  return (
    <div className="PatientShowScreen h-full">
      <ScreenTitle title="Patients » View Patient" />
      <Modal
        isOpen={activeModal === "EDIT_PATIENT"}
        onRequestClose={() => setActiveModal(null)}
      >
        <ModalHeader
          icon="user-edit"
          title="Edit Patient"
          onClose={() => setActiveModal(null)}
        />
        <div className="px-8 pt-6 pb-8 flex flex-col">
          <EditPatientMemberForm
            patientMemberId={id}
            onSuccess={() => setActiveModal(null)}
          />
        </div>
      </Modal>
      {loading ? (
        <div className="p-12 text-center">
          <Spinner />
        </div>
      ) : error || !data ? (
        <div>
          <p>Failed to load</p>
        </div>
      ) : (
        <div className="h-full flex">
          <div className="w-1/3 flex-grow-0 flex-shrink-0 bg-white border-r border-gray-300">
            <div className="h-full w-full overflow-y-scroll">
              <div className="mt-3">
                <Link
                  to="/o/patients"
                  className="border-b border-blue-500 border-dashed font-medium text-blue-700"
                >
                  <span className="mr-2 text-sm">
                    <FAIcon icon="chevron-left" />
                  </span>
                  Back to Patients
                </Link>
              </div>
              <section
                id="patient-header-section"
                className="px-8 pt-4 pb-8"
              >
                <div className="align-middle bg-indigo-100 border border-2 border-indigo-400 flex flex-col h-20 justify-center mt-2 mx-auto rounded-full shadow-inner text-xl sm:text-2xl lg:text-4xl text-indigo-800 w-20">
                  <span className="tracking-wider">
                    {data.patientMember.patient.firstName.slice(0, 1)}
                    {data.patientMember.patient.lastName.slice(0, 1)}
                  </span>
                </div>
                <p className="mt-3 text-2xl text-gray-900">
                  {data.patientMember.patient.firstName}{" "}
                  {data.patientMember.patient.lastName}
                </p>
                <table className="mt-2 mx-auto">
                  <tbody>
                    <tr>
                      <td className="text-right">
                        <span className="mr-1 text-gray-600 font-normal">
                          DOB:
                        </span>
                      </td>
                      <td className="text-left">
                        <p className="text-gray-700 font-semibold">
                          {data.patientMember.patient.dob}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right">
                        <span className="mr-1 text-gray-600 font-normal">
                          MRN:
                        </span>
                      </td>
                      <td className="text-left">
                        <p className="text-gray-700 font-semibold font-mono">
                          {data.patientMember.medicalRecordNumber
                            ? data.patientMember.medicalRecordNumber
                            : "-"}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div
                  id="patient-quick-actions"
                  className="mt-4 flex justify-around align-middle"
                >
                  {hasFeature("referrals") ? (
                    <QuickAction className="flex-grow flex-shrink-0 px-2">
                      <Link
                        to={`/o/referral/new?patientId=${id}`}
                        className="mx-auto flex items-center justify-center bg-blue-500 border-blue-700 h-10 hover:bg-blue-600 active:bg-blue-700 focus:outline-none focus:shadow-outline rounded-full text-white w-10"
                      >
                        <FAIcon icon="share" />
                      </Link>
                      <p className="font-medium mt-1 text-blue-800 text-sm">
                        Refer
                      </p>
                    </QuickAction>
                  ) : null}

                  {/* <QuickAction className="flex-grow flex-shrink-0 px-2">
                      <button className="bg-blue-500 border-blue-700 h-10 hover:bg-blue-600 active:bg-blue-700 focus:outline-none focus:shadow-outline rounded-full text-white w-10">
                        <FAIcon icon="paper-plane" />
                      </button>
                      <p className="font-medium mt-1 text-blue-800 text-sm">
                        Send
                      </p>
                    </QuickAction> */}

                  {hasFeature('text_messaging') ? (
                    <QuickAction className="flex-grow flex-shrink-0 px-2">
                      <button className="bg-blue-500 border-blue-700 h-10 hover:bg-blue-600 active:bg-blue-700 focus:outline-none focus:shadow-outline rounded-full text-white w-10">
                        <FAIcon icon="comment-dots" />
                      </button>
                      <p className="font-medium mt-1 text-blue-800 text-sm">
                        Message
                      </p>
                    </QuickAction>
                  ) : null}
                </div>
              </section>
              <section
                id="patient-info-section"
                className="px-8 pt-4 pb-8 border-t border-gray-300 text-left"
              >
                <div className="flex justify-between">
                  <p className="font-semibold text-indigo-700">
                    About this patient:
                  </p>
                  <button
                    className="border-b-2 border-blue-500 border-dashed font-medium text-blue-600 text-sm"
                    onClick={() => setActiveModal("EDIT_PATIENT")}
                  >
                    Edit
                    <span className="ml-2">
                      <FAIcon icon="pencil-alt" />
                    </span>
                  </button>
                </div>

                <div className="-mx-2 flex mt-6">
                  <div className="flex-grow px-2">
                    <p className="font-semibold text-gray-600 text-sm">
                      First Name
                    </p>
                    <p className="mt-1 text-gray-800 text-xl">
                      {data.patientMember.patient.firstName}
                    </p>
                  </div>
                  <div className="flex-grow px-2">
                    <p className="font-semibold text-gray-600 text-sm">
                      Last Name
                    </p>
                    <p className="mt-1 text-gray-800 text-xl">
                      {data.patientMember.patient.lastName}
                    </p>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="font-semibold text-gray-600 text-sm">
                    Date of Birth
                  </p>
                  <p className="mt-1 text-gray-800 text-xl">
                    {data.patientMember.patient.dob}
                  </p>
                </div>
                <div className="-mx-2 flex mt-4">
                  <div className="flex-grow px-2">
                    <p className="font-semibold text-gray-600 text-sm">
                      Primary Phone
                    </p>
                    <p className="mt-1 text-gray-800 text-xl">
                      {data.patientMember.patient.primaryPhone?.formatted ||
                        "-"}
                    </p>
                  </div>
                  <div className="flex-grow px-2">
                    <p className="font-semibold text-gray-600 text-sm">
                      Secondary Phone
                    </p>
                    <p className="mt-1 text-gray-800 text-xl">
                      {data.patientMember.patient.secondaryPhone ? (
                        data.patientMember.patient.secondaryPhone.formatted
                      ) : (
                        <span className="text-gray-600">-</span>
                      )}
                    </p>
                  </div>
                </div>
              </section>
              <section
                id="patient-primary-insurance"
                className="border-gray-300 border-t px-8 pt-4 pb-8 text-left"
              >
                <PatientInsuranceCoverages
                  patientMemberId={data.patientMember.id}
                />
              </section>
            </div>
          </div>
          <div className="flex-grow h-full overflow-y-scroll">
            <div className="text-left">
              <div>
                <NavTabs>
                  <NavTabLink to={`${match.url}/activity`}>
                    <span>Activity</span>
                  </NavTabLink>
                  {/* <NavTabLink to={`${match.url}/notes`}>
                        <span>Notes</span>
                      </NavTabLink>
                      <NavTabLink to={`${match.url}/tasks`}>
                        <span>Tasks</span>
                      </NavTabLink> */}
                </NavTabs>
              </div>

              <Switch>
                <Route exact path={match.path}>
                  <Redirect to={`${match.url}/activity`} />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      )
      }
    </div>
  );
};
