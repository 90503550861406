import { FC, useCallback, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { HorizontalHealthPlanPickerField } from "components/formik/HealthPlanPickerField";
import { Button, HorizontalField, HorizontalTextField } from "@preferral/ui";
import toast from "react-hot-toast";

const CREATE_INSURANCE_COVERAGE = gql`
  mutation CreateInsuranceCoverage(
    $patientMemberId: UUID4!
    $input: CreateInsuranceCoverageInput!
  ) {
    createInsuranceCoverage(patientMemberId: $patientMemberId, input: $input) {
      errors {
        key
        message
      }
      insuranceCoverage {
        id
      }
    }
  }
`;

interface MutationData {
  createInsuranceCoverage: {
    errors?: InputError[];
    insuranceCoverage?: {
      id: string;
    };
  };
}

interface CreateInsuranceCoverageInput {
  carrierId?: string;
  unlistedCarrierName?: string;
  healthPlanId?: string;
  unlistedHealthPlanName?: string;
  membershipNumber: string;
}

interface MutationVariables {
  patientMemberId: string;
  input: CreateInsuranceCoverageInput;
}

type AddInsuranceCoverageFormProps = {
  patientMemberId: string;
  refetchQueries: any;
  closeModal: () => void;
};

export const AddInsuranceCoverageForm: FC<AddInsuranceCoverageFormProps> = (props) => {
  const { patientMemberId, refetchQueries, closeModal } = props;

  const [isUnlisted, setIsUnlisted] = useState(false);
  const [createInsuranceCoverage] = useMutation<
    MutationData,
    MutationVariables
  >(CREATE_INSURANCE_COVERAGE);

  const onSubmit = useCallback(async (formValues, { setStatus, setSubmitting }) => {
    setStatus({ errors: null });

    const input = {
      ...formValues,
      ...(formValues.healthPlan || {})
    };

    delete input.healthPlan;

    try {
      const { data } = await createInsuranceCoverage({
        variables: {
          patientMemberId,
          input,
        },
        refetchQueries,
      });

      if (data?.createInsuranceCoverage.errors) {
        setStatus({ errors: data.createInsuranceCoverage.errors });
      } else if (data?.createInsuranceCoverage.insuranceCoverage) {
        // it worked
        toast.success("Insurance added");
        return closeModal();
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong");
    }
    setSubmitting(false);
  }, [patientMemberId, refetchQueries, createInsuranceCoverage, closeModal]);

  return (
    <div className="AddInsuranceCoverageForm">
      <Formik
        initialValues={{
          membershipNumber: "",
          healthPlan: {
            carrierId: null,
            healthPlanId: null,
            ribbonHealthPlanUuid: null,
          },
          unlistedCarrierName: "",
          unlistedHealthPlanName: "",
        }}
        validationSchema={
          !isUnlisted
            ? Yup.object().shape({
              healthPlan: Yup.object().shape({
                carrierId: Yup.string().nullable(),
                healthPlanId: Yup.string().nullable(),
              })
                .test({
                  name: "carrierIdAndHealthPlanIdSet",
                  // NB: This MUST NOT use fat arrow function syntax because we need `this`.
                  test: function (values: any) {
                    const isValid =
                      !!values.carrierId &&
                      (!!values.healthPlanId || !!values.ribbonHealthPlanUuid);

                    if (isValid) return true;

                    return this.createError({
                      path: "",
                      message: "Please set carrier and health plan",
                    });
                  },
                })
                .required("Please select a health plan"),
              membershipNumber: Yup.string().required("Required"),
            })
            : Yup.object().shape({
              membershipNumber: Yup.string()
                .trim()
                .required("Required"),
              unlistedCarrierName: Yup.string()
                .trim()
                .required("Required"),
              unlistedHealthPlanName: Yup.string()
                .trim()
                .required("Required"),
            })
        }
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <div className="mt-3">
              <HorizontalHealthPlanPickerField
                name="healthPlan"
                label="Insurance Plan"
                disabled={isUnlisted}
              />

              <HorizontalField label="">
                <label htmlFor="isUnlisted">
                  <input
                    id="isUnlisted"
                    type="checkbox"
                    checked={isUnlisted}
                    onChange={() => setIsUnlisted(!isUnlisted)}
                  />
                  <span className="ml-2 text-gray-900">
                    Carrier/Health Plan not in list
                  </span>
                </label>
              </HorizontalField>

              {isUnlisted ? (
                <>
                  <HorizontalTextField
                    name="unlistedCarrierName"
                    label="Carrier Name"
                  />

                  <HorizontalTextField
                    name="unlistedHealthPlanName"
                    label="Health Plan Name"
                  />
                </>
              ) : null}
              <HorizontalTextField
                name="membershipNumber"
                label="Membership Number"
              />
            </div>

            <div className="flex items-center justify-center mt-3 py-4">
              <Button
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                kind="primary"
                color="blue"
              >
                Add Insurance
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
