import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { SendFaxForm } from "./SendFaxForm";

interface SendFaxModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const SendFaxModal: FC<SendFaxModalProps> = (props) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="fax"
        title="Send Fax"
        onClose={onClose}
      />
      <div className="p-4">
        <SendFaxForm
          onSuccess={onClose}
        />
      </div>
    </Modal>
  );
};
