import { FC } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router";
import { NavTabs, NavTabLink } from "layouts/PortalLayout/NavTabs";
import { ScreenTitle } from "context/ScreenTitle";
import { FaxesInboxScreen } from "screens/FaxesInboxScreen";

const FaxOutboxScreen = () => <h1>Fax Outbox</h1>;
const ContactsScreen = () => <h1>Contacts</h1>;
const EventLogScreen = () => <h1>EventLog</h1>;

interface FaxesScreenProps { }

export const FaxesScreen: FC<FaxesScreenProps> = () => {
  const match = useRouteMatch();

  return (
    <div className="FaxesScreen min-h-full flex flex-col">
      <ScreenTitle title="Faxes" />

      <NavTabs>
        <NavTabLink to={`${match.url}/inbox`} activeClassName="bg-white">
          <span>Inbox</span>
        </NavTabLink>
        <NavTabLink to={`${match.url}/outbox`}>
          <span>Outbox</span>
        </NavTabLink>
        <NavTabLink to={`${match.url}/contacts`}>
          <span>Contacts</span>
        </NavTabLink>
      </NavTabs>

      <Switch>
        <Route exact path={match.path}>
          <Redirect to={`${match.url}/inbox`} />
        </Route>
        <Route path={`${match.url}/inbox`}>
          <FaxesInboxScreen />
        </Route>
        <Route path={`${match.url}/outbox`}>
          <FaxOutboxScreen />
        </Route>
        <Route path={`${match.url}/contacts`}>
          <ContactsScreen />
        </Route>
        <Route path={`${match.url}/event_log`}>
          <EventLogScreen />
        </Route>
      </Switch>
    </div>
  );
};
