import { useReducer, useCallback } from "react";

interface Action<T> {
  type: "ADD" | "REMOVE" | "CLEAR";
  item?: T;
}

function setReducer<T>(prevState: Set<T>, action: Action<T>): Set<T> {
  switch (action.type) {
    case "ADD": {
      let nextState = new Set(prevState);
      nextState.add(action.item!);
      return nextState;
    }
    case "REMOVE": {
      let nextState = new Set(prevState);
      nextState.delete(action.item!);
      return nextState;
    }
    case "CLEAR": {
      let nextState = new Set(prevState);
      nextState.clear();
      return nextState;
    }
  }
}

export function useSet<T>(initialState: T[] | Set<T> = []) {
  const [state, dispatch] = useReducer(setReducer, new Set(initialState));

  const add = useCallback(
    (item: T) => {
      return dispatch({ type: "ADD", item });
    },
    [dispatch]
  );

  const remove = useCallback(
    (item: T) => {
      return dispatch({ type: "REMOVE", item });
    },
    [dispatch]
  );

  const clear = useCallback(() => {
    return dispatch({ type: "CLEAR" });
  }, [dispatch]);

  return {
    add,
    remove,
    clear,
    has: state.has.bind(state),
  };
}
