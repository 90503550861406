import React, { FC } from "react";
import { Formik } from "formik";
import { Button } from "@preferral/ui";
import {
  QuestionnaireModel,
  QuestionnaireFields,
  templateToFields
} from "components/Questionnaire";

interface PreviewFormProps {
  template: QuestionnaireModel
};

export const PreviewForm: FC<PreviewFormProps> = props => {
  const { template } = props;

  return (
    <div className="PreviewForm">
      <Formik
        initialValues={{
          form: templateToFields(template)
        }}
        onSubmit={console.log}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <QuestionnaireFields
              name="form"
              template={template}
            />
            <div className="p-4 text-center mt-3">
              <Button type="submit" color="blue">
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
      {/* <pre className="text-left text-xs">
        {JSON.stringify(template, null, 2)}
      </pre> */}
    </div>
  );
};
