import { FC, useCallback } from "react";
import { useMutation, gql } from "@apollo/client";
import TagIcon from "@heroicons/react/outline/TagIcon";
import { Button } from "@preferral/ui";
import { Modal } from "components/Modal";
import toast from "react-hot-toast";

const DELETE_TAG = gql`
  mutation DeletePatientPacketSubmissionTag($id: UUID4!) {
    deletePatientPacketSubmissionTag(id: $id) {
      errors {
        key
        message
      }
      patientPacketSubmissionTag {
        id
      }
    }
  }
`;

interface MutationData {
  deletePatientPacketSubmissionTag: {
    errors?: InputError[];
    patientPacketSubmissionTag?: {
      id: string;
    };
  };
}

interface MutationVariables {
  id: any;
}

interface DeleteTagModalProps {
  id: string;
  isOpen: boolean;
  onClose(): void;
  onSuccess?(): void;
}

export const DeleteTagModal: FC<DeleteTagModalProps> = (props) => {
  const { isOpen, onClose, id, onSuccess } = props;

  const [deleteTag, { loading }] = useMutation<MutationData, MutationVariables>(
    DELETE_TAG
  );

  const onSubmit = useCallback(
    async (id: string) => {
      const variables = { id };

      try {
        const { data } = await deleteTag({ variables });
        for (let error of data?.deletePatientPacketSubmissionTag.errors || []) {
          toast.error(error.message);
        }
        if (data?.deletePatientPacketSubmissionTag.patientPacketSubmissionTag) {
          // it worked...
          toast.success("Tag deleted.");
          return onSuccess && onSuccess();
        }
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
      }
    },
    [deleteTag, onSuccess]
  );

  return (
    <Modal
      className="_DeleteTagModal max-w-lg"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className=" p-4">
        <div className="sm:flex sm:items-start">
          <div className="sm:mx-0 sm:h-10 sm:w-10 flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full">
            <TagIcon className="w-6 h-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="sm:mt-0 sm:ml-4 sm:text-left mt-3 text-center">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Delete Tag
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Are you sure you want to delete this patient packet submission tag? The
                tag will be removed from all patient packet submissions.
              </p>
            </div>
          </div>
        </div>
        <div className="sm:mt-4 sm:flex sm:flex-row-reverse mt-5">
          <Button
            type="button"
            kind="primary"
            color="red"
            isLoading={loading}
            className="sm:ml-3"
            onClick={() => onSubmit(id)}
          >
            Delete Tag
          </Button>
          <Button
            type="button"
            className="hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
