import { FC, useCallback } from "react";
import { gql, useQuery } from "@apollo/client";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Spinner, FadeUpIn } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { CircleIconHeader } from "components/CircleIconHeader";
import { ConfirmationForm, AppointmentRequestModel } from "./ConfirmationForm";
import { ReferralBrief } from "../QuestionsStep/ReferralBrief";

const INBOUND_REFERRAL_CONFIRMATION = gql`
  query FetchInboundReferralConfirmation($id: UUID4!) {
    inboundReferral(id: $id) {
      id
      patientMemberId
      patientMember {
        id
        email
        patient {
          id
          firstName
          lastName
          dob
          consentToTextMessage
          primaryPhone {
            raw
            formatted
          }
          secondaryPhone {
            raw
            formatted
          }
        }
      }
    }
  }
`;

interface Data {
  inboundReferral: {
    id: string;
    patientMemberId: string;
    patientMember: {
      id: string;
      email: string;
      patient: {
        id: string;
        consentToTextMessage: boolean;
        primaryPhone?: {
          raw: string;
          formatted: string;
        };
        secondaryPhone?: {
          raw: string;
          formatted: string;
        };
      };
    };
  };
}

interface Variables {
  id: string;
}

interface RouteParams {
  inboundReferralId: string;
}

interface ConfirmationStepProps { }

export const ConfirmationStep: FC<ConfirmationStepProps> = () => {
  const match = useRouteMatch<RouteParams>();
  const { inboundReferralId } = match.params;

  const { data, loading, error } = useQuery<Data, Variables>(
    INBOUND_REFERRAL_CONFIRMATION,
    { variables: { id: inboundReferralId } }
  );

  const history = useHistory();

  const handleSuccess = useCallback(
    (appointmentRequest: AppointmentRequestModel) => {
      history.push(`/o/appointment_requests/${appointmentRequest.id}`);
    },
    [history]
  );

  return (
    <>
      <ScreenTitle title={["Inbound Request", "Confirm"]} />
      <div className="_ConfirmationStep container mx-auto mt-4 mb-8 text-left">
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.inboundReferral ? (
          <p>Failed to load</p>
        ) : (
          <FadeUpIn show>
            <div className="flex -mx-2 mt-4">
              <div className="flex-1 max-w-sm mx-2">
                <div className="rounded-2xl bg-white shadow-md p-4 sticky top-4">
                  <ReferralBrief inboundReferralId={inboundReferralId} />
                </div>
              </div>

              <div className="flex-1 mx-2 pb-8">
                <div className="bg-white px-2 pt-4 rounded-xl shadow-xl">
                  <CircleIconHeader icon="list-alt">
                    Last Step: Confirm
                  </CircleIconHeader>
                  <div className="max-w-2xl p-2 mx-auto">
                    <h3 className="border-b border-cool-gray-300 border-double font-medium pb-2 pt-1 text-cool-gray-700 text-xl">
                      Patient Contact Info
                    </h3>
                    <ConfirmationForm
                      inboundReferralId={inboundReferralId}
                      onSuccess={handleSuccess}
                      initialValues={{
                        consentToTextMessage:
                          data.inboundReferral.patientMember.patient
                            .consentToTextMessage,
                        primaryPhone:
                          data.inboundReferral.patientMember.patient
                            .primaryPhone?.formatted || "",
                        secondaryPhone:
                          data.inboundReferral.patientMember.patient
                            .secondaryPhone?.formatted || "",
                        email: data.inboundReferral.patientMember.email || "",
                        additionalNotes: "",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </FadeUpIn>
        )}
      </div>
    </>
  );
};
