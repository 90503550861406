import { FC, useCallback } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { gql, useQuery, useMutation } from "@apollo/client";
import { StandardOption, SelectField, Button } from "@preferral/ui";
import { useAppointmentRequest } from "../../AppointmentRequestContext";
import toast from "react-hot-toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Spinner } from "@preferral/ui";

const defaultDelayReasonOptions: StandardOption[] = [
  "Patient Preference",
  "Post Surgery",
  "Referring Provider",
  "Difficulty Contacting Patient",
].map((r) => ({ value: r, label: r }));

const DELAY_REASONS = gql`
  query GetDelayReasons($departmentId: UUID4!) {
    departmentSettings(id: $departmentId) {
      id
      schedulingDelayReasons {
        id
        reason
      }
    }
  }
`;

interface Data {
  departmentSettings: {
    id: string;
    schedulingDelayReasons: SchedulingDelayReasonModel[];
  };
}

interface Variables {
  departmentId: string;
}

interface SchedulingDelayReasonModel {
  id: string;
  reason: string;
}

const UPDATE_REASON_FOR_DELAY = gql`
  mutation UpdateReasonForDelay(
    $appointmentRequestId: UUID4!
    $reason: String!
  ) {
    updateAppointmentRequestReasonForDelay(
      appointmentRequestId: $appointmentRequestId
      reason: $reason
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
      }
    }
  }
`;

interface MutationData {
  updateAppointmentRequestReasonForDelay: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appointmentRequestId: string;
  reason: string;
}

interface FormValues {
  reason: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    reason: Yup.string().required("Required"),
  })
  .required();

interface ReasonForDelayFormProps {
  onSuccess(): void;
}

export const ReasonForDelayForm: FC<ReasonForDelayFormProps> = (props) => {
  const { onSuccess } = props;
  const {
    appointmentRequest: { id, departmentId, reasonForDelay },
    refetch,
  } = useAppointmentRequest();
  const { data, loading } = useQuery<Data, Variables>(DELAY_REASONS, {
    variables: { departmentId },
  });
  const [updateReason] = useMutation<MutationData, MutationVariables>(
    UPDATE_REASON_FOR_DELAY
  );

  const candidateReasonOptions =
    data?.departmentSettings.schedulingDelayReasons.map(reasonAsOption) || [];
  const reasonOptions =
    candidateReasonOptions.length === 0 && !loading
      ? defaultDelayReasonOptions
      : candidateReasonOptions;

  const onSubmit = useCallback(
    (values, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;
      return updateReason({
        variables: { appointmentRequestId: id, reason: values.reason },
      }).then((resp) => {
        if (resp.data?.updateAppointmentRequestReasonForDelay.errors) {
          setStatus({
            errors: resp.data.updateAppointmentRequestReasonForDelay.errors,
          });
        } else if (
          resp.data?.updateAppointmentRequestReasonForDelay.appointmentRequest
        ) {
          // it worked...
          toast.success("Reason for delay updated!");
          refetch();
          return onSuccess();
        }
        setSubmitting(false);
      });
    },
    [id, updateReason, refetch, onSuccess]
  );

  return loading ? (
    <div className="p-12 text-center">
      <Spinner />
    </div>
  ) : (
    <Formik<FormValues>
      initialValues={{ reason: reasonForDelay || "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form className="_ReasonForDelayForm" onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <SelectField
            name="reason"
            label="Reason for Delay"
            options={reasonOptions}
            isLoading={loading}
          />
          <div className="mt-3 text-center">
            <Button
              type="submit"
              kind="primary"
              color="blue"
              isLoading={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

function reasonAsOption(reason: SchedulingDelayReasonModel): StandardOption {
  return {
    value: reason.reason,
    label: reason.reason,
  };
}
