import { FC, useCallback } from "react";
import { useMutation, gql } from '@apollo/client';
import { Modal } from 'components/Modal';
import { Button } from '@preferral/ui';
import toast from 'react-hot-toast';
import LockOpenIcon from '@heroicons/react/outline/LockOpenIcon';

const UNLOCK_USER = gql`
  mutation UnlockUser($userId: UUID4!) {
    unlockUser(id: $userId) {
      errors {
        key
        message
      }
      user {
        id
        lockedFromFailedLoginAttempts
      }
    }
  }
`;

interface MutationData {
  unlockUser: {
    errors?: InputError[];
    user?: {
      id: string;
      lockedFromFailedLoginAttempts: boolean;
    };
  }
}

interface MutationVariables {
  userId: string;
}

interface UnlockUserModalProps {
  isOpen: boolean;
  onClose(): void;
  userId: string;
  onSuccess(): void;
};

export const UnlockUserModal: FC<UnlockUserModalProps> = props => {
  const { isOpen, onClose, userId, onSuccess } = props;

  const [unlockUser, { loading: submitting }] = useMutation<MutationData, MutationVariables>(UNLOCK_USER);

  const onSubmit = useCallback(() => {
    return unlockUser({ variables: { userId } }).then(resp => {
      if (resp.data?.unlockUser.errors) {
        toast.error(resp.data.unlockUser.errors[0].message);
      } else if (resp.data?.unlockUser.user) {
        // it worked...
        toast.success("User account unlocked");
        onSuccess();
        onClose();
      }
    })
  }, [userId, unlockUser, onSuccess, onClose])

  return (
    <Modal className="_UnlockUserModal max-w-lg" isOpen={isOpen} onRequestClose={onClose}>
      <div className="p-4">
        <div className="sm:flex sm:items-start">
          <div className="sm:mx-0 sm:h-10 sm:w-10 flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-blue-100 rounded-full">
            <LockOpenIcon
              className="w-6 h-6 text-blue-600"
              aria-hidden="true"
            />
          </div>
          <div className="sm:mt-0 sm:ml-4 sm:text-left mt-3 text-center">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Unlock User Account
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Are you sure you want to unlock this user account? Doing so will enable them to continue to attempt to login.
              </p>
            </div>
          </div>
        </div>
        <div className="sm:mt-4 sm:flex sm:flex-row-reverse mt-5">
          <Button
            type="button"
            kind="primary"
            color="blue"
            isLoading={submitting}
            disabled={submitting}
            className="sm:ml-3"
            onClick={onSubmit}
          >
            Unlock User Account
          </Button>
          <Button
            type="button"
            className="hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
