import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Button, InputText } from "@preferral/ui";
import { FormStatusErrors } from "components/formik/FormStatusErrors";

export const SSOForm: FC = () => {
  const [subdomain, setSubdomain] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<{ errors?: null | InputError[] }>({
    errors: null,
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setStatus({ errors: null });
    setTimeout(() => {
      setStatus({
        errors: [{ key: "", message: "Organization domain not found" }],
      });
    }, 400);
  };

  return (
    <>
      <div className="_SSOForm bg-white py-8 px-4 shadow-xl sm:rounded-2xl sm:px-10">
        <p className="text-gray-700 text-sm">
          Enter your organization's domain:
        </p>
        <form className="mt-6" onSubmit={handleSubmit}>
          <div className="max-w-xs mx-auto flex items-center">
            <InputText
              value={subdomain}
              onChange={setSubdomain}
              inputProps={{
                name: "subdomain",
                autoFocus: true,
                placeholder: "domain",
              }}
              containerClassName="flex-1"
              className="text-right"
            />
            <p className="font-medium ml-1 text-gray-800">.preferral.com</p>
          </div>
          <FormStatusErrors status={status as any} />
          <div className="mt-6 max-w-xs mx-auto">
            <span className="block w-full rounded-md shadow-sm">
              <Button
                type="submit"
                disabled={subdomain === "" || isSubmitting}
                color="blue"
                className="w-full justify-center"
              >
                Continue
              </Button>
            </span>
          </div>
        </form>
      </div>
      <div className="mt-4 text-sm">
        <p className="leading-5 max-w mt-1 text-center text-gray-600 text-sm">
          <Link
            to="/auth/sign_in"
            className="inline-block ml-2 text-blue-500 hover:text-blue-700 font-semibold"
          >
            Sign in with your email and password
          </Link>
        </p>
      </div>
    </>
  );
};
