import {
  CommunicationPreference,
  CommunicationType,
  MergedPreference,
} from "./model";

export function mediumLabel(medium: string): string {
  return medium === "sms" ? "Text Messages" : medium;
}

export function preferenceKey(
  preference: CommunicationType | CommunicationPreference
): string {
  return `${preference.medium}-${preference.notification}`;
}

export function isMatchingPreference(
  communicationType: CommunicationType,
  preference: CommunicationPreference
): boolean {
  return (
    communicationType.medium === preference.medium &&
    communicationType.notification === preference.notification
  );
}

export function preferredValue(
  communicationType: CommunicationType,
  preferences: CommunicationPreference[]
): boolean {
  const preference = preferences.find(pref =>
    isMatchingPreference(communicationType, pref)
  );
  return preference ? preference.value : communicationType.defaultValue;
}

export function mergeDefaultsAndPreferences(
  communicationTypes: CommunicationType[],
  preferences: CommunicationPreference[]
): MergedPreference[] {
  return communicationTypes.map(commType => ({
    ...commType,
    value: preferredValue(commType, preferences),
  }));
}

export function groupByMedium(preferences: MergedPreference[]) {
  const obj: { [x: string]: MergedPreference[] } = preferences.reduce(
    (acc: { [x: string]: MergedPreference[] }, elem) => {
      if (acc[elem.medium]) {
        acc[elem.medium].push(elem);
      } else {
        acc[elem.medium] = [elem];
      }
      return acc;
    },
    {}
  );
  return Object.keys(obj).map(key => ({ medium: key, items: obj[key] }));
}
