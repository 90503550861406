import {
  FC,
  useState,
  createContext,
  useContext,
  useEffect
} from "react";

type State = string[];
type Update = (newState: State) => void;

const ScreenTitleStateContext = createContext<State | undefined>(undefined);
const ScreenTitleUpdateContext = createContext<Update | undefined>(undefined);

/**
 * This module provides 2 components and a hook:
 * - <ScreenTitleProvider />,
 * - <ScreenTitle />
 * - useScreenTitle()
 *
 * This module doesn't deal with displaying the ScreenTitle visually.
 *
 */

/**
 * ScreenTitleProvider
 */

export const ScreenTitleProvider: FC = (props) => {
  const { children } = props;
  const [title, setTitle] = useState<State>([""]);

  useEffect(() => {
    document.title = asString(title);
  }, [title]);

  return (
    <ScreenTitleStateContext.Provider value={title}>
      <ScreenTitleUpdateContext.Provider value={setTitle}>
        {children}
      </ScreenTitleUpdateContext.Provider>
    </ScreenTitleStateContext.Provider>
  );
};

/**
 * useScreenTitle()
 */

export function useScreenTitle() {
  const stateCtx = useContext(ScreenTitleStateContext);
  const updateCtx = useContext(ScreenTitleUpdateContext);
  if (stateCtx === undefined || updateCtx === undefined) {
    throw new Error("useScreenTitle must be used within a ScreenTitleProvider");
  }
  return {
    title: stateCtx,
    shortTitle: stateCtx[stateCtx.length - 1],
    titleString: asString(stateCtx),
    updateTitle: updateCtx,
  };
}

/**
 * ScreenTitle
 */

interface ScreenTitleProps {
  title: string | string[];
}

export const ScreenTitle: FC<ScreenTitleProps> = (props) => {
  const { title } = props;
  const { updateTitle } = useScreenTitle();

  useEffect(() => {
    if (typeof title === "string") {
      updateTitle([title]);
    } else {
      updateTitle(title);
    }
  }, [title, updateTitle]);

  return null;
};

function asString(title: string | string[]): string {
  if (Array.isArray(title)) {
    return title.join(" » ");
  } else {
    return title as string;
  }
}
