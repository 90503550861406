import { FC, useEffect } from "react";
import qs from "query-string";
import { Redirect, useLocation } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { useAuth } from "context/AuthContext";
import { CircleIcon } from "@preferral/ui";
import toast from "react-hot-toast";
import { analytics } from "lib/analytics";

/**
 * This is used after an admin has requested an impersonation session
 * by associating a token -> user on the server. This page grabs the
 * token from a URL param, and POSTs to the server, which returns
 * a JWT for the user the token is associated to.
 *
 * After retrieving the JWT, we'll redirect the browser to an SPA
 * page such as the user's dashboard.
 */

const ISSUE_JWT_FOR_IMPERSONATION_TOKEN = gql`
  mutation IssueJwtForImpersonationToken($token: String!) {
    issueJwtForImpersonationToken(token: $token) {
      errors {
        key
        message
      }
      session {
        token
      }
    }
  }
`;

interface MutationData {
  issueJwtForImpersonationToken: {
    errors?: InputError[];
    session?: {
      token: string;
    };
  };
}

export const ImpersonationAuthScreen: FC = () => {
  const location = useLocation();
  const params = qs.parse(location.search);
  const { token, return_to: returnToPath = "/o/dashboard" } = params;

  const { login: signIn } = useAuth();

  const [fetchJwtForToken, { data, loading, error }] = useMutation<
    MutationData
  >(ISSUE_JWT_FOR_IMPERSONATION_TOKEN);

  useEffect(() => {
    async function fetchJwt() {
      const variables = { token };

      try {
        const { data } = await fetchJwtForToken({ variables });

        if (data?.issueJwtForImpersonationToken.session) {
          analytics.disableTracking();
          const {
            token: jwtToken,
          } = data.issueJwtForImpersonationToken.session;
          signIn(jwtToken);
        }
      } catch (e) {
        toast.error("Something went wrong.");
        console.error(e);
      }
    }

    fetchJwt();
  }, []);

  return (
    <div className="_ImpersonationAuthScreen min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 text-left">
      <div className="p-4 flex justify-center">
        <CircleIcon icon="mask" color="purple" />
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-6xl opacity-25">
        <div>
          <p className="font-semibold mt-3 mb-1">Return Path:</p>
          <pre className="text-xs bg-gray-200 overflow-x-scroll p-2 rounded">
            {returnToPath}
          </pre>
        </div>
        <div>
          <p className="font-semibold mt-3 mb-1">location.search:</p>
          <pre className="text-xs bg-gray-200 overflow-x-scroll p-2 rounded">
            {location.search}
          </pre>
        </div>
        <div>
          <p className="font-semibold mt-3 mb-1">params:</p>
          <pre className="text-xs bg-gray-200 overflow-x-scroll p-2 rounded">
            {JSON.stringify(params, null, 2)}
          </pre>
        </div>
        <div>
          {loading || (!data && !error) ? (
            <p>Loading...</p>
          ) : error ? (
            <div>
              <p className="font-semibold mt-3 mb-1">Error:</p>
              <pre className="text-xs bg-gray-200 overflow-x-scroll p-2 rounded">
                {JSON.stringify(error, null, 2)}
              </pre>
            </div>
          ) : data && data.issueJwtForImpersonationToken ? (
            <div>
              <p className="font-semibold mt-3 mb-1">Success:</p>
              <Redirect to={returnToPath as string} push />
              <pre className="text-xs bg-gray-200 overflow-x-scroll p-2 rounded">
                {JSON.stringify(data.issueJwtForImpersonationToken, null, 2)}
              </pre>
            </div>
          ) : (
            <p>
              {/* This should never happen. "Error Code 91" is arbitrary, but grep-able */}
              Something went wrong. Error Code 91.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
