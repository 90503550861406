import { FC, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { gql, useQuery, useMutation } from "@apollo/client";
import { SelectField, Button, Spinner } from "@preferral/ui";
import { useAppointmentRequest } from "../../AppointmentRequestContext";
import toast from "react-hot-toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";

const DEPARTMENT_SPECIALTIES = gql`
  query GetDepartmentSpecialties($departmentId: UUID4!) {
    departmentSpecialties(departmentId: $departmentId) {
      id
      name
    }
  }
`;

interface Data {
  departmentSpecialties: SpecialtyModel[];
}

interface Variables {
  departmentId: string;
}

interface SpecialtyModel {
  id: string;
  name: string;
}

const UPDATE_REQUESTED_SPECIALTY = gql`
  mutation UpdateAppointmentRequestSpecialty(
    $appointmentRequestId: UUID4!
    $specialtyId: UUID4!
  ) {
    updateAppointmentRequestSpecialty(
      appointmentRequestId: $appointmentRequestId
      specialtyId: $specialtyId
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
      }
    }
  }
`;

interface MutationData {
  updateAppointmentRequestSpecialty: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appointmentRequestId: string;
  specialtyId: string;
}

interface FormValues {
  specialtyId: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    specialtyId: Yup.string().required("Required"),
  })
  .required();

interface ChangeSpecialtyFormProps {
  onSuccess(): void;
}

export const ChangeSpecialtyForm: FC<ChangeSpecialtyFormProps> = (props) => {
  const { onSuccess } = props;
  const {
    appointmentRequest: { id, departmentId, requestedSpecialty },
    refetch,
  } = useAppointmentRequest();

  const { data, loading } = useQuery<Data, Variables>(DEPARTMENT_SPECIALTIES, {
    variables: { departmentId },
  });

  const [updateSpecialty] = useMutation<MutationData, MutationVariables>(
    UPDATE_REQUESTED_SPECIALTY
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikActions;
      return updateSpecialty({
        variables: {
          appointmentRequestId: id,
          specialtyId: values.specialtyId,
        },
      }).then((resp) => {
        if (resp.data?.updateAppointmentRequestSpecialty.errors) {
          setStatus({
            errors: resp.data.updateAppointmentRequestSpecialty.errors,
          });
        } else if (
          resp.data?.updateAppointmentRequestSpecialty.appointmentRequest
        ) {
          // it worked...
          toast.success("Specialty updated!");
          refetch();
          return onSuccess();
        }
        setSubmitting(false);
      });
    },
    [updateSpecialty, id, refetch, onSuccess]
  );

  const specialtyOptions =
    data?.departmentSpecialties.map((s) => ({
      value: s.id,
      label: s.name,
    })) || [];

  return loading ? (
    <div className="p-12 text-center">
      <Spinner />
    </div>
  ) : (
    <Formik<FormValues>
      initialValues={{
        specialtyId: requestedSpecialty.id,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, status, values }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <div className="mt-3">
            <SelectField
              name="specialtyId"
              label="Requested Specialty"
              options={specialtyOptions}
              isLoading={loading}
            />
          </div>
          <div className="flex items-center justify-end mt-3">
            <Button
              type="submit"
              kind="primary"
              color="blue"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Update Specialty
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
