import { FC, useCallback, useState } from "react";
import {
  useRouteMatch,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Avatar, ImageObject, Spinner } from "@preferral/ui";
import { ProviderProfile } from "./ProviderProfile";
import { ProviderLocations } from "./ProviderLocations";
import { ProviderDepartments } from "./ProviderDepartments";
import { NavLinkTabs, NavLinkTab } from "components/NavLinkTabs";
import { ProviderSettings } from "./ProviderSettings";
import { ProfilePictureModal } from "./ProfilePictureModal";
import medofficeSrc from "images/medoffice.jpg"
// import MailIcon from '@heroicons/react/solid/MailIcon';
// import PhoneIcon from '@heroicons/react/solid/PhoneIcon';

const PROVIDER = gql`
  query GetProvider($id: UUID4!) {
    provider(id: $id) {
      id
      nameWithAppellation
      avatar(size: "medium") {
        base64
        src
        srcSet
      }
    }
  }
`;

interface Data {
  provider: ProviderModel;
}

interface ProviderModel {
  id: string;
  nameWithAppellation: string;
  avatar: ImageObject;
}

interface Variables {
  id: string;
}

/**
 * ProfileHeader.
 */

interface ProfileHeaderProps {
  provider: ProviderModel;
  setActiveModal(modal: ActiveModal): void;
}

const ProfileHeader: FC<ProfileHeaderProps> = (props) => {
  const { provider, setActiveModal } = props;

  return (
    <div>
      <div>
        <img
          className="h-32 w-full object-cover lg:h-48"
          src={medofficeSrc}
          alt="Medical Office"
        />
      </div>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
            <div className="relative h-24 lg:h-32 rounded-full overflow-hidden border-4 border-white">
              <Avatar size="md" image={provider.avatar} />
              <label
                htmlFor="user-photo"
                className="absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100"
              >
                <span>Change</span>
                <span className="sr-only"> user photo</span>
                <button
                  type="button"
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                  onClick={() => setActiveModal("CHANGE_PICTURE")}
                />
              </label>
            </div>
          </div>
          <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
              <h1 className="text-2xl font-bold text-gray-900 truncate">
                {provider.nameWithAppellation}
              </h1>
            </div>
            {/* <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <MailIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" />
                <span>Message</span>
              </button>
              <button
                type="button"
                className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <PhoneIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" />
                <span>Call</span>
              </button>
            </div> */}
          </div>
        </div>
        <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
          <h1 className="text-2xl font-bold text-gray-900 truncate">
            {provider.nameWithAppellation}
          </h1>
        </div>
      </div>
    </div>
  );
};

/**
 * ProviderShowScreen.
 */

interface ProviderShowScreenProps {
  refetchProviders(): void;
}

type ActiveModal = "CHANGE_PICTURE";

export const ProviderShowScreen: FC<ProviderShowScreenProps> = (props) => {
  const { refetchProviders } = props;

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => setActiveModal(null), []);

  const history = useHistory();
  const match = useRouteMatch<{ providerId: string }>();
  const { providerId } = match.params;

  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    PROVIDER,
    {
      variables: { id: providerId },
    }
  );

  const onRemove = useCallback(() => {
    refetchProviders();
    return history.push("/o/settings/providers");
  }, [refetchProviders, history]);

  return (
    <>
      <ProfilePictureModal
        providerId={providerId}
        isOpen={activeModal === "CHANGE_PICTURE"}
        onClose={closeModal}
        onSuccess={refetch}
      />
      <main
        className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last"
        tabIndex={0}
      >
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.provider ? (
          <p>Failed to load.</p>
        ) : (
          <article>
            {/* <!-- Profile header --> */}
            <ProfileHeader
              provider={data.provider}
              setActiveModal={setActiveModal}
            />

            <div className="px-4 py-3 sm:p-0">
              <NavLinkTabs>
                <NavLinkTab to={`${match.url}/profile`}>Profile</NavLinkTab>
                <NavLinkTab to={`${match.url}/departments`}>
                  Departments
                </NavLinkTab>
                <NavLinkTab to={`${match.url}/locations`}>Locations</NavLinkTab>
                <NavLinkTab to={`${match.url}/settings`}>Settings</NavLinkTab>
              </NavLinkTabs>
            </div>

            <Switch>
              <Route path={`${match.path}/profile`}>
                <ProviderProfile providerId={providerId} />
              </Route>
              <Route path={`${match.path}/departments`}>
                <ProviderDepartments providerId={providerId} />
              </Route>
              <Route path={`${match.path}/locations`}>
                <ProviderLocations providerId={providerId} />
              </Route>
              <Route path={`${match.path}/settings`}>
                <ProviderSettings providerId={providerId} onRemove={onRemove} />
              </Route>
              <Route path="*">
                <Redirect to={`${match.url}/profile`} />
              </Route>
            </Switch>
          </article>
        )}
      </main>
    </>
  );
};
