import { FC, useCallback, useState } from "react";
import { gql } from "graphql.macro";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useRouteMatch } from "react-router-dom";
import {
  Spinner,
  FAIcon,
  DropdownButton,
  DropdownItemLink,
  DropdownItemButton,
  JsonDebugger,
} from "@preferral/ui";
import { ddd } from "@preferral/common";
import { mmDdYyyy, hMmA, distanceInWords } from "lib/dateFormatters";
import { ScreenTitle } from "context/ScreenTitle";
import { QUESTIONNAIRE_FRAGMENT } from "components/Questionnaire";
import { DetailItem, DetailList } from "components/DetailList";
import { AppointmentRequestModel } from "../model";
import { TaskPanel } from "../components/TaskPanel";
import { AppointmentsPanel } from "../components/AppointmentsPanel";
import { ActivityPanel } from "../components/ActivityPanel";
import { PatientPanel } from "../components/PatientPanel";
import { InsurancePanel } from "../components/InsurancePanel";
import { CircleIconHeader } from "components/CircleIconHeader";
import { Files } from "../components/Files";
import { ReasonForDelayModal } from "../components/ReasonForDelayModal";
import { RequestedProviderPanel } from "../components/RequestedProviderPanel";
import { RequestedLocationPanel } from "../components/RequestedLocationPanel";
import { ExhaustRequestModal } from "../components/ExhaustRequestModal";
import { RejectRequestModal } from "../components/RejectRequestModal";
import { AssignTeammateModal } from "../components/AssignTeammateModal";
import { StatusBadge } from "../components/StatusBadge";
import { ChangeSpecialtyModal } from "../components/ChangeSpecialtyModal";
import { AppointmentRequestProvider } from "../AppointmentRequestContext";
import {
  APPOINTMENT_REQUEST_EVENT_FRAGMENT,
  APPOINTMENT_REQUEST_FRAGMENT,
  LOCATION_FRAGMENT,
  NPPES_LOCATION_FRAGMENT,
  NPPES_PROVIDER_FRAGMENT,
  PATIENT_MEMBER_FRAGMENT,
  PROVIDER_FRAGMENT,
  UNLISTED_LOCATION_FRAGMENT,
  UNLISTED_PROVIDER_FRAGMENT,
} from "../fragments";
import { QuestionnairePanel } from "../components/QuestionnairePanel";
import { ReferredBy } from "../components/ReferredBy";
import { analytics } from "lib/analytics";
import toast from "react-hot-toast";

const APPOINTMENT_REQUEST = gql`
  query GetReceivedAppointmentRequest($id: UUID4!) {
    appointmentRequest(id: $id) {
      ...AppointmentRequestFields
      inboundReferral {
        id
        form {
          ...QuestionnaireFields
        }
        referringProvider {
          __typename
          ... on Provider {
            ...ProviderFields
          }
          ... on NppesProvider {
            ...NppesProviderFields
          }
          ... on UnlistedProvider {
            ...UnlistedProviderFields
          }
        }
        referringLocation {
          __typename
          ... on Location {
            ...LocationFields
          }
          ... on NppesLocation {
            ...NppesLocationFields
          }
          ... on UnlistedLocation {
            ...UnlistedLocationFields
          }
        }
      }
      patientMember {
        ...PatientMemberFields
      }
      appointmentRequestEvents {
        ...AppointmentRequestEventFields
      }
    }
  }
  ${APPOINTMENT_REQUEST_FRAGMENT}
  ${APPOINTMENT_REQUEST_EVENT_FRAGMENT}
  ${LOCATION_FRAGMENT}
  ${NPPES_LOCATION_FRAGMENT}
  ${UNLISTED_LOCATION_FRAGMENT}
  ${PATIENT_MEMBER_FRAGMENT}
  ${PROVIDER_FRAGMENT}
  ${NPPES_PROVIDER_FRAGMENT}
  ${UNLISTED_PROVIDER_FRAGMENT}
  ${QUESTIONNAIRE_FRAGMENT}
`;

interface Data {
  appointmentRequest: AppointmentRequestModel;
}

interface Variables {
  id: string;
}

const DOWNLOAD_PDF = gql`
  mutation AppointmentRequestDownloadPdf($id: UUID4!) {
    appointmentRequestDownloadPdf(id: $id) {
      url
    }
  }
`;

interface MutationData {
  appointmentRequestDownloadPdf: {
    url: string;
  }
}

interface MutationVariables {
  id: string | undefined;
}

type ActiveModal =
  | "ASSIGN_TEAMMATE"
  | "CHANGE_SPECIALTY"
  | "REASON_FOR_DELAY"
  | "EXHAUST_REQUEST"
  | "REJECT_REQUEST";

interface AppointmentRequestShowScreenProps { }

export const ReceivedRequestShowScreen: FC<AppointmentRequestShowScreenProps> =
  () => {
    const {
      params: { appointmentRequestId },
    } = useRouteMatch<{ appointmentRequestId: string }>();

    const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

    const closeModal = useCallback(() => setActiveModal(null), []);

    const { data, loading, error, refetch } = useQuery<Data, Variables>(
      APPOINTMENT_REQUEST,
      { variables: { id: appointmentRequestId } }
    );

    const appointmentRequest = data?.appointmentRequest;
    const inboundReferral = appointmentRequest?.inboundReferral;

    const [downloadPdfMut, { loading: downloadingPDF }] = useMutation<MutationData, MutationVariables>(DOWNLOAD_PDF);

    const downloadPdf = useCallback(
      async () => {
        const variables = { id: data?.appointmentRequest.id };

        analytics.track("Download PDF", {
          direction: "received",
          status: data?.appointmentRequest.status,
        });

        try {
          const { data: downloadData } = await downloadPdfMut({ variables });

          if (downloadData?.appointmentRequestDownloadPdf.url) {
            // it worked...
            window.open(downloadData.appointmentRequestDownloadPdf.url);
          }
        } catch (e) {
          console.error(e);
          toast.error("Something went wrong.");
        }
      }, [data, downloadPdfMut]);

    return (
      <>
        <ScreenTitle title={["Referrals", "View Referral"]} />
        <div className="_AppointmentRequestShowScreen lg:container mx-auto pb-12 text-left">
          {loading ? (
            <div className="p-6 text-center">
              <Spinner />
            </div>
          ) : error || !appointmentRequest ? (
            <p>Failed to load</p>
          ) : (
            <AppointmentRequestProvider
              refetch={refetch}
              appointmentRequest={appointmentRequest}
              patientMember={appointmentRequest.patientMember}
            >
              <AssignTeammateModal
                isOpen={activeModal === "ASSIGN_TEAMMATE"}
                onClose={closeModal}
              />
              <ChangeSpecialtyModal
                isOpen={activeModal === "CHANGE_SPECIALTY"}
                onClose={closeModal}
              />
              <ReasonForDelayModal
                isOpen={activeModal === "REASON_FOR_DELAY"}
                onClose={closeModal}
              />
              <ExhaustRequestModal
                isOpen={activeModal === "EXHAUST_REQUEST"}
                onClose={closeModal}
              />
              <RejectRequestModal
                isOpen={activeModal === "REJECT_REQUEST"}
                onClose={closeModal}
              />
              <div className="pt-4 px-8">
                <Link
                  to={`/o/referrals/inboxes/${appointmentRequest.inboxId}/requests`}
                  className="border-b border-blue-500 border-dashed font-medium text-blue-700"
                >
                  <span className="mr-2 text-sm">
                    <FAIcon icon="chevron-left" />
                  </span>
                  Back to Inbox
                </Link>
              </div>
              <div className="pt-4 px-4 lg:grid lg:grid-cols-5 lg:gap-4">
                <div className="col-span-3">
                  <div className="bg-white rounded-xl shadow-xl border p-4">
                    <div className="flex items-center">
                      <div className="flex-grow">
                        <CircleIconHeader icon={["far", "address-card"]}>
                          Referral
                        </CircleIconHeader>
                      </div>
                      <div className="mr-2">
                        <StatusBadge />
                      </div>
                      <div>
                        <DropdownButton label="Actions">
                          <DropdownItemLink to={`/o/referral/new?patientId=${appointmentRequest.patientMember.id}`}>
                            Start New Referral
                          </DropdownItemLink>
                          <DropdownItemButton
                            onClick={() => setActiveModal("ASSIGN_TEAMMATE")}
                          >
                            Assign Teammate
                          </DropdownItemButton>
                          <DropdownItemButton
                            onClick={() => setActiveModal("CHANGE_SPECIALTY")}
                          >
                            Change Specialty
                          </DropdownItemButton>
                          <DropdownItemButton
                            onClick={() => setActiveModal("REASON_FOR_DELAY")}
                          >
                            Add Reason for Delay
                          </DropdownItemButton>
                          <DropdownItemButton
                            color="red"
                            onClick={() => setActiveModal("EXHAUST_REQUEST")}
                          >
                            Exhaust Request
                          </DropdownItemButton>
                          <DropdownItemButton
                            color="red"
                            onClick={() => setActiveModal("REJECT_REQUEST")}
                          >
                            Reject Request
                          </DropdownItemButton>
                          <DropdownItemButton
                            onClick={() => downloadPdf()}
                            disabled={downloadingPDF}
                          >
                            {
                              downloadingPDF ? (
                                <>
                                  <div className="mr-1.5 h-5 w-5 inline">
                                    <Spinner />
                                  </div>
                                  <span>Downloading PDF...</span>
                                </>
                              ) : (
                                <span>Download PDF</span>
                              )
                            }
                          </DropdownItemButton>
                        </DropdownButton>
                      </div>
                    </div>
                    <div id="_RequestInfo" className="mt-6 px-6 py-3">
                      <DetailList>
                        <DetailItem label="Assigned To">
                          {appointmentRequest!.assignedReceivingUser ? (
                            appointmentRequest?.assignedReceivingUser.name
                          ) : (
                            <span className="text-cool-gray-500 italic">
                              Unassigned
                            </span>
                          )}
                        </DetailItem>
                        <DetailItem label="Received">
                          {ddd(appointmentRequest!.insertedAt)}{" "}
                          {mmDdYyyy(appointmentRequest!.insertedAt)} at{" "}
                          {hMmA(appointmentRequest!.insertedAt)}{" "}
                          <span className="text-cool-gray-400">
                            ({distanceInWords(appointmentRequest!.insertedAt)}{" "}
                            ago)
                          </span>
                        </DetailItem>
                        <DetailItem label="Specialty">
                          {appointmentRequest!.requestedSpecialty?.name || "-"}
                        </DetailItem>
                        {appointmentRequest?.reasonForDelay ? (
                          <DetailItem label="Reason for Delay">
                            {appointmentRequest.reasonForDelay}
                          </DetailItem>
                        ) : null}
                        {appointmentRequest?.rejectedAt ? (
                          <DetailItem label="Rejected At">
                            {mmDdYyyy(appointmentRequest.rejectedAt)}
                            {" at "}
                            {hMmA(appointmentRequest.rejectedAt)}
                          </DetailItem>
                        ) : null}
                        {appointmentRequest?.rejectionReason ? (
                          <DetailItem label="Rejection Reason">
                            {appointmentRequest.rejectionReason}
                          </DetailItem>
                        ) : null}
                      </DetailList>
                    </div>
                    <div className="pt-4 grid grid-cols-2 gap-2 divide-x">
                      <div className="px-4 border-r">
                        <div id="_PatientInfo">
                          <PatientPanel />
                        </div>

                        <div id="_InsuranceInfo">
                          <InsurancePanel />
                        </div>
                      </div>

                      <div className="px-4 border-l">
                        <div id="_RequestedProvider">
                          <RequestedProviderPanel />
                        </div>
                        <div id="_RequestedLocation" className="pt-4">
                          <RequestedLocationPanel />
                        </div>
                      </div>
                    </div>

                    <div className="_FilesSection pt-4">
                      <Files />
                    </div>

                    <div id="pt-4 _RequestObjectInfo">
                      {inboundReferral ? (
                        <ReferredBy
                          referringProvider={inboundReferral.referringProvider}
                          referringLocation={inboundReferral.referringLocation}
                        />
                      ) : null}

                      <QuestionnairePanel questionnaire={inboundReferral?.form} />
                    </div>

                    <div className="pt-8">
                      <JsonDebugger data={data} />
                    </div>
                  </div>
                </div>

                <div className="col-span-2">
                  <div className="bg-white rounded-xl shadow-xl border p-4">
                    <CircleIconHeader icon="calendar-alt">
                      Scheduling
                    </CircleIconHeader>
                    <div id="_AppointmentsPanelContainer">
                      <AppointmentsPanel />
                    </div>
                    <div id="_TaskPanelContainer px-4">
                      <TaskPanel />
                    </div>
                  </div>

                  <div className="mt-4 pt-4 bg-white rounded-xl shadow-xl border">
                    <CircleIconHeader icon="clipboard-list">
                      Activity
                    </CircleIconHeader>

                    <div id="_ActivityPanelContainer" className="pt-8">
                      <ActivityPanel />
                    </div>
                  </div>
                </div>
              </div>
            </AppointmentRequestProvider>
          )}
        </div>
      </>
    );
  };
