import { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useRouteMatch, useHistory } from "react-router-dom";
import {
  QuestionnaireModel,
  QUESTIONNAIRE_FRAGMENT,
  questionnaireForServer,
  mergeAnswers,
  QuestionnaireFields,
  QuestionnaireFieldset,
  templateToFields
} from "components/Questionnaire";
import { defaultReferralForm } from 'components/QuestionnaireTemplateEditor';
import { Spinner, Button, FAIcon, FadeUpIn } from "@preferral/ui";
import { Formik, FormikHelpers } from "formik";
import { ScreenTitle } from "context/ScreenTitle";
// import { ReferralBrief } from "./ReferralBrief";
import { CircleIconHeader } from "components/CircleIconHeader";
import { ReferralBrief } from "./ReferralBrief";
import { ReactComponent as PaperClip } from "images/paperclip.svg";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { FakeEligibilityNotification } from "components/FakeEligibilityNotification";
import { analytics } from "lib/analytics";

const REFERRAL_FORM = gql`
  query FetchReferralForm($id: UUID4!) {
    platformReferral(id: $id) {
      id
      referringPatientMemberId
      specialty {
        id
        name
        referralForm {
          ...QuestionnaireFields
        }
      }
      referringProvider {
        id
        nameWithAppellation
      }
      form {
        ...QuestionnaireFields
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

interface Data {
  platformReferral: {
    id: string;
    form?: QuestionnaireModel;
    referringPatientMemberId: string;
    referringProvider: {
      id: string;
      nameWithAppellation: string;
    };
    specialty: {
      id: string;
      name: string;
      referralForm: null | QuestionnaireModel;
    };
  };
}

interface Variables {
  id: string;
}

const SUBMIT_QUESTIONNAIRE = gql`
  mutation UpdatePlatformReferralForm($id: UUID4!, $input: ReferralFormInput!) {
    updateReferralForm(id: $id, input: $input) {
      errors {
        key
        message
      }
      referral {
        id
        form {
          ...QuestionnaireFields
        }
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

interface MutationData {
  updateReferralForm: {
    errors?: InputError[];
    referral?: {
      id: string;
      form: QuestionnaireModel;
    };
  };
}

interface MutationVariables {
  id: string;
  input: {
    form: QuestionnaireModel;
  };
}

interface RouteParams {
  referralId: string;
}

interface FormValues {
  form: QuestionnaireFieldset;
}

interface QuestionsStepProps { }

export const QuestionsStep: FC<QuestionsStepProps> = () => {
  const match = useRouteMatch<RouteParams>();
  const { referralId } = match.params;

  const history = useHistory();

  const { data, loading, error } = useQuery<Data, Variables>(REFERRAL_FORM, {
    variables: { id: referralId },
  });

  const questionnaireTemplate = data?.platformReferral.form || data?.platformReferral.specialty.referralForm || defaultReferralForm;

  const [submitQuestionnaire] = useMutation<MutationData, MutationVariables>(
    SUBMIT_QUESTIONNAIRE
  );

  const onSuccess = useCallback(
    (referralId: string) => {
      return history.push(`/o/referral/${referralId}/results`);
    },
    [history]
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      const questionnaire = questionnaireForServer(
        mergeAnswers(questionnaireTemplate, values.form)
      );

      const input = {
        form: questionnaire,
      };

      try {
        const { data } = await submitQuestionnaire({
          variables: { id: referralId, input },
        });

        if (data?.updateReferralForm.errors) {
          setStatus({ errors: data.updateReferralForm.errors });
        } else if (data?.updateReferralForm.referral) {
          // it worked...
          analytics.track('Submitted Questions Page', {
            answered_questionnaire: Object.values(values.form).some(Boolean),
          });
          const { id } = data.updateReferralForm.referral;
          return onSuccess(id);
        }
      } catch (e) {
        console.error(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
      } finally {
        setSubmitting(false);
      }
    },
    [questionnaireTemplate, onSuccess, referralId, submitQuestionnaire]
  );

  const { hasFeature } = useFeatureFlags();

  return (
    <>
      <ScreenTitle title={["New Referral", "Questions"]} />
      <div className="_QuestionsStep container mx-auto mt-4 mb-8 text-left">
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.platformReferral ? (
          <p>Failed to load.</p>
        ) : (
          <FadeUpIn show>
            <div className="flex mt-4 px-2 sm:px-4">
              <div className="flex-1 max-w-sm mx-2">
                <div className="rounded-2xl bg-white shadow-md p-4 sticky top-4">
                  <div className="relative">
                    <div className="absolute right-0 top-0">
                      <PaperClip className="-translate-y-6 h-12 transform translate-x-1 w-12" />
                    </div>
                  </div>
                  <ReferralBrief referralId={referralId} />
                </div>
              </div>

              <div className="flex-1 mx-2 pb-8">
                <div className="bg-white px-2 pt-6 rounded-xl shadow-xl">
                  <div className="max-w-2xl px-2 pb-8 mx-auto">
                    <CircleIconHeader icon="clipboard-list">
                      {data.platformReferral.specialty.name} Questions
                    </CircleIconHeader>

                    <Formik
                      initialValues={{
                        form: templateToFields(questionnaireTemplate),
                      }}
                      onSubmit={onSubmit}
                    >
                      {({ handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                          <QuestionnaireFields name="form" template={questionnaireTemplate} />
                          <div className="max-w-3xl mx-auto mt-8 border-t border-cool-gray-200 pt-5">
                            <div className="flex justify-end">
                              <span className="ml-3 inline-flex rounded-md shadow-sm">
                                <Button
                                  type="submit"
                                  color="blue"
                                  disabled={isSubmitting}
                                >
                                  Next
                                  <span className="ml-2">
                                    <FAIcon icon="arrow-right" />
                                  </span>
                                </Button>
                              </span>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </FadeUpIn>
        )}
      </div>
      {hasFeature("fake_eligibility_notification") ? (
        <FakeEligibilityNotification />
      ) : null}
    </>
  );
};
