import { FC } from "react";
import { gql } from "graphql.macro";
import { useQuery } from "@apollo/client";
import { Button, FAIcon, HorizontalField } from "@preferral/ui";
import { Spinner } from "components/Spinner";

const HEALTH_PLAN = gql`
  query HealthPlan($id: UUID4!) {
    healthPlan(id: $id) {
      id
      name
      carrier {
        id
        name
      }
    }
  }
`;

interface SelectedHealthPlanProps {
  healthPlanId: string;
  onClear?(): void;
}

export const SelectedHealthPlan: FC<SelectedHealthPlanProps> = props => {
  const { healthPlanId, onClear } = props;

  const { data, loading, error } = useQuery(HEALTH_PLAN, {
    variables: { id: healthPlanId },
  });

  return (
    <div className="_SelectedHealthPlan">
      <HorizontalField label="Health Plan">
        {loading ? (
          <Spinner />
        ) : error || !(data && data.healthPlan) ? (
          <div>Unable to load health plan</div>
        ) : (
          <div className="flex border bg-gray-100 border-gray-300 text-gray-700 rounded px-5 py-2 font-semibold">
            <p className="flex-grow">{data.healthPlan.carrier.name} - {data.healthPlan.name}</p>
            {onClear ? (
              <div>
                <Button
                  type="button"
                  kind="tertiary"
                  size="xs"
                  color="red"
                  className="border"
                  onClick={onClear}
                >
                  <FAIcon icon="times" />
                </Button>
              </div>
            ) : null}
          </div>
        )}
      </HorizontalField>
    </div>
  );
};
