import { FC, useCallback, useEffect, useState } from "react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FormikHelpers, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { HorizontalSelectField, StandardOption, Spinner, HorizontalTextField, PillTabs, PillTab } from "@preferral/ui";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import {
  ProviderModel,
  ProviderOptionGqlFields,
  ProviderSelectOption,
} from "components/ProviderOption";
import { Link } from "react-router-dom";
import { analytics } from "lib/analytics";
// import { OrganizationProviderSelect } from "components/ProviderSelect";

// Ref: https://account.eligible.com/docs/healthcare-industry#using-cpts-and-service-type-codes
const serviceTypeOptions: StandardOption[] = [
  {
    value: "30",
    label: "Health Benefit Plan Coverage",
  },
  {
    value: "4",
    label: "Radiology - Diagnostic X-Ray",
  },
  {
    value: "62",
    label: "Radiology - MRI Scan",
  },
  {
    value: "98",
    label: "TeleHealth Services (Professional Visit)",
  },
  {
    value: "3",
    label: "TeleHealth Services (Consultation)",
  },
  {
    value: "2",
    label: "Surgical",
  },
  {
    value: "A7",
    label: "Psychiatric - Inpatient",
  },
  {
    value: "A8",
    label: "Psychiatric - Outpatient",
  },
  {
    value: "A9",
    label: "Rehabilitation",
  },
  {
    value: "AI",
    label: "Substance Abuse",
  },
  {
    value: "PT",
    label: "Physical Therapy",
  },
];

const DEPARTMENTS = gql`
  query NewEligibilityLookupDepartmentData {
    me {
      id
      departments {
        id
        name
      }
    }
  }
`;

interface DepartmentData {
  me: {
    id: string;
    departments: {
      id: string;
      name: string;
    }[];
  };
}

const PROVIDERS = gql`
  query Providers($departmentId: UUID4!) {
    departmentProviders(departmentId: $departmentId, first: 100) {
      cursor
      endOfList
      items {
        ${ProviderOptionGqlFields}
      }
    }
  }
`;

interface ProvidersData {
  departmentProviders: Paginated<ProviderModel>;
}

interface ProvidersVariables {
  departmentId: string;
}

const LOOKUP_ELIGIBILITY = gql`
  mutation LookupEligibility(
    $coverageId: UUID4!
    $providerId: UUID4!
    $input: EligibilityLookupInput
  ) {
    lookupEligibility(id: $coverageId, providerId: $providerId, input: $input) {
      errors {
        key
        message
      }
      eligibilityLookup {
        id
        loading
        error
        data
        insertedAt
        updatedAt
      }
    }
  }
`;

interface MutationData {
  lookupEligibility: {
    errors?: InputError[];
    eligibilityLookup?: {
      id: string;
      loading: boolean;
      error: string;
      data: JSONObject;
      insertedAt: string;
      updatedAt: string;
    };
  };
}

interface MutationVariables {
  coverageId: string;
  providerId: string;
  input: {
    serviceTypeParam?: string;
    cptCodeParam?: string;
  };
}

interface FormValues {
  departmentId: string;
  providerId: string;
  serviceTypeParam: string;
  cptCodeParam: string;
}

const initialValues: FormValues = {
  departmentId: "",
  providerId: "",
  serviceTypeParam: "30",
  cptCodeParam: ""
};

const serviceTypeValidationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    providerId: Yup.string().required("Required"),
    serviceTypeParam: Yup.string().required("Required"),
  })
  .required();

const cptCodeValidationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    providerId: Yup.string().required("Required"),
    cptCodeParam: Yup.string().required("Required"),
  })
  .required();

const validationSchemas = {
  SERVICE_TYPE: serviceTypeValidationSchema,
  CPT_CODE: cptCodeValidationSchema
}
interface NewEligibilityLookupFormProps {
  refetchQueries: any;
  onCancel(): void;
  onSuccess(): void;
  insuranceCoverageId: string;
};

export const NewEligibilityLookupForm: FC<NewEligibilityLookupFormProps> = props => {
  const {
    refetchQueries,
    insuranceCoverageId,
    onCancel,
    onSuccess,
  } = props;

  const [whichServiceParam, setWhichServiceParam] = useState<"SERVICE_TYPE" | "CPT_CODE">("SERVICE_TYPE")

  const [lookupEligibility] = useMutation<MutationData, MutationVariables>(
    LOOKUP_ELIGIBILITY
  );

  const departmentsQuery = useQuery<DepartmentData>(DEPARTMENTS);

  const [fetchProviders, providersData] = useLazyQuery<
    ProvidersData,
    ProvidersVariables
  >(PROVIDERS);

  const departmentOptions =
    departmentsQuery.data?.me.departments.map((d) => ({
      value: d.id,
      label: d.name,
    })) || [];

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      try {
        const { data } = await lookupEligibility({
          variables: {
            coverageId: insuranceCoverageId,
            providerId: values.providerId,
            input: {
              serviceTypeParam: values.serviceTypeParam,
              cptCodeParam: whichServiceParam === "CPT_CODE" ? values.cptCodeParam : undefined
            },
          },
          refetchQueries
        });

        if (data?.lookupEligibility.errors) {
          setStatus({ errors: data.lookupEligibility.errors });
        } else if (data?.lookupEligibility.eligibilityLookup) {
          // it worked...
          toast.success("Submitted coverage inquiry.");
          analytics.track("Eligibility Lookup Submitted");
          onSuccess();
        }
      } catch (e) {
        console.error(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
      } finally {
        setSubmitting(false);
      }
    },
    [refetchQueries, insuranceCoverageId, lookupEligibility, onSuccess, whichServiceParam]
  );

  const formikBag = useFormik<FormValues>({
    initialValues,
    validationSchema: validationSchemas[whichServiceParam],
    onSubmit,
  });

  const { handleSubmit, status, isSubmitting, values } = formikBag;

  // When departmentId changes, (re)fetch providers
  useEffect(() => {
    if (values.departmentId && !providersData.loading) {
      fetchProviders({
        variables: { departmentId: values.departmentId },
      });
    }
  }, [values.departmentId]);

  const departmentSelectVisible = !(
    departmentsQuery.data && departmentOptions.length === 1
  );

  return (
    <div className="NewEligibilityLookupForm bg-white border border-gray-300 shadow rounded p-4">
      <h3 className="mb-4 mt-2 mx-4 text-gray-700 text-xl">
        New Eligibility Lookup
      </h3>
      <FormikProvider value={formikBag}>
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          {departmentsQuery.loading ? (
            <div className="p-12 text-center">
              <Spinner />
            </div>
          ) : departmentsQuery.error ? (
            <p>Failed to load</p>
          ) : (
            <>
              <div className="flex justify-around">

                <PillTabs
                  activeTab={whichServiceParam}
                  onChange={setWhichServiceParam as (tab: string) => void}
                >
                  <PillTab tab="SERVICE_TYPE">Use Service Type</PillTab>
                  <PillTab tab="CPT_CODE">Use CPT Code</PillTab>
                </PillTabs>
              </div>
              <div className="mt-3">
                {
                  whichServiceParam === "SERVICE_TYPE" ? (
                    <HorizontalSelectField
                      name="serviceTypeParam"
                      label="Service Type"
                      options={serviceTypeOptions}
                    />
                  ) : (
                    <HorizontalTextField
                      name="cptCodeParam"
                      label="CPT Code"
                    />
                  )
                }
              </div>
              <div
                className={`mt-3 ${departmentSelectVisible ? "" : "hidden"
                  }`}
              >
                <HorizontalSelectField
                  name="departmentId"
                  label="Department"
                  options={departmentOptions}
                  isLoading={departmentsQuery.loading}
                  autoSelect
                />
              </div>
              <div className="mt-3">
                <HorizontalSelectField
                  name="providerId"
                  label="Provider"
                  options={
                    providersData.data?.departmentProviders.items ||
                    []
                  }
                  disabled={departmentsQuery.loading}
                  isLoading={providersData.loading}
                  getOptionLabel={(o: ProviderModel) =>
                    o.nameWithAppellation
                  }
                  getOptionValue={(o: ProviderModel) => o.id}
                  components={{ Option: ProviderSelectOption }}
                  autoSelect={!!values.departmentId}
                />
                {!providersData.loading &&
                  !providersData.data?.departmentProviders.items
                    ?.length ? (
                  <div className="font-semibold py-2 text-cool-gray-600 text-xs">
                    Need to add referring provider options?:{" "}
                    <Link
                      to="/o/settings/providers"
                      className="hover:text-blue-700 link text-blue-600 underline"
                    >
                      Go to settings
                    </Link>
                  </div>
                ) : null}
              </div>
            </>
          )}

          {/* <div className="mt-3">
              <HorizontalSelectField
                name="providerId"
                label="Referring Provider"
                options={
                  providersData.data?.departmentProviders.items ||
                  []
                }
                disabled={departmentsQuery.loading}
                isLoading={providersData.loading}
                getOptionLabel={(o: ProviderModel) =>
                  o.nameWithAppellation
                }
                getOptionValue={(o: ProviderModel) => o.id}
                components={{ Option: ProviderSelectOption }}
                autoSelect={!!values.departmentId}
              />
              {!providersData.loading &&
                !providersData.data?.departmentProviders.items
                  ?.length ? (
                <div className="font-semibold py-2 text-cool-gray-600 text-xs">
                  Need to add referring provider options?:{" "}
                  <Link
                    to="/o/settings/providers"
                    className="hover:text-blue-700 link text-blue-600 underline"
                  >
                    Go to settings
                  </Link>
                </div>
              ) : null}
            </div> */}



          <div className="p-2 flex items-center justify-center">
            <button
              type="button"
              className="btn"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-2 btn btn-blue"
              disabled={isSubmitting}
            >
              Lookup Coverage
            </button>
          </div>
        </form>
      </FormikProvider>
    </div>
  );
};
