import { FC } from "react";
import { Response } from "components/Questionnaire";
import { QuestionnaireModel } from '../../model';


interface QuestionnairePanelProps {
  questionnaire?: QuestionnaireModel;
};

export const QuestionnairePanel: FC<QuestionnairePanelProps> = props => {
  const { questionnaire } = props;

  return (
    <div className="_QuestionnairePanel">
      <h4 className="text-xl py-4 font-semibold">Questions</h4>

      <div id="_QuestionnaireResponseContainer">
        {questionnaire ? (
          <Response questionnaire={questionnaire} />
        ) : (
          <div className="p-4 text-center text-cool-gray-600 bg-cool-gray-50">
            <p>No questionnaire response</p>
          </div>
        )}
      </div>
    </div>
  );
};
