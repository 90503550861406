import { gql, useQuery } from "@apollo/client";

const DEPARTMENTS_AND_SPECIALTIES = gql`
  query NewReferralData {
    me {
      id
      departments {
        id
        name
      }
    }
    specialties(filter: { curated: true }) {
      id
      name
    }
  }
`;

interface DepartmentAndSpecialtiesData {
  me: {
    id: string;
    departments: {
      id: string;
      name: string;
    }[];
  };
  specialties: {
    id: string;
    name: string;
  }[];
}

export function useDepartmentsAndSpecialties() {
  const deptAndSpecialties = useQuery<DepartmentAndSpecialtiesData>(
    DEPARTMENTS_AND_SPECIALTIES
  );

  const referringDepartmentOptions =
    deptAndSpecialties.data?.me.departments.map(d => ({
      value: d.id,
      label: d.name,
    })) || [];

  const specialtyOptions =
    deptAndSpecialties.data?.specialties.map(s => ({
      value: s.id,
      label: s.name,
    })) || [];

  const showDepartmentSelect = !(
    deptAndSpecialties.data && referringDepartmentOptions.length === 1
  );

  return {
    departmentAndSpecialtiesLoading: deptAndSpecialties.loading,
    departmentAndSpecialtiesError: deptAndSpecialties.error,
    referringDepartmentOptions,
    specialtyOptions,
    showDepartmentSelect,
  };
}
