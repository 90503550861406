import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField, Button } from "@preferral/ui";

const RESET_PASSWORD = gql`
  mutation($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

interface Data {
  requestPasswordReset: string;
}

interface Variables {
  email: string;
}

export const ForgotPasswordForm: FC = () => {
  const [resetPassword] = useMutation<Data, Variables>(RESET_PASSWORD);

  return (
    <>
      <div className="_ForgotPasswordForm bg-white py-8 px-4 shadow-xl sm:rounded-2xl sm:px-10">
        <p className="text-gray-700 text-sm">
          We can help you reset your password using the email address linked to
          your account.
        </p>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email()
              .required("Required"),
          })}
          onSubmit={({ email }, { setStatus, setSubmitting }) => {
            // setStatus({errors: null})
            resetPassword({ variables: { email } }).then((resp) => {
              if (resp?.data?.requestPasswordReset === "ok") {
                window.alert("Password Reset Email Sent!");
              }
              setSubmitting(false);
            });
          }}
        >
          {({ status, isSubmitting, handleSubmit }) => (
            <form className="mt-6" onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <TextField
                name="email"
                type="email"
                label="Email"
                placeholder="you@organization.com"
                autoFocus
              />

              <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    color="blue"
                    className="w-full justify-center"
                  >
                    Reset my password
                  </Button>
                </span>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="mt-4 text-sm">
        <p className="leading-5 max-w mt-1 text-center text-gray-600 text-sm">
          <Link
            to="/auth/sign_in"
            className="inline-block ml-2 text-blue-500 hover:text-blue-700 font-semibold"
          >
            Sign in with your email and password
          </Link>
        </p>
        <p className="leading-5 max-w mt-1 text-center text-gray-600 text-sm">
          Using SSO?{" "}
          <Link
            to="/auth/sso"
            className="inline-block ml-2 text-blue-500 hover:text-blue-700 font-semibold"
          >
            Sign in with SSO
          </Link>
        </p>
      </div>
    </>
  );
};
