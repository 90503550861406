import React, { FC, useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg, CroppedImage, Point, Area } from "./cropImage";
import { Button } from "@preferral/ui";

interface ImageCropperProps {
  src: string;
  onChange(croppedImage: CroppedImage): void;
  onSave(croppedImageBlob: Blob): void;
  isSaving: boolean;
}

export const ImageCropper: FC<ImageCropperProps> = (props) => {
  const { src, onChange, onSave, isSaving } = props;
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  // NB: Status flag for when rendering a crop to a Blob.
  const [isRendering, setIsRendering] = useState(false);

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    if (!croppedAreaPixels) return;
    setIsRendering(true);
    try {
      const croppedImage = await getCroppedImg(
        src,
        croppedAreaPixels,
        rotation
      );
      onChange(croppedImage);
    } catch (e) {
      console.error(e);
    } finally {
      setIsRendering(false);
    }
  }, [src, onChange, croppedAreaPixels, rotation]);

  const saveImage = useCallback(async () => {
    if (!croppedAreaPixels) return;
    setIsRendering(true);

    try {
      const croppedImage = await getCroppedImg(
        src,
        croppedAreaPixels,
        rotation
      );
      return onSave(croppedImage.blob);
    } catch (e) {
      console.error(e);
    } finally {
      setIsRendering(false);
    }
  }, [src, croppedAreaPixels, rotation, onSave]);

  return (
    <div className="ImageCropper">
      <div className="relative h-56 w-full">
        <Cropper
          image={src}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className="py-4 flex items-center justify-end gap-2">
        <Button
          type="button"
          onClick={showCroppedImage}
          isLoading={isRendering}
          disabled={isRendering}
        >
          Update Preview
        </Button>
        <Button
          type="button"
          color="blue"
          onClick={saveImage}
          isLoading={isSaving}
          disabled={isRendering || isSaving}
        >
          Save Image
        </Button>
      </div>
    </div>
  );
};

function isSameCrop(left: Area | null, right: Area | null): boolean {
  return (
    (left === null && right === null) ||
    (left?.x === right?.x &&
      left?.y === right?.y &&
      left?.height === right?.height &&
      left?.width === right?.width)
  );
}
