export function formatGender(
  gender: "M" | "F" | null | undefined
): string | null {
  if (gender === "M") {
    return "Male";
  } else if (gender === "F") {
    return "Female";
  } else {
    return null;
  }
}
