import { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { validPassword } from "lib/validPassword";
import { FAIcon } from "components/FAIcon";
import { Button, TextField } from "@preferral/ui";

const RESET_PASSWORD = gql`
  mutation(
    $id: String!
    $token: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    resetPassword(
      id: $id
      token: $token
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      errors {
        key
        message
      }
      session {
        token
      }
    }
  }
`;

interface Data {
  resetPassword: {
    errors?: InputError[];
    session?: {
      token: string;
    };
  };
}

interface Variables {
  id: string;
  token: string;
  password: string;
  passwordConfirmation: string;
}

interface FormValues {
  password: string;
  passwordConfirmation: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    password: validPassword,
    passwordConfirmation: validPassword,
  })
  .required();

interface PasswordResetFormProps {
  id: string;
  token: string;
  onSignIn(jwt: string): void;
}

export const ResetPasswordForm: FC<PasswordResetFormProps> = props => {
  const { id, token, onSignIn } = props;

  const [resetPassword] = useMutation<Data, Variables>(RESET_PASSWORD);

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikActions;
      setStatus({ errors: null });

      const variables = { id, token, ...values };

      try {
        const { data } = await resetPassword({ variables });

        if (data?.resetPassword.errors) {
          setStatus({ errors: data.resetPassword.errors });
        } else if (data?.resetPassword.session) {
          const { token } = data.resetPassword.session;

          onSignIn(token);
        }
      } catch (e) {
        console.error(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
      } finally {
        setSubmitting(false);
      }
    },
    [resetPassword, id, onSignIn, token]
  );

  return (
    <>
      <div className="_ResetPasswordForm bg-white py-8 px-4 shadow-xl sm:rounded-2xl sm:px-10">
        <Formik<FormValues>
          initialValues={{ password: "", passwordConfirmation: "" }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ status, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <TextField
                name="password"
                placeholder="••••••••••"
                type="password"
                label="New Password"
                icon="lock"
              />

              <div className="mt-3">
                <TextField
                  name="passwordConfirmation"
                  placeholder="••••••••••"
                  type="password"
                  label="Confirm New Password"
                  icon="lock"
                />
              </div>

              <div className="mt-8">
                <span className="block w-full rounded-md shadow-sm">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    color="blue"
                    className="w-full justify-center"
                  >
                    Reset Password
                  </Button>
                </span>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="mt-4 flfex justify-around has-text-weight-bold">
        <Link
          to="/auth/sign_in"
          className="inline-block ml-2 text-blue-500 hover:text-blue-700 font-semibold"
        >
          <span className="mr-2">
            <FAIcon icon="arrow-left" />
          </span>
          Back to Sign In
        </Link>
      </div>
    </>
  );
};
