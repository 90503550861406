import { FC, useCallback } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { AddLocationForm } from "./AddLocationForm";
import { Button, CircleIcon, Spinner } from "@preferral/ui";
import { NoResults } from "components/NoResults";
import XIcon from '@heroicons/react/outline/XIcon';
import toast from "react-hot-toast";

const PROVIDER_LOCATIONS = gql`
  query ProviderLocations($providerId: UUID4!) {
    provider(id: $providerId) {
      id
      locations {
        id
        name
        streetAddress
        streetAddressLine2
        city
        stateAbbreviation
        zip
        geom {
          lat
          lng
        }
        timeZoneName
      }
    }
  }
`;

interface Data {
  provider: {
    id: string;
    locations: LocationModel[];
  };
}

interface LocationModel {
  id: string;
  name: string;
  streetAddress: string;
  streetAddressLine2?: string;
  city: string;
  stateAbbreviation: string;
  zip: string;
}

interface Variables {
  providerId: string;
}

const DELETE_PROVIDER_MAPPING = gql`
  mutation RemoveProviderMapping($providerId: UUID4!, $locationId: UUID4!) {
    deleteProviderMapping(providerId: $providerId, locationId: $locationId) {
      errors {
        key
        message
      }
      provider {
        id
      }
    }
  }
`;

interface MutationData {
  deleteProviderMapping: {
    errors?: InputError[];
    provider?: {
      id: string;
    };
  };
}

interface MutationVariables {
  providerId: string;
  locationId: string;
}

/**
 * ProviderLocationCard.
 */

interface ProviderLocationCardProps {
  providerId: string;
  location: LocationModel;
  onDeleteSuccess(): void;
}

const ProviderLocationCard: FC<ProviderLocationCardProps> = (props) => {
  const { providerId, location, onDeleteSuccess } = props;

  const [deleteMapping, { loading }] = useMutation<
    MutationData,
    MutationVariables
  >(DELETE_PROVIDER_MAPPING);

  const onDelete = useCallback(() => {
    return deleteMapping({
      variables: { providerId, locationId: location.id },
    }).then((resp) => {
      if (resp.data?.deleteProviderMapping.errors) {
        toast.error(resp.data.deleteProviderMapping.errors[0].message);
      } else if (resp.data?.deleteProviderMapping.provider) {
        // it worked...
        toast.success("Location mapping removed");
        return onDeleteSuccess();
      }
    });
  }, [providerId, location.id, deleteMapping, onDeleteSuccess]);

  return (
    <li className="flex items-center gap-3 p-3 mb-2 rounded shadow">
      <div className="flex-shrink-0">
        <CircleIcon icon="map-marker-alt" />
      </div>
      <div className="flex-grow">
        <p className="leading-tight text-cool-gray-900 font-semibold">
          {location.name}
        </p>
        <div className="mt-1 leading-none text-cool-gray-700 text-xs">
          <p>
            {location.streetAddress}
            {location.streetAddressLine2 && `, ${location.streetAddressLine2}`}
          </p>
          <p>
            {location.city}, {location.stateAbbreviation}, {location.zip}
          </p>
        </div>
      </div>
      <div className="flex-shrink-0">
        <Button
          type="button"
          kind="tertiary"
          color="red"
          onClick={onDelete}
          className="border"
          isLoading={loading}
          disabled={loading}
        >
          <XIcon className="h-5 w-5" />
        </Button>
      </div>
    </li>
  );
};

/**
 * ProviderLocations.
 */

interface ProviderLocationsProps {
  providerId: string;
}

export const ProviderLocations: FC<ProviderLocationsProps> = (props) => {
  const { providerId } = props;

  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    PROVIDER_LOCATIONS,
    { variables: { providerId } }
  );

  return (
    <div className="_ProviderLocations p-4 grid grid-cols-2 gap-3">
      <div className="_locations-list">
        <h3 className="p-3 text-xs font-semibold text-cool-gray-500 uppercase tracking-wider">
          Locations
        </h3>
        {loading ? (
          <div className="p-8 text-center">
            <Spinner />
          </div>
        ) : error || !data?.provider ? (
          <p>Failed to load</p>
        ) : data.provider.locations.length > 0 ? (
          <ul>
            {data.provider.locations.map((loc) => (
              <ProviderLocationCard
                key={loc.id}
                providerId={providerId}
                location={loc}
                onDeleteSuccess={refetch}
              />
            ))}
          </ul>
        ) : (
          <NoResults icon="map-marker-alt" text="No locations" />
        )}
      </div>

      <div className="_add-location-section pt-2">
        <div className="p-3 border rounded-md shadow-md">
          <h3 className="p-3 text-xs font-semibold text-cool-gray-500 uppercase tracking-wider">
            Add Location
          </h3>
          <AddLocationForm
            providerId={providerId}
            excludeLocationIds={data?.provider.locations.map((l) => l.id) || []}
            onSuccess={refetch}
          />
        </div>
      </div>
    </div>
  );
};
