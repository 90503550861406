import { FC, useState, InputHTMLAttributes, useEffect } from "react";
import { InputText, Spinner, FAIcon, Popper } from "@preferral/ui";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce/lib";
import { useCombobox } from "downshift";
import { IconProp } from "@fortawesome/fontawesome-svg-core";


const SEARCH = gql`
  query Icd10CodeSearch($text: String!, $resultsCount: Int) {
    icd10CodeSearch(text: $text, resultsCount: $resultsCount){
      items {
        icd10Code
        icd10Title
      }
    }
  }
`;


interface Icd10CodeSearchItem {
  icd10Code: string
  icd10Title: string
}

interface Icd10CodeSearch {
  icd10CodeSearch: {
    items: Icd10CodeSearchItem[];
  }
}

interface Icd10CodeDiagnosisModel {
  text?: string | null
  ICD10_code?: string | null
}

interface Icd10CodeSearchInputProps {
  onItemSelect(newValue: Icd10CodeDiagnosisModel): void,
  icon?: IconProp;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
}

export const Icd10CodeSearchInput: FC<Icd10CodeSearchInputProps> = (props) => {

  const { onItemSelect, inputProps, icon } = props;
  const [inputValue, setInputValue] = useState("")
  const [debouncedInputValue] = useDebounce(inputValue, 600);

  const [searchValue, { data: searchItems, loading: isSearchingValue }] = useLazyQuery<Icd10CodeSearch>(SEARCH)

  useEffect(() => {
    if (debouncedInputValue !== "") {
      searchValue({
        variables: {
          text: debouncedInputValue,
          resultsCount: 5,
        }
      })
    }
  }, [debouncedInputValue]);

  const {
    isOpen,
    getInputProps,
    getComboboxProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    reset
  } = useCombobox<Icd10CodeSearchItem>({
    items: !!searchItems ? searchItems.icd10CodeSearch.items : [],
    onInputValueChange({ isOpen: isSearching, inputValue }) {
      if (isSearching) {
        setInputValue(inputValue || "");
      }
    },
    onSelectedItemChange({ selectedItem }) {
      if (selectedItem) {
        onItemSelect({
          ICD10_code: selectedItem.icd10Code,
          text: selectedItem.icd10Title
        });
      }
    },
    itemToString(item) {
      return item.icd10Title;
    },
    onIsOpenChange({ inputValue, selectedItem, isOpen }) {
      if (!isOpen) {
        // If there is no user selection we must use typed text unless is empty
        if (!selectedItem) {
          onItemSelect({
            text: inputValue !== "" ? inputValue : null,
            ICD10_code: null
          });
          // If user has partially deleted input and closes dropdown we must restart values  
        } else if (selectedItem.icd10Title != inputValue) {
          onItemSelect({
            text: null,
            ICD10_code: null
          });
          // and then Restart the dropdown
          reset()
        }
      }
    }
  });

  return (
    <div className="relative">
      <Popper
        placement={{
          horizontal: "left",
          vertical: "bottom",
        }}
        widthFull
        content={
          isOpen && (
            <div className="relative">
              <div
                className="bg-white border mt-1 py-2 rounded shadow-xl w-full"
                {...getMenuProps()}
              >
                <p className="font-semibold leading-tight select-none text-center text-gray-600 text-xs">
                  Type to search
                </p>
                {searchItems?.icd10CodeSearch.items ? (
                  searchItems?.icd10CodeSearch.items.length === 0 ? (
                    <p className="font-light py-6 text-center text-gray-600 text-lg">
                      <span className="mr-2 text-base text-gray-500">
                        <FAIcon icon="search" />
                      </span>
                      No Results
                    </p>
                  ) : (
                    searchItems?.icd10CodeSearch.items.map((item, index) => (
                      <div
                        key={item.icd10Code}
                        className={`PatientSearchResult cursor-pointer hover:bg-blue-200 px-3 py-1 transition-colors transition-faster ${index === highlightedIndex ? "bg-blue-200" : ""
                          }`}
                        {...getItemProps({
                          item,
                          index,
                        })}
                      >
                        <p className="font-semibold text-gray-800">
                          {item.icd10Title}
                        </p>
                      </div>
                    ))
                  )
                ) : isSearchingValue ? (
                  <div className="text-center p-4">
                    <Spinner />
                  </div>
                ) : null}
              </div>
            </div>
          )
        }
      >
        <div className="_Icd10CodeSearchInput" {...getComboboxProps()}>
          <InputText
            isLoading={isSearchingValue}
            icon={icon}
            inputProps={{
              ...inputProps,
              ...getInputProps(),
              autoComplete: "off",
            }}
          />
        </div>
      </Popper>
    </div>
  )
}