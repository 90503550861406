import { FC } from "react";
import { Redirect, Switch, Route, useRouteMatch } from "react-router-dom";
import { NavTabs, NavTabLink } from "layouts/PortalLayout/NavTabs";
import { ScreenTitle } from "context/ScreenTitle";
import { ReceivedAppointmentRequestsScreen } from "./ReceivedAppointmentRequestsScreen";
import { SentReferralsScreen } from './SentReferralsScreen';

// const EventLogReferralsScreen = () => <h1>Event Log</h1>;

interface ReferralsScreenProps { }

export const ReferralsScreen: FC<ReferralsScreenProps> = () => {
  const match = useRouteMatch();

  return (
    <div className="ReferralsScreen">
      <ScreenTitle title="Referrals" />

      <NavTabs>
        <NavTabLink to={`${match.url}/sent`}>
          <span>Sent</span>
        </NavTabLink>
        <NavTabLink to={`${match.url}/inboxes`}>
          <span>Inboxes</span>
        </NavTabLink>
        {/* <NavTabLink to={`${match.url}/event_log`}>
          <span>Event Log</span>
        </NavTabLink> */}
      </NavTabs>

      <Switch>
        <Route exact path={match.path}>
          <Redirect to={`${match.url}/sent`} />
        </Route>
        <Route path={`${match.path}/sent`}>
          <SentReferralsScreen />
        </Route>
        <Route path={`${match.path}/inboxes`}>
          <ReceivedAppointmentRequestsScreen />
        </Route>
        {/* <Route path={`${match.path}/event_log`}>
          <EventLogReferralsScreen />
        </Route> */}
      </Switch>
    </div>
  );
};
