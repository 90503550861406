import { FC } from "react";
import { InputBox } from "./InputBox";
import { HealthPlanFinder } from "./HealthPlanFinder";
import styled from "styled-components/macro";
import { HealthPlanPickerContext, useHealthPlanPicker } from "./context";
import { HealthPlanValue } from "./model";

const Container = styled.div`
  &:focus-within {
    z-index: 99;
    position: relative;
  }
`;


/**
 * HealthPlanPicker.
 */

export interface HealthPlanPickerProps {
  id?: string;
  disabled?: boolean;
  onChange(value: HealthPlanValue): void;
}

const InnerHealthPlanPicker: FC<HealthPlanPickerProps> = (props) => {
  const { id, disabled = false } = props;

  const { state } = useHealthPlanPicker();

  return (
    <Container
      className="HealthPlanPicker"
      id={id || "insurance-picker-wrapper"}
    >
      <div className="hidden">
        <h4>Insurance</h4>
      </div>

      {/* <HealthPlanPickerContext.Provider value={contextValue}> */}
      <div className="relative">
        {/* This one gets covered... */}
        <InputBox disabled={disabled} />
        {state.isOpen ? (
          <div className="absolute inset-0">
            <HealthPlanFinder />
          </div>
        ) : null}
      </div>
      {/* </HealthPlanPickerContext.Provider> */}
    </Container>
  );
};

export const HealthPlanPicker: FC<HealthPlanPickerProps> = props => {
  const { onChange } = props;

  return (
    <HealthPlanPickerContext onChange={onChange}>
      <InnerHealthPlanPicker {...props} />
    </HealthPlanPickerContext>
  )
}
