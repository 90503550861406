import React, { FC, useCallback } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { AddDepartmentForm } from "./AddDepartmentForm";
import {
  Button,
  CircleIcon,
  DropdownButton,
  DropdownItemButton,
  Spinner,
} from "@preferral/ui";
import { NoResults } from "components/NoResults";
import toast from "react-hot-toast";
import { FAIcon } from "components/FAIcon";

const PROVIDER_DEPARTMENTS = gql`
  query ProviderDepartments($providerId: UUID4!) {
    provider(id: $providerId) {
      id
      departmentMappings {
        id
        acceptingNewPatients
        department {
          id
          name
        }
      }
    }
  }
`;

interface Data {
  provider: {
    id: string;
    departmentMappings: DepartmentProviderModel[];
  };
}

interface DepartmentProviderModel {
  id: string;
  acceptingNewPatients: boolean;
  department: {
    id: string;
    name: string;
  };
}

interface Variables {
  providerId: string;
}

const DELETE_PROVIDER_DEPARTMENT = gql`
  mutation RemoveProviderDepartment(
    $providerId: UUID4!
    $departmentId: UUID4!
  ) {
    deleteDepartmentProvider(
      providerId: $providerId
      departmentId: $departmentId
    ) {
      errors {
        key
        message
      }
      department {
        id
      }
    }
  }
`;

interface DeleteMutationData {
  deleteDepartmentProvider: {
    errors?: InputError[];
    department?: {
      id: string;
    };
  };
}

interface MutationVariables {
  providerId: string;
  departmentId: string;
}

const TOGGLE_ACCEPTING_PATIENTS = gql`
  mutation ToggleAcceptingPatients($providerId: UUID4!, $departmentId: UUID4!) {
    toggleAcceptingNewPatients(
      providerId: $providerId
      departmentId: $departmentId
    ) {
      errors {
        key
        message
      }
      departmentProvider {
        id
      }
    }
  }
`;

interface ToggleMutationData {
  toggleAcceptingNewPatients: {
    errors?: InputError[];
    departmentProvider?: {
      id: string;
    };
  };
}

interface MutationVariables {
  providerId: string;
  departmentId: string;
}

/**
 * ProviderDepartmentCard.
 */

interface ProviderDepartmentCardProps {
  providerId: string;
  departmentProvider: DepartmentProviderModel;
  onUpdate(): void;
}

const ProviderDepartmentCard: FC<ProviderDepartmentCardProps> = (props) => {
  const { providerId, departmentProvider, onUpdate } = props;

  const [deleteMapping, { loading: deleteMappingLoading }] = useMutation<
    DeleteMutationData,
    MutationVariables
  >(DELETE_PROVIDER_DEPARTMENT);

  const [toggleAccepting, { loading: toggleAcceptingLoading }] = useMutation<
    ToggleMutationData,
    MutationVariables
  >(TOGGLE_ACCEPTING_PATIENTS);

  const onDelete = useCallback(() => {
    return deleteMapping({
      variables: { providerId, departmentId: departmentProvider.department.id },
    }).then((resp) => {
      if (resp.data?.deleteDepartmentProvider.errors) {
        toast.error(resp.data.deleteDepartmentProvider.errors[0].message);
      } else if (resp.data?.deleteDepartmentProvider.department) {
        // it worked...
        toast.success("Department mapping removed");
        return onUpdate();
      }
    });
  }, [providerId, departmentProvider.department.id, deleteMapping, onUpdate]);

  const onAcceptingToggle = useCallback(() => {
    return toggleAccepting({
      variables: { providerId, departmentId: departmentProvider.department.id },
    }).then((resp) => {
      if (resp.data?.toggleAcceptingNewPatients.errors) {
        toast.error(resp.data.toggleAcceptingNewPatients.errors[0].message);
      } else if (resp.data?.toggleAcceptingNewPatients.departmentProvider) {
        // it worked...
        toast.success("Accepting new patients toggled");
        return onUpdate();
      }
    });
  }, [providerId, departmentProvider.department.id, toggleAccepting, onUpdate]);

  return (
    <li className="flex items-center gap-3 p-3 mb-2 rounded shadow">
      <div className="flex-shrink-0">
        <CircleIcon icon="sitemap" />
      </div>
      <div className="flex-grow">
        <p className="leading-tight text-cool-gray-900 font-semibold">
          {departmentProvider.department.name}
        </p>
        {departmentProvider.acceptingNewPatients ? (
          <p className="mt-1 text-sm text-green-500">
            <span className="mr-2 text-green-400">
              <FAIcon icon="check" />
            </span>
            Accepting new patients
          </p>
        ) : (
          <p className="mt-1 text-sm text-red-500">
            <span className="mr-2 text-red-400">
              <FAIcon icon="times" />
            </span>
            Not accepting new patients
          </p>
        )}
      </div>
      <div className="flex-shrink-0">
        <DropdownButton>
          <DropdownItemButton onClick={onAcceptingToggle}>
            Toggle Accepting New Patients
          </DropdownItemButton>
          <DropdownItemButton color="red" onClick={onDelete}>
            Delete mapping
          </DropdownItemButton>
        </DropdownButton>
      </div>
    </li>
  );
};

/**
 * ProviderDepartments.
 */

interface ProviderDepartmentsProps {
  providerId: string;
}

export const ProviderDepartments: FC<ProviderDepartmentsProps> = (props) => {
  const { providerId } = props;

  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    PROVIDER_DEPARTMENTS,
    { variables: { providerId } }
  );

  return (
    <div className="_ProviderDepartments p-4 grid grid-cols-2 gap-3">
      <div className="_departments-list">
        <h3 className="p-3 text-xs font-semibold text-cool-gray-500 uppercase tracking-wider">
          Departments
        </h3>
        {loading ? (
          <div className="p-8 text-center">
            <Spinner />
          </div>
        ) : error || !data?.provider ? (
          <p>Failed to load.</p>
        ) : data.provider.departmentMappings.length > 0 ? (
          <ul>
            {data.provider.departmentMappings.map((dp) => (
              <ProviderDepartmentCard
                key={dp.id}
                providerId={providerId}
                departmentProvider={dp}
                onUpdate={refetch}
              />
            ))}
          </ul>
        ) : (
          <NoResults icon="sitemap" text="No departments" />
        )}
      </div>

      <div className="_add-department-section pt-2">
        <div className="p-3 border rounded-md shadow-md">
          <h3 className="p-3 text-xs font-semibold text-cool-gray-500 uppercase tracking-wider">
            Add Department
          </h3>
          <AddDepartmentForm
            providerId={providerId}
            excludeDepartmentIds={
              data?.provider.departmentMappings.map((dp) => dp.department.id) ||
              []
            }
            onSuccess={refetch}
          />
        </div>
      </div>
    </div>
  );
};
