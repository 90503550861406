import { FC } from "react";
import { PatientInsuranceCoverages } from "./PatientInsuranceCoverages";
import { useAppointmentRequest } from "../../AppointmentRequestContext";

interface InsurancePanelProps { }

export const InsurancePanel: FC<InsurancePanelProps> = () => {
  const { patientMember } = useAppointmentRequest();
  return (
    <div className="InsurancePanel">
      <h4 className="text-xl py-4 font-semibold">Insurance</h4>
      <PatientInsuranceCoverages patientMemberId={patientMember.id} />
    </div>
  );
};
