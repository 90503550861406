import { FC, useCallback } from "react";
import parse from "date-fns/parse";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, FAIcon, TextField } from "@preferral/ui";
import { useAppointmentRequest } from "../../AppointmentRequestContext";
import toast from "react-hot-toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { DatePickerField } from "components/formik/DatePickerField";
import { addBusinessDays } from "date-fns";
import { mmDdYyyy } from "lib/dateFormatters";
import { analytics } from "lib/analytics";

const CREATE_TASK = gql`
  mutation CreateTask($appointmentRequestId: UUID4!, $input: TaskInput!) {
    createTask(appointmentRequestId: $appointmentRequestId, input: $input) {
      errors {
        key
        message
      }
      task {
        id
      }
    }
  }
`;

interface MutationData {
  createTask: {
    errors?: InputError[];
    task?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appointmentRequestId: string;
  input: TaskInput;
}

interface TaskInput {
  label: string;
  dueAt?: string;
  assignedUserId?: string;
}

type FormValues = TaskInput;

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    label: Yup.string().trim().required("Required"),
    dueAt: Yup.string().trim(),
    assignedUserId: Yup.string(),
  })
  .required();

const initialValues: FormValues = {
  label: "",
  dueAt: "",
  assignedUserId: "",
};

const now = new Date();
const oneDay = addBusinessDays(now, 1);
const twoDays = addBusinessDays(now, 2);
const oneDayString = mmDdYyyy(oneDay);
const twoDaysString = mmDdYyyy(twoDays);

/**
 * AddTaskForm.
 */

interface AddTaskFormProps {
  onCreate?(): void;
}

export const AddTaskForm: FC<AddTaskFormProps> = (props) => {
  const { onCreate } = props;

  const [addTask] = useMutation<MutationData, MutationVariables>(CREATE_TASK);

  const {
    appointmentRequest: { id: appointmentRequestId, insertedAt },
    refetch,
  } = useAppointmentRequest();

  const onSubmit = useCallback(
    (values, { setSubmitting, setStatus, resetForm }) => {
      setStatus({ errors: null });
      const input = {
        ...values,
        dueAt: !!values.dueAt ? parseDate(values.dueAt) : null,
      };

      if (!input.dueAt) {
        delete input.dueAt;
      }

      if (!input.assignedUserId) {
        delete input.assignedUserId;
      }

      return addTask({
        variables: { appointmentRequestId, input },
      }).then((resp) => {
        if (resp.data?.createTask.errors) {
          setStatus({ errors: resp.data.createTask.errors });
        } else if (resp.data?.createTask.task) {
          // it worked
          analytics.track("Task Added", {
            referral_id: appointmentRequestId,
            referral_requested_date: insertedAt,
          });
          toast.success("Comment added");
          resetForm();
          refetch();
          if (onCreate) {
            return onCreate();
          }
        }
        setSubmitting(false);
      });
    },
    [addTask, appointmentRequestId, onCreate, refetch]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <TextField name="label" label="Task" />
          <div className="flex pt-1">
            <Button
              type="button"
              size="xs"
              kind="secondary"
              className="border"
              color="teal"
              onClick={() => setFieldValue("label", "Call Patient")}
            >
              <span className="mr-2">
                <FAIcon icon="bolt" />
              </span>
              Call Patient
            </Button>
            <Button
              type="button"
              size="xs"
              kind="secondary"
              className="ml-2 border"
              color="teal"
              onClick={() => setFieldValue("label", "Call Office")}
            >
              <span className="mr-2">
                <FAIcon icon="bolt" />
              </span>
              Call Office
            </Button>
          </div>

          <div className="mt-3">
            <DatePickerField name="dueAt" label="Due Date" />
            <div className="flex pt-1">
              <Button
                type="button"
                size="xs"
                kind="secondary"
                className="border"
                color="teal"
                onClick={() => setFieldValue("dueAt", oneDayString)}
              >
                <span className="mr-2">
                  <FAIcon icon="bolt" />
                </span>
                1 Day
              </Button>
              <Button
                type="button"
                size="xs"
                kind="secondary"
                className="ml-2 border"
                color="teal"
                onClick={() => setFieldValue("dueAt", twoDaysString)}
              >
                <span className="mr-2">
                  <FAIcon icon="bolt" />
                </span>
                2 Days
              </Button>
            </div>
          </div>

          <div className="flex items-center justify-end mt-3 py-4">
            <Button
              type="submit"
              color="blue"
              kind="primary"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Add Task
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

function parseDate(dateString: string): Date {
  return parse(dateString, "M/dd/yyyy", new Date());
}
