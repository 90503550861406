import { FC, useEffect, useRef, useState } from "react";
import styled from 'styled-components/macro';
import verification from './eligibility_verified.svg';

const Notification = styled.div`
  position: fixed;
  top: 64px;
  right: 30px;
  visibility: hidden;
  opacity: 0;
  transform: translateX(60px);
  transition: visibility 0s linear 300ms, opacity 300ms, transform 300ms;
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid rbg(133, 214, 133);
  box-shadow: 0 1px 12px 0 rgba(0,0,0,0.2);
  background: rgba(75, 167, 75, 0.87);

  strong {
    color: white;
    text-shadow: 0 1px 1px #078407;
  }

  &.visible {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    transition: visibility 0s linear 0s, opacity 300ms, transform 300ms;
  }
`;

interface FakeEligibilityNotificationProps { };

export const FakeEligibilityNotification: FC<FakeEligibilityNotificationProps> = props => {
  const [visible, setVisible] = useState(false);

  const inRef = useRef<NodeJS.Timeout>();
  const outRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    inRef.current = setTimeout(() => setVisible(true), 2000);
    outRef.current = setTimeout(() => setVisible(false), 20000);
    return () => {
      inRef.current && clearTimeout(inRef.current);
      outRef.current && clearTimeout(outRef.current);
    }
  }, []);

  const cn = [
    "_FakeEligibilityNotification flex items-center",
    visible && "visible"
  ].filter(Boolean).join(" ");

  return (
    <Notification className={cn}>
      <img style={{ width: 120 }} className="image" src={verification} alt="eligibility verified" />
      <div style={{ maxWidth: 207 }} className="ml-4">
        <p><strong>Insurance Eligibility has been verified</strong></p>
        <button type="button" className="text-gray-100" onClick={() => setVisible(false)}>
          dismiss
        </button>
      </div>
    </Notification>
  );
};
