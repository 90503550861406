import React, { FC } from "react";
import {
  useLocation,
  Switch,
  useRouteMatch,
  Redirect,
  Route,
} from "react-router-dom";
import qs from "query-string";
import { PatientStep } from "./PatientStep/PatientStep";
import { QuestionsStep } from "./QuestionsStep/QuestionsStep";
import { ConfirmationStep } from "./ConfirmationStep/ConfirmationStep";
import {
  NavigationSteps,
  NavigationStepModel,
} from "components/NavigationSteps";

const steps: NavigationStepModel[] = [
  {
    label: "Patient Info",
    icon: "user",
  },
  {
    label: "Questions",
    icon: "clipboardList",
  },
  {
    label: "Confirmation",
    icon: "checkCircle",
  },
];

function getCurrentStep(pathname: string) {
  if (pathname.includes("/new")) return 0;
  if (pathname.includes("/questions")) return 1;
  if (pathname.includes("/confirm")) return 2;
  return -1;
}

interface NewInboundReferralScreenProps {}

export const NewInboundReferralScreen: FC<NewInboundReferralScreenProps> = () => {
  const location = useLocation();
  const match = useRouteMatch();
  const { patientId } = qs.parse(location.search);

  return (
    <div className="NewInboundReferralScreen">
      <div className="max-w-full mb-8 mx-12 sm:max-w-xl sm:mx-auto mt-6">
        <NavigationSteps
          steps={steps}
          currentStep={getCurrentStep(location.pathname)}
        />
      </div>
      <Switch>
        <Route path={`${match.path}/new`}>
          <PatientStep />
        </Route>
        <Route path={`${match.path}/:inboundReferralId/questions`}>
          <QuestionsStep />
        </Route>
        <Route path={`${match.path}/:inboundReferralId/confirm`}>
          <ConfirmationStep />
        </Route>
        <Route path="*">
          <Redirect to={`${match.url}/new`} />
        </Route>
      </Switch>
    </div>
  );
};
