import { FC, useState, useCallback, useEffect } from "react";
import {
  VerticalField,
  FileDropzone,
  Button,
  FAIcon,
  Spinner,
  ImageObject,
} from "@preferral/ui";
import { CroppedImage } from "./cropImage";
import { ImageCropper } from "./ImageCropper";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import ArrowRightIcon from '@heroicons/react/outline/ArrowRightIcon';
import DownloadIcon from '@heroicons/react/outline/DownloadIcon';
import LinkIcon from '@heroicons/react/outline/LinkIcon';

const UPDATE_PROVIDER_AVATAR = gql`
  mutation UpdateProviderAvatar($providerId: UUID4!, $avatar: Upload!) {
    uploadProviderAvatar(providerId: $providerId, avatar: $avatar) {
      errors {
        key
        message
      }
      provider {
        id
        avatar(size: "small") {
          base64
          src
          srcSet
        }
      }
    }
  }
`;

interface MutationData {
  uploadProviderAvatar: {
    errors?: InputError[];
    provider?: {
      id: string;
      avatar: ImageObject;
    };
  };
}

interface MutationVariables {
  providerId: string;
  avatar: Blob;
}

/**
 * URLInput.
 */

const IMGPROXY_SRC = gql`
  query ImgproxySrc($src: String!) {
    imgproxySrc(src: $src)
  }
`;

interface ImgproxyData {
  imgproxySrc: string;
}

interface URLInputProps {
  onChange(url: string): void;
}

const imageUrlRegex = /^(https?:\/\/.*\.(?:png|jpg))$/;

const URLInput: FC<URLInputProps> = (props) => {
  const { onChange } = props;

  const [loadImgproxySrc, { data, loading }] = useLazyQuery<ImgproxyData>(
    IMGPROXY_SRC
  );

  const [value, setValue] = useState("");

  const isValidUrl = imageUrlRegex.test(value);

  const onSubmit = useCallback(() => {
    if (isValidUrl && value) {
      return loadImgproxySrc({ variables: { src: value } });
    }
  }, [value, isValidUrl, loadImgproxySrc]);

  useEffect(() => {
    if (data?.imgproxySrc) {
      onChange(data.imgproxySrc);
    }
  }, [onChange, data?.imgproxySrc]);

  return (
    <>
      <div className="mt-1 flex rounded-md shadow-sm">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <LinkIcon className="h-5 w-5 text-cool-gray-400" />
          </div>
          <input
            className="form-input block w-full rounded-none rounded-l-md pl-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            placeholder="Image URL"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <button
          className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
          disabled={!value || !isValidUrl}
          onClick={onSubmit}
        >
          {loading ? (
            <Spinner />
          ) : (
            <DownloadIcon className="h-5 w-5 text-cool-gray-400" />
          )}
          <span className="ml-2">Fetch Image</span>
        </button>
      </div>
      {value && !isValidUrl ? (
        <p className="text-xs py-2 text-red-500 italic">Invalid image URL</p>
      ) : null}
    </>
  );
};

/**
 * AvatarPreview.
 */

interface AvatarPreviewProps {
  src?: string;
}

const AvatarPreview: FC<AvatarPreviewProps> = (props) => {
  const { src } = props;

  return (
    <div className="flex">
      {src ? (
        <img
          className="h-24 w-24 rounded-full border-4 border-white sm:h-32 sm:w-32 shadow"
          src={src}
          alt="avatar preview"
        />
      ) : (
        <div className="bg-indigo-50 border-4 border-white flex flex-col h-24 items-center justify-center rounded-full shadow sm:h-32 sm:w-32 text-center text-cool-gray-600 text-xs w-24">
          <ArrowRightIcon className="w-5 h-5 mb-2" />
          Select and crop an image
        </div>
      )}
    </div>
  );
};

interface ProfilePictureFormProps {
  providerId: string;
  onSuccess(): void;
}

export const ProfilePictureForm: FC<ProfilePictureFormProps> = (props) => {
  const { providerId, onSuccess } = props;
  const [urlSrc, setUrlSrc] = useState<string | null>(null);
  const [fileSrc, setFileSrc] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<CroppedImage | null>(null);

  const [uploadAvatar, { loading }] = useMutation<
    MutationData,
    MutationVariables
  >(UPDATE_PROVIDER_AVATAR);

  const resetSrc = useCallback(() => {
    setUrlSrc(null);
    setFileSrc(null);
    setCroppedImage(null);
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    const image = acceptedFiles[0];
    setFileSrc(URL.createObjectURL(image));
  }, []);

  // Make sure to revoke the data uris to avoid memory leaks.
  useEffect(() => () => fileSrc && URL.revokeObjectURL(fileSrc), [fileSrc]);

  const imageSrc = urlSrc || fileSrc;

  const onSave = useCallback(
    (croppedImageBlob: Blob) => {
      return uploadAvatar({
        variables: { providerId, avatar: croppedImageBlob },
      }).then((resp) => {
        if (resp.data?.uploadProviderAvatar.errors) {
          toast.error(resp.data.uploadProviderAvatar.errors[0].message);
        } else if (resp.data?.uploadProviderAvatar.provider) {
          // it worked...
          toast.success("Profile image updated");
          onSuccess();
        }
      });
    },
    [uploadAvatar, providerId, onSuccess]
  );

  return (
    <div className="_ProfilePictureForm flex gap-8">
      <div className="_preview-section flex-shrink-0">
        <h4 className="p-3 text-xs font-semibold text-cool-gray-500 uppercase tracking-wider">
          Preview:
        </h4>
        <AvatarPreview src={croppedImage?.src} />
      </div>

      <div className="_upload-and-crop flex-grow">
        <div className="flex items-center gap-6">
          <h4 className="p-3 text-xs font-semibold text-cool-gray-500 uppercase tracking-wider">
            {imageSrc ? "Edit Image:" : "Select Image:"}
          </h4>
          {imageSrc ? (
            <Button
              type="button"
              onClick={resetSrc}
              kind="tertiary"
              color="red"
              size="xs"
            >
              <span className="mr-2">
                <FAIcon icon="times" />
              </span>
              Clear Image
            </Button>
          ) : null}
        </div>

        {imageSrc ? (
          <ImageCropper
            src={imageSrc}
            onChange={setCroppedImage}
            onSave={onSave}
            isSaving={loading}
          />
        ) : (
          <>
            <div className="mt-3">
              <VerticalField label="From URL:">
                <URLInput onChange={setUrlSrc} />
              </VerticalField>
            </div>

            <div className="mt-5">
              <VerticalField label="Or, from file:">
                <FileDropzone
                  dropzoneOptions={{
                    accept: "image/*",
                    onDrop,
                  }}
                />
              </VerticalField>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
