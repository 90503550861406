import { FC, useState } from "react";
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";
// import logoStampSrc from "./preferral_cross_flat.svg";
import logoStampSrc from "images/aristamd_logo_stacked.svg";
import logoSrc from "images/aristamd_logo.svg";
import { useScreenTitle } from "context/ScreenTitle";
import { CurrentUserMenu } from "components/CurrentUserMenu";
import { IconNavLink } from "./IconNavLink";
import { NewThingModal } from "./NewThingModal";
import PlusIcon from "@heroicons/react/outline/PlusIcon";

// Screens
import { AnalyticsScreen } from "screens/AnalyticsScreen";
import { PatientsScreen } from "screens/PatientsScreen";
import { PatientShowScreen } from "screens/PatientShowScreen";
import { ReferralsScreen } from "screens/ReferralsScreen";
import { EConsultsScreen } from "screens/EConsultsScreen";
import { NewInboundReferralScreen } from "screens/NewInboundReferralScreen/NewInboundReferralScreen";
import { SettingsScreen } from "screens/SettingsScreen";
import { CalendarScreen } from "screens/CalendarScreen";
import { DirectMessagesScreen } from "screens/DirectMessagesScreen";
import { FaxesScreen } from "screens/FaxesScreen";
import { PatientFormsScreen } from "screens/PatientFormsScreen";
import { TextMessagesScreen } from "screens/TextMessagesScreen";
import {
  ReceivedRequestShowScreen,
  SentRequestShowScreen,
} from "screens/AppointmentRequestShowScreen";
import { NewReferralScreen } from "screens/NewReferralScreen/NewReferralScreen";
import { PatientPacketShowScreen } from "screens/PatientPacketShowScreen";
import { EConsultShowScreen } from "screens/EConsultShowScreen";
import { EConsultRequestFormScreen } from "screens/EConsultRequestFormScreen";
import { Modal } from "components/Modal";
import { useTrackUser } from "hooks/useTrackUser";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { CircleIcon } from "@preferral/ui";
import { NewTooltip } from "components/NewTooltip";
import { analytics } from "lib/analytics";

import { LiveChatLoaderProvider, HelpScout } from "react-live-chat-loader";

/**
 * TopNav.
 */

interface TopNavProps {
  sidebarOpen: boolean;
  setSidebarOpen(isOpen: boolean): void;
}

const TopNav: FC<TopNavProps> = props => {
  const { setSidebarOpen } = props;
  const { titleString, shortTitle } = useScreenTitle();

  return (
    <div className="relative z-10 flex-shrink-0 flex h-16 border-b-2 border-blue-200 bg-white">
      <button
        onClick={() => setSidebarOpen(true)}
        className="px-4 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden"
      >
        <svg
          className="h-6 w-6"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </button>
      <div className="flex-1 px-4 flex justify-between">
        <div className="flex-1 flex">
          <div className="w-full flex md:ml-0">
            <div className="flex items-center text-gray-400 w-full">
              <h2 className="hidden md:inline-block font-bold md:mx-4 md:text-lg text-base text-gray-700 whitespace-no-wrap">
                {titleString}
              </h2>
              <h2 className="inline-block md:hidden font-bold md:mx-4 md:text-lg text-base text-gray-700 whitespace-no-wrap">
                {shortTitle}
              </h2>
            </div>
          </div>
        </div>
        <div className="ml-4 flex items-center md:ml-6">
          {/* <button className="p-1 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500">
            <svg
              className="h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
          </button> */}
          <div className="ml-3 relative">
            <CurrentUserMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * SidebarNavLink.
 */

// interface SidebarNavLinkProps {
//   to: string;
//   className?: string;
// }

// const SidebarNavLink: FC<SidebarNavLinkProps> = (props) => {
//   const { to, className = '', children } = props;

//   return (
//     <NavLink
//       to={to}
//       className={`mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-cool-gray-700 rounded-md hover:text-blue-700 hover:bg-blue-100 focus:outline-none focus:bg-blue-100 transition ease-in-out duration-150 ${className}`}
//       activeClassName="text-blue-800 bg-blue-100 hover:bg-blue-100 shadow-inner"
//     >
//       {children}
//     </NavLink>
//   )
// }

/**
 * SidebarLinks.
 */
interface SidebarLinksProps {
  className?: string;
}

const SidebarLinks: FC<SidebarLinksProps> = props => {
  const { className = "" } = props;
  const match = useRouteMatch();

  const { hasFeature } = useFeatureFlags();

  return (
    <nav
      className={`flex-1 px-2 py-4 flex flex-col justify-center ${className}`}
    >
      {hasFeature("econsults") ? (
        <IconNavLink
          to={`${match.path}econsults`}
          label="eConsults"
          icon="user-couple"
        />
      ) : null}
      {hasFeature("referrals") ? (
        <IconNavLink
          to={`${match.path}referrals`}
          label="Referrals"
          icon="inbox-full"
        />
      ) : null}
      {/* <IconNavLink
        to={`${match.path}patient_forms`}
        label="Patient Forms"
        icon="survey"
      /> */}
      {hasFeature("patient_forms") ? (
        <IconNavLink
          to={`${match.path}patient_packets`}
          label="Patient Forms"
          icon="survey"
        />
      ) : null}
      {/* <IconNavLink
        to={`${match.path}direct_messages`}
        label="Direct Messaging"
        icon="mail"
      />
      <IconNavLink
        to={`${match.path}text_messages`}
        label="Text Messaging"
        icon="chat-group"
      /> */}
      {hasFeature("fax_inboxes") ? (
        <IconNavLink to={`${match.path}faxes`} label="Faxes" icon="fax" />
      ) : null}
      <div
        className="w-6 bg-cool-gray-400 my-3 mx-auto"
        style={{ height: 2 }}
      />
      <IconNavLink
        to={`${match.path}patients`}
        label="Patients"
        icon="user-group"
      />
      {/* <IconNavLink
        to={`${match.path}calendar`}
        label="Calendar"
        icon="calendar-date"
      /> */}
      {hasFeature("analytics") ? (
        <IconNavLink
          to={`${match.path}analytics`}
          label="Analytics"
          icon="chart"
        />
      ) : null}
      {/* <IconNavLink
        to={`${match.path}workflows`}
        label="Workflows"
        icon="bolt"
      /> */}
    </nav>
  );
};

/**
 * SideToTopCurve.
 */

const SideToTopCurve = () => {
  return (
    <div className="_SideToTopCurve text-blue-200">
      <svg
        width="32"
        height="32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M32 0H0v32h2C2 15.431 15.431 2 32 2V0z" fill="currentColor" />
        <path d="M32 0H0v32C0 14.327 14.327 0 32 0z" fill="#fff" />
      </svg>
    </div>
  );
};

/**
 * Sidebar.
 */

interface SidebarProps {
  isOpen: boolean;
  onClose(): void;
  onNewThingClick(): void;
}

const Sidebar: FC<SidebarProps> = props => {
  const { isOpen, onClose, onNewThingClick } = props;
  const match = useRouteMatch();

  return (
    <>
      {/* Off-canvas menu for mobile */}
      <div
        onClick={onClose}
        className={`fixed inset-0 z-30 bg-gray-600 opacity-0 pointer-events-none transition-opacity ease-linear duration-300 ${
          isOpen
            ? "opacity-75 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
      />
      <div
        className={`fixed inset-y-0 left-0 flex flex-col z-40 max-w-xs w-full bg-white transform ease-in-out duration-300 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="absolute top-0 right-0 -mr-14 p-1">
          {isOpen ? (
            <button
              onClick={onClose}
              className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
            >
              <svg
                className="h-6 w-6 text-white"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          ) : null}
        </div>
        <div className="bg-white border-r flex flex-shrink-0 h-16 items-center px-4">
          <img className="h-8 w-auto" src={logoSrc} alt="Preferral Logo" />
        </div>
        <div className="flex-1 h-0 overflow-y-auto">
          <SidebarLinks />
          {/* <nav className="px-2 py-4">
          <div className="flex justify-center">
            <span className="inline-flex rounded-md shadow-sm">
              <Link
                to="/cw/p2p_request_wizard/new"
                className="inline-flex items-center px-4 py-2 font-bold border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gold-600 hover:bg-gold-500 focus:outline-none focus:border-gold-700 focus:shadow-outline-yellow active:bg-gold-700 transition ease-in-out duration-150"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" />
                New P2P Request
              </Link>
            </span>
          </div>
          <NavLink
            to="/cw/dashboard"
            className="mt-4 group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-navy-300 hover:text-white hover:bg-navy-700 focus:outline-none focus:text-white focus:bg-navy-700 transition ease-in-out duration-150"
            activeClassName="text-white bg-gray-900 hover:bg-gray-900"
          >
            <svg
              className="mr-4 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"
              />
            </svg>
            Dashboard
          </NavLink>
          <NavLink
            to="/cw/requests"
            className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-navy-300 hover:text-white hover:bg-navy-700 focus:outline-none focus:text-white focus:bg-navy-700 transition ease-in-out duration-150"
            activeClassName="text-white bg-gray-900 hover:bg-gray-900"
          >
            <svg
              className="mr-4 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            My P2P Requests
          </NavLink>
        </nav> */}
        </div>
      </div>

      {/* Static sidebar for desktop */}
      <div className="hidden z-20 md:flex md:flex-shrink-0 bg-white">
        <div className="h-0 relative w-0">
          <div
            className="absolute"
            style={{
              left: "4rem",
              top: "4rem",
              transform: "translate(-2px, -2px)",
            }}
          >
            <SideToTopCurve />
          </div>
        </div>
        <div className="flex flex-col w-16">
          <div className="flex items-center h-16 flex-shrink-0 px-4">
            <img
              className="h-8 w-auto mx-auto"
              src={logoStampSrc}
              alt="Preferral Logo"
            />
          </div>

          {/* TODO: Make this overflow-y-auto (scroll) again when ready to fix x/y hidden scroll issue with sidebar label tips */}
          {/* <div className="h-0 flex-1 flex flex-col border-r-2 border-blue-200 overflow-y-auto py-4"> */}
          <div className="h-0 flex-1 flex flex-col border-r-2 border-blue-200 overflow-visible py-4">
            <div className="_NewThingButton">
              <span className="inline-block rounded-md shadow-md">
                <button
                  type="button"
                  className="inline-flex items-center px-2 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-700 active:bg-blue-700 transition duration-150 ease-in-out"
                  onClick={onNewThingClick}
                >
                  <PlusIcon className="h-6 w-6 text-white" />
                </button>
              </span>
            </div>
            <SidebarLinks />
            <IconNavLink
              className="flex-shrink-0"
              to={`${match.path}settings`}
              label="Settings"
              icon="cog"
            />
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * PortalLayout.
 */

interface PortalLayoutProps {}

export const PortalLayout: FC<PortalLayoutProps> = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [newThingModalOpen, setNewThingModalOpen] = useState(false);

  const openNewThingModal = () => setNewThingModalOpen(true);
  const closeNewThingModal = () => setNewThingModalOpen(false);
  const match = useRouteMatch();

  const { hasFeature } = useFeatureFlags();

  useTrackUser();

  return (
    <div
      className="_PortalLayout h-screen flex overflow-hidden"
      style={{ backgroundColor: "#f2f6fa" }}
    >
      <Modal
        isOpen={newThingModalOpen}
        onRequestClose={closeNewThingModal}
        className="max-w-3xl rounded-lg"
      >
        <NewThingModal onClose={closeNewThingModal} />
      </Modal>
      <Sidebar
        isOpen={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        onNewThingClick={openNewThingModal}
      />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <TopNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main
          className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
          tabIndex={0}
        >
          <LiveChatLoaderProvider
            providerKey={process.env.REACT_APP_HELP_SCOUT_KEY!}
            provider="helpScout"
          >
            <HelpScout icon="question" color="#2292EC" />
          </LiveChatLoaderProvider>

          <Switch>
            {hasFeature("analytics") ? (
              <Route path={`${match.path}analytics`}>
                <AnalyticsScreen />
              </Route>
            ) : null}

            {hasFeature("referrals") ? (
              <Route path={`${match.path}referrals`}>
                <ReferralsScreen />
              </Route>
            ) : null}

            <Route path={`${match.path}econsults/:id(\\d+)`}>
              <EConsultShowScreen />
            </Route>

            {/* NB: Work on a draft eConsult */}
            <Route path={`${match.path}econsults/:fhirId/edit`}>
              <EConsultRequestFormScreen />
            </Route>

            <Route path={`${match.path}new_econsult`}>
              <EConsultRequestFormScreen />
            </Route>

            {hasFeature("econsults") ? (
              <Route path={`${match.path}econsults`}>
                <EConsultsScreen />
              </Route>
            ) : null}

            <Route
              path={`${match.path}appointment_requests/:appointmentRequestId`}
            >
              <ReceivedRequestShowScreen />
            </Route>

            <Route path={`${match.path}sent_requests/:appointmentRequestId`}>
              <SentRequestShowScreen />
            </Route>

            <Route path={`${match.path}referral`}>
              <NewReferralScreen />
            </Route>

            <Route path={`${match.path}inbound_referral`}>
              <NewInboundReferralScreen />
            </Route>

            <Route exact path={`${match.path}patients`}>
              <PatientsScreen />
            </Route>

            <Route path={`${match.path}patients/:id`}>
              <PatientShowScreen />
            </Route>

            <Route exact path={`${match.path}calendar`}>
              <CalendarScreen />
            </Route>

            <Route exact path={`${match.path}direct_messages`}>
              <DirectMessagesScreen />
            </Route>

            {hasFeature("fax_inboxes") ? (
              <Route path={`${match.path}faxes`}>
                <FaxesScreen />
              </Route>
            ) : null}

            {hasFeature("patient_forms") ? (
              <Route path={`${match.path}patient_forms`}>
                <PatientFormsScreen />
              </Route>
            ) : null}

            <Route exact path={`${match.path}patient_packets`}>
              {/* <PatientPacketsScreen /> */}
              <Redirect to={`${match.url}/patient_forms/packets`} />
            </Route>

            <Route
              path={`${match.path}patient_packets/:patientPacketSubmissionId`}
            >
              <PatientPacketShowScreen />
            </Route>

            <Route path={`${match.path}text_messages`}>
              <TextMessagesScreen />
            </Route>

            {/* <Route
            path={`${match.path}requests/:id`}
            component={AppointmentRequestShowPage}
          /> */}

            <Route path={`${match.path}settings`}>
              <SettingsScreen />
            </Route>
          </Switch>
        </main>
      </div>
    </div>
  );
};
