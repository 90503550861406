import React, { FC, useState, useCallback } from "react";
import { gql, useQuery } from "@apollo/client";
import { GradientHeader } from "../../../GradientHeader";
import { AddDepartmentProviderModal } from "./AddDepartmentProviderModal";
import { NoResults } from "components/NoResults";
import {
  Avatar,
  Button,
  ImageObject,
  TableContainer,
  Table,
  TH,
  TD,
  DropdownButton,
  DropdownItemButton,
  Spinner,
  FAIcon,
  DropdownItemLink,
  VerticalField,
} from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { useRouteMatch } from "react-router-dom";
import { ZERO_WIDTH_SPACE } from "@preferral/common";
import { defaultFilter, FilterModel, FilterPanel } from "./FilterPanel";

const DEPARTMENT_PROVIDERS = gql`
  query DepartmentProviders($departmentId: UUID4!, $filter: ListProvidersFilter) {
    department(id: $departmentId) {
      id
      name
    }
    departmentProviders(departmentId: $departmentId, filter: $filter) {
      cursor
      endOfList
      items {
        id
        npi
        nameWithAppellation
        insertedAt
        departmentMappings {
          id
          departmentId
          acceptingNewPatients
        }
        avatar(size: "small") {
          base64
          src
          srcSet
        }
        specialties {
          id
          name
        }
      }
    }
  }
`;

interface Data {
  department: {
    id: string;
    name: string;
  };
  departmentProviders: Paginated<ProviderModel>;
}

interface ProviderModel {
  id: string;
  npi: string;
  nameWithAppellation: string;
  insertedAt: string;
  departmentMappings: {
    id: string;
    departmentId: string;
    acceptingNewPatients: boolean;
  }[];
  avatar: ImageObject;
  specialties: {
    id: string;
    name: string;
  }[];
}

interface Variables {
  departmentId: string;
  filter: FilterModel;
}

type ActiveModal = "ADD_PROVIDER" | "REMOVE_PROVIDER";

interface DepartmentProvidersScreenProps { }

export const DepartmentProvidersScreen: FC<DepartmentProvidersScreenProps> = (
  props
) => {
  const match = useRouteMatch<{ departmentId: string }>();
  const { departmentId } = match.params;

  const [filter, setFilter] = useState<FilterModel>(defaultFilter);

  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    DEPARTMENT_PROVIDERS,
    { variables: { departmentId, filter } }
  );

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const openAddProviderModal = useCallback(() => {
    setActiveModal("ADD_PROVIDER");
  }, [setActiveModal]);

  const openRemoveProviderModal = useCallback(() => {
    setActiveModal("REMOVE_PROVIDER");
  }, [setActiveModal]);

  const closeModal = useCallback(() => {
    setActiveModal(null);
  }, [setActiveModal]);

  return (
    <>
      <ScreenTitle title={["Settings", "Department", "Providers"]} />
      <AddDepartmentProviderModal
        departmentId={departmentId}
        isOpen={activeModal === "ADD_PROVIDER"}
        onClose={closeModal}
        onSuccess={refetch}
      />
      <div className="_DepartmentProvidersScreen">
        <div className="bg-white box rounded-lg shadow-lg">
          <GradientHeader
            icon="user-doctor"
            title="Department Providers"
            subtitle={
              data?.department.name
                ? `Manage providers for ${data.department.name}`
                : ``
            }
          />
          <div className="p-4">
            <div className="_FilterAndActions flex justify-between items-start py-4 px-2 lg:px-4">
              <FilterPanel value={filter} onChange={setFilter} isLoading={loading} />
              <div spec-id="department-providers-index-action">
                <VerticalField label={ZERO_WIDTH_SPACE}>
                  <Button
                    type="button"
                    color="blue"
                    onClick={openAddProviderModal}
                  >
                    <span className="mr-2">
                      <FAIcon icon="plus" />
                    </span>
                    Add Provider
                  </Button>
                </VerticalField>
              </div>
            </div>

            {loading ? (
              <div className="p-6 text-center">
                <Spinner />
              </div>
            ) : error || !data?.departmentProviders ? (
              <p>Failed to load</p>
            ) : (
              <div className="pt-6">
                {data.departmentProviders.items.length === 0 ? (
                  <NoResults
                    icon="user-doctor"
                    text={`No matching providers.`}
                  />
                ) : (
                  <TableContainer>
                    <Table>
                      <thead>
                        <tr>
                          <TH>Provider</TH>
                          <TH />
                        </tr>
                      </thead>
                      <tbody>
                        {data.departmentProviders.items.map((prov) => (
                          <tr key={prov.id}>
                            <TD>
                              <div className="flex items-center">
                                <div className="inline-block">
                                  <Avatar size="sm" image={prov.avatar} />
                                </div>
                                <div className="ml-4">
                                  <div className="flex items-baseline">
                                    <p className="font-semibold text-cool-gray-900 text-lg">
                                      {prov.nameWithAppellation}
                                    </p>
                                    <p className="ml-4 text-cool-gray-500 text-xs italic">
                                      NPI: {prov.npi}
                                    </p>
                                  </div>
                                  {prov.specialties.length > 0 && (
                                    <p className="text-sm text-cool-gray-700">
                                      {prov.specialties
                                        .map((s) => s.name)
                                        .join(", ")}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </TD>
                            <TD className="lg:w-40">
                              <DropdownButton label="Actions">
                                <DropdownItemLink
                                  to={`/o/settings/providers/${prov.id}/profile`}
                                >
                                  Edit Profile
                                </DropdownItemLink>
                                <DropdownItemButton
                                  color="red"
                                  onClick={openRemoveProviderModal}
                                >
                                  Remove Provider
                                </DropdownItemButton>
                              </DropdownButton>
                            </TD>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
