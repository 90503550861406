import React, { FC, InputHTMLAttributes, useState } from "react";
import { Button } from "@preferral/ui";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ProviderSearchBox, ProviderSearchFilter } from "./ProviderSearchBox";
import { SelectedProvider } from "./SelectedProvider";
import { CreateUnlistedProviderDrawer } from "./CreateUnlistedProviderDrawer";
import { ProviderReferenceModel } from "./types";

type ActiveModal = "CREATE_UNLISTED_PROVIDER";

export interface ProviderSearchSelectProps {
  value: ProviderReferenceModel | null | undefined;
  onChange(selectedProvider: ProviderReferenceModel | null): void;
  onBlur?(e: any): void;
  icon?: IconProp;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  filter?: ProviderSearchFilter;
  allowCreation?: boolean;
}

export const ProviderSearchSelect: FC<ProviderSearchSelectProps> = (props) => {
  const {
    value,
    onChange,
    onBlur,
    icon,
    inputProps = {},
    filter = {},
    allowCreation = false,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  return (
    <>
      {allowCreation ? (
        <CreateUnlistedProviderDrawer
          isOpen={activeModal === "CREATE_UNLISTED_PROVIDER"}
          onClose={() => setActiveModal(null)}
          onSuccess={onChange}
        />
      ) : null}
      {!!value ? (
        <div className="py-2">
          <SelectedProvider
            provider={value}
            onClear={() => onChange(null)}
            isClearable
          />
        </div>
      ) : (
        <>
          <ProviderSearchBox
            icon={icon}
            value={searchTerm}
            onChange={setSearchTerm}
            onBlur={onBlur}
            onProviderClick={onChange}
            inputProps={inputProps}
            filter={filter}
          />
          {/* WIP: Add Validation error message? Or handle that in parent... */}
          {allowCreation ? (
            <div className="flex items-center p-1">
              <p className="mr-2 text-cool-gray-700 text-xs">
                Couldn't find the provider?
              </p>
              <Button
                type="button"
                kind="tertiary"
                size="xs"
                color="blue"
                onClick={() => setActiveModal("CREATE_UNLISTED_PROVIDER")}
              >
                Enter a Provider
              </Button>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};
