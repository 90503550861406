import styled from 'styled-components/macro';

export const TrailingGradient = styled.div`
  position: absolute;
  right: 1px;
  bottom: 0;
  left: 1px;
  height: 4rem;
  background: linear-gradient(0, rgba(0,0,0,0.15), transparent);
`;
