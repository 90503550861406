import React, { FC, useCallback, useState } from "react";
import cx from "classnames";
import { useRouteMatch } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import { GradientHeader } from "screens/SettingsScreen/GradientHeader";
import { Spinner } from "components/Spinner";
import { Modal, ModalHeader } from "components/Modal";
import { NewInboxForm } from "./NewInboxForm";
import { EditInboxForm } from "./EditInboxForm";
import { RetireInboxForm } from "./RetireInboxForm";
import { FAIcon } from "components/FAIcon";
import { TableContainer, Table, TH, TD } from "@preferral/ui";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { DropdownButton, DropdownItemButton } from "components/DropdownButton";
import { NewTooltip } from "components/NewTooltip";
import { ScreenTitle } from "context/ScreenTitle";
import toast from "react-hot-toast";

const INBOXES = gql`
  query ListDepartmentInboxes($id: UUID4!) {
    department(id: $id) {
      id
      name
      inboxes {
        id
        name
        active
        isDefaultGuestReferralInbox
        isDefaultInboundReferralInbox
        isDefaultReferralInbox
        isDefaultSelfReferralInbox
        isDefaultFormSubmissionInbox
        insertedAt
        updatedAt
      }
    }
  }
`;

type Inbox = {
  id: string;
  name: string;
  active: boolean;
  isDefaultGuestReferralInbox: boolean;
  isDefaultInboundReferralInbox: boolean;
  isDefaultReferralInbox: boolean;
  isDefaultSelfReferralInbox: boolean;
  isDefaultFormSubmissionInbox: boolean;
  insertedAt: string;
  updatedAt: string;
};

interface Data {
  department: {
    id: string;
    name: string;
    inboxes: Inbox[];
  };
}

const UPDATE_DEFAULT_INBOX_FOR = gql`
  mutation UpdateDefaultInboxFor($id: UUID4!, $requestType: String!) {
    updateDefaultInboxFor(id: $id, requestType: $requestType) {
      errors {
        key
        message
      }
      inbox {
        id
        name
        name
        isDefaultGuestReferralInbox
        isDefaultInboundReferralInbox
        isDefaultReferralInbox
        isDefaultSelfReferralInbox
        isDefaultFormSubmissionInbox
        insertedAt
        updatedAt
      }
    }
  }
`;

interface MutationData {
  updateDefaultInboxFor: {
    errors?: InputError[];
    inbox?: Inbox;
  };
}

type RequestType =
  | "self_referrals"
  | "guest_referrals"
  | "network_referrals"
  | "inbound_referrals"
  | "form_submissions";

interface MutationVars {
  id: string;
  requestType: RequestType;
}

function isDefaults(inbox: Inbox): string[] {
  const map: { [x in string]: boolean } = {
    formSubmissions: inbox.isDefaultFormSubmissionInbox,
    guestReferrals: inbox.isDefaultGuestReferralInbox,
    inboundReferrals: inbox.isDefaultInboundReferralInbox,
    networkReferrals: inbox.isDefaultReferralInbox,
    selfReferrals: inbox.isDefaultSelfReferralInbox,
  };
  return Object.keys(map).filter((key: string) => !!map[key]);
}

const sets: { [x in string]: { tip: string; label: string } } = {
  formSubmissions: {
    tip: "This inbox is the default inbox for custom form submissions",
    label: "Form submissions",
  },
  guestReferrals: {
    tip: "This inbox is the default inbox for guest referrals",
    label: "Guest-referrals",
  },
  inboundReferrals: {
    tip:
      "This inbox is the default inbox for inbound referrals entered by your staff",
    label: "Inbound-referrals",
  },
  networkReferrals: {
    tip:
      "This inbox is the default inbox for referrals from the Preferral network",
    label: "Network-referrals",
  },
  selfReferrals: {
    tip: "This inbox is the default inbox for patient self-referrals",
    label: "Self-referrals",
  },
};

const DefaultLabel: React.FC<{ name: string }> = ({ name }) => {
  if (!sets[name]) {
    return null;
  }
  return <NewTooltip tip={sets[name].tip}>{sets[name].label}</NewTooltip>;
};

const DescribeDefaults: React.FC<{ inbox: Inbox }> = ({ inbox }) => {
  const defaultFor = isDefaults(inbox);
  if (defaultFor.length === 0) {
    return null;
  }
  return (
    <div>
      <p className="text-xs text-gray-700">Default inbox for:</p>
      <p className="font-semibold text-blue-800 text-sm">
        {defaultFor.map((key, idx) => (
          <span key={key}>
            {idx === 0 ? "" : idx === defaultFor.length - 1 ? " & " : ", "}
            <DefaultLabel name={key} />
          </span>
        ))}
      </p>
    </div>
  );
};

interface InboxesProps {}

export const Inboxes: FC<InboxesProps> = () => {
  const match = useRouteMatch<{ departmentId: string }>();
  const { departmentId } = match.params;

  const { data, loading, error } = useQuery<Data>(INBOXES, {
    variables: { id: departmentId },
  });

  const [updateDefault] = useMutation<MutationData, MutationVars>(
    UPDATE_DEFAULT_INBOX_FOR
  );

  const updateDefaultFor = useCallback(
    (inboxId: string, requestType: RequestType) => {
      return updateDefault({
        variables: { id: inboxId, requestType },
        refetchQueries: [{ query: INBOXES, variables: { id: departmentId } }],
      }).then((res: any) => {
        if (res.data && res.data.updateDefaultInboxFor) {
          toast.success("Inbox updated");
        }
      });
    },
    [updateDefault, departmentId, toast]
  );

  const [currentModal, setCurrentModal] = useState<
    "CREATE_INBOX" | "EDIT_INBOX" | "RETIRE_INBOX" | null
  >(null);

  const [selectedInboxId, setSelectedInbox] = useState<string | null>(null);

  const openCreateModal = useCallback(() => {
    setCurrentModal("CREATE_INBOX");
  }, [setCurrentModal]);

  const openEditModal = useCallback(
    (inboxId: string) => {
      setCurrentModal("EDIT_INBOX");
      setSelectedInbox(inboxId);
    },
    [setCurrentModal, setSelectedInbox]
  );

  const openRetireModal = useCallback(
    (inboxId: string) => {
      setCurrentModal("RETIRE_INBOX");
      setSelectedInbox(inboxId);
    },
    [setCurrentModal, setSelectedInbox]
  );

  const dismissModal = useCallback(() => {
    setCurrentModal(null);
  }, [setCurrentModal]);

  return (
    <>
      <ScreenTitle title="Settings » Department » Inboxes" />
      <Modal
        isOpen={currentModal === "CREATE_INBOX"}
        onRequestClose={dismissModal}
      >
        <ModalHeader icon="inbox" title="Create Inbox" onClose={dismissModal} />
        <div className="px-8 pt-6 pb-8 flex flex-col">
          <NewInboxForm
            departmentId={departmentId}
            refetchQueries={[
              {
                query: INBOXES,
                variables: { id: departmentId },
              },
            ]}
            closeModal={dismissModal}
          />
        </div>
      </Modal>
      <Modal
        isOpen={currentModal === "EDIT_INBOX"}
        onRequestClose={dismissModal}
      >
        <ModalHeader icon="inbox" title="Edit Inbox" onClose={dismissModal} />
        <div className="px-8 pt-6 pb-8 flex flex-col">
          {selectedInboxId && (
            <EditInboxForm
              closeModal={dismissModal}
              inboxId={selectedInboxId}
              refetchQueries={[
                {
                  query: INBOXES,
                  variables: { id: departmentId },
                },
              ]}
            />
          )}
        </div>
      </Modal>
      <Modal
        isOpen={currentModal === "RETIRE_INBOX"}
        onRequestClose={dismissModal}
      >
        <ModalHeader icon="inbox" title="Retire Inbox" onClose={dismissModal} />
        <div className="px-8 pt-6 pb-8 flex flex-col">
          {selectedInboxId && (
            <RetireInboxForm
              departmentId={departmentId}
              inboxId={selectedInboxId}
              closeModal={dismissModal}
              refetchQueries={[
                {
                  query: INBOXES,
                  variables: { id: departmentId },
                },
              ]}
            />
          )}
        </div>
      </Modal>
      <div className="bg-white box rounded-lg shadow-lg">
        <GradientHeader
          icon="inbox"
          title="Inboxes"
          subtitle="Manage department inboxes"
        />
        <div className="p-4">
          {loading ? (
            <div className="p-12 text-center">
              <Spinner />
            </div>
          ) : error || !(data && data.department) ? (
            <div>
              <p>Failed to load</p>
            </div>
          ) : (
            <div>
              <div className="flex justify-end px-3 pb-4">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={openCreateModal}
                >
                  <span className="mr-2">
                    <FAIcon icon="plus" />
                  </span>
                  Add Inbox
                </button>
              </div>
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <TH>Name</TH>
                      <TH />
                      <TH />
                    </tr>
                  </thead>
                  <tbody>
                    {data.department.inboxes.map((inbox: any) => (
                      <tr key={inbox.id}>
                        <TD>
                          <p
                            className={cx("font-semibold", {
                              "text-gray-900": inbox.active,
                              "text-gray-600": !inbox.active,
                            })}
                          >
                            {inbox.name}
                            {!inbox.active && (
                              <span className="ml-2 text-purple-600">
                                Inactive
                              </span>
                            )}
                          </p>
                          <p className="text-gray-500 text-xs">
                            Created:{" "}
                            {format(
                              parseISO(inbox.insertedAt),
                              "M/d/yy h:mm a"
                            )}
                          </p>
                        </TD>
                        <TD>
                          <DescribeDefaults inbox={inbox} />
                        </TD>
                        <TD className="w-40">
                          <DropdownButton label="Actions">
                            <DropdownItemButton
                              onClick={() => openEditModal(inbox.id)}
                            >
                              Edit Inbox
                            </DropdownItemButton>
                            <DropdownItemButton
                              onClick={() =>
                                updateDefaultFor(inbox.id, "network_referrals")
                              }
                            >
                              Set as default inbox for referrals
                            </DropdownItemButton>
                            <DropdownItemButton
                              onClick={() =>
                                updateDefaultFor(inbox.id, "guest_referrals")
                              }
                            >
                              Set as default inbox for guest referrals
                            </DropdownItemButton>
                            <DropdownItemButton
                              onClick={() =>
                                updateDefaultFor(inbox.id, "inbound_referrals")
                              }
                            >
                              Set as default inbox for inbound referrals
                            </DropdownItemButton>
                            <DropdownItemButton
                              onClick={() =>
                                updateDefaultFor(inbox.id, "self_referrals")
                              }
                            >
                              Set as default inbox for self-referrals
                            </DropdownItemButton>
                            <DropdownItemButton
                              onClick={() =>
                                updateDefaultFor(inbox.id, "form_submissions")
                              }
                            >
                              Set as default inbox for form submissions
                            </DropdownItemButton>
                            <DropdownItemButton
                              color="red"
                              onClick={() => openRetireModal(inbox.id)}
                            >
                              Retire Inbox
                            </DropdownItemButton>
                          </DropdownButton>
                        </TD>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
