import { FC } from "react";
import { ActivityForm } from "./ActivityForm";
import { ActivityLog } from "./ActivityLog";

interface ActivityPanelProps { }

export const ActivityPanel: FC<ActivityPanelProps> = (props) => {
  return (
    <div className="ActivityPanel">
      <div className="_ActivityLogContainer max-w-xl mx-auto pb-6 pt-4 px-4">
        <ActivityLog />
      </div>

      <div className="_ActivityFormContainer bg-cool-gray-50 border-cool-gray-200 border-t p-4 rounded-b-xl">
        <ActivityForm />
      </div>
    </div>
  );
};
