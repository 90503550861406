import React, { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { ProfilePictureForm } from "./ProfilePictureForm";

interface ProfilePictureModalProps {
  providerId: string;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

export const ProfilePictureModal: FC<ProfilePictureModalProps> = (props) => {
  const { providerId, isOpen, onClose, onSuccess } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="max-w-2xl">
      <ModalHeader
        icon="crop"
        title="Update Profile Picture"
        onClose={onClose}
      />

      <div className="p-4">
        <ProfilePictureForm
          providerId={providerId}
          onSuccess={() => {
            onSuccess();
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};
