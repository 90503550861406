import { FC, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";
import { PatientPacketSubmissionTagModel } from "../PatientPacketTagsScreen";
import { useMutation } from "@apollo/client";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Button, ColorSwatchField, TextField } from "@preferral/ui";
import toast from "react-hot-toast";

const CREATE_TAG = gql`
  mutation CreatePatientPacketSubmissionTag($departmentId: UUID4!, $input: PatientPacketSubmissionTagInput!) {
    createPatientPacketSubmissionTag(departmentId: $departmentId, input: $input) {
      errors {
        key
        message
      }
      patientPacketSubmissionTag {
        id
        label
        description
        color
        insertedAt
      }
    }
  }
`;

interface MutationData {
  createPatientPacketSubmissionTag: {
    errors?: InputError[];
    patientPacketSubmissionTag?: PatientPacketSubmissionTagModel;
  };
}

interface MutationVariables {
  departmentId: string;
  input: FormValues;
}

interface FormValues {
  label: string;
  color: string;
  description: string;
}
const hexRegex = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;

const validationSchema = Yup.object()
  .shape({
    label: Yup.string().required("Required"),
    description: Yup.string(),
    color: Yup.string()
      .required("Required")
      .matches(hexRegex, "Invalid color"),
  })
  .required();

interface NewTagFormProps {
  departmentId: string;
  onSuccess(): void;
}

export const NewTagForm: FC<NewTagFormProps> = (props) => {
  const { departmentId, onSuccess } = props;

  const [createTag] = useMutation<MutationData, MutationVariables>(CREATE_TAG);

  const onSubmit = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { setSubmitting, setStatus } = formikHelpers;

      setStatus({ errors: null });

      const variables: MutationVariables = {
        departmentId,
        input: values,
      };

      try {
        const { data } = await createTag({ variables });

        if (data?.createPatientPacketSubmissionTag.errors) {
          setStatus({ errors: data.createPatientPacketSubmissionTag.errors });
        } else if (data?.createPatientPacketSubmissionTag.patientPacketSubmissionTag) {
          // it worked...
          toast.success("Tag created.");
          return onSuccess();
        }
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
      } finally {
        setSubmitting(false);
      }
    },
    [departmentId, createTag, onSuccess]
  );

  return (
    <div className="_NewTagForm">
      <Formik<FormValues>
        initialValues={{
          label: "",
          description: "",
          color: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <div className="grid grid-cols-2 gap-4">
              <TextField name="label" label="Label" />

              {/* <TextField
                name="color"
                label="Color"
              /> */}
              <ColorSwatchField name="color" label="Color" />
            </div>

            <div className="mt-4">
              <TextField
                name="description"
                label="Tag Description"
                indicateOptional
              />
            </div>

            <div className="mt-6 text-center">
              <Button
                type="submit"
                kind="primary"
                color="blue"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Create Tag
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
