import { FC, useCallback, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import groupBy from "lodash.groupby";
import {
  Avatar,
  Button,
  CircleIcon,
  FAIcon,
  ImageObject,
  InputText,
} from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ProviderShowScreen } from "./ProviderShowScreen";
import { AddProviderModal } from "./AddProviderModal";
import { CreateProviderModal } from "./CreateProviderModal";
import { ImportProvidersModal } from "./ImportProvidersModal";

const ORGANIZATION_PROVIDERS = gql`
  query OrganizationProviders($first: Int) {
    me {
      id
      organization {
        id
        providerCount
      }
    }
    organizationProviders(first: $first) {
      cursor
      endOfList
      items {
        id
        npi
        nameWithAppellation
        lastName
        insertedAt
        avatar {
          base64
          src
          srcSet
        }
        primarySpecialty {
          id
          name
        }
      }
    }
  }
`;

interface Data {
  organizationProviders: Paginated<ProviderModel>;
  me: {
    id: string;
    organization: {
      id: string;
      providerCount: number;
    };
  };
}

interface ProviderModel {
  id: string;
  npi: string;
  nameWithAppellation: string;
  lastName: string;
  insertedAt: string;
  avatar: ImageObject;
  primarySpecialty?: {
    id: string;
    name: string;
  };
}

interface Variables {
  first?: number;
  after?: string;
  filter?: ListProvidersFilter;
}

interface ListProvidersFilter {
  organizationId?: string;
  searchTerm?: string;
  npi?: string;
}

interface ProvidersScreenProps { }

type ActiveModal = "ADD_PROVIDER" | "CREATE_PROVIDER" | "IMPORT_PROVIDERS";

export const ProvidersScreen: FC<ProvidersScreenProps> = (props) => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");

  const match = useRouteMatch();

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const closeModal = useCallback(() => setActiveModal(null), []);

  const [searchTerm, setSearchTerm] = useState("");

  const { data, refetch } = useQuery<Data, Variables>(
    ORGANIZATION_PROVIDERS,
    { variables: { first: 200 } }
  );

  const providersByLetter = groupBy(
    data?.organizationProviders.items || [],
    (prov: ProviderModel) => prov.lastName.slice(0, 1).toUpperCase()
  );

  return (
    <>
      <ScreenTitle title={["Settings", "Providers"]} />
      <AddProviderModal
        isOpen={activeModal === "ADD_PROVIDER"}
        openCreateProviderModal={() => setActiveModal("CREATE_PROVIDER")}
        onClose={closeModal}
        onSuccess={refetch}
      />
      <CreateProviderModal
        isOpen={activeModal === "CREATE_PROVIDER"}
        onClose={closeModal}
        onSuccess={refetch}
      />
      <ImportProvidersModal
        isOpen={activeModal === "IMPORT_PROVIDERS"}
        onClose={closeModal}
        onSuccess={refetch}
      />
      <div className="_ProvidersScreen flex bg-white min-h-full max-h-full overflow-y-auto">
        <aside className="flex flex-col flex-shrink-0 w-96 border-r border-gray-200">
          <div className="px-6 py-3 flex items-center justify-end gap-2">
            <Button
              type="button"
              kind="primary"
              size="xs"
              onClick={() => setActiveModal("ADD_PROVIDER")}
            >
              <span className="mr-2">
                <FAIcon icon="plus" />
              </span>
              Add Provider
            </Button>
            <Button
              type="button"
              kind="primary"
              size="xs"
              onClick={() => setActiveModal("IMPORT_PROVIDERS")}
            >
              <span className="mr-2">
                <FAIcon icon="file-upload" />
              </span>
              Import List
            </Button>
          </div>
          <div className="px-6 pt-3 pb-4">
            <h2 className="text-lg font-medium text-gray-900">
              Provider Directory
            </h2>
            <p className="mt-1 text-sm text-gray-600">
              Search directory of{" "}
              {[data?.me.organization.providerCount, "providers"]
                .filter(Boolean)
                .join(" ")}
            </p>
            <form className="mt-6 flex space-x-4" action="#">
              <div className="flex-1 min-w-0">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <InputText
                  value={searchTerm}
                  onChange={setSearchTerm}
                  icon="search"
                  inputProps={{ placeholder: "Search" }}
                />
              </div>
            </form>
          </div>

          <nav
            className="flex-1 min-h-0 overflow-y-auto"
            aria-label="Directory"
          >
            {Object.keys(providersByLetter).map((letter) => (
              <div key={letter} className="relative">
                <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                  <h3>{letter}</h3>
                </div>
                <ul className="relative z-0 divide-y divide-gray-200">
                  {providersByLetter[letter].map((provider) => (
                    <li
                      key={provider.id}
                      className={
                        pathSegments.includes(provider.id) ? "bg-blue-50" : ""
                      }
                    >
                      <div className="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500">
                        <div className="flex-shrink-0">
                          <Avatar size="thumb" image={provider.avatar} />
                        </div>
                        <div className="flex-1 min-w-0">
                          <Link
                            to={`${match.url}/${provider.id}`}
                            className="focus:outline-none"
                          >
                            {/* <!-- Extend touch target to entire panel --> */}
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            ></span>
                            <p className="text-sm font-medium text-gray-900">
                              {provider.nameWithAppellation}
                            </p>
                            <p className="text-xs text-gray-500 truncate">
                              {provider.primarySpecialty?.name}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            <div className="border-t border-cool-gray-200 text-center p-3 text-xs italic text-cool-gray-400">
              End of List
            </div>
          </nav>
        </aside>
        <Switch>
          <Route path={`${match.path}/:providerId`}>
            <ProviderShowScreen refetchProviders={refetch} />
          </Route>
          <Route path="*">
            <div className="bg-cool-gray-50 flex flex-col items-center justify-center min-h-full w-full">
              <CircleIcon icon="user-doctor" color="indigo" />
              <h4 className="font-bold pb-1 pt-3 text-cool-gray-700 text-lg">
                No Provider Selected
              </h4>
              <p className="text-cool-gray-500 text-sm">
                Select a provider from the list, or:
              </p>
              <div className="flex gap-3 py-5">
                <Button
                  type="button"
                  kind="primary"
                  size="xs"
                  onClick={() => setActiveModal("ADD_PROVIDER")}
                >
                  <span className="mr-2">
                    <FAIcon icon="plus" />
                  </span>
                  Add Provider
                </Button>
                <Button
                  type="button"
                  kind="primary"
                  size="xs"
                  onClick={() => setActiveModal("IMPORT_PROVIDERS")}
                >
                  <span className="mr-2">
                    <FAIcon icon="file-upload" />
                  </span>
                  Import List
                </Button>
              </div>
            </div>
          </Route>
        </Switch>

      </div>
    </>
  );
};
