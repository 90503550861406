import { FC, useState } from "react";
// import mixpanel from 'mixpanel-browser';
import { ScreenTitle } from "context/ScreenTitle";
import { startOfWeek, endOfWeek, addDays } from "date-fns";
import { DateRangeDropdown } from "components/DateRangeDropdown";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import { FAIcon } from "@preferral/ui";
// import { ExportsWidget } from "./ExportsWidget";
import { DepartmentSchedulingVolume } from "./reports/DepartmentSchedulingVolume";
import { TimeRange } from "./model";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { EConsultVolume } from "./reports/EConsultVolume";
import { SentReferralsVolume } from "./reports/SentReferralsVolume";
import { SentReferralsBySpecialty } from "./reports/SentReferralsBySpecialty";
import { SentReferralsByReferringLocation } from "./reports/SentReferralsByReferringLocation";
import { SentReferralsByReferringProvider } from "./reports/SentReferralsByReferringProvider";
import { SentReferralsByReceivingLocation } from "./reports/SentReferralsByReceivingLocation";
import { SentReferralsByReceivingProvider } from "./reports/SentReferralsByReceivingProvider";
import { PatientPacketSubmissionVolume } from "./reports/PatientPacketSubmissionVolume";
import { PatientPacketSubmissionsByStatus } from "./reports/PatientPacketSubmissionsByStatus";
import { PatientPacketSubmissionsByTag } from "./reports/PatientPacketSubmissionsByTag";
import { SentReferralsTimeToBook } from "./reports/SentReferralsTimeToBook";

const AnalyticsRoot: FC = () => {
  return (
    <div className="px-4 pt-24 text-center">
      <h1>Select a Report on the Left</h1>
    </div>
  );
};

const ReportLink: FC<{ to: string; icon: IconProp }> = props => {
  const { to, icon, children } = props;

  return (
    <NavLink
      to={to}
      className="px-2 py-1 transition-fast relative block hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 text-sm text-gray-600 font-medium rounded-lg"
      activeClassName="text-blue-700 bg-blue-200"
    >
      <span className="pl-2 pr-3">
        <FAIcon icon={icon} />
      </span>
      {children}
    </NavLink>
  );
};

enum Reports {
  EConsultVolume = "econsult_volume",
  PatientPacketSubmissionVolume = "patient_packet_submission_volume",
  PatientPacketSubmissionsByStatus = "patient_packet_submissions_by_status",
  PatientPacketSubmissionsByTag = "patient_packet_submissions_by_tag",
  SentReferralsVolume = "sent_referrals_volume",
  SentReferralsBySpecialty = "sent_referrals_by_specialty",
  SentReferralsByReferringLocation = "sent_referrals_by_referring_location",
  SentReferralsByReferringProvider = "sent_referrals_by_referring_provider",
  SentReferralsByReceivingLocation = "sent_referrals_by_receiving_location",
  SentReferralsByReceivingProvider = "sent_referrals_by_receiving_provider",
  DepartmentSchedulingVolume = "department_scheduling_volume",
  SentReferralsTimeToBook = "sent_referrals_time_to_book",
  // ProviderSchedulingVolume = "provider_scheduling_volume",
  // InternalUserProduction = "internal_user_production",
  // ScheduleAdherence = "schedule_adherence",
  // TimeToBook = "time_to_book",
  // BookingDistance = "booking_distance",
}

const startOfLastWeek = startOfWeek(addDays(new Date(), -7));
const endOfLastWeek = endOfWeek(addDays(new Date(), -7));

interface AnalyticsScreenProps { }

export const AnalyticsScreen: FC<AnalyticsScreenProps> = () => {
  const [timeRange, setTimeRange] = useState<TimeRange>({
    start: startOfLastWeek,
    finish: endOfLastWeek,
  });

  const match = useRouteMatch();

  const { hasFeature } = useFeatureFlags();

  return (
    <>
      <ScreenTitle title="Analytics" />
      <div className="_AnalyticsScreen flex text-left">
        <div className="_sidebar flex-1 flex-shrink-0 max-w-md pl-6 pr-3">
          <div className="_date-range pt-8">
            <h3 className="font-semibold my-4 text-cool-gray-600">
              Date Range
            </h3>
            <DateRangeDropdown value={timeRange} onChange={setTimeRange} />
          </div>

          <div className="_report-nav pt-8">
            <h3 className="font-semibold my-4 text-cool-gray-600">Reports</h3>

            <div className="border p-2 rounded-lg bg-white">
              {hasFeature("econsults") ? (
                <div className="pb-6">
                  <h4 className="font-semibold pb-2 pt-1 px-4 text-gray-600 text-xs uppercase">
                    eConsults
                  </h4>

                  <div className="flex flex-col gap-1">
                    <ReportLink
                      to={`${match.path}/${Reports.EConsultVolume}`}
                      icon="chart-line"
                    >
                      eConsult Volume
                    </ReportLink>
                  </div>
                </div>
              ) : null}

              {hasFeature("patient_forms") ? (
                <div className="pb-6">
                  <h4 className="font-semibold pb-2 pt-1 px-4 text-gray-600 text-xs uppercase">
                    Patient Forms
                  </h4>
                  <div className="flex flex-col gap-1">
                    <ReportLink
                      to={`${match.path}/${Reports.PatientPacketSubmissionVolume}`}
                      icon="chart-line"
                    >
                      Patient Packet Submissions - Volume Over Time
                    </ReportLink>

                    <ReportLink
                      to={`${match.path}/${Reports.PatientPacketSubmissionsByStatus}`}
                      icon="chart-bar"
                    >
                      Patient Packets Sent - By Status
                    </ReportLink>

                    <ReportLink
                      to={`${match.path}/${Reports.PatientPacketSubmissionsByTag}`}
                      icon="chart-bar"
                    >
                      Patient Packets Sent - By Tag
                    </ReportLink>
                  </div>
                </div>
              ) : null}

              {hasFeature("referrals") ? (
                <div className="pb-6">
                  <h4 className="font-semibold pb-2 pt-1 px-4 text-gray-600 text-xs uppercase">
                    Referral Management
                  </h4>

                  <div className="flex flex-col gap-1">
                    <ReportLink
                      to={`${match.path}/${Reports.SentReferralsVolume}`}
                      icon="chart-line"
                    >
                      Sent Referrals - Volume Over Time
                    </ReportLink>

                    <ReportLink
                      to={`${match.path}/${Reports.SentReferralsBySpecialty}`}
                      icon="chart-bar"
                    >
                      Sent Referrals - By Specialty
                    </ReportLink>

                    <ReportLink
                      to={`${match.path}/${Reports.SentReferralsByReferringLocation}`}
                      icon="chart-bar"
                    >
                      Sent Referrals - By Referring Location
                    </ReportLink>

                    <ReportLink
                      to={`${match.path}/${Reports.SentReferralsByReferringProvider}`}
                      icon="chart-bar"
                    >
                      Sent Referrals - By Referring Provider
                    </ReportLink>

                    <ReportLink
                      to={`${match.path}/${Reports.SentReferralsByReceivingLocation}`}
                      icon="chart-bar"
                    >
                      Sent Referrals - By Receiving Location
                    </ReportLink>

                    <ReportLink
                      to={`${match.path}/${Reports.SentReferralsTimeToBook}`}
                      icon="chart-bar"
                    >
                      Sent Referrals - Time to Book
                    </ReportLink>

                    <ReportLink
                      to={`${match.path}/${Reports.SentReferralsByReceivingProvider}`}
                      icon="chart-bar"
                    >
                      Sent Referrals - By Receiving Provider
                    </ReportLink>

                    <ReportLink
                      to={`${match.path}/${Reports.DepartmentSchedulingVolume}`}
                      icon="chart-line"
                    >
                      Department Scheduling Volume
                    </ReportLink>

                    {/* <ReportLink
                  // to={`${match.path}/${Reports.ProviderSchedulingVolume}`}
                  icon="chart-bar"
                >
                  Provider Scheduling Volume
                </ReportLink> */}

                    {/* <ReportLink
                  to={`${match.path}/${Reports.InternalUserProduction}`}
                  icon="chart-bar"
                >
                  User Scheduling Volume
                </ReportLink> */}

                    {/* <ReportLink
                  to={`${match.path}/${Reports.ScheduleAdherence}`}
                  icon="chart-bar"
                >
                  Schedule Adherence
                </ReportLink> */}

                    {/* <ReportLink
                  to={`${match.path}/${Reports.TimeToBook}`}
                  icon="chart-bar"
                >
                  Time to Book Appointment
                </ReportLink> */}

                    {/* <ReportLink
                  to={`${match.path}/${Reports.BookingDistance}`}
                  icon="chart-bar"
                >
                  Booking Distance
                </ReportLink> */}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {/*<div className="_exports pt-8">*/}
          {/*  <h3 className="font-semibold my-4 text-cool-gray-600">Exports</h3>*/}
          {/*  <ExportsWidget />*/}
          {/*</div>*/}
        </div>
        <div className="flex-1 py-8 pl-3 pr-6">
          <Switch>
            <Route exact path={match.path}>
              <AnalyticsRoot />
            </Route>

            <Route path={`${match.path}/${Reports.EConsultVolume}`}>
              {hasFeature("econsults") ? (
                <EConsultVolume timeRange={timeRange} />
              ) : null}
            </Route>

            <Route path={`${match.path}/${Reports.PatientPacketSubmissionVolume}`}>
              {hasFeature("patient_forms") ? (
                <PatientPacketSubmissionVolume timeRange={timeRange} />
              ) : null}
            </Route>

            <Route path={`${match.path}/${Reports.PatientPacketSubmissionsByStatus}`}>
              {hasFeature("patient_forms") ? (
                <PatientPacketSubmissionsByStatus timeRange={timeRange} />
              ) : null}
            </Route>

            <Route path={`${match.path}/${Reports.PatientPacketSubmissionsByTag}`}>
              {hasFeature("patient_forms") ? (
                <PatientPacketSubmissionsByTag timeRange={timeRange} />
              ) : null}
            </Route>

            <Route path={`${match.path}/${Reports.DepartmentSchedulingVolume}`}>
              <DepartmentSchedulingVolume timeRange={timeRange} />
            </Route>

            <Route path={`${match.path}/${Reports.SentReferralsVolume}`}>
              <SentReferralsVolume timeRange={timeRange} />
            </Route>

            <Route path={`${match.path}/${Reports.SentReferralsBySpecialty}`}>
              <SentReferralsBySpecialty timeRange={timeRange} />
            </Route>

            <Route
              path={`${match.path}/${Reports.SentReferralsByReferringLocation}`}
            >
              <SentReferralsByReferringLocation timeRange={timeRange} />
            </Route>

            <Route
              path={`${match.path}/${Reports.SentReferralsByReferringProvider}`}
            >
              <SentReferralsByReferringProvider timeRange={timeRange} />
            </Route>

            <Route
              path={`${match.path}/${Reports.SentReferralsByReceivingLocation}`}
            >
              <SentReferralsByReceivingLocation timeRange={timeRange} />
            </Route>

            <Route
              path={`${match.path}/${Reports.SentReferralsByReceivingProvider}`}
            >
              <SentReferralsByReceivingProvider timeRange={timeRange} />
            </Route>

            <Route path={`${match.path}/${Reports.SentReferralsTimeToBook}`}>
              <SentReferralsTimeToBook timeRange={timeRange} />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};
