import React, { FC } from "react";
import { DetailList, DetailItem, Spinner } from "@preferral/ui";
import { gql, useQuery } from "@apollo/client";
import parseISO from "date-fns/parseISO";
import differenceInYears from "date-fns/differenceInYears";
import { ZERO_WIDTH_SPACE } from "@preferral/common";
import { mmDdYyyy } from "lib/dateFormatters";
import {
  SelectedProvider,
  ProviderReferenceModel,
} from "components/ProviderSearchSelect";
import {
  SelectedLocation,
  LocationReferenceModel,
} from "components/ProviderLocationSelect";

const REFERRAL = gql`
  query FetchPlatformReferral($referralId: UUID4!) {
    platformReferral(id: $referralId) {
      id
      specialty {
        id
        name
      }
      referringDepartment {
        id
        name
      }
      referringProviderId
      referringLocationId
      referringPatientMember {
        id
        patient {
          id
          name
          dob
        }
        isSelfPay
        insuranceCoverages {
          id
          unlistedCarrierName
          unlistedHealthPlanName
          healthPlan {
            id
            name
            carrier {
              id
              name
            }
          }
        }
      }
    }
  }
`;

interface Data {
  platformReferral: PlatformReferralModel;
}

interface Variables {
  referralId: string;
}

interface PlatformReferralModel {
  id: string;
  specialty: {
    id: string;
    name: string;
  };
  referringDepartment: {
    id: string;
    name: string;
  };
  referringProviderId: string;
  referringLocationId: string;
  referringPatientMember: {
    id: string;
    patient: {
      id: string;
      name: string;
      dob: string;
    };
    isSelfPay: string;
    insuranceCoverages: {
      id: string;
      unlistedCarrierName?: string;
      unlistedHealthPlanName?: string;
      healthPlan?: {
        id: string;
        name: string;
        carrier: {
          id: string;
          name: string;
        };
      };
    }[];
  };
}

/**
 * Spacer.
 */

const Spacer: FC = () => {
  return <div className="m-2 lg:m-4 border-b border-cool-gray-200" />;
};

/**
 * ReferralBrief.
 */
interface ReferralBriefProps {
  referralId: string;
  receivingProviderId?: string;
  receivingLocationId?: string;
  receivingNppesProviderId?: string;
  receivingNppesLocationId?: string;
}

export const ReferralBrief: FC<ReferralBriefProps> = (props) => {
  const { referralId } = props;

  const { data, loading, error } = useQuery<Data, Variables>(REFERRAL, {
    variables: { referralId },
  });

  const referringProviderReference: ProviderReferenceModel | null = data?.platformReferral
    ? {
        kind: "platform",
        id: data.platformReferral.referringProviderId,
      }
    : null;

  const referringLocationReference: LocationReferenceModel | null = data?.platformReferral
    ? {
        kind: "platform",
        id: data.platformReferral.referringLocationId,
      }
    : null;

  return (
    <div className="_ReferralBrief">
      <div className="px-2 py-3">
        <h4 className="font-semibold text-cool-gray-700 text-lg">
          Referral Info <span className="text-cool-gray-400">(Draft)</span>
        </h4>
        <p className="font-medium text-cool-gray-600 text-xs">
          {data?.platformReferral
            ? data.platformReferral.specialty.name
            : ZERO_WIDTH_SPACE}
        </p>
      </div>
      <Spacer />
      {loading ? (
        <div className="p-4 lg:p-6 text-center">
          <Spinner />
        </div>
      ) : error || !data?.platformReferral ? (
        <p>Failed to load</p>
      ) : (
        <>
          <h5 className="font-black text-base text-cool-gray-600">Patient</h5>
          <div className="mt-3">
            <DetailList>
              <DetailItem label="Name">
                {data.platformReferral.referringPatientMember.patient.name}
              </DetailItem>
              <DetailItem label="DOB">
                {mmDdYyyy(
                  data.platformReferral.referringPatientMember.patient.dob
                )}{" "}
                <span className="text-cool-gray-400">
                  (
                  {age(
                    data.platformReferral.referringPatientMember.patient.dob
                  )}{" "}
                  y/o)
                </span>
              </DetailItem>
              {data.platformReferral.referringPatientMember.isSelfPay ? (
                <DetailItem label="Insurance">Self-Pay</DetailItem>
              ) : hasInsurance(data) ? (
                <>
                  <DetailItem label="Carrier">
                    {carrierName(data.platformReferral.referringPatientMember)}
                  </DetailItem>
                  <DetailItem label="Health Plan">
                    {healthPlanName(
                      data.platformReferral.referringPatientMember
                    )}
                  </DetailItem>
                </>
              ) : null}
            </DetailList>
          </div>

          <Spacer />
          <h5 className="font-black text-base text-cool-gray-600">
            Referring Provider
          </h5>
          <div className="mt-3">
            {referringProviderReference ? (
              <SelectedProvider provider={referringProviderReference} />
            ) : null}
          </div>

          <Spacer />
          <h5 className="font-black text-base text-cool-gray-600">
            Referring Location
          </h5>
          <div className="mt-3">
            {referringLocationReference ? (
              <SelectedLocation location={referringLocationReference} />
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

function age(isoDate: string): number {
  const date = parseISO(isoDate);
  return differenceInYears(new Date(), date);
}

function hasInsurance(data: Data): boolean {
  return !!(
    data?.platformReferral.referringPatientMember.insuranceCoverages?.length > 0
  );
}

function carrierName(patientMember: any): string {
  const coverage = patientMember.insuranceCoverages[0];
  return coverage.unlistedCarrierName || coverage.healthPlan.carrier.name;
}

function healthPlanName(patientMember: any): string {
  const coverage = patientMember.insuranceCoverages[0];
  return coverage.unlistedHealthPlanName || coverage.healthPlan.name;
}
