import { FC, useEffect, useState } from "react";
import ArrowLeftIcon from "@heroicons/react/solid/ArrowLeftIcon";
import DownloadIcon from "@heroicons/react/solid/DownloadIcon";
import UploadIcon from "@heroicons/react/solid/UploadIcon";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Avatar,
  Button,
  FAIcon,
  Spinner,
  DetailList,
  DetailItem,
  useToggle,
  CopyText,
  Tooltip,
  JsonDebugger,
} from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { useAmdSettings } from "hooks/useAmdSettings";
import { ChatBubble } from "components/ChatBubble";
import { HtmlSanitizer } from "components/HtmlSanitizer";
import { TrailingGradient } from "components/TrailingGradient";
import { distanceInWords, hMmA, mmDdYy, mmDdYyyy } from "lib/dateFormatters";
import { StatusBadge } from "screens/EConsultsScreen/StatusBadge";
import { FinalizeEConsultModal } from "./FinalizeEConsultModal";
import { MarkUploadedToEhrModal } from "./MarkUploadedToEhrModal";
import { RerouteEConsultModal } from "./RerouteEConsultModal";
import { FollowUpCommentBox } from "./FollowUpCommentBox";
import toast from "react-hot-toast";
import {
  CommentModel,
  DispositionComment,
  DispositionHistoryModel,
  EconsultModel,
  EconsultPDFData,
  PanelistModel,
  RoleModel,
} from "./model";
import { PANELIST_DETAIL_FRAGMENT } from "./fragments";
import { analytics } from "lib/analytics";
import { useStartReferral } from "./useStartReferral";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";

const GET_ECONSULT = gql`
  query GetEConsult($id: Int!) {
    me {
      id
      aristamdProviderId
      firstName
      lastName
    }
    econsult(id: $id) {
      id
      organizationId
      responseDate
      createdAt
      updatedAt
      requestDate
      source
      status
      statusSet
      additionalDetails
      disclaimer {
        id
        text
      }
      assets {
        id
        url
        name
        uploadedBy {
          id
          name
        }
      }
      comments {
        id
        comment
        type
        createdAt
        user {
          id
          name
          roles {
            id
            displayName
          }
          panelistDetail {
            ...PanelistDetailFields
          }
        }
        assets {
          id
          url
          name
        }
      }
      panelist {
        ...PanelistDetailFields
      }
      patient {
        id
        name
        firstName
        lastName
        gender
        dateOfBirth
        systemOfRecord
        referenceId
        coverage {
          id
          insurancePlanName
          insuranceProviderName
          memberId
          updatedAt
        }
      }
      requester {
        id
        name
        roles {
          id
          displayName
        }
      }
      requestingPhysician {
        id
        name
      }
      specialty {
        id
        displayName
      }
      assessmentAnswer {
        answers
      }
      answer {
        actionsPatient
        actionsPcp
        additionalDiagnostics
        appropriateness
        dispositionDate
        dispositionId
        faceRecommendationSupport
        followUp
        infoRequired
        initials
        recommendation
        recommendedSpecialty
        saved
        visitUrgency
      }
      dispositionHistory {
        comment
        appropriateness
        dispositionDate
        reroutedAt
        fromSpecialty {
          displayName
        }
        toSpecialty {
          displayName
        }
        panelist {
          ...PanelistDetailFields
        }
        dispositionAttachments {
          id
          name
          url
        }
      }
    }
  }
  ${PANELIST_DETAIL_FRAGMENT}
`;

const GET_ECONSULT_PDF = gql`
  query GetEconsultPDF($id: Int!) {
    econsultPdf(id: $id) {
      data
    }
  }
`;

interface Data {
  me: {
    id: string;
    aristamdProviderId: string;
    firstName: string;
    lastName: string;
  };
  econsult: EconsultModel;
}

interface Variables {
  id: number;
}

interface RouteParams {
  id: string;
}

const MESSAGES = {
  follow_up: "Requester has a follow-up question on your consult",
  ok_to_treat: "Treatment options available at the primary care level",
  wrong_specialty: "Different specialty area recommended",
  pending_requester_feedback: "Please provide additional information.",
  pending_specialist_review: "Pending specialist review",
  face_to_face: "In-person visit with specialist recommended",
};

/**
 * ActionPanel.
 */

interface ActionPanelProps {
  isOpen: boolean;
}

const ActionPanel: FC<ActionPanelProps> = props => {
  const { isOpen, children } = props;

  const cn = [
    "_ActionPanel absolute z-10 bottom-0 inset-x-0 transform transition duration-150 flex items-center justify-center pb-4",
    isOpen ? "translate-y-0 opacity-1" : "translate-y-32 opacity-0",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={cn}>
      <div className="rounded-full flex items-center gap-x-2 bg-white shadow-2xl border-2 border-blue-400 px-8 py-3">
        {children}
      </div>
    </div>
  );
};

/**
 * EConsultComment.
 */

interface EConsultCommentProps {
  comment: CommentModel;
}

const EConsultComment: FC<EConsultCommentProps> = props => {
  const { comment } = props;
  const roleHeader = isSpecialist(comment.user)
    ? getPanelistSpecialtyNames(comment.user.panelistDetail)
    : roleDisplayName(comment.user.roles) || null;

  return (
    <ChatBubble
      sent={!isSpecialist(comment.user)}
      last
      className={`text-sm mt-3 mx-6 max-w-xl text-left ${
        !isSpecialist(comment.user) ? "self-end" : ""
      }`}
    >
      <div className="flex justify-between">
        <div>
          <p className="font-semibold text-sm">{comment.user.name}</p>
          <p className="text-gray-600 text-xs">{roleHeader}</p>
        </div>
        <div className="group text-xs">
          <p className="text-gray-500 group-hover:hidden">
            {distanceInWords(comment.createdAt)} ago
          </p>
          <p className="text-gray-500 hidden group-hover:block">
            {mmDdYyyy(comment.createdAt)} {hMmA(comment.createdAt)}
          </p>
        </div>
      </div>
      <div className="pt-2 mb-4">
        <span className="font-semibold mr-2">Comment:</span>
        {isHTML(comment.comment) ? (
          <div className="prose pt-2">
            <HtmlSanitizer html={comment.comment} />
          </div>
        ) : (
          <p>{comment.comment}</p>
        )}
      </div>
      {comment.assets.length !== 0 && (
        <div className="pt-2 flex">
          <p className="font-semibold mr-2 flex-grow-0">Attachments:</p>
          <div className="_AttachmentsList flex-grow">
            {comment.assets.map((obj, idx) => (
              <a
                key={obj.url}
                target="_blank"
                rel="noref noreferrer nofollow"
                href={obj.url}
                className={`_Attachment ${
                  idx === 0 ? "mt-0" : "mt-1"
                } inline-block whitespace-no-wrap truncate bg-indigo-50 border border-indigo-400 cursor-pointer duration-150 font-semibold hover:bg-indigo-100 px-2 py-1 rounded shadow text-indigo-700 transition-colors underline`}
              >
                <FAIcon icon="file-alt" className="mr-2" />
                {obj.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </ChatBubble>
  );
};

interface EConsultAnswerProps {
  disposition: DispositionComment;
  econsult: EconsultModel;
}

const EConsultAnswer: FC<EConsultAnswerProps> = props => {
  const { disposition, econsult } = props;
  return (
    <ChatBubble last className="text-sm mt-3 mx-6 max-w-xl text-left">
      <div className="_MessageHeader flex justify-between">
        <div>
          <p className="font-semibold text-sm">
            {getPanelistName(disposition.user.panelistDetail)}
          </p>
          <p className="text-gray-600 text-xs">
            {getPanelistSpecialtyNames(disposition.user.panelistDetail)}
          </p>
        </div>
        <div className="group text-xs">
          <p className="text-gray-500 group-hover:hidden">
            {distanceInWords(disposition.createdAt)} ago
          </p>
          <p className="text-gray-500 hidden group-hover:block">
            {mmDdYyyy(disposition.createdAt)} {hMmA(disposition.createdAt)}
          </p>
        </div>
      </div>

      {disposition.appropriateness === "wrong_specialty" && (
        <div className="_">
          <p className="pt-2">
            <span className="font-semibold mr-2">Requested Specialty:</span>
            {disposition.oldSpecialty}
          </p>
          <p>
            <span className="font-semibold mr-2">Recommended Specialty:</span>
            {disposition.newSpecialty}
          </p>
        </div>
      )}

      <div className="pt-2">
        <span className="font-semibold mr-2">Recommendation & Rationale:</span>
        {isHTML(disposition.comment) ? (
          <div className="prose">
            <HtmlSanitizer html={disposition.comment} />
          </div>
        ) : (
          <p>{disposition.comment}</p>
        )}
      </div>

      {disposition.appropriateness === "face_to_face" &&
        econsult.answer?.visitUrgency && (
          <p className="mt-2">
            <span className="font-semibold mr-2">
              Recommended Visit Urgency:
            </span>
            {capitalize(econsult.answer.visitUrgency)}
          </p>
        )}
      <EConsultSignatureAndDisclaimer econsult={econsult} />
    </ChatBubble>
  );
};

/**
 * EConsultSignatureAndDisclaimer.
 */

interface EConsultSignatureAndDisclaimerProps {
  econsult: EconsultModel;
}

const EConsultSignatureAndDisclaimer: FC<EConsultSignatureAndDisclaimerProps> =
  props => {
    const [showDisclaimer, toggleShowDisclaimer] = useToggle(false);
    const { econsult } = props;
    const panelistName = getPanelistName(econsult.panelist);

    return (
      <>
        <div
          className="mt-2 cursor-pointer text-xs font-semibold italic text-teal-700"
          role="button"
          onClick={toggleShowDisclaimer}
        >
          Signature and Disclosure
          <FAIcon className="ml-2" icon="chevron-down" />
        </div>

        {showDisclaimer ? (
          <div className="_signature mt-4">
            <p className="font-bold font-cursive italic text-lg">
              {panelistName}
            </p>
            <div className="border-l-4 mt-1 pl-3">
              <p className="font-medium text-gray-700 text-sm">
                {panelistName}
              </p>
              <p className="font-light text-xs">
                {getPanelistSpecialtyNames(econsult.panelist)}
              </p>
            </div>
            {econsult.disclaimer ? (
              <p className="mt-4 text-xs">
                <span className="font-semibold mr-2 block">Disclosure:</span>
                {econsult.disclaimer.text}
              </p>
            ) : null}
          </div>
        ) : null}
      </>
    );
  };

/**
 * EConsultShowScreen.
 */

interface EConsultShowScreenProps {}

export const EConsultShowScreen: FC<EConsultShowScreenProps> = () => {
  const { id } = useParams<RouteParams>();
  const eConsultId = +id;
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { hasFeature } = useAmdSettings();
  const { hasFeature: hasFeatureFlag } = useFeatureFlags();

  const [activeModal, setActiveModal] = useState<
    | "FINALIZE_FORM"
    | "REROUTE_FORM"
    | "COMMENTS_FORM"
    | "MARK_AS_UPLOADED_TO_EHR_MODAL"
    | null
  >(null);

  const closeModal = () => setActiveModal(null);

  const { loading, data, error, refetch } = useQuery<Data, Variables>(
    GET_ECONSULT,
    {
      variables: { id: eConsultId },
    }
  );

  const [downloadEconsult, { loading: loadingEconsultPDF }] = useLazyQuery<
    EconsultPDFData,
    Variables
  >(GET_ECONSULT_PDF, {
    variables: { id: +id },
    onCompleted: data => {
      if (!data) {
        console.error("PDF response is blank");
        toast.error("PDF Generation Failed.");
      } else if (!data.econsultPdf.data) {
        console.error("PDF is blank");
        toast.error("PDF Generation Failed.");
      } else {
        fetch(data.econsultPdf.data)
          .then(res => res.blob())
          .then(blob => {
            const a = document.createElement("a");
            document.body.appendChild(a);
            const blobUrl = window.URL.createObjectURL(blob);
            a.href = blobUrl;
            a.download = `${eConsultId}.pdf`;
            a.click();
            setTimeout(() => {
              window.URL.revokeObjectURL(blobUrl);
              document.body.removeChild(a);
            }, 0);
          });
      }
    },
    onError: err => {
      console.error(err);
      toast.error("Error requesting PDF.");
    },
  });

  const { startReferral, loading: startReferralLoading } = useStartReferral();

  const onDownloadEconsult = () => {
    analytics.track("Download eConsult PDF", {
      finalized: data?.econsult.statusSet === "finalized",
    });
    downloadEconsult();
  };

  const isFinalized = data?.econsult.statusSet === "finalized";
  const canBeRerouted = ["wrong_specialty", "econsult_reassigned"].includes(
    data?.econsult.status || ""
  );
  const canBeFinalized = data?.econsult.statusSet === "ready_to_review";

  const canFollowUp = [
    "ok_to_treat",
    "face_to_face",
    "wrong_specialty",
    "econsult_reassigned",
    "pending_requester_feedback",
  ].includes(data?.econsult.status || "");

  const additionalDetails = data?.econsult.additionalDetails;

  const panelistName = getPanelistName(data?.econsult.panelist || null);

  const conversation = data?.econsult ? buildConversation(data.econsult) : [];

  // for requesters actions should be enabled
  // for provider just if the econsult belongs to you
  const isNotAssignedProvider =
    data?.me.aristamdProviderId !== null
      ? data?.me.aristamdProviderId !== data?.econsult.requestingPhysician.id
      : false;

  useEffect(() => {
    if (data) {
      analytics.track("Viewed eConsult", {
        page_url: window.location.href,
        specialty_name: data.econsult.specialty?.displayName,
        chief_complaint: additionalDetails?.diagnosis?.display_name,
      });
    }
  }, [data]);

  return (
    <>
      <ScreenTitle title={["eConsults", "View eConsult"]} />

      <FinalizeEConsultModal
        isOpen={activeModal === "FINALIZE_FORM"}
        onClose={closeModal}
        eConsultId={eConsultId}
        onSuccess={() => {
          refetch();
          closeModal();
        }}
      />

      <MarkUploadedToEhrModal
        eConsultId={eConsultId}
        isOpen={activeModal === "MARK_AS_UPLOADED_TO_EHR_MODAL"}
        onClose={closeModal}
        onSuccess={() => {
          refetch();
          closeModal();
        }}
      />

      {!!data?.econsult.answer?.recommendedSpecialty ? (
        <RerouteEConsultModal
          isOpen={activeModal === "REROUTE_FORM"}
          onClose={closeModal}
          eConsultId={eConsultId}
          recommendedSpecialtyCode={data.econsult.answer.recommendedSpecialty}
          currentSpecialtyName={data.econsult.specialty.displayName}
          onSuccess={() => {
            analytics.track("Rerouted eConsult", {
              econsult_id: eConsultId,
              previousSpecialty: data?.econsult?.answer?.recommendedSpecialty,
              recommendedSpecialty: data.econsult.specialty.displayName,
            });
            refetch();
            closeModal();
          }}
        />
      ) : null}

      <div className="_EConsultShowScreen h-full overflow-hidden">
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.econsult ? (
          <p>Failed to load</p>
        ) : (
          <div className="h-full flex">
            <aside
              className={`w-1/3 flex-grow-0 flex-shrink-0 bg-white border-r border-gray-300 ${
                isDetailsOpen ? "sm:block" : "hidden"
              } md:block`}
            >
              <div className="h-full w-full overflow-y-scroll">
                <div className="mt-3">
                  <Link
                    to="/o/econsults"
                    className="inline-flex items-center border-b border-blue-500 border-dashed font-medium text-blue-700"
                  >
                    <ArrowLeftIcon className="h-4 w-4 mr-2" />
                    Back to eConsults
                  </Link>
                  {data?.econsult ? (
                    <>
                      <section className="_specialist-section px-8 pt-4 pb-8">
                        {data.econsult.panelist.profilePhotoSrc ? (
                          <Avatar
                            size="md"
                            className="border-2 border-indigo-400"
                            image={{
                              src: data.econsult.panelist.profilePhotoSrc,
                            }}
                          />
                        ) : (
                          <div className="align-middle bg-indigo-100 border-2 border-indigo-400 flex flex-col h-20 justify-center mt-2 mx-auto rounded-full shadow-inner text-xl sm:text-2xl lg:text-4xl text-indigo-800 w-20">
                            <span className="tracking-wider">
                              {data.econsult.panelist.user.name.slice(0, 1)}
                            </span>
                          </div>
                        )}

                        <div>
                          <p className="mt-3 text-lg text-gray-900 text-center font-semibold">
                            {panelistName}
                          </p>
                          <p className="mt-2 text-sm text-gray-900 text-center font-bold">
                            {getPanelistSpecialtyNames(data.econsult.panelist)}
                          </p>
                          <p className="mt-1 text-xs text-gray-900 text-center">
                            {data.econsult.panelist.notes}
                          </p>
                        </div>
                      </section>

                      <section
                        id="summary-info-section"
                        className="px-8 pt-4 pb-8 border-t border-gray-300 text-left"
                      >
                        <div className="flex justify-between">
                          <h2 className="font-semibold text-indigo-700">
                            Summary:
                          </h2>
                        </div>
                        <DetailList className="mt-4">
                          <DetailItem label="Status">
                            <StatusBadge eConsult={data.econsult} />
                          </DetailItem>
                          {data.econsult.answer ? (
                            <>
                              <DetailItem label="Disposition">
                                <p className="text-base">
                                  {
                                    MESSAGES[
                                      data.econsult.answer.appropriateness
                                    ]
                                  }
                                </p>
                              </DetailItem>
                              {data.econsult.status === "face_to_face" &&
                                data.econsult.answer?.visitUrgency && (
                                  <DetailItem label="Visit Urgency">
                                    <p className="text-base">
                                      {capitalize(
                                        data.econsult.answer?.visitUrgency
                                      )}
                                    </p>
                                  </DetailItem>
                                )}
                            </>
                          ) : null}
                          <DetailItem label="Date Requested">
                            <span className="text-base">
                              {mmDdYy(data.econsult.requestDate)}
                            </span>
                          </DetailItem>
                          <DetailItem label="Requested by">
                            <span className="text-base">
                              {data.econsult.requestingPhysician.name}
                            </span>
                          </DetailItem>
                          <DetailItem label="Request ID">
                            <span className="text-base">
                              <CopyText text={`C${data.econsult.id}`}>
                                C{data.econsult.id}
                              </CopyText>
                            </span>
                          </DetailItem>
                        </DetailList>
                        {process.env.NODE_ENV !== "production" ? (
                          <JsonDebugger data={data} />
                        ) : null}
                      </section>
                      <section
                        id="patient-info-section"
                        className="px-8 pt-4 pb-8 border-t border-gray-300 text-left"
                      >
                        <p className="font-semibold text-indigo-700">
                          About This Patient:
                        </p>
                        <DetailList className="mt-4">
                          <DetailItem label="Patient Name">
                            <span className="text-base">
                              {data.econsult.patient.name}
                            </span>
                          </DetailItem>
                          <DetailItem label="Date of Birth">
                            <span className="text-base">
                              {mmDdYyyy(data.econsult.patient.dateOfBirth)}
                            </span>
                          </DetailItem>
                          <DetailItem label="Gender">
                            <span className="text-base">
                              {data.econsult.patient.gender}
                            </span>
                          </DetailItem>
                        </DetailList>
                      </section>
                      {/* the coverage is always coming with updated_at or id so coverage is never going to be empty */}
                      {data.econsult.patient.coverage?.insuranceProviderName ||
                      data.econsult.patient.coverage?.memberId ? (
                        <section
                          id="patient-primary-insurance"
                          className="px-8 pt-4 pb-8 border-t border-gray-300 text-left"
                        >
                          <div className="flex justify-between">
                            <p className="font-semibold text-indigo-700">
                              Patient's Insurance:
                            </p>
                          </div>
                          <DetailList className="mt-4">
                            {data.econsult.patient.coverage
                              .insuranceProviderName && (
                              <DetailItem label="Carrier">
                                <span className="text-base ">
                                  {
                                    data.econsult.patient.coverage
                                      .insuranceProviderName
                                  }
                                </span>
                              </DetailItem>
                            )}
                            {data.econsult.patient.coverage.memberId && (
                              <DetailItem label="Membership #">
                                <span className="text-base">
                                  {data.econsult.patient.coverage.memberId}
                                </span>
                              </DetailItem>
                            )}
                            <DetailItem label="Updated at">
                              <span className="text-base">
                                {mmDdYyyy(
                                  data.econsult.patient.coverage.updatedAt
                                )}
                              </span>
                            </DetailItem>
                          </DetailList>
                        </section>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            </aside>
            <main
              className="_RightSide relative flex flex-col items-center h-full max-h-full w-full overflow-hidden"
              style={{ transform: "translateZ(0)" }}
            >
              <TrailingGradient />

              <FollowUpCommentBox
                currentUser={{
                  firstName: data.me.firstName,
                  lastName: data.me.lastName,
                }}
                eConsultId={eConsultId}
                isOpen={activeModal === "COMMENTS_FORM"}
                onClose={closeModal}
                onSuccess={() => {
                  analytics.track("Ask Follow Up", {
                    eConsult_id: data.econsult.id,
                    finalized: data.econsult.statusSet === "finalized",
                  });
                  refetch();
                  closeModal();
                }}
              />

              <ActionPanel isOpen={!activeModal}>
                <>
                  {data.econsult.additionalDetails?.uploaded_to_ehr === false &&
                    hasFeature("manual_ehr_notification_tracking") && (
                      <Tooltip
                        color="purple"
                        tip={
                          isNotAssignedProvider &&
                          "Only the requesting physician can interact with this eConsult."
                        }
                      >
                        <Button
                          disabled={isNotAssignedProvider}
                          className="mr-2"
                          size="sm"
                          kind="primary"
                          color="blue"
                          onClick={() =>
                            setActiveModal("MARK_AS_UPLOADED_TO_EHR_MODAL")
                          }
                        >
                          <UploadIcon className="w-4 h-4 mr-2" />
                          Mark as Uploaded to EHR
                        </Button>
                      </Tooltip>
                    )}

                  {!isFinalized ? (
                    <Tooltip
                      color="purple"
                      tip={
                        (isNotAssignedProvider &&
                          "Only the requesting physician can interact with this eConsult." ||
                          !canBeFinalized &&
                          !isNotAssignedProvider &&
                          "Awaiting specialist response")
                      }
                    >
                      <Button
                        disabled={!canFollowUp || isNotAssignedProvider}
                        className="mr-2"
                        size="sm"
                        kind="primary"
                        color="blue"
                        onClick={() => setActiveModal("COMMENTS_FORM")}
                      >
                        <FAIcon icon="reply" className="mr-3" />
                        {data.econsult?.answer?.appropriateness ===
                          "pending_requester_feedback" ? (
                          <span> Provide Follow Up</span>
                        ) : (
                          <span> Ask Follow Up</span>
                        )}
                      </Button>
                    </Tooltip>
                  ) : null}

                  {canBeRerouted ? (
                    <Tooltip
                      color="purple"
                      tip={
                        isNotAssignedProvider &&
                        "Only the requesting physician can interact with this eConsult."
                      }
                    >
                      <Button
                        disabled={isNotAssignedProvider}
                        className="mr-2"
                        size="sm"
                        kind="primary"
                        color="blue"
                        onClick={() => setActiveModal("REROUTE_FORM")}
                      >
                        <FAIcon
                          icon="arrows-split-up-and-left"
                          className="mr-3"
                        />
                        Reroute
                      </Button>
                    </Tooltip>
                  ) : null}

                  {!isFinalized || canBeRerouted ? (
                    <div className="_Spacer inline-block align-top border-gray-400 border-l h-8 mx-4" />
                  ) : null}

                  {isFinalized ? (
                    <>
                      <Button
                        disabled
                        className="mr-2"
                        size="sm"
                        color="green"
                        kind="secondary"
                      >
                        <FAIcon icon="check-double" className="mr-3" />
                        Already Finalized
                      </Button>
                    </>
                  ) : (
                    <Tooltip
                      color="purple"
                      tip={
                        (isNotAssignedProvider &&
                          "Only the requesting physician can interact with this eConsult." ||
                          !canBeFinalized &&
                          !isNotAssignedProvider &&
                          "Awaiting specialist response")
                      }
                    >
                      <Button
                        disabled={!canBeFinalized || isNotAssignedProvider}
                        className="mr-2"
                        size="sm"
                        kind="primary"
                        color="blue"
                        onClick={() => setActiveModal("FINALIZE_FORM")}
                      >
                        <FAIcon icon="check-double" className="mr-3" />
                        Finalize
                      </Button>
                    </Tooltip>
                  )}
                  {hasFeatureFlag("referrals") ? (
                    <Tooltip
                      color="purple"
                      tip={
                        isNotAssignedProvider &&
                        "Only the requesting physician can interact with this eConsult."
                      }
                    >
                      <Button
                        disabled={
                          startReferralLoading || isNotAssignedProvider
                        }
                        size="sm"
                        kind="primary"
                        color="blue"
                        onClick={() => startReferral(data.econsult.patient.id)}
                        isLoading={startReferralLoading}
                      >
                        <FAIcon icon="paper-plane" className="mr-3" />
                        Refer to F2F
                      </Button>
                    </Tooltip>
                  ) : null}
                </>
              </ActionPanel>

              <div className="_StaticBar bg-white border flex items-center justify-between gap-2 px-6 py-3 shadow w-full">
                <button
                  className={`md:hidden float-left inline-block text-indigo-700 hover:bg-purple-50 text-sm mt-1
                  ${isDetailsOpen ? "flex flex-row-reverse items-center" : ""}`}
                  onClick={() => {
                    setIsDetailsOpen(!isDetailsOpen);
                  }}
                >
                  {isDetailsOpen ? "Hide Details" : "Show Details"}
                  <FAIcon
                    className="mx-2"
                    icon={isDetailsOpen ? faChevronLeft : faChevronRight}
                  />
                </button>

                <Button
                  className="flex items-center justify-center"
                  type="button"
                  size="xs"
                  color="purple"
                  kind="secondary"
                  onClick={() => onDownloadEconsult()}
                  isLoading={loadingEconsultPDF}
                >
                  <DownloadIcon className="h-4 w-4 mr-2" />
                  Download PDF
                </Button>
              </div>
              <div
                className="_ConsultChat max-w-7xl flex-1 bg-gray-50 flex flex-col border-l border-r shadow-inner w-full h-full overflow-y-scroll "
                style={{ paddingBottom: "16rem" }}
              >
                {/* PCP Submission */}
                <ChatBubble
                  sent
                  last
                  className="text-sm mt-3 mx-6 max-w-xl self-end text-left"
                >
                  <div className="flex justify-between">
                    <div>
                      <p className="font-semibold text-sm">
                        {data.econsult.requester.name}
                      </p>
                      <p className="text-gray-600 text-xs">
                        {roleDisplayName(data.econsult.requester.roles) || null}
                      </p>
                    </div>
                    <div className="group text-xs">
                      <p className="text-gray-500 group-hover:hidden">
                        {distanceInWords(additionalDetails!.first_request_date)}{" "}
                        ago
                      </p>
                      <p className="text-gray-500 hidden group-hover:block">
                        {mmDdYy(additionalDetails!.first_request_date)}{" "}
                        {hMmA(additionalDetails!.first_request_date)}
                      </p>
                    </div>
                  </div>
                  <p className="pt-2">
                    <span className="font-semibold mr-2">Specialty:</span>
                    {data.econsult.specialty.displayName}
                  </p>
                  <p className="pt-2">
                    <span className="font-semibold mr-2">Chief Complaint:</span>
                    {additionalDetails!.diagnosis.display_name}
                    {additionalDetails!.diagnosis.details ? (
                      <span> - {additionalDetails!.diagnosis.details}</span>
                    ) : null}
                  </p>
                  <div className="pt-2 mb-4">
                    <span className="font-semibold mr-2">
                      Clinical Question and Supporting Details:
                    </span>
                    {isHTML(additionalDetails!.main_question) ? (
                      <div className="prose pt-2">
                        <HtmlSanitizer
                          html={additionalDetails!.main_question}
                        />
                      </div>
                    ) : (
                      <p>{additionalDetails!.main_question}</p>
                    )}
                  </div>
                  <div className="pt-2 flex">
                    <p className="font-semibold mr-2 flex-grow-0">
                      Attachments:
                    </p>
                    <div className="_AttachmentsList flex-grow">
                      {data.econsult.assets.map((obj, idx) => (
                        <a
                          key={obj.url}
                          target="_blank"
                          rel="noref noreferrer nofollow"
                          href={obj.url}
                          className={`_Attachment ${
                            idx === 0 ? "mt-0" : "mt-1"
                          } inline-block whitespace-no-wrap truncate bg-indigo-50 border border-indigo-400 cursor-pointer duration-150 font-semibold hover:bg-indigo-100 px-2 py-1 rounded shadow text-indigo-700 transition-colors underline`}
                        >
                          <FAIcon icon="file-alt" className="mr-2" />
                          {obj.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </ChatBubble>

                {/* Comments and Dispositions sorted by creation date */}
                {conversation.map((obj: CommentModel | DispositionComment) =>
                  obj.type === "disposition" ? (
                    <EConsultAnswer
                      disposition={obj as DispositionComment}
                      key={obj.id || obj.createdAt}
                      econsult={data.econsult}
                    />
                  ) : (
                    <EConsultComment
                      key={obj.id || obj.createdAt}
                      comment={obj as CommentModel}
                    />
                  )
                )}
              </div>
            </main>
          </div>
        )}
      </div>
    </>
  );
};

function capitalize(str: string) {
  return str.replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
}

function isSpecialist(user: {
  roles: Array<{ displayName: string }>;
}): boolean {
  return user.roles.map(r => r.displayName).includes("Specialist");
}

function roleDisplayName(roles: Array<{ displayName: string }>): string | void {
  const displayName = roles.find(
    obj =>
      obj.displayName === "Provider" ||
      obj.displayName === "RNN Member" ||
      obj.displayName === "Specialist"
  )?.displayName;

  return displayName === "RNN Member"
    ? "Referral Nurse Navigator"
    : displayName || (roles[0] && roles[0].displayName);
}

function isHTML(content: string) {
  // Good enough...
  return content.startsWith("<");
}

function buildConversation(econsult: EconsultModel): CommentModel[] {
  // Define a function that turns disposition history record into a DispositionComment.
  function asComment(disposition: DispositionHistoryModel): DispositionComment {
    let comment: DispositionComment = {
      type: "disposition",
      id: "",
      createdAt: disposition.dispositionDate || "",
      comment: disposition.comment,
      assets: disposition.dispositionAttachments || [],
      appropriateness: disposition.appropriateness,
      user: {
        id: disposition.panelist.user.id,
        name: disposition.panelist.user.name,
        roles: [{ id: "", displayName: "Specialist" }],
        panelistDetail: disposition.panelist,
      },
    };

    if (disposition.appropriateness === "wrong_specialty") {
      comment.oldSpecialty = disposition.fromSpecialty.displayName;
      comment.newSpecialty = disposition.toSpecialty.displayName;
    }

    return comment;
  }

  // Map disposition history into an array of DispositionComments.
  const dispositionHistoryComments = (econsult.dispositionHistory || [])
    .filter(d => d.appropriateness !== "wrong_specialty" || !!d.reroutedAt)
    .map(asComment);

  // Create a DispositionComment for the current econsult answer, if present.
  let answerComment: DispositionComment | null = econsult.answer
    ?.dispositionDate
    ? {
        type: "disposition",
        id: "",
        user: {
          id: econsult.panelist.user.id,
          name: econsult.panelist.user.name,
          roles: [{ id: "", displayName: "Specialist" } as RoleModel],
          panelistDetail: econsult.panelist,
        },
        createdAt: econsult.answer.dispositionDate || "",
        comment: econsult.answer.recommendation,
        assets: [],
        appropriateness: econsult.answer.appropriateness,
      }
    : null;

  if (econsult.answer?.appropriateness === "wrong_specialty") {
    answerComment!.oldSpecialty = econsult.specialty.displayName;
    answerComment!.newSpecialty = capitalize(
      econsult.answer.recommendedSpecialty.replaceAll("_", " ")
    );
  }

  return (
    [
      ...dispositionHistoryComments,
      answerComment!,
      ...(econsult.comments || []),
    ]
      .filter(Boolean)
      // NB: We can sort without converting to Date because ISO8601 timestamps are lexicographically ordered
      .sort((a, b) =>
        a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0
      )
  );
}

function getPanelistSpecialtyNames(
  panelistDetail: PanelistModel | null | undefined
): string {
  return (
    panelistDetail?.specialties
      .map(obj => obj.displayName)
      .filter(Boolean)
      .join(", ") || ""
  );
}

function getPanelistName(
  panelistDetail: PanelistModel | null | undefined
): string {
  return (
    [panelistDetail?.user.name, panelistDetail?.suffix]
      .filter(Boolean)
      .join(", ") || ""
  );
}
