import { format } from "lib/dateFormatters";
import { TimeSeriesDatum } from "./model";

export function transformTimeSeries(data: TimeSeriesDatum[]) {
  const p = data.map(d => {
    let datum = { datetime: format(d.timespan, "M/d/yy h:mm a") };
    d.metrics.forEach(m => {
      datum[m.metric] = m.value;
    });
    return datum;
  });
  return p;
}
