import { FC } from "react";
import { FilterForm, FilterField, SelectInput, Button } from "@preferral/ui";
import { gql, useQuery } from "@apollo/client";

const LIST_PATIENT_FORMS = gql`
  query ListPatientForms {
    patientForms {
      endOfList
      cursor
      items {
        id
        title
      }
    }
  }
`;

interface Data {
  patientForms: Paginated<PatientForm>;
}

interface PatientForm {
  id: string;
  title: string;
}

export interface FilterModel {
  patientFormId?: string;
}

export const defaultFilter: FilterModel = {
  patientFormId: "ALL",
};

const allOption = {
  value: "ALL",
  label: "All",
};

interface FilterPanelProps {
  value: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading: boolean;
}

export const FilterPanel: FC<FilterPanelProps> = (props) => {
  const { value = defaultFilter, onChange, isLoading } = props;

  const { data, loading } = useQuery<Data>(LIST_PATIENT_FORMS, {
    variables: { filter: value },
  });

  const patientFormOptions = [
    allOption,
    ...(data?.patientForms.items.map((pf) => ({
      value: pf.id,
      label: pf.title,
    })) || []),
  ];

  return (
    <FilterForm<FilterModel>
      defaultValue={defaultFilter}
      value={value}
      onChange={onChange}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="patientFormId" icon="filter" label="Form">
          <div className="w-64">
            <SelectInput
              name="patientFormId"
              options={patientFormOptions}
              isLoading={loading}
            />
          </div>
        </FilterField>

        <FilterField>
          <Button
            type="submit"
            color="blue"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Filter
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};
