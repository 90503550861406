import { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import ArchiveIcon from '@heroicons/react/outline/ArchiveIcon';
import { ConfirmationModal } from "components/ConfirmationModal";
import { useAppointmentRequest } from "../../AppointmentRequestContext";

const EXHAUST_REQUEST = gql`
  mutation ExhaustRequest($appointmentRequestId: UUID4!) {
    exhaustAppointmentRequest(appointmentRequestId: $appointmentRequestId) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
      }
    }
  }
`;

interface Data {
  exhaustAppointmentRequest: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
    };
  };
}

interface Variables {
  appointmentRequestId: string;
}

interface ExhaustRequestModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const ExhaustRequestModal: FC<ExhaustRequestModalProps> = (props) => {
  const { isOpen, onClose } = props;

  const {
    appointmentRequest: { id },
    refetch,
  } = useAppointmentRequest();
  const [exhaustRequest] = useMutation<Data, Variables>(EXHAUST_REQUEST);

  const onSubmit = useCallback(() => {
    return exhaustRequest({ variables: { appointmentRequestId: id } }).then(
      (resp) => {
        if (resp.data?.exhaustAppointmentRequest.errors) {
          toast.error(resp.data.exhaustAppointmentRequest.errors[0].message);
        } else if (resp.data?.exhaustAppointmentRequest.appointmentRequest) {
          // it worked...
          toast.success("Request marked as exhausted");
          refetch();
        }
      }
    );
  }, [id, exhaustRequest, refetch]);

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      color="red"
      icon={ArchiveIcon}
      title="Exhaust Request"
      prompt="Continue"
      onSubmit={onSubmit}
    >
      <p className="text-sm text-gray-500">
        Are you sure you wish to mark this referral as exhausted?
      </p>
    </ConfirmationModal>
  );
};
