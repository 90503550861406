import { FC, useCallback, useState, useMemo } from "react";
import {
  Button,
  DateMaskField,
  FadeUpIn,
  FAIcon,
  HorizontalTextField,
  RadioGroupInput,
  SelectField,
  Spinner,
  TextField,
  TextMaskField,
} from "@preferral/ui";
import {
  localDateToISO,
  ISODateToLocal,
  removeVoidKeys,
} from "@preferral/common";
import * as Yup from "yup";
import { gql, useMutation } from "@apollo/client";
import { ScreenTitle } from "context/ScreenTitle";
import { SelectedPatientMember } from "screens/NewInboundReferralScreen/PatientStep/SelectedPatientMember";
import { CircleIconHeader } from "components/CircleIconHeader";
import { FieldArray, FormikHelpers, useFormik, FormikProvider } from "formik";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { HorizontalHealthPlanPickerField } from "components/formik/HealthPlanPickerField";
import { PatientSearchTextField } from "components/formik/PatientSearchTextField";
import { Link, useHistory } from "react-router-dom";
import { ProviderModel, ProviderSelectOption } from "components/ProviderOption";
import { LocationModel, LocationSelectOption } from "components/LocationOption";
import { CountySelectField } from "components/formik/CountySelectField";
import { FileUploadInput } from "components/formik/FileUploadField";
import { HealthPlanValue } from "components/HealthPlanPicker";
import { analytics } from "lib/analytics";
import { PatientMemberModel } from "screens/NewInboundReferralScreen/types";
import { SelectedHealthPlan } from "./SelectedHealthPlan";
import { useReferringLocations } from "./hooks/useReferringLocations";
import { useReferringProviders } from "./hooks/useReferringProviders";
import { useDepartmentsAndSpecialties } from "./hooks/useDepartmentsAndSpecialties";
import { useWaitForUploads } from "./hooks/useWaitForUploads";
import toast from "react-hot-toast";
import { PatientSearchResult } from "components/PatientSearchInput";
import { useSyncPatientMember } from "screens/EConsultShowScreen/useSyncPatientMember";

const CREATE_REFERRAL = gql`
  mutation CreateReferral($input: ReferralInput!) {
    createReferral(input: $input) {
      errors {
        key
        message
      }
      referral {
        id
      }
    }
  }
`;

interface MutationData {
  createReferral: {
    errors?: InputError[];
    referral?: {
      id: string;
    };
  };
}

interface MutationVariables {
  input: ReferralInput;
}

interface ReferralInput {
  referringDepartmentId: string;
  referringProviderId: string;
  referringLocationId: string;
  specialtyId: string;
  fileUploadIds: string[];
  patientMemberId?: string;
  patientMember?: PatientMemberInput;
}

interface PatientMemberInput {
  firstName: string;
  lastName: string;
  dob: string;
  isSelfPay: boolean;
  insuranceCoverages: InsuranceCoverageFormModel[];
  zip: string;
  fipsCode: string;
  medicalRecordNumber?: string;
  ssn?: string;
  primaryPhone?: string;
}

type InsuranceType = "SELF_PAY" | "INSURANCE";

const InsuranceTypes: Record<string, InsuranceType> = {
  SELF_PAY: "SELF_PAY",
  INSURANCE: "INSURANCE",
};

const InsuranceTypeOptions = [
  {
    label: "Patient has insurance",
    value: InsuranceTypes.INSURANCE,
  },
  {
    label: "Patient plans to self-pay",
    value: InsuranceTypes.SELF_PAY,
  },
];

const blankInsuranceCoverage = {
  viewOnly: false,
  healthPlan: {
    carrierId: null,
    healthPlanId: null,
    ribbonHealthPlanUuid: null,
  },
  healthPlanNotFound: false,
  unlistedCarrierName: "",
  unlistedHealthPlanName: "",
  membershipNumber: "",
};

const initialValues: FormValues = {
  selectedPatientMemberId: null,
  referringDepartmentId: "",
  referringProviderId: "",
  referringLocationId: "",
  patientMember: {
    firstName: "",
    lastName: "",
    dob: "",
    zip: "",
    fipsCode: "",
    insuranceType: InsuranceTypes.INSURANCE,
    insuranceCoverages: [blankInsuranceCoverage],
    medicalRecordNumber: "",
  },
  specialtyId: "",
  fileUploadIds: [],
};

interface FormValues {
  selectedPatientMemberId: string | null;
  referringDepartmentId: string;
  referringProviderId: string;
  referringLocationId: string;
  patientMember: {
    firstName: string;
    lastName: string;
    dob: string;
    zip: string;
    fipsCode: string;
    insuranceType: InsuranceType;
    insuranceCoverages: InsuranceCoverageFormModel[];
    medicalRecordNumber: string;
  };
  specialtyId: string;
  fileUploadIds: string[];
}

interface InsuranceCoverageFormModel {
  viewOnly?: boolean;
  healthPlan?: HealthPlanValue;
  healthPlanNotFound?: boolean;
  unlistedCarrierName: string;
  unlistedHealthPlanName: string;
  membershipNumber: string;
  carrierId?: string | null;
}

const insuranceCoverageSchema: Yup.SchemaOf<InsuranceCoverageFormModel> =
  Yup.object()
    .shape({
      healthPlan: Yup.object().when("healthPlanNotFound", {
        is: (healthPlanNotFound: boolean) => !healthPlanNotFound,
        then: schema =>
          schema
            .shape({
              carrierId: Yup.string().nullable(),
              healthPlanId: Yup.string().nullable(),
            })
            .test({
              name: "carrierIdAndHealthPlanIdSet",
              // NB: This MUST NOT use fat arrow function syntax because we need `this`.
              test: function (values: any) {
                const isValid =
                  !!values.carrierId &&
                  (!!values.healthPlanId || !!values.ribbonHealthPlanUuid);

                if (isValid) return true;

                return this.createError({
                  path: "",
                  message: "Please set carrier and health plan",
                });
              },
            })
            .required("Please select a health plan"),
        otherwise: schema => schema.nullable(),
      }),
      membershipNumber: Yup.string().required("Required"),
    })
    .required();

interface PatientStepProps {
  patientMemberId?: string | null;
}

export const PatientStep: FC<PatientStepProps> = props => {
  const { patientMemberId } = props;

  const [editingPatientMemberId, setEditingPatientMemberId] = useState<
    string | null
  >(null);
  const [isMrnEmpty, setIsMrnEmpty] = useState<boolean | true>(true);

  const validationSchema: Yup.SchemaOf<FormValues> = useMemo(
    () =>
      Yup.object()
        .shape({
          selectedPatientMemberId: Yup.string().nullable(),
          referringProviderId: Yup.string().required("Required"),
          specialtyId: Yup.string().required("Required"),
          referringLocationId: Yup.string().required("Required"),
          patientMember: Yup.object().when("selectedPatientMemberId", {
            is: (selectedPatientMemberId: string | void) =>
              !selectedPatientMemberId || editingPatientMemberId,
            then: schema =>
              schema.shape({
                firstName: Yup.string().required("Required"),
                lastName: Yup.string().required("Required"),
                dob: Yup.string().required("Required"),
                zip: Yup.string().required("Required"),
                fipsCode: Yup.string().required("Required"),
                insuranceCoverages: Yup.array()
                  .when("insuranceType", {
                    is: InsuranceTypes.INSURANCE,
                    then: schema => schema.of(insuranceCoverageSchema).min(1),
                    otherwise: schema => schema.min(0),
                  })
                  .required(),
                medicalRecordNumber: Yup.string()
                  .required("Required")
                  .matches(
                    /^[A-Za-z0-9-]*$/,
                    "Please remove any special character"
                  ),
              }),
            otherwise: schema => schema.nullable(),
          }),
        })
        .required(),
    [editingPatientMemberId]
  );

  const {syncPatientMember, loading} = useSyncPatientMember()

  const {
    departmentAndSpecialtiesLoading,
    departmentAndSpecialtiesError,
    referringDepartmentOptions,
    specialtyOptions,
    showDepartmentSelect,
  } = useDepartmentsAndSpecialties();

  const [createReferral] = useMutation<MutationData, MutationVariables>(
    CREATE_REFERRAL
  );

  const history = useHistory();

  /**
   * Grab a promise we can wait on while file uploads
   * are finishing.
   */
  const [waitForUploads, instanceId] = useWaitForUploads();

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: false });

      let dob = values.patientMember.dob
        ? localDateToISO(values.patientMember.dob)
        : "";

      const isSelfPay = values.patientMember.insuranceType === "SELF_PAY";

      const insuranceCoverages = (
        isSelfPay ? [] : values.patientMember.insuranceCoverages
      ).map(ic => {
        const insuranceInput = {
          ...ic,
          ...(ic.healthPlan || {}),
        };
        delete insuranceInput.viewOnly;
        delete insuranceInput.healthPlanNotFound;
        delete insuranceInput.healthPlan;
        return insuranceInput;
      });

      // Only set the patient member info if it's a new patient (no selectedPatientMemberId)
      // or if we're editing the patient member.
      const includePatientAttrs =
        !values.selectedPatientMemberId || editingPatientMemberId;

      let input: ReferralInput = {
        referringDepartmentId: values.referringDepartmentId,
        referringProviderId: values.referringProviderId,
        referringLocationId: values.referringLocationId,
        fileUploadIds: values.fileUploadIds,
        specialtyId: values.specialtyId,
        patientMemberId:
          editingPatientMemberId || values.selectedPatientMemberId || undefined,
        patientMember: includePatientAttrs
          ? removeVoidKeys<PatientMemberInput>({
              firstName: values.patientMember.firstName,
              lastName: values.patientMember.lastName,
              isSelfPay,
              insuranceCoverages,
              dob,
              zip: values.patientMember.zip,
              fipsCode: values.patientMember.fipsCode,
              medicalRecordNumber: values.patientMember.medicalRecordNumber,
            })
          : undefined,
      };

      await waitForUploads();

      try {
        const { data } = await createReferral({ variables: { input } });

        if (data?.createReferral.errors) {
          setStatus({ errors: data.createReferral.errors });
        } else if (data?.createReferral.referral) {
          // it worked...
          // WIP: Modify this to be aware of editingPatientMemberId
          if (!values.selectedPatientMemberId && input.patientMember) {
            trackPatientCreated(input);
          }
          const { id } = data.createReferral.referral;
          return history.push(`/o/referral/${id}/questions`);
        }
        setSubmitting(false);
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
        setSubmitting(false);
      }
    },
    [history, editingPatientMemberId, waitForUploads, createReferral]
  );

  const formikBag = useFormik<FormValues>({
    initialValues: {
      ...initialValues,
      selectedPatientMemberId: patientMemberId || null,
    },
    validationSchema,
    onSubmit,
  });

  const { handleSubmit, status, isSubmitting, values, setValues } = formikBag;

  const providersData = useReferringProviders({
    referringDepartmentId: values.referringDepartmentId,
  });

  const locationsData = useReferringLocations({
    referringDepartmentId: values.referringDepartmentId,
    referringProviderId: values.referringProviderId,
  });

  function clearSelectedPatient() {
    setEditingPatientMemberId(null);
    setIsMrnEmpty(true);
    setValues({
      ...values,
      selectedPatientMemberId: null,
      patientMember: {
        ...initialValues.patientMember,
      },
    });
  }

  function onEditClick(patientMember: PatientMemberModel) {
    setEditingPatientMemberId(values.selectedPatientMemberId || null);
    setIsMrnEmpty(!patientMember.medicalRecordNumber);
    setValues({
      ...values,
      patientMember: {
        // WIP: No need to spread this one...
        ...values.patientMember,
        firstName: patientMember.patient.firstName,
        lastName: patientMember.patient.lastName,
        dob: patientMember.patient.dob
          ? ISODateToLocal(patientMember.patient.dob)
          : "",
        zip: patientMember.patient.zip || "",
        medicalRecordNumber: patientMember.medicalRecordNumber || "",
        fipsCode: patientMember.patient.fipsCode || "",
        insuranceType: patientMember.isSelfPay
          ? InsuranceTypes.SELF_PAY
          : InsuranceTypes.INSURANCE,
        insuranceCoverages:
          patientMember.insuranceCoverages.length === 0
            ? [blankInsuranceCoverage]
            : patientMember.insuranceCoverages.map(ic => {
                return {
                  viewOnly: true,
                  membershipNumber: ic.membershipNumber || "",
                  healthPlanNotFound:
                    !!ic.unlistedCarrierName || !!ic.unlistedHealthPlanName,
                  unlistedCarrierName: ic.unlistedCarrierName || "",
                  unlistedHealthPlanName: ic.unlistedHealthPlanName || "",
                  healthPlan: {
                    carrierId: ic.healthPlan?.carrier.id || null,
                    healthPlanId: ic.healthPlan?.id || null,
                    ribbonHealthPlanUuid: ic.healthPlan?.ribbonUuid || null,
                  },
                };
              }),
      },
    });
  }

  async function onPatientSearchSelection(patient: PatientSearchResult) {
    setEditingPatientMemberId(null);
    if (patient.patient) {
      setValues({
        ...values,
        selectedPatientMemberId: patient.patient.patientMembers[0].id,
        patientMember: {
          ...initialValues.patientMember
        }
      })
    } else if (patient.amdPatient) {
      const {patientMember, errors} = await syncPatientMember(patient.amdPatient.id)
      if (patientMember) {
        setValues({
          ...values,
          selectedPatientMemberId: patientMember.id,
          patientMember: {
            ...initialValues.patientMember
          }
        })
      } else if (errors) {
        toast.error(errors[0].message);
      } else {
        toast.error("Error selecting patient");
      }
    }
  }

  function cancelEdit() {
    setValues({
      ...values,
      selectedPatientMemberId: editingPatientMemberId,
      patientMember: {
        ...initialValues.patientMember,
      },
    });
    setIsMrnEmpty(true);
    setEditingPatientMemberId(null);
  }

  return (
    <>
      <ScreenTitle title={["New Referral", "Patient Info"]} />

      <FadeUpIn show>
        <div className="_PatientStep bg-white max-w-5xl mx-auto mb-8 p-4 rounded-xl shadow-lg text-left w-full">
          <CircleIconHeader icon="user-doctor">New Referral</CircleIconHeader>

          <section className="mt-4">
            <FormikProvider value={formikBag}>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <FormStatusErrors status={status} />
                <div className="sm:grid sm:grid-cols-2 sm:gap-6 px-3">
                  <div className="_patient-info">
                    <h3 className="p-3 text-xs font-semibold text-cool-gray-500 uppercase tracking-wider">
                      Patient Info
                    </h3>
                    <div
                      className={`_patient-info-section ${
                        editingPatientMemberId
                          ? "border rounded shadow bg-blue-50 px-3 pb-3"
                          : ""
                      }`}
                    >
                      {values.selectedPatientMemberId ? (
                        <>
                          <SelectedPatientMember
                            patientMemberId={values.selectedPatientMemberId}
                            onClearSelection={clearSelectedPatient}
                            onEditClick={onEditClick}
                            isEditing={!!editingPatientMemberId}
                            onCancelEditClick={cancelEdit}
                          />
                        </>
                      ) : null}
                      {!values.selectedPatientMemberId ||
                      editingPatientMemberId ? (
                        <>
                          <div className="mt-3">
                            <PatientSearchTextField
                              name="patientMember.lastName"
                              label="Patient Last Name"
                              icon="search"
                              autoFocus
                              onPatientClick={onPatientSearchSelection}
                              externalLoading={loading}
                            />
                          </div>

                          <div className="mt-3 flex -mx-2">
                            <div className="flex-1 px-2">
                              <TextField
                                name="patientMember.firstName"
                                label="Patient First Name"
                              />
                            </div>
                            <div className="flex-1 px-2">
                              <DateMaskField
                                name="patientMember.dob"
                                label="Patient DOB"
                              />
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-2 gap-4">
                            <TextMaskField
                              name="patientMember.zip"
                              label="Patient Zip"
                              mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                              placeholder="Patient Zip"
                            />

                            <CountySelectField
                              zip={values.patientMember.zip}
                              name="patientMember.fipsCode"
                              label="Patient County"
                              disabled={values.patientMember.zip.length < 5}
                              placeholder={
                                values.patientMember.zip.length < 5
                                  ? "Enter zip first..."
                                  : "Select..."
                              }
                              autoSelect
                            />
                          </div>
                          <div className="mt-3">
                            <TextField
                              name="patientMember.medicalRecordNumber"
                              label="Patient MRN"
                              inputProps={{
                                disabled: !isMrnEmpty,
                              }}
                            />
                          </div>
                          <div className="mt-6">
                            <RadioGroupInput
                              name="patientMember.insuranceType"
                              options={InsuranceTypeOptions}
                              className="font-medium justify-center sc-fzXfMz text-sm"
                              inline
                            />
                          </div>
                          {hasInsurance(values) ? (
                            <>
                              <FieldArray name="patientMember.insuranceCoverages">
                                {({ insert, remove, replace }) => (
                                  <>
                                    {values.patientMember.insuranceCoverages.map(
                                      (ic, index) => (
                                        <div
                                          key={index}
                                          className="bg-white border m-3 p-4 rounded-xl shadow-lg"
                                        >
                                          <div className="flex items-center justify-between">
                                            <h6 className="text-xs font-semibold text-gray-700">
                                              {index === 0
                                                ? "Primary"
                                                : "Supplemental"}{" "}
                                              Insurance
                                            </h6>
                                            {index > 0 ? (
                                              <button
                                                type="button"
                                                className="btn btn-red-alt btn-sm"
                                                onClick={() => remove(index)}
                                              >
                                                Remove
                                              </button>
                                            ) : (
                                              <div />
                                            )}
                                          </div>
                                          <div className="mt-3">
                                            {ic.healthPlan?.healthPlanId &&
                                            ic.viewOnly ? (
                                              <SelectedHealthPlan
                                                healthPlanId={
                                                  ic.healthPlan?.healthPlanId
                                                }
                                                onClear={() =>
                                                  replace(index, {
                                                    ...ic,
                                                    viewOnly: false,
                                                    healthPlan:
                                                      blankInsuranceCoverage.healthPlan,
                                                  })
                                                }
                                              />
                                            ) : (
                                              <HorizontalHealthPlanPickerField
                                                name={`patientMember.insuranceCoverages.${index}.healthPlan`}
                                                label="Health Plan"
                                              />
                                            )}
                                          </div>
                                          <div className="mt-3">
                                            <HorizontalTextField
                                              name={`patientMember.insuranceCoverages.${index}.membershipNumber`}
                                              label="Member ID"
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                    <div className="text-center">
                                      <button
                                        type="button"
                                        className="bg-white border btn btn-sm hover:bg-blue-100 transition-colors transition-medium"
                                        onClick={() =>
                                          insert(
                                            values.patientMember
                                              .insuranceCoverages.length,
                                            blankInsuranceCoverage
                                          )
                                        }
                                      >
                                        <span className="mr-2">
                                          <FAIcon icon="plus" />
                                        </span>
                                        Add Insurance
                                      </button>
                                    </div>
                                  </>
                                )}
                              </FieldArray>
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div className="_referral-info mt-3 sm:mt-0 pt-3 sm:pt-0 border-t sm:border-none border-cool-gray-100">
                    <h3 className="p-3 text-xs font-semibold text-cool-gray-500 uppercase tracking-wider">
                      Referral Info
                    </h3>
                    {departmentAndSpecialtiesLoading ? (
                      <div className="p-12 text-center">
                        <Spinner />
                      </div>
                    ) : departmentAndSpecialtiesError ? (
                      <p>Failed to load</p>
                    ) : (
                      <>
                        <div
                          className={`mt-3 ${
                            showDepartmentSelect ? "" : "hidden"
                          }`}
                        >
                          <SelectField
                            name="referringDepartmentId"
                            label="Referring Department"
                            options={referringDepartmentOptions}
                            isLoading={departmentAndSpecialtiesLoading}
                            autoSelect
                          />
                        </div>
                        <div className="mt-3">
                          <SelectField
                            name="referringProviderId"
                            label="Referring Provider"
                            options={
                              providersData.data?.departmentProviders.items ||
                              []
                            }
                            disabled={departmentAndSpecialtiesLoading}
                            isLoading={providersData.loading}
                            getOptionLabel={(o: ProviderModel) =>
                              o.nameWithAppellation
                            }
                            getOptionValue={(o: ProviderModel) => o.id}
                            components={{ Option: ProviderSelectOption }}
                            autoSelect={!!values.referringDepartmentId}
                          />
                          {!providersData.loading &&
                          !providersData.data?.departmentProviders.items
                            ?.length ? (
                            <div className="font-semibold py-2 text-cool-gray-600 text-xs">
                              Need to add referring provider options?:{" "}
                              <Link
                                to="/o/settings/providers"
                                className="hover:text-blue-700 link text-blue-600 underline"
                              >
                                Go to settings
                              </Link>
                            </div>
                          ) : null}
                        </div>
                        <div className="mt-3">
                          <SelectField
                            name="referringLocationId"
                            label="Referring Location"
                            options={
                              locationsData.data?.departmentLocations.items ||
                              []
                            }
                            placeholder={
                              !values.referringDepartmentId
                                ? "Select referring department first..."
                                : !values.referringProviderId
                                ? "Select referring provider first..."
                                : "Select..."
                            }
                            disabled={
                              !values.referringProviderId ||
                              departmentAndSpecialtiesLoading
                            }
                            isLoading={locationsData.loading}
                            getOptionLabel={(o: LocationModel) => o.name}
                            getOptionValue={(o: LocationModel) => o.id}
                            components={{ Option: LocationSelectOption }}
                            autoSelect={!!values.referringProviderId}
                          />
                        </div>
                      </>
                    )}
                    <div className="mt-3">
                      <SelectField
                        name="specialtyId"
                        label="Referral Specialty"
                        options={specialtyOptions}
                        isLoading={departmentAndSpecialtiesLoading}
                      />
                    </div>
                    <div className="mt-8">
                      <FileUploadInput
                        name="fileUploadIds"
                        instanceId={instanceId}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center mt-6 p-4">
                  <Button
                    type="submit"
                    kind="primary"
                    color="blue"
                    size="lg"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Next
                    <span className="ml-2">
                      <FAIcon icon="arrow-right" />
                    </span>
                  </Button>
                </div>
              </form>
            </FormikProvider>
          </section>
        </div>
      </FadeUpIn>
    </>
  );
};

function hasInsurance(values: FormValues): boolean {
  return values.patientMember.insuranceType === InsuranceTypes.INSURANCE;
}

function trackPatientCreated(input: ReferralInput) {
  let analyticsData: any = {
    source: "Referral Wizard",
    self_pay: !!input.patientMember?.isSelfPay,
  };
  if (
    input.patientMember &&
    !input.patientMember.isSelfPay &&
    input.patientMember.insuranceCoverages.length > 0
  ) {
    analyticsData.insurance_carrier_id =
      input.patientMember?.insuranceCoverages[0].carrierId;
  }
  analytics.track("Patient Added", analyticsData);
}
