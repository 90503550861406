import { FC, useCallback, useState, useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { GradientHeader } from "../../GradientHeader";
import toast from "react-hot-toast";
import { HorizontalField, Button, Spinner } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";

const CURRENT_ORG_LOGO = gql`
  query GetCurrentOrganizationLogo {
    me {
      id
      organization {
        id
        logoUrl
      }
    }
  }
`;

interface Data {
  me: {
    id: string;
    organization: {
      id: string;
      logoUrl?: string;
    }
  }
}

const UPDATE_ORGANIZATION_LOGO = gql`
  mutation UpdateOrganizationLogo($logo: Upload!) {
    updateOrganizationLogo(logo: $logo) {
      errors {
        key
        message
      }
      organization {
        id
        logoUrl
      }
    }
  }
`;

interface MutationData {
  updateOrganizationLogo: {
    errors?: InputError[];
    organization?: {
      id: string;
      logoUrl?: string;
    }
  }
}

interface MutationVariables {
  logo: any;
}

/**
 * Preview.
 */

interface PreviewProps {
  file: any;
}

const Preview: FC<PreviewProps> = (props) => {
  const { file } = props;
  const [loading, setLoading] = useState(true);
  const [preview, setPreview] = useState<any>();

  useEffect(() => {
    if (file) {
      let reader = new FileReader();
      reader.onload = () => {
        setLoading(false);
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
      return () => reader.abort();
    }
    return () => { };
  }, [file, setLoading, setPreview]);

  if (!file) {
    return null;
  }

  if (loading) {
    return <p>loading...</p>;
  }

  return (
    <img
      src={preview}
      alt={file.name}
      className="img-thumbnail mt-2"
      height={200}
      width={200}
    />
  );
};

type FormValues = MutationVariables;

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    logo: Yup.mixed().required("Required"),
  })
  .required();

interface OrganizationLogoFormProps { };

export const OrganizationLogoForm: FC<OrganizationLogoFormProps> = props => {
  const { loading, data, error } = useQuery<Data>(CURRENT_ORG_LOGO);
  const [updateLogo] = useMutation<MutationData, MutationVariables>(UPDATE_ORGANIZATION_LOGO);

  const onSubmit = useCallback((values, formikHelpers) => {
    const { setStatus, setSubmitting } = formikHelpers;
    console.log({ values })

    return updateLogo({ variables: { logo: values.logo } }).then(resp => {
      if (resp.data?.updateOrganizationLogo.errors) {
        setStatus({ errors: resp.data.updateOrganizationLogo.errors });
      } else if (resp.data?.updateOrganizationLogo.organization) {
        // it worked...
        toast.success("Logo updated");
      }
      setSubmitting(false);
    })
  }, [updateLogo])

  return (
    <>
      <ScreenTitle title={["Settings", "Organization Logo"]} />
      <div className="_OrganizationLogoForm bg-white box rounded-lg shadow-lg">
        <GradientHeader
          icon="trademark"
          title="Organization Logo"
          subtitle="Update your organization's logo."
        />
        {
          loading ? (
            <div className="p-12 text-center">
              <Spinner />
            </div>
          ) : error || !data?.me.organization ? (
            <p>Failed to load</p>
          ) : (
            <>
              <div className="mt-3">
                <HorizontalField label="Current Logo">
                  {
                    data.me.organization.logoUrl ? (
                      <div className="p-2">
                        <img className="max-h-24 max-w-xs" src={data.me.organization.logoUrl} alt="Organization Logo" />
                      </div>
                    ) : (
                      <p>No Logo Uploaded</p>
                    )
                  }
                </HorizontalField>
              </div>
              <Formik<FormValues>
                initialValues={{ logo: null }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, status, isSubmitting, setFieldValue, handleSubmit }) => (
                  <form className="p-6" onSubmit={handleSubmit}>
                    <FormStatusErrors status={status} />

                    <div className="mt-3">
                      <HorizontalField label="Upload New Logo">
                        <input
                          id="logo"
                          name="logo"
                          type="file"
                          onChange={(event: any) => {
                            console.log(event)
                            setFieldValue("logo", event.currentTarget.files[0]);
                          }}
                          className="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                        />
                        {values.logo && (
                          <div className="mt-2">
                            <Preview file={values.logo} />
                          </div>
                        )}
                      </HorizontalField>
                    </div>
                    <div className="mt-3">
                      <HorizontalField label="">
                        <Button
                          type="submit"
                          kind="primary"
                          color="blue"
                          disabled={isSubmitting}
                          isLoading={isSubmitting}
                        >
                          Save
                        </Button>
                      </HorizontalField>
                    </div>
                  </form>
                )}
              </Formik>
            </>
          )
        }
      </div>
    </>
  );
};
