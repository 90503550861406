import { FC } from "react";
import { useAppointmentRequest } from "../../AppointmentRequestContext";
import { AppointmentRequestStatusModel } from "../../model";

interface StatusBadgeProps { }

const classNames: Record<AppointmentRequestStatusModel, string> = {
  OPEN: "bg-indigo-100 text-indigo-800",
  EXHAUSTED: "bg-yellow-100 text-yellow-800",
  WITHDRAWN: "bg-gray-100 text-red-800",
  REJECTED: "bg-red-100 text-red-800",
  SCHEDULED: "bg-green-100 text-green-800",
  CLOSED: "bg-gray-100 text-red-800"
};

const labels: Record<AppointmentRequestStatusModel, string> = {
  OPEN: "Open",
  EXHAUSTED: "Exhausted",
  REJECTED: "Rejected",
  SCHEDULED: "Scheduled",
  WITHDRAWN: "Withdrawn",
  CLOSED: "Closed",
};

export const StatusBadge: FC<StatusBadgeProps> = (props) => {
  const {
    appointmentRequest: { status },
  } = useAppointmentRequest();

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 shadow-inner rounded-md text-base font-semibold ${classNames[status]}`}
    >
      {labels[status]}
    </span>
  );
};
