import { FC, useCallback, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { ScreenTitle } from "context/ScreenTitle";
import { mmDdYyyy } from "lib/dateFormatters";
import {
  Button,
  DropdownButton,
  DropdownItemButton,
  FAIcon,
  Spinner,
  Table,
  TableContainer,
  TD,
  TH,
} from "@preferral/ui";
import { NoResults } from "components/NoResults";
import { GradientHeader } from "../../GradientHeader";
import { NewTagModal } from "./NewTagModal";
import { EditTagModal } from "./EditTagModal";
import { DeleteTagModal } from "./DeleteTagModal";
import { useRouteMatch } from "react-router-dom";

export const PATIENT_PACKET_SUBMISSION_TAGS = gql`
  query ListPatientPacketSubmissionTags($first: Int, $after: UUID4) {
    patientPacketSubmissionTags(first: $first, after: $after) {
      endOfList
      cursor
      items {
        id
        label
        description
        color
        insertedAt
      }
    }
  }
`;

interface Data {
  patientPacketSubmissionTags: Paginated<PatientPacketSubmissionTagModel>;
}

interface Variables {
  first?: number;
  after?: string;
}

export interface PatientPacketSubmissionTagModel {
  id: string;
  label: string;
  description: string | void;
  color: string;
  insertedAt: string;
}

/**
 * Tags.
 */

interface TagsProps { }

type ActiveModal =
  | { type: "NEW" }
  | { type: "EDIT"; id: string }
  | { type: "DELETE"; id: string };

export const PatientPacketTagsScreen: FC<TagsProps> = props => {
  const match = useRouteMatch<{ departmentId: string }>();
  const { departmentId } = match.params;

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const closeModal = useCallback(() => setActiveModal(null), []);
  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    PATIENT_PACKET_SUBMISSION_TAGS
  );

  const onSuccess = useCallback(() => {
    refetch();
    closeModal();
  }, [refetch, closeModal]);

  return (
    <>
      <ScreenTitle title={["Settings", "Department", "Patient Packet Tags"]} />
      <NewTagModal
        departmentId={departmentId}
        isOpen={activeModal?.type === "NEW"}
        onClose={closeModal}
        onSuccess={onSuccess}
      />
      {activeModal?.type === "EDIT" && activeModal.id ? (
        <EditTagModal
          id={activeModal.id}
          isOpen={true}
          onClose={closeModal}
          onSuccess={onSuccess}
        />
      ) : null}
      {activeModal?.type === "DELETE" && activeModal.id ? (
        <DeleteTagModal
          id={activeModal.id}
          isOpen={true}
          onClose={closeModal}
          onSuccess={onSuccess}
        />
      ) : null}
      <div className="_Tags bg-white rounded-lg shadow-lg">
        <GradientHeader
          title="Tags"
          subtitle="Manage your patient packet submission tags"
        />

        <div className="p-4 text-right">
          <Button
            type="button"
            kind="primary"
            color="blue"
            onClick={() => setActiveModal({ type: "NEW" })}
          >
            <span className="mr-2">
              <FAIcon icon="plus" />
            </span>
            Add Tag
          </Button>
        </div>

        {loading ? (
          <div className="p-8 text-center">
            <Spinner />
          </div>
        ) : error || !data?.patientPacketSubmissionTags ? (
          <p>Failed to load</p>
        ) : data.patientPacketSubmissionTags.items.length === 0 ? (
          <NoResults icon="tags" text="No patient packet submission tags yet" />
        ) : (
          <div className="p-2">
            <TableContainer>
              <Table
                className="is-fullwidth is-hoverable table"
                style={{ marginBottom: 0 }}
              >
                <thead>
                  <tr>
                    <TH>Label</TH>
                    <TH>Description</TH>
                    <TH>Created At</TH>
                    <TH>Edit</TH>
                  </tr>
                </thead>
                <tbody>
                  {data.patientPacketSubmissionTags.items.map(tag => (
                    <tr key={tag.id}>
                      <TD>
                        <div className="flex items-center">
                          <div
                            style={{ backgroundColor: tag.color }}
                            className="w-8 h-8 rounded-full shadow-inner"
                          />
                          <p className="ml-4 font-semibold">{tag.label}</p>
                        </div>
                      </TD>
                      <TD>{tag.description || "-"}</TD>
                      <TD>{mmDdYyyy(tag.insertedAt)}</TD>
                      <TD>
                        <DropdownButton label="Actions">
                          <DropdownItemButton
                            color="red"
                            onClick={() =>
                              setActiveModal({ type: "DELETE", id: tag.id })
                            }
                          >
                            Delete Tag
                          </DropdownItemButton>
                        </DropdownButton>
                      </TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="text-center" style={{ padding: "1rem" }}>
                <p className="text-gray-500"> End of List</p>
              </div>
            </TableContainer>
          </div>
        )}
      </div>
    </>
  );
};
