import React from "react";
import "./GradientHeader.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FAIcon } from "components/FAIcon";

type GradientHeaderProps = {
  title: string;
  subtitle?: string;
  icon?: IconProp;
};

const GradientHeader: React.FC<GradientHeaderProps> = ({
  icon,
  title,
  subtitle
}) => {
  return (
    <div className="GradientHeader-primary">
      <h1>
        {icon && (
          <span className="mr-3 text-2xl text-blue-200">
            <FAIcon icon={icon} />
          </span>
        )}
        {title}
      </h1>
      {subtitle && (
        <h6 className="font-semibold leading-none text-blue-100 text-sm">
          {subtitle}
        </h6>
      )}
    </div>
  );
};

export { GradientHeader };
