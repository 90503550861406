import React, { FC, useCallback } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Button, SelectField } from "@preferral/ui";
import {
  LocationOptionGqlFields,
  LocationModel,
  LocationSelectOption,
} from "components/LocationOption";
import toast from "react-hot-toast";

const ORGANIZATION_LOCATIONS = gql`
  query GetOrganizationLocations {
    organizationLocations(first: 100) {
      cursor
      endOfList
      items {
        ${LocationOptionGqlFields}
      }
    }
  }
`;

interface Data {
  organizationLocations: Paginated<LocationModel>;
}

const ADD_DEPARTMENT_LOCATION = gql`
  mutation AddDepartmentLocation($departmentId: UUID4!, $locationId: UUID4!) {
    assignLocationToDepartment(
      departmentId: $departmentId
      locationId: $locationId
    ) {
      errors {
        key
        message
      }
      department {
        id
      }
    }
  }
`;

interface MutationData {
  assignLocationToDepartment: {
    errors?: InputError[];
    department?: {
      id: string;
    };
  };
}

interface MutationVariables {
  departmentId: string;
  locationId: string;
}

interface FormValues {
  locationId: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    locationId: Yup.string().required("Required"),
  })
  .required();

interface AddLocationFormProps {
  departmentId: string;
  refetch(): void;
  onSuccess(): void;
}

export const AddLocationForm: FC<AddLocationFormProps> = (props) => {
  const { departmentId, refetch, onSuccess } = props;

  const { data, loading } = useQuery<Data>(ORGANIZATION_LOCATIONS);

  const [assignLocation] = useMutation<MutationData, MutationVariables>(
    ADD_DEPARTMENT_LOCATION
  );

  const locationOptions = data?.organizationLocations.items || [];

  const onSubmit = useCallback(
    (values, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;
      setStatus({ errors: null });
      return assignLocation({
        variables: { departmentId, locationId: values.locationId },
      }).then((resp) => {
        if (resp.data?.assignLocationToDepartment.errors) {
          setStatus({ errors: resp.data.assignLocationToDepartment.errors });
        } else if (resp.data?.assignLocationToDepartment.department) {
          // it worked...
          toast.success("Location assigned to department!");
          refetch();
          onSuccess();
        }
        setSubmitting(false);
      });
    },
    [departmentId, refetch, onSuccess, assignLocation]
  );
  return (
    <Formik<FormValues>
      initialValues={{ locationId: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, status }) => (
        <form onSubmit={handleSubmit}>
          <SelectField
            name="locationId"
            label="Location"
            options={locationOptions}
            isLoading={loading}
            getOptionLabel={(l: LocationModel) => l.name}
            getOptionValue={(l: LocationModel) => l.id}
            components={{ Option: LocationSelectOption }}
          />
          <div className="mt-3 text-center">
            <Button
              type="submit"
              kind="primary"
              color="blue"
              isLoading={isSubmitting}
            >
              Add Location
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
