import React, { FC } from "react";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import { coalesceString } from "lib/coalesce";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { InvitationRegisterForm } from "./InvitationRegisterForm";
import imgSrc from "images/identity_form_min.svg";
import { useAuth } from "context/AuthContext";

const QUERY = gql`
  query FetchInvitedUser($id: UUID4!, $token: String!) {
    invitedUser(id: $id, token: $token) {
      id
      email
      firstName
      lastName
      timeZoneName
      organization {
        id
        name
      }
    }
    timeZoneNames
  }
`;

interface Data {
  invitedUser: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    timeZoneName: string;
    organization: {
      id: string;
      name: string;
    };
  };
  timeZoneNames: string[];
}

interface Variables {
  id: string;
  token: string;
}

const InvalidPageMessage = () => (
  <div>
    <h1 className="is-size-2">Invalid Password Reset URL</h1>
    <p>
      <Link to="/auth/forgot_password">Click Here</Link> to request a new
      password reset link
    </p>
  </div>
);

interface InvitationRegisterScreenProps {}

export const InvitationRegisterScreen: FC<InvitationRegisterScreenProps> = () => {
  const location = useLocation();
  const { id, token } = qs.parse(location.search);
  const { login } = useAuth();

  const { data, loading, error } = useQuery<Data, Variables>(QUERY, {
    variables: {
      id: (id as string | undefined) ?? "",
      token: (token as string | undefined) ?? ""
    }
  });

  return (
    <div className="InvitationRegisterScreen">
      {id && token ? (
        loading ? (
          <h1>Loading...</h1>
        ) : error ? (
          <InvalidPageMessage />
        ) : (
          <div className="box" style={{ padding: 0 }}>
            <div className="columns is-gapless">
              <div
                className="column is-vcentered"
                style={{
                  backgroundColor: "rgb(238, 242, 255)",
                  borderTopLeftRadius: 6,
                  borderBottomLeftRadius: 6
                }}
              >
                <figure className="image" style={{ padding: "10%" }}>
                  <img src={imgSrc} alt="Complete my account illustration" />
                </figure>
              </div>
              <div className="column has-text-left">
                <div className="section">
                  <h4 className="title is-4 has-text-centered">
                    Finish Account Setup
                  </h4>
                  <ul>
                    <li>
                      <b>Organization:</b>{" "}
                      {data && data.invitedUser.organization.name}
                    </li>
                    <li>
                      <b>Name:</b> {data && data.invitedUser.firstName}{" "}
                      {data && data.invitedUser.lastName}
                    </li>
                    <li>
                      <b>Email:</b> {data && data.invitedUser.email}
                    </li>
                  </ul>
                  {data && (
                    <InvitationRegisterForm
                      id={coalesceString(id)}
                      token={coalesceString(token)}
                      timeZoneName={data.invitedUser.timeZoneName}
                      timeZoneNames={data.timeZoneNames}
                      onSignIn={login}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <InvalidPageMessage />
      )}
    </div>
  );
};
