import { FC, useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { Spinner } from "@preferral/ui";
import { useAuth } from "context/AuthContext";
import aristamdLogoSrc from "images/aristamd_logo.svg";
import { useAmdSessionContext } from "context/AmdSessionContext";
import { useHistory, useLocation } from "react-router-dom";
import qs from 'query-string'
import { DEFAULT_AFTER_SIGN_IN_PATH } from "config/constants";

const AMD_SSO = gql`
  mutation AmdSSO($amdJwt: String!) {
    issueJwtForAmdJwt(amdJwt: $amdJwt) {
      errors {
        key
        message
      }
      session {
        token
      }
    }
  }
`;

interface AmdSSOData {
  issueJwtForAmdJwt: {
    errors?: InputError[];
    session?: {
      token: string;
    };
  };
}

interface Variables {
  amdJwt: string;
}

export const AmdSSOScreen: FC = () => {
  const { login: signIn } = useAuth();

  const [hasFailed, setHasFailed] = useState(false);
  const [amdSSOLogin] = useMutation<AmdSSOData, Variables>(AMD_SSO);
  const { logout: amdLogout, redirectAmdLogin, getCookie: getAmdCookie } = useAmdSessionContext();
  const amdJwt = getAmdCookie()?.access_token;

  // Get target redirect from query string (friendly forwarding)
  const location = useLocation();
  const history = useHistory()
  const targetPath = getRedirect(qs.parse(location.search) as Record<string, string>)

  useEffect(() => {
    if (amdJwt) {
      amdSSOLogin({ variables: { amdJwt } })
        .then(({ data }) => {
          if (data?.issueJwtForAmdJwt?.errors) {
            toast.error(data.issueJwtForAmdJwt.errors[0].message);
            setHasFailed(true);
          } else if (data?.issueJwtForAmdJwt?.session) {
            // it worked
            const { token } = data.issueJwtForAmdJwt.session;
            // onSignIn(token);
            const cb = () => history.push(targetPath);
            return signIn(token, cb);
          } else {
            console.error("Should never happen");
          }
        })
        .catch(({ message }) => {
          toast.error("Something went wrong.");
          console.error(message);
          setHasFailed(true);
        });
    } else {
      redirectAmdLogin()
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasFailed) {
      amdLogout();
    }
  }, [hasFailed, amdLogout]);

  return (
    <div className="min-h-screen bg-cool-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto w-48"
          src={aristamdLogoSrc}
          alt="AristaMD Logo"
        />
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <Spinner />
      </div>
    </div>
  );
};

function getRedirect(queryStringParams: Record<string, string>): string {
  if (queryStringParams?.redirect && queryStringParams.redirect.startsWith("/")) {
    return queryStringParams.redirect;
  }
  return DEFAULT_AFTER_SIGN_IN_PATH;
}
