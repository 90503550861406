import { FC } from 'react'
import cloneDeep from 'lodash.clonedeep'
import ReactMarkdown from 'react-markdown'
import { useFormik, FormikProvider } from 'formik'
import { QuestionModel, QuestionnaireModel, SomeAnswer } from '../types'
import { QuestionnaireFormProvider } from './QuestionnaireFormContext'
import { FormStatusErrors } from 'components/formik/FormStatusErrors'
import { ShortTextQuestionField } from './ShortTextQuestionField'
import { DropdownQuestionField } from './DropdownQuestionField'
import { AddressQuestionField } from './AddressQuestionField'
import { CheckboxesQuestionField } from './CheckboxesQuestionField'
import { ToothSelectorQuestionField } from './ToothSelectorQuestionField'
import { SignatureQuestionField } from './SignatureQuestionField'
import { RadioQuestionField } from './RadioQuestionField'
import { ProviderQuestionField } from './ProviderQuestionField'
import { EmailQuestionField } from './EmailQuestionField'
import { PhoneNumberQuestionField } from './PhoneNumberQuestionField'
import { NumberQuestionField } from './NumberQuestionField'
import { MedicationListQuestionField } from './MedicationListQuestionField'
import { DateQuestionField } from './DateQuestionField'
import { answerKindsForQuestionKind } from '../helpers'
import { mmDdYyyy } from 'lib/dateFormatters'

const Question: FC<{ question: QuestionModel }> = (props) => {
  const { question } = props
  switch (question.kind) {
    case 'address':
      return <AddressQuestionField question={question} />
    case 'checkboxes':
      return <CheckboxesQuestionField question={question} />
    case 'date':
      return <DateQuestionField question={question} />
    case 'dropdown':
      return <DropdownQuestionField question={question} />
    case 'medication_list':
      return <MedicationListQuestionField question={question} />
    case 'number':
      return <NumberQuestionField question={question} />
    case 'email':
      return <EmailQuestionField question={question} />
    case 'phone_number':
      return <PhoneNumberQuestionField question={question} />
    case 'preferred_provider':
      return <ProviderQuestionField question={question} />
    case 'radio':
      return <RadioQuestionField question={question} />
    case 'signature':
      return <SignatureQuestionField question={question} />
    case 'tooth_selector':
      return <ToothSelectorQuestionField question={question} />
    case 'text':
      return <ShortTextQuestionField question={question} />
  }
}

export type QuestionnaireFieldset = Record<string, SomeAnswer['value'] | null>

/**
 * QuestionnaireFields.
 */

interface QuestionnaireFieldsProps {
  name?: string
  template: QuestionnaireModel
}

export const QuestionnaireFields: FC<QuestionnaireFieldsProps> = (props) => {
  const { name, template } = props

  return (
    <QuestionnaireFormProvider namePrefix={name} template={template}>
      {template.sections.map((section) => (
        <div key={section.id} className="lg:p-4 lg:m-4 lg:border lg:rounded">
          {section.title ? (
            <h4 className="my-4 text-lg font-semibold">{section.title}</h4>
          ) : null}

          {section.subtitle ? (
            <h5 className="mb-4 text-gray-500">{section.subtitle}</h5>
          ) : null}

          {section.text ? (
            <article className="my-5 prose mx-auto">
              <ReactMarkdown>
                {section.text}
              </ReactMarkdown>
            </article>
          ) : null}

          {section.questions.map((question) => (
            <div key={question.id} className="mt-3">
              <Question question={question} />
            </div>
          ))}
        </div>
      ))}
    </QuestionnaireFormProvider>
  )
}

interface FormProps {
  template: QuestionnaireModel
}

type FormValues = QuestionnaireFieldset

export const Form: FC<FormProps> = (props) => {
  const { template } = props

  function onSubmit(values: any) {
    console.log('ON SUBMIT', values)
  }

  const formikBag = useFormik<FormValues>({
    initialValues: templateToFields(template),
    onSubmit,
  })

  const { status, handleSubmit } = formikBag

  return (
    <FormikProvider value={formikBag}>
      <form onSubmit={handleSubmit}>
        <FormStatusErrors status={status} />

        <QuestionnaireFields template={template} />
      </form>
    </FormikProvider>
  )
}

export function templateToFields(
  template: QuestionnaireModel,
): QuestionnaireFieldset {
  let fields: QuestionnaireFieldset = {}
  for (let section of template.sections) {
    for (let question of section.questions) {
      fields[`${question.id}`] =
        question.answer?.value || blankAnswerValueForQuestion(question)

      // Check if string is ISO date, if so convert to mm/dd/yyyy
      if (question.kind === 'date' && typeof fields[`${question.id}`] === "string" && (fields[`${question.id}`] as string).match(/^\d{4}-\d{2}-\d{2}$/)) {
        fields[`${question.id}`] = mmDdYyyy(fields[`${question.id}`] as string);
      }
    }
  }
  return fields
}

function blankAnswerValueForQuestion(question: QuestionModel): SomeAnswer['value'] {
  switch (question.kind) {
    case 'address':
      // TODO: When address is a google autocomplete field, make this an object with blank fields
      return ''
    case 'checkboxes':
      return []
    case 'date':
      return ''
    case 'dropdown':
      return ''
    case 'medication_list':
      // TODO: Maybe have a default value here
      return []
    case 'number':
      return 0
    case 'email':
      return ''
    case 'phone_number':
      return ''
    case 'preferred_provider':
      return ''
    case 'radio':
      return ''
    case 'signature':
      return ''
    case 'text':
      return ''
    case 'tooth_selector':
      return []
  }
}

export function mergeAnswers(
  template: QuestionnaireModel,
  values: QuestionnaireFieldset,
): QuestionnaireModel {
  let questionnaire = cloneDeep(template)

  for (let section of questionnaire.sections) {
    for (let question of section.questions) {
      question.answer = null;
      if (values[question.id]) {
        question.answer = {
          _kind: answerKindsForQuestionKind[question.kind] as any,
          value: values[question.id] as any,
        }
      }
    }
  }
  return questionnaire
}
