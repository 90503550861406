import React, { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { AddProviderForm } from "./AddProviderForm";
import { Button } from "@preferral/ui";

interface AddProviderModalProps {
  isOpen: boolean;
  openCreateProviderModal(): void;
  onClose(): void;
  onSuccess(): void;
}

export const AddProviderModal: FC<AddProviderModalProps> = (props) => {
  const { isOpen, onClose, openCreateProviderModal, onSuccess } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="user-doctor" title="Add Provider" onClose={onClose} />

      <div className="px-4">
        <div className="flex font-semibold gap-2 items-center justify-center mx-auto p-3 px-8 rounded-lg text-cool-gray-600 text-xs">
          <p>Can't find the provider you're looking for?</p>
          <Button
            type="button"
            color="blue"
            kind="tertiary"
            size="xs"
            onClick={openCreateProviderModal}
            className="font-semibold text-blue-600"
          >
            Create a provider
          </Button>
        </div>
        <AddProviderForm
          onSuccess={() => {
            onSuccess();
            return onClose();
          }}
        />
      </div>
    </Modal>
  );
};
