import "core-js/features/string/pad-start";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import { ENV, MIXPANEL_TOKEN } from "config/constants";
import "fontsource-inter/latin.css";
import "@fontsource/allura"
import "./styles/tailwind.dist.css";
import "./styles/ReactModal.css";
import { EnvironmentNotice } from "@preferral/ui";
import { App } from "./App";
import { AppProviders } from "./context";
import * as serviceWorker from "./serviceWorker";
import createIconLibrary from "./createIconLibrary";
import { analytics } from "lib/analytics";

if (process.env.REACT_APP_TRACKING_ENABLED === "false") {
  analytics.disableTracking()
} else {
  analytics.enableTracking()
}

createIconLibrary();

ReactModal.setAppElement("#root");

ReactDOM.render(
  <AppProviders>
    <App />
    <EnvironmentNotice isProduction={ENV === "PROD"}
    />
  </AppProviders>,
  document.getElementById("root")
);

analytics.init({
  mixpanel: {
    token: MIXPANEL_TOKEN!,
    config: { debug: ENV === "DEV", track_pageview: false }
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
