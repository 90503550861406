import React, { FC } from "react";
import { mmDdYyyy, hMmA } from "lib/dateFormatters";
import { ExclamationCircleIcon } from "components/TwoToneIcons/ExclamationCircleIcon";
import { DetailTable, DetailRow } from "components/DetailTable";
import { Coverage } from "./coverage";

interface CoverageLookupErrorProps {
  coverage: Coverage;
}

export const CoverageLookupError: FC<CoverageLookupErrorProps> = (props) => {
  const { coverage } = props;

  return (
    <>
      <div className="flex items-center">
        <div>
          <ExclamationCircleIcon className="h-6 w-6" color="orange" />
        </div>
        <div className="ml-2 flex items-baseline">
          <h4 className="font-semibold text-orange-700">Lookup Rejected</h4>
          <p className="ml-4 text-xs text-gray-600">
            Updated: {mmDdYyyy(coverage.json.created_at)} at{" "}
            {hMmA(coverage.json.created_at)}
          </p>
        </div>
      </div>
      <div className="mt-4">
        <div>
          <h5 className="text-sm font-semibold text-gray-900">Details</h5>
          <DetailTable>
            <DetailRow label="Rejection Reason">
              {coverage.json.error.reject_reason_description}
            </DetailRow>
            <DetailRow label="Details">
              {coverage.json.error.details ? (
                <p className="leading-tight">
                  {translateErrorDetails(coverage.json.error.details)}
                </p>
              ) : (
                <p className="text-gray-500">-</p>
              )}
            </DetailRow>
            <DetailRow label="Follow-Up Action" noWrapLabel>
              {coverage.json.error.follow_up_action_description}
            </DetailRow>
          </DetailTable>
        </div>
      </div>
    </>
  );
};

function translateErrorDetails(details: string): string {
  if (details.includes("Your NPI should be enrolled")) {
    return "The provider NPI used in the lookup needs to be enrolled with the selected carrier before making eligibility queries. Please contact support to assist with provider enrollment.";
  }
  return details;
}
