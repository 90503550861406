import React from "react";
import ReactModal from "react-modal";
import cx from "classnames";

type ModalProps = {
  isOpen: boolean;
  onRequestClose: () => any;
  className?: string;
  overlayClassName?: string;
  closeTimeoutMS?: number;
};

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  className,
  overlayClassName,
  children,
  closeTimeoutMS = 150,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={cx(
        "Modal z-40 w-full max-w-lg px-2 focus:outline-none",
        className
      )}
      overlayClassName={cx("Overlay z-40", overlayClassName)}
      closeTimeoutMS={closeTimeoutMS}
    >
      <div className="bg-white shadow-md rounded-lg">{children}</div>
    </ReactModal>
  );
};

export { Modal };
