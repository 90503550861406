import { FC, useCallback, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import { gql, useQuery } from "@apollo/client";
import { BlockSpinner } from "components/BlockSpinner";
import {
  NewAppointmentRequestParams,
  QueryData as Data,
  QueryVariables as Variables,
} from "./model";
import { SearchInterfaceProvider } from "./SearchInterfaceContext";
import { SearchResultCard } from "./SearchResultCard";
import { FilterPanel, FilterModel, defaultFilter } from "./FilterPanel";
import { analytics } from "lib/analytics";
import { ScreenTitle } from "../../../context/ScreenTitle";

const APPOINTMENT_REQUEST_SEARCH_RESULTS = gql`
  query AppointmentRequestSearchResults(
    $filter: AppointmentRequestSearchFilter
  ) {
    appointmentRequestSearchResults(filter: $filter) {
      endOfList
      cursor
      meta {
        specialty {
          id
          name
          defaultSearchMode
        }
        ribbonNetworkAvailable
        ribbonNetworkUnavailableReason
      }
      items {
        ribbonProvider {
          npi
          firstName
          lastName
          avatar(size: "medium") {
            base64
            src
            srcSet
          }
          locations {
            uuid
            name
            addressDetails {
              addressLine1
              addressLine2
              city
              state
              zip
            }
            latitude
            longitude
            distance
            phoneNumbers {
              details
              phone {
                formatted
              }
            }
            faxes {
              details
              fax {
                formatted
              }
            }
          }
        }
        nppesProvider {
          id
          nameWithAppellation
          avatar(size: "medium") {
            base64
            src
            srcSet
          }
          primarySpecialty {
            id
            name
          }
        }
        nppesLocations {
          id
          name
          streetAddress
          streetAddressLine2
          city
          stateAbbreviation
          zip
          geom {
            lat
            lng
          }
          distance
          timeZoneName
          phone {
            formatted
          }
          fax {
            formatted
          }
        }
        nppesLocation {
          id
          name
          streetAddress
          streetAddressLine2
          city
          stateAbbreviation
          zip
          geom {
            lat
            lng
          }
          distance
          timeZoneName
          phone {
            formatted
          }
          fax {
            formatted
          }
        }
        ribbonLocation {
          uuid
          name
          addressDetails {
            addressLine1
            addressLine2
            city
            state
            zip
          }
          latitude
          longitude
          distance
          phoneNumbers {
            details
            phone {
              formatted
            }
          }
          faxes {
            details
            fax {
              formatted
            }
          }
        }
        provider {
          id
          nameWithAppellation
          avatar(size: "medium") {
            base64
            src
            srcSet
          }
          primarySpecialty {
            id
            name
          }
        }
        location {
          id
          name
          streetAddress
          streetAddressLine2
          city
          stateAbbreviation
          zip
          geom {
            lat
            lng
          }
          distance
          timeZoneName
          phone {
            formatted
          }
          fax {
            formatted
          }
        }
      }
    }
  }
`;

interface RouteParams {
  referralId: string;
}

interface ResultsStepProps {}

export const ResultsStep: FC<ResultsStepProps> = props => {
  const match = useRouteMatch<RouteParams>();
  const { referralId } = match.params;

  const [filter, setFilter] = useState<FilterModel>(defaultFilter);

  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    APPOINTMENT_REQUEST_SEARCH_RESULTS,
    {
      variables: {
        filter: { ...filter, referralId },
      },
    }
  );

  const history = useHistory();

  useEffect(() => {
    if (!!data?.appointmentRequestSearchResults) {
      analytics.track("Referral Search Results", {
        referral_id: referralId,
        in_network: filter.onlyInNetwork,
        search_mode: filter.searchByLocation ? "location" : "provider",
        results_count: data?.appointmentRequestSearchResults.items.length,
        requested_specialty_name:
          data?.appointmentRequestSearchResults.meta.specialty.name,
      });
    }
  }, [data]);

  const onSelect = useCallback(
    (params: NewAppointmentRequestParams) => {
      const queryString = qs.stringify(params as any);
      return history.push(`/o/referral/${referralId}/confirm?${queryString}`);
    },
    [referralId, history]
  );

  return (
    <>
      <ScreenTitle title={["New Referral", "Results"]} />
      <div className="_ResultsStep flex h-full border-t border-gray-200 -mt-5 pb-28">
        <div className="fixed h-full top-10 left-15 px-6 py-3 border-r">
          <FilterPanel
            value={filter}
            onChange={setFilter}
            isLoading={loading}
            searchResultsMeta={
              data?.appointmentRequestSearchResults.meta || null
            }
          />
        </div>
        {loading ? (
          <div className="p-6 text-center w-full ml-72">
            <BlockSpinner />
          </div>
        ) : error || !data?.appointmentRequestSearchResults ? (
          <div className="p-6 text-center w-full ml-72">
            <p>
              Failed to load. Please try again or contact {""}
              <a className="link" href="mailto:support@preferral.com">
                support@aristamd.com
              </a>
            </p>
          </div>
        ) : data?.appointmentRequestSearchResults.items.length === 0 ? (
          <div className="p-6 text-center w-full ml-72">
            <p>No results found, please try a new search.</p>
          </div>
        ) : (
          <SearchInterfaceProvider
            searchResults={data.appointmentRequestSearchResults}
            refetch={refetch}
          >
            <div className="gap-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 pt-8 px-10 mx-auto ml-72 overflow-y-auto">
              {data.appointmentRequestSearchResults.items.map(
                (searchResult, idx) => (
                  <div
                    key={
                      searchResult.nppesProvider?.id ||
                      searchResult.provider?.id ||
                      searchResult.ribbonProvider?.npi ||
                      searchResult.ribbonLocation?.uuid ||
                      searchResult.location?.id ||
                      searchResult.nppesLocation?.id ||
                      idx
                    }
                  >
                    <SearchResultCard
                      key={idx}
                      specialty={
                        data.appointmentRequestSearchResults.meta.specialty
                      }
                      searchResult={searchResult}
                      onSelect={onSelect}
                    />
                  </div>
                )
              )}
            </div>
            {/* <pre className="text-xs text-left">
            {JSON.stringify(data, null, 2)}
          </pre> */}
          </SearchInterfaceProvider>
        )}
      </div>
    </>
  );
};
