import { FC, useCallback, useState } from "react";
import OfficeBuildingIcon from '@heroicons/react/outline/OfficeBuildingIcon';
import { useAppointmentRequest } from "../../AppointmentRequestContext";
import { Button, CircleIcon, FAIcon } from "@preferral/ui";
import { AssignLocationModal } from "./AssignLocationModal";
import { LocationModel } from "../../model";
import { AssignFaxModal } from "./AssignFaxModal/AssignFaxModal";

/**
 * LocationCard.
 */

interface LocationCardProps {
  location: LocationModel;
}



const LocationCard: FC<LocationCardProps> = (props) => {
  const { location } = props;

  type ActiveModal = "ASSIGN_FAX";

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const openAssignFaxModal = useCallback(
    () => setActiveModal("ASSIGN_FAX"),
    []
  );
  const closeModal = useCallback(() => setActiveModal(null), []);

  return (
    <>
      <AssignFaxModal
        isOpen={activeModal === "ASSIGN_FAX"}
        onClose={closeModal}
      />
      <div className="_LocationCard flex items-center gap-4 p-3 bg-white shadow rounded-lg border">
        <CircleIcon icon="map-marker-alt" className="flex-shrink-0" />
        <div>
          <p className="font-black text-cool-gray-700 text-lg">{location.name}</p>
          <div className="italic mt-1 text-cool-gray-500 text-xs">
            <p>{location.streetAddress}</p>
            {location.streetAddressLine2 && <p>{location.streetAddressLine2}</p>}
            <p>
              {location.city}, {location.stateAbbreviation}, {location.zip}
            </p>
          </div>
          <div className="-mx-3 flex flex-wrap items-center justify-around mt-3">
            {location.phone ? (
              <div className="flex-shrink-0 font-bold px-3 py-1 text-cool-gray-600 text-xs">
                <span className="mr-2 text-cool-gray-400">
                  <FAIcon icon="phone" />
                </span>
                {location.phone.formatted}
              </div>
            ) : null}
            {location.fax ? (
              <div className="flex-shrink-0 font-bold px-3 py-1 text-cool-gray-600 text-xs">
                <span className="mr-2 text-cool-gray-400">
                  <FAIcon icon="fax" />
                </span>
                {location.fax.formatted}
              </div>
            ) : (
              <div className="flex-shrink-0 font-bold px-3 py-1 text-cool-gray-600 text-xs">
                <button className="mr-2 text-blue-700" onClick={openAssignFaxModal}>Click to add fax number </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * NoLocation.
 */

const NoLocation: FC = () => {
  return (
    <div className="_NoLocation p-3 rounded bg-gray-50 border border-dotted text-center">
      <div className="flex items-center justify-center p-4">
        <OfficeBuildingIcon className="inline-block h-5 w-5 text-cool-gray-400" />
        <p className="ml-2 text-cool-gray-500 text-sm">No Assigned Location</p>
      </div>
    </div>
  );
};

interface RequestedLocationPanelProps { }

type ActiveModal = "ASSIGN_LOCATION";

export const RequestedLocationPanel: FC<RequestedLocationPanelProps> = () => {
  const {
    appointmentRequest: { iamReceiverOrganization, requestedLocation, requestedNppesLocation },
  } = useAppointmentRequest();

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const openAssignLocationModal = useCallback(() => setActiveModal("ASSIGN_LOCATION"), []);
  const closeModal = useCallback(() => setActiveModal(null), []);

  const location = requestedLocation || requestedNppesLocation;

  return (
    <>
      <AssignLocationModal
        isOpen={activeModal === "ASSIGN_LOCATION"}
        onClose={closeModal}
      />
      <div className="_RequestedLocationPanel">
        {/* <h4 className="text-xl py-4 font-semibold">Requested Location</h4> */}
        {!location ? (
          <>
            <NoLocation />
            {iamReceiverOrganization ? (
              <div className="_RequestedLocationFooter p-2 text-center">
                <Button
                  type="button"
                  color="blue"
                  size="sm"
                  kind="secondary"
                  onClick={openAssignLocationModal}
                >
                  <span className="mr-2">
                    <FAIcon icon="plus" />
                  </span>
                  Assign Location
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <>
            <LocationCard location={location} />
            {iamReceiverOrganization ? (
              <div className="_RequestedLocationFooter p-2 text-center">
                <Button
                  type="button"
                  color="blue"
                  size="xs"
                  kind="secondary"
                  onClick={openAssignLocationModal}
                >
                  <span className="mr-2">
                    <FAIcon icon="map-marker-alt" />
                  </span>
                  Change Location
                </Button>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};
