import { FC, createContext, useContext } from "react";
import { AppointmentRequestModel, SentAppointmentRequestModel, PatientMemberModel } from "./model";

interface State {
  refetch(): void;
  appointmentRequest: AppointmentRequestModel | SentAppointmentRequestModel;
  patientMember: PatientMemberModel;
}

const appointmentRequestContext = createContext<State>(null!);

type AppointmentRequestProviderProps = State;

export const AppointmentRequestProvider: FC<AppointmentRequestProviderProps> = (
  props
) => {
  const { refetch, appointmentRequest, patientMember, children } = props;

  return (
    <appointmentRequestContext.Provider value={{ refetch, appointmentRequest, patientMember }}>
      {children}
    </appointmentRequestContext.Provider>
  );
};

export function useAppointmentRequest() {
  const context = useContext(appointmentRequestContext);
  if (context === undefined) {
    throw new Error(
      `useAppointmentRequest must be used within an AppointmentRequestProvider`
    );
  }
  return context;
}
