import React, { FC } from "react";
import {
  useLocation,
  Switch,
  useRouteMatch,
  Redirect,
  Route,
} from "react-router-dom";
import qs from "query-string";
import { PatientStep } from "./PatientStep/PatientStep";
import { QuestionsStep } from "./QuestionsStep/QuestionsStep";
import { ResultsStep } from "./ResultsStep/ResultsStep";
import { ConfirmationStep } from "./ConfirmationStep/ConfirmationStep";
import {
  NavigationSteps,
  NavigationStepModel,
} from "components/NavigationSteps";

const steps: NavigationStepModel[] = [
  {
    label: "Patient Info",
    icon: "user",
  },
  {
    label: "Questions",
    icon: "clipboardList",
  },
  {
    label: "Results",
    icon: "search",
  },
  {
    label: "Confirmation",
    icon: "checkCircle",
  },
];

function getCurrentStep(pathname: string) {
  if (pathname.includes("/new")) return 0;
  if (pathname.includes("/questions")) return 1;
  if (pathname.includes("/results")) return 2;
  if (pathname.includes("/confirm")) return 3;
  return -1;
}

interface NewReferralScreenProps {}

export const NewReferralScreen: FC<NewReferralScreenProps> = props => {
  const location = useLocation();
  const match = useRouteMatch();
  const { patientId } = qs.parse(location.search);

  const currentStep = getCurrentStep(location.pathname);

  return (
    <div
      className={`NewInboundReferralScreen h-full ${
        currentStep === 2 ? "overflow-hidden" : ""
      }`}
    >
      <div
        className={`max-w-full mb-8 mx-12 sm:max-w-xl sm:mx-auto mt-3 transform transition-transform duration-300 ease-in-out ${
          currentStep === 2 ? "scale-75" : "scale-100"
        }`}
      >
        <NavigationSteps steps={steps} currentStep={currentStep} />
      </div>
      <Switch>
        <Route path={`${match.path}/new`}>
          <PatientStep patientMemberId={patientId as string | null} />
        </Route>
        <Route path={`${match.path}/:referralId/questions`}>
          <QuestionsStep />
        </Route>
        <Route path={`${match.path}/:referralId/results`}>
          <ResultsStep />
        </Route>
        <Route path={`${match.path}/:referralId/confirm`}>
          <ConfirmationStep />
        </Route>
        <Route path="*">
          <Redirect to={`${match.url}/new`} />
        </Route>
      </Switch>
    </div>
  );
};
