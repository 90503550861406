import { FC, useCallback } from "react";
import * as Yup from "yup";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { Button, SelectField } from "@preferral/ui";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import {
  LocationSelectOption,
  LocationOptionGqlFields,
  LocationModel,
} from "components/LocationOption";
import { useAppointmentRequest } from "../../../AppointmentRequestContext";

const DEPARTMENT_LOCATIONS = gql`
  query GetDepartmentLocations($departmentId: UUID4!) {
    departmentLocations(departmentId: $departmentId, first: 100) {
      cursor
      endOfList
      items {
        ${LocationOptionGqlFields}
      }
    }
  }
`;

interface Data {
  departmentLocations: Paginated<LocationModel>;
}

interface Variables {
  departmentId: string;
}

const ASSIGN_LOCATION = gql`
  mutation UpdateRequestedLocation(
    $appointmentRequestId: UUID4!
    $locationId: UUID4!
  ) {
    assignAppointmentRequestLocation(
      appointmentRequestId: $appointmentRequestId
      locationId: $locationId
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
      }
    }
  }
`;

interface MutationData {
  assignAppointmentRequestLocation: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appointmentRequestId: string;
  locationId: string;
}

interface FormValues {
  locationId: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    locationId: Yup.string().required("Required"),
  })
  .required();

interface AssignLocationFormProps {
  onSuccess(): void;
}

export const AssignLocationForm: FC<AssignLocationFormProps> = (props) => {
  const { onSuccess } = props;
  const {
    appointmentRequest: { id, requestedLocation, departmentId },
    refetch,
  } = useAppointmentRequest();

  const { data, loading } = useQuery<Data, Variables>(DEPARTMENT_LOCATIONS, {
    variables: { departmentId },
  });

  const [assignLocation] = useMutation<MutationData, MutationVariables>(
    ASSIGN_LOCATION
  );

  const locationOptions = data?.departmentLocations.items || [];

  const onSubmit = useCallback(
    (values, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;
      setStatus({ errors: null });
      return assignLocation({
        variables: { appointmentRequestId: id, locationId: values.locationId },
      }).then((resp) => {
        if (resp.data?.assignAppointmentRequestLocation.errors) {
          setStatus({
            errors: resp.data.assignAppointmentRequestLocation.errors,
          });
        } else if (
          resp.data?.assignAppointmentRequestLocation.appointmentRequest
        ) {
          // it worked....
          toast.success("Location assigned!");
          refetch();
          onSuccess();
        }
        setSubmitting(false);
      });
    },
    [id, assignLocation, onSuccess, refetch]
  );

  return (
    <Formik
      initialValues={{ locationId: requestedLocation?.id }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <SelectField
            name="locationId"
            label="Requested Location"
            options={locationOptions}
            isLoading={loading}
            getOptionLabel={(l: LocationModel) => l.name}
            getOptionValue={(l: LocationModel) => l.id}
            components={{ Option: LocationSelectOption }}
          />
          <div className="mt-3 text-center">
            <Button
              type="submit"
              kind="primary"
              color="blue"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Assign Location
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
