import React from "react";
import cx from "classnames";
import { Icon } from "@preferral/ui";
import "./NewThingModal.css";
import { Link } from "react-router-dom";
import { FAIcon } from "components/FAIcon";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { analytics } from "lib/analytics";

const TileButton: React.FC<{
  upgradeRequired?: boolean;
  onClick?: () => void;
  className?: string;
}> = ({ upgradeRequired = false, onClick, children, className }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cx(
        "TileButton bg-white border-2 border-cool-gray-400 focus:outline-none focus:shadow-outline hover:border-blue-400 hover:shadow-lg py-8 rounded-lg shadow text-center text-cool-gray-700 hover:text-blue-700",
        className
      )}
    >
      <div className="px-2">{children}</div>
    </button>
  );
};

const TileLink: React.FC<{
  upgradeRequired?: boolean;
  to: string;
  onClick?: () => void;
  className?: string;
}> = ({ upgradeRequired = false, to, children, className, onClick }) => {
  return (
    <Link
      to={to}
      className={cx(
        "TileButton bg-white border-2 border-cool-gray-400 focus:outline-none focus:shadow-outline hover:border-blue-400 hover:shadow-lg py-8 rounded-lg shadow text-center text-cool-gray-700 hover:text-blue-700",
        className
      )}
      onClick={onClick}
    >
      <div className="px-2">{children}</div>
    </Link>
  );
};

type NewThingModalProps = {
  onClose: () => void;
};

const NewThingModal: React.FC<NewThingModalProps> = ({ onClose }) => {
  const { hasFeature } = useFeatureFlags();
  const trackReferralCreation = () => {
    analytics.timeEvent('Referral Created');
    onClose();
  }
  return (
    <div className="NewThingModal bg-cool-gray-100 border-blue-500 border-t-4 rounded-lg">
      <div className="flex px-6 py-4 justify-between items-center">
        <div className="flex items-center">
          <div className="p-2 bg-blue-100 border border-blue-400 rounded-full">
            {/* <CircleIcon icon="plus" color="blue" /> */}
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="text-blue-600"
                d="M16 29.3334C23.3638 29.3334 29.3333 23.3638 29.3333 16C29.3333 8.63622 23.3638 2.66669 16 2.66669C8.63616 2.66669 2.66663 8.63622 2.66663 16C2.66663 23.3638 8.63616 29.3334 16 29.3334Z"
                fill="currentColor"
              />
              <path
                className="text-white"
                d="M17.3333 14.6667H22.6667C23.0203 14.6667 23.3594 14.8071 23.6095 15.0572C23.8595 15.3072 24 15.6464 24 16C24 16.3536 23.8595 16.6928 23.6095 16.9428C23.3594 17.1929 23.0203 17.3333 22.6667 17.3333H17.3333V22.6667C17.3333 23.0203 17.1929 23.3594 16.9428 23.6095C16.6928 23.8595 16.3536 24 16 24C15.6464 24 15.3072 23.8595 15.0572 23.6095C14.8071 23.3594 14.6667 23.0203 14.6667 22.6667V17.3333H9.33333C8.97971 17.3333 8.64057 17.1929 8.39052 16.9428C8.14048 16.6928 8 16.3536 8 16C8 15.6464 8.14048 15.3072 8.39052 15.0572C8.64057 14.8071 8.97971 14.6667 9.33333 14.6667H14.6667V9.33333C14.6667 8.97971 14.8071 8.64057 15.0572 8.39052C15.3072 8.14048 15.6464 8 16 8C16.3536 8 16.6928 8.14048 16.9428 8.39052C17.1929 8.64057 17.3333 8.97971 17.3333 9.33333V14.6667Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div className="ml-4">
            <h4 className="font-bold text-2xl text-cool-gray-700">
              Start a New...
            </h4>
          </div>
        </div>
        <button
          className="text-gray-500 hover:text-cool-gray-700 text-xl"
          onClick={onClose}
        >
          <FAIcon icon="times" />
        </button>
      </div>
      <div className="py-16 px-20">
        <div className="flex items-center justify-around -mx-2">
          <div className="flex flex-col">
            <div className="flex justify-around px-2">
              {
                hasFeature("referrals") ? (
                  <TileLink to="/o/referral/new" onClick={()=>trackReferralCreation()} className="mr-4">
                    <div className="flex flex-col h-32 py-2 items-center justify-between">
                      <Icon icon="inbox-upload" size={64} />
                      <p className="font-semibold leading-tight">Referral</p>
                    </div>
                  </TileLink>
                ) : null
              }
              {
                hasFeature("text_messaging") ? (
                  <TileButton>
                    <div className="flex flex-col h-32 py-2 items-center justify-between">
                      <Icon icon="chat-group" size={64} />
                      <p className="font-semibold leading-tight">Text Message</p>
                    </div>
                  </TileButton>
                ) : null
              }
            </div>
            <div className="flex justify-around px-2">
              {/* <TileButton>
              <div className="flex flex-col h-32 py-2 items-center justify-between">
                <Icon icon="mail" size={64} />
                <p className="font-semibold leading-tight">Direct Message</p>
              </div>
            </TileButton> */}
              {
                hasFeature("econsults") ? (
                  <TileLink to="/o/new_econsult" onClick={onClose} className="mt-4 mr-4">
                    <div className="flex flex-col h-32 py-2 items-center justify-between">
                      <Icon icon="user-couple" size={64} />
                      <p className="font-semibold leading-tight">eConsult</p>
                    </div>
                  </TileLink>
                ) : null
              }
              {
                hasFeature("fax_inboxes") ? (
                  <TileButton className="mt-4">
                    <div className="flex flex-col h-32 py-2 items-center justify-between">
                      <Icon icon="fax" size={64} />
                      <p className="font-semibold leading-tight">Fax</p>
                    </div>
                  </TileButton>
                ) : null
              }
            </div>
          </div>

          <div className="bg-cool-gray-400 h-48 mx-3" style={{ width: 2 }} />
          <div className="flex flex-col px-2">
            {
              hasFeature("referrals") ? (
                <TileLink to="/o/inbound_referral/new" onClick={()=>trackReferralCreation()}>
                  <div className="flex flex-col h-32 py-2 items-center justify-between">
                    <Icon icon="inbox-download" size={64} />
                    <p className="font-semibold leading-tight">Inbound Referral</p>
                  </div>
                </TileLink>
              ) : null
            }
            <TileLink
              to="/o/patients?open=new"
              className="mt-4"
              onClick={onClose}
            >
              <div className="flex flex-col h-32 py-2 items-center justify-between">
                <Icon icon="user-add" size={64} />
                <p className="font-semibold leading-tight">Patient</p>
              </div>
            </TileLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export { NewThingModal };
