import { FC, useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { Drawer, DrawerHeader } from "components/Drawer";
import {
  Spinner,
  FAIcon,
  TableContainer,
  Table,
  TH,
  TD,
  Button,
  VerticalField,
  makeAppendItems,
} from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { NoResults } from "components/NoResults";
import { ViewPatientFormSubmission } from "./ViewPatientFormSubmission";
import { FilterPanel, defaultFilter, FilterModel } from "./FilterPanel";
import { SendFormModal } from "../SendFormModal";
import { ZERO_WIDTH_SPACE } from "@preferral/common";
import { useHistory, useRouteMatch } from "react-router-dom";

const PAGE_SIZE = 30;

const LIST_PATIENT_FORM_SUBMISSIONS = gql`
  query ListPatientFormSubmissions(
    $first: Int
    $after: UUID4
    $filter: PatientFormSubmissionsFilter
  ) {
    patientFormSubmissions(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        title
        insertedAt
        patientForm {
          id
          title
        }
      }
    }
  }
`;

interface Data {
  patientFormSubmissions: Paginated<PatientFormSubmission>;
}

interface PatientFormSubmission {
  id: string;
  title: string;
  insertedAt: string;
  patientForm: {
    id: string;
    title: string;
  };
}

type ActiveModal = "SEND_FORM";

interface PatientFormSubmissionsScreenProps { }

export const PatientFormSubmissionsScreen: FC<PatientFormSubmissionsScreenProps> = () => {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const closeDrawer = () => setSelectedId(null);

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const closeModal = () => setActiveModal(null);

  const [filter, setFilter] = useState<FilterModel>(defaultFilter);

  const { data, loading, error, fetchMore } = useQuery<Data>(
    LIST_PATIENT_FORM_SUBMISSIONS,
    { variables: { first: PAGE_SIZE, filter } }
  );

  // This is to handle specific form submission URLs, such as those sent in staff emails.
  const match = useRouteMatch();
  const specificMatch = useRouteMatch<{ patientFormSubmissionId: string }>(`${match.path}/:patientFormSubmissionId`);
  const history = useHistory();

  useEffect(() => {
    if (specificMatch) {
      setSelectedId(specificMatch.params.patientFormSubmissionId)
      history.replace(match.url)
    }
  }, [match.url, specificMatch, setSelectedId, history])

  return (
    <div className="_PatientFormSubmissionsScreen text-left">
      <ScreenTitle title={["Patient Forms", "Submissions"]} />

      <SendFormModal
        isOpen={activeModal === "SEND_FORM"}
        onClose={closeModal}
      />

      <Drawer width="3xl" isOpen={!!selectedId} onRequestClose={closeDrawer}>
        <DrawerHeader
          icon="file-alt"
          title="Form Submission"
          onClose={closeDrawer}
        />
        {selectedId && (
          <ViewPatientFormSubmission patientFormSubmissionId={selectedId} />
        )}
      </Drawer>
      <div className="container mx-auto">
        <div className="flex justify-between items-start py-4 px-2 lg:px-4">
          <FilterPanel value={filter} onChange={setFilter} isLoading={loading} />
          <div spec-id="patient-forms-action">
            <VerticalField label={ZERO_WIDTH_SPACE}>
              <Button color="blue" onClick={() => setActiveModal("SEND_FORM")}>
                <span className="mr-2">
                  <FAIcon icon="paper-plane" />
                </span>
                Send Form
              </Button>
            </VerticalField>
          </div>
        </div>
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.patientFormSubmissions ? (
          <p>Failed to load</p>
        ) : (
          <div className="pb-8 lg:px-4">
            {data.patientFormSubmissions.items.length === 0 ? (
              <NoResults
                icon="file-alt"
                text="No Patient Form Submissions Yet"
              />
            ) : (
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <TH>Form</TH>
                      <TH>Submitted At</TH>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {data.patientFormSubmissions.items.map((fs) => (
                      <tr key={fs.id}>
                        <TD>
                          <p
                            onClick={() => setSelectedId(fs.id)}
                            className="text-lg text-blue-500 underline cursor-pointer"
                          >
                            {fs.title}
                          </p>
                        </TD>
                        <TD>{fs.insertedAt}</TD>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {data.patientFormSubmissions.items.length > 0 ? (
                  <div className="bg-white border-gray-300 border-t p-2 text-center text-gray-500">
                    {data.patientFormSubmissions.endOfList ? (
                      <p>End of List</p>
                    ) : (
                      <div className="max-w-sm mx-auto">
                        <Button
                          type="button"
                          size="sm"
                          kind="secondary"
                          color="blue"
                          className="w-full"
                          onClick={() =>
                            fetchMore({
                              query: LIST_PATIENT_FORM_SUBMISSIONS,
                              variables: {
                                first: PAGE_SIZE,
                                after: data.patientFormSubmissions.cursor,
                                filter,
                              },
                              updateQuery,
                            })
                          }
                        >
                          Load More
                        </Button>
                      </div>
                    )}
                  </div>
                ) : null}
              </TableContainer>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const updateQuery = makeAppendItems<Data>("patientFormSubmissions");
