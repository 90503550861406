import { FC, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import CheckCircleIcon from "@heroicons/react/solid/CheckCircleIcon";
import {
  Spinner,
  TableContainer,
  Table,
  TH,
  TD,
  Button,
  makeAppendItems,
  Badge,
  FAIcon,
  Tooltip,
  CircleProgress,
} from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { NoResults } from "components/NoResults";
import { distanceInWords, hMmA, mmDdYyyy } from "lib/dateFormatters";
import {
  FilterPanel,
  FilterModel,
  removeVoidKeys,
  removeUnusedFilterKeys,
  defaultFilter,
} from "./FilterPanel";
import { AppointmentRequestEventModel } from "../../AppointmentRequestShowScreen/model";
import { APPOINTMENT_REQUEST_EVENT_FRAGMENT } from "screens/AppointmentRequestShowScreen/fragments";
import { EventIndicator } from "../components/EventIndicatorIcon";
import { TaskModel } from "../../AppointmentRequestShowScreen/model";
import { UserAvatar } from "components/UserAvatar";

const PAGE_SIZE = 50;

const LIST_SENT_REFERRALS = gql`
  query ListSentReferrals(
    $first: Int
    $after: UUID4
    $filter: ListSentReferralsFilter
  ) {
    sentReferrals(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        insertedAt
        updatedAt
        referringPatientMember {
          id
          medicalRecordNumber
          patient {
            id
            name
            firstName
            lastName
            dob
            primaryPhone {
              raw
              formatted
            }
          }
        }
        appointmentRequest {
          id
          status
          insertedAt
          latestAppointment {
            id
          }
          requestedSpecialty {
            id
            name
          }
          assignedReferringUser {
            id
            firstName
            lastName
            name
          }
          requestedProvider {
            id
            nameWithAppellation
          }
          requestedNppesProvider {
            id
            nameWithAppellation
          }
          requestedLocation {
            id
            name
          }
          requestedNppesLocation {
            id
            name
            organization {
              id
              name
            }
          }
          appointmentRequestEvents {
            ...AppointmentRequestEventFields
          }
          department {
            id
            organization {
              id
              name
            }
          }
          tasks {
            id
            label
            dueAt
            isDue
            done
            createdByUser {
              id
              firstName
              lastName
              organization {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  ${APPOINTMENT_REQUEST_EVENT_FRAGMENT}
`;

interface Data {
  sentReferrals: Paginated<SentReferralModel>;
}

interface SentReferralModel {
  id: string;
  insertedAt: string;
  updatedAt: string;
  appointmentRequest: SentAppointmentRequestModel;
  referringPatientMember: PatientMemberModel;
}

interface PatientMemberModel {
  id: string;
  medicalRecordNumber?: string;
  patient: PatientModel;
}

interface PatientModel {
  id: string;
  name: string;
  dob: string;
  primaryPhone: {
    raw: string;
    formatted: string;
  };
}

interface SentAppointmentRequestModel {
  id: string;
  status: AppointmentRequestStatusModel;
  insertedAt: string;
  latestAppointment: null | {
    id: string;
    status: string;
  };
  assignedReferringUser: null | {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
  };
  requestedSpecialty: {
    id: string;
    name: string;
  };
  requestedProvider: null | {
    id: string;
    npi: string;
    nameWithAppellation: string;
  };
  requestedNppesProvider: null | {
    id: string;
    npi: string;
    nameWithAppellation: string;
  };
  requestedLocation: null | {
    id: string;
    name: string;
  };
  requestedNppesLocation: null | {
    id: string;
    name: string;
    organization: {
      id: string;
      name: string;
    };
  };
  appointmentRequestEvents: AppointmentRequestEventModel[];
  department: null | {
    id: string;
    organization: {
      id: string;
      name: string;
    };
  };
  tasks: TaskModel[];
}

type AppointmentRequestStatusModel =
  | "OPEN"
  | "SCHEDULED"
  | "REJECTED"
  | "EXHAUSTED"
  | "WITHDRAWN"
  | "CLOSED";

/**
 * StatusBadge.
 */

const badges: Record<
  Exclude<AppointmentRequestStatusModel, "SCHEDULED">,
  [AppColor, string]
> = {
  OPEN: ["indigo", "Open"],
  REJECTED: ["red", "Rejected"],
  EXHAUSTED: ["gray", "Exhausted"],
  WITHDRAWN: ["gray", "Withdrawn"],
  CLOSED: ["gray", "Closed"],
};

interface StatusBadgeProps {
  status: AppointmentRequestStatusModel;
}

export const StatusBadge: FC<StatusBadgeProps> = props => {
  const { status } = props;

  if (status === "SCHEDULED") {
    return (
      <span className="flex text-sm font-semibold items-center px-5 py-1 text-green-800">
        <CheckCircleIcon className="w-4 h-4 mr-2 text-green-500" />
        Scheduled
      </span>
    );
  }

  const [color, label] = badges[status];

  return <Badge color={color}>{label}</Badge>;
};

/**
 * ReferralRecipient.
 */

interface ReferralRecipientProps {
  referral: SentReferralModel;
}

const ReferralRecipient: FC<ReferralRecipientProps> = props => {
  const { referral } = props;
  const { appointmentRequest } = referral;

  const providerName =
    appointmentRequest.requestedProvider?.nameWithAppellation ||
    appointmentRequest.requestedNppesProvider?.nameWithAppellation;
  const locationName =
    appointmentRequest.requestedLocation?.name ||
    appointmentRequest.requestedNppesLocation?.name;
  const orgName =
    appointmentRequest.department?.organization.name ||
    appointmentRequest.requestedNppesLocation?.organization.name;

  return (
    <div className="_ReferralRecipient">
      <div className="_provider flex items-center">
        <FAIcon icon="user-md" className="mr-2 text-gray-500" />
        {providerName ? (
          providerName
        ) : (
          <span className="text-gray-600 text-light italic">Unassigned</span>
        )}
      </div>
      <div className="_location flex items-center">
        <FAIcon icon="map-marker-alt" className="mr-2 text-gray-500" />
        {locationName ? (
          locationName
        ) : (
          <span className="text-gray-600 text-light italic">Unassigned</span>
        )}
      </div>
      <div className="_organization flex items-center">
        <FAIcon icon="hospital-alt" className="mr-2 text-gray-500" />
        {orgName}
      </div>
    </div>
  );
};

/**
 * SentReferralsScreen.
 */

interface SentReferralsScreenProps {}

export const SentReferralsScreen: FC<SentReferralsScreenProps> = props => {
  const [rawFilter, setFilter] = useState<FilterModel>(defaultFilter);

  const filter = useMemo(
    () => removeUnusedFilterKeys(removeVoidKeys(rawFilter)),
    [rawFilter]
  );

  const {
    data,
    loading,
    error,
    fetchMore: doFetchMore,
  } = useQuery<Data>(LIST_SENT_REFERRALS, {
    variables: { filter },
    nextFetchPolicy: "network-only",
  });

  function fetchMore() {
    return doFetchMore({
      query: LIST_SENT_REFERRALS,
      variables: {
        first: PAGE_SIZE,
        after: data?.sentReferrals.cursor,
        filter,
      },
      updateQuery,
    });
  }

  function areTasksDone(tasks: TaskModel[]) {
    return tasks.every(t => {
      return t.done;
    });
  }

  return (
    <>
      <ScreenTitle title={["Referrals", "Sent"]} />
      <div className="_SentReferralsScreen text-left container mx-auto">
        <div className="py-4 lg:px-4 max-w-5xl">
          <FilterPanel
            value={rawFilter}
            onChange={setFilter}
            isLoading={loading}
          />
        </div>
        {loading ? (
          <div className="p-6 text-center">
            <Spinner />
          </div>
        ) : error || !data?.sentReferrals ? (
          <p>Failed to load</p>
        ) : data.sentReferrals.items.length === 0 ? (
          <div className="pb-8 lg:px-4">
            <NoResults icon="inbox-out" text="No matching sent referrals" />
          </div>
        ) : (
          <div className="pb-8 lg:px-4">
            <TableContainer>
              <Table className="text-left">
                <thead>
                  <tr>
                    <TH className="text-center">
                      <FAIcon icon="user-tag" />
                    </TH>
                    <TH>Patient</TH>
                    <TH>Specialty</TH>
                    <TH>Referred To</TH>
                    <TH>Progress</TH>
                    <TH className="text-center" style={{ width: 174 }}>
                      Status
                    </TH>
                    <TH>Sent At</TH>
                  </tr>
                </thead>
                <tbody>
                  {data.sentReferrals.items.map(r => (
                    <>
                      <EventIndicator
                        appointmentRequestEvents={
                          r.appointmentRequest.appointmentRequestEvents
                        }
                      />
                      <tr
                        key={r.id}
                        className={`${
                          areTasksDone(r.appointmentRequest.tasks) ||
                          r.appointmentRequest.tasks.length === 0 ||
                          r.appointmentRequest.tasks.find(
                            t => !t.done && t.isDue
                          )
                            ? "bg-white opacity-1"
                            : "bg-gray-200 opacity-75"
                        } hover:bg-blue-50 transition-colors duration-300`}
                      >
                        <TD
                          className="text-center"
                          style={{ paddingLeft: "1rem", paddingRight: 0 }}
                        >
                          {r.appointmentRequest.assignedReferringUser ? (
                            <Tooltip
                              tip={
                                r.appointmentRequest.assignedReferringUser.name
                              }
                            >
                              <div className="inline-block">
                                <UserAvatar
                                  className="inline-block"
                                  firstName={
                                    r.appointmentRequest.assignedReferringUser
                                      .firstName
                                  }
                                  lastName={
                                    r.appointmentRequest.assignedReferringUser
                                      .lastName
                                  }
                                />
                              </div>
                            </Tooltip>
                          ) : null}
                        </TD>
                        <TD>
                          <div className="flex items-center">
                            <div>
                              <Link
                                to={`/o/sent_requests/${r.appointmentRequest.id}`}
                                className="text-sm font-medium text-blue-600 hover:text-gray-700"
                              >
                                {r.referringPatientMember.patient.name}
                              </Link>
                              <div className="text-sm text-gray-500">
                                <span className="font-light italic mr-1">
                                  DOB:{" "}
                                </span>
                                {mmDdYyyy(r.referringPatientMember.patient.dob)}
                              </div>
                            </div>
                          </div>
                        </TD>
                        <TD>
                          <p>{r.appointmentRequest.requestedSpecialty.name}</p>
                        </TD>
                        <TD>
                          <ReferralRecipient referral={r} />
                        </TD>
                        <TD>
                          {r.appointmentRequest.tasks.length > 0 ? (
                            <CircleProgress
                              value={getProgressBarData(
                                r.appointmentRequest.tasks
                              )}
                              styles={{
                                textSize: "26px",
                                textColor: "#1A56DB",
                                pathColor: "#3E82F8",
                              }}
                            ></CircleProgress>
                          ) : null}
                        </TD>
                        <TD className="text-center">
                          <StatusBadge status={r.appointmentRequest.status} />
                        </TD>
                        <TD style={{ width: 172 }}>
                          <div className="group text-xs">
                            <p className="text-gray-500 group-hover:hidden">
                              {distanceInWords(r.appointmentRequest.insertedAt)}{" "}
                              ago
                            </p>
                            <p className="text-gray-500 hidden group-hover:block">
                              {mmDdYyyy(r.appointmentRequest.insertedAt)}{" "}
                              {hMmA(r.appointmentRequest.insertedAt)}
                            </p>
                          </div>
                        </TD>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
              <div className="bg-white border-gray-300 border-t p-2 text-center text-gray-500">
                {data.sentReferrals.endOfList ? (
                  <p>End of List</p>
                ) : (
                  <Button
                    type="button"
                    size="sm"
                    kind="secondary"
                    color="blue"
                    className="w-full"
                    onClick={fetchMore}
                  >
                    Load More
                  </Button>
                )}
              </div>
            </TableContainer>
          </div>
        )}
      </div>
    </>
  );
};

const updateQuery = makeAppendItems<Data>("sentReferrals");

function getProgressBarData(tasks: TaskModel[]) {
  const completedTasks = tasks.filter(t => t.done).length;
  const totalTaks = tasks.length;
  return (completedTasks / totalTaks) * 100;
}
