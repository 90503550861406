import styled from 'styled-components/macro';
import myBubbleTail from './my_bubble_tail.svg';
import theirBubbleTail from './their_bubble_tail.svg';

interface ChatBubbleProps {
  sent?: boolean;
  last?: boolean;
}

export const ChatBubble = styled.div<ChatBubbleProps>`
  position: relative;
  padding: 1rem;
  border-width: 1px;
  border-radius: 1.5rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);

  /* NB: Make the li bullets darker. */
  & .prose ul>li:before {
    background-color: #161e2e;
  }

  & .prose p { margin: 0; }
  & .prose ul { margin: 0; }
  & .prose ul>li { margin: 0; }
  & .prose ol { margin: 0; }
  & .prose ol>li { margin: 0; }

  ${props => props.sent ? (`
    background-color: #c3ddfd;
    border-color: #a4cafe;
  `) : (`
    background-color: #f4f5f7;
    border-color: #d2d6dc;
  `)}

  ${props => props.last && (
    props.sent ? (`
      &:before {
        content: "";
        background-image: url('${myBubbleTail}');
        position: absolute;
        z-index: 0;
        bottom: 0;
        right: -11px;
        height: 23px;
        width: 22px;
      }
    `) : (`
      &:before {
        content: "";
        background-image: url('${theirBubbleTail}');
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: -13px;
        height: 23px;
        width: 20px;
      }
    `)
  )}
`;
