import { FC } from 'react';
import { Modal, ModalHeader } from 'components/Modal';
import { CancelAppointmentForm } from './CancelAppointmentForm';

interface CancelAppointmentModalProps {
  appointmentId: string;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

export const CancelAppointmentModal: FC<CancelAppointmentModalProps> = (props) => {
  const { appointmentId, isOpen, onClose, onSuccess } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        color="red"
        icon={["far", "calendar-times"]}
        onClose={onClose}
        title="Cancel Appointment"
      />
      <div className="_CancelAppointmentModal p-4">
        <CancelAppointmentForm
          appointmentId={appointmentId}
          onSuccess={onSuccess}
        />
      </div>
    </Modal>
  );
};
