import React, { FC } from "react";
import { Formik } from "formik";
import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField, SingleCheckboxInput, SelectField } from "@preferral/ui";
import { validPassword } from "lib/validPassword";

const INVITATION_REGISTER = gql`
  mutation RegisterViaInvitation($input: RegisterViaInvitationInput!) {
    registerViaInvitation(input: $input) {
      errors {
        key
        message
      }
      session {
        token
      }
    }
  }
`;

interface RegisterViaInvitationInput {
  id: string;
  token: string;
  timeZoneName: string;
  password: string;
  passwordConfirmation: string;
  acceptsTermsOfService: boolean;
}

interface Data {
  registerViaInvitation: {
    errors?: InputError[];
    session?: {
      token: string;
    };
  };
}

interface Variables {
  input: RegisterViaInvitationInput;
}

type InvitationRegisterFormProps = {
  id: string;
  token: string;
  timeZoneName: string;
  timeZoneNames: string[];
  onSignIn: (jwt: string) => any;
};

export const InvitationRegisterForm: FC<InvitationRegisterFormProps> = ({
  id,
  token,
  timeZoneName,
  timeZoneNames,
  onSignIn,
}) => {
  return (
    <>
      <Mutation<Data, Variables> mutation={INVITATION_REGISTER}>
        {(updateAccount) => (
          <Formik
            initialValues={{
              timeZoneName,
              password: "",
              passwordConfirmation: "",
              acceptsTermsOfService: false,
            }}
            validationSchema={Yup.object().shape({
              password: validPassword,
              passwordConfirmation: validPassword,
              acceptsTermsOfService: Yup.bool()
                .test(
                  "acceptsTermsOfService",
                  "You must agree to the terms of service to continue.",
                  (value) => value === true
                )
                .required("Required"),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              setStatus({ errors: null });
              const input = {
                ...values,
                id,
                token,
              };
              updateAccount({ variables: { input } }).then((resp) => {
                if (
                  resp &&
                  resp.data &&
                  resp.data.registerViaInvitation.errors
                ) {
                  setStatus({
                    errors: resp.data.registerViaInvitation.errors,
                  });
                } else if (
                  resp &&
                  resp.data &&
                  resp.data.registerViaInvitation.session
                ) {
                  const { token } = resp.data.registerViaInvitation.session;

                  onSignIn(token);
                }
                setSubmitting(false);
              });
            }}
          >
            {({ status, isSubmitting, handleSubmit }) => (
              <div style={{ padding: "1.5rem" }}>
                <form onSubmit={handleSubmit}>
                  <FormStatusErrors status={status} />

                  <SelectField
                    name="timeZoneName"
                    label="Your Time Zone"
                    options={timeZoneNames.map((tz: string) => ({
                      label: tz,
                      value: tz,
                    }))}
                  />

                  <TextField
                    name="password"
                    placeholder="••••••••••"
                    label="Choose a Password"
                    type="password"
                  />

                  <TextField
                    name="passwordConfirmation"
                    placeholder="••••••••••"
                    label="Confirm Password"
                    type="password"
                  />

                  <SingleCheckboxInput
                    name="acceptsTermsOfService"
                    checkboxLabel={
                      <>
                        I accept the{" "}
                        <button type="button">Terms of Service</button>
                      </>
                    }
                  />

                  <div className="field" style={{ marginTop: "1.5rem" }}>
                    <div className="control has-text-centered">
                      <button
                        className="button is-link"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </Formik>
        )}
      </Mutation>
    </>
  );
};
