import { FC, useState, useCallback } from "react";
import UserIcon from '@heroicons/react/outline/UserIcon';
import { useAppointmentRequest } from "../../AppointmentRequestContext";
import { AssignProviderModal } from "./AssignProviderModal";
import { Button, FAIcon, Avatar } from "@preferral/ui";
import { RequestedProviderModel } from "../../model";

/**
 * ProviderCard.
 */

interface ProviderCardProps {
  provider: RequestedProviderModel;
}

const ProviderCard: FC<ProviderCardProps> = (props) => {
  const { provider } = props;
  return (
    <div className="_ProviderCard flex items-center gap-4 p-3 bg-white shadow rounded-lg border">
      <Avatar image={provider.avatar} size="sm" />
      <div>
        <p className="font-black text-cool-gray-700 text-xl">
          {provider.nameWithAppellation}
        </p>
        <p className="italic text-cool-gray-500 text-xs">
          {provider.primarySpecialty?.name}
        </p>
      </div>
    </div>
  );
};

/**
 * NoProvider.
 */

const NoProvider: FC = () => {
  return (
    <div className="_NoProvider p-3 rounded bg-gray-50 border border-dotted text-center">
      <div className="flex items-center justify-center p-4">
        <UserIcon className="inline-block h-5 w-5 text-cool-gray-400" />
        <p className="ml-2 text-cool-gray-500 text-sm">No Assigned Provider</p>
      </div>
    </div>
  );
};

interface RequestedProviderPanelProps { }

type ActiveModal = "ASSIGN_PROVIDER";

export const RequestedProviderPanel: FC<RequestedProviderPanelProps> = (
  props
) => {
  const {
    appointmentRequest: { iamReceiverOrganization, requestedProvider, requestedNppesProvider },
  } = useAppointmentRequest();

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const openAssignProviderModal = useCallback(
    () => setActiveModal("ASSIGN_PROVIDER"),
    []
  );
  const closeModal = useCallback(() => setActiveModal(null), []);

  const provider = requestedProvider || requestedNppesProvider;

  return (
    <>
      <AssignProviderModal
        isOpen={activeModal === "ASSIGN_PROVIDER"}
        onClose={closeModal}
      />
      <div className="_RequestedProviderPanel">
        <h4 className="text-xl py-4 font-semibold">
          Requested Provider / Location
        </h4>
        {!provider ? (
          <>
            <NoProvider />
            {iamReceiverOrganization ? (
              <div className="_RequestedProviderFooter p-2 text-center">
                <Button
                  type="button"
                  color="blue"
                  size="sm"
                  kind="secondary"
                  onClick={openAssignProviderModal}
                >
                  <span className="mr-2">
                    <FAIcon icon="plus" />
                  </span>
                  Assign Provider
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <>
            <ProviderCard provider={provider} />
            {iamReceiverOrganization ? (
              <div className="_RequestedProviderFooter p-2 text-center">
                <Button
                  type="button"
                  color="blue"
                  size="xs"
                  kind="secondary"
                  onClick={openAssignProviderModal}
                >
                  <span className="mr-2">
                    <FAIcon icon="user-doctor" />
                  </span>
                  Change Provider
                </Button>
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};
