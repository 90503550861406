import { FC, ComponentType } from "react";
import UserIcon from '@heroicons/react/outline/UserIcon'
import PencilAltIcon from '@heroicons/react/outline/PencilAltIcon'
import ClipboardListIcon from '@heroicons/react/outline/ClipboardListIcon'
import CalendarIcon from '@heroicons/react/outline/CalendarIcon'
import CheckCircleIcon from '@heroicons/react/outline/CheckCircleIcon'
import CheckIcon from '@heroicons/react/outline/CheckIcon'
import SearchIcon from '@heroicons/react/outline/SearchIcon'

type IconName =
  | "user"
  | "search"
  | "pencilAlt"
  | "clipboardList"
  | "calendar"
  | "checkCircle"
  | "check";

const iconMap: Record<IconName, ComponentType<{ className?: string }>> = {
  user: UserIcon,
  search: SearchIcon,
  pencilAlt: PencilAltIcon,
  clipboardList: ClipboardListIcon,
  calendar: CalendarIcon,
  checkCircle: CheckCircleIcon,
  check: CheckIcon,
};

/**
 * NavigationSteps.
 */

export type NavigationStepModel = {
  label: string;
  icon: IconName;
};

interface NavigationStepsProps {
  currentStep: number;
  steps: NavigationStepModel[];
}

export const NavigationSteps: FC<NavigationStepsProps> = (props) => {
  const { currentStep, steps } = props;

  function getStepState(stepIndex: number) {
    if (currentStep === stepIndex) return "selected";
    if (stepIndex < currentStep) return "active";
    return "inactive";
  }

  return (
    <div className="navigationSteps flex items-center pb-8">
      {steps.map((step, idx) => (
        <NavigationStep
          key={step.label}
          state={getStepState(idx)}
          step={step}
          showSeparator={idx < steps.length - 1}
        />
      ))}
    </div>
  );
};

/**
 * NavigationStepBackground.
 */

export type NavigationStepState = "inactive" | "active" | "selected";

interface NavigationStepBackgroundProps {
  state: NavigationStepState;
}

const NavigationStepBackground: FC<NavigationStepBackgroundProps> = (props) => {
  const { state, children } = props;

  const cn = [
    "h-10 w-10 flex items-center justify-center rounded-full border transition-colors ease-in-out duration-300",
    state !== "active" && "border-transparent",
    state === "active" &&
    "bg-green-100 text-green-500 shadow-inner border-green-300",
    state === "selected" && "bg-blue-500 text-white",
    state === "inactive" && "bg-gray-100 text-gray-400",
  ]
    .filter(Boolean)
    .join(" ");

  return <div className={cn}>{children}</div>;
};

/**
 * NavigationStepLine.
 */

const NavigationStepLine: FC = () => {
  return (
    <div className="navigationStepLine border-gray-400 border-t-2 flex-1 mx-1 lg:mx-4" />
  );
};

/**
 * NavigationStepLabel.
 */

const NavigationStepLabel: FC = ({ children }) => {
  return (
    <div className="absolute mt-1 ml-1/2 transform -translate-x-1/2 font-semibold text-xs whitespace-no-wrap">
      {children}
    </div>
  );
};

/**
 * Individual NavigationStep.
 */

interface NavigationStepProps {
  step: NavigationStepModel;
  state: NavigationStepState;
  showSeparator: boolean;
}

const navigationStepClassNames: Record<NavigationStepState, string> = {
  active: "shadow-lg text-gray-800 cursor-pointer",
  selected: "text-gray-800 cursor-pointer",
  inactive: "text-gray-500",
};

const NavigationStep: FC<NavigationStepProps> = (props) => {
  const { step, state, showSeparator } = props;

  const Icon = iconMap[state === "active" ? "check" : step.icon];

  return (
    <>
      <div
        className={`${navigationStepClassNames[state]} flex-0 h-10 w-10 relative rounded-full`}
      >
        <NavigationStepBackground state={state}>
          <Icon className="w-6 h-6" />
        </NavigationStepBackground>
        <NavigationStepLabel>{step.label}</NavigationStepLabel>
      </div>
      {showSeparator && <NavigationStepLine />}
    </>
  );
};
