import React, { FC } from "react";
import { Mutation, Query } from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { GradientHeader } from "../../GradientHeader";
import toast from "react-hot-toast";

import {
  HorizontalField,
  HorizontalTextField,
  HorizontalSelectField,
} from "@preferral/ui";
import { Spinner } from "components/Spinner";
import { ScreenTitle } from "context/ScreenTitle";

const CURRENT_USER_PROFILE_DATA_QUERY = gql`
  {
    me {
      id
      firstName
      lastName
      timeZoneName
    }
    timeZoneNames
  }
`;

interface FetchData {
  me: {
    id: string;
    firstName: string;
    lastName: string;
    timeZoneName: string;
  };
  timeZoneNames: string[];
}

const UPDATE_CURRENT_USER_PROFILE_MUTATION = gql`
  mutation UpdateCurrentUserProfile($input: UserProfileInput!) {
    updateUserProfile(input: $input) {
      errors {
        key
        message
      }
      firstName
      lastName
      timeZoneName
    }
  }
`;

interface UpdateData {
  updateUserProfile: {
    errors?: InputError[];
    firstName: string;
    lastName: string;
    timeZoneName: string;
  };
}

interface UserProfileInput {
  firstName: string;
  lastName: string;
  timeZoneName: string;
}

interface UpdateVariables {
  input: UserProfileInput;
}

interface ProfileFormProps {}

export const ProfileForm: FC<ProfileFormProps> = () => {
  return (
    <div className="bg-white box rounded-lg shadow-lg">
      <ScreenTitle title="Settings » Profile" />
      <GradientHeader
        icon="user-edit"
        title="Profile Information"
        subtitle="Update your personal profile information."
      />
      <div className="ProfileForm">
        <Query<FetchData> query={CURRENT_USER_PROFILE_DATA_QUERY}>
          {({ loading, data, error }) =>
            loading ? (
              <div className="p-12 text-center">
                <Spinner />
              </div>
            ) : error ? (
              <div>
                <h1>Error</h1>
                <pre>{JSON.stringify(error)}</pre>
              </div>
            ) : (
              <Mutation<UpdateData, UpdateVariables>
                mutation={UPDATE_CURRENT_USER_PROFILE_MUTATION}
              >
                {(updateCurrentUser) => (
                  <Formik
                    initialValues={{
                      firstName: (data && data.me.firstName) || "",
                      lastName: (data && data.me.lastName) || "",
                      timeZoneName: (data && data.me.timeZoneName) || "",
                    }}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string().required("Required"),
                      lastName: Yup.string().required("Required"),
                      timeZoneName: Yup.string().required("Required"),
                    })}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                      setStatus({ errors: null });
                      updateCurrentUser({
                        variables: { input: values },
                        refetchQueries: [
                          { query: CURRENT_USER_PROFILE_DATA_QUERY },
                        ],
                      }).then(
                        (res) => {
                          // TODO: errors
                          if (
                            res &&
                            res.data &&
                            res.data.updateUserProfile.errors
                          ) {
                            setStatus({
                              errors: res.data.updateUserProfile.errors,
                            });
                          } else {
                            toast.success("Updated profile!");
                          }
                          setSubmitting(false);
                        },
                        (rej) => setSubmitting(false)
                      );
                    }}
                  >
                    {({ values, status, isSubmitting, handleSubmit }) => (
                      <div className="p-6">
                        <form onSubmit={handleSubmit}>
                          <FormStatusErrors status={status} />

                          <div className="mt-3">
                            <HorizontalTextField
                              name="firstName"
                              label="First Name"
                            />
                          </div>
                          <div className="mt-3">
                            <HorizontalTextField
                              name="lastName"
                              label="Last Name"
                            />
                          </div>
                          <div className="mt-3">
                            <HorizontalSelectField
                              name="timeZoneName"
                              label="Time Zone"
                              options={
                                data?.timeZoneNames.map((tz) => ({
                                  value: tz,
                                  label: tz,
                                })) || []
                              }
                            />
                          </div>
                          <div className="mt-3">
                            <HorizontalField label="">
                              <button
                                type="submit"
                                className="btn btn-blue"
                                disabled={isSubmitting}
                              >
                                Save
                              </button>
                            </HorizontalField>
                          </div>
                        </form>
                      </div>
                    )}
                  </Formik>
                )}
              </Mutation>
            )
          }
        </Query>
      </div>
    </div>
  );
};
