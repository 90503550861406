import React, { Children, FC, isValidElement, ReactElement } from "react";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { InputPlainSelect, StandardOption } from "@preferral/ui";

/**
 * NavLinkTab.
 */

interface NavLinkTabProps {
  to: string;
  children: string;
}

export const NavLinkTab: FC<NavLinkTabProps> = (props) => {
  const { to, children } = props;
  const match = useRouteMatch(to);
  const isActive = !!match;

  const linkClassNames = isActive
    ? "border-blue-500 text-blue-600 focus:text-blue-800 focus:border-blue-700"
    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300";

  return (
    <Link
      to={to}
      className={`${linkClassNames} ml-8 group inline-flex items-center py-4 px-1 border-b-2  font-medium text-sm leading-5 `}
    >
      {children}
    </Link>
  );
};

/**
 * NavLinkTabs.
 */

interface NavLinkTabsProps {
  children: ReactElement<NavLinkTabProps>[];
}

export const NavLinkTabs: FC<NavLinkTabsProps> = (props) => {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();

  const tabOptions: StandardOption[] = Children.map<any, any>(
    children,
    (child: ReactElement<NavLinkTabProps>) => {
      if (isValidElement(child)) {
        return {
          value: child.props.to,
          label: child.props.children,
        };
      }
      return null;
    }
  ).filter(Boolean);

  return (
    <div>
      <div className="sm:hidden">
        <InputPlainSelect
          value={location.pathname}
          onChange={history.push}
          options={tabOptions}
        />
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-cool-gray-200">
          <nav className="flex -mb-px" aria-label="Tabs">
            {children}
          </nav>
        </div>
      </div>
    </div>
  );
};
