import { FC, useCallback, useState } from "react";
import CalendarIcon from '@heroicons/react/outline/CalendarIcon';
import { Button, FAIcon } from "@preferral/ui";
import { useAppointmentRequest } from "../../AppointmentRequestContext";
import { AppointmentCard } from "./AppointmentCard";
import { NewAppointmentModal } from "./NewAppointmentModal";
import { NewAppointmentModal as SentReferralNewAppointmentModal } from "./SentReferralNewAppointmentModal";
import { CancelAppointmentModal } from "./CancelAppointmentModal";

/**
 * NoAppointmentsYet.
 */

interface NoAppointmentYetProps {
  onClick(): void;
}

const NoAppointmentsYet: FC<NoAppointmentYetProps> = (props) => {
  const { onClick } = props;

  return (
    <div className="_NoAppointmentsYet p-3 rounded bg-gray-50 border border-dotted text-center">
      <div className="flex items-center justify-center p-4">
        <CalendarIcon className="inline-block h-5 w-5 text-cool-gray-400" />
        <p className="ml-2 text-cool-gray-500">No Appointments</p>
      </div>
      <Button
        className="mt-3"
        color="blue"
        kind="secondary"
        size="sm"
        onClick={onClick}
      >
        <span className="mr-2">
          <FAIcon icon="plus" />
        </span>
        Add Appointment
      </Button>
    </div>
  );
};

type ActiveModal =
  | { type: "NEW_APPOINTMENT" }
  | { type: "SENT_REFERRAL_NEW_APPOINTMENT" }
  | { type: "CANCEL_APPOINTMENT", appointmentId: string };

interface AppointmentsPanelProps { }

export const AppointmentsPanel: FC<AppointmentsPanelProps> = (props) => {
  const {
    appointmentRequest: { iamReceiverOrganization, appointments },
    refetch,
  } = useAppointmentRequest();
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => setActiveModal(null), []);
  const openNewAppointmentModal = useCallback(
    () => iamReceiverOrganization ? setActiveModal({ type: "NEW_APPOINTMENT" }) : setActiveModal({ type: "SENT_REFERRAL_NEW_APPOINTMENT" }),
    [iamReceiverOrganization]
  );

  return (
    <>
      <NewAppointmentModal
        isOpen={activeModal?.type === "NEW_APPOINTMENT"}
        onClose={closeModal}
      />
      <SentReferralNewAppointmentModal
        isOpen={activeModal?.type === "SENT_REFERRAL_NEW_APPOINTMENT"}
        onClose={closeModal}
      />
      {activeModal?.type === "CANCEL_APPOINTMENT" ? (
        <CancelAppointmentModal
          appointmentId={activeModal.appointmentId}
          isOpen
          onClose={closeModal}
          onSuccess={() => {
            refetch();
            closeModal();
          }}
        />
      ) : null}
      <div className="AppointmentsPanel">
        <h4 className="text-xl py-4 font-semibold">Appointments</h4>

        <div className="px-4">
          {appointments.length === 0 ? (
            <NoAppointmentsYet onClick={openNewAppointmentModal} />
          ) : (
            appointments.map((a) => (
              <div key={a.id} className="_AppointmentCardContainer pb-3">
                <AppointmentCard
                  appointment={a}
                  onCancelClick={() => setActiveModal({ type: "CANCEL_APPOINTMENT", appointmentId: a.id })}
                  onChanged={refetch}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};
