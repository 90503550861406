import React, { FC } from "react";
import { NavLink, Route, useRouteMatch } from "react-router-dom";
import { LoginAttempts } from "./LoginAttempts";
import { FAIcon } from "components/FAIcon";
import { ScreenTitle } from "context/ScreenTitle";

interface OrganizationUserShowProps {}

export const OrganizationUserShow: FC<OrganizationUserShowProps> = () => {
  const match = useRouteMatch<{ userId: string }>();
  const { userId } = match.params;

  return (
    <div className="OrganizationUserShow">
      <ScreenTitle title="Settings » Users » User Details" />
      <div className="bg-white box rounded-lg shadow-lg">
        <div className="tabs is-centered">
          <ul>
            <li>
              <NavLink
                to={`/o/settings/users/${userId}/profile`}
                activeClassName="is-active"
              >
                <span className="icon is-small">
                  <FAIcon icon="user-edit" aria-hidden="true" />
                </span>
                <span>Profile</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/o/settings/users/${userId}/account`}
                activeClassName="is-active"
              >
                <span className="icon is-small">
                  <FAIcon icon="lock" aria-hidden="true" />
                </span>
                <span>Account</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/o/settings/users/${userId}/login_attempts`}
                activeClassName="is-active"
              >
                <span className="icon is-small">
                  <FAIcon icon="list-alt" aria-hidden="true" />
                </span>
                <span>Login Attempts</span>
              </NavLink>
            </li>
          </ul>
        </div>

        <Route path="/o/settings/users/:userId/login_attempts">
          <LoginAttempts />
        </Route>
      </div>
    </div>
  );
};
