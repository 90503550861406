import { FC } from "react";
// import { gql } from "graphql.macro";
import { gql, useQuery } from "@apollo/client";
import { Avatar, Spinner } from "@preferral/ui";
import { ZERO_WIDTH_SPACE } from "@preferral/common";

const PROVIDER_LOCATION = gql`
  query ProviderLocation($providerId: UUID4!, $locationId: UUID4!) {
    provider(id: $providerId) {
      id
      nameWithAppellation
      avatar(size: "medium") {
        base64
        src
        srcSet
      }
      primarySpecialty {
        id
        name
      }
    }
    location(id: $locationId) {
      id
      name
      streetAddress
      streetAddressLine2
      city
      stateAbbreviation
      zip
      geom {
        lat
        lng
      }
    }
  }
`;

const LOCATION = gql`
  query Location($locationId: UUID4!) {
    location(id: $locationId) {
      id
      name
      streetAddress
      streetAddressLine2
      city
      stateAbbreviation
      zip
      geom {
        lat
        lng
      }
    }
  }
`;

const NPPES_PROVIDER_LOCATION = gql`
  query NppesProviderLocation(
    $nppesProviderId: UUID4!
    $nppesLocationId: UUID4!
  ) {
    nppesProvider(id: $nppesProviderId) {
      id
      nameWithAppellation
      avatar(size: "medium") {
        base64
        src
        srcSet
      }
      primarySpecialty {
        id
        name
      }
    }
    nppesLocation(id: $nppesLocationId) {
      id
      name
      streetAddress
      streetAddressLine2
      city
      stateAbbreviation
      zip
      geom {
        lat
        lng
      }
    }
  }
`;

const NPPES_LOCATION = gql`
  query NppesLocation($nppesLocationId: UUID4!) {
    nppesLocation(id: $nppesLocationId) {
      id
      name
      streetAddress
      streetAddressLine2
      city
      stateAbbreviation
      zip
      geom {
        lat
        lng
      }
    }
  }
`;

const RIBBON_PROVIDER_LOCATION = gql`
  query RibbonProviderLocation($ribbonProviderNpi: String!, $ribbonLocationUuid: String!) {
    ribbonProvider(npi: $ribbonProviderNpi) {
      npi
      firstName
      lastName
    }
    ribbonLocation(uuid: $ribbonLocationUuid) {
      uuid
      name
    }
  }
`;

/**
 * Spacer.
 */

const Spacer: FC = () => {
  return <div className="m-2 border-b border-cool-gray-200" />;
};

/**
 * ReferralTo.
 */

interface ReferralToProps {
  providerId?: string;
  locationId?: string;
  nppesProviderId?: string;
  nppesLocationId?: string;
  ribbonProviderNpi?: string;
  ribbonLocationUuid?: string;
}

export const ReferralTo: FC<ReferralToProps> = (props) => {
  const {
    providerId,
    nppesProviderId,
    locationId,
    nppesLocationId,
    ribbonProviderNpi,
    ribbonLocationUuid
  } = props;

  if (providerId && locationId) {
    return (
      <ReferralToProviderLocation
        providerId={providerId}
        locationId={locationId}
      />
    );
  } else if (locationId) {
    return <ReferralToLocation locationId={locationId} />;
  } else if (nppesProviderId && nppesLocationId) {
    return (
      <NppesProviderLocationInfo
        nppesProviderId={nppesProviderId}
        nppesLocationId={nppesLocationId}
      />
    );
  } else if (nppesLocationId) {
    return <NppesLocationInfo nppesLocationId={nppesLocationId} />;
  } else if (ribbonProviderNpi && ribbonLocationUuid) {
    return (
      <RibbonProviderLocationInfo
        ribbonProviderNpi={ribbonProviderNpi}
        ribbonLocationUuid={ribbonLocationUuid}
      />
    )
  }

  return null;
};

/**
 * ReferralToProviderLocaiton.
 */

interface ReferralToProviderLocationProps {
  providerId: string;
  locationId: string;
}

const ReferralToProviderLocation: FC<ReferralToProviderLocationProps> = (
  props
) => {
  const { providerId, locationId } = props;

  const { data, loading } = useQuery(PROVIDER_LOCATION, {
    variables: { providerId, locationId },
  });

  return (
    <div>
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : !data.provider || !data.location ? (
        <p>Failed to load.</p>
      ) : (
        <div>
          {data.provider.nameWithAppellation}
          {data.location.name}
        </div>
      )}
    </div>
  );
};

/**
 * ReferralToLocation.
 */

interface ReferralToLocationProps {
  locationId: string;
}

const ReferralToLocation: FC<ReferralToLocationProps> = (props) => {
  const { locationId } = props;

  const { data, loading } = useQuery(LOCATION, {
    variables: { locationId },
  });

  return (
    <div>
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : !data.location ? (
        <p>Failed to load.</p>
      ) : (
        <div>{data.location.name}</div>
      )}
    </div>
  );
};

/**
 * ReferralToNppesProviderLocaiton.
 */

interface NppesProviderLocationInfoProps {
  nppesProviderId: string;
  nppesLocationId: string;
}

const NppesProviderLocationInfo: FC<NppesProviderLocationInfoProps> = (
  props
) => {
  const { nppesProviderId, nppesLocationId } = props;

  const { data, loading } = useQuery(NPPES_PROVIDER_LOCATION, {
    variables: { nppesProviderId, nppesLocationId },
  });

  if (loading) {
    return (
      <div className="text-center p-4">
        <Spinner />
      </div>
    );
  } else if (!data.nppesProvider || !data.nppesLocation) {
    return <p>Failed to load.</p>;
  }

  const { nppesProvider, nppesLocation } = data;

  const streetAddress = [
    nppesLocation.streetAddress,
    nppesLocation.streetAddressLine2,
  ]
    .filter(Boolean)
    .join(", ");
  const cityStateZip = [
    nppesLocation.city,
    nppesLocation.stateAbbreviation,
    nppesLocation.zip,
  ]
    .filter(Boolean)
    .join(", ");

  return (
    <div>
      <div className="px-2">
        <h4 className="font-semibold text-cool-gray-700 text-lg">
          Referral To
        </h4>
      </div>
      <Spacer />
      <div className="flex h-full">
        <div className="flex-shrink-0 py-3 pl-3">
          <Avatar size="sm" image={nppesProvider.avatar} />
        </div>
        <div className="flex-1 flex flex-col h-full min-w-0 p-4 text-left">
          <div>
            <p className="font-bold text-cool-gray-800 text-lg">
              {nppesProvider.nameWithAppellation}
            </p>
            {nppesProvider.primarySpecialty ? (
              <p className="leading-tight text-cool-gray-600 text-xs">
                {nppesProvider.primarySpecialty.name}
              </p>
            ) : null}
          </div>
          <div className="flex-grow py-3">
            <p className="text-sm font-semibold text-cool-gray-700">
              {nppesLocation.name}
            </p>
            <p className="text-xs text-cool-gray-600">{streetAddress}</p>
            <p className="text-xs text-cool-gray-600">{cityStateZip}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * NppesLocationInfo.
 */

interface NppesLocationInfoProps {
  nppesLocationId: string;
}

const NppesLocationInfo: FC<NppesLocationInfoProps> = (props) => {
  const { nppesLocationId } = props;

  const { data, loading } = useQuery(NPPES_LOCATION, {
    variables: { nppesLocationId },
  });

  return (
    <div>
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : !!data.nppesLocation ? (
        <p>Failed to load.</p>
      ) : (
        <div>{data.nppesLocation.name}</div>
      )}
    </div>
  );
};

/**
 * RibbonProviderLocationInfo.
 */

interface RibbonProviderLocationInfoProps {
  ribbonProviderNpi: string;
  ribbonLocationUuid: string;
}

const RibbonProviderLocationInfo: FC<RibbonProviderLocationInfoProps> = props => {
  const { ribbonProviderNpi, ribbonLocationUuid } = props;

  const { data, loading } = useQuery(RIBBON_PROVIDER_LOCATION, {
    variables: { ribbonProviderNpi, ribbonLocationUuid }
  })

  return (
    <div>
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : !!data.ribbonProvider ? (
        <p>Failed to load.</p>
      ) : (
        <pre>{JSON.stringify(data, null, 2)}</pre>
      )}
    </div>
  )
}
