import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Switch, Route, Redirect } from "react-router-dom";
import { NavMenu } from "./NavMenu";
import { ProfileForm as UserProfileForm } from "./User/ProfileForm";
import { AccountForm as UserAccountForm } from "./User/AccountForm";
import { NotificationPreferences as UserNotificationPreferences } from "./User/NotificationPreferences";
import { ProfileForm as OrganizationProfileForm } from "./Organization/ProfileForm";
import { OrganizationLogoForm } from "./Organization/OrganizationLogoForm";
import { OrganizationUsers } from "./Organization/OrganizationUsers";
import { OrganizationUserShow } from "./Organization/OrganizationUserShow";
// import { BillingPage } from "./Organization/Billing/BillingPage";
import { ProvidersScreen } from "./Organization/ProvidersScreen";
import { Departments } from "./Organization/Departments";
import { Locations } from "./Organization/Locations";
import { Inboxes } from "./Department/Inboxes";
import { Specialties } from "./Department/Specialties";
import { EditReferralForm } from "./Department/Specialties/EditReferralForm";
import { PatientFormsScreen } from "./Department/PatientFormsScreen";
import { PatientPacketsScreen } from "./Department/PatientPacketsScreen";
import { EditPatientFormScreen } from "./Department/EditPatientFormScreen";
import "./SettingsScreen.css";
import { Spinner } from "components/Spinner";
import styled from "styled-components";
import { DepartmentUsersScreen } from "./Department/DepartmentUsersScreen";
import { DepartmentProvidersScreen } from "./Organization/Departments/DepartmentProvidersScreen";
import { DepartmentLocationsScreen } from "./Organization/Departments/DepartmentLocationsScreen";
import { PatientPacketTagsScreen } from "./Department/PatientPacketTagsScreen";
import { PatientNotificationsScreen } from "./Department/PatientNotificationsScreen";
import { SMSTemplatesScreen } from "./Department/SMSTemplatesScreen";
import { LinkType } from "./NavMenu/NavMenu";
import { useFeatureFlags } from "hooks/useFeatureFlags";

const Layout = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 4fr;
  grid-template-columns: 1fr 4fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  overflow: hidden;
`;

const Sidebar = styled.div`
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / span 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / span 1;
  height: 100%;
  overflow: scroll;
`;

const Content = styled.div`
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / span 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / span 1;
  height: 100%;
  overflow: scroll;
`;

const ME_FOR_SETTINGS = gql`
  {
    me {
      id
      aristamdUserId
      role {
        id
        name
      }
      organization {
        id
        departments {
          id
          name
        }
      }
    }
  }
`;

interface Data {
  me: {
    id: string;
    aristamdUserId: string;
    role: {
      id: string;
      name: string;
    };
    organization: {
      id: string;
      departments: {
        id: string;
        name: string;
      }[];
    };
  };
}

interface SettingsScreenProps {}

export const SettingsScreen: FC<SettingsScreenProps> = () => {
  const { data, loading, error } = useQuery<Data>(ME_FOR_SETTINGS);
  const isAMD = !!data?.me.aristamdUserId;
  const { hasFeature } = useFeatureFlags();

  return (
    <Layout className="text-left">
      <Sidebar className="pb-20 bg-gray-100 border-gray-300 border-r">
        <NavMenu
          title="Me"
          links={
            [
              {
                icon: "user-edit",
                path: "/o/settings/profile",
                label: "My Info",
              },
              !isAMD && {
                icon: "lock",
                path: "/o/settings/account",
                label: "Account",
              },
              {
                icon: "bell",
                path: "/o/settings/notifications",
                label: "Notifications",
              },
            ].filter(Boolean) as LinkType[]
          }
        />
        {data && data.me.role.name === "Admin" ? (
          <>
            <NavMenu
              title="Account"
              links={[
                {
                  icon: "hospital-alt",
                  path: "/o/settings/organization_profile",
                  label: "Organization Info",
                },
                {
                  icon: "trademark",
                  path: "/o/settings/organization_logo",
                  label: "Organization Logo",
                },
                // {
                //   icon: "lock",
                //   path: "/o/settings/configure_form",
                //   label: "Configure Form"
                // },
                {
                  icon: "users",
                  path: "/o/settings/users",
                  label: "Users",
                },
                {
                  icon: "sitemap",
                  path: "/o/settings/departments",
                  label: "Departments",
                  exact: true,
                },
                {
                  icon: "user-md",
                  path: "/o/settings/providers",
                  label: "Providers",
                },
                {
                  icon: "map-marker-alt",
                  path: "/o/settings/locations",
                  label: "Locations",
                },
                // {
                //   icon: "bell",
                //   path: "/o/settings/organization_notifications",
                //   label: "Notifications",
                // },
                // {
                //   icon: ["far", "credit-card"],
                //   path: "/o/settings/billing",
                //   label: "Billing",
                // },
                // {
                //   icon: "list-alt",
                //   path: "/o/settings/audit_log",
                //   label: "Audit Log",
                // },
              ]}
            />
            {data.me.organization.departments.map(dept => (
              <div key={dept.id}>
                <NavMenu
                  title={dept.name}
                  links={
                    [
                      // {
                      //   icon: "hospital-alt",
                      //   path: `/o/settings/departments/${dept.id}/profile`,
                      //   label: "Department Info",
                      // },
                      {
                        icon: "users",
                        path: `/o/settings/departments/${dept.id}/users`,
                        label: "Users",
                      },
                      hasFeature("referrals") && {
                        icon: "inbox",
                        path: `/o/settings/departments/${dept.id}/inboxes`,
                        label: "Inboxes",
                      },
                      {
                        icon: "stethoscope",
                        path: `/o/settings/departments/${dept.id}/specialties`,
                        label: "Specialties",
                      },
                      {
                        icon: "bell",
                        path: `/o/settings/departments/${dept.id}/patient_notifications`,
                        label: "Patient Notifications",
                      },
                      {
                        icon: "brackets-curly",
                        path: `/o/settings/departments/${dept.id}/sms_templates`,
                        label: "SMS Templates",
                      },
                      hasFeature("patient_forms") && {
                        icon: "file-alt",
                        path: `/o/settings/departments/${dept.id}/patient_forms`,
                        label: "Patient Forms",
                      },
                      hasFeature("patient_forms") && {
                        icon: "envelope-open-text",
                        path: `/o/settings/departments/${dept.id}/patient_packets`,
                        label: "Patient Packets",
                      },
                      hasFeature("patient_forms") && {
                        icon: "tags",
                        path: `/o/settings/departments/${dept.id}/patient_packet_tags`,
                        label: "Patient Packet Tags",
                      },
                      {
                        icon: "user-md",
                        path: `/o/settings/departments/${dept.id}/providers`,
                        label: "Providers",
                      },
                      {
                        icon: "map-marker-alt",
                        path: `/o/settings/departments/${dept.id}/locations`,
                        label: "Locations",
                      },
                      // {
                      //   icon: "hashtag",
                      //   path: `/o/settings/departments/${dept.id}/phone_numbers`,
                      //   label: "Phone Numbers",
                      // },
                    ].filter(Boolean) as LinkType[]
                  }
                />
              </div>
            ))}
          </>
        ) : null}
      </Sidebar>
      <Content>
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !(data && data.me) ? (
          <h1>Failed to load</h1>
        ) : (
          <Switch>
            <Route exact path="/o/settings">
              <Redirect to="/o/settings/profile" />
            </Route>

            <Route path="/o/settings/profile">
              <div className="p-10">
                <UserProfileForm />
              </div>
            </Route>
            {!isAMD && (
              <Route path="/o/settings/account">
                <div className="p-10">
                  <UserAccountForm />
                </div>
              </Route>
            )}

            <Route path="/o/settings/notifications">
              <div className="p-10">
                <UserNotificationPreferences />
              </div>
            </Route>

            <Route path="/o/settings/organization_profile">
              <div className="p-10">
                <OrganizationProfileForm />
              </div>
            </Route>

            <Route path="/o/settings/organization_logo">
              <div className="p-10">
                <OrganizationLogoForm />
              </div>
            </Route>

            {/*<Route path="/o/settings/billing">*/}
            {/*  <div className="p-10">*/}
            {/*    <BillingPage />*/}
            {/*  </div>*/}
            {/*</Route>*/}

            <Route exact path="/o/settings/users">
              <div className="p-10">
                <OrganizationUsers />
              </div>
            </Route>

            <Route exact path="/o/settings/departments">
              <div className="p-10">
                <Departments />
              </div>
            </Route>

            <Route path="/o/settings/providers">
              <ProvidersScreen />
            </Route>

            {/* <Route path="/o/settings/providers/:providerId">
              <ProviderShow />
            </Route> */}

            <Route exact path="/o/settings/locations">
              <div className="p-10">
                <Locations />
              </div>
            </Route>

            {/* <Route
                    exact
                    path="/o/settings/case_programs"
                    component={CasePrograms}
                  /> */}
            <Route path="/o/settings/users/:userId">
              <div className="p-10">
                <OrganizationUserShow />
              </div>
            </Route>
            {hasFeature("referrals") ? (
              <Route path="/o/settings/departments/:departmentId/inboxes">
                <div className="p-10">
                  <Inboxes />
                </div>
              </Route>
            ) : null}

            <Route exact path="/o/settings/departments/:departmentId/users">
              <div className="p-10">
                <DepartmentUsersScreen />
              </div>
            </Route>
            <Route
              exact
              path="/o/settings/departments/:departmentId/specialties"
            >
              <div className="p-10">
                <Specialties />
              </div>
            </Route>
            <Route path="/o/settings/departments/:departmentId/specialties/:departmentSpecialtyId/referral_form">
              <div className="p-10">
                <EditReferralForm />
              </div>
            </Route>

            <Route path="/o/settings/departments/:departmentId/patient_notifications">
              <div className="p-10">
                <PatientNotificationsScreen />
              </div>
            </Route>

            <Route path="/o/settings/departments/:departmentId/sms_templates">
              <div className="p-10">
                <SMSTemplatesScreen />
              </div>
            </Route>

            <Route path="/o/settings/departments/:departmentId/patient_forms/:patientFormId/edit">
              <div className="p-10">
                <EditPatientFormScreen />
              </div>
            </Route>

            <Route exact path="/o/settings/departments/:departmentId/providers">
              <div className="p-10">
                <DepartmentProvidersScreen />
              </div>
            </Route>

            <Route exact path="/o/settings/departments/:departmentId/locations">
              <div className="p-10">
                <DepartmentLocationsScreen />
              </div>
            </Route>
            {hasFeature("patient_forms") ? (
              <>
                <Route
                  exact
                  path="/o/settings/departments/:departmentId/patient_forms"
                >
                  <div className="p-10">
                    <PatientFormsScreen />
                  </div>
                </Route>
                <Route
                  exact
                  path="/o/settings/departments/:departmentId/patient_packets"
                >
                  <div className="p-10">
                    <PatientPacketsScreen />
                  </div>
                </Route>
                <Route
                  exact
                  path="/o/settings/departments/:departmentId/patient_packet_tags"
                >
                  <div className="p-10">
                    <PatientPacketTagsScreen />
                  </div>
                </Route>
              </>
            ) : null}
          </Switch>
        )}
      </Content>
    </Layout>
  );
};
