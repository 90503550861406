import { FC } from 'react'
import { Alert } from 'components/Alert'
import { gql, useMutation } from '@apollo/client'
import { AppointmentRequestEventModel } from 'screens/AppointmentRequestShowScreen/model'

const DISMISS_NOTIFICATION = gql`
  mutation dismissEventNotification($id: UUID4!){
    dismissAppointmentRequestEventNotification(appointmentRequestEventNotificationId: $id) {
      id
    }
  }
`;

interface MutationData {
  dismissAppointmentRequestEventNotification: {
    id: string;
  }
}

interface MutationVariables {
  id: string;
}

type AppointmentRequestEventNotificationAlertProps = {
  appointmentRequestEvent: AppointmentRequestEventModel
}

const ACCEPTED_EVENTS = {
  fax_failed: {
    color: "red",
    message: "An error occurred while faxing. Please check the fax number and try again.",
    icon: "triangle-exclamation",
  },
  missing_fax_number: {
    color: "yellow",
    message: "An error occurred while faxing. Please check the fax number and try again.",
    icon: "triangle-exclamation",
  },
}

export const AppointmentRequestEventNotificationAlert: FC<AppointmentRequestEventNotificationAlertProps> = ({ appointmentRequestEvent }) => {

  const [dismissNotification] = useMutation<MutationData, MutationVariables>(DISMISS_NOTIFICATION)

  const { type, appointmentRequestEventNotification } = appointmentRequestEvent

  const isAccepted = (type: string) => Object.keys(ACCEPTED_EVENTS).includes(type)

  return isAccepted(type) && appointmentRequestEventNotification && !appointmentRequestEventNotification.isAcknowledged ?
    <Alert
      message={ACCEPTED_EVENTS[type].message}
      icon={ACCEPTED_EVENTS[type].icon}
      onDismiss={() => dismissNotification({ variables: { id: appointmentRequestEventNotification.id } })}
      color={ACCEPTED_EVENTS[type].color}
    /> : null
}