import React, { FC, useCallback } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spinner } from "components/Spinner";
import { useRouteMatch } from "react-router-dom";
import { ScreenTitle } from "context/ScreenTitle";
import { useState } from "react";
import { AddUserModal } from "./AddUserModal";
import {
  FAIcon,
  Table,
  TH,
  TD,
  DropdownButton,
  DropdownItemButton,
  TableContainer,
} from "@preferral/ui";
import { NoResults } from "components/NoResults";
import { GradientHeader } from "screens/SettingsScreen/GradientHeader";

const DEPARTMENT_USERS = gql`
  query GetDepartmentUsers($id: UUID4!) {
    department(id: $id) {
      id
      name
      users {
        id
        name
        email
        deactivatedAt
      }
    }
  }
`;

interface Data {
  department: {
    id: string;
    name: string;
    users: {
      id: string;
      name: string;
      email: string;
      deactivatedAt: string;
    }[];
  };
}

interface Variables {
  id: string;
}

interface RouteParams {
  departmentId: string;
}

interface DepartmentUsersScreenProps {}

export const DepartmentUsersScreen: FC<DepartmentUsersScreenProps> = (
  props
) => {
  const match = useRouteMatch<RouteParams>();
  const { departmentId } = match.params;
  const { data, loading, error } = useQuery<Data, Variables>(DEPARTMENT_USERS, {
    variables: { id: departmentId },
  });
  const [activeModal, setActiveModal] = useState<"ADD_USER" | null>(null);

  const closeModal = useCallback(() => {
    setActiveModal(null);
  }, [setActiveModal]);

  return (
    <>
      <ScreenTitle title={["Settings", "Departments", "Users"]} />
      <AddUserModal
        departmentId={departmentId}
        isOpen={activeModal === "ADD_USER"}
        onClose={closeModal}
        refetchQueries={[
          {
            query: DEPARTMENT_USERS,
            variables: { id: departmentId },
          },
        ]}
      />

      <div className="bg-white box rounded-lg shadow-lg">
        <GradientHeader
          icon="users"
          title="Department Users"
          subtitle={
            !data?.department
              ? ""
              : `Manage users for the ${data.department.name} department`
          }
        />
        <div className="p-4">
          {loading ? (
            <div className="p-12">
              <Spinner />
            </div>
          ) : error || !data?.department ? (
            <p>Failed to load</p>
          ) : (
            <div>
              <div className="flex justify-end px-3 pb-4">
                <button
                  onClick={() => setActiveModal("ADD_USER")}
                  className="btn btn-blue"
                >
                  <FAIcon icon="plus" />
                  <span className="ml-2">Add User</span>
                </button>
              </div>
              {data.department.users.length === 0 ? (
                <NoResults icon="users" text="No users added" />
              ) : (
                <TableContainer>
                  <Table>
                    <thead>
                      <tr>
                        <TH>Name</TH>
                        <TH />
                      </tr>
                    </thead>
                    <tbody>
                      {data.department.users.map((u) => (
                        <tr key={u.id}>
                          <TD>
                            <p>
                              {u.name}{" "}
                              {!!u.deactivatedAt ? "(Deactivated)" : ""}
                            </p>
                            <p>{u.email}</p>
                          </TD>
                          <TD className="w-40">
                            <DropdownButton label="Actions">
                              <DropdownItemButton onClick={console.log}>
                                Remove User
                              </DropdownItemButton>
                            </DropdownButton>
                          </TD>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </TableContainer>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
