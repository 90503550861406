import mixpanel, {
  Dict,
  RequestOptions,
  Callback,
  Config,
} from "mixpanel-browser";

const TRACKING_KEY = "preferral-tracking-enabled";

interface InitOptions {
  mixpanel: {
    token: string;
    config?: Partial<Config>;
  };
}

export const analytics = {
  staticProperties: {},

  init(options: InitOptions) {
    if (!this.getEnabled()) return;
    mixpanel.init(options.mixpanel.token, options.mixpanel.config || {});
  },

  identify(
    unique_id?: string | undefined,
    properties?: Dict | undefined
  ): void {
    if (!this.getEnabled()) return;
    mixpanel.identify(unique_id);

    if (properties) {
      mixpanel.people.set(properties);
    }
  },

  setGroup(
    group_key: string,
    group_ids: string | number | string[] | number[],
    callback?: Callback | undefined
  ): void {
    if (!this.getEnabled()) return;
    mixpanel.set_group(group_key, group_ids, callback);
  },

  updateGroup(group_key: string, group_id: string, properties: Dict) {
    if (!this.getEnabled()) return;
    mixpanel.get_group(group_key, group_id).set(properties);
  },

  track(
    event_name: string,
    properties?: Dict | undefined,
    optionsOrCallback?: RequestOptions | Callback | undefined,
    callback?: Callback | undefined
  ): void {
    if (!this.getEnabled()) return;

    mixpanel.track(
      event_name,
      { ...this.staticProperties, ...properties },
      optionsOrCallback,
      callback
    );
  },

  timeEvent(eventName: string) {
    if (!this.getEnabled()) return;
    mixpanel.time_event(eventName);
  },

  getEnabled() {
    return localStorage.getItem(TRACKING_KEY) !== "false";
  },

  disableTracking() {
    localStorage.setItem(TRACKING_KEY, "false");
  },

  enableTracking() {
    localStorage.setItem(TRACKING_KEY, "true");
  },

  setStaticProperties(properties: Dict) {
    this.staticProperties = properties || {};
  },
};
