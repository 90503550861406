import React, { HTMLAttributes, forwardRef } from "react";
import { colorClassNames } from "./colorClassNames";

interface XCircleIconProps extends HTMLAttributes<SVGSVGElement> {
  color: AppColor;
}

export const XCircleIcon = forwardRef<SVGSVGElement, XCircleIconProps>(
  (props, ref) => {
    const { color, ...rest } = props;
    const lightClassName = colorClassNames[color].light;
    const darkClassName = colorClassNames[color].dark;
    return (
      <svg
        ref={ref}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          className={lightClassName}
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          fill="currentColor"
        />
        <path
          className={darkClassName}
          d="M13.4099 12L16.2399 14.83C16.4038 15.0213 16.4894 15.2674 16.4797 15.519C16.4699 15.7707 16.3656 16.0095 16.1875 16.1876C16.0094 16.3656 15.7707 16.47 15.519 16.4797C15.2673 16.4894 15.0212 16.4038 14.8299 16.24L11.9999 13.41L9.16994 16.24C9.0803 16.3447 8.96998 16.4297 8.84591 16.4897C8.72185 16.5497 8.58672 16.5835 8.449 16.5888C8.31128 16.5941 8.17395 16.5709 8.04562 16.5206C7.9173 16.4704 7.80075 16.3941 7.70329 16.2966C7.60584 16.1992 7.52958 16.0826 7.47931 15.9543C7.42903 15.826 7.40583 15.6887 7.41115 15.5509C7.41647 15.4132 7.4502 15.2781 7.51022 15.154C7.57024 15.03 7.65526 14.9196 7.75994 14.83L10.5899 12L7.75994 9.16999C7.59612 8.97869 7.51051 8.73261 7.52023 8.48094C7.52995 8.22926 7.63429 7.99052 7.81238 7.81243C7.99048 7.63433 8.22921 7.53 8.48089 7.52028C8.73257 7.51056 8.97864 7.59616 9.16994 7.75999L11.9999 10.59L14.8299 7.75999C15.0212 7.59616 15.2673 7.51056 15.519 7.52028C15.7707 7.53 16.0094 7.63433 16.1875 7.81243C16.3656 7.99052 16.4699 8.22926 16.4797 8.48094C16.4894 8.73261 16.4038 8.97869 16.2399 9.16999L13.4099 12Z"
          fill="currentColor"
        />
      </svg>
    );
  }
);
