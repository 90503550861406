import { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import XIcon from "@heroicons/react/outline/XIcon";
import { ConfirmationModal } from "components/ConfirmationModal";
import { useAppointmentRequest } from "../../AppointmentRequestContext";
import { analytics } from "../../../../lib/analytics";

const CLOSE_REQUEST = gql`
  mutation CloseRequest($appointmentRequestId: UUID4!) {
    closeAppointmentRequest(appointmentRequestId: $appointmentRequestId) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
      }
    }
  }
`;

interface Data {
  closeAppointmentRequest: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
    };
  };
}

interface Variables {
  appointmentRequestId: string;
}

interface CloseRequestModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const CloseRequestModal: FC<CloseRequestModalProps> = props => {
  const { isOpen, onClose } = props;

  const {
    appointmentRequest: { id, insertedAt },
    refetch,
  } = useAppointmentRequest();
  const [exhaustRequest] = useMutation<Data, Variables>(CLOSE_REQUEST);

  const onSubmit = useCallback(() => {
    return exhaustRequest({ variables: { appointmentRequestId: id } }).then(
      resp => {
        if (resp.data?.closeAppointmentRequest.errors) {
          toast.error(resp.data.closeAppointmentRequest.errors[0].message);
        } else if (resp.data?.closeAppointmentRequest.appointmentRequest) {
          // it worked...
          analytics.track("Close Referral", {
            referral_id: id,
            referral_requested_date: insertedAt,
          });
          toast.success("Request marked as closed");
          refetch();
        }
      }
    );
  }, [id, exhaustRequest, refetch]);

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      color="red"
      icon={XIcon}
      title="Close Request"
      prompt="Continue"
      onSubmit={onSubmit}
    >
      <p className="text-sm text-gray-500">
        Are you sure you want to close this referral?
      </p>
    </ConfirmationModal>
  );
};
