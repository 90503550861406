import React, { FC, createContext, useContext } from "react";
import { ApolloQueryResult } from "@apollo/client";
import { QueryData, QueryVariables, SearchResults } from "./model";

interface State {
  refetch(
    variables?: QueryVariables | undefined
  ): Promise<ApolloQueryResult<QueryData>>;
  searchResults: SearchResults;
}

const searchInterfaceContext = createContext<State>(null!);

interface SearchInterfaceProviderProps {
  refetch(
    variables?: QueryVariables | undefined
  ): Promise<ApolloQueryResult<QueryData>>;
  searchResults: SearchResults;
}

export const SearchInterfaceProvider: FC<SearchInterfaceProviderProps> = (
  props
) => {
  const { refetch, searchResults, children } = props;

  return (
    <searchInterfaceContext.Provider value={{ refetch, searchResults }}>
      {children}
    </searchInterfaceContext.Provider>
  );
};

export function useSearchInterface() {
  const context = useContext(searchInterfaceContext);
  if (context === undefined) {
    throw new Error(
      `useSearchInterface must be used within a SearchInterfaceContext`
    );
  }
  return context;
}
