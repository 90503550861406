import React, { FC } from "react";
import { components, OptionProps } from "react-select";
import { CircleIcon } from "@preferral/ui";
import { mmDdYyyy } from "lib/dateFormatters";

export const PATIENT_FORM_OPTION_GQL_FIELDS = `
id
title
insertedAt
updatedAt
`;

export interface PatientFormModel {
  id: string;
  title: string;
  insertedAt: string;
  updatedAt: string;
}

/**
 * PatientFormOption.
 */

interface PatientFormOptionProps {
  patientForm: PatientFormModel;
}

export const PatientFormOption: FC<PatientFormOptionProps> = props => {
  const { patientForm } = props;
  return (
    <div className="_PatientFormOption flex items-center">
      <div className="flex-shrink-0">
        <CircleIcon icon="file-alt" />
      </div>
      <div className="ml-3">
        <p className="leading-tight text-cool-gray-900 font-semibold">
          {patientForm.title}
        </p>
        <div className="mt-1 leading-none text-cool-gray-700 text-xs italic">
          <p>Last Updated: {mmDdYyyy(patientForm.insertedAt)}</p>
        </div>
      </div>
    </div>
  );
};

/**
 * PatientFormSelectOption.
 */
type PatientFormSelectOptionProps = OptionProps<PatientFormModel, false>;

export const PatientFormSelectOption: FC<PatientFormSelectOptionProps> = props => {
  return (
    <components.Option {...props}>
      <PatientFormOption patientForm={props.data} />
    </components.Option>
  );
};
