import React, { FC } from "react";
import { ScreenTitle } from "context/ScreenTitle";

interface TextMessagesScreenProps {}

export const TextMessagesScreen: FC<TextMessagesScreenProps> = () => {
  return (
    <div className="TextMessagesScreen">
      <ScreenTitle title="Text Messages" />
      <p>TextMessagesScreen</p>
    </div>
  );
};
