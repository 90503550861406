import React, { FC, useCallback } from "react";
import * as Yup from "yup";
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { Button, PhoneMaskField } from "@preferral/ui";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { analytics } from "lib/analytics";

import { useAppointmentRequest } from "../../AppointmentRequestContext";

const SEND_FAX = gql`
  mutation appointmentRequestSendFax($appointmentRequestId: UUID4!, $faxNumber: String!){
    appointmentRequestSendFax(appointmentRequestId: $appointmentRequestId, faxNumber: $faxNumber) {
    appointmentRequest {
      id
    }
    errors {
      key
      message
    }
  }
}
`;

interface MutationData {
  appointmentRequestSendFax: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appointmentRequestId: string;
  faxNumber: string;
}

interface FormValues {
  fax: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    fax: Yup.string().matches(
      /^\(\d{3}\)\s\d{3}-\d{4}$/,
      "Invalid fax number"
    )
  })
  .required();

interface SendFaxFormProps {
  onSuccess(): void;
}

export const SendFaxForm: FC<SendFaxFormProps> = (props) => {
  const { onSuccess } = props;
  const {
    appointmentRequest: { id: appointmentRequestId, requestedNppesLocation },
    refetch,
  } = useAppointmentRequest();

  const [sendFax] = useMutation<MutationData, MutationVariables>(
    SEND_FAX
  );

  const onSubmit = useCallback(
    (values, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;
      setStatus({ errors: null });
      return sendFax({
        variables: { appointmentRequestId, faxNumber: values.fax },
      }).then((resp) => {
        if (resp.data?.appointmentRequestSendFax.errors) {
          setStatus({
            errors: resp.data.appointmentRequestSendFax.errors,
          });
        } else if (
          resp.data?.appointmentRequestSendFax.appointmentRequest
        ) {
          // it worked....
          analytics.track("Sent Fax Manually", { referral_id: appointmentRequestId })
          toast.success("Fax sent!");
          refetch();
          onSuccess();
        }
        setSubmitting(false);
      });
    },
    [appointmentRequestId, sendFax, onSuccess, refetch]
  );

  return (
    <Formik
      initialValues={{ fax: requestedNppesLocation?.fax?.formatted || "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <PhoneMaskField
            name="fax"
            label="Fax To:"
            placeholder="(___) ___-____"
            icon="fax"
          />
          <div className="mt-3 text-center">
            <Button
              type="submit"
              kind="primary"
              color="blue"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Send Fax
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
