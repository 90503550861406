import { FC, useState } from "react";
import { PillTabs, PillTab } from "@preferral/ui";
import { Modal, ModalHeader } from "components/Modal";
import { SendSingleFormForm } from "./SendSingleFormForm";
import { SendPacketForm } from "./SendPacketForm";

interface SendFormModalProps {
  isOpen: boolean;
  onClose(): void;
}

type ActiveTab = "PACKET" | "SINGLE_FORM";

export const SendFormModal: FC<SendFormModalProps> = (props) => {
  const { isOpen, onClose } = props;

  const [activeTab, setActiveTab] = useState<ActiveTab>("PACKET");

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="paper-plane"
        title="Send patient forms"
        onClose={onClose}
      />

      <div className="flex justify-center">
        <PillTabs
          activeTab={activeTab}
          onChange={setActiveTab as (tab: string) => void}
        >
          <PillTab tab="PACKET">Packet</PillTab>
          <PillTab tab="SINGLE_FORM">Single Form</PillTab>
        </PillTabs>
      </div>
      <div className="p-4">
        {activeTab === "PACKET" ? (
          <SendPacketForm onSuccess={onClose} />
        ) : (
          <SendSingleFormForm onSuccess={onClose} />
        )}
      </div>
    </Modal>
  );
};
