import React, { FC, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { GradientHeader } from "../../GradientHeader";
import { ModalHeader, Modal } from "components/Modal";
import { TableContainer, Table, TH, TD } from "@preferral/ui";
import { NewDepartmentForm } from "./NewDepartmentForm";
import { DropdownButton, DropdownItemButton } from "components/DropdownButton";
import { Spinner } from "components/Spinner";
import { FAIcon } from "components/FAIcon";
import { ScreenTitle } from "context/ScreenTitle";
import { analytics } from "lib/analytics";

const DEPARTMENTS = gql`
  query ListDepartments {
    me {
      id
      organization {
        id
        departments {
          id
          name
          userCount
          providerCount
          locationCount
          specialtyCount
          insertedAt
          updatedAt
        }
      }
    }
  }
`;

interface Data {
  me: {
    organization: {
      departments: {
        id: string;
        name: string;
        userCount: number;
        locationCount: number;
        providerCount: number;
        specialtyCount: number;
        insertedAt: string;
        updatedAt: string;
      }[];
    };
  };
}

interface DepartmentsProps {}

export const Departments: FC<DepartmentsProps> = () => {
  const history = useHistory();

  const { data, loading, error } = useQuery<Data>(DEPARTMENTS);
  const [currentModal, setCurrentModal] = useState<"CREATE_DEPARTMENT" | null>(
    null
  );

  const openCreateModal = useCallback(() => {
    setCurrentModal("CREATE_DEPARTMENT");
  }, [setCurrentModal]);

  const dismissModal = useCallback(() => {
    setCurrentModal(null);
  }, [setCurrentModal]);

  return (
    <>
      <ScreenTitle title="Settings » Departments" />
      <Modal
        isOpen={currentModal === "CREATE_DEPARTMENT"}
        onRequestClose={dismissModal}
      >
        <ModalHeader
          icon="sitemap"
          title="Create Department"
          onClose={dismissModal}
        />
        <div className="px-8 pt-6 pb-8 flex flex-col">
          <NewDepartmentForm
            refetchQueries={[
              {
                query: DEPARTMENTS,
              },
            ]}
            closeModal={dismissModal}
            onSuccess={(departmentId: string) => {
              analytics.track("Add Department");
              history.push(`/o/settings/departments/${departmentId}/info`);
            }}
          />
        </div>
      </Modal>
      <div className="bg-white box rounded-lg shadow-lg">
        <GradientHeader
          icon="sitemap"
          title="Departments"
          subtitle="Manage your organization's departments."
        />
        <div className="p-4">
          {loading ? (
            <div className="p-12 text-center">
              <Spinner />
            </div>
          ) : error || !(data && data.me) ? (
            <div>
              <p>Failed to load</p>
            </div>
          ) : (
            <div>
              <div className="flex justify-end px-3 pb-4">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={openCreateModal}
                >
                  <span className="mr-2">
                    <FAIcon icon="plus" />
                  </span>
                  Add Department
                </button>
              </div>
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <TH>Name</TH>
                      <TH />
                      <TH />
                      <TH />
                      <TH />
                      <TH />
                    </tr>
                  </thead>
                  <tbody>
                    {data.me.organization.departments.map((dept) => (
                      <tr key={dept.id}>
                        <TD>
                          <p className="text-gray-900 font-semibold">
                            {dept.name}
                          </p>
                          <p className="text-gray-500 text-xs">
                            Created:{" "}
                            {format(
                              parseISO(dept.insertedAt),
                              "M/d/yy 'at' h:mm a"
                            )}
                          </p>
                        </TD>
                        <TD>
                          <p className="text-gray-900 text-lg">
                            {dept.userCount}
                          </p>{" "}
                          <p className="font-semibold text-gray-700 text-xs">
                            users
                          </p>
                        </TD>
                        <TD>
                          <p className="text-gray-900 text-lg">
                            {dept.providerCount}
                          </p>{" "}
                          <p className="font-semibold text-gray-700 text-xs">
                            providers
                          </p>
                        </TD>
                        <TD>
                          <p className="text-gray-900 text-lg">
                            {dept.locationCount}
                          </p>{" "}
                          <p className="font-semibold text-gray-700 text-xs">
                            locations
                          </p>
                        </TD>
                        <TD>
                          <p className="text-gray-900 text-lg">
                            {dept.specialtyCount}
                          </p>{" "}
                          <p className="font-semibold text-gray-700 text-xs">
                            specialties
                          </p>
                        </TD>
                        <TD className="w-40">
                          <DropdownButton label="Actions">
                            <DropdownItemButton onClick={() => null}>
                              Test
                            </DropdownItemButton>
                          </DropdownButton>
                        </TD>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
