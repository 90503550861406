import { FC } from "react";
import { Toaster } from "react-hot-toast";
import "./App.css";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import { PrivateRoute } from "components/PrivateRoute/PrivateRoute";
import { NoMatchScreen } from "screens/NoMatchScreen/NoMatchScreen";
import { PortalLayout } from "layouts/PortalLayout";
import { ConfirmAccountScreen } from "screens/ConfirmAccountScreen";
import { useAuth } from "context/AuthContext";
import { useLayoutEffect } from "react";
import { ImpersonationAuthScreen } from "screens/ImpersonationAuthScreen";
import { SignInScreen } from "screens/SignInScreen";
import { SSOScreen } from "screens/SSOScreen";
import { ForgotPasswordScreen } from "screens/ForgotPasswordScreen";
import { InvitationRegisterScreen } from "screens/InvitationRegisterScreen";
import { ResetPasswordScreen } from "screens/ResetPasswordScreen";
import { ReferralCodeScreen } from "screens/ReferralCodeScreen/ReferralCodeScreen";
import { FillOutPatientFormScreen } from "screens/FillOutPatientFormScreen";
import { AmdSSOScreen } from "screens/AmdSSOScreen";

const AuthRoutes: FC = () => {
  const { token } = useAuth();
  const location = useLocation();
  const history = useHistory();

  const { from } = (location.state as any) || {
    from: { pathname: "/o/dashboard" },
  };

  // If the user seems to be signed in, forward them to
  // the authenticated screens.
  useLayoutEffect(() => {
    if (token && !location.pathname.includes("/auth/impersonate")) {
      history.replace(from);
    }
  }, [history, token, from, location.pathname]);

  return (
    <Switch>
      <Route exact path="/auth/impersonate">
        <ImpersonationAuthScreen />
      </Route>
      <Route exact path="/auth/sign_in">
        <SignInScreen />
      </Route>
      <Route exact path="/auth/sso">
        <SSOScreen />
      </Route>
      <Route exact path="/auth/forgot_password">
        <ForgotPasswordScreen />
      </Route>
      <Route exact path="/auth/reset_password">
        <ResetPasswordScreen />
      </Route>
      <Route exact path="/auth/invitation_register">
        <InvitationRegisterScreen />
      </Route>
      <Route exact path="/auth/amd_sso">
        <AmdSSOScreen />
      </Route>
    </Switch>
  );
};

const PublicUserRoutes: FC = () => {
  return (
    <Switch>
      <Route exact path="/ug/referral_code">
        <ReferralCodeScreen />
      </Route>
    </Switch>
  );
};

const PublicPatientRoutes: FC = () => {
  return (
    <Switch>
      <Route path="/pg/forms/:formId">
        <FillOutPatientFormScreen />
      </Route>
    </Switch>
  );
};



export const App: FC = () => {
  return (
    <div className="App antialiased">
      <Switch>
        <Route exact path="/">
          <Redirect to="/auth/sign_in" />
        </Route>
        <Route exact path="/confirm_account">
          <ConfirmAccountScreen />
        </Route>
        <Route path="/auth/">
          <AuthRoutes />
        </Route>
        <PrivateRoute path="/o/">
          {/* <PortalLayout /> */}
          <PortalLayout />
        </PrivateRoute>
        {/* NB: /ug/ is for UserGuest, which is someone acting as a practice user that doesn't necessarily have an account */}
        <Route path="/ug/">
          <PublicUserRoutes />
        </Route>
        {/* NB: /pg/ is for PatientGuest, which is someone acting as a patient user that doesn't necessarily have an account. */}
        <Route path="/pg/">
          <PublicPatientRoutes />
        </Route>
        <Route path="*">
          <NoMatchScreen />
        </Route>
      </Switch>
      <Toaster position="top-right" />
    </div>
  );
};
