import { FC, useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import {
  Spinner,
  TableContainer,
  Table,
  TH,
  TD,
  Button,
  makeAppendItems,
  FAIcon,
  Tooltip,
} from "@preferral/ui";
import { NoResults } from "components/NoResults";
import { FilterPanel, FilterModel } from "./FilterPanel";
import { distanceInWords, hMmA, mmDdYyyy } from "lib/dateFormatters";
import {
  AppointmentRequestStatusModel,
  TaskModel,
} from "screens/AppointmentRequestShowScreen/model";
import { StatusBadge } from "screens/ReferralsScreen/SentReferralsScreen";
import { UserAvatar } from "components/UserAvatar";

const PAGE_SIZE = 50;

const LIST_APPOINTMENT_REQUESTS = gql`
  query ListAppointmentRequests(
    $first: Int
    $after: UUID4
    $filter: ListAppointmentRequestsFilter
  ) {
    appointmentRequests(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        status
        insertedAt
        updatedAt
        requestedSpecialty {
          id
          name
        }
        requestedProvider {
          id
          nameWithAppellation
        }
        requestedLocation {
          id
          name
        }
        assignedReceivingUser {
          id
          name
          firstName
          lastName
        }
        tasks {
          id
          isDue
          done
        }
        patientMember {
          id
          medicalRecordNumber
          patient {
            id
            name
            dob
            primaryPhone {
              raw
              formatted
            }
          }
        }
      }
    }
  }
`;

interface Data {
  appointmentRequests: Paginated<AppointmentRequestModel>;
}

interface AppointmentRequestModel {
  id: string;
  status: AppointmentRequestStatusModel;
  insertedAt: string;
  updatedAt: string;
  requestedSpecialty: {
    id: string;
    name: string;
  };
  requestedProvider: null | {
    id: string;
    nameWithAppellation: string;
  };
  requestedLocation: null | {
    id: string;
    name: string;
  };
  assignedReceivingUser: null | {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
  };
  tasks: {
    id: string;
    isDue: boolean;
    done: boolean;
  }[];
  patientMember: PatientMemberModel;
}

interface PatientMemberModel {
  id: string;
  medicalRecordNumber?: string;
  patient: PatientModel;
}

interface PatientModel {
  id: string;
  name: string;
  dob: string;
  primaryPhone: {
    raw: string;
    formatted: string;
  };
}

/**
 * ReferralRecipient.
 */

interface ReferralRecipientProps {
  appointmentRequest: AppointmentRequestModel;
}

const ReferralRecipient: FC<ReferralRecipientProps> = props => {
  const { appointmentRequest } = props;

  const providerName =
    appointmentRequest.requestedProvider?.nameWithAppellation;
  const locationName = appointmentRequest.requestedLocation?.name;

  return (
    <div className="_ReferralRecipient">
      <div className="_provider flex items-center">
        <FAIcon icon="user-md" className="mr-2 text-gray-500" />
        {providerName ? (
          providerName
        ) : (
          <span className="text-gray-600 text-light italic">Unassigned</span>
        )}
      </div>
      <div className="_location flex items-center">
        <FAIcon icon="map-marker-alt" className="mr-2 text-gray-500" />
        {locationName ? (
          locationName
        ) : (
          <span className="text-gray-600 text-light italic">Unassigned</span>
        )}
      </div>
    </div>
  );
};

/**
 * AppointmentRequestInboxScreen.
 */

interface AppointmentRequestInboxScreenProps {}

export const AppointmentRequestInboxScreen: FC<AppointmentRequestInboxScreenProps> =
  props => {
    const {
      params: { inboxId },
    } = useRouteMatch<{ inboxId: string }>();

    const [filter, setFilter] = useState<FilterModel>({ inboxId });

    const { data, loading, error, fetchMore } = useQuery<Data>(
      LIST_APPOINTMENT_REQUESTS,
      {
        variables: { filter },
        nextFetchPolicy: "network-only",
      }
    );

    function areTasksDone(
      tasks: { id: string; isDue: boolean; done: boolean }[]
    ) {
      return tasks.every(t => {
        return t.done;
      });
    }

    // When `inboxId` changes, reset filter and refetch
    useEffect(() => {
      const newFilter = { inboxId };
      setFilter(newFilter);
    }, [inboxId]);

    return (
      <div className="_AppointmentRequestInboxScreen text-left container mx-auto ">
        <div className="py-4 lg:px-4 max-w-5xl">
          <FilterPanel
            key={inboxId}
            inboxId={inboxId}
            value={filter}
            onChange={setFilter}
            isLoading={loading}
          />
        </div>
        {loading ? (
          <div className="p-6 text-center">
            <Spinner />
          </div>
        ) : error || !data?.appointmentRequests ? (
          <p>Failed to load</p>
        ) : data.appointmentRequests.items.length === 0 ? (
          <div className="pb-8 lg:px-4">
            <NoResults icon="inbox" text="No matching appointment requests" />
          </div>
        ) : (
          <div className="pb-8 lg:px-4">
            <TableContainer>
              <Table className="text-left">
                <thead>
                  <tr>
                    <TH className="text-center">
                      <FAIcon icon="user-tag" />
                    </TH>
                    <TH>Patient</TH>
                    <TH>Specialty</TH>
                    <TH>Referred To</TH>
                    <TH className="text-center" style={{ width: 174 }}>
                      Status
                    </TH>
                    <TH>Received At</TH>
                  </tr>
                </thead>
                <tbody>
                  {data.appointmentRequests.items.map(ar => (
                    <tr
                      key={ar.id}
                      className={`${
                        areTasksDone(ar.tasks) ||
                        ar.tasks.length === 0 ||
                        ar.tasks.find(t => !t.done && t.isDue)
                          ? "bg-white opacity-1"
                          : "bg-gray-200 opacity-75"
                      } hover:bg-blue-50 transition-colors duration-300`}
                    >
                      <TD
                        className="text-center"
                        style={{ paddingLeft: "1rem", paddingRight: 0 }}
                      >
                        {ar.assignedReceivingUser ? (
                          <Tooltip tip={ar.assignedReceivingUser.name}>
                            <div className="inline-block">
                              <UserAvatar
                                className="inline-block"
                                firstName={ar.assignedReceivingUser.firstName}
                                lastName={ar.assignedReceivingUser.lastName}
                              />
                            </div>
                          </Tooltip>
                        ) : null}
                      </TD>
                      <TD>
                        <div className="flex items-center">
                          <div>
                            <Link
                              to={`/o/appointment_requests/${ar.id}`}
                              className="text-sm font-medium text-blue-600 hover:text-gray-700"
                            >
                              {ar.patientMember.patient.name}
                            </Link>
                            <div className="text-sm text-gray-500">
                              <span className="font-light italic mr-1">
                                DOB:{" "}
                              </span>
                              {mmDdYyyy(ar.patientMember.patient.dob)}
                            </div>
                          </div>
                        </div>
                      </TD>
                      <TD>
                        <p>{ar.requestedSpecialty.name}</p>
                      </TD>
                      <TD>
                        <ReferralRecipient appointmentRequest={ar} />
                      </TD>
                      <TD className="text-center">
                        <StatusBadge status={ar.status} />
                      </TD>
                      <TD style={{ width: 172 }}>
                        <div className="group text-xs">
                          <p className="text-gray-500 group-hover:hidden">
                            {distanceInWords(ar.insertedAt)} ago
                          </p>
                          <p className="text-gray-500 hidden group-hover:block">
                            {mmDdYyyy(ar.insertedAt)} {hMmA(ar.insertedAt)}
                          </p>
                        </div>
                      </TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="bg-white border-gray-300 border-t p-2 text-center text-gray-500">
                {data.appointmentRequests.endOfList ? (
                  <p>End of List</p>
                ) : (
                  <Button
                    type="button"
                    size="sm"
                    kind="secondary"
                    color="blue"
                    className="w-full"
                    onClick={() => {
                      fetchMore({
                        query: LIST_APPOINTMENT_REQUESTS,
                        variables: {
                          first: PAGE_SIZE,
                          after: data.appointmentRequests.cursor,
                          filter,
                        },
                        updateQuery,
                      });
                    }}
                  >
                    Load More
                  </Button>
                )}
              </div>
            </TableContainer>
          </div>
        )}
      </div>
    );
  };

const updateQuery = makeAppendItems<Data>("appointmentRequests");
