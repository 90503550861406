import { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Button, TextField, SelectField, PhoneMaskField, DateMaskField } from "@preferral/ui";
import { localDateToISO } from '@preferral/common';
import {
  PatientPacketSelectOption,
  PATIENT_PACKET_OPTION_GQL_FIELDS,
  PatientPacketModel,
} from "components/PatientPacketOption";
import toast from "react-hot-toast";
import { pick } from "lib/objectUtils";
import { analytics } from "lib/analytics";
import { determineChannel } from "./helpers";

const LIST_PATIENT_PACKETS = gql`
  query ListPatientPackets {
    patientPackets {
      endOfList
      cursor
      items {
        ${PATIENT_PACKET_OPTION_GQL_FIELDS}
      }
    }
  }
`;

interface Data {
  patientPackets: Paginated<PatientPacketModel>;
}

const SEND_PATIENT_PACKET = gql`
  mutation SendPatientPacket($id: UUID4!, $input: SendPatientPacketInput!) {
    sendPatientPacket(id: $id, input: $input) {
      errors {
        key
        message
      }
      patientPacketSubmission {
        id
      }
    }
  }
`;

interface MutationData {
  sendPatientPacket: {
    errors?: InputError[];
    patientPacketSubmission?: {
      id: string;
    };
  };
}

interface MutationVariables {
  id: string;
  input: SendPatientPacketInput;
}

interface SendPatientPacketInput {
  phoneNumber?: string;
  email?: string;
}

interface FormValues {
  patientPacketId: string;
  patientFirstName: string;
  patientLastName: string;
  patientDob: string;
  appointmentDate: string;
  email: string;
  phoneNumber: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    patientPacketId: Yup.string().required("Required"),
    email: Yup.string(),
    phoneNumber: Yup.string(),
  })
  .required();

interface SendPacketFormProps {
  onSuccess(): void;
}

export const SendPacketForm: FC<SendPacketFormProps> = props => {
  const { onSuccess } = props;

  const { data, loading } = useQuery<Data>(LIST_PATIENT_PACKETS);

  const patientPacketOptions = data?.patientPackets.items || [];

  const [sendPatientPacket] = useMutation<MutationData, MutationVariables>(
    SEND_PATIENT_PACKET
  );

  const onSubmit = useCallback(
    async (values, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      const variables: MutationVariables = {
        id: values.patientPacketId,
        input: pick<Record<string, string>, string>(
          {
            patientFirstName: values.patientFirstName.trim(),
            patientLastName: values.patientLastName.trim(),
            patientDob: values.patientDob ? localDateToISO(values.patientDob) : "",
            appointmentDate: values.appointmentDate ? localDateToISO(values.appointmentDate) : "",
            email: values.email.trim(),
            phoneNumber: values.phoneNumber.replace(/\D/g, ""),
          },
          v => v !== ""
        ),
      };

      try {
        const { data } = await sendPatientPacket({ variables });

        if (data?.sendPatientPacket.errors) {
          setStatus({ errors: data.sendPatientPacket.errors });
        } else if (data?.sendPatientPacket.patientPacketSubmission) {
          // it worked...
          toast.success("Patient packet sent!");
          const channel = determineChannel(variables.input);
          analytics.track("Patient Packet Sent", { channel });
          onSuccess();
        }
        setSubmitting(false);
      } catch (e) {
        toast.error("Something went wrong.");
        console.error(e);
        setSubmitting(false);
      }
    },
    [sendPatientPacket, onSuccess]
  );

  return (
    <Formik<FormValues>
      initialValues={{
        patientPacketId: "",
        patientFirstName: "",
        patientLastName: "",
        patientDob: "",
        appointmentDate: "",
        email: "",
        phoneNumber: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <SelectField
            name="patientPacketId"
            label="Packet"
            options={patientPacketOptions}
            isLoading={loading}
            getOptionLabel={(o: PatientPacketModel) => o.title}
            getOptionValue={(o: PatientPacketModel) => o.id}
            components={{ Option: PatientPacketSelectOption }}
          />

          <div className="mt-3 grid grid-cols-2 gap-3">
            <TextField
              name="patientFirstName"
              label="Patient First Name"
            />

            <TextField
              name="patientLastName"
              label="Patient Last Name"
            />
          </div>

          <div className="mt-3 grid grid-cols-2 gap-3">
            <DateMaskField indicateOptional name="patientDob" label="Patient DOB" />
            <DateMaskField indicateOptional name="appointmentDate" label="Appointment Date" />
          </div>

          <div className="mt-3">
            <TextField indicateOptional name="email" label="Email Address" />
          </div>

          <div className="mt-3">
            <PhoneMaskField
              indicateOptional
              name="phoneNumber"
              label="Phone Number"
            />
          </div>

          <div className="flex items-center justify-end mt-3 py-4">
            <Button
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting}
              color="blue"
            >
              Send Packet
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
