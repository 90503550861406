import { FC, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { ToggleSwitch, Spinner } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { GradientHeader } from "../../GradientHeader";
import {
  CommunicationPreference,
  CommunicationType,
  groupByMedium,
  mediumLabel,
  mergeDefaultsAndPreferences,
  preferenceKey,
} from "screens/SettingsScreen/User/NotificationPreferences";
import { useSet } from "hooks/useSet";

const PATIENT_NOTIFICATION_PREFERENCES = gql`
  query DepartmentPatientNotificationPreferences($departmentId: UUID4!) {
    communicationTypes(ownerType: "patient") {
      medium
      notification
      label
      description
      defaultValue
    }
    department(id: $departmentId) {
      id
      patientCommunicationPreferences {
        medium
        notification
        value
      }
    }
  }
`;

interface Data {
  communicationTypes: CommunicationType[];
  department: {
    id: string;
    patientCommunicationPreferences: CommunicationPreference[];
  };
}

interface Variables {
  departmentId: string;
}

const UPDATE_NOTIFICATION_PREFERENCE = gql`
  mutation UpdatePatientNotificationPreference(
    $departmentId: UUID4!
    $medium: String!
    $notification: String!
    $value: Boolean!
  ) {
    updateDepartmentPatientCommunicationPreference(
      departmentId: $departmentId
      medium: $medium
      notification: $notification
      value: $value
    )
  }
`;

interface MutationData {
  updateDepartmentPatientCommunicationPreference: string;
}

interface MutationVariables {
  departmentId: string;
  medium: string;
  notification: string;
  value: boolean;
}

interface PatientNotificationsScreenProps { }

export const PatientNotificationsScreen: FC<PatientNotificationsScreenProps> =
  props => {
    const match = useRouteMatch<{ departmentId: string }>();
    const { departmentId } = match.params;

    const { data, loading, error } = useQuery<Data, Variables>(
      PATIENT_NOTIFICATION_PREFERENCES,
      { variables: { departmentId } }
    );

    const preferences =
      data &&
      groupByMedium(
        mergeDefaultsAndPreferences(
          data.communicationTypes,
          data.department.patientCommunicationPreferences
        )
      );

    const {
      add: addSaving,
      remove: removeSaving,
      has: isSaving,
    } = useSet<string>();

    const [updateNotificationPreference] = useMutation<
      MutationData,
      MutationVariables
    >(UPDATE_NOTIFICATION_PREFERENCE);

    const updatePreference = useCallback(
      async (newCommPref: CommunicationPreference) => {
        const key = preferenceKey(newCommPref);

        addSaving(key);

        try {
          await updateNotificationPreference({
            variables: { departmentId, ...newCommPref },
            refetchQueries: [
              {
                query: PATIENT_NOTIFICATION_PREFERENCES,
                variables: { departmentId },
              },
            ],
          });
          toast.success("Updated communication preference.");
        } catch (e) {
          console.error(e);
          toast.error("Something went wrong.");
        }
        removeSaving(key);
      },
      [departmentId, addSaving, removeSaving, updateNotificationPreference]
    );

    return (
      <>
        <ScreenTitle
          title={["Settings", "Department", "Patient Notifications"]}
        />
        <div className="_PatientNotificationsScreen bg-white box rounded-lg shadow-lg">
          <GradientHeader
            icon="bell"
            title="Patient Notifications"
            subtitle="Configure which notifications patients will receive."
          />
          <div className="p-8">
            {loading ? (
              <div className="p-12 text-center">
                <Spinner />
              </div>
            ) : error || !preferences ? (
              <p>Failed to load</p>
            ) : (
              <>
                {preferences.map(medium => (
                  <div key={medium.medium} className="mb-8">
                    <h3 className="capitalize text-gray-700 font-semibold max-w-3xl mb-4 mx-auto text-2xl w-full">
                      {mediumLabel(medium.medium)}
                    </h3>
                    {medium.items.map(item => (
                      <form
                        key={`${item.medium}-${item.notification}`}
                        className="border-b max-w-3xl mb-2 mx-auto pb-2 w-full"
                      >
                        <div className="-mx-6 flex items-center p-4">
                          <div className="flex-1 px-6">
                            <label className="font-semibold text-gray-900">
                              {item.label}
                            </label>
                            <p className="mt-3 text-gray-700 text-sm">
                              {item.description}
                            </p>
                          </div>
                          <div className="px-6 w-40">
                            <ToggleSwitch
                              id={`_PreferenceToggle-${preferenceKey(item)}`}
                              checked={item.value}
                              onChange={(enabled: boolean) =>
                                updatePreference({ ...item, value: enabled })
                              }
                              loading={isSaving(preferenceKey(item))}
                              showLabel
                            />
                          </div>
                        </div>
                      </form>
                    ))}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </>
    );
  };
