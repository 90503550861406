import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { AssignReferringTeammateForm } from "./AssignReferringTeammateForm";

interface AssignTeammateModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const AssignReferringTeammateModal: FC<AssignTeammateModalProps> = (props) => {
  const { isOpen, onClose } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="user-plus" title="Assign Teammate" onClose={onClose} />
      <div className="p-4">
        <AssignReferringTeammateForm onCancel={onClose} onSuccess={onClose} />
      </div>
    </Modal>
  );
};
