import React, { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  FileUploader as UIFileUploader,
  FileUploaderProps as UIFileUploaderProps,
  DoUploadFn,
  ServerFileModel,
} from "@preferral/ui";

const UPLOAD_FILE = gql`
  mutation UploadFile($upload: Upload!) {
    uploadFile(upload: $upload) {
      errors {
        key
        message
      }
      fileUpload {
        id
        name
        url
      }
    }
  }
`;

const AMD_UPLOAD_FILE = gql`
mutation UploadFile($upload: Upload!) {
    saveAmdAsset(upload: $upload) {
      errors {
        key
        message
      }
      fileUpload {
        id
        name
        url
      }
    }
  }
`;

interface Data {
  uploadFile?: {
    errors?: InputError[];
    fileUpload?: ServerFileModel;
  };
  saveAmdAsset?: {
    errors?: InputError[];
    fileUpload?: ServerFileModel;
  };
}

interface Variables {
  upload: Blob;
}

export type FileUploaderProps = Omit<UIFileUploaderProps, "doUpload">;

export const FileUploader: FC<FileUploaderProps> = (props) => {
  const { source } = props;
  const [uploadMut] = useMutation<Data, Variables>(source ? AMD_UPLOAD_FILE : UPLOAD_FILE);

  const doUpload = useCallback<DoUploadFn>(
    (upload, { onProgress, onAbortPossible }) => {
      return uploadMut({
        variables: {
          upload: upload.file,
        },
        context: {
          fetchOptions: {
            useUpload: true,
            onProgress,
            onAbortPossible,
          },
        },
      }).then((resp) => {
        const response = resp.data?.uploadFile || resp.data?.saveAmdAsset;
        if (response?.errors) {
          return Promise.reject(response.errors);
        } else if (response?.fileUpload) {
          return response.fileUpload;
        } else {
          return Promise.reject("Upload failed");
        }
      });
    },
    []
  );

  return <UIFileUploader doUpload={doUpload} {...props} />;
};
