import React, { FC } from "react";
import { FAIcon } from "@preferral/ui";
import { formatPhoneNumber } from "@preferral/common";

interface PlanContactDisplayProps {
  contact: JSONObject;
}

export const PlanContactDisplay: FC<PlanContactDisplayProps> = (props) => {
  const { contact } = props;

  if (contact.contact_type === "telephone") {
    return (
      <div className="leading-normal">
        <span className="mr-2 text-gray-600">
          <FAIcon icon="phone" />
        </span>
        {formatPhoneNumber(contact.contact_value)}
      </div>
    );
  } else if (contact.contact_type === "url") {
    const url = contact.contact_value.startsWith("www.")
      ? "https://" + contact.contact_value
      : contact.contact_value;
    return (
      <div className="leading-normal">
        <span className="mr-2 text-gray-600">
          <FAIcon icon="globe" />
        </span>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-blue-600"
        >
          {contact.contact_value}
        </a>
      </div>
    );
  } else if (contact.contact_type === "fax") {
    return (
      <div className="leading-normal">
        <span className="mr-2 text-gray-600">
          <FAIcon icon="fax" />
        </span>
        {formatPhoneNumber(contact.contact_value)}
      </div>
    );
  }
  return null;
};
