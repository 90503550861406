import React, { FC } from "react";
import { CircleIcon } from "@preferral/ui";
import { LocationModel } from "./model";
import { components, OptionProps } from "react-select";

export const LocationOptionGqlFields = `
id
name
streetAddress
streetAddressLine2
city
stateAbbreviation
zip
geom {
  lat
  lng
}
timeZoneName
`;

/**
 * LocationOption.
 */

export interface LocationOptionProps {
  location: LocationModel;
}

export const LocationOption: FC<LocationOptionProps> = (props) => {
  const { location } = props;
  return (
    <div className="_LocationOption flex items-center">
      <CircleIcon icon="map-marker-alt" />
      <div className="ml-3">
        <p className="leading-tight text-cool-gray-900 font-semibold">
          {location.name}
        </p>
        <div className="mt-1 leading-none text-cool-gray-700 text-xs">
          <p>
            {location.streetAddress}
            {location.streetAddressLine2 && `, ${location.streetAddressLine2}`}
          </p>
          <p>
            {location.city}, {location.stateAbbreviation}, {location.zip}
          </p>
        </div>
      </div>
    </div>
  );
};

/**
 * LocationSelectOption.
 */
type LocationSelectOptionProps = OptionProps<LocationModel, false>;

export const LocationSelectOption: FC<LocationSelectOptionProps> = (props) => {
  return (
    <components.Option {...props}>
      <LocationOption location={props.data} />
    </components.Option>
  );
};
