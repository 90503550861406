import { FC } from "react";
import { Modal, ModalHeader } from "components/Modal";
import { ChangeSpecialtyForm } from "./ChangeSpecialtyForm";

interface ChangeSpecialtyModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const ChangeSpecialtyModal: FC<ChangeSpecialtyModalProps> = (props) => {
  const { isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="stethoscope"
        title="Update Specialty"
        onClose={onClose}
      />
      <div className="p-4">
        <ChangeSpecialtyForm onSuccess={onClose} />
      </div>
    </Modal>
  );
};
