import { FC } from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { CircleIcon } from "components/CircleIcon";
import { FAIcon, Spinner, DetailList, DetailItem } from '@preferral/ui'
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { PatientFormSubmissionView } from "./PatientFormSubmissionView";
import { distanceInWords, hMmA, mmDdYyyy } from "lib/dateFormatters";
import { formatPhoneNumber } from "@preferral/common";

const PATIENT_PACKET_SUBMISSION = gql`
  query GetPatientPacketSubmission($id: UUID4!) {
    patientPacketSubmission(id: $id) {
      id
      title
      insertedAt
      resentAt
      openedAt
      completedAt
      status
      patientPacket {
        id
      }
      patientAccount {
        id
        phoneNumber
        email
      }
      patientFormSubmissionsCount
      completedPatientFormsCount
      patientFormSubmissions {
        id
        title
        startedAt
        submittedAt
        status
      }
    }
  }
`;

interface PatientPacketSubmissionModel {
  id: string;
  title: string;
  insertedAt: string;
  resentAt: string | void;
  openedAt?: string;
  completedAt?: string;
  status: string;
  patientPacket: {
    id: string;
  };
  patientAccount: {
    id: string;
    email: string;
    phoneNumber: string;
  };
  patientFormSubmissionsCount: number;
  completedPatientFormsCount: number;
  patientFormSubmissions: PatientFormSubmissionModel[];
}

interface PatientFormSubmissionModel {
  id: string;
  title: string;
  startedAt?: string;
  submittedAt?: string;
  status: string;
}

interface Data {
  patientPacketSubmission: PatientPacketSubmissionModel
}

interface Variables {
  id: string;
}

/**
 * PatientFormLink.
 */

interface PatientFormLinkProps {
  to: string;
  patientFormSubmission: PatientFormSubmissionModel
  className?: string;
}

const patientFormLinkIcons = {
  UNOPENED: "clipboard",
  IN_PROGRESS: "clipboard-list",
  COMPLETED: "clipboard-check"
}

const statusClassNames = {
  UNOPENED: "text-gray-600",
  IN_PROGRESS: "text-purple-600",
  COMPLETED: "text-green-600"
};

const statusLabels = {
  UNOPENED: "Not Started",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed"
};

const PatientFormLink: FC<PatientFormLinkProps> = props => {
  const { to, patientFormSubmission, className = '' } = props;
  const match = useRouteMatch(to);
  const isActive = !!match;

  const linkClassNames = isActive
    ? "bg-blue-50 border-blue-500 text-blue-700 hover:bg-blue-50 hover:text-blue-700 focus:bg-blue-100 focus:text-blue-700"
    : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900 focus:bg-gray-50 focus:text-gray-900";

  const iconClassNames = isActive
    ? "text-blue-500 group-hover:text-blue-500 group-focus:text-blue-600"
    : "text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500";

  const statusClassName = statusClassNames[patientFormSubmission.status];
  const statusLabel = statusLabels[patientFormSubmission.status];

  return (
    <Link to={to} className={`${linkClassNames} ${className} group rounded-md border-l-4 px-3 py-2 flex items-center text-sm leading-5 font-medium focus:outline-none transition ease-in-out duration-150`}>
      <div className="flex items-center flex-grow flex-shrink min-w-0">
        <span className="mr-3">
          <FAIcon icon={patientFormLinkIcons[patientFormSubmission.status]} className={iconClassNames} />
        </span>
        <p className="flex-grow flex-shrink min-w-0 truncate">{patientFormSubmission.title}
        </p>
      </div>
      <span className={`ml-1 whitespace-no-wrap italic font-semibold text-xs ${statusClassName}`}>
        {statusLabel}
      </span>
    </Link>
  );
}

/**
 * PatientPacketShowScreen.
 */

interface PatientPacketShowScreenProps { };

interface RouteParams {
  patientPacketSubmissionId: string;
}

export const PatientPacketShowScreen: FC<PatientPacketShowScreenProps> = props => {
  const match = useRouteMatch<RouteParams>();
  const { patientPacketSubmissionId } = match.params;

  const { data, loading, error } = useQuery<Data, Variables>(PATIENT_PACKET_SUBMISSION, { variables: { id: patientPacketSubmissionId } });

  return (
    <div className="_PatientPacketShowScreen px-5 container mx-auto text-left">
      <div className="mt-5 sm:mt-6">
        <Link to={`/o/patient_packets`}
          className="flex items-center font-medium text-blue-500 hover:text-blue-600 focus:outline-none"
        >
          <ArrowLeftIcon className="h-4 w-4 mr-2" />Back to Patient Packets
        </Link>
      </div>
      {loading ? (
        <div className="p-12 text-center">
          <Spinner />
        </div>
      ) : error || !data?.patientPacketSubmission ? (
        <p>Failed to load.</p>
      ) : (
        <div className="_PatientPacketShowScreen py-6 flex">
          <div className="_Sidemenu w-full max-w-sm">
            <div className="p-4 bg-white border rounded-lg shadow">

              <div>

                <h3 className="mt-2 text-lg font-semibold text-gray-700">Packet Details</h3>
                <DetailList className="mt-4">
                  <DetailItem label="Patient Phone">
                    {data.patientPacketSubmission.patientAccount.phoneNumber ? formatPhoneNumber(data.patientPacketSubmission.patientAccount.phoneNumber) : "-"}
                  </DetailItem>
                  <DetailItem label="Patient Email">
                    {data.patientPacketSubmission.patientAccount.email || "-"}
                  </DetailItem>
                  <DetailItem label="Sent At">
                    <p className="text-base">{mmDdYyyy(data.patientPacketSubmission.insertedAt)} at {hMmA(data.patientPacketSubmission.insertedAt)}</p>
                    <p className="italic pt-1 text-gray-600 text-xs">{distanceInWords(data.patientPacketSubmission.insertedAt)} ago</p>
                  </DetailItem>
                  {
                    data.patientPacketSubmission.resentAt ? (
                      <DetailItem label="Last Resent At">
                        <p className="text-base">{mmDdYyyy(data.patientPacketSubmission.resentAt)} at {hMmA(data.patientPacketSubmission.resentAt)}</p>
                        <p className="italic pt-1 text-gray-600 text-xs">{distanceInWords(data.patientPacketSubmission.resentAt)} ago</p>
                      </DetailItem>
                    ) : null
                  }
                  <DetailItem label="Opened At">
                    {
                      data.patientPacketSubmission.openedAt ? (
                        <>
                          <p className="text-base">{mmDdYyyy(data.patientPacketSubmission.openedAt)} at {hMmA(data.patientPacketSubmission.openedAt)}</p>
                          <p className="italic pt-1 text-gray-600 text-xs">{distanceInWords(data.patientPacketSubmission.openedAt)} ago</p>
                        </>
                      ) : "-"
                    }
                  </DetailItem>
                  <DetailItem label="Completed At">
                    {
                      data.patientPacketSubmission.completedAt ? (
                        <>
                          <p className="text-base">{mmDdYyyy(data.patientPacketSubmission.completedAt)} at {hMmA(data.patientPacketSubmission.completedAt)}</p>
                          <p className="italic pt-1 text-gray-600 text-xs">{distanceInWords(data.patientPacketSubmission.completedAt)} ago</p>
                        </>
                      ) : "-"
                    }
                  </DetailItem>
                </DetailList>
              </div>

              <div>
                <h3 className="mt-8 text-lg font-semibold text-gray-700">Forms</h3>

                <nav className="mt-4 flex flex-col gap-1">
                  {
                    data.patientPacketSubmission.patientFormSubmissions.map(pfs => (
                      <PatientFormLink
                        key={pfs.id}
                        to={`${match.url}/patient_form_submission/${pfs.id}`}
                        patientFormSubmission={pfs}
                      />
                    ))
                  }
                </nav>

              </div>
            </div>
          </div>
          <div className="flex-grow ml-4">
            <Switch>
              <Route path={`${match.path}/patient_form_submission/:patientFormSubmissionId`}>
                <PatientFormSubmissionView />
              </Route>
              <Route path="*">
                <div className="flex flex-col items-center justify-center min-h-full w-full">
                  <CircleIcon icon="file-alt" />
                  <h4 className="font-bold pb-1 pt-3 text-cool-gray-700 text-lg">
                    No Form Selected
                  </h4>
                </div>
              </Route>
            </Switch>
            {/* <pre>
              {JSON.stringify(data, null, 2)}
            </pre> */}

          </div>
        </div>
      )}
    </div>
  );
};
