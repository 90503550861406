import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  Button,
  FAIcon,
  CircleIcon,
  JsonDebugger,
  Spinner,
} from "@preferral/ui";
import { iso8601Date } from "lib/dateFormatters";
import { TimeRange, TimeSeriesData } from "../model";
import { LineChart } from "../charts/LineChart";
import { transformTimeSeries } from "../helpers";

const DEPARTMENT_SCHEDULING_VOLUME = gql`
  query DepartmentSchedulingVolume($dateRange: InclusiveDateRangeInput!) {
    analyticsDepartmentSchedulingVolume(dateRange: $dateRange) {
      timeRange {
        start
        finish
      }
      data {
        timespan
        metrics {
          metric
          value
        }
      }
    }
  }
`;

interface Data {
  analyticsDepartmentSchedulingVolume: TimeSeriesData;
}

interface DepartmentSchedulingVolumeProps {
  timeRange: TimeRange;
}

export const DepartmentSchedulingVolume: FC<DepartmentSchedulingVolumeProps> =
  props => {
    const { timeRange } = props;

    const dateRange = {
      start: iso8601Date(timeRange.start),
      finish: iso8601Date(timeRange.finish),
    };

    const { data, loading, error, refetch } = useQuery<Data>(
      DEPARTMENT_SCHEDULING_VOLUME,
      {
        variables: { dateRange },
      }
    );

    return (
      <div className="_DepartmentSchedulingVolume p-4 bg-white rounded-lg border shadow-xl">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <CircleIcon icon="chart-line" />
            <p className="text-xl font-semibold ml-4">
              Department Scheduling Volume
            </p>
          </div>
          {/*<Button kind="secondary" color="gray" className="border">*/}
          {/*  Export CSV*/}
          {/*  <span className="ml-2">*/}
          {/*    <FAIcon icon="file-download" />*/}
          {/*  </span>*/}
          {/*</Button>*/}
        </div>

        {loading ? (
          <div className="p-8 text-center">
            <Spinner />
          </div>
        ) : error || !data?.analyticsDepartmentSchedulingVolume ? (
          <div className="p-8 text-center">
            <p>Failed to load.</p>
            <Button
              type="button"
              size="sm"
              className="mt-4"
              disabled={loading}
              isLoading={loading}
              onClick={() => refetch()}
            >
              Retry
            </Button>
          </div>
        ) : (
          <div className="p-8 text-left">
            <LineChart
              data={transformTimeSeries(
                data.analyticsDepartmentSchedulingVolume.data
              )}
              xValKey="datetime"
              yValKey="scheduled"
              yValLabel="Scheduled Appointments"
            />
          </div>
        )}

        <JsonDebugger data={data} />
      </div>
    );
  };
