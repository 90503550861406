import { FC, useRef, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AMD_ECONSULT_REQUEST_FORM_ORIGIN } from "config/constants";
import { ScreenTitle } from "context/ScreenTitle";
import toast from "react-hot-toast"

interface RouteParams {
  fhirId?: string;
}

export const EConsultRequestFormScreen: FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { fhirId } = useParams<RouteParams>();
  const history = useHistory();

  const handleMessage = useCallback((event: MessageEvent) => {
    switch (event.data?.status) {
      case 'econsult_cancel':
        toast.success('Your request was cancelled.');
        break;
      case 'econsult_draft':
        toast.success(`eConsult ${event.data?.econsultId} was saved as a draft.`);
        break;
    }
    switch (event.data?.transition) {
      case 'next-go-to-econsults-open':
        return history.push('/o/econsults/open');
      case 'next-go-to-econsults-all':
        return history.push('/o/econsults/all');
      default:
        break;
    }
  }, [history]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage)
  }, [handleMessage]);

  const iframeSrc = `${AMD_ECONSULT_REQUEST_FORM_ORIGIN}/econsults/create-request${fhirId ? `/${fhirId}` : ''}`;

  return (
    <>
      <ScreenTitle title="New eConsult Request" />
      <iframe
        title="aristamd-request-form"
        key={Math.random()}
        ref={iframeRef}
        src={iframeSrc}
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100%",
          border: "none",
          margin: 0,
          padding: 0,
          overflow: "hidden"
        }}
      />
    </>
  );
}
