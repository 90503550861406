import React, { FC, useCallback } from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Button, SelectField } from "@preferral/ui";
import {
  LocationOptionGqlFields,
  LocationModel,
  LocationSelectOption,
} from "components/LocationOption";
import toast from "react-hot-toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";

const ORGANIZATION_LOCATIONS = gql`
  query OrganizationLocations {
    me {
      id
      organization {
        id
        locations {
          ${LocationOptionGqlFields}
        }
      }
    }
  }
`;

interface Data {
  me: {
    id: string;
    organization: {
      id: string;
      locations: LocationModel[];
    };
  };
}

const CREATE_PROVIDER_MAPPINGS = gql`
  mutation AddProviderMapping($providerId: UUID4!, $locationIds: [UUID4!]!) {
    mapProviderToLocations(providerId: $providerId, locationIds: $locationIds) {
      errors {
        key
        message
      }
      provider {
        id
      }
    }
  }
`;

interface MutationData {
  mapProviderToLocations: {
    errors?: InputError[];
    provider?: {
      id: string;
    };
  };
}

interface MutationVariables {
  providerId: string;
  locationIds: string[];
}

interface FormValues {
  locationId: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    locationId: Yup.string().required("Required").nullable(),
  })
  .required();

const initialValues: FormValues = {
  locationId: "",
};

interface AddLocationFormProps {
  providerId: string;
  excludeLocationIds?: string[];
  onSuccess(): void;
}

export const AddLocationForm: FC<AddLocationFormProps> = (props) => {
  const { providerId, excludeLocationIds = [], onSuccess } = props;

  const { data, loading } = useQuery<Data>(ORGANIZATION_LOCATIONS);

  const locationOptions =
    data?.me.organization.locations.filter(
      (l) => !excludeLocationIds.includes(l.id)
    ) || [];

  const [addLocations] = useMutation<MutationData, MutationVariables>(
    CREATE_PROVIDER_MAPPINGS
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting, resetForm } = formikActions;

      setStatus({ errors: null });

      try {
        const { data } = await addLocations({
          variables: { providerId, locationIds: [values.locationId] },
        });

        if (data?.mapProviderToLocations.errors) {
          setStatus({ errors: data.mapProviderToLocations.errors });
        } else if (data?.mapProviderToLocations.provider) {
          // it worked...
          toast.success("Success!");
          resetForm();
          onSuccess();
        }
      } catch (e) {
        console.error(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
      } finally {
        setSubmitting(false);
      }
    },
    [addLocations, providerId, onSuccess]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, status }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <div className="mt-3">
            <SelectField<LocationModel>
              name="locationId"
              label="Location"
              isLoading={loading}
              options={locationOptions}
              getOptionLabel={(o: LocationModel) => o.name}
              getOptionValue={(o: LocationModel) => o.id}
              components={{ Option: LocationSelectOption }}
            />
          </div>
          <div className="flex items-center justify-end mt-3">
            <Button
              type="submit"
              kind="primary"
              color="blue"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Add Location
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
