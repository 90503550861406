import { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useAppointmentRequest } from "../../../AppointmentRequestContext";
import { TimeMaskInput } from "components/formik/TimeMaskField";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { DatePickerInput } from "components/formik/DatePickerField";
import {
  ProviderSelectOption,
  ProviderModel,
  ProviderOptionGqlFields,
} from "components/ProviderOption";
import {
  HorizontalSelectField,
  HorizontalField,
  Button,
  Spinner,
} from "@preferral/ui";
import { localDateRegex, localDateToISO } from "@preferral/common";
import {
  LocationModel,
  LocationOptionGqlFields,
  LocationSelectOption,
} from "components/LocationOption";

const DEPARTMENT_PROVIDERS_AND_LOCATIONS = gql`
  query DepartmentProvidersAndLocations($departmentId: UUID4!, $pageSize: Int) {
    departmentProviders(departmentId: $departmentId, first: $pageSize) {
      cursor
      endOfList
      items {
        ${ProviderOptionGqlFields}
      }
    }
    departmentLocations(departmentId: $departmentId, first: $pageSize) {
      cursor
      endOfList
      items {
        ${LocationOptionGqlFields}
      }
    }
    me {
      id
      timeZoneName
    }
  }
`;

interface Data {
  departmentProviders: Paginated<ProviderModel>;
  departmentLocations: Paginated<LocationModel>;
  me: {
    id: string;
    timeZoneName: string;
  };
}

interface Variables {
  departmentId: string;
  pageSize?: number;
}

const SET_APPOINTMENT = gql`
  mutation BookAppointment(
    $appointmentRequestId: UUID4!
    $input: AppointmentInput!
  ) {
    bookAppointment(
      appointmentRequestId: $appointmentRequestId
      input: $input
    ) {
      errors {
        key
        message
      }
      appointment {
        id
      }
    }
  }
`;

interface MutationData {
  bookAppointment: {
    errors?: InputError[];
    appointment?: {
      id: string;
    };
  };
}

interface MutationVariables {
  appointmentRequestId: string;
  input: AppointmentInput;
}

interface AppointmentInput {
  date: string;
  startTime: string;
  providerId: string;
  locationId: string;
  timeZoneName: string;
  duration: number;
}

interface FormValues {
  date: string;
  startTime: string;
  providerId: string;
  locationId: string;
  timeZoneName: string;
  duration: number;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    date: Yup.string()
      .matches(localDateRegex, "Invalid date")
      .required("Required"),
  })
  .required();

interface NewAppointmentFormProps {
  onCancel(): void;
  onSuccess(): void;
}

export const NewAppointmentForm: FC<NewAppointmentFormProps> = (props) => {
  const { onCancel, onSuccess } = props;

  const {
    appointmentRequest: {
      id: appointmentRequestId,
      requestedLocation,
      requestedProvider,
      departmentId,
    },
    refetch,
  } = useAppointmentRequest();

  const { data, loading } = useQuery<Data, Variables>(
    DEPARTMENT_PROVIDERS_AND_LOCATIONS,
    { variables: { departmentId } }
  );

  const [setAppointment] = useMutation<MutationData, MutationVariables>(
    SET_APPOINTMENT
  );

  const onSubmit = useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikHelpers;

      setStatus({ errors: null });

      const input: AppointmentInput = {
        providerId: values.providerId,
        locationId: values.locationId,
        date: localDateToISO(values.date),
        startTime: values.startTime,
        timeZoneName: values.timeZoneName,
        duration: values.duration,
      };

      return setAppointment({
        variables: { appointmentRequestId, input },
      }).then((resp) => {
        if (resp.data?.bookAppointment.errors) {
          setStatus({ errors: resp.data.bookAppointment.errors });
        } else if (resp.data?.bookAppointment.appointment) {
          // it worked...
          toast.success("Appointment created!");
          refetch();
          return onSuccess();
        }
        setSubmitting(false);
      });
    },
    [setAppointment, appointmentRequestId, refetch, onSuccess]
  );

  const initialValues = {
    date: "",
    startTime: "",
    providerId: requestedProvider?.id || "",
    locationId: requestedLocation?.id || "",
    timeZoneName:
      requestedLocation?.timeZoneName || data?.me.timeZoneName || "US/Central",
    duration: 30,
  };

  return loading ? (
    <div className="p-12 text-center">
      <Spinner />
    </div>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="mt-3">
            <HorizontalSelectField
              label="Provider"
              name="providerId"
              options={data?.departmentProviders.items || []}
              isLoading={loading}
              getOptionLabel={(p: ProviderModel) => p.nameWithAppellation}
              getOptionValue={(p: ProviderModel) => p.id}
              components={{ Option: ProviderSelectOption }}
            />
          </div>

          <div className="mt-3">
            <HorizontalSelectField
              label="Location"
              name="locationId"
              options={data?.departmentLocations.items || []}
              isLoading={loading}
              getOptionLabel={(l: LocationModel) => l.name}
              getOptionValue={(l: LocationModel) => l.id}
              components={{ Option: LocationSelectOption }}
            />
          </div>
          <div className="mt-3">
            <HorizontalField label="Date/Time">
              <div className="flex items-start">
                <div className="flex-grow mr-1">
                  <DatePickerInput name="date" icon="calendar-alt" />
                  <div className="text-right">
                    <select className="border-b border-dashed border-indigo-300 font-semibold p-1 text-indigo-800 text-xs">
                      <option>US/Central</option>
                      <option>US/Eastern</option>
                    </select>
                  </div>
                </div>
                <div className="ml-1 flex-grow">
                  <TimeMaskInput
                    name="startTime"
                    placeholder="hh:mm pm"
                    icon={["far", "clock"]}
                  />
                  <div>
                    <select>
                      <option>30 min</option>
                      <option>35 min</option>
                    </select>
                  </div>
                </div>
              </div>
            </HorizontalField>
          </div>

          <div className="mt-3 p-3 flex items-center justify-center gap-3">
            <Button type="button" onClick={onCancel} disabled={isSubmitting}>
              Cancel
            </Button>

            <Button
              type="submit"
              kind="primary"
              color="blue"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Save Appointment
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
