import { FC, useState, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-hot-toast";
import { Button } from "@preferral/ui";
import { Modal, ModalHeader } from "components/Modal";

const MARK_UPLOADED_TO_EHR = gql`
  mutation UpdateEconsultUploadedToEhr($id: Int!, $uploadedToEhr: Boolean!) {
    updateEconsultUploadedToEhr(id: $id, uploadedToEhr: $uploadedToEhr) {
      errors {
        key
        message
      }
      econsult {
        id
        status
      }
    }
  }
`;

interface MutationData {
  updateEconsultUploadedToEhr: {
    errors?: InputError[];
    econsult?: {
      id: string;
      status: string;
    };
  };
}

interface MutationVariables {
  id: number;
  uploadedToEhr: boolean;
}

interface MarkUploadedToEhrModalProps {
  eConsultId: number;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

export const MarkUploadedToEhrModal: FC<MarkUploadedToEhrModalProps> =
  props => {
    const { isOpen, onClose, eConsultId, onSuccess } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState<string[] | null>(null);

    const [markAsUploadedToEhr] = useMutation<MutationData, MutationVariables>(
      MARK_UPLOADED_TO_EHR
    );

    const onSubmit = useCallback(async () => {
      setErrors(null);
      setIsSubmitting(true);

      const variables = {
        id: eConsultId,
        uploadedToEhr: true
      };

      try {
        const { data } = await markAsUploadedToEhr({ variables });

        if (data?.updateEconsultUploadedToEhr.errors) {
          setErrors(data.updateEconsultUploadedToEhr.errors.map(e => e.message));
        } else if (data?.updateEconsultUploadedToEhr.econsult) {
          // it worked...
          toast.success("eConsult updated")
          return onSuccess();
        }
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
      }
      setIsSubmitting(false);
    }, [eConsultId, markAsUploadedToEhr, onSuccess]);

    return (
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <ModalHeader
          icon="circle-check"
          title="Upload eConsult to the EHR"
          onClose={onClose}
        />
        <div className="p-4">
          <p>
            Confirm the eConsult has been uploaded to your EHR using your
            existing procedures.
          </p>

          {errors?.map(e => (
            <p key={e} className="text-red-600 py-1">{e}</p>
          ))}

          <div className="flex items-center justify-end pt-6">
            <Button
              type="button"
              className="mr-3"
              kind="secondary"
              color="gray"
              size="lg"
              disabled={isSubmitting}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              kind="primary"
              color="blue"
              size="lg"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              onClick={onSubmit}
            >
              Mark uploaded to the EHR
            </Button>
          </div>
        </div>
      </Modal>
    );
  };
